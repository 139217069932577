<template>
    <div
        class="roomTable"
        v-bind:class="{ 'mobile': isMobile() }"
        v-for="(room, idx) in roomList"
        v-bind:key="idx"
        v-on:click="enterRoom(room, 'baccarat')">
        <div class="roomInfoTop">
            <img
                v-if="dealers[room['dealer_id']]"
                v-bind:src="`data:image/png;base64,${decompression(dealers[room['dealer_id']]['meta_png'])}`"
                class="dealerImage"/>
            <img
                v-else
                src="../../assets/icons/lobby_table/roomLogo.png"
                class="roomNoImg">
            <img
                src="../../assets/icons/lobby_table/btn-minmax.png"
                class="btnInfo"
                v-on:click.stop="toggleMinMax(room)">
            <div class="dealerNameBox">
                {{ room['dealer_id'] }}
            </div>
            <div class="roomLike">
                <img src="../../assets/icons/lobby_table/heart-card-1404.png">
                <span>{{ room['like'] }}</span>
            </div>
        </div>
        <div
            v-bind:class="{
                'openFade': isShowMinMax[room['table_id']],
                'closeFade': !isShowMinMax[room['table_id']]}"
            class="openInrInfo">
            <h4>{{ room["currency_code"] }}</h4>
            <ul>
                <li
                    v-for="(minMax, key) in minMaxs"
                    v-bind:key="key">
                    <img v-bind:src="minMax['src']">
                    <span>
                        <span v-numberFormat="getMinmax(room, minMax, 'min')"></span>
                        -
                        <span v-number-format="getMinmax(room, minMax, 'max')"></span>
                    </span>
                </li>
            </ul>
        </div>
        <div class="scoreBoard">
            <ScoreBoard
                v-bind:scoreBoard="getBigRoad(room['room_no'])"
                v-bind:isMobile="isMobile()"/>
        </div>
        <div class="roomInfoBtm">
            <div class="roomNameBox">
                <h3>{{ room['table_id'] }} <span>#{{ room['score_board_record'].length }}</span></h3>
                <div class="roomPrsnl">
                    <span>{{ roomUsers[room['room_no']] }}</span>
                    <img src="../../assets/icons/lobby_listbox/user-icon2.png">
                </div>
            </div>
            <ul class="gameProgress">
                <span
                    class="gameStatus"
                    v-for="(icon, idx) in statusImage['off']"
                    v-bind:key="idx">
                    <span>
                        <img v-bind:src="roomStatus[room['room_no']] == idx ? statusImage['on'][roomStatus[room['room_no']]] : icon">
                    </span>
                    <span
                        class="arrow"
                        v-if="idx != statusImage['off'].length - 1">
                        <img src="../../assets/icons/lobby_listbox/icon-arrow-1.png">
                    </span>
                </span>
            </ul>
        </div>
    </div>
</template>
<script setup>
    import { enterRoom } from '@/js/common';
    import { getBigRoad } from "@/js/scoreBoard/generator";
    import ScoreBoard from "@/components/common/ScoreBoard";
    import { isMobile, decompression } from "@/js/utils";
    import { onMounted, reactive, ref } from "vue";
    import { defineProps } from "vue";

    const props = defineProps({
        dealers: Object,
        roomStatus: Object,
        roomUsers: Object,
        roomList: Array,
    });

    const isShowMinMax = reactive({});
    // CMS에서 받아올 MINMAX 정보들 방 I 아이콘클릭시 보여줌
    const minMaxs = [
        {
            'key': 'pp',
            'minMax': 'amount_bet_max_4',
            'src': require('@/assets/icons/lobby_listbox/icon-p-p.png')
        },
        {
            'key': 'p',
            'minMax': 'amount_bet_max_1',
            'src': require('@/assets/icons/lobby_listbox/icon-p.png')
        },
        {
            'key': 'tie',
            'minMax': 'amount_bet_max_3',
            'src': require('@/assets/icons/lobby_listbox/icon-t.png')
        },
        {
            'key': 'b',
            'minMax': 'amount_bet_max_2',
            'src': require('@/assets/icons/lobby_listbox/icon-b.png')
        },
        {
            'key': 'bp',
            'minMax': 'amount_bet_max_5',
            'src': require('@/assets/icons/lobby_listbox/icon-p-b.png')
        },
    ];
    // 방 상태이미지 아이콘 ON/OFF
    const statusImage =  reactive({
        'on': [
            require('@/assets/icons/lobby_listbox/icon-user-1.png'),
            require('@/assets/icons/lobby_listbox/icon-clock-1.png'),
            require('@/assets/icons/lobby_listbox/icon-time-1.png'),
            require('@/assets/icons/lobby_listbox/icon-cards-1.png'),
            require('@/assets/icons/lobby_listbox/icon-cup-1.png'),
        ],
        'off': [
            require('@/assets/icons/lobby_listbox/icon-user-2.png'),
            require('@/assets/icons/lobby_listbox/icon-clock-2.png'),
            require('@/assets/icons/lobby_listbox/icon-time-2.png'),
            require('@/assets/icons/lobby_listbox/icon-cards-2.png'),
            require('@/assets/icons/lobby_listbox/icon-cup-2.png'),
        ]
    });
    // 방 I 아이콘클릭시 min/max 보여주기 여부
    const toggleMinMax = (room) => {
        if(!isShowMinMax[room['table_id']]){
            isShowMinMax[room['table_id']] = false;
        }
        isShowMinMax[room['table_id']] = !isShowMinMax[room['table_id']];
    }

    // CMS 배팅 MIN/MAX 정보 가져오기
    const getMinmax = (room, minMaxInfo, minMax) => {
        if(!room[minMaxInfo['minMax']]){
            return;
        }
        return minMax == 'min' ? room[minMaxInfo['minMax']].split(':')[0] : room[minMaxInfo['minMax']].split(':')[1];
    }

</script>
<style scoped>
	.gameStatus span {
		display: flex;
	}
	.roomTable{
		position: relative;
		width: 251px;
		height: 349px;
		background-color: #1b1b1b;
		border: 1px solid #fff;
		cursor: pointer;
		/*margin: 0 55px 55px 0;*/
        transition: all .3s ease;
	}

	.roomInfoTop{
		position: relative;
		width: inherit;
		height: 233px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
	.dealerImage {
		width: 100%;
		height: auto;
		padding-right: 2px;
	}
	.roomNoImg{
		width: 128px;
		height: auto;
	}
	.dealerNameBox{
		position: absolute;
		width: 99.4%;
		height: 36px;
		text-align: center;
		line-height: 39px;
		bottom: 0;
		left: 0;
		background-color: rgba(18,27,45,.7);
		text-shadow: 0 0 10px rgba(0,0,0,0.8);
		color: #fff;
		font-size: 22px;
	}
	.scoreBoard{
		width: 100%;
		height: 51px;
		background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAACFJREFUKFNjnDlz5n8GIgAjSGF6ejojIbWjCvGGENHBAwDKoybVGe/voAAAAABJRU5ErkJggg==") repeat #fff;
	}
	.roomNameBox{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 9px;
		color: #fff;
		font-weight: 300;
		border-bottom: 1px solid #8d8d8d;
		line-height: 33px;
		height: 33px;
	}
	.roomNameBox > h3{
		font-size: 20px;
	}
	.roomNameBox > h3 > span{
		font-size: 18px;
	}
	.roomInfoBtm{
		width: 100%;
		height: 62px;
		text-align: left;
		background-color: #1b1b1b;
	}
	.roomInfoBtm ul{
		display: flex;
		padding: 5px 5px;
		justify-content: center;
		flex-direction: row;
		height: 33px;
	}
	.roomInfoBtm li{
		/*width: 242px;*/
		display: flex;
		align-items: center;
		font-size: 20px;
		color: #fff;
		justify-content: space-between;
	}
	.roomInfoBtm li:nth-child(2){
		align-items: center;
		border-right: 1px solid #6d6d6d;
		border-left:  1px solid #6d6d6d;
	}
	.roomInfoBtm li:nth-child(2) span{
		font-size: 10px;
		line-height: 8px;
	}
	.btnInfo{
		position: absolute;
		width: 30px;
		height: 30px;
		top: 0;
		right: 0;
		margin-top: 5px;
		margin-right: 7px;
		cursor: pointer;
		z-index: 1;
	}
	.openInrInfo{
		width: 100%;
		height: 233px;
		position: absolute;
		top: 0;
		right: 0;
		background-color: rgba(20,21,24,0.8);
		color: #fff;
		display: flex;
		padding: 10px 15px 0;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 10px;
        opacity: 0;
	}
	.openInrInfo > h4{
		font-size: 20px;
	}
	.openInrInfo > h4:lang(en){
		font-size: 16px !important;
	}
	.openInrInfo ul{
		display: flex;
		gap: 10px;
		flex-direction: column;
		width: 100%;
	}
	.openInrInfo ul li{
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 5px;
		justify-content: space-between;
		font-size: 16px;
		gap: 10px;
		white-space: nowrap;
	}
	.openInrInfo ul li:lang(en){
		font-size: 15px !important;
	}
	.openInrInfo ul li img{
		width: 23px;
	}
	.gameStatus {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 5px;
	}
	.gameStatus ::v-deep(img:nth-child(1)) {
		width: 22px;
	}
	.gameStatus ::v-deep(.arrow) {
		width: 15px;
	}
	/* 211027수정사항 반영 */
	.roomLike{
		position: absolute;
		top: 0;
		left: 0;
		width: auto;
        min-width: 84px;
        max-width: 110px;
		height: 34px;
		background-color: rgba(23,39,62,.7);
		border-radius: 15px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		color: #fff;
		font-size: 20px;
		margin-left: 3px;
		margin-top: 3px;
        padding: 0 8px;
	}
	.roomLike > span{
		font-size: 16px;
	}
	.roomPrsnl{
		display: flex;
		align-items: center;
		gap: 5px;
	}
	.roomPrsnl > span{
		height: 25px;
	}



	/*mobile start*/
	.roomTable.mobile {
		position: relative;
		width: 49%;
		height: 250px;
		background-color: #1b1b1b;
		border: 1px solid #fff;
	}

	.roomTable.mobile .roomInfoTop{
		position: relative;
		width: 100%;
		height: 165px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
	.roomTable.mobile .dealerImage {
		width: 100%;
		height: auto;
	}
	.roomTable.mobile .roomNoImg{
		width: 73px;
		height: auto;
		max-width: 100%;
	}
	.roomTable.mobile .dealerNameBox{
		position: absolute;
		width: 100%;
		height: 20px;
		text-align: center;
		line-height: 21px;
		bottom: 0;
		left: 0;
		background-color: rgba(23,39,62,.7);
		text-shadow: 0 0 10px rgba(0,0,0,.8);
		color: #fff;
		font-size: 14px;
	}
	.roomTable.mobile .scoreBoard{
		width: 100%;
		height: 38px;
		background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAAXNSR0IArs4c6QAAABhJREFUGFdjZMACJk6c+J+RHhIgi7DZAwBQFRAzu21jBgAAAABJRU5ErkJggg==") repeat #fff;
	}
	.roomTable.mobile .roomInfoBtm{
		width: 100%;
		height: 38px;
		background-color: #1b1b1b;
	}
	.roomTable.mobile .gameProgress{
		width: 100%;
		height: 29px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.roomTable.mobile .gameProgress li{
		align-items: center;
		justify-content: center;
	}
	.roomTable.mobile .gameProgress li:nth-child(even){
		font-size: 10px;
	}
	.roomTable.mobile .gameProgress li > span{
		margin: 0 1px;
	}
	.roomTable.mobile .gameProgress img{
		width: auto;
		height: 17px;
	}
	.roomTable.mobile .gameStatus {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		justify-content: center;
	}
	.roomTable.mobile .btnInfo{
		position: absolute;
		width: 22px;
		height: 22px;
		top: 0;
		right: 0;
		margin-top: 5px;
		margin-right: 5px;
		cursor: pointer;
		z-index: 1;
	}
	.roomTable.mobile .openInrInfo{
		width: 100%;
		height: 164px;
		position: absolute;
		top: 0;
		right: 0;
		background-color: rgba(20,21,24,0.8);
		color: #fff;
		display: flex;
		padding: 10px 17px 0;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		visibility: visible;
		gap: 5px;
	}
	.roomTable.mobile .openInrInfo > h4{
		font-size: 15px;
	}
	.roomTable.mobile .openInrInfo ul{
		display: flex;
		gap: 5px;
		flex-direction: column;
	}
	.roomTable.mobile .openInrInfo ul li{
		display: flex;
		align-items: center;
		margin-bottom: 5px;
		justify-content: space-between;
		font-size: 11px !important;
		gap: 10px;
	}
	.roomTable.mobile .openInrInfo ul li img{
		width: 16px;
	}
	.roomTable.mobile .roomNameBox{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 4px;
		color: #fff;
		font-weight: 300;
		border-bottom: 1px solid #8d8d8d;
		line-height: 20px;
		height: 20px;
	}
	.roomTable.mobile .roomNameBox > h3{
		font-size: 15px;
		display: flex;
		flex-direction: row;
	}
	.roomTable.mobile .roomNameBox > h3 > span {
		font-size: 12px;
	}
	.layoutChange{
		position: absolute;
		bottom: 0;
		right: 0;
		display: flex;
		flex-direction: row;
		gap: 10px;
		margin-right: 25px;
		margin-bottom: 20px;
	}
	.layoutChange > li > img{
		width: 38px;
		height: 28px;
		max-width: 100%;
	}

	/* 211027수정사항 반영 */
	.roomTable.mobile .roomLike{
		position: absolute;
		top: 0;
		left: 0;
		width: auto;
        min-width: 48px;
        max-width: 80px;
		height: 18px;
		background-color: rgba(23,39,62,.7);
		border-radius: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 6px;
		color: #fff;
		font-size: 13px;
		margin-left: 3px;
		margin-top: 3px;
	}
	.roomTable.mobile .roomLike img{
		width: 12px;
	}
	.roomTable.mobile .roomPrsnl{
		display: flex;
		align-items: center;
		font-size: 13px;
		gap: 3px;
	}
	.roomTable.mobile .roomPrsnl > span{
		height: 17px;
	}
	.roomTable.mobile .roomPrsnl > img{
		width: 12px;
	}

    /*211117 한유정 추가*/
    .roomTable:hover{
        transform: scale(105%);
        transition: all .5s ease;
    }
    .openFade{
        opacity: 1;
        transition: all .5s ease;
    }
    .closeFade{
        opacity: 0;
        transition: all .3s ease;
    }
</style>