<template>
    <div
        v-bind:class="[props.options['size'], { 'gun3': isResize && !isMobile() }]"
        class="scoreBoard"
        v-on:click.stop="toggleResize()">
        <div
            class="row"
            v-for="(score, key) in props.scoreBoard"
            v-bind:key="key">
            <div
                class="cell"
                v-for="(cell, cellIdx) in score"
                v-bind:class="[getScoreClassGun(cell), props.options['size']]"
                v-bind:key="cellIdx">
            </div>
        </div>
    </div>
</template>
<script setup>
    import { isMobile } from "@/js/utils";
    import { getTieCount, getScoreClassGun } from "@/js/scoreBoard/generator";
    import { defineComponent, defineProps, ref } from "vue";

    const props = defineProps({
        scoreBoard: {
            type: Object,
            default: () => {
                return {}
            }
        },
        options: {
            type: Object,
            default: () => {
                return { size: 's' }
            }
        }
    });
    const isResize = ref(false);
    // 중국점 확대
    const toggleResize = () => {
        isResize.value = !isResize.value;
    };

</script>
<style scoped>
@keyframes ani_scoreBoard3Gun {
    0%{
        transform: scale(1);
        width: 47.4%;
    }
    100%{
        transform: scale(2.11);
    }
}
.gun3{
    animation: ani_scoreBoard3Gun .5s 1 alternate forwards;
    border-left: none !important;
    transition: all 0s ease;
    z-index: 999;
    width: 47.4% !important;
    transform-origin: right bottom;
}
.scoreBoard.s,
.scoreBoard.m,
.scoreBoard.sm {
	width: 100%;
	height: inherit;
	background-color: white;
	display: flex;
	flex-direction: column;
	background-image: linear-gradient(90deg, gray 1px, transparent 0), linear-gradient(gray 1px, transparent 0);
	background-size: 10px 10px;
	background-attachment: local;
	overflow: auto;
}

.scoreBoard.bigEyeSize {
	background-size: 15.5px 15.5px !important;
	min-height: 97px;
}

.scoreBoard.mobileSize {
	background-size: 4.5px 4.5px !important;
}

.scoreBoard.s {
	background-size: 10px 10px;
}
.scoreBoard.m {
	background-size: 29px 28px;
	overflow: hidden;
}
.scoreBoard.sm {
	background-size: 18px 18px !important;
}

.scoreBoard .row {
	display: flex;
    scroll-snap-align: end;
}
.scoreBoard .cell {
	position: relative;
	border-radius: 30px;
	box-sizing: border-box;
}

.cell.s {
	width: 10px;
	height: 10px;
	font-size: 6px !important;
}

.point.s {
	width: 1px;
	height: 1px;
}

.cell.mobileSize {
	width: 4.5px!important;
	height: 4.5px!important;
}

.bigEyeCelleSize {
	margin-left: 0 !important;
	width: 15.5px !important;
	height: 15.5px !important;
}

.cell.m {
	min-width: 14.5px;
	height: 14px;
}

.cell.sm {
	width: 8.8px;
	height: 8.8px;
}

.scoreBoard .cell.blue {
	background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="100%" x2="100%" y2="0" stroke="blue"/></svg>');
}
.scoreBoard .cell.red {
	background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="100%" x2="100%" y2="0" stroke="red"/></svg>');
}
</style>