export default {
  "baccarat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บาคาร่า"])},
  "teenpatti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เทนเนปัตติ"])},
  "dragonTiger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสือมังกร"])},
  "holdem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โฮลเดม"])},
  "roulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูเล็ต"])},
  "sicbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซิคโบ"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายวัน"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ร้านค้า"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติศาสตร์"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนตาราง"])},
  "betLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำกัดการเดิมพัน"])},
  "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิด/ปิดเสียง"])},
  "helpInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วยเหลือ/ข้อมูล"])},
  "lobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล็อบบี้"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salga"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสนทนา"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิดีโอ"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซื้อ"])},
  "fx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอฟเอ็กซ์"])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพลง"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สูง"])},
  "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลาง"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต่ำ"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดอันดับ"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ช่วยเหลือ C/S"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวเลือก"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาName"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากระบบ"])},
  "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำแนะนำ"])},
  "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พี.แพร์"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เล่น"])},
  "tie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไท"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นายธนาคาร"])},
  "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บี.แพร์"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เล่น"])},
  "banker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นายธนาคาร"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมดุล"])},
  "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดิมพัน"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนเงิน"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พร้อมแล้ว"])},
  "betReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วางเดิมพันของคุณ"])},
  "betEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิ้นสุดการเดิมพัน"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณารอจนกว่ารอบต่อไปจะเริ่มขึ้น"])},
  "noGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีรายการเกม"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาอังกฤษName"])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาเกาหลีName"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาญี่ปุ่นName"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาฮินดี้Name"])},
  "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาจีนName"])},
  "vt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาเวียดนามName"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาไทย"])},
  "sp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษาสเปนName"])},
  "would": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณอยากซื้อไหม?"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ราคา"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลา"])},
  "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่จำกัด"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน title: group"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ ไม่ ไม่ ไม่ค่ะ"])},
  "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขตัวแทน"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขประจำตัว"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงินฝาก"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถอนตัว"])},
  "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเดิมพัน"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จ่ายเงิน"])},
  "win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชนะ"])},
  "win2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชนะ"])},
  "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การสูญเสีย"])},
  "lose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แพ้"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วาดภาพ"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลผู้ใช้"])},
  "gameId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขเกม"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่( UTC)"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลลัพธ์"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ก่อนหน้านี้"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถัดไป"])},
  "historyWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* ประวัติสามารถดูได้เฉพาะ 100 แถวสุดท้ายเท่านั้น"])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูลให้ดู"])},
  "noRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปัจจุบันยังไม่มีตารางที่ใช้งานอยู่"])},
  "chatInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่งข้อความสนทนา"])},
  "toList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการ"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มิน"])},
  "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แม็กซ์"])},
  "checkMinMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรดตรวจสอบขีดจำกัดการเดิมพัน"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิด"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำลังโหลด"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตาราง title: window"])},
  "roundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROUND ID (ROUND ID)"])},
  "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลมกล่อม"])},
  "betCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกการเดิมพัน"])},
  "sabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซาบง"])},
  "betUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกการเดิมพัน"])},
  "betRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าการเดิมพันรอบก่อนหน้านี้"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้อนกลับ"])},
  "rebet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรียกคืน"])},
  "tutorialTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีการเล่นเกม"])},
  "tutorialTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผู้เล่นเลี้ยว"])},
  "tutorialTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบงก์เกอร์เลี้ยว"])},
  "tutorial1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การ์ด A คำนวณด้วยตัวเลข 1: และ 10: J: Q: และ K คำนวณด้วย 0 บัตรที่เหลือคำนวณจากจำนวนการเป็นเจ้าของบัตรแต่ละใบ"])},
  "tutorial2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลรวมของตัวเลขทั้งสองตัวพิจารณายกเว้นหน่วยที่ 10 ตัวอย่างเช่น ไม่ใช่ 5 + 8 = 13 แต่เป็น 3"])},
  "tutorial3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดยพื้นฐานแล้ว ผู้เล่นและนายธนาคารจะได้รับไพ่ใบละ 2 ใบ ในเวลานี้ หากผลรวมของตัวเลขทั้งสองกลายเป็น 8 หรือ 9 เกมจะจบลงโดยไม่ได้รับไพ่ใบที่ 3 ภายใต้เงื่อนไข Natural"])},
  "tutorial4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากทั้งสองอย่างไม่ได้เป็น Natural ผู้เล่นจะตัดสินใจว่าจะได้รับไพ่ใบที่สามหรือไม่ โดยพิจารณาจากผลรวมของไพ่ทั้งสองใบ"])},
  "tutorial5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ หากผลรวมของตัวเลขของผู้เล่นเป็น 6 หรือ 7, ไม่รับไพ่ (ยืน)"])},
  "tutorial6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ หากผลรวมของเลขผู้เล่นเป็น 0-5, ได้รับไพ่ใบที่ 3"])},
  "tutorial7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ถ้าผู้เล่นเป็น Stand"])},
  "tutorial8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ ถ้าผู้เล่นอายุ 6 หรือ 7 และนายธนาคารอายุ 6 หรือ 7: ไม่ยอมรับบัตร"])},
  "tutorial9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ ถ้าผู้เล่นอายุ 6 หรือ 7 และนายธนาคารอายุ 0 ถึง 5, ได้รับบัตรที่ 3"])},
  "tutorial10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถ้าผู้เล่นได้ไพ่ใบที่ 3"])},
  "tutorial11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ ถ้าผู้เล่นเป็น 0-5 และนายธนาคารเป็น 0-2: ได้รับบัตรที่ 3"])},
  "tutorial12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ ถ้าผู้เล่นเป็น 0 ถึง 5 และนายธนาคารเป็น 3, รับไพ่ที่ 3 (ยกเว้นถ้าไพ่ที่ 3 ของผู้เล่นเป็น 8)"])},
  "tutorial13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ ถ้าผู้เล่นเป็น 0 ถึง 5 และนายธนาคารเป็น 4: รับไพ่ใบที่ 3 (ยกเว้นถ้าไพ่ใบที่ 3 เป็น 0: 1: 8: 9)"])},
  "tutorial14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ ถ้าผู้เล่นเป็น 0 ถึง 5 และนายธนาคารเป็น 5, รับไพ่ใบที่ 3 (ยกเว้นถ้าไพ่ใบที่ 3 เป็น 0: 1: 2: 3: 8: 9)"])},
  "tutorial15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ ถ้าผู้เล่นเป็น 0 ถึง 5 และนายธนาคารเป็น 6, รับไพ่ใบที่ 3 (ยกเว้นถ้าไพ่ใบที่ 3 เป็น 0: 1: 2: 3: 4: 5: 8: 9)"])},
  "tutorial16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ ถ้าผู้เล่นเป็น 0 ถึง 5 และนายธนาคารเป็น 7: ไม่ยอมรับไพ่ (ยืน)"])},
  "tutorial17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตามกฎการแข่งขัน ใบที่ใกล้เคียงกับ 9 จาก 2 หรือ 3 ใบชนะ"])},
  "tutorial18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มันแตกต่างกันเล็กน้อยขึ้นอยู่กับตาราง แต่โดยปกติจะคืนค่ากลับมาเป็นสองเท่าของจำนวนการตีไปยังด้านที่ชนะ และถ้าคุณชนะด้วยแต้ม คุณจะคืนค่ากลับมาเป็นแปดเท่าของจำนวนการตี"])},
  "tutorial19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื่องจาก Banker มีอัตราการชนะสูง หากคุณชนะโดย Banker คุณจะจ่ายค่าคอมมิชชั่น 5% ให้กับบ้าน"])},
  "msgErr1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความยาวเกินไป (ความยาวสูงสุด 100 ตัวอักษร)"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าจอเต็ม"])},
  "cam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แคม"])},
  "lobbyExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทางออก"])},
  "equip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อุปกรณ์"])},
  "unequip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ชัดเจน"])},
  "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ความสมดุลไม่เพียงพอ"])},
  "dealerSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผิวตัวแทนจำหน่าย"])},
  "cardSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผิวไพ่"])},
  "dealerProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรไฟล์ตัวแทนจำหน่าย"])},
  "beInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้อยู่"])},
  "dealerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลตัวแทนจำหน่าย"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมนูอาหาร"])},
  "myInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลของฉัน"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อุปกรณ์Name"])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แพลตฟอร์ม"])},
  "lastIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ip ล่าสุด"])},
  "lastDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โดเมนล่าสุด"])},
  "loginTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ครั้งที่แล้ว"])},
  "registerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรจส์ วันที่"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เรจส์ ที่อยู่"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สกุลเงิน"])},
  "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขผู้ใช้"])},
  "agentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขตัวแทน"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โพรไฟล์"])},
  "dealerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อตัวแทนจำหน่าย"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อย่างเช่น"])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เคล็ดลับ"])},
  "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาเล่น"])},
  "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมาชิก"])},
  "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวแทนจำหน่าย"])},
  "meron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "wala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAW"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHITE"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "lastGameResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการแข่งขันล่าสุด"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอ็ม"])},
  "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดับเบิลยู"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าร่วม"])},
  "memberList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายชื่อสมาชิก"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเทศ title: window"])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกรด"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แอลวี"])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แปลง"])},
  "deposit_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงินฝาก"])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การถอนตัว"])},
  "depositBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เงินฝาก"])},
  "transactionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติการทำธุรกรรม"])},
  "depositWarningTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าธรรมเนียมวัดสำหรับการแปลง แต่ไม่ได้วัดเมื่อแลกเปลี่ยนเงิน"])},
  "depositInfoTxt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถแปลงจุด Sabong Point (USD) ของคุณเป็น Ethereum หรือ BNB"])},
  "depositInfoTxt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้อนค่าที่ต้องการแปลง แล้วกดปุ่ม Transfer เพื่อแปลงเป็น Sabong Point โดยการประยุกต์ใช้ราคาตลาดที่เหมาะสมกับเวลาที่ต้องการแลกเปลี่ยน"])},
  "walletCreateTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหรียญของ SABONG WALLET ถือผ่านการส่งกระเป๋าสตางค์ คุณสามารถเพิ่มปริมาณได้"])},
  "walletCreateTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างกระเป๋าสตางค์ vinus sabong"])},
  "depositSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัญลักษณ์การฝากเงิน"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัญลักษณ์"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่า"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ก๊าซ"])},
  "copyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกที่อยู่"])},
  "myWalletAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่ USD ของบริษัท"])},
  "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดัชนี"])},
  "registerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ลงทะเบียน"])},
  "requestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการร้องขอ"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขการทำธุรกรรม"])},
  "resultAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนผลลัพธ์"])},
  "withdrawalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนการถอนเงิน"])},
  "fromTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จากสกุลเงินโอน"])},
  "transferAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนการโอนเงิน"])},
  "toTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อโอนเงินตรา"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กระเป๋าสตางค์"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชุดตกแต่ง"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตอนนี้"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนทั้งหมด"])},
  "gameHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติเกม"])},
  "fightNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สู้ No."])},
  "betList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการเดิมพัน"])},
  "gmt-mnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จีเอ็มที-เอ็มเอ็นแอล"])},
  "gmt-ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-IST"])},
  "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้ำอีกครั้ง"])},
  "betRooster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พนันไก่ไก่"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล็อกอิน"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การถ่ายโอน"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เชื่อมต่อกับกระเป๋าตังค์"])},
  "walletHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติกระเป๋าตังค์"])},
  "cancelInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเดิมพันถูกยกเลิกเนื่องจากอัตราเงินปันผลผิดปกติ"])},
  "cancelInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลลัพธ์ที่แท้จริงของเกม"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อเข้าใช้"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลงชื่อ"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันนี้"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อวาน"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สัปดาห์"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือน"])},
  "noBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีความสมดุล"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภท"])},
  "bannerContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกมนี้เป็นผลิตภัณฑ์คอนเทนต์ที่แข็งแกร่งและยิ่งใหญ่ที่สุดของ Gaming และคาดว่าจะได้รับความนิยมมากที่สุดในปัจจุบัน"])},
  "bannerContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เนื้อหา SABONG ร่วมมือกับบริษัทชั้นนำของฟิลิปปินส์ถ่ายทอดสดทุกวันโดยไม่หยุดค่ะ"])},
  "bannerContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณสามารถสัมผัสกับเกม SABONG ที่ทรงพลังและน่าตื่นเต้นที่สุดในฟิลิปปินส์"])},
  "sabongGuideHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. เกมซาบง"])},
  "sabongGuide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกมชนไก่ของฟิลิปปินส์เรียกว่า 'ซาบง' ในภาษาตากาล็อก"])},
  "sabongGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เป็นหนึ่งในกีฬาพื้นเมืองที่ชาวฟิลิปปินส์ชื่นชอบมากที่สุด"])},
  "sabongGuide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเดิมพันสามารถเป็นได้ทั้ง 'RED' หรือ 'BLUE' หรือทั้งสองอย่าง"])},
  "sabongGuide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตราเงินปันผลจะผันผวนขึ้นลงตามปริมาณการเดิมพัน และการเดิมพันด้านหนึ่งคือ"])},
  "sabongGuide5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การรวมตัวกันมากเกินไปอาจทำให้เกมถูกยกเลิกได้"])},
  "sabongGuideHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. การเดิมพันและผลการแข่งขัน"])},
  "sabongGuide6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การเดิมพันจะเริ่มต้นเมื่อ \"Bet Open\" เปิดอยู่และเป็นเวลาเพียง 30 วินาทีหลังจากการโทรออกครั้งสุดท้าย"])},
  "sabongGuide7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช่ คุณไม่สามารถเดิมพันได้เมื่อหมดเวลาเดิมพัน"])},
  "sabongGuide8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อการเดิมพันสิ้นสุดลง เกมจะดำเนินต่อไปและจำนวนเงินที่ตรงกับอัตราเงินปันผลจะถูกกำหนดตามผลลัพธ์"])},
  "sabongGuide9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คุณจะได้รับหรือเสียเงินรางวัล"])},
  "sabongGuideHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. เกมไม่ถูกต้อง"])},
  "sabongGuide10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกมจับฉลาก"])},
  "sabongGuide12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อเกมยังไม่ตัดสินจนกว่าจะเริ่มเกม 10 นาที"])},
  "sabongGuide13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เมื่อไก่ตายทั้งคู่และกรรมการตัดสินว่าไม่สามารถเล่นเกมได้"])},
  "sabongGuide14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกมการยกเลิก"])},
  "sabongGuide15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในกรณีเลิกเล่นเนื่องจากไก่สภาพไม่ดี"])},
  "sabongGuide16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หากอัตราเงินปันผลไม่เกิน x1.20 เนื่องจากความไม่สมดุลในการเดิมพัน"])},
  "sabongGuide17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ในสถานการณ์ข้างต้น จำนวนเงินเดิมพันจะถูกส่งคืนและเกมจะเป็นโมฆะ"])}
}