<template>
    <swiper
        v-show="gameType != 'sabong'"
        v-bind:slidesPerView="'auto'"
        v-bind:spaceBetween="10"
        v-bind:speed="100"
        v-bind:pagination="{ &quot;clickable&quot;: true }"
        class="mySwiper"
    >
        <swiper-slide
            class="swiperItems"
            v-for="(game, key) in gameList"
            v-bind:key="key"
            v-on:click="setGameType(game)">
            <div
                class="gameMenuM"
                v-bind:class="{ 'selectGame': gameType === key }">
                <div
                    class="gameMenuImgWrap"
                    v-bind:class="{ 'dragonImgWrap': game['key'] === 'dragonTiger', 'sabongImgWrap': game['key'] == 'sabong'}">
                    <img
                        v-bind:src="game['img']"
                        v-bind:class="[{'lock': game['lock'] }, game['key']]"
                        alt="baccarat">
                    <img
                        class="comSoon"
                        v-if="game['lock']"
                        src="../../../assets/mobile/comming-soon.png"
                        alt="comming soon">
                </div>
                <h3 v-bind:class="{'lock': game['lock'], 'dragonTxt': game['key'] === 'dragonTiger'}">
                    {{ $t(game['key']) }}
                </h3>
                <!--  2021-11-08 이은정 추가    -->
                <div
                    v-if="gameType === game['key'] && game['key'] !== 'sabong'"
                    class="horiLine"></div>
                <div
                    v-if="gameType === game['key'] && game['key'] === 'sabong'"
                    class="sabongHoriLine">
                    <div class="select"></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </swiper-slide>
    </swiper>
</template>
<script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import sabongTabMenu from "@/template/sabong/sabongTabMenuM";

    // Import Swiper styles
    import 'swiper/swiper-bundle.css';
    import { defineComponent } from "vue";
    export default defineComponent({
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
                gameType: process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? 'sabong' : 'baccarat',
                gameList: [
                    {
                        'key': 'baccarat',
                        'title': this.$t('baccarat'),
                        'img': require('@/assets/icons/menu_game_icon/icon-baccarat.png'),
                        'click': () => {

                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? false : true
                    },
                    {
                        'key': 'dragonTiger',
                        'title': this.$t('dragonTiger'),
                        'img': require('@/assets/icons/menu_game_icon/icon-dragontiger.png'),
                        'click': () => {

                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                    {
                        'key': 'teenpatti',
                        'title': this.$t('teenpatti'),
                        'img': require('@/assets/icons/menu_game_icon/icon-teenpatti.png'),
                        'click': () => {

                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                    {
                        'key': 'holdem',
                        'title': this.$t('holdem'),
                        'img': require('@/assets/icons/menu_game_icon/icon-holdem.png'),
                        'click': () => {

                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                    {
                        'key': 'roulette',
                        'title': this.$t('roulette'),
                        'img': require('@/assets/icons/menu_game_icon/icon-roulette.png'),
                        'click': () => {

                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                    {
                        'key': 'sicbo',
                        'title': this.$t('sicbo'),
                        'img': require('@/assets/icons/menu_game_icon/icon-sicbo.png'),
                        'click': () => {

                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                ],
            }
        },
        methods: {
            setGameType(game) {
                if(game['lock']){
                    return;
                }
                this.gameType = game['key'];
            },
        }
    })
</script>
<style scoped>
	.lock {
		opacity: 0.5;
	}
	.gameMenuM{
		width: 80px;
		height: 80px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}
	.gameMenuM > img{
		width: 46px;
		height: auto;
	}
	.swiperItems{
		width: auto !important;
		margin: 0 !important;
		display: flex;
		flex-direction: row;
	}
	.gameMenuImgWrap{
		position: relative;
		height: auto;
	}
	.dragonImgWrap{
		height: 45px;
	}
    .gameMenuImgWrap img:nth-child(1){
        width: 41px;
        height: 41px;
    }
	.dragonImgWrap img:nth-child(2){
		bottom: 2px !important;
	}
	.gameMenuImgWrap img:nth-child(2){
		width: 77px;
		height: auto;
		position: absolute;
		bottom: 3px;
        right: 0;
        left: 0;
		z-index: 2;
        margin-left: -17px;
        max-width: unset;
	}
	.gameMenuM h3{
		font-size: 10px;
		color: #fafafa;
        margin-top: 6px;
	}

    /* 2021-11-08 이은정 추가 */
    .horiLine{
        margin-top: 3px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 2px;
        width: 70%;
        bottom: 0;
        background-color: #c8c8c8;
    }

    .sabongHoriLine {
        margin-top: 3px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 2px;
        width: 70%;
        bottom: 0;
        gap: 2px;
    }
    .sabongHoriLine > div{
        width: 25%;
        height: 2px;
        background-color: #545454;
    }
    .sabongHoriLine > div.select{
        background-color: #c8c8c8;
    }

    .dragonTxt{
        margin-top: -4px !important;
    }
    .dragonTxt:lang(ko){
        margin-top: 6px !important;
    }
	.mySwiper{
		padding: 11px 0 4px 7px;
	}
	.gameMenuM{
		cursor: not-allowed;
	}
	.selectGame{
		cursor: pointer !important;
		opacity: 1 !important;
	}
	.sabongImgWrap img:nth-child(1){
		top: -3px;
		position: relative;
	}

</style>