<template>
    <Header v-on:showModal="showModal"/>
    <div class="lobbyWrapM">
        <GameButtons/>
        <div
            class="roomBg"
            v-if="roomList.length == 0 && gameType !== 'sabong'">
            <span class="noRoom">
                {{ loadMsg }}
            </span>
            <Loading/>
        </div>
        <div class="roomBg">
            <div class="roomWrap">
                <keep-alive>
                    <component
                        v-bind:is="getRoomComponent"
                        v-bind:dealers="dealers"
                        v-bind:roomList="roomList"
                        v-bind:roomUsers="roomUsers"
                        v-bind:roomStatus="roomStatus"/>
                </keep-alive>
            </div>
        </div>
        <div
            class="logoWrap"
            v-if="isPagcor">
            <img
                src="../../assets/mobile/pagcor-logo.png"
                alt="pagcorLogo">
        </div>
        <ModalDaily
            v-if="modalKey == 'daily'"
            v-on:closeModal="closeModal"/>
        <ModalRank
            v-if="modalKey == 'rank'"
            v-on:closeModal="closeModal"/>
        <ModalHistory
            v-if="modalKey == 'history'&& gameType != 'sabong'"
            v-on:closeModal="closeModal"/>
        <ModalLocale
            v-if="modalKey == 'lang'"
            v-on:closeModal="closeModal"/>
        <ModalHelp
            v-if="modalKey == 'help'"
            v-on:closeModal="closeModal"/>
        <Shop
            v-if="modalKey == 'shop'"
            v-on:closeModal="closeModal"/>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import Loading from "@/components/common/Loading";
    import Header from "@/components/mobile/layout/HeaderM";
    import GameButtons from "@/components/mobile/layout/GameButtons";
    import SabongRoomM from "@/components/room/SabongRoomM";
    import BaccaratRoom from "@/components/room/BaccaratRoom";
    // Import Swiper styles
    import Lobby from "@/components/Lobby";
    import ModalRank from "@/components/popup/ModalRank";
    import ModalHistory from "@/components/popup/ModalHistory";
    import ModalLocale from "@/components/popup/ModalLocale";
    import ModalHelp from "@/components/popup/ModalHelp";
    import ModalDaily from "@/components/mobile/popup/Daily";
    import Shop from "@/components/mobile/popup/Shop";
    import { isMobile } from "@/js/utils";
    import { LOGO } from "@/js/const";

    export default defineComponent({
        mixins: [Lobby],
        components : {
            Header,
            GameButtons,
            SabongRoomM,
            Loading,
            ModalRank,
            ModalHistory,
            ModalLocale,
            ModalHelp,
            ModalDaily,
            Shop,
        },
        name: 'Lobby',
        data() {
            return {
                isPagcor: LOGO?.[location.hostname]?.isPagcor,
                modalKey: ''
            }
        },
        computed: {
            /**
             * @name: 게임별 방 컴포넌트 가져오기
             */
            getRoomComponent() {
                return this.gameType == 'baccarat' ? BaccaratRoom : SabongRoomM;
            },
        },
        created() {
        },
        methods: {
            isMobile,
            showModal(modalKey){
                this.modalKey = modalKey;
            },
            closeModal() {
                this.modalKey = '';
            }
        },
    });
</script>
<style scoped>
	.noRoom {
		color: white;
	}
	/* 룸 */
	.roomBg{
		width: 100%;
        min-height: 70vh;
        height: auto;
        background: transparent;
		display: flex;
		justify-content: center;
        align-items: flex-start;
		/* background-color: #2F3033; */
	}
	.roomWrap{
		width: 100%;
        height: auto;
        background: transparent;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 6px;
		justify-content: flex-start;
	}
    .lobbyWrapM {
        background-color: #2F3033;
        min-height: 100%;
    }
    .logoWrap{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 29px;
    }
    .logoWrap img{
        width: 82px;
    }
	.nonSelect{
		opacity: .5;
	}
</style>
