<template>
    <div class="sideMenuInner">
        <div class="centerContents">
            <div class="topContents">
                <div class="row">
                    <div class="tx1">
                        {{ $t('userId') }}
                    </div>
                    <div
                        class="tx2"
                        v-if="loginUser['isWallet']">
                        <a
                            v-bind:href="`https://bscscan.com/address/${userInfo['id']}`"
                            title="Check your Chain Explorer"
                            target="_blank">{{ setCollapsed(userInfo['id'],6,-4) }}</a>
                    </div>
                    <div
                        class="tx2"
                        v-else>
                        {{ setCollapsed(userInfo['id'],6,-4) }}
                    </div>
                </div>
            </div>
            <div class="bar"></div>
            <div class="userInfo">
                <div class="tx2">
                    LV. {{ userInfo['player_level'] }}
                </div>
                <div class="tx2">
                    <img v-bind:src="getGradeImage()"/>
                </div>
                <div class="tx2">
                    <img v-bind:src="getFlagImage()"/>
                    <span>
                        {{ userInfo['currency_code'] }}
                    </span>
                </div>
            </div>
            <div
                class="row"
                v-for="(col, key) in cols"
                v-bind:key="key">
                <div class="tx1">
                    {{ $t(col['title']) }}
                </div>
                <div class="tx2">
                    {{ userInfo[col['key']] || '-' }}
                </div>
            </div>
        </div>
        <div class="botContents">
            <div class="row deposit">
                <div class="tx1 deposit">
                    {{ $t('deposit') }}
                </div>
                <div
                    class="tx2 deposit"
                    v-number-format="userInfo['amount_for_deposit']">
                </div>
            </div>
            <div class="row withdraw">
                <div class="tx1 withdraw">
                    {{ $t('withdraw') }}
                </div>
                <div
                    class="tx2 withdraw"
                    v-number-format="userInfo['amount_for_withdraw']">
                </div>
            </div>
            <div class="row amount">
                <div class="tx1 amount">
                    {{ $t('amount') }}
                </div>
                <div
                    class="tx2 amount"
                    v-number-format="userInfo['amount_retained']">
                </div>
            </div>
        </div>
        <div
            class="walletBox"
            v-if="loginUser['isWallet']">
            <p v-if="isWalletOwn">
                The user who created the Vinus Wallet
            </p>
            <button
                v-if="!isWalletOwn"
                v-on:click="getCreatWallet(); $emit('hideModal'); $emit('showUserInfo', false);">
                Create Sabong Wallet
            </button>
        </div>
    </div>
</template>
<script>
    import { PLAYER_GRADE_IMG_TX, FLAG_IMG } from "@/js/const";
    import { defineComponent } from "vue";
    import { loginUser, err } from '@/js/reactive';
    import { makeWallet, walletOwn, walletAddress, sabongWalletInfo} from "@/js/inGame/sabongWallet.js"
    import { isMobile, setCollapsed } from "@/js/utils";

    export default defineComponent({
        data(){
            return {
                PLAYER_GRADE_IMG_TX: PLAYER_GRADE_IMG_TX,
                FLAG_IMG: FLAG_IMG,
                loginUser: this.$store.getters['login/getLoginUser'],
                isWalletOwn: sabongWalletInfo['walletOwn'],
                userInfo: {},
                cols: [
                    {
                        'key': 'device',
                        'title': 'device'
                    },
                    {
                        'key': 'platform',
                        'title': 'platform'
                    },
                    {
                        'key': 'last_ip',
                        'title': 'lastIp'
                    },
                    {
                        'key': 'login_time',
                        'title': 'loginTime'
                    },
                    {
                        'key': 'register_time',
                        'title': 'registerTime'
                    },
                    {
                        'key': 'address',
                        'title': 'address'
                    },
                    {
                        'key': 'full_name',
                        'title': 'currency'
                    },
                ],
            }
        },
        async mounted() {
            await this.getRank();
            await this.getUserDetail();
        },
        methods: {
            isMobile,
            setCollapsed,
            async getRank(){
                const res = await this.apis.getRank({'type': 0});
                this.userInfo = res['decrypt_data']['value']['myRanking'];
            },
            async getUserDetail(){
                const res = await this.apis.getUserDetail({
                    'userId': this.loginUser['id']
                });
                this.userInfo = Object.assign(this.userInfo, res['decrypt_data']['search']);
            },
            async getCreatWallet(){
                const res = await makeWallet();
                if(res[0] === true) {
                    await walletOwn();
                    await walletAddress();
                    err['msg'] = res[1];
                }else {
                    err['msg'] = res[1];
                }
            },
            getGradeImage(){
                if(!this.userInfo['class']){
                    return;
                }
                return require(`@/assets/sabong/grade/${this.PLAYER_GRADE_IMG_TX[this.userInfo['class']]}`);
            },
            getFlagImage(){
                if(!this.userInfo['currency_code']){
                    return;
                }
                return require(`@/assets/sabong/flag/${this.FLAG_IMG?.[this.userInfo['currency_code']] || this.FLAG_IMG['KOR']}`);
            }
        }
    });
</script>
<style scoped>
    *{
        font-family: 'Folks', sans-serif;
        letter-spacing: .3px;
    }
    .bar{
        width: 97%;
        margin: 0 auto 6px;
        height: 2px;
        background-color: #484848;
    }
    .sideMenuInner{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        justify-content: center;
        padding: 0 10px;
        font-size: 18px;
        margin-top: 13px;
    }
    .sideMenuInner .centerContents{
        padding-bottom: 10px;
    }
    .sideMenuInner .centerContents,
    .sideMenuInner .botContents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #1b1b1b;
        width: 100%;
        border-radius: 6px;
    }

    .withdraw {
        color: #f10000 !important;
    }
    .deposit {
        color: #3ba4e1 !important;
    }
    .amount {
        color: #ffce70 !important;
    }
    .userInfo {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .userInfo .tx2 {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }
    .userInfo .tx2:nth-child(3) img{
        width: 21px;
    }
    .tx1 {
        color: #5ccfff;
        border-right: 2px solid #484848;
    }
    .tx2 {
        margin-left: 15px !important;
        color: white;
    }

    .row {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;
    }

    .topContents .row div:nth-child(1) {
        text-align: center;
        width: 23% !important;
        min-width: 70px !important;
    }
    .topContents .row div:nth-child(2) {
        text-align: center;
        width: 70% !important;
    }
    .centerContents .row div:nth-child(1),
    .botContents .row div:nth-child(1) {
        margin-right: auto;
        margin-left: 10px;
        width: 40%;
    }
    .row div:nth-child(2){
        width: 60%;
        margin-left: auto;
    }

    .sideMenuInner .botContents {
        height: auto;
        padding: 8px 0;
    }


    /*211124 한유정 추가*/
    .myInfoAnother .sideMenuTopBox{
        height: 65px;
    }
    .myInfoAnother .exitImg{
        cursor: pointer;
        position: absolute;
        right: 0;
        margin-right: 3%;
        height: 32px;
    }
    .myInfoAnother .exitImg img{
        width: 32px;
        height: auto;
    }


    .walletBox{
        width: 100%;
        margin-top: 14px;
        text-align: center;
        padding: 0 2%;
    }
    .walletBox p{
        text-align: center;
        font-size: 18px;
        color: #39D530;
    }
    .walletBox button{
        font-size: 18px;
        border-radius: 10px;
        background-image: linear-gradient(73deg, #244dbc 27%, #3ca7e5 83%);
        color: #ffffff;
        width: 250px;
        height: 30px;
        line-height: 30px;
    }


    /* userID 클릭 시 bscscan.com으로이동 */
    .topContents a{
        text-decoration: none !important;
        color: #fff;
        transition: all .5s;
    }
    .topContents a:hover{
        color: #FBDA3C;
    }
</style>