import { loginUser } from '@/js/reactive';
import { checkNaN_Infinity } from "@/js/utils";

const exchangeRate = (rates, loginUser) => {
    let exchangeRates = [];
    rates.forEach(element => element['table'] == 'exchange' ? exchangeRates.push(element) : true);

    exchangeRates = exchangeRates.map(element => {
        const _key = element.code;
        const _rate = Number(element.name);
        const _rateKrw = Number(element.value);
        const obj = {
            key : _key,
            value: {
                'beforeRate' : _rate,
                'afterRate' : _rateKrw
            } };
        return obj;
    });

    let find = exchangeRates.find(rate => rate.key == loginUser.currency);
    if(!find){
        find = exchangeRates.find(rate => rate.key == 'USD');
    }
    return find;
}

const calcRate = (totalValue, rate, betUser) => {
    if(!rate){
        return;
    }
    let rateAmount = betUser && betUser.currency_code == loginUser.currency ? Number(totalValue) : Number(totalValue) / (rate.value['afterRate'] / rate.value['beforeRate']);
    return checkNaN_Infinity(rateAmount).toFixed(2);
}

export {
    exchangeRate,
    calcRate
}