<template>
    <div
        class="backdrop"
        v-on:click.stop="showModal('skin', false)">
    </div>
    <div class="themeModalWrap">
        <div class="modalHeader">
            <span>Theme</span>
            <span class="closeBtn">
                <img
                    src="@/assets/sabong/mobile/icon/btn-close.png"
                    alt="">
            </span>
        </div>
        <ul class="skinSelectModal">
            <li
                v-for="(theme,idx) in themeType"
                v-bind:key="theme"
                v-bind:class="theme"
                v-on:click.stop="changeTheme">
                <input
                    type="radio"
                    name="theme"
                    v-bind:id="theme"
                    v-bind:value="idx"
                    v-bind:checked="activeTheme == idx ? true : false">
                <label v-bind:for="theme">
                    {{theme}}
                </label>
            </li>
        </ul>
    </div>
</template>
<script setup>
    import store from "@/store";
    import {onMounted, ref} from "vue";
    import {useRoute , useRouter} from "vue-router";
    import { showModal } from "@/js/modal/modals";
    const themeType = ['Basic', 'Pro'];

    const route = useRoute();
    const router = useRouter();
    let activeTheme = ref(undefined);
    const changeTheme = async (e) => {
        if(e.target?.tagName === 'INPUT' && Number(e.target.value) !== activeTheme.value) {
            await store.dispatch('theme/changeTheme', e.target.value);
            if(route.name == "inGame"){
                router.go(0);
            }
        }
    }
    onMounted(() => {
        activeTheme.value = store.getters['theme/getTheme'];
    });
</script>
<style scoped>
.backdrop {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.5;
	overflow-y: hidden;
	z-index: 10;
	top: 0;
}
.themeModalWrap{
    width: 95%;
    padding: 10px 10px 32px;
    border: 1px solid #bbb;
    background: #3c3c42;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
}
.themeModalWrap .modalHeader{
    width: 100%;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 12px;
}
.themeModalWrap .modalHeader .closeBtn{
    position: absolute;
    right: 0;
    height: 28px;
}
.themeModalWrap .modalHeader .closeBtn img{
    width: 28px;
    height: 28px;
}
.skinSelectModal{
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    height: 180px;
    padding: 4px;
    background: #1b1b1b;
    overflow-y: auto;
}
.skinSelectModal>li{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    width: 100%;
    height: 48px;
    min-height: 48px;
    background: #1b1b1b;
    border: 2px solid #484848;
    font-size: 16px;
    color: #fff;
    padding: 0 10px;
}
.skinSelectModal>li.checked{
    border: 2px solid #3470ce;
    background-color: #2056af;
}

.skinSelectModal>li input[type='radio'],
.skinSelectModal>li input[type='radio']:checked {
    appearance: none;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 100%;
}

.skinSelectModal>li input[type='radio'] {
    background-color: #bbb;
    border: 2px solid #bbb;
}
.skinSelectModal>li input[type='radio']:checked {
    background-color: #39d530;
    border-color: #39d530;
}
.themeModalWrap button{
    width: 80%;
    height: 40px;
    border: 2px solid #e1e1e1;
    background-image: linear-gradient(to bottom, #6696f6, #2352af);
    color: #fff;
    font-size: 18px;
    margin-top: 32px;
}
</style>