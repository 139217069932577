<template>
    <div
        class="modalInner"
        v-bind:class="{ 'mobile': isMobile() }">
        <ul
            class="btnBox"
            v-bind:class="{ 'mobile': isMobile() }">
            <li
                v-on:click.stop=" tabKey = 'all', requestTransaction() "
                v-bind:class="{
                    'all': tabKey == 'all'
                }">
                {{ $t('all') }}
            </li>
            <li
                v-on:click.stop=" tabKey = 'deposit', requestTransaction() "
                v-bind:class="{
                    'deposit': tabKey == 'deposit'
                }">
                {{ $t('deposit') }}
            </li>
            <li
                v-on:click.stop=" tabKey = 'withdrawal', requestTransaction() "
                v-bind:class="{
                    'withdrawal': tabKey == 'withdrawal'
                }">
                {{ $t('withdrawal') }}
            </li>
        </ul>
        <div class="btnWrap">
            <div class="searchDate">
                <input
                    v-on:change.stop="changeStartDate"
                    type="date"
                    v-bind:value="searchDate['startDate']"
                    v-bind:max="getToday()"
                    read-only
                    required 
                    pattern="\d{4}-\d{2}-\d{2}"
                >
                <p> ~ </p>
                <input 
                    v-on:change.stop="changeEndDate"
                    v-on:click.stop="requestTransaction()"
                    type="date"
                    v-bind:value="searchDate['endDate']"
                    v-bind:max="getToday()"
                    read-only
                    required 
                    pattern="\d{4}-\d{2}-\d{2}"
                >
            </div>
            <button
                v-on:click.stop="requestTransaction()"
                v-on:touchstart.stop="requestTransaction()"
                class="copyBtn">
                {{ $t('search') }}
            </button>
            <select
                v-on:change="selectDays"
                v-if="isMobile()"
            >
                <option value="today">
                    {{ $t('today') }}
                </option>
                <option value="yesterday">
                    {{ $t('yesterday') }}
                </option>
                <option value="week">
                    {{ $t('week') }}
                </option>
                <option value="month">
                    {{ $t('month') }}
                </option>
            </select>
            <button
                v-if="!isMobile()"
                v-on:click.stop="setDays('today')"
                class="searchRange">
                {{ $t('today') }}
            </button>
            <button
                v-if="!isMobile()"
                v-on:click.stop="setDays('yesterday')"
                class="searchRange">
                {{ $t('yesterday') }}
            </button>
            <button
                v-if="!isMobile()"
                v-on:click.stop="setDays('week')"
                class="searchRange">
                {{ $t('week') }}
            </button>
            <button
                v-if="!isMobile()"
                v-on:click.stop="setDays('month')"
                class="searchRange">
                {{ $t('month') }}
            </button>
        </div>
        <ul class="topBox">
            <li v-if="!isMobile()">
                {{ $t('index') }}
            </li>
            <li v-if="tabKey=='all' ">
                {{ $t('type') }}
            </li>
            <li v-if="!isMobile()">
                {{ $t('userId') }}
            </li>
            <li>{{ $t('toTransferCurrency') }}</li>
            <li>{{ $t('transferAmount') }}</li>
            <li v-if="!isMobile()">
                {{ $t('resultAmount') }}
            </li>
            <li>{{ $t('requestStatus') }}</li>
            <li>{{ $t('registerDate') }}(GMT+9)</li>
        </ul>
        <div class="listWrap">
            <div
                class="loading"
                v-if="transHistory == undefined">
                <img
                    src="@/assets/icon_loading.png"
                    alt="">
            </div>
            <p v-if="transHistory?.length == 0">
                There is no information to receive
            </p>
            <ul
                class="list"
                v-for="(data, idx) in transHistory"
                v-bind:key="idx">
                <li v-if="!isMobile()">
                    {{ idx + 1 }}
                </li>
                <li
                    v-if="tabKey == 'all' "
                    v-bind:class="data.type == 1 ? 'deposit' : 'withdrawal' " >
                    {{ data.type == 1 ? $t('deposit') : $t('withdrawal') }}
                </li>
                <li v-if="!isMobile()">
                    {{ loginUser['id'] }}
                </li>
                <li>{{ data['currency_code'] }}</li>
                <li v-numberFormat="data['request_amount']"></li>
                <li
                    v-if="!isMobile()" 
                    v-numberFormat="data['user_after_amount']"></li>
                <li v-bind:class="data['complete_time'] ? 'success' : 'ready'">{{ data['complete_time'] ? data['complete_time'] : 'PENDING' }}
                </li>
                <li>{{ data['request_time'] }}</li>
            </ul>
        </div>
    </div>
</template>
<script setup>
    import { fn } from '@/components/sabong/popup/transaction/transaction';
    import { computed, onMounted, reactive, ref } from "vue";
    import { isMobile } from "@/js/utils";
    // import moment from "moment";
    import { useStore } from "vuex";
    const store = useStore();

    const tabKey = ref('all');

    const loginUser = computed(() => {
        return store.getters['login/getLoginUser'];
    });

    const transHistory = ref(undefined);

    const getToday = (start = null) => {
        let date = new Date(),
            year = date.getFullYear(),
            month = ("0" + (1 + date.getMonth())).slice(-2),
            day = ("0" + ((date.getDate() - start) > 1 ? (date.getDate() - start) : 1)).slice(-2);

        return year + "-" + month + "-" + day;
    }

    // 종료 날짜를 기준으로 단위 계산
    const setStartDate = (number) => {
        const timeStamp = number * 24 * 60 * 60 * 1000;
        let _date = new Date().getTime() - timeStamp,
            date = new Date(_date),
            year = date.getFullYear(),
            month = ("0" + (1 + date.getMonth())).slice(-2),
            day = ("0" + date.getDate()).slice(-2);

        return year + "-" + month + "-" + day;
    }

    const searchDate = reactive({
        startDate: getToday(),
        endDate: getToday()
    })

    const changeStartDate = (e) => searchDate['startDate'] = e.target.value;

    const changeEndDate = (e) => searchDate['endDate'] = e.target.value;

    const setDays = (key) => {
        searchDate['endDate'] = getToday();
        switch(key) {
            case 'today':
                searchDate['startDate'] = setStartDate(0);
                break;
            case 'yesterday':
                searchDate['startDate'] = setStartDate(1);
                break;
            case 'week':
                searchDate['startDate'] = setStartDate(7);
                break;
            case 'month':
                searchDate['startDate'] = setStartDate(30);
                break;
        }
    }

    const requestTransaction = async () => {
        transHistory.value = undefined;

        if (tabKey.value == 'deposit') {
            const res = await fn.depositHistory(searchDate);
            transHistory.value = res?.['decrypt_data']?.['value'].reverse();
        }
        if (tabKey.value == 'withdrawal') {
            const res = await fn.withdrawalHistory(searchDate);
            transHistory.value = res?.['decrypt_data']?.['value'].reverse();
        }
        if (tabKey.value == "all") {
            const deposit = await fn.depositHistory(searchDate);
            const withdrawal = await fn.withdrawalHistory(searchDate);

            transHistory.value = [
                ...deposit?.['decrypt_data']?.['value'],
                ...withdrawal?.['decrypt_data']?.['value']
            ].sort((a, b) => {
                return new Date(a.request_time).getTime() - new Date(b.request_time).getTime();
            })
        }
    }

    // 모바일 select 처리
    const selectDays = (e) => {
        const targetValue = e.target.value;
        console.log(targetValue);
        setDays(targetValue);
    }


    onMounted(async () => {
        await requestTransaction();
    });
</script>
<style scoped>
*{
	font-family: 'Folks', sans-serif;
	letter-spacing: .3px;
	box-sizing: border-box !important;
	font-size: 18px;
}

.modalInner{
	width: 100%;
	padding: 0 6px;
	text-align: center;
	font-size: 0.6vw;
}

.btnBox{
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
	padding: 10px 5px 0 0;
}

.btnBox li{
	width: 160px;
	height: 40px;
	line-height: 40px;
	background-image: linear-gradient(64deg, #4f5a6d 25%, #89959b 85%);
	font-size: 18px;
	color: #cbcbcb;
	text-align: center;
	border-radius: 10px 10px 0 0;
	cursor: pointer;
}

.btnBox li.all{
	border-bottom: 0;
    background-image: linear-gradient(72deg, #244dbc 27%, #bc2424 84%);
	color: #fff;
}
.btnBox li.deposit{
	border-bottom: 0;
	background-image: linear-gradient(64deg, #244dbc 25%, #3ca7e5 85%);
	color: #fff;
}
.btnBox li.withdrawal{
	border-bottom: 0;
    background-image: linear-gradient(73deg, #bc2424 27%, #e467b6 83%);
	color: #fff;
}

.btnWrap{
	background: none !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1%;
	margin: 8px 0;
}
.btnWrap .searchDate {
	display: flex;
	gap: 2%;
}
.btnWrap .searchDate input {
	width: 150px;
    height: 36px;
    line-height: 36px;
    /* background-image: linear-gradient(73deg, #244dbc 27%, #3ca7e5 83%); */
    font-size: 16px;
    border-radius: 10px;
    padding-right: 20px;
}
.btnWrap .searchDate p {
    height: 36px;
    line-height: 36px;
	color: #fff;
    font-size: 24px;
}
.btnWrap button {
	min-width: 60px;
    width: 100px;
    height: 36px;
    line-height: 36px;
    background: transparent;
	color: #fff;
	border: 1px solid #fff;
    font-size: 16px;
    border-radius: 10px;
	transition: all .3s ease-out;
}

.btnWrap button:active {
    background: #fff;
	color: #000;
	border: 1px solid #000;
    font-size: 18px;
}

ul.topBox{
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
}
ul.topBox li{
	color: #5ccfff;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 13px 0;
	font-size: 16px;
	flex: 1;
}

.listWrap{
	width: 100%;
	height: 55vh;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	/* gap: 36px; */
	border-top: 2px solid #484848;
    border-radius: 10px;
    background: #1b1b1b;
	color: #fff;
}
.listWrap .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listWrap ul{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	height: auto;
	color: #ffffff;
    border-radius: 10px;
    padding: 10px 5px;
}

.listWrap ul li{
	display: flex;
	justify-content: center;
	align-items: center;
	height: inherit;
    font-size: 16px;
	text-align: center;
	flex: 1;
	white-space: nowrap;
}

.listWrap ul li.deposit{
    color: #3ba4e1;
}

.listWrap ul li.withdrawal{
    color: #f10000;
}


.modalInner ul.topBox{
	gap: 10px;
}

.modalInner ul li.success,
.modalInner ul li.ready{
	min-height: 46px;
	border-radius: 10px;
}

.modalInner ul li.success{
	border: 2px solid #2056af;
	background-color: #3f82e9;
}
.modalInner ul li.ready{
	border: 2px solid #1d7713;
	background-color: #22ac30;
}

/* userID 클릭 시 bscscan.com으로이동 */
.list a{
	text-decoration: none !important;
	color: #fff;
	transition: all .5s;
}
.list a.BNB:hover{
	color: #FBDA3C;
}
.list a.ETH:hover{
	color: #21325b;
}

input[type="date"] {
    position: relative;
    text-align: right;
    padding: 0 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator { 
    position: absolute;
    width: 100%;
    height: 80%;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
} 


/*mobile*/


.modalInner.mobile{
	padding: 12px 10px;
	font-size: 16px;
}

.modalInner.mobile h3{
	margin: 0 0 8px 0;
	font-size: 18px;
}

.btnBox.mobile{
	padding: 0 4px;
}

.btnBox.mobile li{
	width: 33.3%;
	height: 36px;
	line-height: 36px;
	font-size: 16px;
}

.btnBox.mobile li.active{
	border-bottom: 0;
	background-image: linear-gradient(to bottom, #6696f6, #2352af);
}

.modalInner.mobile .btnWrap {
    height: 40px;
    padding: 8px 5px;
    margin: 4px 0;
}

.modalInner.mobile .btnWrap .searchDate {
    align-items: center;
}

.modalInner.mobile .btnWrap input[type=date]{
    width: 94px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    font-family: Folks;
    font-size: 12px;
    padding: 0;
}

.modalInner.mobile .btnWrap input[type="date"]::-webkit-calendar-picker-indicator { 
    left: -2px;
} 

.modalInner.mobile .btnWrap button{
    width: 64px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    font-family: Folks;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 5px;
}

.modalInner.mobile .btnWrap select{
    width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    font-family: Folks;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    background: transparent;
    border: 1px solid #fff;
}
.modalInner.mobile .btnWrap select option {
    background: black;
    color: #fff;
    padding: 3px 0;
    font-size: 14px;
}

.modalInner.mobile .topBox li{
    max-width: 20%;
    font-size: 12px;
    
}
.modalInner.mobile .listWrap .list li{
    max-width: 20%;
    font-size: 13px;
}

.modalInner.mobile .listWrap .list li:last-child,
.modalInner.mobile .listWrap .list li:nth-last-child(2){
    word-break: break-all;
    white-space: pre-line;
}


</style>