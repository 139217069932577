import axios from "axios";
import router from "@/router/index";
import { encryptData, decryptData, getCookie, encryptMD5, getTomhornDateFormat } from "@/js/utils";
import { agentInfo, loading, err } from "@/js/reactive";
import { ERR_CODE } from "@/js/const";
import { showModal } from "@/js/modal/modals";
import store from "@/store";
import { apis } from "@/repository/repository";
import { logout } from "@/js/common";

const AXIOS_URL = {
    "sabong.jgpot.com": process.env.VUE_APP_AXIOS_BASE_URL1,
    "vsabongservice.com": process.env.VUE_APP_AXIOS_BASE_URL2,
    "kinggalaxyinteractive.com": process.env.VUE_APP_AXIOS_BASE_URL3,
    "kg-365.com": process.env.VUE_APP_AXIOS_BASE_URL3_1,
    "vinuscockxing.com": process.env.VUE_APP_AXIOS_BASE_URL4,
    "vinus-inter.com": process.env.VUE_APP_AXIOS_INTER_URL1,
    "vinusinter.com": process.env.VUE_APP_AXIOS_INTER_URL2,
    "127.0.0.1": process.env.VUE_APP_AXIOS_BASE_URL4,
    "localhost:8089": process.env.VUE_APP_AXIOS_BASE_URL1,
}

let baseHeader = {};
let isError = false;

// VINUS LIVE AXIOS
export const vinusAxios = axios.create({
    //baseURL: AXIOS_URL?.[location.hostname] || process.env.VUE_APP_AXIOS_BASE_URL1,
    //baseURL: process.env.VUE_APP_AXIOS_BASE_URL1,
    baseURL: process.env.VUE_APP_AXIOS_BASE_URL1,
    headers: {
        "publisher-private-key": "C105E791-F4F8-48C8-AC83-B550B3871C8A",
        "client-game-id": "C80A3C3E-99EF-4C37-819D-6E92A96AA1FA",
        "client-version-type": 1000000001,
        "Content-Type": "application/json, application/x-www-form-urlencoded",
    },
    withCredentials: true,
    timeout: 20000
});

const NOT_INCLUDE_PK_URL = [
    '/api/certification/signin'
]

vinusAxios.interceptors.request.use((req) => {
    let isLoginValid = store.getters['login/getLoginUser'].time ? true : false;
    if(req && req['data']?.['pass']){
        isLoginValid = true;
        delete req['data']['pass'];
    }
    if(!isLoginValid){
        return Promise.reject('로그인이 필요합니다.');
    }
    if (getCookie('pk') != '' && NOT_INCLUDE_PK_URL.indexOf(req.url) == -1) {
        req["headers"]["agent-private-key"] = getCookie('pk');
    }
    if (req && req["data"]) {
        let params = {};
        if(isEncryptUrl(req)){
            Object.entries(req["data"]).forEach((data) => {
                if(data[0] == 'demo'){ // 톰혼 데모 URL로 접속하여 로그인하는경우(데모),
                    req["headers"]["agent-private-key"] = process.env.VUE_APP_TOMHORN_DEMO_AGENT_KEY;
                } else if(data[0] == 'real') { // 톰혼 데모 URL로 접속하여 로그인하는경우(리얼플레이),
                    req["headers"]["agent-private-key"] = process.env.VUE_APP_TOMHORN_REAL_AGENT_KEY;
                } else if (data[0] == 'wallet') {
                    req["headers"]["agent-private-key"] = process.env.VUE_APP_SABONG_WALLET_AGENT_KEY;
                } else {
                    params[data[0]] = encryptData(data[1]);
                }
            });
            req["data"] = JSON.stringify(params);
        }
    }
    return req;
},(error) => {
    return Promise.reject(error);
});

vinusAxios.interceptors.response.use(
    async (res) => {
        if(res && res['status'] === 200
            && res['data']?.['Code'] == 0 || res['data']?.['code']?.['logic'] == 0){
            if(res['data']?.['Data']?.['process'] == 'error'){
                err['msg'] = res['data']['Data']['result'];
                return Promise.reject(res);
            }
            if(res["data"]["decrypt_data"]){
                res["data"]["decrypt_data"] = decryptData(res["data"]["encrypt_data"]);
            }
        } else {
            err['msg'] = res['data']['result'] || res['data']['Message'] || ERR_CODE[res['data']['code']['logic']];
            if(res.data?.code){
                if(res['data']['code']['logic'] == '10000002'
                    || res['data']['code']['logic'] == '10000005'
                    || res['data']['code']['logic'] == '200000001') {
                    logout(true);
                }
            }
            return Promise.reject(res);
        }
        completeLoading();
        return res.config.url.indexOf('/api/certification') > -1 ? res.data : res?.data?.Data || res?.data;
    },
    (error) => {
        completeLoading();
        isError = true;
        return Promise.reject(error);
    }
);

// VINUS LIVE 영상 스트리밍
export const streaming = axios.create({
    baseURL : process.env.VUE_APP_STREAMING_URL
});

// AXIOS 요청시 로딩컴포넌트 보여주기 여부
const completeLoading = () => {
    Object.keys(loading).forEach(key => {
        loading[key] = false;
    });
    showModal('loading', false);
}

/**
 * @name: 암호화할 API URL 체크
 * @param: Request/Response Object
 */
const isEncryptUrl = (re) => {
    try{
        let noEncrypt = ['/sabong', '/api/vinusgaming'];
        let url = re?.config?.url || re?.url;
        return noEncrypt.find(noEnc => url.indexOf(noEnc) > -1) ? false : true;
    }catch(e){
        throw `error : ${ e }`;
    }
}
