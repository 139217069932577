<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('menu') }}</h1>
            <span class="exitImg">
                <img
                    src="../../assets/mobile/btn-close.png"
                    alt="close"
                    v-on:click.stop="$emit('hideSideMenu')">
            </span>
        </div>
        <div
            class="sideMenuInner"
            v-if="!showing">
            <ul>
                <li
                    v-for="(menu, key) in menus"
                    v-bind:key="key"
                    v-on:click.stop="menu['click'](menu['key'])">
                    <img
                        v-bind:src="menu['src']"
                        v-bind:alt="menu['key']">
                    <h4>{{ $t(menu['key']) }}</h4>
                </li>
            </ul>
        </div>
    </div>
    <Shop
        v-if="modalKey === 'shop'"
        v-on:showModal="showModal"
        v-on:getEquipDealer="$emit('getEquipDealer')"/>
    <BetLimit
        v-if="modalKey == 'betLimit'"
        v-on:showModal="showModal"
        v-bind:betMinMax="betMinMax"/>
    <ModalHistory
        v-if="modalKey == 'history'"
        v-on:showModal="showModal"/>
    <MemberList
        v-if="modalKey == 'memberList'"
        v-on:showModal="showModal"/>
    <Chat
        v-if="modalKey == 'chat'"
        v-on:showModal="showModal"/>
    <keep-alive>
        <Sound
            v-if="modalKey == 'sound'"
            v-on:showModal="showModal"/>
    </keep-alive>
    <Tutorial
        v-if="modalKey == 'tutorial'"
        v-on:showModal="showModal"/>
    <keep-alive>
        <Video
            v-if="modalKey == 'video'"
            v-on:showModal="showModal"
            v-on:offCam="offCam"/>
    </keep-alive>
</template>
<script>
    import { defineComponent } from "vue";
    import BetLimit from "@/components/mobile/popup/BetLimit";
    import ModalHistory from "@/components/popup/ModalHistory";
    import Chat from "@/components/mobile/popup/Chat";
    import Sound from "@/components/mobile/popup/Sound";
    import Tutorial from "@/components/mobile/popup/Tutorial";
    import Video from "@/components/mobile/popup/Video";
    import Shop from "@/components/mobile/popup/Shop";
    import MemberList from "@/components/mobile/popup/MemberList";

    //메뉴 종류 추가해주세요
    //MyInfo(btn-user-info.png),DealerInfo(btn-dealerinfo.png),MemberList(btn-user-list.png)
    export default defineComponent({
        components: {
            BetLimit,
            ModalHistory,
            Chat,
            Sound,
            Tutorial,
            Video,
            Shop,
            MemberList
        },
        props: ['betMinMax'],
        data(){
            return {
                modalKey: '',
                showing: false,
                menus: [
                    {
                        'key': 'betLimit',
                        'src': require('@/assets/icons/ingame_top_menu/icon-bet-limit.png'),
                        'click': (key) => {
                            this.showModal(key);
                        }
                    },
                    {
                        'key': 'history',
                        'src': require('@/assets/icons/ingame_top_menu/icon-history.png'),
                        'click': (key) => {
                            this.showModal(key);
                        }
                    },
                    {
                        'key': 'chat',
                        'src': require('@/assets/icons/ingame_top_menu/icon-chat.png'),
                        'click': (key) => {
                            this.showModal(key);
                        }
                    },
                    {
                        'key': 'video',
                        'src': require('@/assets/icons/ingame_top_menu/icon-video.png'),
                        'click': (key) => {
                            this.showModal(key);
                        }
                    },
                    {
                        'key': 'sound',
                        'src': require('@/assets/icons/ingame_top_menu/icon-sound.png'),
                        'click': (key) => {
                            this.showModal(key);
                        }
                    },
                    {
                        'key': 'tutorial',
                        'src': require('@/assets/icons/ingame_top_menu/icon-help.png'),
                        'click': (key) => {
                            this.showModal(key);
                        }
                    },
                    {
                        'key': 'shop',
                        'src': require('@/assets/btn/btn-shop.png'),
                        'click': (key) => {
                            this.showModal(key);
                        }
                    },
                    {
                        'key': 'memberList',
                        'src': require('@/assets/icons/ingame_top_menu/btn-user-list.png'),
                        'click': (key) => {
                            this.showModal(key);
                        }
                    },
                    /*  {
                        'key': 'dealerInfo',
                        'src': require('@/assets/icons/ingame_top_menu/btn-dealerinfo.png'),
                        'click': (key) => {
                            this.showModal(key);
                        }
                    },*/

                ],
            }
        },
        methods: {
            showModal(key){
                this.modalKey = key;
                this.showing = key != '' ? true : false;
                //this.$emit('hideSideMenu');
            },
            offCam(isOnoff){
                this.$emit('offCam', isOnoff)
            },
            setEquipDealerImage(image){
                this.$emit('setEquipDealerImage', image);
            }
        }
    });
</script>
<style scoped>
.sideMenuBox{
	position: absolute;
	right: 0;
	width: 75vw;
	color: #fff;
	font-size: 14px;
	border: 1px solid #bbbbbb;
	z-index: 10;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 20px;
}
.sideMenuInner{
	padding: 1.5%;
	background-color: #2e3033;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 3%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/* 내용 */
.sideMenuInner ul li{
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
	padding: 12px;
	font-size: 17px;
}
.sideMenuInner img{
	width: 39px;
	height: auto;
	max-width: 100%;
}
</style>