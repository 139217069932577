<template>
    <div class="sideMenuBox">
        <div class="sideMenuInner">
            <ul>
                <li
                    v-bind:class="{ 'selected': lang['key'] == i18n.locale }"
                    v-bind:key="key"
                    v-on:click.stop="changeLang(lang['key'])"
                    v-for="(lang, key) in langs">
                    <img
                        v-bind:src="lang['img']"
                        alt="english">
                    <h3>{{ $t(lang['key']) }}</h3>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup>
    import { useI18n } from 'vue-i18n';
    import { ref } from "vue";
    import { useStore } from "vuex";

    const i18n = ref(useI18n());
    const store = useStore();
    const locale = 'en';

    const langs = [
        {
            'key': 'en',
            'img': require(`@/assets/locale/flag-english.png`),
        },
        {
            'key': 'th',
            'img': require(`@/assets/locale/flag-thai.png`),
        },
        {
            'key': 'sp',
            'img': require(`@/assets/locale/flag-spanish.png`),
        },
        {
            'key': 'vt',
            'img': require(`@/assets/locale/flag-vietnamese.png`),
        },
        {
            'key': 'hi',
            'img': require(`@/assets/locale/flag-hindi.png`),
        },
        {
            'key': 'ko',
            'img': require(`@/assets/locale/flag-korean.png`),
        },
        {
            'key': 'ja',
            'img': require(`@/assets/locale/flag-japanese.png`),
        },
        {
            'key': 'ch',
            'img': require(`@/assets/locale/flag-chinese.png`),
        }
    ];

    const changeLang = (locale) => {
        store.commit('modal/initModal');
        i18n.value.locale = locale;
    }
</script>
<style scoped>
*{
    font-family: 'Folks', sans-serif;
    letter-spacing: .3px;
}
.sideMenuBox{
    width: 100%;
    height: 100%;
    padding-bottom: 50%;
    color: #fff;
    font-size: 14px;
}
.sideMenuInner{
    padding: 3%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.sideMenuInner ul{
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 18px 18px;
    margin-top: 10vh;
}
.sideMenuInner ul li{
    background-color: #262828;
    width: 45%;
    height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.sideMenuInner ul li img{
    width: 60px;
    height: auto;
    max-width: 100%;
}
.selected {
	background-color: #384f75 !important;
}
</style>