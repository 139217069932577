<template>
    <video
        ref="video"
        v-bind:class="{ 'zoom': isMobile() }"
        class="iframe100"
        autoplay
        playsinline></video>
</template>

<script>
    import { defineComponent } from "vue";
    import { isMobile } from '@/js/utils';
    import { streaming } from "@/plugins/axios";
    import { timer, iscamOff } from "@/js/reactive";
    import { useStore, mapGetters } from "vuex";
    import 'webrtc-adapter';

    export default defineComponent({
        data() {
            return {
                iscamOff: iscamOff,
                store:useStore(),
                url: '',
                jwt: '',
                apiPath: 'https://director.millicast.com/api/director/subscribe',
                turnUrl: 'https://turn.millicast.com/webrtc/_turn',
                iceServers: [],
                accountId : "xKjbpa",
                timer: timer
            }
        },
        props: ['streamName', 'isSabong'],
        mounted() {
            //this.SetStreaming();
            if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
                this.ready();
            } else {
                document.addEventListener('DOMContentLoaded', this.ready);
            }
        },
        watch: {
            getVideoOn: {
                immediate: true,
                handler(val){
                    let vidWin = document.getElementsByTagName('video')[0];
                    if(vidWin){
                        vidWin.volume = val;
                    }
                }
            }
        },
        computed: {
			...mapGetters(['getVideoOn'])
        },
        unmounted() {
            clearTimeout(this.timer['reCamConnect']);
        },
        deactivated() {
            clearTimeout(this.timer['reCamConnect']);
        },
        methods: {
            isMobile,
            async connect() {
                if (!this.url) {
                    await this.SetStreaming();
                }
            },
            getICEServers() {
                return new Promise((resolve, reject) => {
                    let xhr                = new XMLHttpRequest();
                    xhr.onreadystatechange = function (evt) {
                        if (xhr.readyState == 4) {
                            let res = JSON.parse(xhr.responseText), a;
                            console.log('getICEServers::status:', xhr.status, ' response: ', xhr.responseText);
                            switch (xhr.status) {
                                case 200:
                                    //returns array.
                                    if (res.s !== 'ok') {
                                        a = [];
                                        //failed to get ice servers, resolve anyway to connect w/ out.
                                        resolve(a);
                                        return
                                    }
                                    var list = res.v.iceServers;
                                    a        = [];
                                    //call returns old format, this updates URL to URLS in credentials path.
                                    list.forEach(cred => {
                                        let v = cred.url;
                                        if (!v) {
                                            cred.urls = v;
                                            delete cred.url;
                                        }
                                        a.push(cred);
                                        //console.log('cred:',cred);
                                    });
                                    console.log('ice: ', a);
                                    resolve(a);
                                    break;
                                default:
                                    a = [];
                                    //reject(xhr.responseText);
                                    //failed to get ice servers, resolve anyway to connect w/ out.
                                    resolve(a);
                                    break;
                            }
                        }
                    }
                    xhr.open("PUT", this.turnUrl, true);
                    xhr.send();
                });
            },
            ready(){
                let v = document.getElementsByTagName('video')[0];
                if (v) {
                    v.addEventListener("click", evt => {
                        v.play();
                    });
                }
                //connect();

                // get a list of Xirsys ice servers.
                this.getICEServers()
                    .then(list => {
                        this.iceServers = list;
                        //ready to connect.
                        this.connect();
                    });
            },
            async SetStreaming() {
                try {
                    let params = {}
                    if(this.isSabong){
                        params['streamAccountId'] = "fccb8u";
                        params['streamName'] = `ku091m3y`;
                    } else {
                        params['streamAccountId'] = "xKjbpa";
                        params['streamName'] = `Table${this.streamName}`;
                        params['unauthorizedSubscribe'] = true;
                    }
                    const streamingToken = await streaming.post("/subscribe", params);
                    this.url = `${streamingToken.data.data.wsUrl}?token=${streamingToken.data.data.jwt}`;
                    this.connectWebRTC();
                    this.iscamOff = false;
                    this.$store.commit('setIsWebRTCOn', true);
                } catch(e) {
                    // 연결 못 붙을시 webrtc 재연결.
                    this.iscamOff = true;
                    this.$store.commit('setIsWebRTCOn', false);
                    this.timer['reCamConnect'] = setTimeout(() => {
                        this.SetStreaming();
                        clearTimeout(this.timer['reCamConnect']);
                    }, 5000);
                }
            },
            connectWebRTC(){
                console.log('connecting to: ', this.url);
                //create Peer connection object
                let conf = {
                    iceServers:    this.iceServers,
                    // sdpSemantics : "unified-plan",
                    rtcpMuxPolicy: "require",
                    bundlePolicy:  "max-bundle"
                };
                console.log('config: ', conf);
                let THIS = this;
                let pc     = new RTCPeerConnection(conf);
                //Listen for track once it starts playing.
                pc.ontrack = function (event) {
                    console.debug("pc::onAddStream", event);
                    THIS.$store.commit('setIsWebRTCOn', true);
                    //Play it
                    let vidWin = document.getElementsByTagName('video')[0];
                    if (vidWin) {
                        vidWin.srcObject = event.streams[0];
                    }
                };
                console.log('connecting to: ', this.url + '?token=' + this.jwt);//token
                //connect with Websockets for handshake to media server.
                let ws    = new WebSocket(this.url);
                ws.onopen = function () {
                    //Connect to our media server via WebRTC
                    console.log('ws::onopen');
                    //if this is supported
                    /* if (pc.addTransceiver) {
						console.log('transceiver!');
						//Create dummy stream
						const stream = new MediaStream();
						//Create all the receiver tracks
						pc.addTransceiver("audio",{
							direction       : "recvonly",
								streams         : [stream]
						});
						pc.addTransceiver("video",{
							direction       : "recvonly",
								streams         : [stream]
						});
					} */

                    //create a WebRTC offer to send to the media server
                    let offer = pc.createOffer({
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true
                    }).then(desc => {
                        console.log('createOffer Success!');
                        //set local description and send offer to media server via ws.
                        pc.setLocalDescription(desc)
                            .then(() => {
                                console.log('setLocalDescription Success!');
                                //set required information for media server.
                                let data    = {
                                    streamId: this.accountId,//Millicast accountId
                                    sdp:      desc.sdp
                                }
                                //create payload
                                let payload = {
                                    type:    "cmd",
                                    transId: 0,
                                    name:    'view',
                                    data:    data
                                }
                                console.log('send ', payload);
                                ws.send(JSON.stringify(payload));
                            })
                            .catch(e => {
                                console.log('setLocalDescription failed: ', e);
                            })
                    }).catch(e => {
                        console.log('createOffer Failed: ', e)
                    });
                }

                ws.addEventListener('message', evt => {
                    console.log('ws::message', evt);
                    let msg = JSON.parse(evt.data);
                    switch (msg.type) {
                        //Handle counter response coming from the Media Server.
                        case "response":
                            var data   = msg.data;
                            var remotesdp = data.sdp;
                            /* handle older versions of Safari */
                            if (remotesdp && remotesdp.indexOf('\na=extmap-allow-mixed') !== -1) {
                                remotesdp = remotesdp.split('\n').filter(function (line) {
                                    return line.trim() !== 'a=extmap-allow-mixed';
                                }).join('\n');
                                console.log('trimed a=extmap-allow-mixed - sdp \n',remotesdp);
                            }
                            var answer = new RTCSessionDescription({
                                type: 'answer',
                                sdp:  remotesdp
                            });

                            pc.setRemoteDescription(answer)
                                .then(d => {
                                    console.log('setRemoteDescription  Success! ');
                                })
                                .catch(e => {
                                    console.log('setRemoteDescription failed: ', e);
                                });
                            break;
                    }
                });
            }
        }
    });
</script>
<style scoped>
	.iframe100 {
		z-index: -1;
		display: block;
		width: 100%;
		height: 100%;
		border: 0;
	}
	.iframe100.zoom {
		-moz-transform:scale(1.2);
		-webkit-transform:scale(1.2);
		-o-transform:scale(1.2);
		-ms-transform:scale(1.2);
		transform:scale(1.2);
	}
</style>