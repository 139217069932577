import { reactive, computed } from 'vue';

const state = () => reactive({
    modalKey: undefined,
    modals: {},
    options: {},
    showSideMenu: false
});

const mutations = {
    showModal: (state, payload) => {
        state.modalKey = payload.modalKey;
        state.modals[payload.modalKey] = payload.open;
        state.options[payload.modalKey] = {
            styles: payload.styles || {},
            showHeader: payload.showHeader || true
        };
    },
    showSideMenu: (state, payload) => {
        state.showSideMenu = payload;
    },
    initModal: (state) => {
        state.modalKey = undefined;
        state.showSideMenu = false;
        state.modals = {};
        state.options = {};
    }
}

const getters = {
    isModalOpen: (state) => {
        return Object.values(state.modals)?.filter(bool => bool == true)?.length > 0;
    },
    isSideMenuOpen: (state) => {
        return state.showSideMenu;
    },
    getModalOpt: (state) => {
        return state.options[state.modalKey];
    },
    getModalKey: (state) => {
        return state.modalKey;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}