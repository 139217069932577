<template>
    <div
        class="interface"
        v-for="(inter, key) in interfaces['cam']"
        v-bind:key="key"
        v-bind:class="{ 'selected': selectedVideo == inter['key'] }"
        v-on:click.stop="inter['click'](inter['key'])">
        <div class="left">
            ·{{ $t(inter['key']) }}
        </div>
    </div>
</template>
<script>
    import { iscamOff } from "@/js/reactive";
    import { defineComponent } from "vue";
    import { mapGetters } from "vuex";

    export default defineComponent({
        data() {
            return {
                iscamOff: iscamOff,
                selectedVideo: 'on',
                interfaces: {
                    cam: [
                        {
                            'key': 'on',
                            'value': '',
                            'click': () => {
                                this.selectedVideo = 'on';
                                this.iscamOff = false;
                            }
                        },
                        {
                            'key': 'off',
                            'value': '',
                            'click': () => {
                                this.selectedVideo = 'off';
                                this.iscamOff = true;
                            }
                        }
                    ]
                },
            }
        },
        computed: {
			...mapGetters(['getIsWebRTCOn'])
        },
        watch: {
            // webrtc 재요청에서 연결 확인시, 자동으로 캠 ON
            getIsWebRTCOn: {
                immediate: true,
                handler(isOn){
                    if(isOn){
                        this.interfaces['cam'][0]['click']();
                    } else {
                        this.interfaces['cam'][1]['click']();
                    }
                }
            },
        },
    })
</script>
<style scoped>
	.interface {
		display: flex;
		flex-direction: row;
		height: 60px;
		font-size: 15px;
		color: var(--inGame-interface-font-color);
		font-weight: bold;
		align-items: center;
		position: relative;
		z-index: 9999;
		cursor: pointer;
		width: 100%;
		justify-content: center;
	}
	.interface.selected {
		color: var(--inGame-interface-selected-font-color);
	}
    .interface .left:lang(ko){
        white-space: nowrap;
    }

    /*211119 한유정 추가*/
    .interface:nth-child(4) > .right{
        display: none;
    }
    .cam > .interface > div{
        text-align: center;
        width: 50%;
    }
</style>