import store from "@/store";
import { themeApis} from '@/repository/repository';
import router from "@/router";
import { err } from "@/js/reactive";
import { routeName, enterSabongRoom } from "@/router/dynamicRoute";
import { nextTick } from "vue";
import { toTypeString } from "@vue/shared";
import { sabongInfo } from "../js/inGame/sabongInter";
import { isMobile, getCookie, encryptDataV2 } from "@/js/utils";


export const setTheme = (to, from, next) => {
    // live baccarat일 경우 return
    if(process.env.VUE_APP_DEPLOY_GAME_TYPE !== 'sabong') return next();

    // let hostName =  location.hostname;
    // let theme = undefined;
    
    // if(process.env.NODE_ENV == 'development') {
    //     // dev default는 vgsabong
    //     theme = 1;
    // } else {
    //     // production 일때는 테마 설정값 정해주기 ( vsabongservice , kinggalaxy)
    //     // theme = hostName.indexOf('vgsabong') > -1 ? 0 : 1;
    // }
    let theme = 1;
    store.commit('theme/changeTheme' , theme);
    routeTheme(theme);
    next();
}


// 현재 적용중인 테마 불러오기
export const activeTheme = async (to, from) => {
    if(!store.state.theme.userUuid){
        store.commit('theme/setUuid', store.getters['login/getLoginUser'].uuid);
    }
    if(store.state.theme.userUuid && store.getters['login/getLoginUser'].uuid){
        const theme = await themeApis.getTheme(store.state.theme.userUuid);
        store.commit('theme/changeTheme' , theme.Data?.theme);
        const themeRouter = routeTheme(theme?.Data?.theme).valid;
        /**
         * 이거 주석 아닙니다.
         */
        /*eslint no-extra-boolean-cast: "off"*/
        if(!!!themeRouter){
            const path = to.path;
            if(path.substr(0,path.lastIndexOf("/")) == "/inGame"){
                const params = path.substr(path.lastIndexOf("/") + 1);
                await router.push({
                    name : "inGame",
                    params : {
                        "gameType" : params
                    }
                })
            }
        }
    }
}

export const routeTheme = (number) => {
    let themeName = "";
    switch(number) {
    case 0:
        themeName = routeName['InGameBasic'];
        break;
    case 1:
        themeName = routeName['InGamePro'];
        break;
    }
    let _theme = validRoute(themeName.name);
    router.addRoute(themeName);
    return {valid : _theme , name : themeName.name};
}

export const validRoute = (name) => {
    return router.hasRoute(name);
}