import { currencyInfo, loginUser, timer, sabongChips } from "@/js/reactive";
import router                   from "@/router";
import { apis, walletApis }                 from "@/repository/repository";
import {CARD_MARK_ARRAY, CARD_INFO_ARRAY, PLAYER_GRADE_IMG, FLAG_IMG, DOMAIN_AGENT_KEY } from "@/js/const";
import { chat } from "@/plugins/mqtt";
import {setCookie, getCookie, deleteCookie, encryptData, decryptUrlData, decryptData} from "@/js/utils";
import moment from "moment";
import { setting as metamask } from "@/js/wallet/metamask/setting";
import { setting as wc } from "@/js/wallet/walletConnect/setting";
import { modals, showModal } from "@/js/modal/modals";
import store from "@/store";
import { exchangeRate } from '@/js/inGame/exchange';

// import  { getReferralMemberList, getMyRefferal } from "@/js/reffer/reffer";

/**
 * @name: 브라우저가 닫힐때 초기화해주는 함수
 */
export const initData = () => {
    store.commit('modal/initModal');
}

/**
 * 바카라 방 입장
 * @param room
 * @param gameType
 * @returns
 */
export const enterRoom = (room, gameType) => {
    let params = {};
    if(gameType == 'baccarat'){
        params = {
            roomNo: room['room_no'],
            tableNo: room['table_id'].replace('Table', ''),
            index: room['index'],
            room: JSON.stringify(room)
        }
    }
    router.push({ name: 'inGameLive', params: {...params }});
}

/**
 * 카드 결과 가져오기
 * @param board{key:String, result:index}
 * @param order{index 카드표시 순서}
 * @returns index
 */
export const getCardResult = (board, order) => {
    let marks = {
        '♠': 'card-spades.png',
        '♣': 'card-clubs.png',
        '♥': 'card-hearts.png',
        '◆': 'card-diamonds.png',
    }
    return {
        'mark': marks[CARD_MARK_ARRAY[board['result'][order]]],
        'rst':  board['result'][order],
        'color': board['result'][order] < 26 ? 'black' : 'red',
        'tx': CARD_INFO_ARRAY[board['result'][order]]
    };
}

/**
 * @name: 통화 심볼 가져오기
 * @param: 통화 심볼
 */
export const getSymbolImageFileName = (symbol) => {
    let symbols = {
        '₹': 'inr.png',
        '₩': 'krw.png',
        '$': 'usd.png',
        '¥': 'cny.png',
        '₱': 'php.png',
        '฿': 'thb.png',
        '₫': 'vnd.png'
    }
    return symbols[symbol];
}

/**
 * @name: 딜러 랭킹 계산
 * @param: 딜러리스트 Array
 */
export const calcDealerRank = (dealers) => {
    let rankList = dealers.filter(rank => rank['rank'] != '-');
    return rankList.sort((a, b) => {
        return b['weight'] - a['weight'];
    });
}

/**
 * @name: 로비에서 방 MINMAX 정보 가져오기
 * @param: MIN/MAX 정보들 Object
 * @param: MIN/MAX 배팅항목 String
 * @param: 배팅항목순서 String
 */
export const getMinMaxs = (obj, key, order) => {
    let split = obj[key].split(':');
    return split[order];
}

/**
 * @name: 채팅 보내기
 * @param: Vue Component this
 */
export const sendMessage = (THIS) => {
    if(THIS.msg == ''){
        return;
    }
    chat(`${THIS.msg}/${moment().format('HH:mm')}/${getCookie('loginId')}/${THIS.$route['params']['roomNo']}`);
    THIS.msg = '';
}

export const logout = async (noRequest) => {
    if(!noRequest){
        await apis.logout();
    }
    loginUser['uuid'] = undefined;
    store.commit('modal/initModal');
    store.commit('login/setLoginUser', {});
    clearLoginCookie();
    router.push('/');
}

export const logoutForQueryLogin = async (userId = undefined) => {
    if(userId) {
        await apis.logout();
    }
    loginUser['uuid'] = undefined;
    store.commit('modal/initModal');
    store.commit('login/setLoginUser', {});
    clearLoginCookie();
}


const clearLoginCookie = () => {
    deleteCookie('loginId');
    deleteCookie('uuid');
    deleteCookie('currency');
    deleteCookie('pk');
}

/**
 * @name: 게이트웨이에서 /play url로 접근시, 사용하는 로그인 함수
 */
export const gameLauncherLogin = async (userInfo) => {
    try{
        let userId = decryptData(userInfo.user_id);
        let params = {
            user_id  : userId,
            password : decryptData(String(userInfo.password)),
            grade    : "1",
            device   : 0,
            version  : "",
        };
        if(userInfo?.cms){
            params['cms'] = true;
        }
        const res = await apis.login(params);
        if (res?.decrypt_data?.value?.agent_private_key) {
            setCookie('pk', res?.decrypt_data?.value?.agent_private_key);
        }
        if (res?.decrypt_data?.is_success) {
            setCookie('currency', res["decrypt_data"]["value"]["sessionData"]["account"]["currency_code"]);
            setCookie('uuid', res["decrypt_data"]["value"]["sessionData"]["account"]["uuid"]);
            loginUser["uuid"]     = res["decrypt_data"]["value"]["sessionData"]["account"]["uuid"];
            loginUser["id"]       = userId;
            loginUser['time']     = new Date().getTime();
            loginUser['currency'] = res["decrypt_data"]["value"]["sessionData"]["account"]["currency_code"];
            store.commit('login/setLoginUser', {
                ...loginUser
            });
            store.commit('theme/setUuid', loginUser['uuid']);
            showModal('login', false);
            setCookie('loginId', userId);
            await router.push('/lobby');
        } else {
            return res.decrypt_data;
        }
    }catch(e){
        console.log(e);
    }
}

/**
 * 비누스 라이브 로그인
 * @param name
 * @param password
 * @param 아이디 기억 여부
 * @param fadeAnim 로그인시 화면전환 페이드 애니메이션 콜백함수
 * @param demoLogin 톰혼에서 게임런처로 데모접속여부
 * @param token 톰혼에서 리얼플레이로 접근하는 토큰
 * @param walletType 월렛로그인시 전달되는 스트링값 metamask or wc
 * @returns {Promise<void>}
 */
export const login = async (name, password, isRememberId , fadeAnim, demoLogin, token, walletType) => {
    if(name == '' || password == ''){
        alert('Please input your ID or Password');
        return;
    }
    let params = {
        user_id  : name,
        password : password,
        grade    : "1",
        device   : 0,
        version  : "",
    };
    // 추천인 body에 referral_code
    if(store.getters['login/getRefferCode']) {
        params['referral_code'] = store.getters['login/getRefferCode'];
    }

    if(demoLogin){
        params['demo'] = true;
    }
    if(walletType){
        params['wallet'] = true;
    }
    let headers = {}
    if(isRememberId == 'normalSignup'){
        headers['agent-private-key'] = DOMAIN_AGENT_KEY[location.hostname];
    }
    // 추천인 headers에 referral_code
    if(store.getters['login/getPrivateKey']) {
        headers['agent-private-key'] = store.getters['login/getPrivateKey'];
    }
    // 톰혼유저일경우(리얼모드)
    if(demoLogin == false){
        setCookie('isTh', encryptData('areyoukiddingme'));
        params['real'] = true;
        params['token'] = token;
        params['etc'] = 1;
    }
    const res = await apis.login(params, headers);
    store.commit('login/initCodeAndKey');
    store.commit('modal/showModal', { modalKey: store.getters['modal/getModalKey'], open: false});
    if(walletType){
        const wallets = {
            wc: wc,
            metamask: metamask
        }
        if(wallets[walletType].accounts.length == 0){
            await wallets[walletType].fn.onBoarding();
            await logout();
            return;
        } else {

        }
    }
    if (res?.decrypt_data?.value?.agent_private_key) {
        setCookie('pk', res?.decrypt_data?.value?.agent_private_key);
    }
    if(typeof isRememberId == 'boolean'){
        setCookie('rememberId', name);
    }
    if (res?.decrypt_data?.is_success) {
        setCookie('currency', res["decrypt_data"]["value"]["sessionData"]["account"]["currency_code"]);
        setCookie('uuid', res["decrypt_data"]["value"]["sessionData"]["account"]["uuid"]);
        loginUser["uuid"]     = res["decrypt_data"]["value"]["sessionData"]["account"]["uuid"];
        loginUser["id"]       = name;
        loginUser['time']     = new Date().getTime();
        loginUser['currency'] = res["decrypt_data"]["value"]["sessionData"]["account"]["currency_code"];
        store.commit('login/setLoginUser', {
            ...loginUser,
            isWallet: walletType ? true : false
        });
        store.commit('theme/setUuid', loginUser['uuid']);
        store.commit('modal/initModal');
        // showModal('login', false);
        setCookie('loginId', name);

        // await getReferralMemberList();
        // await getMyRefferal();
        fadeAnim();
        router.push({ name: 'Lobby' })
    } else {
        return res.decrypt_data;
    }
};

/**
 * @name: 환율정보 가져오기
 */
export const setCurrencyInfo = async (args) => {
    let exInfo = undefined;
    const res = await apis.getCurrencyInfo(args);
    if(res?.data){
        exInfo = exchangeRate(res.data, loginUser);
        currencyInfo['data'] = res.data;
        sabongChips['pcc'] = res.data.filter(data => data['table'] == 'pcc');
        sabongChips['inter'] = res.data.filter(data => data['table'] == 'international');
        sabongChips['wpa'] = res.data.filter(data => data['table'] == 'wpa');
    }
    return exInfo;
}

/**
 * @name: 커미션정보가져오기
 */
export const setCommisionInfo = async (idx) => {
    let com = 1.89;
    const res = await apis.getCurrencyInfo();
    if(res.Code == 0){
        com = res.data.find(cur => cur.game == idx && cur.table == 'COMMISSION');
        com = com ? 2 - (2 * (Number(com.value) / 100)) : 1.89;
    }
    /*    export const SABONG_COMMISION_DEFAULT = 2 - (2 * 0.055);*/
    return com.toFixed(2);
}

/**
 * @name: 유저 등급 이미지 가져오기
 * @param: index int 유저 등급IDX 배열 순서
 */
export const getGradeImage = (idx) => {
    let img = PLAYER_GRADE_IMG[idx];
    return require(`@/assets/grade/${img}`) || '';
}
/**
 * @name: 유저 국기 이미지 가져오기
 * @param: key String 국기 key
 */
export const getFlagImage = (key) => {
    let img = FLAG_IMG?.[key] || FLAG_IMG['KOR'];
    return require(`@/assets/flag/${img}`);
}
