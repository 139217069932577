<template>
    <header>
        <div
            class="logoIdBox"
            v-on:click.stop="store.commit('modal/showModal', { modalKey: 'userInfo', open: true, styles: { width: '100vw', height: '100vh'}})">
            <div class="logo">
                <img v-bind:src="setup['logoSrc']">
            </div>
            <div
                class="sabongHeaderM userId">
                {{ loginUser['isWallet'] ? setCollapsed(loginUser['id']) : loginUser['id'] }}
            </div>
            <ul class="inrBox">
                <li></li>
                <li>
                    <span
                        class="inrBox"
                        v-numberFormat="loginUser['balance']"></span>
                    <span class="inrBox">
                        {{ loginUser['currency'] }}
                    </span>
                </li>
            </ul>
        </div>
        <div class="headerRight">
            <div
                class="walletIcon"
                v-if="loginUser['isWallet']">
                <img
                    src="@/assets/btn/btn_wallet.png"
                    alt="wallet"
                    v-on:click.stop="store.commit('modal/showModal', { modalKey: 'MyWallet', open: true, styles: { width: '100vw', height: '100vh'}})">
                <span class="walletAlert"></span>
            </div>
            <div
                class="menuBtn">
                <div class="labelBtn">
                    <button v-on:click.stop="store.commit('modal/showSideMenu', !store.getters['modal/isSideMenuOpen'])"> <img
                        src="@/assets/mobile/h-btn.png"
                        width="28">
                    </button>
                </div>
            </div>
        </div>
    </header>
</template>
<script setup>
    import { computed, watch, onUnmounted, reactive } from "vue";
    import { useRouter } from "vue-router";
    import store from "@/store";
    import { setCollapsed } from "@/js/utils";
    import {getBalance} from "@/js/request";
    import { timer } from "@/js/reactive";
    import {ethAmount, sabongWalletInfo, walletAddress, walletOwn, walletUsdPrice} from "@/js/inGame/sabongWallet";
    import {LOGO} from "@/js/const";
    const router = useRouter();

    const setup = reactive({
        logoSrc: LOGO?.[location.hostname]?.headerM || LOGO['vinusinter.com'].headerM,
    })

    const loginUser = computed(() => {
        return store.getters['login/getLoginUser'];
    });
    onUnmounted(() => {
        clearTimeout(timer['balance']);
    });
    if(!loginUser.value.time){
        router.push('/');
    }
    watch(loginUser.value, async (val) => {
        if(val.time){
            await getBalance();
            if(loginUser.value['isWallet']){
                await walletOwn();
                await ethAmount('eth');
                await walletUsdPrice('eth');
                // 지갑 보유 시 히스토리와 지갑 주소 찾기
                await getOwnWallet();
            }
        }
    },{
        deep: true,
        immediate: true
    });
    const getOwnWallet = async () => {
        if(sabongWalletInfo['walletOwn']) {
            await walletAddress();
        }
    }
</script>


<style scoped>
*{
    font-family: 'Folks', sans-serif !important;
    letter-spacing: .3px;
}
header{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
	background-color: #1b1b1b;
	height: 40px;
    color: #fff;
    font-size: 14px;
    padding: 0 10px;
}
header .logo,
header .menuBtn{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 30;
}
.logo > img{
    width: 30px !important;
}
input {
    position: fixed;
    left: -9999px;
}
label {
    cursor: pointer;
}
/*header #menuBtn:checked ~ nav{
	display: block;
}*/

header .menuBtn,
header .currency{
    display: flex;
    justify-content: space-between;
    gap: 11px;
    color: #ffce70;
    margin-left: 5%;
}
header .menuBtn{
    margin-top: 4px;
}


nav.sidemenu{
    position: absolute;
    top: 30px;
    right: -10px;
    width: 224px;
    height: auto;
    border: solid 1px #bbb;
    background-color: #1b1b1b;
    color: #ffffff;
    font-size: 18px;
    padding: 18px 15px;
    border-radius: 12px;
}
nav.sidemenu ul{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 5.5%;
}
nav.sidemenu ul li{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 25px;
    padding: 0 13px;
}
nav.sidemenu ul li:last-child{
    height: 70px;
}
.hr{
    border-color: #6c6c6c;
}

/* 211027추가 */
.currency{
    margin-left: 13px !important;
}
.logoIdBox{
    display: flex;
    align-items: center;
    gap: 4px;
}
.sideMenuBox{
    width: 100%;
    height: 100vh;
    padding-bottom: 30%;
    color: #fff;
    font-size: 14px;
    position: absolute;
    top: 0;
    background-color: #2e3033;
}

/*211209한유정 추가*/
.dropdownChangeMoney{
    color: #fff;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
}
.dropdownChangeMoney > ol{
    width: 210px;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 30px;
    background-color: #1b1b1b;
    border: 1px solid #fff;
    list-style-type: none;
    z-index: 1;
}
.dropdownChangeMoney > ol > li{
    font-size: 12px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.dropdownChangeMoney > ol > li > span{
    color: #ffce70;
}
.dropdownChangeMoney > ol > li:hover{
    background-color: #3c4149;
}

/*20220224 김혜련 추가*/
.logoIdBox{
    width: auto;
}
.headerRight{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 13px;
}
.walletIcon{
    position: relative;
    color: #ffffff;
    width: 34px;
    height: 34px;
}
.walletIcon img{
    width: 34px;
}

.walletAlert{
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff2121;
}
ul.inrBox{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
    font-size: 12px;
    margin-left: 50px;
    white-space: nowrap;
}
ul.inrBox li{
    display: flex;
    color: #fff;
    gap: 3px;
}
ul.inrBox li span:first-child{
    color: #ffce70;
}
@media screen and (max-width: 389px) {
/*    .sabongHeaderM{
        font-size: 1em;
        padding-right: 5px;
    }
    ul.inrBox{
        font-size: 0.9px;
        width: 62%;
    }
    header .menuBtn{
        margin-left: 0;
    }
    .logoIdBox{
        width: 90%;
        gap: 5px;
    }*/
}



</style>
