<template>
    <li class="skinBox">
        <ul class="skinSelectModal" >
            <li
                v-for="(theme,idx) in themeType"
                v-bind:key="theme"
                v-bind:class="theme"
                v-on:click.stop="changeTheme">
                <input
                    type="radio"
                    name="theme"
                    v-bind:id="theme"
                    v-bind:value="idx"
                    v-bind:checked="activeTheme == idx ? true : false">
                <label v-bind:for="theme">
                    {{theme}}
                </label>
            </li>
        </ul>
    </li>
</template>

<script setup>
    import store from "@/store";
    import {onMounted, ref} from "vue";
    import {useRoute , useRouter} from "vue-router";
    const themeType = ['Basic', 'Pro'];

    const route = useRoute();
    const router = useRouter();
    let activeTheme = ref(undefined);
    const changeTheme = async (e) => {
        if(e.target?.tagName === 'INPUT' && Number(e.target.value) !== activeTheme.value) {
            await store.dispatch('theme/changeTheme', e.target.value);
            if(route.name == "inGame"){
                router.go(0);
            }
        }
    }

    onMounted(() => {
        activeTheme.value = store.getters['theme/getTheme'];
    });
</script>

<style>
.skinBox{
    width: 100%;
    height: 100%;
}
.skinBox::marker{
    color: transparent;
}
.skinSelectModal{
    position: absolute;
    right: 0;
    top:0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 123px;
    height: 80px;
    background: #1b1b1b;
    border: 1px solid #bbb;
    box-sizing: border-box;
}
.skinSelectModal>li{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    height: 40px;
    background: transparent;
    font-size: 18px;
    color: #fff;
    padding: 0 10px;
    z-index: 10;
}
.skinSelectModal>li input[type='radio']{
    width: 18px;
    height: 18px;
    padding: 4px;
    box-sizing: border-box;
    background-color: #bbb;
    border: 2px solid #bbb;
    border-radius: 50%;
    cursor: pointer;
}
.skinSelectModal>li label{
    box-sizing: border-box;
    width: 80px;
    height: 100%;
    cursor: pointer;
    line-height: 40px;
    text-align: right;
}
.skinSelectModal>li input[type='radio']:checked {
    background-color: #39d530;
    border-color: #39d530;
}
</style>