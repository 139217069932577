<template>

    <div
        class="historyWrap"
        v-if="!modals['sabongHistoryDetl']">
        <div class="body">
            <div
                class="row noData"
                v-if="historyData.historyList.length == 0">
                {{ $t('noData') }}
            </div>
            <div
                class="row"
                v-else
                v-on:click.stop="historyCmd.fn.setHistoryDetl(history)"
                v-for="(history, idx) in historyData.historyList"
                v-bind:key="idx">
                <div class="topContents">
                    <span>&#8226;</span>
                    <div>
                        <span class="text">
                            {{ history['event_id'] }}
                        </span> - <span class="text">
                            #{{ history['fight_no'] }}
                        </span>
                    </div>
                </div>
                <div class="botContents">
                    <div class="infos">
                        <div class="text">
                            {{ $t('date') }}
                        </div>
                        <div class="value">
                            {{ history['reg_dt'] }}
                        </div>
                    </div>
                    <div class="infos">
                        <div class="text">
                            {{ $t('bet') }}
                        </div>
                        <div
                            class="value"
                            v-numberFormat="history['bet_amount']">
                        </div>
                    </div>
                    <div class="infos">
                        <div>
                            <span class="profit">
                                {{ $t('win2') }}
                            </span>
                            <span class="andLine">
                                &#47;
                            </span>
                            <span class="loss">
                                {{ $t('loss') }}
                            </span>
                        </div>
                        <div
                            class="value"
                            v-bind:class="{
                                'profit': Number(history['winnings']) > 0,
                                'loss': Number(history['winnings']) < 0,
                            }"
                            v-numberFormat="history['winnings']">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="historyFooter">
            <span v-on:click.stop="historyCmd.fn.goPrevPage()">
                Prev
            </span>
            <ul class="historyPageNum">
                <li>{{ historyData.curPage }}</li>
                <li>&#47;</li>
                <li>{{ historyData.totalPage }}</li>
            </ul>
            <span v-on:click.stop="historyCmd.fn.goNextPage()">
                Next
            </span>
        </div>
    </div>

    <div
        class="historyWrap historyDetail"
        v-if="modals['sabongHistoryDetl']">
        <div class="body box">
            <div class="row historyDetail">
                <div class="topContents">
                    <span
                        class="prev"
                        v-on:click.stop="historyCmd.fn.goPage('prev')">
                        <img
                            alt="prev"
                            src="@/assets/icons/icon_arrow_l.png">
                    </span>
                    <div class="cntTxtBox">
                        <div>
                            <span class="text">
                                {{ historyData.selectedHistory['event_id'] }}
                            </span> - <span class="text">
                                #{{ historyData.selectedHistory['fight_no'] }}
                            </span>
                        </div>
                        <div>
                            <span>{{ historyData.curNavIdx + 1}} of {{ historyData.curPage }} pages</span>
                        </div>
                    </div>
                    <span
                        class="next"
                        v-on:click.stop="historyCmd.fn.goPage('next')">
                        <img
                            alt="next"
                            src="@/assets/icons/icon_arrow_r.png">
                    </span>
                </div>

                <div class="botContents">
                    <div class="infos">
                        <div class="text">
                            {{ $t('date') }}
                        </div>
                        <div class="value">
                            {{ historyData.selectedHistory.reg_dt }}
                        </div>
                    </div>
                    <div class="infos">
                        <div class="text">
                            {{ $t('bet') }}
                        </div>
                        <div
                            class="value"
                            v-number-format="historyData.selectedHistory.bet_amount">
                        </div>
                    </div>
                    <div class="infos">
                        <div>
                            <span class="profit">
                                {{ $t('win2') }}
                            </span>
                            <span class="andLine">
                                &#47;
                            </span>
                            <span class="loss">
                                {{ $t('loss') }}
                            </span>
                        </div>
                        <div
                            class="info"
                            v-bind:class="{
                                'profit': Number(historyData.selectedHistory['winnings']) > 0,
                                'loss': Number(historyData.selectedHistory['winnings']) < 0,
                            }"
                            v-number-format="historyData.selectedHistory['winnings']">
                        </div>
                    </div>
                </div>
            </div>

            <div class="centerContents">
                <div
                    v-for="(data, key) in boxUi['winColors']"
                    v-bind:class="[data,{ 'dim': getWinColor(historyData.selectedHistory) != data }]"
                    v-bind:key="key">
                    <span>{{ $t(setBetLocale.text[data]) }} </span>
                </div>
            </div>

            <div class="botContents">
                <div
                    class="result"
                    v-for="(data, key) in boxUi['betRows']"
                    v-bind:key="key">
                    <div v-bind:class="data">
                        {{ $t(setBetLocale.text[data]) }}
                    </div>
                    <div class="text">
                        {{ $t('bet') }}
                    </div>
                    <div v-number-format="historyData.selectedHistory[`bet_${data}`]">
                    </div>
                    <div class="text">
                        {{ $t('result') }}
                    </div>
                    <div v-number-format="historyData.selectedHistory[`win_${data}`]">
                    </div>
                </div>
            </div>
        </div>
        <div class="modalFooter">
            <div
                class="center"
                v-on:click.stop="showModal('sabongHistoryDetl', false)">
                {{ $t('toList') }}
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onBeforeMount, onUnmounted, ref } from "vue";
    import { historyData, getHistoryCmd, getWinColor, initSabongHistoryData } from "@/js/history/history";
    import { showModal, modals } from "@/js/modal/modals";
    import { useRoute } from "vue-router";
    import { SABONG_GAME_TYPE_IDX, LOGO } from '@/js/const';

    const route = useRoute();
    let historyCmd = ref(undefined);
    const setBetLocale = LOGO[location.hostname].betLocale;
    const boxUi = {
        winColors: ['meron', 'wala', 'draw', 'cancel'],
        betRows: ['meron', 'wala', 'draw']
    }
    onBeforeMount(async () => {
        historyCmd.value = getHistoryCmd(route.meta.gameType);
        await historyCmd.value.fn.setHistory(SABONG_GAME_TYPE_IDX[route.params.gameType]);
    });
    onUnmounted(() => {
        modals['sabongHistoryDetl'] = false;
        initSabongHistoryData();
    });
</script>
<style scoped>
.historyWrap {
	width: 100%;
	height: 100%;
	color: white;
	font-weight: bold;
	line-height: normal;
}

.historyWrap:not(.historyDetail,.inGameModalWrap) {
	padding-bottom: 60px;
}

.historyWrap .header {
	display: flex;
	align-items: center;
	width: 100%;
	height: 80px;
	margin: 0 auto;
}

.historyWrap .header select {
	width: 80px;
	height: 30px;
}

.historyWrap .body {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	overflow-y: scroll;
	font-size: 16px;
	font-weight: 500;
	gap: 5px;
	padding: 5px;
	background-color: transparent;
	border-radius: unset !important;
}

.historyWrap.historyDetail .body {
	display: flex;
	flex-direction: column;
	width: 98%;
	height: 83%;
	margin: 0 auto;
	overflow: auto;
	padding: 4px 0;
	font-size: 20px;
	border: none;

}

.historyWrap .body .row {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100px;
	padding: 0 10px 10px 10px;
	gap: 12px;
	font-size: 20px;
	cursor: pointer;
	border: solid 2px #484848;
}

.row.noData {
	height: 100% !important;
	align-items: center;
	justify-content: center;
}

.historyWrap .body .row.historyDetail {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 110px;
	background-color: transparent;
	border: none;
}

.historyWrap .body .row .topContents {
	border-bottom: 2px solid #6c6c6c;

}

.historyWrap .body .row .topContents,
.historyWrap .body .row .botContents {
	flex: 1;
	display: flex;
	width: 100%;
	height: 24px;
	gap: 10px;
	text-align: center;
	flex-direction: row;
	align-items: center;
	justify-content: left;
	font-size: 14px;
}

.topContents .text {
	width: 90px;
	border-right: none;
	white-space: nowrap;
	padding-right: 0;
}

.topContents .gameId {
	text-align: left;
	margin-left: 10px;
}

.prev {
	cursor: pointer;
	position: sticky;
	left: 0;
	top: 0;
	margin-left: -10px;
}

.next {
	cursor: pointer;
	position: sticky;
	right: 0;
	top: 0;
	margin-right: -10px;
}

.topContents > .prev > img,
.topContents > .next > img {
	width: 14px;
	height: 25px;
	vertical-align: middle;
}

.cntTxtBox {
	width: 100%;
	display: flex;
	flex-direction: row;
	font-size: 14px;
	justify-content: space-between;
}

.cntTxtBox .text {
	width: 110px;
}

/*.cntTxtBox:lang(ko) {*/
/*    font-size: 18px;*/
/*}*/

.historyWrap .body .botContents {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: inherit;
}

.historyWrap .body .botContents .result {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 10px 0;
}

.historyWrap .body .botContents .result div:first-child {
	border-right: 2px solid #484848;
	font-size: 16px;
}

.result div.p,
.result div.pp {
	/*color: var(--modal-history-player-color);*/
	color: #3c86ff;
}

.result div.b,
.result div.bp {
	/*color: var(--modal-history-banker-color);*/
	color: #ff1818;
}

.result div.tie {
	/*color: var(--modal-history-tie-color);*/
	color: #26ff01;
}

.historyWrap .body .centerContents {
	width: 100%;
	height: 102px;
	/*min-height: 150px;*/
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-bottom: 15px;
	margin: 20px 0 26px 0;
	gap: 10px;
	font-size: 20px;
	flex-wrap: wrap;
}

.historyWrap .body .centerContents > div {
	width: 38%;
	min-width: 174px;
	height: 46px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dim {
	opacity: 0.4;
}
.meron {
	background-color: #bb3a3a;
}

.wala {
	background-color: #2056af;
}

.draw {
	background-color: #37841f;
}

.cancel {
	background-color: #5a5a5c;
}

.historyWrap .centerContents .result {
	display: flex;
	width: 300px;
	text-align: center;
}

.historyWrap .centerContents .result .header {
	width: 100%;
	height: 45px;
	justify-content: center;
	margin-bottom: 10px;
}

.historyWrap .centerContents .result .header.banker {
	flex-direction: row-reverse;
}

/*.historyWrap .centerContents .result .header .title.player {*/
/*    color: var(--modal-history-player-color);*/
/*}*/

/*.historyWrap .centerContents .result .header .title.banker {*/
/*    color: var(--modal-history-banker-color);*/
/*}*/

.historyWrap .centerContents .result .cards {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 10px;
}

.cards .card {
	width: 46px;
	height: 63px;
	background-color: white;
	border-radius: 8px;
	position: relative;
	color: black;
	white-space: pre-line;
	font-size: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cards.player .card.last {
	transform: rotate(90deg);
}

.cardSum {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 38px;
	background-color: #222222;
	border-radius: 6px;
}

.historyWrap .body .row .botContents div {
	flex: 1;
}

.historyWrap ::v-deep(.text) {
	/*color: var(--modal-history-font-color);*/
	color: #5ccfff;
}

.historyWrap ::v-deep(.infos) {
	display: flex;
	flex-direction: column;
	align-items: center;
	/*gap: 10px;*/
	white-space: nowrap;
}

.andLine {
	color: #5ccfff;
	margin: 0 2px;
}

.historyWrap ::v-deep(.modalFooter) {
	display: flex;
	width: 95%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 50px;
	margin: 0 auto;
}

.modalFooter .center {
	flex: 1;
	text-align: center;
	margin: 0 auto;
	cursor: pointer;
	font-size: 20px;
}

.modalFooter .left, .modalFooter .right {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 15px;
	cursor: pointer;
	font-size: 20px;
}

.nextBtn {
	transform: scaleX(-1);
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
}

.modalFooterM {
	display: none;
}

.profit {
	color: #2be000 !important;
}

.loss {
	color: #ff121a !important;
}

.black {
	color: black !important;
}

.red {
	color: #dd3d3d !important;
}

.card .mark {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 20px;
	margin-left: 2px;
}

.mark > img {
	width: 16px;
}

.card .tx {
	font-size: 40px;
	position: relative;
	top: 10px;
}

/* 211029 한유정 추가 */
.topContents:lang(ko) {
	padding-left: 0 !important;
}

.topContents > .text:lang(ko) {
	white-space: nowrap;
}

.result > .text {
	font-size: 16px;
}

.historyWrap .body .botContents .result div {
	text-align: center;
	font-size: 16px;
}

.result > div:nth-child(1){
	width: 25%;
}
.historyWrap .result > div:nth-child(2),
.historyWrap .result > div:nth-child(4){
	width: 10%;
}
.result > div:nth-child(3){
	width: 25%;
}
.result > div:nth-child(5){
	width: 35%;
}

.historyWrap .body .row:lang(en) {
	font-size: 16px !important;
}

.historyWrap .body > .historyDetail > .topContents {
	padding-left: 0 !important;
	gap: 0 !important;
}

/* 21115 한유정 추가 */
.historyFooter {
	height: 45px;
	width: 100%;
	display: flex;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	column-gap: 30px;
	position: sticky;
	font-size: 20px;
	bottom: 0;
	background-color: #3b3b3b;
}

.historyFooter > span {
	border-radius: 10px;
	border: 1px solid #fff;
	font-size: 20px;
	padding: 5px 10px;
	cursor: pointer;
}

.historyPageNum {
	display: flex;
	column-gap: 5px;
}

.inGameModalWrap .historyWrap.historyDetail .body {
	height: 91%;

}
</style>