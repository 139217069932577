<template>
    <div
        class="modalInner"
        v-bind:class="{ 'mobile': isMobile() }">
        <div class="info">
            <div class="logo1">
                <img src="@/assets/telegram.png"/>
            </div>
            <div>
                Telegram<br>
                <span>&#64;exceltechsupport</span>
            </div>
        </div>
        <div class="info">
            <div class="logo2 telLogo">
                <img
                    v-if="logoSrc"
                    v-bind:src="logoSrc"/>
            </div>
            <div>
                {{ logoTitle }}<br>
                <span>+255 32 020 0865</span>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { reactive } from 'vue';
    import { showModal } from '@/js/modal/modals';
    import { isMobile, getCookie, setCookie } from "@/js/utils";
    import { useStore } from "vuex";
    import { LOGO } from '@/js/const';

    const store = useStore();

    const logoSrc = LOGO?.[location.hostname]?.interRoomLogo || LOGO['vinusinter.com'].interRoomLogo;
    const logoTitle = LOGO?.[location.hostname]?.interRoomTitle || LOGO['vinusinter.com'].interRoomTitle;


</script>
<style scoped>
.telLogo img {
	position: relative;
	top: 10px;
}
*{
    font-family: 'Folks', sans-serif;
    letter-spacing: .3px;
    box-sizing: border-box;
}
.modalInner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:50px;
    height: 337px;
    margin: 14px 6px 6px;
    border-radius: 6px;
    background-color: #1b1b1b;
}

.info {
	text-align: center;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.info div{
    font-size: 22px;
	color: #5ccfff;
	line-height: 25px;
	text-align: center;
    width: 46%;
}
.info div > span{
	color: #fff;
}

.logo1 img{
    width: 84px;
}
.logo2 img{
    width: 100px;
}

/* mobile */
.modalInner.mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    height: 230px;
    margin: 14px 6px 6px;
    border-radius: 6px;
    background-color: #1b1b1b;
}
.modalInner.mobile .info div{
    font-size: 16px;
    line-height: 20px;
}

.modalInner.mobile .logo1 img{
    width: 60px;
}
.modalInner.mobile .logo2 img{
    width: 80px;
}

</style>