<template>
    <!-- <Intro/> -->
    <transition name="fade">
        <div
            v-if="show"
            class="lobby">
            <SabongWalletHeader/>
            <div class="lobbyWrap">
                <div
                    class="lobbyTopBox"
                    v-show="isShowGameList">
                </div>
                <div class="roomBg">
                    <div
                        class="roomWrap"
                        v-if="roomList.length == 0 && gameType !== 'sabong'">
                        <Loading/>
                        <span class="noRoom">
                            {{ loadMsg }}
                        </span>
                    </div>
                    <div class="roomWrap">

                       
                        <keep-alive>
                            <component
                                v-bind:is="getRoomComponent"
                                v-bind:dealers="dealers"
                                v-bind:roomList="roomList"
                                v-bind:roomUsers="roomUsers"
                                v-bind:roomStatus="roomStatus"/>
                        </keep-alive>
                        
                    <!--
                        <keep-alive>
                            <component
                                v-bind:is="SabongRoom"
                                v-bind:dealers="dealers"
                                v-bind:roomList="roomList"
                                v-bind:roomUsers="roomUsers"
                                v-bind:roomStatus="roomStatus"/>
                        </keep-alive>
                        -->
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    /*https://www.npmjs.com/package/css-vars-ponyfill*/
    import cssVars from "css-vars-ponyfill";
    cssVars({ ...DEFAULT_THEME });

    import { defineComponent } from "vue";
    import Intro from "@/components/sabong/intro";
    import { sabongApis, pccApis } from "@/repository/repository";
    import { topics, totalRoom, loginUser, sabong, betInfos } from "@/js/reactive";
    import { MQTT, sendTopic } from "@/plugins/mqtt";
    import { decompression, isMobile } from "@/js/utils";
    import { DEFAULT_THEME } from "@/js/theme";
    import { BACCARAT_SCORE_IDX } from "@/js/const";
    import { getMinMaxs } from "../../js/common";
    import { getBetUsers, checkSabongLogin, getEvents, getPccBalance } from "@/js/inGame/sabong";
    import { getSabongEvents } from "@/js/inGame/sabongInter";
    import Header from "../../components/layout/Header";
    import Loading from "@/components/common/Loading";
    import sabongTabMenu from "@/template/sabong/sabongTabMenu";
    import BaccaratRoom from "@/components/room/BaccaratRoom";
    //import SabongRoom from "@/components/room/SabongRoom";
    import SabongRoom from "@/components/room/SabongRoomInGame";
    import SabongRoomM from "@/components/room/SabongRoomM";
    import SabongWalletHeader from "@/components/layout/SabongWalletHeader";
    import { modals } from "@/js/modal/modals";

    export default defineComponent({
        components : {
            Header,
            Loading,
            sabongTabMenu,
            SabongWalletHeader,
            Intro
        },
        name: 'Lobby',
        computed: {
            /**
             * @name: 게임별 방 컴포넌트 가져오기
             */
            getRoomComponent() {
                return this.gameType == 'baccarat' ? BaccaratRoom : isMobile() ? SabongRoomM : SabongRoom;
            },
        },
        data() {
            return {
                modals: modals,
                show : false,
                // 게임메뉴들
                /*                loginFadein : false,*/
                gameList: [
                    {
                        'key': 'baccarat',
                        'img': require('../../assets/icons/menu_game_icon/icon-baccarat.png'),
                        'click': (game) => {
                            this.setGameType(game);
                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? false : true
                    },
                    {
                        'key': 'dragonTiger',
                        'img': require('../../assets/icons/menu_game_icon/icon-dragontiger.png'),
                        'click': (game) => {
                            this.setGameType(game);

                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                    {
                        'key': 'teenpatti',
                        'img': require('../../assets/icons/menu_game_icon/icon-teenpatti.png'),
                        'click': (game) => {
                            this.setGameType(game);

                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                    {
                        'key': 'holdem',
                        'img': require('../../assets/icons/menu_game_icon/icon-holdem.png'),
                        'click': (game) => {
                            this.setGameType(game);
                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                    {
                        'key': 'roulette',
                        'img': require('../../assets/icons/menu_game_icon/icon-roulette.png'),
                        'click': (game) => {
                            this.setGameType(game);
                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                    {
                        'key': 'sicbo',
                        'img': require('../../assets/icons/menu_game_icon/icon-sicbo.png'),
                        'click': (game) => {
                            this.setGameType(game);
                        },
                        'lock': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live' ? true : false
                    },
                ],
                // 게임리스토 보여주기 여부
                isShowGameList: true,
                // 방 접속 유저들
                roomUsers: {},
                // 현재 로그인유저
                loginUser: loginUser,
                // 방 상태값
                roomStatus: {},
                // 중국점 스코어 여부
                BACCARAT_SCORE_IDX: BACCARAT_SCORE_IDX,
                // 토픽 방 여부
                isLoading: true,
                // 현재 가지고있는 전체방
                totalRoom: totalRoom,
                // 현재 선택된 게임메뉴
                gameType: process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? 'sabong' : 'baccarat',
                // 딜러툴에서 보내주는 전체 토픽정보들
                topics: topics,
                // 로비에서 보여줄 방정보
                roomList: [],
                // 로비에서 보여줄 방정보들
                totalRoomList: [],
                // 딜러이미지들
                dealers: {},
                // 로딩 메시지 타이머
                loadTimer: undefined,
                // 로딩메시지
                toggleMsg: false,
                loadMsg: this.$t('noRoom'),
                timer: undefined
            };
        },
        watch: {
            topics: {
                deep: true,
                handler(topics) {
                    if(this.gameType !== 'sabong'){
                        this.getOpenRoom(topics);
                    }
                },
            },
        },
        created() {
            MQTT();
        },
        async mounted() {

            console.log('sabong lobby mounted');

            sendTopic('SabongUserLogin', {});
            this.show = true;
            if(process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live'){
                await this.getDealerImage();
                await this.getTotalRoomList();
            } else {
                this.isShowGameList = false;
                await getSabongEvents();
                this.timer = setInterval(async () => {
                    await getSabongEvents();
                }, 5000);
            }
            // 로딩메시지 표현
            this.loadTimer = setInterval(() => {
                this.toggleMsg = !this.toggleMsg
                this.loadMsg = this.toggleMsg ? this.$t('noRoom') : this.$t('loading');
            }, 3000);
        },
        unmounted() {
            clearInterval(this.timer);
        },
        methods: {
            // 딜러이미지 압축
            decompression,
            // 로비 방 아이콘 클릭시 배팅 MIN MAX
            getMinMaxs,
            // 로비에서 게임메뉴 선택
            setGameType(game) {
                if(game['lock']){
                    return;
                }
                this.gameType = game['key'];
            },
            //딜러 이미지 가져오기
            async getDealerImage() {
                const res = await this.apis.getDealerImage();
                if(res['code']['logic'] == 0 && res['decrypt_data']['images']){
                    this.dealers = res['decrypt_data']['images'];
                }
            },
            // 전체 방정보 가져오기
            async getTotalRoomList() {
                const res = await this.apis.getRoomInfo({key: "TABLE_GAME_LIMITS"});
                this.totalRoomList = res["decrypt_data"]["const_data"]["table_game_limits"];
            },
            //현재 열려있는 방 가져오기
            getOpenRoom(topics) {
                let regex = /.*\/live_bacc\/RoomInfo/gi;
                this.isLoading = false;
                this.roomList = [];
                Object.entries(topics).forEach((topic) => {
                    if (
                        topic[0].match(regex) &&
                        this.roomList.findIndex((room) => room["room_no"] === topic[1]["room_no"]) === -1
                    ) {
                        let round = 0;
                        if(topics[`${topic[1]['room_no']}/live_bacc/GameStatus`]){
                            round = topics[`${topic[1]['room_no']}/live_bacc/GameStatus`]['game_id'];
                        }

                        if(!this.dealers[topic[1]['dealer_id']]){
                            this.dealers[topic[1]['dealer_id']] = undefined;
                        }

                        let findKey = topic[0].split('/')[0] -1;
                        if (this.totalRoomList[findKey]) {
                            this.roomList.push(
                                Object.assign(
                                    this.totalRoomList[findKey],
                                    {
                                        round: round,
                                        ...topic[1]
                                    }
                                )
                            );
                        }
                        this.roomList.sort((a, b) => {
                            return a["room_no"] - b["room_no"];
                        });

                        // 인게임에서 테이블 변경
                        this.totalRoom['rooms'] = this.roomList;
                        this.roomStatus[topic[1]['room_no']] = 0;
                        this.roomUsers[topic[1]['room_no']] = 0;
                        if(this.roomList.length > 0){
                            clearInterval(this.loadTimer);
                        }
                    }

                    if(this.roomList.findIndex((room) => room["room_no"] === topic[1]["room_no"]) > -1){
                        if(typeof topic[1]['status'] !== 'undefined'){
                            this.roomStatus[topic[1]['room_no']] = topic[1]['status'];
                        }
                        if(typeof topic[1]['room_user_count'] !== 'undefined'){
                            this.roomUsers[topic[1]['room_no']] = topic[1]['room_user_count'];
                        }
                    }
                });
            },
        },
    });
</script>
<style scoped>
*{
    font-family: 'Folks', sans-serif;
    letter-spacing: .3px;
}
.lobby {
    height: 100%;
    width: 100%;
}
.noRoom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
    margin: 0 auto;
}
.lock {
    opacity: 0.5;
}
.imgWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 67px;
    height: 67px;
    cursor: pointer;
}
.imgWrap.lock {
    cursor: not-allowed;
}
.imgWrap img {
    width: 67px;
    height: 67px;
}
.lobbyWrap{
    width: 100%;
    background-color: #000;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}
.gameMenu{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 985px;
    margin: 0 auto 10px auto;
    gap: 10px;
}
.gameMenu li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fafafa;
    flex: 1;
    text-shadow: 0 0 2px #000;
    padding-bottom: 15px;
    height: 125px;
    position: relative;
}
.gameMenu li > img{
    width: 128px;
    margin-top: -26px;
    position: relative;
}
.selectGame > img{
    opacity: 1 !important;
}
.horiLine{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 4px;
    width: 80%;
    bottom: 0;
    gap: 4px;
    background-color: #c8c8c8;
}

/* 2021-11-08 이은정 추가 */
.sabongHoriLine {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 4px;
    width: 80%;
    bottom: 0;
    gap: 4px;
}
.sabongHoriLine > div{
    width: 25%;
    height: 4px;
    background-color: #545454;
}
.sabongHoriLine > div.select{
    background-color: #c8c8c8;
}

.gameMenu li > h3{
    padding-top: 20px;
    white-space: nowrap;
    font-size: 15px;
    cursor:pointer;
}

.comingSon{
    position: absolute;
    width: 84px;
    height: 44px;
    border: 2px solid #c8c8c8;
    color: #c8c8c8;
    top: 30px;
    font-size: 20px;
    border-radius: 10px;
    line-height: 14px;;
    padding: 5px;
    font-weight: bold;
}
.comingSon > span{
    font-size: 14px;
    font-weight: bold;
}
.lobbyTopBox{
    position: relative;
    width: 100%;
    height: 160px;
    transform: scale(0.8);
}
.dragonTxt{
    padding-top: 10px !important;
}
.roomBg{
    width: 100%;
    height: auto;
    background-color: #2F3033;
}
.roomWrap{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 56px;
    background-color: #2F3033;
}
.layoutChange{
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 25px;
    margin-bottom: 20px;
}
.layoutChange > li > img{
    width: 38px;
    height: 28px;
    max-width: 100%;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}


/* sabonglogin */
.sideLoginWrap{
    position: absolute;
    top: 0;
    right: 0;
    width: 472px;
    height: 100vh;
    z-index: 999;
}
.sideLogin-enter-active,
.sideLogin-leave-active {
    transition: all .8s ease;
}

.sideLogin-enter-from,
.sideLogin-leave-to {
    transform:  translateX(300px);
    opacity: 0;
}

</style>