<template>
    <div
        class="inGameWrap"
        ref="rootWrap">
        <div class="inGameTopBox">
            <div class="userBetListMrn">
                <!-- win class 추가 -->
                <div class="userBetListWrap mrn">
                    <div class="listTopTxt">
                        <h2>{{ $t('meron') }} {{ $t('betList') }}</h2>
                        <div>
                            <span>{{ betUsers['list']['meron'] ? Object.keys(betUsers['list']['meron']).length : 0 }}</span>
                            <img src="@/assets/icons/lobby_listbox/user-icon2.png">
                        </div>
                    </div>
                    <div class="userBetListInner">
                        <ul
                            v-for="(user, key) in betUsers['list']['meron']"
                            v-bind:key="key">
                            <li>
                                <span>{{ setCollapsed(user['user_id']) }}</span>
                                <p><span v-numberFormat="calcRate(user['amount'],exchangeInfo)"></span><span>{{ loginUser?.currency }}</span></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="inGameTopMidBox">
                <div class="inGameBarWrap">
                    <h1 style="color:#ff2727;">
                        {{ $t('meron') }}
                    </h1>
                    <div class="inGameVsBar">
                        <div class="inGameProgressBarLeft">
                            <div
                                class="aniProgressBarWala"
                                v-bind:style="{ 'width': `${betUsers['list']?.['bet_info']?.['meron_dist'] || 0}%`}">
                                <p>{{ loginUser.currency }}</p>
                                <p v-numberFormat="calcRate(betUsers['list']?.['total_bet']?.['meron_total'], exchangeInfo) || 0"></p>
                            </div>
                            <div class="ProgressBarHp">
                                <span>{{ `${betUsers['list']?.['bet_info']?.['meron_dist'] == 'NaN' ? '0.00' : betUsers['list']?.['bet_info']?.['meron_dist'] || '0.00' }%` }}</span>
                            </div>
                        </div>
                        <div class="VsBarCnt">
                            VS
                        </div>
                        <div class="inGameProgressBarRight">
                            <div
                                class="aniProgressBarMrn"
                                v-bind:style="{ 'width': `${betUsers['list']?.['bet_info']?.['wala_dist'] || 0}%` }">
                                <p v-numberFormat="calcRate(betUsers['list']?.['total_bet']?.['wala_total'], exchangeInfo) || 0"></p>
                                <p>{{ loginUser.currency }}</p>
                            </div>
                            <div class="ProgressBarHp">
                                <span>{{ `${betUsers['list']?.['bet_info']?.['wala_dist'] == 'NaN' ? '0.00' : betUsers['list']?.['bet_info']?.['wala_dist'] || '0.00' }%` }}</span>
                            </div>
                        </div>
                    </div>
                    <h1 style="color:#3774ff;">
                        {{ $t('wala') }}
                    </h1>
                </div>
                <div class="videoWrap">
                    <!--                    <img src="@/assets/sabong/video-logo.png">-->
                    <!--                    <video src="../../assets/sabong.mp4" autoplay loop muted/>-->
                    <!--                    <CameraWebRTC :isSabong="true"/>-->
                    <SabongCam v-bind:vendor="route.params.gameType" />
                    <!--                    <div class="chatWrap">
						<input class="sendMsg" placeholder="Send Chatting Message" type="text">
						<div class="chattingBox">
							<div class="sentMsg">
								<span class="chatTime">[22:00]</span>
								<span>user101</span>
								<span>Hi</span>
							</div>
						</div>
					</div>-->

                    <div
                        v-if="currentGameResult"
                        class="matchRsltWrap"
                        v-bind:class="currentGameResult">
                        <h1>{{ $t(currentGameResult) }}</h1>
                        <div
                            class="resultPriceBox"
                            v-if="currentGameResult != 'cancel'">
                            <span v-numberFormat="realWinAmount"></span>
                        </div>
                        <div
                            class="cancelResultWrap"
                            v-else>
                            <div>
                                {{ $t('cancelInfo1') }} ({{ currentOdds }})
                            </div>
                            <div>
                                {{ $t('cancelInfo2') }}
                            </div>
                            <div
                                class="resultBox"
                                v-bind:class="currentSubRooster">
                                {{ currentSubRooster }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-bind:style="toggleSlideMenu"
                        class="inGameMenu">
                        <div v-on:click="slideMenu">
                            <img
                                src="../../assets/sabong/icon/arrow.png"
                                v-bind:class="{ 'slideMenuBtn': !isShow }">
                        </div>
                        <InGameIcons />
                    </div>
                    <div
                        class="btmWrap"
                        v-if="isShowBtnInterface && isBetStart">
                        <button
                            class="sabongCancelBtn"
                            v-on:click.stop="clickBetCancel()">
                            <img src="../../assets/sabong/icon/icon_cancel2.png">
                            {{ $t('cancel') }}
                        </button>
                        <button
                            class="sabongConfirmBtn"
                            v-on:click.stop="clickBetConfirm()">
                            <img src="../../assets/sabong/icon/check.png">
                            {{ $t('confirm') }}
                            <span class="betCountdown">
                                ({{ betCountDown }})
                            </span>
                        </button>
                    </div>
                    <div
                        class="timeBankWrap"
                        v-if="lastCallTime">
                        <TimeBank v-bind:lastCallTime="lastCallTime"/>
                    </div>
                </div>
                <div class="progressBox">
                    <div
                        class="inGameProgressBar"
                        v-bind:class="getVinusSabongRoomStatus(sabongInfo['rooms'])['tx']">
                        <p class="betTime">
                            <span>{{$t('gmt-mnl')}}   {{ moment().format('YYYY-MM-DD') }}  {{ currentTime['time'] }}</span>
                            <span>{{$t('fightNo')}} {{ getVinusSabongFightNo(sabongInfo['rooms']) }}</span>
                        </p>
                        <!--                        -->
                        <div
                            class="aniProgressBarBet"
                            v-bind:style="{ 'width': `100%`}"
                            v-bind:class="getVinusSabongRoomStatus(sabongInfo['rooms'])['tx']"></div>
                        <div class="ProgressBarHp"></div>
                        <p>{{ getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] }}</p>
                    </div>
                </div>
            </div>
            <div class="userBetListWala">
                <div class="userBetListWrap wala">
                    <div class="listTopTxt">
                        <h2>{{ $t('wala') }} {{ $t('betList') }}</h2>
                        <div>
                            <span>{{ betUsers['list']['wala'] ? Object.keys(betUsers['list']['wala']).length : 0 }}</span>
                            <img src="@/assets/icons/lobby_listbox/user-icon2.png">
                        </div>
                    </div>
                    <div class="userBetListInner">
                        <ul
                            v-for="(user, key) in betUsers['list']['wala']"
                            v-bind:key="key">
                            <li>
                                <span>{{ setCollapsed(user['user_id']) }}</span>
                                <p><span v-numberFormat="calcRate(user['amount'],exchangeInfo)"></span><span>{{ loginUser?.currency }}</span></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="inGameBtmBox">
            <div class="inGameBtmLeftBox">
                <div class="userPlayInfo">
                    <div>
                        <span>{{ $t('balance') }}</span>
                        <ul>
                            <li v-numberFormat="loginUser['balance']"></li>
                            <li class="currency">
                                {{ loginUser['currency'] }}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span>{{ $t('bet') }}</span>
                        <ul>
                            <li v-numberFormat="userBets['total']"></li>
                            <li class="currency">
                                {{ loginUser['currency'] }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="scoreboardBox sbb1">
                    <ul class="betDetailBox">
                        <li
                            v-for="(img,key) in betDetailIcon1"
                            v-bind:key="key">
                            <img v-bind:src="img['src']">
                            <span>{{ img['tx1'] }}</span>
                        </li>
                    </ul>
                    <div class="scoreboardInner">
                        <BaseRoad v-bind:gameType="gameType"/>
                    </div>
                </div>
            </div>
            <div class="inGameBtmMidBox">
                <div
                    class="battingBlock"
                    v-if="loginUser.balance == 0">
                    {{ $t('noBalance') }}
                </div>
                <div class="betBoardBox">
                    <div
                        class="betBoard meronBoard"
                        v-on:click="clickBetBoard('meron',idx)"
                        v-bind:class="{ 'on': clickedBoardKey === 'meron' }">
                        <p>
                            {{ $t('meron') }}
                            <span>x{{ Number(betUsers['list']?.['bet_info']?.['meron_odd']) || '1.86' }}</span>
                        </p>
                        <div class="betBoardDetailBox">
                            <Vue3autocounter
                                v-bind:startAmount="0"
                                v-bind:endAmount="userBets['meron']"
                                v-bind:duration="1"
                                separator=","
                                decimalSeparator="."
                                v-bind:decimals="2"
                                v-bind:autoinit="true"/>
                        </div>
                    </div>
                    <div
                        class="betBoard walaBoard"
                        v-on:click="clickBetBoard('wala',idx)"
                        v-bind:class="{ 'on': clickedBoardKey === 'wala' }">
                        <p>
                            {{ $t('wala') }}
                            <span>x{{ Number(betUsers['list']?.['bet_info']?.['wala_odd']) || '1.86' }}</span>
                        </p>
                        <div class="betBoardDetailBox">
                            <Vue3autocounter
                                v-bind:startAmount="0"
                                v-bind:endAmount="userBets['wala']"
                                v-bind:duration="1"
                                separator=","
                                decimalSeparator="."
                                v-bind:decimals="2"
                                v-bind:autoinit="true"/>
                        </div>
                    </div>
                </div>
                <div class="chipWrap">
                    <div
                        v-for="(chip, key) in chips['Basic']"
                        v-bind:key="key"
                        v-bind:class="[
                            chip['key'],
                            {
                                'activeChip': selectChipKey === chip['key'],
                                'noneActive': selectChipKey != '' && selectChipKey !== chip['key'] || !isBetStart,
                            }]"
                        class="chipBox"
                        v-on:click="clickChip(chip)">
                        <span>{{ chip['tx'] }}</span>
                        <div class="chipBorder">
                            <div class="hold left">
                                <div class="fill"></div>
                            </div>
                            <div class="hold right">
                                <div class="fill"></div>
                            </div>
                        </div>
                        <img v-bind:src="chip['src']">
                    </div>
                    <div class="bgChipsWrap">
                        <div
                            v-for="(chip, key) in chips['Basic']"
                            v-bind:key="key"
                            v-bind:class="[
                                chip['key'],
                                {
                                    'activeChip': selectChipKey === chip['key'],
                                    'noneActive': selectChipKey != '' && selectChipKey !== chip['key'] || !isBetStart,
                                }]"
                            class="chipBox"
                            v-on:click="clickChip(chip,idx,$event)">
                            <span>{{ chip['tx'] }}</span>
                            <div class="chipBorder">
                                <div class="hold left">
                                    <div class="fill"></div>
                                </div>
                                <div class="hold right">
                                    <div class="fill"></div>
                                </div>
                            </div>
                            <img v-bind:src="chip['src']">
                        </div>
                    </div>
                </div>
            </div>
            <div class="inGameBtmRightBox">
                <div class="scoreboardBox sbb2">
                    <ul class="betDetailBox">
                        <li
                            v-for="(img,key) in betDetailIcon2"
                            v-bind:key="key">
                            <img v-bind:src="img['src']">
                            <span>{{ img['tx1'] }}</span>
                        </li>
                    </ul>
                    <div class="scoreboardInner">
                        <BigRoad v-bind:gameType="gameType"/>
                    </div>
                </div>
                <div class="scoreboardBox sbb3">
                    <ul class="betDetailBox">
                        <li
                            v-for="(img,key) in betDetailIcon3"
                            v-bind:key="key">
                            <img v-bind:src="img['src']">
                            <span>{{ img['tx1'] }}</span>
                        </li>
                    </ul>
                    <div class="scoreboardInner">
                        <BigRoadText v-bind:gameType="gameType"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { chips, loginUser, topics, sabongInter } from '@/js/reactive';
    import { getCookie, setCollapsed } from "@/js/utils";
    import router from '@/router';
    import { SABONG_GAME_TYPE_IDX } from "@/js/const";
    import { setCurrencyInfo } from "@/js/common";
    import { setCurrencyChip, getGameResult, sabongInfo, generateSabongInfo, isShowBtnInterface, clearBetData, initData, userBets, betUsers, getBetUsers, getSabongEvents, bet, getVinusSabongRoomStatus, getVinusSabongFightNo } from "@/js/inGame/sabongInter";
    import { onMounted, onBeforeMount, onUnmounted, reactive, ref , watchEffect, watch, computed } from "vue";
    import moment from "moment-timezone";
    import { getBalance, getPing } from "@/js/request";
    import { generateSabongScoreBoard, sabongRoadMap } from "@/js/scoreBoard/generator";
    import { MQTT } from "@/plugins/mqtt";
    import { calcRate } from '@/js/inGame/exchange';

    import BaseRoad from "@/components/scoreBoard/BaseRoad";
    import BigRoad from "@/components/scoreBoard/BigRoad";
    import BigRoadText from "@/components/scoreBoard/BigRoadText";
    import InGameIcons from "@/components/common/InGameIcons";
    import SabongCam from "@/components/common/SabongCam";
    import TimeBank from "@/components/common/TimeBank";
    /*	https://vuejsexamples.com/creating-an-animated-number-counter-in-vue3 */
    import Vue3autocounter from 'vue3-autocounter';

    import { useRoute } from "vue-router";
    import store from "@/store"
    const route = useRoute();
    const clickedBoardKey = ref(undefined);
    const recentResult = ref(undefined);
    const rootWrap = ref(undefined);
    const currentFightNo = ref(undefined);
    const lastCallTime = ref(undefined);
    const currentGameResult = ref(undefined);
    const currentSubRooster = ref(undefined);
    const currentOdds = ref (undefined);
    const realWinAmount = ref(0);
    const isBetStart = ref(false);
    const gameType = route.params.gameType == 'pcc' ? 'vinusPcc' : route.params.gameType;
    const gameIdx = SABONG_GAME_TYPE_IDX[gameType];
    let prevEventId = ref(undefined);
    let betAnim = false;
    // 로그인한 유저의 CMS에 등록된 환율 정보
    const exchangeInfo = ref(undefined);

    watch(isBetStart, (newVal, oldVal) => {
        if(newVal == false && oldVal == true){
            isBetStart.value = false;
            selectChipKey.value = '';
        }
    });

    watchEffect(async () => {
        if(sabongInfo['rooms']?.['event_id']){
            prevEventId.value = sabongInfo['rooms']?.['event_id'];
        }
        if(sabongInfo['rooms']){
            let betStatus = getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'];
            isBetStart.value = betStatus == 'open' || betStatus == 'lastCall' ? true : false;
            lastCallTime.value = sabongInfo['rooms']?.last_call ? sabongInfo['rooms']?.last_call : undefined;
            if(currentFightNo.value && sabongInfo['rooms']['fight_no'] != currentFightNo.value){
                initData();
            }
            currentFightNo.value = sabongInfo['rooms']['fight_no'];
        }
        // 서버 토픽 게임IDx
        if(topics?.[`sabong_result_${gameIdx}`]){
            currentGameResult.value = topics[`sabong_result_${gameIdx}`]?.['rooster'] || '';
            currentSubRooster.value = topics[`sabong_result_${gameIdx}`]?.['subRooster'] || '';
            currentOdds.value = `MERON: ${topics[`sabong_result_${gameIdx}`]?.['meron_odd']} WALA: ${topics[`sabong_result_${gameIdx}`]?.['wala_odd']}`
            if(topics?.[`sabong_result_${gameIdx}`]?.user){
                let find = topics[`sabong_result_${gameIdx}`]['user']?.[getCookie('loginId')];
                if(find){
                    realWinAmount.value = find['win_amount'] > 0 ? find['win_amount'] : -find['bet_amount'];
                }
            }
            await getGameResult(gameType, prevEventId.value);
            let timer = setTimeout(async () => {
                currentGameResult.value = undefined;
                currentSubRooster.value = undefined;
                currentOdds.value = undefined;
                realWinAmount.value = 0;
                delete topics[`sabong_result_${gameIdx}`];
                //0326 generateSabongScoreBoard(sabongInfo['history'], gameType);
                clearTimeout(timer);
            }, 5000);
        }
    });
    const activeChipPosition = {
        x : 0,
        y : 0,
        chipTarget: {}
    }

    const setup = reactive({
        betProgressBarBox : "100%"
    });

    const selectChipKey = ref('');
    const selectChip = reactive({
        'chip': {}
    });
    const currentTime = reactive({
        'date': undefined,
        'time': undefined
    });
    const timer = {};
    const momentTimer = ref(undefined);

    const betCountDown = ref(5);
    let betCountDownTimer = undefined;
    const clearBetCountDown = () => {
        clearInterval(betCountDownTimer);
        betCountDownTimer = undefined;
        betCountDown.value = 5;
    }
    const clickBetConfirm = async () => {
        if(userBets['meron'] + userBets['wala'] + userBets['draw'] <= 0 || betCountDownTimer){
            return;
        }
        betCountDown.value -=1;
        betCountDownTimer = setInterval(async () => {
            if(betCountDown.value == 1){
                await bet('','',true,false,false);
                clearBetCountDown();
                return;
            }
            betCountDown.value -=1;
        }, 1000);
    }

    const clickBetCancel = () => {
        clearBetCountDown();
        clearBetData(false,false);
    }

    const clickChip = (chip,idx,event) => {
        
        if (!isBetStart.value) {
            return;
        }
        activeChipPosition.x = event.x;
        activeChipPosition.y = event.y;
        activeChipPosition.chipTarget = event;

        selectChipKey.value = chip['key'];
        selectChip['chip'] = chip;
    }

    const animationBoard = (event,idx) => {
        const x = event.clientX - activeChipPosition.x;
        const y = event.clientY - activeChipPosition.y;

        //0326
        /*
        const anim = activeChipPosition.chipTarget.target.parentNode.animate([
            {
                visibility: "visible",
                transform : "translate(0px, 0px) scale(1.5)",
                opacity   : "1",
            },
            {
                visibility: "hidden",
                transform : `translate(${x}px, ${y}px) scale(0.5)`,
                opacity   : "0",
            },
        ], {
            duration   : 300,
            iterations : 1,
        });
        if (anim) {
            anim.onfinish = () => {
                betAnim = false;
            };
        }*/
    }

    const clickBetBoard = async (betKey,idx) => {
        if(betCountDownTimer){
            return;
        }
        if(!betAnim){
            betAnim = true;
            animationBoard(event,idx);
        }

        clickedBoardKey.value = betKey;
        await bet(selectChip, betKey);
        setTimeout(() => {
            clickedBoardKey.value = undefined;
        }, 250);
    }

    onBeforeMount(async () => {
        window.addEventListener("resize", windowSetScale);
        await getSabongEvents();
        generateSabongInfo(gameType);
        exchangeInfo.value = await setCurrencyInfo();
        setCurrencyChip(route.params.gameType, 'Basic');
        if(sabongInfo['rooms']?.['event_id']){
            prevEventId.value = sabongInfo['rooms']?.['event_id'];
        }
        await getGameResult(gameType, prevEventId.value);
        //0326 await generateSabongScoreBoard(sabongInfo['history'], gameType);
        if(Object.keys(sabongInfo['rooms']).length > 0 && getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] != 'close'){
            await getBetUsers();
        }
        topics[`sabong_result_${gameIdx}`] = undefined;
        MQTT();
        getPing();
    });

    onMounted(async () => {
        window.dispatchEvent(new Event('resize'));
        await getSabongEvents();
        generateSabongInfo(gameType);
        timer['event'] = setInterval(async () => {
            await getSabongEvents();
            generateSabongInfo(gameType);
        }, 1000);
        timer['balance'] = setInterval( async () => {
            await getBalance(true);
        }, 3000);
        if(Object.keys(sabongInfo['rooms']).length > 0){
            await getBetUsers();
        }
        timer['betUsers'] = setInterval( async () => {
            if(Object.keys(sabongInfo['rooms']).length > 0 && getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] != 'close'){
                await getBetUsers();
            }
        }, 5000);
    });

    onUnmounted(() => {
        window.removeEventListener("resize", windowSetScale);
        Object.keys(timer).forEach(key => {
            clearInterval(timer[key]);
        });
    });

    momentTimer.value = setInterval(() => {
        currentTime['date'] = moment(moment.tz(moment() , "Asia/Manila")).format('YYYY-MM-DD');
        currentTime['time'] = moment(moment.tz(moment() , "Asia/Manila")).format('HH:mm:ss');
    }, 1000);

    const windowSetScale = (event) => {
        let scaleSize = 1;
        let clientAspectRatio = 1920 / 1080;
        let innerWidth = event.target.innerWidth;
        let innerHeight = event.target.innerHeight;

        if(innerHeight * clientAspectRatio <= innerWidth)
        {
            scaleSize = innerHeight / 1080;
        }
        else
        {
            scaleSize = innerWidth / 1920;
        }
        if(rootWrap.value){
            rootWrap.value.style.transform = `scale(${scaleSize})`;
        }
    }
    const betDetailIcon1 = reactive([
        {
            'src': require("@/assets/sabong/icon-m.png"),
            'tx1': computed(() => {

                if(loginUser['uuid'] == undefined)
                    return '';

                //console.log(loginUser['uuid'] );
                //return loginUser['uuid'] ? sabongRoadMap[gameType]['count']['meron'] : '';
                return '';
            })
        },
        {
            'src': require("@/assets/sabong/icon-w.png"),
            'tx1': computed(() => {
                if(loginUser['uuid'] == undefined)
                    return '';

                //return loginUser['uuid'] ? sabongRoadMap[gameType]['count']['wala'] : '';
                return '';
            })
        },
        {
            'src': require("@/assets/sabong/icon-d.png"),
            'tx1': computed(() => {
                if(loginUser['uuid'] == undefined)
                    return '';

                //return loginUser['uuid'] ? sabongRoadMap[gameType]['count']['draw'] : '';
                return '';
            })
        },
        {
            'src': require("@/assets/sabong/icon-c.png"),
            'tx1': computed(() => {
                if(loginUser['uuid'] == undefined)
                    return '';

                //return loginUser['uuid'] ? sabongRoadMap[gameType]['count']['cancel'] : '';
                return '';
            })
        }
    ]);

    const betDetailIcon2 = reactive([
        {
            'src': require("@/assets/sabong/rin-m.png"),
            'tx1': 'Meron'
        },
        {
            'src': require("@/assets/sabong/ring-w.png"),
            'tx1': 'Wala'
        },
        {
            'src': require("@/assets/sabong/ring-d.png"),
            'tx1': 'Draw'
        },
        {
            'src': require("@/assets/sabong/ring-c.png"),
            'tx1': 'Cancel'
        }
    ]);

    const betDetailIcon3 = reactive([
        {
            'src': require("@/assets/sabong/l-text.png"),
            'tx1': 'Llamado'
        },
        {
            'src': require("@/assets/sabong/d-text.png"),
            'tx1': 'Dehado'
        },
        {
            'src': require("@/assets/sabong/p-text.png"),
            'tx1': 'Parehasan'
        },
        {
            'src': require("@/assets/sabong/c-text.png"),
            'tx1': 'Cancel'
        }
    ]);

    const clickIcon = (key) => {
        if(key === 'exit'){
            router.push('/lobby');
        }
        if(key === 'full'){
            if (document.fullscreenElement) {
                document.exitFullscreen()
            } else {
                document.documentElement.requestFullscreen();
            }
        }
    }

    const toggleSlideMenu = {
        open:'0px',
        close:'-450px'
    }
    const isShow = ref(true);
    const slideMenu = () => {
        isShow.value = !isShow.value
        let menu = document.querySelector('.inGameMenu');
        menu.style.marginRight = isShow.value ? toggleSlideMenu.open : toggleSlideMenu.close;
    };

    const theme1 = () => {
        store.dispatch('changeTheme',0);
    }
    const theme2 = () => {
        store.dispatch('changeTheme',1);
    }

</script>
<style scoped>
.currency {
	color: white;
}
span.betCountdown {
	color: gold;
	font-size: 20px;
}
.cancelResultWrap {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	font-size: 24px;
}
.cancelResultWrap .resultBox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 30px;
	padding: 7px;
}

.resultBox.wala {
	background-color: #2056af;
	text-transform: uppercase;
}
.resultBox.meron {
	background-color: #b13636;
	text-transform: uppercase;
}
.resultBox.draw {
	background-color: #26813b;
	text-transform: uppercase;
}
.timeBankWrap{
	position: absolute !important;
	right: 15px;
	bottom: 15px;
	width: 120px;
	height: 120px;
}

.inGameWrap{
	width: 100%;
    /*height: 100vh;*/
    height: calc(100vh - 130px);
	padding: 10px;
	transform-origin: top;
}
.inGameTopBox{
	width: 100%;
	/*height: 816px;*/
    height: 716px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
}
.inGameTopMidBox{
	width: 1284px;
	padding: 0 10px;
}
.inGameBarWrap{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 3px;
}
.inGameVsBar{
	width: 80%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.inGameProgressBarLeft{
	position: relative;
	width: 590px;
	height: 100%;
	background-color: #5a5a5c;
	border-radius: 2rem 0 0 2rem;
	overflow-x: hidden;
}
.aniProgressBarWala{
	display: flex;
	flex-direction: row-reverse;
	gap: 10px;
	align-items: center;
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	background-color: #bb3a3a;
	border-radius: 0;
}
.aniProgressBarWala p{
	color: #ffce23;
	font-size: 24px;
}

.inGameProgressBarRight{
	position: relative;
	width: 590px;
	height: 100%;
	background-color: #5a5a5c;
	border-radius: 0 2rem 2rem 0;
	overflow-x: hidden;
}
.aniProgressBarMrn{
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: #2056af;
}
.aniProgressBarMrn p{
	color: #ffce23;
	font-size: 24px;
}
.VsBarCnt{
	width: 132px;
	height: 100%;
	font-size: 36px;
	line-height: 50px;
	text-align: center;
	color: #fafafa;
	background-color: #d5a922;
}
.ProgressBarHp{
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 50px;
	line-height: 50px;
	gap: 115px;
}


.ProgressBarHp > span{
	font-weight: bold;
	font-size: 26px;
	color: #fff;
}
.inGameProgressBarLeft .ProgressBarHp{
	justify-content: flex-start;
	padding-left: 14px;
}
.inGameProgressBarRight .ProgressBarHp{
	justify-content: flex-end;
	padding-right: 14px;
}


.videoWrap{
	position: relative;
	width: 100%;
	height: 616px;
    /*height: 716px;*/
	text-align: center;
	color: #fff;
	font-size: 40px;
	background-color: #1b1b1b;
	overflow: hidden;
	margin-top: 10px;
}
.videoWrap > p{
	position: absolute;
	line-height: 1.2em;
	top: 6px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	font-size: 16px;
	-webkit-text-stroke: 1px #000;
}
.videoWrap > img{
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}
.videoWrap video {
	width: 100%;
	height: auto;
}
.videoWrap .btmWrap{
	position: absolute;
	bottom: 6px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
}
.videoWrap .btmWrap button{
	width: 238px;
	height: 68px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	font-size: 20px;
    animation: slide-top .4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.videoWrap .btmWrap button.sabongCancelBtn{
	border: solid 2px #94a2b6;
	background-color: #667180;
	color: #fff;
}
.videoWrap .btmWrap button.sabongConfirmBtn{
	border: solid 2px #3f82e9;
	background-color: #2056af;
	color: #fff;
}

.inGameMenu{
	position: absolute;
	right: 0;
	top: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	background-color: rgba(0, 0, 0, 0.75);
	padding: 5px 7px 5px 22px;
	gap: 31px;
	border-radius: 14px 0 0 14px;
	z-index: 2;
    transition: all .5s ease;
}

.inGameMenu img{
	cursor: pointer;
    transition: all .3s ease;
}
InGameIcons:active{
    transform: scale(0.85);
}

.slideMenuBtn{
    transform: rotate(180deg);
}

.progressBox{
	width: 1264px;
	height: 32px;
	margin: 8px auto;
}
.progressBox .inGameProgressBar{
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #5a5a5c;
	border-radius: 2rem;
	overflow: hidden;
}

/* lastCall box-shadow 효과 */
.inGameProgressBar.lastCall{
    box-shadow: 0px 0px 10px 3px #F57D7D;
    animation: blink-1 1s infinite both;
}

.progressBox .inGameProgressBar .betTime{
    width: 100%;
    height: 100%;
    padding: 0 2%;
    position: absolute;
    z-index: 1;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.progressBox .ProgressBarHp{
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 1274px;
	height: 36px;
	gap: 115px;
}
.progressBox .ProgressBarHp > span{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-weight: bold;
	font-size: 26px;
	color: #fff;
}
.progressBox .aniProgressBarBet{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
}
.aniProgressBarBet.open {
	background-color: #00936f;
}

.aniProgressBarBet.lastCall {
	background-color: rgba(88,11,11,.8);
}

.progressBox p{
	position: absolute;
	z-index: 1;
	color: #fff;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px;
}

.userBetListMrn,
.userBetListWala{
	position: relative;
	width: auto;
	height: 100%;
}
.userBetListWrap{
	/*height: 816px;*/
    height: 716px;
	width: 308px;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: #2b2b2c;
	border: 1px solid #484848;
}
.listTopTxt{
    height: 34px;
    width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	font-size: 18px;
	margin-bottom: 6px;
    padding: 0 6px;
}
.userBetListWrap .listTopTxt > div{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
}
.userBetListWrap.listTopTxt img{
	margin-left: 3px;
}
.userBetListWrap.mrn{
    background-image: linear-gradient(to bottom, #2b2b2c, #2b2b2c 55%, #441a1a);
}
.userBetListWrap.mrn > .listTopTxt{
    background-color: #641b1b;
}
.userBetListWrap.mrn > .listTopTxt > h2{
	font-size: 20px;
    color: #ff6d6d;
}
.userBetListWrap.wala{
    background-image: linear-gradient(to bottom, #2b2b2c, #2b2b2c 55%, #182d4a);
}
.userBetListWrap.wala > .listTopTxt{
    background-color: #17346d;
}
.userBetListWrap.wala > .listTopTxt > h2{
    font-size: 20px;
    color: #6aa2ff;
}

.userBetListInner{
	display: flex;
	width: 100%;
	flex-direction: column;
    padding: 0 7px 6px;
}
.userBetListInner ul{
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 5px;
}
.userBetListInner ul li{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
}

.userBetListInner li > span{
	color: #fff;
}
.userBetListInner li p{
	color: #ffce23;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 5px;
}

.inGameBtmBox{
	margin-top: 8px;
	width: 100%;
	height: 234px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	gap: 8px;
}
.inGameBtmLeftBox{
	width: 584px;
	height: 100%;
}

.userPlayInfo{
	width: 100%;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
	margin-bottom: 6px;
}
.userPlayInfo > div{
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	font-size: 18px;
	background-color: #2b2b2c;
	border: 1px solid #484848;
	padding: 0 8px;
    gap: 10px;
}
.userPlayInfo > div ul{
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	color: #ffce23;
}

.scoreboardBox{
	width: 584px;
	background-color: #2b2b2c;
	border: 1px solid #484848;
	padding: 8px 5px 5px;
}
.scoreboardInner{
	width: 100%;
	/*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAABxJREFUKFNjZCAO/GckTh3DqEK8AQUOnv/EhCUAZm8KAZQzbbIAAAAASUVORK5CYII=") repeat #fff;
	background-size: 23px 23px;*/
}
.betDetailBox{
	width: 100%;
	height: 22px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 5px;
	margin-bottom: 9px;
}
.betDetailBox li{
	line-height: 22px;
	color: #fff;
	margin-right: 18px;
}
.sbb1 .scoreboardInner{
	height: 148px;
}
.sbb1 > .betDetailBox > li > img{
	width: 23px;
	vertical-align: middle;
	margin-right: 5px;
}

.inGameBtmMidBox{
	width: 716px;
	height: 100%;
	position: relative;
}
.battingBlock {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 7px;
	width: 720px;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	height: 235px;
	bottom: -5px;
	z-index: 50;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	cursor: not-allowed;
}
.betBoardBox{
    width: 100%;
    height: 144px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    gap: 6px;
    padding: 4px 0;
}

.betBoardBox .betBoard{
    position: relative;
    height: 100%;
    border-radius: 8px;
    padding: 4px;
}

.betBoardBox .betBoard.meronBoard {
    width: 360px;
    border: solid 1px #ff4343;
    background-image: linear-gradient(to top, #981e1e, #be4242);
    outline: solid 4px transparent;
    transition: outline 0.25s;
}
.betBoard.meronBoard.on {
    outline: solid 4px #ff4343;
    animation: click 0.20s;
}

.betBoardBox .betBoard.walaBoard {
    width: 360px;
    border: solid 1px #1d7dc7;
    background-image: linear-gradient(to top, #0d3880, #2056af);
    outline: solid 4px transparent;
    transition: outline 0.25s;
}
.betBoard.walaBoard.on {
    outline: solid 4px #1d7dc7;
    animation: click 0.2s;
}

.betBoard.meronBoard:active,
.betBoard.walaBoard:active{
     transform: scale(0.99);
 }

@keyframes click {
    0% {
        transform: scale(0.99);
    }
    50% {
        transform: scale(1.01);
    }
    100% {
        transform: scale(1);
    }
}

.betBoardBox .betBoard p{
	width: 100%;
	height: 102px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 28px;
	font-weight: bold;
	line-height: 24px;
}
.betBoardBox .betBoard p span{
	font-weight: 300;
	font-size: 20px;
    color: #19e657;
}
.betBoardBox .betBoard .betBoardDetailBox{
	position: absolute;
	bottom: 4px;
	left: 50%;
	transform: translateX(-50%);
	width: 90%;
	height: 26px;
	border-radius: 2rem;
	font-size: 18px;
	color: #ffce23;
	text-align: center;
	line-height: 26px;
}
.betBoardBox .betBoard.meronBoard .betBoardDetailBox{
	background-color: #371b1b;
}
.betBoardBox .betBoard.walaBoard .betBoardDetailBox{
	background-color: #1a1a35;
}
.chipWrap{
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 18px;
}
.chipBox{
	width: 72px;
	height: 72px;
	position: relative;
	cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chipBox > img{
	width: 72px;
	position: absolute;
	bottom: 0;
	left: 0;
}
.chipBox > span{
	width: 72px;
	height: 72px;
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	font-size: 22px;
	font-weight: bold;
	z-index: 1;
}

/* activeChip 220103 이은정 추가*/
.noneActive{
    opacity: 0.7;
}
.activeChip{
    opacity: 1;
    animation: chipClick 0.2s;
}
.activeChip:active{
    transform: scale(0.94);
}

@keyframes chipClick {
    0%{
        transform: scale(0.9);
    }
    50%{
        transform: scale(1.05);
    }
    100%{
        transform: scale(1);
    }
}

.activeChip .chipBorder{
	display: block;
    transform: scale(1.08);
    animation: bg 0.1s linear both ;
    animation-delay: 0.15s;
    opacity: 0.7;
}
.activeChip img{
    transform: scale(1.08);
}

.chipBorder {
    position: absolute;
    height: 82px;
    width: 82px;
    border-radius: 50%;
    overflow: hidden;
    display: none;
}
.hold {
    position: absolute;
    width: 100%;
    height: 100%;
    clip: rect(0px, 100px, 100px, 41px);
    border-radius: 100%;
}

.fill {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    clip: rect(0px, 41px, 100px, 0px);
    background-color: #29FF5B;
}

.left .fill {
    animation: left 0.1s linear both;
    animation-delay: 0.1s;
}

@keyframes left {
    0%{-webkit-transform:rotate(0deg);}
    100%{transform:rotate(-180deg);}
}

.right {
    transform: rotate(180deg);
}

.right .fill {
    animation: right 0.1s linear both ;
}

@keyframes right {
    0%{-webkit-transform:rotate(0deg);}
    100%{transform:rotate(-180deg);}
}
@keyframes bg {
    0%{background-color: transparent}
    100%{background-color: #29FF5B;}
}




.inGameBtmRightBox{
	width: 584px;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.sbb2,
.sbb3{
	width: 584px;
	height: 120px;
	padding: 4px 6px;
}
.inGameBtmRightBox .betDetailBox{
	gap: 13px;
	margin-bottom: 3px;
	height: 12px;
}
.inGameBtmRightBox .betDetailBox > li{
	font-size: 11px;
	line-height: 11px;
}
.inGameBtmRightBox .scoreboardInner{
	height: 93px;
	background-size: 15px;
}
.sbb2 > .betDetailBox > li > img{
	width: 13px;
	vertical-align: middle;
	margin-right: 5px;
}
.sbb3 > .betDetailBox > li > img{
	height: 20px;
	vertical-align: middle;
	margin-right: 5px;
}
.matchRsltWrap{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
}
.matchRsltWrap h1{
	font-size: 40px;
	line-height: 85px;
}
.resultPriceBox h2{
	font-size: 28px;
	color: #ffe539;
}
.resultPriceBox{
	width: 780px;
	height: 44px;
	line-height: 41px;
	text-align: center;
}
.matchRsltWrap.meron{
	background-color: rgba(88,11,11,.8);
}
.matchRsltWrap.wala{
	background-color: rgba(16,56,109,.8);
}
.matchRsltWrap.draw{
	background-color: rgba(18,100,29,.8);
}
.matchRsltWrap.cancel{
	background-color: rgba(32, 32, 32, 0.8);
}

.betPerson{
	color: #ffce23 !important;
}
.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.userBetListWrap.mrn.win{
	border: 4px solid #b3e7ff;
	animation: winNeonWala 1s infinite alternate;
}
@keyframes winNeonMrn {
	0%{
		box-shadow: none;
	}
	50%{
		box-shadow: 0 0 20px 1px #ff4343;
	}
	100%{
		box-shadow: none;
	}
}
.userBetListWrap.wala.win{
	border: 4px solid #b3e7ff;
	animation: winNeonWala 1s infinite alternate;
}
@keyframes winNeonWala {
	0%{
		box-shadow: none;
	}
	50%{
		box-shadow: 0 0 20px 1px #1d7dc7;
	}
	100%{
		box-shadow: none;
	}
}
@keyframes slide-top {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-12px);
    }
}
@keyframes blink-1 {
    0%{
        box-shadow: none;
    }
    50%{
        box-shadow: 0px 0px 10px 3px #F57D7D;
    }
    100%{
        box-shadow: none;
    }
}

.bgChipsWrap{
    position: absolute;
    height: 80px;
    width: initial;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    display: flex;
    justify-content: center;
}

</style>
