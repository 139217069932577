import { reactive, computed } from 'vue';
import { getBetUsersLobby } from '@/js/inGame/sabongInter';

const state = {
    room : {
        'vinusPcc': {
            'meron_odd': '-',
            'wala_odd': '-'
        },
        'inter': {
            'meron_odd': '-',
            'wala_odd': '-'
        },
        'wsa': {
            'meron_odd': '-',
            'wala_odd': '-'
        },
        'one': {
            'meron_odd': '-',
            'wala_odd': '-'
        },
        'nca': {
            'meron_odd': '-',
            'wala_odd': '-'
        }
    }
}

const mutations = {
    setRoomOdd: (state, payload) => {
        state.room[payload.roomName] = JSON.parse(JSON.stringify(payload));
    }
}

const actions = {
    setRoomOdd: async ({ state, commit }, payload) => {
        const res = await getBetUsersLobby(payload);
        commit('setRoomOdd', res);
    }
}

const getters = {
    getRoomOdd: (state) => {
        return state.room;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}