import {reactive} from "vue";

/**
 * @name: 현재 열려있는 modal 정보들
 */
export const modals = reactive({});

/**
 * @name: 모달 열기/닫기
 * @param key String 열려는 모달키,
 * @param isOpen Boolean 열고닫기 여부
 */
export const showModal = (key, isOpen) => {
    modals[key] = isOpen;
}

