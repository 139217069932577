<template>
    <div class="modalBg">
        <div class="modalLog">
            <div class="modalLogTopBox">
                <h1>Daily Reword</h1>
                <span
                    class="exitImg"
                    v-on:click="hideModal">
                    <img
                        alt="close"
                        src="@/assets/mobile/btn-close.png">
                </span>
            </div>
            <div class="modalInner">
                <div class="dailyWrap">
                    <img
                        alt="daily"
                        src="@/assets/baccarat-daily.png">
                </div>
                <div class="dailyBtmBox">
                    <button class="rewardBtn">
                        Get rewards
                    </button>
                </div>
            </div>
        </div>
        <div
            class="backdrop"
            v-on:click="hideModal"></div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";

    export default defineComponent({
        data() {
            return {

            }
        },
        methods: {
            hideModal(){
                this.$emit('setModalKey', '');
            }
        }
    })
</script>
<style scoped>
.modalBg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 997;
}
.backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 998;
}
.modalLog{
    position: relative;
    width: 35vw;
    background-color: #2e3033;
    height: auto;
    border: 1px solid #bbb;
    border-radius: 6px;
    z-index: 999;
}
.modalLogTopBox{
    height: 92px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #2e3033;
    padding: 1%;
    color: #fff;
    border-radius: 6px 6px 0 0;
}
.modalLogTopBox h1{
    font-size: 32px;
}
.exitImg{
    position: absolute;
    right: 0;
    margin-right: 2%;
    height: 28px;
    cursor: pointer;
}
.exitImg img{
    width: 28px;
    height: auto;
}
.modalInner{
    padding: 0 10px 10px;
    background-color: #2e3033;
    border-radius: 0 0 6px 6px;
}

/*내용*/
.dailyWrap{
    width: 100%;
    background-color: #1b1b1b;
    height: 490px;
    overflow-y: hidden;
}
.dailyWrap > img{
    width: 100%;
}
.dailyBtmBox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 107px;
}
.rewardBtn{
    width: 222px;
    height: 56px;
    background-image: linear-gradient(to bottom, #6696f6, #2352af);
    border: 1px solid #c4c4c6;
    color: #f1f1f1;
    font-size: 20px;
}
</style>