<template>
    <header>
        <div
            class="headerWrap">
            <ul class="headerLeftBox">
                <li>
                    <img
                        v-bind:src="logoSrc"
                        alt="vg">
                </li>
                <li v-if="deployType != 'sabong'">
                    <img
                        src="../../assets/header/user-icon.png"
                        alt="user id"
                        v-on:click.stop="clickUserId()">
                    {{ loginUser?.['id'] }}
                </li>
                <li class="changeMoneyView">
                    {{ moneyTitle }}
                </li>
                <li
                    class="inrBox"
                    v-numberFormat="isShowPcc ? loginUser['pccBalance'] : loginUser['balance']"></li>
                <li class="inrBox">
                    {{ loginUser['currency'] }}
                </li>
            </ul>
            <ul class="headerCenterBox">
                <li
                    v-for="(menu, key) in centerMenu"
                    v-bind:class="{ 'hide': menu['hide'] }"
                    v-on:click="menu['click']"
                    v-bind:key="key">
                    {{ $t(menu['key']) }}
                </li>
                <li
                    class="lngBox"
                    v-on:click="isShowLang = !isShowLang">
                    {{ $t("language") }}
                    <img src="../../assets/icons/arrow2.png"/>
                    <transition name="slide">
                        <div
                            class="langWrap"
                            v-if="isShowLang">
                            <div
                                class="lang"
                                v-for="(lang, idx) in langArr"
                                v-bind:key="idx"
                                v-on:click.stop="lang['click']"
                                v-bind:class="{ 'selected': $i18n.locale == lang['key'] }">
                                <div class="flag">
                                    <img
                                        v-bind:src="lang['flag']"
                                        width="18"
                                        height="18"/>
                                </div>
                                <div>
                                    {{ lang['title'] }}
                                </div>
                            </div>
                        </div>
                    </transition>
                </li>
                <li
                    class="LoginBox">
                    <span
                        v-on:click="logout()"
                        v-if="loginUser['uuid']"
                        class="logoutTx">
                        {{ $t("logout") }}
                    </span>
                    <div
                        v-else
                        v-on:click.stop="$store.commit('modal/showModal', { modalKey: 'signin', open: true, styles: { width: '400px'}})">
                        {{ $t("signin") }}
                    </div>
                </li>
            </ul>
        </div>
        <Modal
            v-if="isShowModal"
            v-bind:title="modalTitle"
            v-bind:modalKey="modalKey"
            v-on:setModalKey="setModalKey"
            v-on:hideModal="hideModal"/>
        <transition name="slideUserInfo">
            <ModalUserInfo
                v-if="isShowUserInfo"
                v-on:showUserInfo="showUserInfo"/>
        </transition>
        <Daily
            v-if="modalKey === 'daily'"
            v-on:setModalKey="setModalKey"/>
    </header>
</template>
<script>
    import { defineComponent } from "vue";
    import { loginUser, timer, sabong } from "@/js/reactive";
    import { decryptData, setNumberFormat, getCookie } from "@/js/utils";
    import { showModal } from "@/js/modal/modals";
    import { getBalance } from "@/js/request";
    import Modal from "@/components/popup/Modal";
    import ModalUserInfo from "@/components/popup/ModalUserInfo";
    import Daily from "@/components/popup/Daily";

    export default defineComponent({
        name: "Header",
        components: {
            Modal,
            ModalUserInfo,
            Daily
        },
        data() {
            return {
                deployType: process.env.VUE_APP_DEPLOY_GAME_TYPE,
                logoSrc: process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? require("@/assets/sabong/logo/VS.png") : require("@/assets/vgLobbyLogo.png"),
                isShowUserInfo: false,
                isApiCalling: false,
                modalTitle: '',
                modalKey: '',
                isShowModal: false,
                isShowChangeMoney: false,
                isShowLang: false,
                userBalance: {},
                loginUser: loginUser,
                langArr: [
                    {
                        'key': 'en',
                        'title': this.$t("en"),
                        'click': () => {
                            this.$i18n.locale = 'en'
                            this.isShowLang = false;
                        },
                        'flag': require(`../../assets/flag/flag-english.png`),
                        'lock': false
                    },
                    {
                        'key': 'ko',
                        'title': this.$t("ko"),
                        'click': () => {
                            this.$i18n.locale = 'ko'
                            this.isShowLang = false;
                        },
                        'flag': require(`../../assets/flag/flag-korean.png`),
                        'lock': true
                    },
                    {
                        'key': 'ja',
                        'title': this.$t("ja"),
                        'flag': require(`../../assets/flag/flag-japanese.png`),
                        'click': () => {
                            this.$i18n.locale = 'ja'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'hi',
                        'flag': require(`../../assets/flag/flag-india.png`),
                        'title': this.$t("hi"),
                        'click': () => {
                            this.$i18n.locale = 'hi'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'ch',
                        'title': this.$t("ch"),
                        'flag': require(`../../assets/flag/flag-chinese.png`),
                        'click': () => {
                            this.$i18n.locale = 'ch'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'vt',
                        'title': this.$t("vt"),
                        'flag': require(`../../assets/flag/flag-vietnamese.png`),
                        'click': () => {
                            this.$i18n.locale = 'vt'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'th',
                        'flag': require(`../../assets/flag/flag-thai.png`),
                        'title': this.$t("th"),
                        'click': () => {
                            this.$i18n.locale = 'th'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'sp',
                        'flag': require(`../../assets/flag/flag-spanish.png`),
                        'title': this.$t("sp"),
                        'click': () => {
                            this.$i18n.locale = 'sp'
                            this.isShowLang = false;
                        },
                        'lock': true
                    }
                ],
                centerMenu: [
                    {
                        'key': 'daily',
                        'title': this.$t("daily"),
                        'click': () => {
                            this.modalKey = 'daily';
                            this.isShowModal = false;
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false
                    },
                    {
                        'key': 'shop',
                        'title': this.$t("shop"),
                        'click': () => {
                            this.modalTitle = this.$t("shop");
                            this.modalKey = 'shop';
                            this.isShowModal = true;
                            //showModal('shop', true);
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false
                    },
                    {
                        'key': 'history',
                        'title': this.$t("history"),
                        'click': () => {
                            this.modalTitle = this.$t("history");
                            this.modalKey = 'history';
                            this.isShowModal = true;
                            // if(process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live'){
                            //     showModal('history', true);
                            // }
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false
                    },
                    {
                        'key': 'rank',
                        'title': this.$t("rank"),
                        'click': () => {
                            this.modalTitle = this.$t("rank");
                            this.modalKey = 'rank';
                            this.isShowModal = true;
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false
                    },
                    {
                        'key': 'help',
                        'title': this.$t("help"),
                        'click': () => {
                            /*                  this.modalTitle = this.$t("help");
                            this.modalKey = 'help';
                            this.isShowModal = true;*/
                            // showModal('help', true);
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false
                    },
                ],
                userInfo: {},
                isLoginTextShow: false,
                isShowPcc: false,
                moneyTitle: 'VINUS LIVE'
            }
        },
        watch: {
            userInfo: {
                deep: true,
                handler(val){
                    loginUser['currency'] = val['currency_code'];
                    loginUser['id'] = val['id'];
                }
            }
        },
        async mounted() {
            getBalance();
            await this.getUserDetail();
        },
        unmounted() {
            clearTimeout(timer['balance']);
        },
        methods: {
            clickUserId(){
                this.isShowUserInfo = !this.isShowUserInfo;
            },
            changeShowMoneyView(isShowPcc){
                this.isShowPcc = isShowPcc;
                this.moneyTitle = this.isShowPcc ? 'PCC' : 'VINUS LIVE';
                this.isShowChangeMoney = false;
            },
            showUserInfo(val) {
                this.isShowUserInfo = val
            },
            hideModal(hide) {
                this.isShowModal = hide;
            },
            setModalKey(key){
                this.modalKey = key;
            },
            async logout() {
                if(this.isApiCalling){
                    return;
                }
                this.isApiCalling = true;
                await this.apis.logout();
                if(process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong'){
                    sabong['loginUser'] = undefined;
                }
                this.$router.push('/');
            },
            async getUserDetail(){
                const res = await this.apis.getUserDetail({
                    'userId': getCookie('loginId')
                });
                this.userInfo = res['decrypt_data']['search'];
            },
            loginIconOver(isOver){
                this.isLoginTextShow = isOver ? true : false;
            },
            ChangeMoneyBox(){
                this.isShowChangeMoney = !this.isShowChangeMoney;
            }
        }
    });
</script>
<style scoped>
	.changeMoneyView {
		cursor: pointer;
	}
	.headerCenterBox li.hide {
		display: none;
	}

    .slide-leave-active{
        height: 322px;
        transition: height .2s ease-in-out;
    }
    .slide-leave-to{
        height: 0;
        transition: height .2s ease-in-out;
    }

	.slideUserInfo-enter {

	}

	.slideUserInfo-enter-active {
		animation: slide-in 1s ease-out forwards;
		/* forwards = element가 finishing position에 붙어놓음  */
		transition: opacity .5s;
	}

	.slideUserInfo-leave {

	}
	.slideUserInfo-leave-active {
		animation: slide-out 1s ease-out forwards;
		transition: opacity .5s;
		opacity: 0;
	}

	@keyframes slide-in {
		from {
			transform: translateX(-500px);
		}
		to {
			transform: translateX(0);
		}
	}

	@keyframes slide-out {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-500px);
		}
	}


    @keyframes slideDown {
        from {
            height: 0;
        }
        to {
            height: 322px;
        }
    }

	.lock{
		color: gray;
		cursor: not-allowed !important;
	}
	header{
		width: 100%;
		height: 44px;
		color: #fff;
		background-color: #2F3033;
	}
	.headerWrap{
		width: 100%;
		height: inherit;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 10px 15px;
	}
    ul.headerLeftBox >li > img{
		max-width: 100%;
		height: auto;
		margin-top: 5px;
	}
	.headerWrap ul{
		display: flex;
		flex-direction: row;
		gap: 25px;
		align-items: center;
	}
	.headerLeftBox{
		align-items: center;
	}
    ul.headerLeftBox > li{
		font-size: 14px;
	}
    ul.headerLeftBox > li:nth-child(2),
	.headerCenterBox li,
	.headerRightBox div{
		cursor: pointer;
	}
	.lngBox > img{
		transform: rotate(180deg);
        vertical-align: middle;
	}
	.lngBox .langWrap {
		position: absolute;
		top: 46px;
		right: 85px;
		background-color: #1b1b1b;
		outline: 1px solid #bbbbbb;
		width: 158px;
		z-index: 2;
        animation: slideDown .2s ease-in-out;
        overflow: hidden;
	}
	.lngBox .langWrap .lang {
		display: flex;
		width: 100%;
		height: 40px;
		align-items: center;
		justify-content: flex-start;
        font-size: 14px;
	}
	.lngBox .langWrap .lang .flag {
		display: flex;
		margin: 5px;
		align-items: center;
	}
	.lngBox .langWrap .lang.selected {
		background-color: #3c4149;
	}
    .lang:hover{
        background-color: #3c4149;
    }
	.inrBox{
		color: #ffce70;
	}


    /* 211027추가 */
    ul.headerLeftBox > li:nth-child(2){
        width: 150px;
        height: 34px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        border: 1px solid #fff;
        border-radius: 20px;
        font-size: 18px;
    }
    ul.headerLeftBox > li:nth-child(2) img{
        width: 34px;
        margin-left: -1px;
        margin-top: 0 !important;
    }

    /*211117 한유정 추가*/
    .headerCenterBox > li{
        font-size: 11px !important;
        transition: all .3s ease;
        padding: 0 10px;
    }
    .headerCenterBox > li:hover{
        font-size: 14px !important;
        transition: all .3s ease;
    }
    .openSlide{
        margin-left: 0;
        transition: all 1s ease;
    }
    .closeSlide{
        margin-left: -518px;
        transition: all 1s ease;
    }

    /*211122 한유정 추가*/
    .LoginBox{
        width: 23px;
        height: 21px;
		margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .5s ease;
		white-space: nowrap;
    }
    .logoutTx{
        position: absolute;
        line-height: 30px;
        text-align: center;
    }
	.logoutTx {
		white-space: nowrap;
		position: relative;
	}
	.logoutTx:lang(ko) {
		top: 3px;
	}

    /* sabong header 211125 이은정 추가*/
    .sabongHeaderBg{
        background-color: #1b1b1b;
    }
    .sabongHeader{
        padding-right: 20px !important;
        border-right: 1px solid #969696 !important;
		border: 0 !important;
    }

    .headerCenterBox > .lngBox {
        padding: 20px 10px;
    }

    /*211209한유정 추가*/
    .dropdownChangeMoney{
        color: #fff;
        vertical-align: middle;
        cursor: pointer;
        position: relative;
    }
    .dropdownChangeMoney > ol{
        width: 275px;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 30px;
        background-color: #1b1b1b;
        border: 1px solid #fff;
        list-style-type: none;
        z-index: 1;
    }
    .dropdownChangeMoney > ol > li{
        font-size: 14px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }
    .dropdownChangeMoney > ol > li > span{
        color: #ffce70;
    }
    .dropdownChangeMoney > ol > li:hover{
        background-color: #3c4149;
    }
</style>
