<template>
    <div
        v-if="props.isInGame"
        class="scoreBoardGroup">
        <div
            v-bind:class="[props.options['size'], { 'bigSb': isResize }]"
            class="scoreBoard bigEyeSize"
            v-on:click.stop="toggleResize()">
            <div
                class="row"
                v-for="(row, rowCount) in props.scoreBoard"
                v-bind:key="rowCount">
                <div
                    class="cell bigEyeCelleSize"
                    v-for="(cell, cellIdx) in row"
                    v-bind:key="cellIdx"
                    v-bind:class="[getScoreClass(cell), props.options['size'], { 'tie': getTieCount(cell) > 0 } ]">
                    {{ getTieCount(cell) }}
                    <div
                        class="point pp"
                        v-bind:class="[options['size']]"
                        v-if="getScoreClass(cell).indexOf('pp') > -1">
                    </div>
                    <div
                        class="point bp"
                        v-bind:class="[options['size']]"
                        v-if="getScoreClass(cell).indexOf('bp') > -1">
                    </div>
                </div>
            </div>
        </div>
        <div class="vertical">
            <ScoreBoard1Gun
                v-bind:scoreBoard="scoreBoard1G"
                v-bind:options="props.options"/>
            <ScoreBoard2Gun
                v-bind:scoreBoard="scoreBoard2G"
                v-bind:options="props.options"/>
            <ScoreBoard3Gun
                v-bind:scoreBoard="scoreBoard3G"
                v-bind:options="props.options"/>
        </div>
    </div>

    <div
        v-else
        class="scoreBoard"
        v-bind:class="[props.options['size'],{ 'mobileSize': props.isMobile }]">
        <div
            class="row"
            v-for="(row, rowCount) in props.scoreBoard"
            v-bind:key="rowCount">
            <div
                class="cell"
                v-for="(cell, cellIdx) in row"
                v-bind:key="cellIdx"
                v-bind:class="[getScoreClass(cell), { 'mobileSize': props.isMobile }, props.options['size'], { 'tie': getTieCount(cell) > 0 }]">
                {{ getTieCount(cell) }}
                <div
                    class="point pp"
                    v-bind:class="[options['size']]"
                    v-if="getScoreClass(cell).indexOf('pp') > -1">
                </div>
                <div
                    class="point bp"
                    v-bind:class="[options['size']]"
                    v-if="getScoreClass(cell).indexOf('bp') > -1">
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { BACCARAT_SCORE_IDX } from "@/js/const";
    import { roadMap, getTieCount, getScoreClass, getBigRoad } from "@/js/scoreBoard/generator";
    import ScoreBoard1Gun from "@/components/common/ScoreBoard1Gun";
    import ScoreBoard2Gun from "@/components/common/ScoreBoard2Gun";
    import ScoreBoard3Gun from "@/components/common/ScoreBoard3Gun";
    import { ref, defineProps, onMounted } from "vue";
    const isResize = ref(false);
    const props = defineProps({
        isMobile: {
            type: Boolean,
            default: false
        },
        isInGame: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default: () => {
                return { size: 's' }
            }
        },
        scoreBoard: {
            type: Object,
            default: () => {
                return {}
            }
        },
        scoreBoard1G: {
            type: Object,
            default: () => {
                return {}
            }
        },
        scoreBoard2G: {
            type: Object,
            default: () => {
                return {}
            }
        },
        scoreBoard3G: {
            type: Object,
            default: () => {
                return {}
            }
        }
    });
    // 중국점 확대
    const toggleResize = () => {
        isResize.value = !isResize.value;
    };
    
</script>
<style scoped>
	.scoreBoard.s,
	.scoreBoard.m,
	.scoreBoard.sm {
		overflow-x: auto;
		overflow-y: hidden;
		height: inherit;
		background-attachment: local;
		background-image: linear-gradient(90deg, gray 1px, transparent 0), linear-gradient(gray 1px, transparent 0);
        scroll-snap-type: x mandatory;
	}

    .scoreBoardGroup,
	.scoreBoard{
		width: 100%;
		background-color: white;
		display: flex;
		flex-direction: column;
		overflow-x: hidden;
	}

	.scoreBoardGroup {
		transform: scale(0.96);
		position: relative;
		top: -6px;
		width: 584px;
        height: 179px;
		left: -2px;
		flex-direction: column;
		overflow: hidden;
	}
	.scoreBoardGroup .vertical {
		/*display: flex;
		flex-direction: row;
		align-items: center;*/
		outline: 3px solid #1a1f3f;
		height: 85px;
		min-height: 85px;
        cursor: pointer;
        position: relative;
	}
    .vertical > .scoreBoard{
        position: absolute;
    }
    .vertical > .scoreBoard.m{
        width: 33.3%;
    }
    .vertical .scoreBoard:nth-child(1){
        left: 0;
    }
    .vertical .scoreBoard:nth-child(2){
        left: 33.3%;
    }
    .vertical .scoreBoard:nth-child(3){
        right: 0;
    }

    .vertical .scoreBoard:not(.vertical .scoreBoard:first-child) {
		border-left: 3px solid #1a1f3f;
	}

	.scoreBoard.bigEyeSize {
		background-size: 15px 15px !important;
		height: 92px;
        margin-bottom: 3px;
        cursor: pointer;
	}
    .scoreBoard.mobileSize {
        background-size: 6px 6px !important;
    }
	.scoreBoard.s {
		background-size: 8px 8px;
	}
	.scoreBoard.m {
		background-size: 29px 28px;
	}
	.scoreBoard.sm {
		background-size: 18px 18px !important;
	}

	.scoreBoard .row {
		display: flex;
		flex-direction: row;
        scroll-snap-align: end;
	}
    .vertical > .scoreBoard{
        overflow-x: hidden;
    }
	.scoreBoard .cell {
		position: relative;
		border-radius: 30px;
		box-sizing: border-box;
	}

	.cell.s {
		min-width: 8px !important;
		height: 8px !important;
		font-size: 3px !important;
	}

	.cell.one, .cell.two, .cell.three {
		width: 10px !important;
		height: 9px !important;
		margin-left: 0 !important;
	}

	.point.s {
		width: 1px;
		height: 1px;
	}

	.cell.mobileSize:not(.cell.sm) {
		width: 6px!important;
		height: 6px!important;
	}

	.bigEyeCelleSize {
		margin-left: 0 !important;
		min-width: 15px !important;
		height: 15px !important;
	}

	.cell.m {
		width: 27px;
		height: 28px;
		margin-left: 2px;
	}

	.cell.sm {
		width: 18px;
		height: 18px;
	}

	.scoreBoard .cell.p {
		border: 1px solid blue !important;
	}
	.scoreBoard .cell.b {
		border: 1px solid red !important;
	}
	.scoreBoard .cell.tie {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		color: black;
		font-size: 14px;
		background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="100%" x2="100%" y2="0" stroke="green"/></svg>');
		border:1px solid green;
	}
	.scoreBoard .point.bp {
		border-radius: 100px;
		background-color: red;
		display: flex;
	}

	.scoreBoard .point.pp {
		background-color: blue;
		display: flex;
	}

	.point {
		position: absolute;
		display: none;
		width: 5px;
		height: 5px;
		border-radius: 25px;
		border: 1px solid gray;
	}
	.point.pp {
		top: 0;
		left: 0;
		background-color: blue !important;
		border: 1px solid gray;
	}
	.point.bp {
		bottom: 0;
		right: 0;
		background-color: red !important;
		border: 1px solid gray;
	}

    /*==== 스크롤 css 시작 ====*/
    ::-webkit-scrollbar{
        height: 5px;
    }
    ::-webkit-scrollbar-track {
        background-color: #5B80BD;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #2056af;
    }
    /*==== 스크롤 css 끝 ====*/


    /*211118 한유정 추가*/
    @keyframes ani_scoreBoardBig {
        0%{
            transform: scale(1);
            transform-origin: top left;
        }
        100%{
            transform: scale(2);
            transform-origin: top left;
        }
    }
    .scoreBoard.bigSb{
        animation: ani_scoreBoardBig .5s 1 alternate forwards;
        transition: all 0s ease;
        z-index: 999;
        transform-origin: top left;
    }
</style>