<template>
    <div class="modalBg confirm">
        <div class="modalLog">
            <div class="dealerWrap">
                <div class="dealerImg">
                    <img v-bind:src="selectedDealer['img']"/>
                </div>
                <div class="dealerName">
                    {{ selectedDealer['name'] }}
                </div>
                <div class="dealerDays">
                    (x{{ selectedDealer['expiry'] }} Days)
                </div>
                <div class="price">
                    <p>{{ $t('price') }} : <span v-number-format="selectedDealer['sell_price']"></span></p>
                </div>
                <p class="would">
                    {{ $t('would') }}
                </p>
            </div>
            <div class="btnWrap">
                <button
                    class="cancelBtn"
                    v-on:click.stop="$emit('buyCancel')">
                    {{ $t('cancel') }}
                </button>
                <button
                    class="buyBtn"
                    v-on:click.stop="$emit('buyDealer')">
                    {{ $t('confirm') }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    export default defineComponent({
        props: ['selectedDealer']
    });
</script>
<style scoped>
	.modalBg.confirm{
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.3);
		z-index: 31;
	}

	.modalLog{
		position: relative;
		width: 73vw;
		height: 415px;
		background-color: #2e3033;
		border: 1px solid #c8c8c8;
		border-radius: 2%;
		padding: 13px 16px;
		display: flex;
		flex-direction: column;
		gap: 13px;
	}

	.dealerWrap{
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 340px;
		width: 100%;
		background-color: #1b1b1b;
		padding: 17px 0;
		color: #ffffff;
	}
	.dealerWrap .dealerImg{
		position: relative;
		width: 60%;
		height: 202px;
		text-align: center;
		background-image: url("../../../assets/mobileBg/item-bg.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		justify-items: flex-start;
	}
	.dealerWrap .dealerImg img{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 95%;
	}
	.dealerWrap .dealerName{
		font-size: 16px;
		margin-top: 2%;
	}
	.dealerWrap .dealerDays{
		font-size: 16px;
		margin-bottom: 2%;
	}
	.dealerWrap .price{
		width: 175px;
		height: 30px;
		background-image: url("../../../assets/mobile/price-bg.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #e7b106;
		font-size: 19px;
	}
	.dealerWrap .would{
		font-size: 16px;
		margin: 7% 0 0 0;
	}
	.btnWrap{
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 12px;
	}
	.btnWrap button{
		height: 35px;
		background-size: cover;
		background-repeat: no-repeat;
		color: #ffffff;
		font-size: 17px;
	}
	.cancelBtn{
		background-image: url("../../../assets/shop/btn-gray-2.png");
	}
	.buyBtn{
		background-image: url("../../../assets/shop/btn-blue-2.png");
	}
</style>