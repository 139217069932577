<template>
    <div class="table">
        {{ $t('baccarat') }} | {{ $t('table') }}-{{ tableNo }}<span v-if="gameId">
            ({{ $t('round') }}: {{ gameId }})
        </span>
        |{{ $t('min') }} - {{ $t('max') }} | <span v-numberFormat="betMinMax['p']['min']"></span>-<span v-numberFormat="betMinMax['p']['max']"></span>
    </div>
    <div class="roundId">
        <span v-if="gameNo">
            {{ $t('roundId') }}: {{ gameNo }}
        </span>
    </div>
    <div>
        <span>{{ currentTime }} (GMT-MNL)</span>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import moment from "moment-timezone";

    export default defineComponent({
        data(){
            return {
                momentTimer: undefined,
                currentTime: 0
            }
        },
        unmounted() {
            clearInterval(this.momentTimer);
        },
        mounted(){
            if(!this.momentTimer){
                this.momentTimer = setInterval(() => {
                    this.currentTime = moment(moment.tz(moment() , "Asia/Manila")).format('HH:mm:ss');
                }, 1000);
            }
        },
        props: [
            'tableNo',
            'gameId',
            'gameNo',
            'InGameRoomInfo',
            'betMinMax'
        ]
    });
</script>
<style scoped>
    .table{
        margin-top: 15px;
    }
</style>
