<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('shop') }}</h1>
            <span
                class="exitImg"
                v-on:click.stop="$emit('closeModal')">
                <img
                    alt="close"
                    src="@/assets/mobile/btn-close.png">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="shopWrap_M">
                <div class="currencyBox">
                    <div
                        class="balance"
                        v-number-format="loginUser['balance']">
                    </div>
                    <div class="currency">
                        {{ loginUser['currency'] }}
                    </div>
                </div>

                <div class="shopTab">
                    <ul>
                        <li
                            v-on:click.stop="clickTab('1')"
                            v-bind:class="{ 'select': tabKey == '1' }">
                            {{ $t('dealerSkin') }}
                        </li>
                        <li class="lock">
                            {{ $t('cardSkin') }}
                        </li>
                        <li class="lock">
                            {{ $t('dealerProfile') }}
                        </li>
                    </ul>
                </div>

                <div class="shopBody">
                    <Loading v-if="loading['isLoading']"/>
                    <div
                        class="dealerWrap"
                        v-for="(dealer, key) in dealers"
                        v-bind:key="key">
                        <div class="dealerImg">
                            <img v-bind:src="getDealerImage(dealer)"/>
                            <div
                                class="info"
                                v-on:click.stop="showPreviewModal(dealer)">
                                <img src="../../../assets/shop/btn-minmax.png">
                            </div>
                            <div
                                class="use"
                                v-if="checkDealer(dealer)['key'] == 'unequip'">
                                {{ $t('beInUse') }}
                            </div>
                        </div>
                        <div
                            class="buyBtn"
                            v-bind:class="{ 'disabled': checkDealer(dealer)['isBuy'] }">
                            <button
                                v-bind:class="checkDealer(dealer)['key']"
                                v-on:click.stop="showConfirmModal(dealer)">{{ $t(checkDealer(dealer)['key']) }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <keep-alive>
        <ShopConfirm
            v-if="isShowConfirm"
            v-bind:selectedDealer="selectedDealer"
            v-on:buyCancel="buyCancel"
            v-on:buyDealer="buyDealer"/>
    </keep-alive>
    <keep-alive>
        <ShopDetl
            v-if="isShowPreview"
            v-on:changeThum="changeThum"
            v-on:showConfirmModal="showConfirmModal"
            v-on:hidePreviewModal="hidePreviewModal"
            v-bind:myDealers="myDealers"
            v-bind:equipDealer="equipDealer"
            v-bind:selectedDealer="selectedDealer"
            v-bind:selectThumType="selectThumType"
            v-bind:selectedThums="selectedThums"/>
    </keep-alive>
</template>
<script>
    import { defineComponent } from "vue";
    import moment from "moment";
    import { loginUser, loading } from "@/js/reactive";
    import { setNumberFormat, isMobile } from "@/js/utils";
    import { DEALER_INFO } from "@/js/const";
    import ShopConfirm from "@/components/mobile/popup/ShopConfirm";
    import ShopDetl from "@/components/mobile/popup/ShopDetl";
    import Loading from "@/components/common/Loading";

    export default defineComponent({
        components: {
            ShopConfirm,
            ShopDetl,
            Loading
        },
        data(){
            return {
                loading: loading,
                dealers: [],
                myDealers: {},
                selectedDealer: {},
                selectedThums: [],
                selectThumType: 'a',
                DEALER_INFO: DEALER_INFO,
                loginUser: loginUser,
                isShowConfirm: false,
                isShowPreview: false,
                equipDealer: {},
                tabKey: 1,
            }
        },
        async beforeMount() {
            await this.getMyDealerList();
            await this.getEquipDealer();
            await this.getDealerInfo();
        },
        methods: {
            isMobile,
            hidePreviewModal(){
                this.isShowPreview = false;
                this.$emit('showPreview', false);
                this.selectThumType = 'a';
                this.$emit('showCloseBtn', true);
            },
            clickTab(key){
                this.tabKey = key;
            },
            async getDealerInfo(){
                const res = await this.apis.getShopInfo();
                this.dealers = res['decrypt_data']['dealers'];
                this.dealers.splice(this.dealers.findIndex(dealer => dealer['comment'] == 'victoria'), 1);
            },
            async updateEquipStatus(dealer){
                const res = await this.apis.updateEquipStatus({
                    buyIndex: dealer['index'],
                    colorType: DEALER_INFO['colorType'][this.selectThumType],
                    useYn: this.equipDealer['buy_index'] == dealer['index'] && DEALER_INFO['colorType'][this.selectThumType] == dealer['type'] ? 'N' : 'Y'
                });
                await this.getMyDealerList();
                await this.getEquipDealer();
            },
            async getEquipDealer(){
                const res = await this.apis.getEquipDealer();
                if(res?.['decrypt_data']?.['mounting'].length > 0){
                    this.equipDealer = res?.['decrypt_data']?.['mounting'][0];
                } else {
                    this.equipDealer = {
                        buy_index: 1001,
					...this.dealers.find(shop => shop['comment'] === 'lisa')
                    };
                }
            },
            changeThum(thum){
                this.selectThumType = thum['type'];
                this.selectedDealer = this.dealers.find(dealer => dealer['comment'] == thum['group']);
                this.selectedDealer['img'] = DEALER_INFO['info'].find(info => info['group'] === thum['group'] && info['type'] === thum['type'])[isMobile() ? 'previewM' : 'previewW'];
                this.selectedDealer['type'] = DEALER_INFO['colorType'][this.selectThumType];
            },
            getDealerImage(dealer){
                if(dealer['comment'] == 'victoria'){
                    return;
                }
                return DEALER_INFO['info'].find(info => info['group'] === dealer['comment'] && info['type'] === 'a')[isMobile() ? 'mobile' : 'src'];
            },
            async showConfirmModal(dealer){
                if(this.checkDealer(dealer)['isBuy']){
                    await this.updateEquipStatus(dealer);
                    return;
                }
                this.hidePreviewModal();
                this.selectedDealer = dealer;
                this.$emit('showCloseBtn', false);
                this.isShowConfirm = true;
                this.selectedDealer['img'] = DEALER_INFO['info'].find(info => info['group'] === dealer['comment'] && info['type'] === 'a')[isMobile() ? 'mobile' : 'src'];
            },
            showPreviewModal(dealer){
                this.isShowPreview = true;
                this.$emit('showPreview', true);
                this.$emit('showCloseBtn', false);
                this.selectedDealer = dealer;
                this.selectedDealer['img'] = DEALER_INFO['info'].find(info => info['group'] === dealer['comment'] && info['type'] === 'a')[isMobile() ? 'previewM' : 'previewW'];
                this.selectedThums = DEALER_INFO['thumInfo'][dealer['comment']];
            },
            async buyDealer(){
                const res = await this.apis.buyDealer({
                    shop_index: this.selectedDealer['shopIndex']
                });
                await this.getMyDealerList();
                this.isShowConfirm = false;
                this.$emit('showCloseBtn', true);
            },
            buyCancel(){
                this.isShowConfirm = false;
                this.$emit('showCloseBtn', true);
            },
            async getMyDealerList() {
                const res = await this.apis.getMyDealerList();
                res['decrypt_data']['dealer'].forEach(dealer => {
                    this.myDealers[dealer['buy_index']] = {
                        'expiryTime': undefined
                    }
                    if(dealer['expiry_time']){
                        let expireTime = moment(dealer['expiry_time'], "YYYY-MM-DD HH:mm:ss");
                        let nowTime = moment();
                        this.myDealers[dealer['buy_index']]['expiryTime'] = Math.ceil(moment.duration(expireTime.diff(nowTime)).asDays()) || '30';
                    }
                });
            },
            // 구매한 딜러인지체크
            checkDealer(dealer, isPreview){
                let obj = {
                    'isBuy': false,
                    'period': undefined,
                    'key': 'buy'
                }
                if(this.myDealers?.[dealer['index']]){
                    obj['isBuy'] = true;
                    obj['period'] = this.myDealers[dealer['index']]['expiryTime'] ? `${this.$t('period')}: ${this.myDealers[dealer['index']]['expiryTime']} ${this.$t('days')}` : `${this.$t('period')}: 30 ${this.$t('days')}`;
                }

                if(dealer['comment'] == 'lisa'){
                    obj['isBuy'] = true;
                    obj['period'] = this.$t('unlimit');
                }
                if(obj['isBuy']) {
                    if (isPreview) {
                        obj['key'] = isPreview && this.equipDealer['color_type'] == dealer['type'] && this.equipDealer['buy_index'] == dealer['index'] ? 'unequip' : 'equip';
                    } else {
                        obj['key'] = this.equipDealer['buy_index'] == dealer['index'] ? 'unequip' : 'equip';
                    }
                }
                this.$emit('getEquipDealer');
                return obj;
            }
        }
    });
</script>
<style scoped>
.sideMenuBox{
	width: 100%;
	height: auto;
	padding-bottom: 30%;
	color: #fff;
	font-size: 14px;
	background-color: #2e3033;
	position: fixed;
	top: 0;
	z-index: 30;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 20px;
}
.sideMenuInner{
	padding: 0 3%;
	background-color: #2e3033;
	height: 100%;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 4%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/*내용*/
.dailyWrap{
	width: 100%;
	padding: 12px 4px;
	background-color: #1b1b1b;
	height: 750px;
	overflow-y: hidden;
}
.dailyWrap > img{
	width: 365px;
}

.shopWrap_M{
	width: 100%;
	height: auto;
	color: #fff;
	font-size: 14px;
	background-color: #2e3033;
	padding: 0 8px;
}
.shopTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.shopTopBox > h1 {
	font-size: 22px;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 4%;
}
.exitImg img{
	width: 28px;
	height: auto;
}
.shopWrap_M .currencyBox{
	width: 202px;
	height: 44px;
	background-color: #1b1b1b;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
	padding: 0 11px;
	color: #fff;
	font-size: 18px;
	float: right;
	margin: 5px 0 15px 0;
}
.shopWrap_M .shopTab{
	width: 100%;
}
.shopWrap_M .shopTab ul{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 9px;
	color: #ffffff;
	font-size: 14px;
}
.shopWrap_M .shopTab ul li{
	text-align: center;
	width: 111px;
	height: 40px;
	line-height: 40px;
	background-image: url("../../../assets/shop/btn-gray-2.png") !important;
	background-repeat: no-repeat;
	background-size: 100%;
}
.shopWrap_M .shopTab ul li.select{
	background-image: url("../../../assets/shop/btn-blue-2.png") !important;
	background-repeat: no-repeat;
	background-size: 100%;
}

.shopWrap_M .shopBody{
	width: 100%;
	height: 560px;
	overflow-y: auto;
	padding: 12px;
	background-color: #1b1b1b;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 11px 3%;
}
.shopWrap_M .shopBody .dealerWrap{
	width: 48.5%;
	height: 260px;
	border: 1px solid #484848;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8px 12px 11px;
	gap: 2%;
}
.shopWrap_M .shopBody .dealerWrap .dealerImg{
	position: relative;
	width: 148px;
	height: 204px;
	background-image: url("../../../assets/mobileBg/item-bg.png");
	background-repeat: no-repeat;
	background-size: 100%;
}
.shopWrap_M .shopBody .dealerWrap .dealerImg > img{
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 95%;
}
.shopWrap_M .shopBody .dealerWrap .dealerImg .info{
	position: absolute;
	top: 5px;
	right: 5px;
}
.shopWrap_M .shopBody .dealerWrap .dealerImg .use{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 23px;
	background-color: rgba(20, 30, 109, 0.9);
	text-align: center;
	line-height: 23px;
	color: #fff;
	font-size: 18px;
	font-weight: 300;
}

/* buy Btn*/
.shopWrap_M .shopBody .dealerWrap .buyBtn{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 135px;
	height: 35px;
	background-image: linear-gradient(to top, #2352af, #6696f6);
	border: 1px solid var(--modal-shop-buy-button-outline-color);
}

.shopWrap_M .shopBody .dealerWrap .buyBtn button{
	font-size: 17px;
	color: #fff;
}

.buyBtn button.equip {
	background-image: url('../../../assets/shop/btn-green.png');
	background-size: 100% 100%;
	width: 135px;
	height: 35px;
}
.buyBtn button.unequip {
	background-image: url('../../../assets/shop/btn-purple.png');
	background-size: 100% 100%;
	width: 135px;
	height: 35px;
}
.info > img{
	width: 20px;
}

.thums {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 5px;
	bottom: 0;
	right: 0;
	margin-right: 3%;
}
.thums .thum {
	display: flex;
	cursor: pointer;
}
.thum.selected {
	outline: 4px solid #5ccfff;
	border-radius: 10px;
}

</style>