<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>Bet Limitss</h1>
            <span class="exitImg">
                <img
                    src="../../../assets/mobile/btn-close.png"
                    alt="close"
                    v-on:click.stop="$emit('showModal', '')">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="betLimitWrap">
                <table
                    cellpadding="0"
                    cellspacing="0">
                    <thead>
                        <tr>
                            <td>{{ $t('bet') }}</td>
                            <td>{{ $t('min') }}</td>
                            <td>{{ $t('max') }}</td>
                            <td>{{ $t('payout') }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(limit, key) in limitKeys"
                            v-bind:key="key">
                            <td v-bind:class="limit">
                                {{ $t(limit) }}
                            </td>
                            <td v-moneySymbol="betMinMax[limit]['min']"></td>
                            <td v-moneySymbol="betMinMax[limit]['max']"></td>
                            <td>{{ BACCARAT_PAYOUT[limit]}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import {defineComponent} from "vue";
    import { BACCARAT_PAYOUT } from "@/js/const";
    export default defineComponent({
        props: ['betMinMax'],
        data(){
            return {
                limitKeys: [
                    'p', 'b', 'pp', 'bp', 'tie'
                ],
                BACCARAT_PAYOUT: BACCARAT_PAYOUT
            }
        }
    });

</script>
<style scoped>
.sideMenuBox{
	position: absolute;
	right: 0;
	width: 75vw;
	color: #fff;
	font-size: 14px;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 20px;
}
.sideMenuInner{
	padding: 1.5%;
	background-color: #2e3033;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 3%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/* 내용 */
.betLimitWrap{
	width: 100%;
	height: auto;
	background-color: #1b1b1b;
	padding-bottom: 20%;
}
table{
	width: 97%;
	text-align: center;
	padding: 0 1.5%;
	font-size: 14px;
}
thead td{
	color: #5ccfff;
}
thead tr{
	height: 46px;
	padding: 0 5px;
}
thead tr td{
	border-bottom: 1px solid #6c6c6c;
}
tbody tr{
	height: 36px;
}
.p, .pp{
	color: #3c86ff;
}
.tie{
	color: #26ff01;
}
.b, .bp{
	color: #ff1818;
}
</style>