/**
 * @name: enterUsers.js 현재 방에 접속 하고있는 유저정보들 가져오기
 */

import { topics } from "@/js/reactive";
import { getFlagImage, getGradeImage } from "@/js/common";
import { onMounted, reactive } from "vue";

export const enterRoomUsers = reactive({
    'list': []
});

export const setEnterRoomUsers = (roomNo) => {
    let topicKey = `${roomNo}/live_bacc/RoomUserList`;
    let info = topics[topicKey];
    enterRoomUsers['list'] = [];
    if(info){
        info['user_id'].forEach((user, idx) => {
            enterRoomUsers['list'].push({
                'country': getFlagImage(info['country'][idx]),
                'userId': info['user_id'][idx],
                'grade': getGradeImage(info['grade'][idx]),
                'level': info['player_level'][idx]
            });
        });
    }
}