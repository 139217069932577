<template>
    <div
        class="betBoard"
        v-bind:class="{ 'betDisabled': !isBetProgress }">
        <div class="top_Board">
            <button
                class="p_Board p_boxStyle"
                v-on:touchstart.stop="clickBetBoard(centerBoard[1])">
                <div class="card_Count p_Margin_Style p_boxStyle pCountBox">
                    {{ playerCardSum }}
                </div> <!--플레이어 카드 카운트-->
                <div class="board_Title">
                    <div class="card_Background p_card_Background_Direction">
                        <div
                            class="card"
                            v-bind:class="getCardResult(centerBoard[1], idx)['color']"
                            v-for="(card, idx) in centerBoard[1]['result']"
                            v-bind:key="idx">
                            <span class="cardShape">
                                <img v-bind:src="getCardImage(getCardResult(centerBoard[1], idx)['mark'])"/>
                            </span>
                            <span class="cardNum">
                                {{ getCardResult(centerBoard[1], idx)['tx'] }}
                            </span>
                        </div>
                    </div>
                    <div class="board_Back_Title p_board_Back_Style">
                        {{ $t(centerBoard[1]['key']) }}
                    </div>
                    <div class="board_Back_Title p_board_Back_Style">
                        x{{ centerBoard[1]['win'] }}
                    </div> <!--플레이어 배율-->
                </div>
                <div class="bet_Info_Board_Side">
                    <div class="bet_Count_Gauge p_boxStyle">
                        <div
                            class="p_gauge"
                            v-bind:style="{ 'height': `${getDistData('p')}` }"></div>
                        <div class="gauge_Count">
                            {{ getDistData('p') }}
                        </div>
                    </div>
                    <div
                        class="bet_Count p_boxStyle"
                        v-numberFormat="betInfo['betDetail'][centerBoard[1]['key']]['bet']"></div> <!--플레이어 배팅 카운트-->
                </div>
            </button>
            <button
                class="t_Board t_boxStyle"
                v-on:touchstart.stop="clickBetBoard(centerBoard[2])">
                <div class="board_Title">
                    <div class="board_Back_Title t_board_Back_Style">
                        {{ $t(centerBoard[2]['key']) }} x{{ centerBoard[2]['win'] }}
                    </div>
                </div>
                <div class="bet_Info_Board">
                    <div class="bet_Count_Gauge t_boxStyle">
                        <div
                            class="t_gauge"
                            v-bind:style="{ 'height': `${getDistData('tie')}` }"></div>
                        <div class="gauge_Count">
                            {{ getDistData('tie') }}
                        </div>
                    </div>
                    <div
                        class="bet_Count t_boxStyle"
                        v-numberFormat="betInfo['betDetail'][centerBoard[2]['key']]['bet']"></div> <!--티어 배팅 카운트-->
                </div>
            </button>
            <button
                class="b_Board b_boxStyle"
                v-on:touchstart.stop="clickBetBoard(centerBoard[3])">
                <div class="card_Count b_Margin_Style b_boxStyle bCountBox">
                    {{ bankerCardSum }}
                </div> <!--뱅커 카드 카운트-->
                <div class="board_Title">
                    <div class="card_Background b_card_Background_Direction">
                        <div
                            v-if="centerBoard[3]['result'].length > 0"
                            v-bind:class="getCardResult(centerBoard[3], 0)['color']"
                            class="card">
                            <span class="cardShape">
                                <img v-bind:src="getCardImage(getCardResult(centerBoard[3], 0)['mark'])"/>
                            </span>
                            <span class="cardNum">
                                {{ getCardResult(centerBoard[3], 0)['tx'] }}
                            </span>
                        </div>
                        <div
                            class="card"
                            v-if="centerBoard[3]['result'].length > 1"
                            v-bind:class="getCardResult(centerBoard[3], 1)['color']">
                            <span class="cardShape">
                                <img v-bind:src="getCardImage(getCardResult(centerBoard[3], 1)['mark'])"/>
                            </span>
                            <span class="cardNum">
                                {{ getCardResult(centerBoard[3], 1)['tx'] }}
                            </span>
                        </div>
                        <div
                            class="card"
                            v-if="centerBoard[3]['result'].length > 2"
                            v-bind:class="getCardResult(centerBoard[3], 2)['color']">
                            <span class="cardShape">
                                <img v-bind:src="getCardImage(getCardResult(centerBoard[3], 2)['mark'])"/>
                            </span>
                            <span class="cardNum">
                                {{ getCardResult(centerBoard[3], 2)['tx'] }}
                            </span>
                        </div>
                    </div>
                    <div class="board_Back_Title b_board_Back_Style">
                        {{ $t(centerBoard[3]['key']) }}
                    </div>
                    <div class="board_Back_Title b_board_Back_Style">
                        x{{ centerBoard[3]['win'] }}
                    </div> <!--뱅커 배율-->
                </div>
                <div class="bet_Info_Board_Side">
                    <div
                        class="bet_Count b_boxStyle"
                        v-numberFormat="betInfo['betDetail'][centerBoard[3]['key']]['bet']"></div> <!--뱅커 배팅 카운트-->
                    <div class="bet_Count_Gauge b_boxStyle">
                        <div
                            class="b_gauge"
                            v-bind:style="{ 'height': `${getDistData('b')}` }"></div>
                        <div class="gauge_Count">
                            {{ getDistData('b') }}
                        </div>
                    </div>
                </div>
            </button>
        </div>
        <div class="bot_Board">
            <button
                class="pp_Board p_boxStyle boxs"
                v-on:touchstart.stop="clickBetBoard(centerBoard[0])">
                <div class="board_Title">
                    <div class="board_Back_Title p_board_Back_Style">
                        {{ $t(centerBoard[0]['key']) }} x{{ centerBoard[0]['win'] }}
                    </div>
                </div>
                <div class="bet_Info_Board_Bottom">
                    <div
                        class="bet_Count p_boxStyle"
                        v-numberFormat="betInfo['betDetail'][centerBoard[0]['key']]['bet']"></div> <!--플레이어 페어 배팅 카운트-->
                </div>
            </button>
            <button
                class="bp_Board b_boxStyle boxs"
                v-on:touchstart.stop="clickBetBoard(centerBoard[4])">
                <div class="board_Title">
                    <div class="board_Back_Title b_board_Back_Style">
                        {{ $t(centerBoard[4]['key']) }} x{{ centerBoard[4]['win'] }}
                    </div>
                </div>
                <div class="bet_Info_Board_Bottom">
                    <div
                        class="bet_Count b_boxStyle"
                        v-numberFormat="betInfo['betDetail'][centerBoard[4]['key']]['bet']"></div> <!--플레이어 페어 배팅 카운트-->
                </div>
            </button>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import InGame from "@/components/InGame";
    import { mapGetters } from "vuex"

    export default defineComponent({
        mixins: [InGame],
        data() {
            return {
                betDetail: {}
            }
        },
        computed: {
			...mapGetters(['getBetDetail', 'getCurSelectBoard'])
        },
        watch: {
            getBetDetail: {
                deep: true,
                handler(val){
                    this.betInfo['betDetail'] = val;
                }
            },
            getCurSelectBoard: {
                deep: true,
                handler(val){
                    this.curSelectBoard = val;
                }
            },
        },
    });
</script>
<style scoped>
	.black {
		color: black;
	}

	.red {
		color: #dd3d3d;
	}
    .betBoard {
		font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
		position: relative;
		z-index:4;
        height: 153px;
		bottom: -35px;
        margin-top: -70px;
    }
    .betBoard .top_Board {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        height: 102px;
    }
    .betBoard .top_Board .t_Board {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 32%;
        height: 100%;
		gap: 8px;
        background-image: linear-gradient(to top, #42923e, #184a15);
    }
    .betBoard .top_Board .p_Board {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 33.2%;
        height: 100%;
		position: relative;
        background-image: linear-gradient(to top, #3f64a0, #162346);
    }
    .betBoard .top_Board .b_Board {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 33.2%;
        height: 100%;
        background-image: linear-gradient(to top, #923e4a, #4d1413);
		position: relative;
    }
    .betBoard .bot_Board {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        height: 48px;
    }
    .betBoard .bot_Board .pp_Board {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 49%;
        height: 100%;
        background-image: linear-gradient(to top, #3f64a0, #162346);
    }
    .betBoard .bot_Board .bp_Board {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 49.5%;
        height: 100%;
        background-image: linear-gradient(to top, #923e4a, #4d1413);
    }
    .bet_Info_Board {
        display: flex;
        width: 96%;
        gap: 2%;
        margin-bottom: 2%;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        color: #ffe431;
    }
    .bet_Info_Board_Side {
        display: flex;
        width: 96%;
        margin-bottom: 2%;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        font-size: 15px;
        color: #ffe431;
        gap: 2px;
    }
    .bet_Info_Board_Bottom {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        color: #ffe431;
    }
    .bet_Count_Gauge {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 43px;
        height: 43px;
        background-color: #222222;
        overflow: hidden;
    }
	.gauge_Count {
		font-size: 10px;
	}
    .bet_Count_Gauge .gauge_Count {
        position: absolute;
    }
    .bet_Count {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 77px;
        height: 21px;
        background-color: #222222;
    }
    .card_Count {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: #222222;
        font-size: 15px;
        color: white;
        
    }
    .p_Margin_Style {
        margin: 2% auto auto 2%;
    }
    .b_Margin_Style {
        margin: 2% 2% auto auto;
    }
    .board_Back_Title {
        font-size: 18px;
        font-weight: 500;
    }
    .board_Title {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
    }
    .card_Background {
        position: absolute;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        width: 100%;
        top: -8px;
    }
    .p_card_Background_Direction {
        flex-direction: row-reverse;
    }
    .b_card_Background_Direction {
        flex-direction: row;
    }
    .card_Background .card {
        display: flex;
        position: relative;
		bottom: 2px;
        align-items: flex-end;
        white-space: pre-line;
        justify-content: space-around;
        width: 36px;
        height: 45px;
        font-size: 31px;
        font-weight: bold;
        border-radius: 4px;
        background-color: white;
    }
    .card .cardShape{
        position: absolute;
        top: -1px;
        left: 1px;
        font-size: 15px;
    }
    .cardRed {
        color:#c90000;
    }
    .cardBlack{
        color: #000;
    }
	.card.cardRotate {
        display: flex;
		white-space: nowrap !important;
		font-size: 20px;
		align-items: center;
		justify-content: center;
		font-weight: bold;
        width: 46px;
        height: 35px;
        border-radius: 4px;
        background-color: white;
    }
    .p_gauge {
        width: 100%;
        margin-top: auto;
        background-color: #4486d7;
    }
    .t_gauge {
        width: 100%;
        height: 44%;
        margin-top: auto;
        background-color: #4bd12b;
    }
    .b_gauge {
        width: 100%;
        height: 44%;
        margin-top: auto;
        background-color: #dd3d3d;
    }
    .p_board_Back_Style {
        color: #6aaeda;
    }
    .t_board_Back_Style {
        color: #9dff86;
    }
    .b_board_Back_Style {
        color: #ff7575;
    }
    .p_boxStyle {
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #4486d7;
    }
    .t_boxStyle {
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #4bd12b;
    }
    .b_boxStyle {
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #dd3d3d;
    }
    .boxs{
        margin-top: 2px;
    }
    .pCountBox{
        position: absolute!important;
        bottom: 25px !important;
        margin-left: 3px!important;
        margin-top: 3px!important;
        right: 3px !important;
        height: 22px!important;
        width: 22px!important;
        line-height: 22px!important;
    }
    .bCountBox{
        position: absolute!important;
        bottom: 25px !important;
        margin-right: 3px!important;
        margin-top: 3px!important;
        left: 3px !important;
        height: 22px!important;
        width: 22px!important;
        line-height: 22px!important;
    }

    /*211029 한유정 추가*/
    .cardShape {
        position: absolute;
        font-size: 16px;
        top: 0;
        left: 0;
        margin-left: 1px;
        margin-top: 1px;
    }
    .cardShape > img{
        width: 16px;
    }

    /*
    @media screen and (max-width: 1124px), (max-height: 1898px) {
        .betBoard {
            height: 328px;
        }
        .bet_Info_Board {
            font-size: 30px;
        }
        .bet_Info_Board_Side {
            font-size: 30px;
        }
        .bet_Info_Board_Bottom {
            font-size: 30px;
        }
        .bet_Count_Gauge {
            width: 82px;
            height: 82px;
        }
        .bet_Count {
            width: 165px;
            height: 43px;
        }
        .card_Count {
            width: 45px;
            height: 45px;
            font-size: 30px;
        }
        .board_Back_Title {
            font-size: 33px;
        }
		.card {
			white-space: pre-line;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 50px;
		}
        .card_Background .card {
            width: 75px;
            height: 99px;
        }
        .card_Background .cardRotate {
            width: 99px;
            height: 75px;
        }
    }
    @media screen and (max-width: 843px), (max-height: 1424px) {
        .betBoard {
            height: 219px;
        }
        .bet_Info_Board {
            font-size: 20px;
        }
        .bet_Info_Board_Side {
            font-size: 20px;
        }
        .bet_Info_Board_Bottom {
            font-size: 20px;
        }
        .bet_Count_Gauge {
            width: 55px;
            height: 55px;
        }
        .bet_Count {
            width: 110px;
            height: 29px;
        }
        .card_Count {
            width: 30px;
            height: 30px;
            font-size: 20px;
        }
        .board_Back_Title {
            font-size: 22px;
        }
        .card_Background .card {
            width: 50px;
            height: 66px;
        }
        .card_Background .cardRotate {
            width: 66px;
            height: 50px;
        }
    }
    @media screen and (max-width: 562px), (max-height: 949px) {
        .betBoard {
            height: 164px;
        }
        .bet_Info_Board {
            font-size: 15px;
        }
        .bet_Info_Board_Side {
            font-size: 15px;
        }
        .bet_Info_Board_Bottom {
            font-size: 15px;
        }
        .bet_Count_Gauge {
            width: 41px;
            height: 41px;
        }
        .bet_Count {
            width: 82px;
            height: 21px;
        }
        .card_Count {
            width: 22px;
            height: 22px;
            font-size: 15px;
        }
        .board_Back_Title {
            font-size: 16px;
        }
        .card_Background .card {
            width: 37px;
            height: 49px;
        }
        .card_Background .cardRotate {
            width: 49px;
            height: 37px;
        }
    }
    @media screen and (max-width: 421px), (max-height: 712px) {
        .betBoard {
            height: 109px;
        }
        .bet_Info_Board {
            font-size: 10px;
        }
        .bet_Info_Board_Side {
            font-size: 10px;
        }
        .bet_Info_Board_Bottom {
            font-size: 10px;
        }
        .bet_Count_Gauge {
            width: 27px;
            height: 27px;
        }
        .bet_Count {
            width: 55px;
            height: 14px;
        }
        .card_Count {
            width: 15px;
            height: 15px;
            font-size: 10px;
        }
        .board_Back_Title {
            font-size: 11px;
        }
        .card_Background .card {
            width: 25px;
            height: 33px;
        }
        .card_Background .cardRotate {
            width: 33px;
            height: 25px;
        }
    }
     */
</style>