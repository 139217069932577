<template>
    <div
        class="modalInner"
        v-bind:class="{ 'mobile': isMobile() }">
        <div class="symbolWrap">
            <h3>{{ $t('symbol') }}</h3>
            <div class="ethListBox">
                <div>
                    <div>
                        <div class="symbolVal">
                            <img
                                src="../../../assets/icons/vgt.png"
                                alt="vgtIcon">
                            <span>USD</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="convertInputWrap">
            <h3>{{ $t('value') }}</h3>
            <div class="inputWrap">
                <div class="inputBox">
                    <input
                        v-on:keyup="setTotalPrice"
                        v-model="InputVal"
                        class="convertInput"
                        type="text"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    />
                    <p>USD</p>
                </div>
                <div class="maxbtn">
                    <button
                        v-on:click="setAmonutValue"
                        value="25">
                        25%
                    </button>
                    <button
                        v-on:click="setAmonutValue"
                        value="50">
                        50%
                    </button>
                    <button
                        v-on:click="setAmonutValue"
                        value="100">
                        100%
                    </button>
                </div>
            </div>
            <h3 class="wide">
                {{ $t('Transfer Symbol') }}
            </h3>
            <div class="resultWrap">
                <div
                    class="vgtResult"
                    v-on:click.stop="showDropDown = !showDropDown">
                    <span><img
                        v-bind:src="selectedSymbol['icon']"
                        alt="vgtIcon">{{ selectedSymbol['symbol'] }}<img
                            class="arrow"
                            src="@/assets/sabong/icon/icon_arrow.png"></span>
                    <p>{{ totalPrice }}</p>
                </div>
                <ul
                    class="dropdown"
                    v-if="showDropDown">
                    <li
                        v-for="(sy, idx) in symbols"
                        v-bind:key="idx"
                        v-on:click.stop="clickSymbol">
                        <span><img
                            v-bind:src="sy['icon']"
                            alt="vgtIcon">{{ sy['symbol']}}</span>
                    </li>
                </ul>
                <div class="gasResult">
                    <p>{{ $t('gas') }}</p>
                    <p class="gasPrice">
                        <span>{{ ethGas }}</span>
                        <span>ETH</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="infoWrap">
            <p>
                {{ $t('depositInfoTxt1') }}
                {{ $t('depositInfoTxt2') }}
            </p>
        </div>
        <div class="warningWrap">
            <p>
                {{ $t('depositWarningTxt') }}
            </p>
        </div>
        <div class="btnWrap">
            <button
                v-bind:disabled="ethApis.isApiCalling.value"
                v-bind:class="{ 'disabled': ethApis.isApiCalling.value}"
                class="transferBtn"
                v-on:click="convertEth();">
                {{ $t('withdrawal') }}<span v-if="ethApis.isApiCalling.value">
                    ...
                </span>
            </button>
        </div>
    </div>
</template>
<script setup>
    import { isMobile, encryptDataV2 } from "@/js/utils";
    import { loginUser } from "@/js/reactive";
    import { walletEthGas , walletUsdPrice, walletConvertSend, sabongWalletInfo} from "@/js/inGame/sabongWallet.js"
    import {computed, onMounted, ref, defineEmits, reactive} from "vue";
    import { err } from "@/js/reactive";
    import { ethApis } from '@/repository/repository';

    let ethGas = computed(() => {
        return sabongWalletInfo['walletEthGas'];
    });
    let userEthAmonut = computed(() => {
        return sabongWalletInfo['ethAmount'];
    });
    let InputVal = ref(0);
    let price1 = 0;
    let totalPrice = ref(0);

    const showDropDown = ref(false);
    const symbols = reactive({
        ETH: {
            icon: require('@/assets/icons/eth.png'),
            symbol: 'ETH',
            key: 'eth'
        },
        BNB: {
            icon: require('@/assets/icons/bnb.png'),
            symbol: 'BNB',
            key: 'bsc'
        }
    });
    const selectedSymbol = ref({
        icon: require('@/assets/icons/eth.png'),
        symbol: 'ETH',
        key: 'eth'
    });
    const clickSymbol = async (e) => {
        showDropDown.value = false;
        InputVal.value = 0;
        selectedSymbol.value = symbols[e.target.innerText.replaceAll(' ', '')];
        await walletEthGas(selectedSymbol.value['key']);
        await walletUsdPrice(selectedSymbol.value['key']);
    }

    onMounted(async () => {
        await walletEthGas('eth');
        await walletUsdPrice('eth');
    })

    const setAmonutValue = (e) => {
        const targetPercent = Number(e.target.value) / 100;
        if(loginUser['balance'] > 0) {
            InputVal.value = loginUser['balance'] * targetPercent;
        } else {
            err['msg'] = 'The amount of USD you have not enough.';
        }
        setTotalPrice();
    }

    const setTotalPrice = () => {
        price1 = (InputVal.value) * 0.996;
        totalPrice.value = Number((price1 / sabongWalletInfo['walletUsdPrice']).toFixed(6));
    }

    const convertEth = async () => {
        if(InputVal.value == 0){
            err['msg'] = 'Please input USD amount.'
            return;
        }
        const res = await ethApis.$fetch('withdrawal', {
            address: encryptDataV2(loginUser['id']),
            value: InputVal.value,
            symbol: selectedSymbol.value['key']
        });
        err['msg'] = 'Withdrawal Complete.';
        InputVal.value = 0;
    }
</script>
<style scoped>
	.arrow {
		width: 10px;
		height: 10px;
	}
	.disabled {
		cursor: not-allowed !important;
		opacity: 0.7;
	}
    *{
        font-family: 'Folks', sans-serif;
        letter-spacing: .3px;
    }
    .modalInner{
        padding: 5px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
    .modalInner h3{
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 22px;
    }
    .modalInner h3.wide{
        padding-top: 22px;
        font-size: 20px;
        padding-bottom: 8px;
    }
    .modalInner img{
        width: 32px;
        margin-right: 10px;
    }
    .symbolWrap{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .symbolWrap .ethWrap *{
        line-height: 25px;
    }
    .symbolWrap>div{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0px 12px;
        height: 44px;
        position: relative;
        background:#1b1b1b;
        border-radius: 6px;
    }
    .ethListBox>div{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .ethListBox>div>div{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .ethListBox .symbolVal {
        display: flex;
    }
    .ethListBox .symbolVal span{
        display: flex;
        align-items: center;
        width: 26px;
    }
    .ethListBox .symbolVal img{
        width: 32px;
        height: 32px;
    }
    .ethListBox .symbolChange {
        display: flex;
        align-items: center;
    }
    .ethListBox .symbolChange img{
        width: 32px;
        height: 32px;
        margin: 0;
        cursor: pointer;
    }
    .symbolWrap .depth_2{
        display:flex ;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        height: auto;
        min-height: 100px;
        max-height: 350px;
        overflow-y: auto;
        border: 1px solid #bbbbbb;
        padding: 5px;
        position: absolute;
        top: 43px;
        left: 0;
        z-index:10;
        background: #1b1b1b;
        visibility: hidden;
    }
    .symbolWrap .depth_2 li{
        display: flex;
        line-height: 39px;
        text-align: left;
        border: 2px solid #484848;
    }
    .symbolWrap .depth_2 li img{
        margin-left: 5px;
    }
    .symbolWrap>img{
        width: 18px;
        margin: 11px 0;
    }
    .convertInputWrap{
        display: flex;
        flex-direction: column;
        padding-bottom: 22px;
        width: 100%;
    }
    .convertInputWrap > div{
        background: #1b1b1b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
    }
    .convertInputWrap .inputBox{
        display: flex;
        gap: 14px;
        width: 100%;
        padding: 10px 5px;
    }
    .convertInputWrap .maxbtn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding-bottom: 10px;
        padding-right: 12px;
    }
    .convertInputWrap .maxbtn > button {
        width: 80px;
        height: 34px;
        line-height: 34px;
        font-size: 16px;
        border-radius: 5px;
        background-image: linear-gradient(73deg, #244dbc 27%, #3ca7e5 83%);
    }
    .convertInputWrap>div label{
        display: flex;
        align-items: center;
    }
    .inputWrap input{
        width: 100%;
        height:25px;
        background: none;
        border-bottom: 2px solid #484848;
        text-align: right;
        font-size: 20px;
    }
    .inputWrap p{
        margin-right: 10px;
    }
    .inputWrap input::placeholder{
        color: #fff;
    }
    .resultWrap{
        display: flex;
        flex-direction: column;
        height:88px;
        padding: 0 14px;
		position: relative;
    }
	.resultWrap .dropdown {
		display: flex;
		flex-direction: column;
		padding: 10px;
		align-items: center;
		justify-content: center;
		border: 1px solid white;
		position: absolute;
		width: 110px;
		top: 45px;
		left: 10px;
		gap: 10px;
		background-color: #1b1b1b;
	}
	.resultWrap .dropdown li span {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
    .resultWrap>div{
        width: 100%;
        height:44px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .resultWrap>div span{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .gasResult span{
        gap: 14px;
    }
    .infoWrap,.warningWrap{
        width: 80%;
        font-size: 16px;
    }

    .infoWrap{
        padding-bottom: 16px;
    }
    .warningWrap p{
        color: #ff2c2c;
    }
    .btnWrap{
        margin: 36px 0;
    }
    .alertText {
        text-align: center;
        width: 70%;
        color: #ff2c2c;
    }

    .modalInner button{
        width: 294px;
        height:50px;
        line-height: 50px;
        border-radius: 10px;
        background-image: linear-gradient(73deg, #d46c29 27%, #edbe69 83%);
        font-size: 20px;
    }
    *{
        color:#fff;
    }

    /* mobile */
    .modalInner.mobile{
        padding: 0 10px;
        font-size: 18px;
    }
    .modalInner.mobile .symbolWrap>div{
        height: 40px;
        padding: 0 10px;
    }
    .modalInner.mobile img{
        width: 28px;
    }
    .modalInner.mobile h3{
        font-size: 20px;
    }
    .modalInner.mobile .symbolWrap h3{
        font-size: 18px;
        padding-top: 16px;
        padding-bottom: 8px;
    }
    .modalInner.mobile .convertWrap > div{
        height: 42px;
    }
    .modalInner.mobile .convertWrap > div .rArrow img{
        width: 22px;
    }
    .modalInner.mobile .convertWrap > img{
        margin: 9px 0;
    }
    .modalInner.mobile .modalInner h3{
        padding: 11px 0 8px;
        font-size: 16px;
    }
    .modalInner.mobile .inputWrap{
        padding: 0;
        padding-left: 5px;
    }
    .modalInner.mobile .inputWrap input{
        font-size: 16px;
        width: 100%;
        border-bottom: 1px solid #484848;
    }
    .modalInner.mobile .convertInputWrap{
        padding-bottom: 16px;
    }
    .modalInner.mobile .resultWrap{
        height: 80px;
    }
    .modalInner.mobile .resultWrap p{
        font-size: 18px;
    }
    .modalInner.mobile .btnWrap{
        margin: 39px 0;
        display: flex;
        justify-content: center;
    }
    .modalInner.mobile .infoWrap, .modalInner.mobile .warningWrap{
        width: 100%;
        padding: 0 38px;
    }
    .modalInner.mobile .infoWrap{
        padding-bottom: 10px;
    }
    .modalInner.mobile .infoWrap p, .modalInner.mobile .warningWrap p{
        font-size: 18px;
        line-height: 1.3;
    }
    .gasPrice{
        display: flex;
        column-gap: 10px;
    }
    .modalInner.mobile .transferBtn{
        width: 266px;
        height: 45px;
        line-height: 45px;
        font-size: 20px;
        display: block;
    }
</style>