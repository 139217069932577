<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('history') }}</h1>
            <span
                class="exitImg"
                v-on:click.stop="showModal('sabongHistoryDetl', false)">
                <img
                    alt="close"
                    src="@/assets/mobile/btn-close.png">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="historyWrap">
                <div class="historyList">
                    <ul class="historyListTopBox">
                        <li v-on:touchstart.stop="historyCmd.fn.goPage('prev')">
                            <img
                                alt="prev"
                                src="@/assets/icons/icon_history_arrow_L.png">
                        </li>
                        <li class="historyListTopTxt">
                            <span class="accentColor">
                                #{{ historyData.selectedHistory.event_id }}
                            </span>
                            <hr class="hr">
                            <span>{{ $t('round')}} {{ historyData.selectedHistory.fight_no }}</span>
                            <span>{{ historyData.curNavIdx + 1}} of {{ historyData.curPage }} pages</span>
                        </li>
                        <li v-on:touchstart.stop="historyCmd.fn.goPage('next')">
                            <img
                                alt="next"
                                src="@/assets/icons/icon_history_arrow_R.png">
                        </li>
                    </ul>
                    <table
                        cellpadding="0"
                        cellspacing="0">
                        <tr>
                            <td class="accentColor">
                                {{ $t('date') }}
                            </td>
                            <td class="accentColor">
                                {{ $t('bet') }}
                            </td>
                            <td>
                                <span class="winColor">
                                    {{ $t('win2') }}
                                </span>
                                <span class="accentColor">
                                    &#47;
                                </span>
                                <span class="loseColor">
                                    {{ $t('lose') }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ historyData.selectedHistory['reg_dt'] }}</td>
                            <td v-number-format="historyData.selectedHistory['bet_amount']"></td>
                            <td
                                v-number-format="historyData.selectedHistory['winnings']"
                                v-bind:class="{
                                    'profit': historyData.selectedHistory['winnings'] > 0,
                                    'loss': historyData.selectedHistory['winnings'] < 0
                                }"></td>
                        </tr>
                    </table>
                </div>
                <div class="historyDetlMidBox">
                    <ul>
                        <li
                            v-for="(data, key) in boxUi['winColors']"
                            v-bind:class="[data, {'highLite': getWinColor(historyData.selectedHistory) == data }]"
                            v-bind:key="key">
                            {{ $t(setBetLocale.text[data]) }}
                        </li>
                    </ul>
                </div>
                <table>
                    <tr
                        v-for="(data, key) in boxUi['betRows']"
                        v-bind:key="key">
                        <td v-bind:class="data">
                            {{ $t(setBetLocale.text[data]) }}
                        </td>
                        <td class="accentColor">
                            {{ $t('bet') }}
                        </td>
                        <td v-number-format="historyData.selectedHistory[`bet_${data}`]"></td>
                        <td class="accentColor">
                            {{ $t('result') }}
                        </td>
                        <td v-number-format="historyData.selectedHistory[`win_${data}`]"></td>
                    </tr>
                </table>
            </div>
            <div class="historyFooter">
                <ul>
                    <li v-on:click.stop="showModal('sabongHistoryDetl', false)">
                        {{ $t('toList') }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { showModal, modals } from '@/js/modal/modals';
    import { historyData, getHistoryCmd, getWinColor } from "@/js/history/history";
    import { onBeforeMount, onUnmounted, ref} from "vue";
    import { LOGO } from '@/js/const';

    const setBetLocale = LOGO[location.hostname].betLocale;
    const boxUi = {
        winColors: ['meron', 'wala', 'draw', 'cancel'],
        betRows: ['meron', 'wala', 'draw']
    }
    let historyCmd = ref(undefined);
    onBeforeMount(async () => {
        historyCmd.value = getHistoryCmd('sabong');
    });
    onUnmounted(() => {
        modals['sabongHistoryDetl'] = false;
    });
</script>
<style scoped>
.sideMenuBox{
	width: 100%;
	height: auto;
	padding-bottom: 75%;
	color: #fff;
	font-size: 14px;
	background-color: #2e3033;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 18px;
}
.sideMenuInner{
	padding: 0 3%;
	background-color: #2e3033;
	height: 100%;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 4%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/* 내용 */
.historyWrap{
	width: 100%;
	height: auto;
	padding: 2px 4px;
	background-color: #1b1b1b;
}
.historyList{
	width: 100%;
}
.historyListTopBox{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 13px;
	height: 35px;
}
.historyListTopBox li img{
	max-width: unset;
	height: 22px;
	vertical-align: middle;
}
.historyListTopTxt{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	column-gap: 10px;
}
.historyListTopBox:lang(en){
	font-size: 12px !important;
}
.historyList > ul.historyListTopBox:lang(en){
	gap: 7px !important;
}
.hr{
	width: 0;
	height: 16px;
	border-right: 1px solid #6c6c6c;
}
.historyList > table{
    width: 100%;
	text-align: center;
	font-size: 13px;
	border-top: 1px solid #6c6c6c;
    display: grid;
    grid-template-rows: 1fr 1fr;
    place-items: center;
}
.historyList > table > tr{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
}

.winColor{
	color: #2be000;
}
.accentColor{
	color: #5ccfff;
}
.accentColor:lang(en){
	white-space: nowrap;
	font-size: 12px;
}
li.accentColor:lang(en){
	white-space: nowrap;
	font-size: 12px;
	min-width: 55px;
}
.loseColor{
	color: #ff121a;
}

.historyWrap > table{
	width: 100%;
	text-align: center;
	font-size: 13px;
	border-spacing: 0 22px;
}
.historyWrap > table > tr{
	height: 20px;
}
.historyWrap > table > tr > td:nth-child(1){
	width: 82px;
	border-right: 1px solid #6c6c6c;
}
.historyFooter ul{
	width: 100%;
	height: 35px;
	margin: 5px 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 15px;
}
.historyDetlMidBox{
	width: 100%;
	height: 158px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.historyDetlMidBox > ul{
	width: 92%;
	height: auto;
	row-gap: 12px;
	column-gap: 12px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.historyDetlMidBox > ul > li{
	width: 48%;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	opacity: .5;
	color: white;
}
.highLite {
	opacity: 1 !important;
}

.historyDetlMidBox ::v-deep(.wala) {
	background-color: #3c86ff;
}

.historyDetlMidBox ::v-deep(.meron) {
	background-color: #ff1818;
}

.historyDetlMidBox ::v-deep(.draw) {
	background-color: #26ff01;
}

.historyDetlMidBox ::v-deep(.cancel) {
	background-color: #5a5a5c;
}

.wala {
	color: #3c86ff;
}
.draw {
	color: #26ff01;
}
.meron {
	color: #ff1818;
}
.cancel {
	color: #5a5a5c;
}
</style>