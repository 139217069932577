<template>
    <TabMenu
        v-on:symbol="symbol"
        v-on:changeTab="changeTab"
        v-bind:selectedTab="selectedTab"/>
    <div class="modalInner">
        <ul class="topBox">
            <li>{{ $t('index') }}</li>
            <li>{{ $t('userId') }}</li>
            <li>{{ $t('toTransferCurrency') }}</li>
            <li>{{ $t('transferAmount') }}</li>
            <li>{{ $t('fromTransferCurrency') }}</li>
            <li>{{ $t('resultAmount') }}</li>
            <li>{{ $t('transactionId') }}</li>
            <li>{{ $t('requestStatus') }}</li>
            <li>{{ $t('registerDate') }}(GMT+9)</li>
        </ul>
        <div class="listWrap">
            <p v-if="history?.length == 0">
                There is no information to receive
            </p>
            <ul
                class="list"
                v-for="(data, idx) in history"
                v-bind:key="idx">
                <li>{{ idx + 1 }}</li>
                <li><a 
                    v-bind:class="selectedSymbol"
                    target="_blank"
                    v-bind:href="blankHiperLink(selectedSymbol,'address',loginUser['id'])"
                >
                    {{ setCollapsed(loginUser['id']) }}
                </a></li>
                <li>{{ data['toCurrency'] }}</li>
                <li v-numberFormat="selectedTab == 'deposit' ? data['value'] / 10 ** 18 : data['ethusd']">
                </li>
                <li>{{ data['fromCurrency'] }} </li>
                <li v-numberFormat="data['afterAmount']">
                </li>
                <li><a
                    v-bind:class="selectedSymbol"
                    target="_blank"
                    v-bind:href="blankHiperLink(selectedSymbol,'tx',data['hash'])"
                >
                    {{ setCollapsed(data['hash'],6,-6) }}
                </a></li>
                <li v-bind:class="data['change']">
                    <span>{{ data['change'] }}</span>
                </li>
                <li>{{ moment(data['timeStamp'] * 1000).format('YYYY-MM-DD HH:mm:ss') }}</li>
            </ul>
        </div>
    </div>
</template>
<script setup>
    import {computed, defineComponent, onMounted, ref} from "vue";
    import { isMobile, setCollapsed } from "@/js/utils";
    import { sabongWalletInfo, walletTransHistory } from "@/js/inGame/sabongWallet"
    import TabMenu from "@/components/layout/sabongTab";
    import moment from "moment";
    import { useStore } from "vuex";
    const store = useStore();

    const selectedTab = ref('deposit');
    const selectedSymbol = ref('ETH');

    const loginUser = computed(() => {
        return store.getters['login/getLoginUser'];
    });
    
    const symbol = (value) => {
        selectedSymbol.value = value ? 'ETH' : 'BNB';
    }
    const blankHiperLink = (symbol, search ,hash) => {
        const _symbol = symbol === 'BNB' ? 'https://www.bscscan.com/' : 'https://etherscan.io/';
        const _search = search;
        const _hash = hash; 
        return `${_symbol}${_search}/${_hash}`;
    }
    const changeTab = async (selected) => {
        selectedTab.value = selected.tabKey;
        await walletTransHistory(selectedTab.value, selected.symbol['key']);
    }
    let history = computed(() => {
        return sabongWalletInfo['transHistory'];
    });
    onMounted(async () => {
        await walletTransHistory(selectedTab.value, 'eth');
    });
</script>
<style scoped>
*{
    font-family: 'Folks', sans-serif;
    letter-spacing: .3px;
    box-sizing: border-box !important;
    font-size: 20px;
}

.modalInner{
    width: 100%;
    padding: 0 6px;
    background-color: #1b1b1b;
    text-align: center;
    font-size: 0.75vw;
}
ul.topBox{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
ul.topBox li{
    color: #5ccfff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
    font-size: 16px;
	flex: 1;
}

.listWrap{
    width: 100%;
    height: 55vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 22px 0;
    border-top: 2px solid #484848;
    color: #fff;
}
.listWrap ul{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: auto;
    color: #ffffff;
}

.listWrap ul li{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: inherit;
    text-align: center;
	flex: 1;
	white-space: nowrap;
}
.modalInner ul{
    gap: 10px;
}
.modalInner ul li:nth-child(1){
    width: 5%;
}
.modalInner ul li:nth-child(2){
    width: 7%;
}
.modalInner ul li:nth-child(3){
    width: 7%;
}
.modalInner ul li:nth-child(4){
    width: 10%;
}
.modalInner ul li:nth-child(5){
    width: 10%;
}
.modalInner ul li:nth-child(6){
    width: 15%;
}
.modalInner ul li:nth-child(7){
    width: 23%;
    word-break: break-all;
    padding: 7px;
}
.modalInner ul li:nth-child(8){
    width: 8%;
}
.modalInner ul li:nth-child(9){
    width: 17%;
}

.modalInner ul li:nth-child(8) span{
    width: 90%;
    height: 46px;
    line-height: 46px;
    border-radius: 10px;
}

.modalInner ul li:nth-child(8).success span{
    border: 2px solid #2056af;
    background-color: #3f82e9;
}
.modalInner ul li:nth-child(8).ready span{
    border: 2px solid #1d7713;
    background-color: #22ac30;
}
.modalInner ul li:nth-child(8).failed span{
    border: 2px solid #941616;
    background-color: #e14242;
}

    /* userID 클릭 시 bscscan.com으로이동 */
    .list a{
        text-decoration: none !important;
        color: #fff;
        transition: all .5s;
    }
    .list a.BNB:hover{
        color: #FBDA3C;
    }
    .list a.ETH:hover{
        color: #21325b;
    }
</style>