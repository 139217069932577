<template>
    <div>
        <div
            class="backdrop"
            v-on:click="hideModal">
        </div>
        <div
            class="modalWrap"
            v-bind:class="{'SBhistoryWrap': modalKey === 'transactionHistory'}">
            <div class="modalHeader">
                {{ title }}
                <div
                    class="closeBtn menu"
                    v-on:click.stop="hideModal"
                    v-if="isShowCloseBtn">
                    <img
                        src="../../assets/mobile/btn-close.png"
                        alt="close"/>
                </div>
            </div>
            <div
                class="modalBody"
                v-bind:class="{ 'helpModal': modalKey === 'help' }">
                <WalletConvertModal v-if="modalKey === 'convert'"/>
                <WalletTransferModal v-if="modalKey === 'deposit_menu'" />
                <WalletTransactionHistoryModal v-if="modalKey === 'transactionHistory'" />
            </div>
        </div>
        <ModalDealerInfo
            v-if="selectedDealer"
            v-on:hideDealerInfo="hideDealerInfo"
            v-bind:selectedDealer="selectedDealer"/>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { loginUser, loading } from "@/js/reactive";
    import { setNumberFormat, decompression } from "@/js/utils";
    import { DEALER_INFO, CARD_INFO_ARRAY, CARD_INFO_ARRAY_VALUE, FLAG_IMG, PLAYER_GRADE_IMG } from "@/js/const";
    import { calcDealerRank } from "@/js/common";
    import WalletTransferModal from "@/components/sabong/popup/WalletTransferModal";
    import WalletConvertModal from "@/components/sabong/popup/WalletConvertModal";
    import ModalDealerInfo from "@/components/popup/ModalDealerInfo";
    import WalletTransactionHistoryModal from "@/components/sabong/popup/WalletTransactionHistoryModal";

    export default defineComponent({
        "name": "WalletModal",
        components: {
            ModalDealerInfo,
            WalletTransferModal,
            WalletConvertModal,
            WalletTransactionHistoryModal
        },
        data(){
            return {
                loading: loading,
                FLAG_IMG: FLAG_IMG,
                PLAYER_GRADE_IMG: PLAYER_GRADE_IMG,
                isShowConfirm: false,
                isShowUserInfo: false,
                isShowHistoryDetail: false,
                CARD_INFO_ARRAY: CARD_INFO_ARRAY,
                loginUser: loginUser,
                selectedUser: {},
                selectedDealer: undefined,
                dealerRanks: [
                    {
                        'key': 'rank',
                        'tx': 'no',
                        'value': ''
                    },
                    {
                        'key': 'profile',
                        'tx': 'profile',
                        'value': ''
                    },
                    {
                        'key': 'user_id',
                        'tx': 'dealerName',
                        'value': ''
                    },
                    {
                        'key': 'amount_for_like',
                        'tx': 'like',
                        'value': ''
                    },
                    {
                        'key': 'amount_for_tip',
                        'tx': 'tip',
                        'value': ''
                    },
                    {
                        'key': 'amount_for_play_time',
                        'tx': 'playTime',
                        'value': ''
                    },
                ],
                myRanks: [
                    {
                        'key': 'no',
                        'value': 0,
                    },
                    {
                        'key': 'id',
                        'value': '-',
                    },
                    {
                        'key': 'detail',
                        'value': '',
                    },
                    {
                        'key': 'deposit',
                        'value': 0,
                    },
                    {
                        'key': 'withdraw',
                        'value': 0,
                    },
                    {
                        'key': 'bets',
                        'value': 0,
                    },
                    {
                        'key': 'pay',
                        'value': 0,
                    },
                    {
                        'key': 'win',
                        'value': 0,
                    },
                    {
                        'key': 'lose',
                        'value': 0,
                    },
                ],
                rankList: [],
                // rankIcons: {
                //     '0': require("../../assets/icons/grade-1.png"),
                //     '1': require("../../assets/icons/grade-2.png"),
                //     '2': require("../../assets/icons/grade-3.png")
                // },
                isShowCloseBtn: true,
                isShowPreview: false,
                tabKey: 1,
                dealers: {}
            }
        },
        props: {
            title: {
                type: String,
                default: () => {
                    return "";
                }
            },
            modalKey: {
                type: String,
                default: () => {
                    return "";
                }
            }
        },
        watch: {
            modalKey: {
                deep: true,
                immediate: true,
                handler(val){
                    if(this.modalKey === 'rank'){
                        this.getRank();
                    }
                }
            }
        },
        methods: {
            async clickTab(key){
                this.$emit('setModalKey', key);
                if(key == 'dealerRank'){
                    await this.getDealerRank();
                }
            },
            hideModal() {
                this.$emit('hideModal', false);
                this.$emit('hideShop');
            },
            showPreview(isShow){
                this.isShowPreview = isShow;
            },
            showCloseBtn(isShow){
                this.isShowCloseBtn = isShow
            },
            hideDealerInfo(){
                this.selectedDealer = undefined;
            },
            showDealerInfo(rank){
                this.selectedDealer = {
					...rank,
					'img': this.dealers[rank['user_id']]
                }
            },
            hideShop(){
                this.$emit('hideShop');
            },
            showUserInfo(user, key){
                if(key && key !== 'detail'){
                    return;
                }
                this.selectedUser['id'] = user['user_id'];
                this.selectedUser['deposit'] = user['amount_for_deposit'];
                this.selectedUser['withdraw'] = user['amount_for_withdraw'];
                this.selectedUser['bets'] = user['amount_for_bet'];
                this.selectedUser['country'] = user['country'] ? user['country'] : "INR";
                this.selectedUser['level'] = user['player_level'];
                this.selectedUser['flagImg'] = require(`../../assets/flag/${this.FLAG_IMG[this.selectedUser['country']]}`);
                this.selectedUser['gradeImg'] = require(`../../assets/grade/${this.PLAYER_GRADE_IMG[user['rating']]}`);
                this.isShowUserInfo = true;
            },
            async getDealerRank(){
                this.rankList = [];
                const res = await this.apis.getDealerRank();
                calcDealerRank(res['decrypt_data']['value']).forEach(dealer => {
                    this.rankList.push({
                        'rank': dealer['rank'] || '-',
                        'profile': dealer['user_id'],
                        'user_id': dealer['user_id'],
                        'detail': '',
                        'like': dealer['amount_for_like'],
                        'tip': dealer['amount_for_tip'],
                        'playTime': dealer['amount_for_play_time'],
                    });
                });

                let queue = [];
                this.rankList.forEach(dealer => {
                    queue.push(
                        this.apis.getDealerProfile({
                            start: 0,
                            end: 1,
                            id: dealer['user_id'],
                            req: true
                        })
                    );
                });
                const ress = await Promise.all(queue);
                ress.forEach(res => {
                    let meta = undefined;
                    if(res?.decrypt_data?.images.length > 0){
                        meta = res?.decrypt_data?.images[0]['meta_png'];
                        this.dealers[res['decrypt_data']['images'][0]['name']] = meta ? `data:image/png;base64,${decompression(meta)}` : require('../../assets/icons/lobby_table/dealer-none.png');
                    }
                });
            },
            async getRank() {
                this.rankList = [];
                const res = await this.apis.getRank({'type': 0 });
                if(res?.decrypt_data?.value?.myRanking){
                    let myRank = res?.decrypt_data?.value?.myRanking;
                    this.myRanks[0]['value'] = '50+';
                    this.myRanks[1]['value'] = myRank['user_id'];
                    this.myRanks[2]['value'] = myRank;
                    this.myRanks[3]['value'] = setNumberFormat(myRank['amount_for_deposit']);
                    this.myRanks[4]['value'] = setNumberFormat(myRank['amount_for_withdraw']);
                    this.myRanks[5]['value'] = setNumberFormat(myRank['amount_for_bet']);
                    this.myRanks[6]['value'] = setNumberFormat(myRank['amount_for_pay']);
                    this.myRanks[7]['value'] = setNumberFormat(myRank['amount_for_win']);
                    this.myRanks[8]['value'] = setNumberFormat(myRank['amount_for_lose']);
                }
				
                if(res?.decrypt_data?.value?.rankingList.length > 0){
                    res['decrypt_data']['value']['rankingList'].forEach(data => {
                        this.rankList.push({
                            'agent': data['agent_id'] || '-',
                            'id': data['user_id'],
                            'detail': '',
                            'deposit': data['amount_for_deposit'],
                            'withdraw': data['amount_for_withdraw'],
                            'bets': data['amount_for_bet'],
                            'pay': data['amount_for_pay'],
                            'win': data['amount_for_win'],
                            'lose': data['amount_for_lose'],
                            'info': data
                        });
                    });
                }
            },
        }
    })
</script>
<style scoped>
    .disabled{
        cursor: not-allowed;
    }
	.userId {
		color: white;
	}
	.preview {
		width: 35vw !important;
	}
	.backdrop {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: black;
		opacity: 0.5;
		overflow-y: hidden;
		z-index: 10;
	}
	.modalWrap {
		position: absolute;
		z-index: 20;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		width: 470px;
        height: auto;
		outline: 1px solid #bbbbbb;
		border-radius: 6px;
        background-color: #3b3b3b;
	}
    .helpWrap{
        position: absolute;
        z-index: 20;
        top: 50%;
        left: 50%;
        transform:translate(-50%, -50%);
        width: 568px;
        height: auto;
    }
	.helpWrap.lobby {
		top: 44% !important;
	}
	.userInfoModal {
		position: absolute;
		z-index: 21;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		width: 544px;
		height: 392px;
        border: 1px solid #bbb;
        border-radius: 6px;
        background-color: var(--modal-header-background-color);
	}
    .userInfoModal .modalHeader,
    .userInfoModal .modalBody{
        background-color: unset !important;
    }
	.modalWrap .modalHeader,
    .userInfoModal .modalHeader,
	.confirmModal .modalHeader{
		display: flex;
		flex-direction: row;
		justify-content: center;
		font-size: 24px;
		align-items: center;
		background-color: var(--modal-header-background-color);
		color: var(--modal-header-font-color);
		width: 100%;
        height: 63px;
	}
	.modalWrap .modalHeader .closeBtn,
	.confirmModal .modalHeader .closeBtn,
	.userInfoModal .modalHeader .closeBtn {
		position: absolute;
		z-index: 25;
		margin-right: 20px;
        top: 0;
        display: flex;
        height: inherit;
        align-items: center;
	}
    .modalWrap .helpModal{
        height: 300px !important;
    }
	.modalWrap .modalBody,
    .userInfoModal .modalBody,
	.historyModal .modalBody {
        background-color: var(--modal-header-background-color);
		width: 100%;
		height: auto;
		background-size: 100% 100%;
	}

	.confirmModal .modalBody {
		height: 700px;
	}

	.userInfoModal .modalBody {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 0px;
		gap: 10px;
		height: 296px;
	}

	.userInfoModal .modalBody .userInfo {
		display: flex;
		flex-direction: column;
		width: 90%;
		height: 88px;
	}

	.verticalLine {
		width: 2px;
		max-width: 2px;
		height: 26px;
		background-color: #484848;
	}

	.horizonLine {
		width: 90%;
		height: 2px;
		max-height: 2px;
		background-color: var(--modal-userInfo-line-color);
	}

	.userInfoModal .modalBody .userInfo .infos {
		flex: 1;
		display: flex;
		width: 95%;
		flex-direction: row;
		align-items: center;
		text-align: center;
		margin: 0 auto;
	}

	.userInfoModal .modalBody .userInfo .infos:not(.userInfoModal .modalBody .userInfo .infos:last-child) {
		border-bottom: 1px solid var(--inGame-header-border-color);
	}

	.userInfoModal .modalBody .userInfo .infos:first-child div:nth-child(1) {
		width: 165px;
	}

	.userInfoModal .modalBody .userInfo .infos:first-child div:nth-child(3) {
		width: 330px;
	}

	.userInfoModal .modalBody .userInfo .infos:last-child div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		gap: 5px;
		color: var(--modal-userInfo-col-font-color);
	}

	.userInfoModal .modalBody .userInfo .infos .text {
		color: var(--modal-userInfo-id-font-color);
	}

	.userInfoModal .modalBody .amounts,
	.userInfoModal .modalBody .userInfo {
		background-color: var(--inGame-header-color);
	}

	.userInfoModal .modalBody .amounts {
		width: 90%;
		height: 160px;
		display: flex;
		flex-direction: column;
	}

	.userInfoModal .modalBody .amounts .infos {
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: center;
	}

	.userInfoModal .modalBody .amounts .infos .text {
		margin-right: auto;
		margin-left: 15px;
	}

	.userInfoModal .modalBody .amounts .infos .amount {
		margin-left: auto;
		margin-right: 15px;
	}

	.rankWrap {
		width: 100%;
		height: inherit;
	}

	.shopWrap .header,
	.rankWrap .header {
		display: flex;
		align-items: center;
		width: 100%;
		height: 80px;
		margin: 0 auto;
	}
	.rankWrap .header {
		width: 95%;
	}

	.rankWrap .rank_tab {
        display: flex;
		width: 100%;
        flex-direction: row;
        padding-left: 26px;
        gap: 10px;
    }
    .rankWrap .rank_tab .tab {
		cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 50px;
		background-image: url("../../assets/shop/btn-gray-2.png");
        position: relative;
    }
	.tab.selected {
		background-image: url("../../assets/shop/btn-blue-2.png") !important;
	}
	.tab.lock {
		cursor: not-allowed !important;
	}
    .rankWrap .rank_tab .tab .tab_text {
        font-size: 16px;
        color: #f1f1f1;
        z-index: 1;
        font-weight: 300;
		white-space: nowrap;
    }

	.rankWrap .dealerBody {
		display: flex;
		width: 95%;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
	}

	.rankWrap .dealerBody .columns {
		display: flex;
		height: 80px;
		background-color: #2b2b2b;
		border-bottom: 2px solid #484848;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.rankWrap .dealerBody .columns div,
	.rankWrap .dealerBody .dealerBox .row div {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
	}

	.rankWrap .dealerBody .dealerBox{
		display: flex;
		flex-direction: column;
		overflow-y: scroll;
		height: 500px;
	}

	.rankWrap .dealerBody .dealerBox .row {
		background-color: #2b2b2b;
		border: 2px solid #484848;
	}

	.rankWrap .header .myRank,
	.rankWrap .body .columns .column {
		text-align: center;
		white-space: nowrap;
		color: var(--modal-rank-col-font-color);
		font-weight: bold;
		font-size: 14px;
	}
	.rankWrap ::v-deep(.row) {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 120px;
		font-size: 14px;
        border: solid 2px #484848;
		padding: 8px;
	}

	.rankWrap ::v-deep(.row) div {
		text-align: center;
        color: #e6e6e6;
	}

	.rankWrap ::v-deep(.row) div:nth-child(1),
	.rankWrap .body .columns .column:nth-child(1),
	.rankWrap .header .myRank:nth-child(1) {
		width: 5%;
		margin-left: 6px;
	}
	.rankWrap ::v-deep(.row) div:nth-child(2),
	.rankWrap .body .columns .column:nth-child(2),
	.rankWrap .header .myRank:nth-child(2) {
		width: 15%;
	}
	.rankWrap .body .row div:nth-child(3),
	.rankWrap .body .columns .column:nth-child(3),
	.rankWrap .header .myRank:nth-child(3) {
		transform: scale(0.7);
	}
	.rankWrap ::v-deep(.row) div:nth-child(4),
	.rankWrap .body .columns .column:nth-child(4),
	.rankWrap .header .myRank:nth-child(4) {
		width: 12%;
	}
	.rankWrap ::v-deep(.row) div:nth-child(5),
	.rankWrap .body .columns .column:nth-child(5),
	.rankWrap .header .myRank:nth-child(5) {
		width: 12%;
	}
	.rankWrap ::v-deep(.row) div:nth-child(6),
	.rankWrap .body .columns .column:nth-child(6),
	.rankWrap .header .myRank:nth-child(6) {
		width: 12%;
	}
	.rankWrap ::v-deep(.row) div:nth-child(7),
	.rankWrap .body .columns .column:nth-child(7),
	.rankWrap .header .myRank:nth-child(7) {
		width: 12%;
	}
	.rankWrap ::v-deep(.row) div:nth-child(8),
	.rankWrap .body .columns .column:nth-child(8),
	.rankWrap .header .myRank:nth-child(8) {
		width: 12%;
	}
	.rankWrap ::v-deep(.row) div:nth-child(9),
	.rankWrap .body .columns .column:nth-child(9),
	.rankWrap .header .myRank:nth-child(9) {
		width: 12%;
	}
	.rankWrap ::v-deep(.row) div:nth-child(10),
	.rankWrap .body .columns .column:nth-child(10),
	.rankWrap .header .myRank:nth-child(10) {
		width: 13%;
	}

    .rankBox {
        background-color: #1b1b1b;
    }

	.deposit, .playTime {
		color: #3ba4e1 !important;
	}

	.withdraw {
		color: #f10000 !important;
	}

	.bets, .tip {
		color: #fbe291 !important;
	}

	.pay {
		color: #e4b204 !important;
	}

	.win {
		color: #00d6d9 !important;
	}

	.lose, .like {
		color: #ff5454 !important;
	}

	.profit {
		color: #2be000 !important;
	}
	.loss {
		color: #ff121a !important;
	}

	.detail {
		background-image: url("../../assets/icons/glasses.png");
		background-size: 100% 100%;
		width: 34px;
		max-width: 34px !important;
		height: 34px !important;
		text-indent: -10000px !important;
		cursor: pointer;
	}

	.column.detail {
		background-image: none !important;
	}

	.rankWrap .body .columns {
		display: flex;
		align-items: center;
		width: 95%;
		height: 80px;
		margin: 0 auto;
	}

	.rankWrap .body .columns.vertical {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 95%;
		height: 440px;
		overflow-y: scroll;
		margin: 0 auto;
        padding: 5px;
        gap: 4px;
	}
    .profile{
        width: 82px !important;
        height: 76px;
        overflow: hidden !important;
    }
    .profile > img{
        width: 82px;
    }
    .closeBtn > img{
        width: 36px;
        height: 36px;
        vertical-align: middle;
    }

    .SBhistoryWrap{
        width: 85vw;
        background-color: #3b3b3b;
        height: auto;
        border-radius: 6px;
        border: 1px solid #bbb;
        padding: 8px;
    }
</style>