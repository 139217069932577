<template>
    <div class="inGameWrap">
        <div
            v-show="isShowChips"
            class="selectChipBg"></div>
        <div class="inGameTopBox">
            <div class="userPlayInfo">
                <ul>
                    <li>
                        <span>Balance</span>
                        <span v-numberFormat="loginUser['balance']"></span>
                    </li>
                    <li>
                        <span>Bet</span>
                        <span v-numberFormat="userBets['total']"></span>
                    </li>
                </ul>
                <div class="betProgressBar">
                    <p class="betRound">
                        <span>{{ $t('fightNo') }} : {{ getVinusSabongFightNo(sabongInfo["rooms"]) }}</span>
                    </p>
                    <div class="aniBetProgressBar">
                    </div>
                    <span>{{ getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] || 'Please wait.' }}</span>
                </div>
            </div>
            <div class="inGameTopLeftBox">
                <div class="inGameVsBar">
                    <div class="inGameProgressBarLeft">
                        <div
                            class="aniProgressBarWala"
                            v-bind:style="{ 'width': `${betUsers['list']?.['bet_info']?.['meron_dist'] || 0}%`}"></div>
                        <div class="ProgressBarHp">
                            <span> {{ `${betUsers['list']?.['bet_info']?.['meron_dist'] == 'NaN' ? '0.00' : betUsers['list']?.['bet_info']?.['meron_dist'] || '0.00' }%` }} </span>
                            <span
                                class="betPerson"
                                v-numberFormat="calcRate(betUsers['list']?.['total_bet']?.['meron_total'], exchangeInfo) || 0"></span>
                        </div>
                    </div>
                    <div class="VsBarCnt">
                        VS
                    </div>
                    <div class="inGameProgressBarRight">
                        <div
                            class="aniProgressBarMrn"
                            v-bind:style="{ 'width': `${betUsers['list']?.['bet_info']?.['wala_dist'] || 0}%` }"></div>
                        <div class="ProgressBarHp">
                            <span
                                class="betPerson"
                                v-numberFormat="calcRate(betUsers['list']?.['total_bet']?.['wala_total'], exchangeInfo) || 0"></span>
                            <span> {{ `${betUsers['list']?.['bet_info']?.['wala_dist'] == 'NaN' ? '0.00' : betUsers['list']?.['bet_info']?.['wala_dist'] || '0.00' }%` }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="inGameMidBox">
            <div class="inGameMidLeft">
                <div class="videoWrap">
                    <SabongCam v-bind:vendor="route.params.gameType"/>
                    <div class="betBtnBox">
                        <!--                        <img
                            v-on:mouseup.stop="showModal('skin', true)"
                            src="@/assets/sabong/mobile/icon/btn_uiskin.png">-->
                        <!--                        <img src="../../assets/sabong/mobile/icon/icon_menu.png">
                        <img src="../../assets/sabong/mobile/icon/icon_change_table.png">-->
                        <img
                            v-on:touchstart.stop="showModal('sabongHistory', true)"
                            src="@/assets/icons/ingame_top_menu/btn-history.png">
                        <img
                            v-on:mouseup="router.push('/lobby')"
                            src="@/assets/sabong/mobile/icon/icon_lobby.png">
                    </div>
                    <div
                        v-if="currentGameResult"
                        class="inGameResultBox"
                        v-bind:class="currentGameResult">
                        <h1>{{ currentGameResult }}</h1>
                        <div
                            class="resultPriceBox"
                            v-if="currentGameResult != 'cancel'">
                            <span v-numberFormat="realWinAmount"></span>
                        </div>
                        <div
                            class="cancelResultWrap"
                            v-else>
                            <div>
                                {{ $t('cancelInfo1') }} ({{ currentOdds }})
                            </div>
                            <div>
                                {{ $t('cancelInfo2') }}
                            </div>
                            <div
                                class="resultBox"
                                v-bind:class="currentSubRooster">
                                {{ currentSubRooster }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="inGameMidRight">
                <div
                    class="battingBlock"
                    v-if="loginUser.balance == 0">
                </div>
                <div class="inGameMidTop">
                    <div
                        class="userBetListMrn"
                        v-on:touchstart.stop="bet(selectChip, 'meron')">
                        <div class="userBetListTopBox mrn">
                            <h1>{{ $t('meron') }}
                                <span>{{ Number(betUsers['list']?.['bet_info']?.['meron_odd']) || '1.86' }}</span>
                            </h1>
                            <div class="amountBox">
                                <vue3-autocounter
                                    v-bind:startAmount="0"
                                    v-bind:endAmount="userBets['meron']"
                                    v-bind:duration="1"
                                    separator=","
                                    decimalSeparator="."
                                    v-bind:decimals="2"
                                    v-bind:autoinit="true"/>
                                <!--                                <span v-numberFormat="userBets['meron']"></span>-->
                            </div>
                            <div class="player">
                                <img
                                    src="../../assets/sabong/mobile/icon/icon_user.png"
                                    alt="">
                                <span>{{ betUsers['list']['meron'] ? Object.keys(betUsers['list']['meron']).length : 0 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="userBetListWala">
                        <div
                            class="userBetListTopBox wala"
                            v-on:touchstart.stop="bet(selectChip, 'wala')">
                            <h1>{{ $t('wala') }}
                                <span>{{ Number(betUsers['list']?.['bet_info']?.['wala_odd']) || '1.86' }}</span>
                            </h1>
                            <div class="amountBox">
                                <vue3-autocounter
                                    v-bind:startAmount="0"
                                    v-bind:endAmount="userBets['wala']"
                                    v-bind:duration="1"
                                    separator=","
                                    decimalSeparator="."
                                    v-bind:decimals="2"
                                    v-bind:autoinit="true"/>
                                <!--                                <span v-numberFormat="userBets['wala']"></span>-->
                            </div>
                            <div class="player">
                                <img
                                    src="../../assets/sabong/mobile/icon/icon_user.png"
                                    alt="">
                                <span>{{ betUsers['list']['wala'] ? Object.keys(betUsers['list']['wala']).length : 0 }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="inGameBetBox">
            <div
                class="battingBlock"
                v-if="loginUser.balance == 0">
                {{ $t('noBalance') }}
            </div>
            <div class="inGameChipBox">
                <div
                    class="leftBtnBox"
                    v-on:click.stop="clickBetCancel()">
                    <div
                        class="inGameCancelBtn"
                        v-if="isBetStart">
                        <img
                            src="../../assets/icons/ingame_bet_btn/btn_cancel.png"
                            alt="">
                        <p>{{ $t('cancel') }}</p>
                    </div>
                </div>

                <div class="inGameChips">
                    <div class="select">
                        <img
                            v-on:touchstart.stop="showChips()"
                            v-bind:src="selectChip['chip']?.['src']">
                        <p v-on:touchstart.stop="showChips()">
                            {{ selectChip['chip']?.['tx'] }}
                        </p>
                        <div
                            class="chips"
                            v-show="isShowChips">
                            <div
                                v-for="(chip, idx) in chips['Basic']"
                                v-bind:class="chip['key']"
                                v-on:touchstart.stop="clickChip(chip)"
                                v-bind:key="idx">
                                <img
                                    v-bind:src="chip['src']"
                                    alt="">
                                <p>{{ chip['tx'] }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="rightBtnBox"
                    v-on:click.stop="clickBetConfirm()">
                    <div
                        class="inGameRecallBtn"
                        v-if="isBetStart">
                        <img
                            src="../../assets/sabong/btn_confirm_1.png"
                            alt="">
                        <p>
                            {{ $t('confirm') }}
                            <span class="betCountdown">
                                ({{ betCountDown }})
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="inGameListBox">
                <div
                    class="mrn"
                    v-on:touchstart.stop="showBetList('meron')">
                    <span>MERON</span>
                    <p>Bet List</p>
                </div>
                <div
                    class="wala"
                    v-on:touchstart.stop="showBetList('wala')">
                    <span>WALA</span>
                    <p>Bet List</p>
                </div>
            </div>
        </div>
        <div class="inGameBtmBox">
            <div class="inGameTabBox">
                <ul>
                    <li
                        v-on:click="selectRoadMap = 0"
                        v-bind:class="{ 'select': selectRoadMap == 0}">
                        <span></span>
                        <p>Bead Road</p>
                    </li>
                    <li
                        v-on:click="selectRoadMap = 1"
                        v-bind:class="{ 'select': selectRoadMap == 1}">
                        <span></span>
                        <p>Big Road</p>
                    </li>
                    <li
                        v-on:click="selectRoadMap = 2"
                        v-bind:class="{ 'select': selectRoadMap == 2}">
                        <span></span>
                        <p>Trend</p>
                    </li>
                </ul>
            </div>
            <div
                class="scoreboardBox"
                v-if="selectRoadMap == 0">
                <ul class="betDetailBox">
                    <li>
                        <img src="@/assets/sabong/mobile/icon/icon_m.png">
                        <span>{{ $t('meron') }}</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/mobile/icon/icon_d.png">
                        <span>{{ $t('draw') }}</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/mobile/icon/icon_w.png">
                        <span>{{ $t('wala') }}</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/mobile/icon/icon-c.png">
                        <span>{{ $t('cancel') }}</span>
                    </li>
                </ul>
                <div class="scoreboardInner">
                    <BaseRoad v-bind:gameType="gameType"/>
                </div>
            </div>
            <div
                class="scoreboardBox"
                v-if="selectRoadMap == 1">
                <ul class="betDetailBox">
                    <li>
                        <img src="@/assets/sabong/rin-m.png">
                        <span>{{ $t('meron') }}</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/ring-d.png">
                        <span>{{ $t('draw') }}</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/ring-w.png">
                        <span>{{ $t('wala') }}</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/ring-c.png">
                        <span>{{ $t('cancel') }}</span>
                    </li>
                </ul>
                <div class="scoreboardInner">
                    <BigRoad v-bind:gameType="gameType"/>
                </div>
            </div>
            <div
                class="scoreboardBox"
                v-if="selectRoadMap == 2">
                <ul class="betDetailBox">
                    <li>
                        <img src="@/assets/sabong/l-text.png">
                        <span>Llamado</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/d-text.png">
                        <span>Dehado</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/p-text.png">
                        <span>Parehasan</span>
                    </li>
                    <li>
                        <img src="@/assets/sabong/c-text.png">
                        <span>Cancel</span>
                    </li>
                </ul>
                <div class="scoreboardInner">
                    <BigRoadText v-bind:gameType="gameType"/>
                </div>
            </div>
        </div>
    </div>
    <BetList
        v-if="modals['betList']"
        v-bind:exchangeInfo="exchangeInfo"
        v-bind:betKey="betKey"/>
    <SabongModalHistoryM
        v-if="modals['sabongHistory']"
        v-on:showModal="showModal"/>
    <SBingameThemeModalM
        v-if="modals['skin']"/>
</template>
<script setup>
    import { chips, loginUser, topics, sabongInter } from '@/js/reactive';
    import { setCurrencyInfo } from "@/js/common";
    import { SABONG_GAME_TYPE_IDX } from "@/js/const";
    import router from '@/router';
    import { getCookie } from '@/js/utils';
    import { setCurrencyChip, getGameResult, sabongInfo, generateSabongInfo, isShowBtnInterface, clearBetData, initData, userBets, betUsers, getBetUsers, getSabongEvents, bet, getVinusSabongRoomStatus, getVinusSabongFightNo } from "@/js/inGame/sabongInter";
    import { onMounted, onBeforeMount, onUnmounted, reactive, ref , watchEffect, watch, computed } from "vue";
    import moment from "moment-timezone";
    import { getBalance, getPing } from "@/js/request";
    import { generateSabongScoreBoard } from "@/js/scoreBoard/generator";
    import { calcRate } from '@/js/inGame/exchange';

    import BaseRoad from "@/components/scoreBoard/BaseRoad";
    import BigRoad from "@/components/scoreBoard/BigRoad";
    import BigRoadText from "@/components/scoreBoard/BigRoadText";
    import BetList from "@/components/sabongInter/BetListM";
    import InGameIcons from "@/components/common/InGameIcons";
    import SabongCam from "@/components/common/SabongCam";
    import SBingameThemeModalM from "@/components/sabong/popup/SBingameThemeModalM";
    import SabongModalHistoryM from "@/components/sabong/popup/SabongModalHistoryM";
    /*	https://vuejsexamples.com/creating-an-animated-number-counter-in-vue3 */
    import Vue3autocounter from 'vue3-autocounter';

    import { modals, showModal } from "@/js/modal/modals";
    import { MQTT } from "@/plugins/mqtt";
    import { useRoute } from "vue-router";
    import { useStore } from "vuex";
    const store = useStore();
    const route = useRoute();

    const recentResult = ref(undefined);
    const rootWrap = ref(undefined);
    const currentFightNo = ref(undefined);
    const currentGameResult = ref(undefined);
    const currentSubRooster = ref(undefined);
    const currentOdds = ref (undefined);
    const realWinAmount = ref(0);
    const isBetStart = ref(false);
    const gameType = route.params.gameType == 'pcc' ? 'vinusPcc' : route.params.gameType;
    const gameIdx = SABONG_GAME_TYPE_IDX[gameType];
    let prevEventId = ref(undefined);
    // 로그인한 유저의 CMS에 등록된 환율 정보
    const exchangeInfo = ref(undefined);

    watchEffect(async () => {

        if(sabongInfo['rooms']?.['event_id']){
            prevEventId.value = sabongInfo['rooms']?.['event_id'];
        }

        if(sabongInfo['rooms']){
            let betStatus = getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'];
            isBetStart.value =  betStatus == 'open' || betStatus == 'lastCall' ? true : false;
            if(currentFightNo.value && sabongInfo['rooms']['fight_no'] != currentFightNo.value){
                initData();
            }
            currentFightNo.value = sabongInfo['rooms']['fight_no'];
        }
        // 서버 토픽 게임IDx
        if(topics?.[`sabong_result_${gameIdx}`]){
            currentGameResult.value = topics[`sabong_result_${gameIdx}`]?.['rooster'] || '';
            currentSubRooster.value = topics[`sabong_result_${gameIdx}`]?.['subRooster'] || '';
            currentOdds.value = `MERON: ${topics[`sabong_result_${gameIdx}`]?.['meron_odd']} WALA: ${topics[`sabong_result_${gameIdx}`]?.['wala_odd']}`
            if(topics?.[`sabong_result_${gameIdx}`]?.user){
                let find = topics[`sabong_result_${gameIdx}`]['user']?.[getCookie('loginId')];
                if(find){
                    realWinAmount.value = find['win_amount'] > 0 ? Number(find['win_amount'].toFixed(2)) : -Number((find['bet_amount']).toFixed(2));
                }
            }
            await getGameResult(gameType, prevEventId.value);
            let timer = setTimeout(async () => {
                currentGameResult.value = undefined;
                currentSubRooster.value = undefined;
                currentOdds.value = undefined;
                realWinAmount.value = 0;
                delete topics[`sabong_result_${gameIdx}`];
                generateSabongScoreBoard(sabongInfo['history'], gameType);
                clearTimeout(timer);
            }, 5000);
        }
    });

    watch(isBetStart, (newVal, oldVal) => {
        if(newVal == false && oldVal == true){
            isBetStart.value = false;
        }
    });

    const betKey = ref("");
    const isShowChips = ref(false);
    const reqTimer =  ref(undefined);
    const timer = {};
    const selectChip = reactive({
        chip: chips['Basic'][0]
    });

    const selectRoadMap = ref(0);

    const clickChip = (chip) => {
        selectChip['chip'] = chip;
        isShowChips.value = false;
    }

    const betCountDown = ref(5);
    let betCountDownTimer = undefined;
    const clearBetCountDown = () => {
        clearInterval(betCountDownTimer);
        betCountDownTimer = undefined;
        betCountDown.value = 5;
    }
    const clickBetConfirm = async () => {
        if(userBets['meron'] + userBets['wala'] + userBets['draw'] <= 0 || betCountDownTimer){
            return;
        }
        betCountDown.value -=1;
        betCountDownTimer = setInterval(async () => {
            if(betCountDown.value == 1){
                await bet('','',true,false,false);
                clearBetCountDown();
                return;
            }
            betCountDown.value -=1;
        }, 1000);
    }

    const clickBetCancel = () => {
        clearBetCountDown();
        clearBetData(false,false);
    }

    const showChips = () => {
        isShowChips.value = !isShowChips.value;
    }

    const showBetList = (key) => {
        betKey.value = key;
        showModal('betList', true);
    }
    onBeforeMount(async () => {
        await getSabongEvents();
        generateSabongInfo(gameType);
        exchangeInfo.value = await setCurrencyInfo();
        setCurrencyChip(route.params.gameType, 'Basic');
        if(sabongInfo['rooms']?.['event_id']){
            prevEventId.value = sabongInfo['rooms']?.['event_id'];
        }
        await getGameResult(gameType, prevEventId.value);
        //0326 await generateSabongScoreBoard(sabongInfo['history'], gameType);
        if(Object.keys(sabongInfo['rooms']).length > 0 && getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] != 'close'){
            await getBetUsers();
        }
        topics[`sabong_result_${gameIdx}`] = undefined;
        MQTT();
        getPing();
    });

    onMounted(async () => {
        await getSabongEvents();
        generateSabongInfo(gameType);
        timer['event'] = setInterval(async () => {
            await getSabongEvents();
            generateSabongInfo(gameType);
        }, 1000);
        timer['balance'] = setInterval( async () => {
            await getBalance(true);
        }, 3000);
        if(Object.keys(sabongInfo['rooms']).length > 0){
            await getBetUsers();
        }
        timer['betUsers'] = setInterval( async () => {
            if(Object.keys(sabongInfo['rooms']).length > 0 && getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] != 'close'){
                await getBetUsers();
            }
        }, 5000);
    });

    onUnmounted(() => {
        Object.keys(timer).forEach(key => {
            clearInterval(timer[key]);
        });
        clearInterval(reqTimer.value);
    })

    const icons = reactive([
        {
            'key': 'change',
            'img': require("@/assets/icons/ingame_top_menu/btn-tchange.png"),
        },
        {
            'key': 'history',
            'img': require("@/assets/icons/ingame_top_menu/btn-history.png"),
        },
        {
            'key': 'user',
            'img': require("@/assets/icons/ingame_top_menu/btn-user-list.png"),
        },
        {
            'key': 'chat',
            'img': require("@/assets/icons/ingame_top_menu/btn-chat.png"),
        },
        {
            'key': 'userInfo',
            'img': require("@/assets/icons/ingame_top_menu/btn-user-info.png"),
        },
        {
            'key': 'video',
            'img': require("@/assets/icons/ingame_top_menu/btn-cam.png"),
        },
        {
            'key': 'tutorial',
            'img': require("@/assets/icons/ingame_top_menu/btn-tutorial.png"),
        },
        {
            'key': 'sound',
            'img': require("@/assets/icons/ingame_top_menu/btn-sound.png"),
        },
        {
            'key': 'full',
            'img': require("@/assets/icons/ingame_top_menu/btn-full.png"),
        },
        {
            'key': 'exit',
            'img': require("@/assets/icons/ingame_top_menu/btn-exit.png"),
        },
    ]);
</script>
<style scoped>
span.betCountdown {
	color: gold;
	font-size: 10px;
}

.cancelResultWrap {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	font-size: 10px !important;
	position: relative;
	top: 10px;
}
.cancelResultWrap .resultBox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 30px;
}

.resultBox.wala {
	background-color: #2056af;
	text-transform: uppercase;
}
.resultBox.meron {
	background-color: #b13636;
	text-transform: uppercase;
}
.resultBox.draw {
	background-color: #26813b;
	text-transform: uppercase;
}

.aniProgressBarWala,
.aniProgressBarWala-leave-to{
	transition: all .5s ease;
}
.aniProgressBarWala-enter-active,
.aniProgressBarWala-leave-active{
	transition: all 1s ease;
}

.aniProgressBarMrn,
.aniProgressBarMrn-leave-to{
	transition: all .5s ease;
}
.aniProgressBarMrn-enter-active,
.aniProgressBarMrn-leave-active{
	transition: all 1s ease;
}

.aniBetProgressBar,
.aniBetProgressBar-leave-to{
	transition: all 3s ease;
}
.aniBetProgressBar-enter-active,
.aniBetProgressBar-leave-active{
	transition: all 3s ease;
}

.aniProgressBarWala{
	width: 0%;

}
.aniProgressBarMrn{
	width: 0%;
}
.inGameWrap{
	width: 100%;
	height: 100%;
}
.inGameTopBox{
	width: 100%;
	height: 75px;
	padding: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.inGameMidBox{
	width: 100%;
	height: 811px;
}
.inGameBtmBox{
	width: 100%;
	height: auto;
	padding: 4px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.inGameTopLeftBox{
	width: 100%;
	height: 100%;
}
.inGameVsBar{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.inGameProgressBarLeft{
	position: relative;
	width: 48%;
	height: 100%;
	background-color: #5a5a5c;
	border-radius: 2rem 0 0 2rem;
	overflow-x: hidden;
}
.inGameProgressBarLeft > span{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
}
.aniProgressBarWala{
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	background-color: #bb3a3a;
	border-radius: 0;
}
.inGameProgressBarRight{
	position: relative;
	width: 48%;
	height: 100%;
	background-color: #5a5a5c;
	border-radius: 0 2rem 2rem 0;
	overflow-x: hidden;
}
.inGameProgressBarRight > span{
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-weight: bold;
	font-size: 14px;
	color: #fff;
}
.aniProgressBarMrn{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: #2056af;
}
.VsBarCnt{
	width: 30px;
	height: 100%;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
	color: #fafafa;
	background-color: #d5a922;
}
.inGameTopRightBox > ul{
	display: flex;
	flex-direction: row;
	gap: 12px;
}
.inGameTopRightBox > ul > li > img{
	width: 50px;
}
.inGameMidBox{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.inGameMidLeft{
	width: 100%;
	height: 100%;
}
.videoWrap{
	position: relative;
	width: 100%;
	height: 219px;
	text-align: center;
	color: #fff;
	font-size: 20px;
	background-image: url("../../assets/sabong/cam.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
    margin: 4px 0;
}
.betBtnBox{
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	margin-right: 4px;
	margin-top: 6px;
	z-index: 1;
}
.betBtnBox > img{
	width: 40px;
	vertical-align: middle;
	cursor: pointer;
}

.inGameResultBox{
	position: absolute;
	width: 100%;
	height: 150px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.inGameResultBox.meron{
	background-color: rgba(88, 11, 11, 0.8);
}
.inGameResultBox.wala{
	background-color: rgba(16, 56, 109, 0.8);
}
.inGameResultBox.draw{
	background-color: rgba(18, 100, 29, 0.8);
}
.inGameResultBox.cancel{
	background-color: rgba(32, 32, 32, 0.8);
}

.inGameResultBox > p{
	font-size: 20px;
	font-weight: 600;
}
.inGameResultBox > div{
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 5px;
	width: 250px;
	height: 24px;
	line-height: 24px;
	font-size: 16px;
}

.inGameMidRight{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	gap: 6px;
	padding: 0 4px;
	position: relative;
}

.battingBlock {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 7px;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	height: 100%;
	bottom: 0px;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	cursor: not-allowed;
}

.inGameMidRight .inGameMidTop{
	height: 58px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 4px;
}
.userBetListMrn,
.userBetListWala
{
	position: relative;
	width: 49.4%;
	height: 60px;
}
.userBetListDraw{
	position: relative;
	width: 100%;
	height: 60px;
}
.userBetListTopBox{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: #fff;
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	border-radius: 6px;
}
.userBetListTopBox.mrn{
	background-image: linear-gradient(to bottom, #bb3a3a, #981e1e);
	border: 1px solid  #ff516f;
}
.userBetListTopBox.wala{
	background-image: linear-gradient(to bottom, #2056af, #0d3880);
	border: 1px solid #3395d6;
}
.userBetListTopBox > h1{
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 18px;
	line-height: 16px;
	font-weight: 600;
}
.userBetListTopBox > h1 > span{
	font-size: 13px;
	font-weight: 300;
    color: #19e657;
}
.amountBox{
	width: 142px;
	height: 20px;
	border-radius: 2rem;
	text-align: center;
	line-height: 20px;
	font-size: 14px;
	color: #ffce23;
}
.mrn .amountBox{
	background-color: #371b1b;
	color: #ffce23;
}
.wala .amountBox{
	background-color: #1a1a35;
	color: #ffce23;
}
.player{
	position: absolute;
	top: 2px;
	right: 2px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1px;
	width: 42px;
	height: 14px;
	border-radius: 2rem;
	padding: 0 4px;
	background-color: #371b1b;
}
.player span{
	font-size: 10px;
}
.player img{
    width: 6px;
}
.userPlayInfo{
	width: 100%;
	height: 55px;
	padding: 4px;
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.userPlayInfo ul{
	width: 100%;
	height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 4px;
}
.userPlayInfo ul > li{
	height: 20px;
	line-height: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	font-size: 12px;
	border: 1px solid #484848;
	padding: 0 4px;
}
.userPlayInfo ul > li span:nth-child(2){
	color: #ffce23;
}
.userPlayInfo ul > li:nth-child(1){
	width: 50%;
	background-color: #2b2b2c;
	border-radius: 2rem 0 0 2rem;
}
.userPlayInfo ul > li:nth-child(2){
	width: 50%;
	background-color: #2b2b2c;
	border-radius: 0 2rem 2rem 0;
}
.betProgressBar{
	width: 100%;
	height: 22px;
	border-radius: 2rem;
	position: relative;
	background-color: #00936f;
}
.videoWrap .betTime{
    width: 100%;
    height: auto;
    position: absolute;
    top:94%;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.videoWrap .betTime span{
    font-size: 10px;
    color: #fff;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}
.betProgressBar .betRound{
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 0 2%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.betProgressBar .betRound span{
     font-size: 10px;
     color: #fff;
}
.betProgressBar>span{
    width: 100%;
    position: absolute;
    text-align: center;
    line-height: 22px;
    color: #fff;
    font-size: 14px;
}
.aniBetProgressBar{
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	border-radius: 2rem 0 0 2rem;
}

.inGameBetBox{
    position: relative;
	width: 100%;
	height: auto;
	padding: 0 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.inGameChipBox{
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}
.inGameChipBox > div{
	width: 33.3%;
}
.inGameChipBox > div p{
	color: #fafafa;
	font-size: 16px;
	white-space: nowrap;
}
.inGameChipBox .leftBtnBox img,
.inGameChipBox .rightBtnBox img{
	width: 60px;
	max-width: unset;
    margin-bottom: 5px;
}

.inGameChipBox .leftBtnBox{
	display: flex;
	justify-content: center;
}

.inGameChipBox .inGameChips{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}
.inGameChipBox .inGameChips .select > img{
	width: 70px;
}
.inGameChipBox .inGameChips .select{
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 4;
}
.inGameChipBox .inGameChips .select p{
	position: absolute;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #db0000;
	-webkit-text-stroke: 1px #000;
}


/* chip 클릭시 보이는 부분*/
.selectChipBg{
	position: absolute;
	width: 100vw;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	z-index: 2;

}
.inGameChipBox .inGameChips .chips{
	position: absolute;
	z-index: 1;

}
.inGameChipBox .inGameChips .chips > div{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.inGameChipBox .inGameChips .chips p{
	position: absolute;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.inGameChipBox .inGameChips .chips img{
	width: 50px;
	max-width: unset;
}
.chipBlack{
	top: 218px;
	left: -83px;
}
.chipBlue{
	top: 90px;
	left: -115px;
}
.chipGreen{
	bottom: 31px;
	left: -77px;
}
.chipPink{
	bottom: 114px;
	left: 0px;
}
.chipPurple{
	bottom: 132px;
	right: -77px;
}
.chipYellow{
	bottom: 111px;
	right: -110px;
}
.chipRed{
	bottom: 81px;
	right: -79px;
}

.inGameChipBox .rightBtnBox{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;
}
.inGameChipBox .rightBtnBox > div{
	width: 50%;
}
.inGameChipBox div > div{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.inGameListBox{
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 4px;
}
.inGameListBox > div{
	width: 50%;
	background-color: #2b2b2b;
	height: 42px;
	border: 1px solid #b7b7b7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #fafafa;
	font-weight: 300;
}
.inGameListBox > div.mrn span{
	color: #ff4a4a;
	font-size: 14px;
}
.inGameListBox > div.wala span{
	color: #5d8dff;
	font-size: 14px;
}
.inGameTabBox{
	width: 100%;
	height: auto;
	padding: 0 4px;
}
.inGameTabBox ul{
	width: 100%;
	height: 30px;
	border-radius: 2rem;
	border: 1px solid #6c6c6c;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 34px;
	padding: 0 20px;
}
.inGameTabBox ul li{
	color: #646464;
	font-size: 12px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 4px;
}
.inGameTabBox ul li.select{
	color: #ffd52d;
}
.inGameTabBox ul li span{
	display: inline-block;
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
}
.inGameTabBox ul li:nth-child(1) span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_1_g.png");
}
.inGameTabBox ul li:nth-child(2) span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_2_g.png");
}
.inGameTabBox ul li:nth-child(3) span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_3_g.png");
}
.inGameTabBox ul li:nth-child(1).select span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_1_y.png");
}
.inGameTabBox ul li:nth-child(2).select  span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_2_y.png");
}
.inGameTabBox ul li:nth-child(3).select  span{
	background-image: url("../../assets/sabong/mobile/icon/board_icon_3_y.png");
}

.scoreboardBox{
	width: 100%;
	background-color: #2b2b2c;
	border: 1px solid #484848;
	padding: 2px 4px 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 5px;
}
.scoreboardInner{
	width: 100%;
	height: 144px;
	/*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAABxJREFUKFNjZCAO/GckTh3DqEK8AQUOnv/EhCUAZm8KAZQzbbIAAAAASUVORK5CYII=") repeat #fff;
	background-size: 22px 22px;*/
}

.betDetailBox{
	width: 100%;
	height: 23px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
.betDetailBox li{
	color: #fff;
	font-size: 14px;
	display: flex;
	align-items: center;
}
.scoreboardBox:nth-child(1) .betDetailBox li > img{
	width: 18px  ;
	vertical-align: middle;
	margin-right: 3px;
}
.scoreboardBox:nth-child(2) .betDetailBox li > img{
	width: 18px;
	vertical-align: middle;
	margin-right: 3px;
}
.scoreboardBox:last-child .betDetailBox li > img{
	width: 16px;
	vertical-align: middle;
	margin-right: 3px;
}

/* 20211111 한유정 추가 */
.sbb2,
.sbb3{
	display: none;
}
.ProgressBarHp{
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	gap: 30px;
	font-size: 14px;
}
.ProgressBarHp > span{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	color: #fff;
}
.betPerson{
	color: #ffce23 !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 6px !important;
}


</style>