import { encryptData, decryptData } from "@/js/utils";
import { topics }                   from "@/js/reactive";
import moment from "moment";
import {chatArr, distArr} from "@/js/reactive";
import { getCookie } from "@/js/utils";
import { MqttClient } from "mqtt";

const mqtt   = require("mqtt");
const mqttOpt = {
    connectTimeout: 4000,
    reconnectPeriod: 1000,
    qos : 2,
    clean: true,
};

const DOMAIN_MQTT_CONNECT_IP = {
    "sabong.jgpot.com":  process.env.VUE_APP_MQTT_IP_ADDRESS,
    "localhost":  process.env.VUE_APP_MQTT_IP_ADDRESS,
    "vgsabong.com":  process.env.VUE_APP_MQTT_IP_ADDRESS_VGSABONG,
    "vinuslive.com":  process.env.VUE_APP_MQTT_IP_ADDRESS,
    "vsabongservice.com":  process.env.VUE_APP_MQTT_IP_ADDRESS,
    "kinggalaxyinteractive.com":  process.env.VUE_APP_MQTT_IP_ADDRESS,
    "kg-365.com":  process.env.VUE_APP_MQTT_IP_ADDRESS_1,
    "vinuscockxing.com": process.env.VUE_APP_MQTT_IP_ADDRESS_COCKXING,
    "vinusinter.com": process.env.VUE_APP_MQTT_IP_ADDRESS_VINUS_INTER,
    "vinus-inter.com": process.env.VUE_APP_MQTT_IP_ADDRESS_VINUS_INTER,
    "127.0.0.1":  process.env.VUE_APP_MQTT_IP_ADDRESS,

}

let client;
let isConnect = false;
export const MQTT = () => {
    if(client == null || !client.connected){
        client = mqtt.connect(DOMAIN_MQTT_CONNECT_IP[location.hostname], mqttOpt);
        client.on("connect", (topic) => {
            client.subscribe("#", mqttOpt, (err) => {
                if (!err) {
                    isConnect = true;
                }
            });
        });
        client.on("message", async (topic, message, packet) => {
            if(process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live'){
                const data = decryptData(message.toString());
                if(data){
                    topics[topic] = data;
                }
            }
            if(topic === 'chat'){
                message = message.toString();
                chatArr.push(message);
                if(chatArr.length > 30){
                    chatArr.shift();
                }
            }

            if(topic === 'userDist'){
                message = message.toString();
                if(message === ''){
                    return;
                }
                let uuid = message.split('/')[0];
                let findIdx = distArr.findIndex(dist => dist.split('/')[0] == uuid);
                if(findIdx > -1){
                    distArr.splice(findIdx, 1);
                }
                distArr.push(message);
            }
            if(topic.indexOf('MainSabong') > -1 && packet.qos == 2){
                const data = decryptData(message.toString());
                if(data) {
                    topics[topic] = data;
                }
            }
            if(topic.indexOf('sabong_result') > -1 && packet.qos == 2) {
                const data = decryptData(message.toString());
                if(data) {
                    topics[topic] = data;
                }
            }
        });

        client.on("end", (topic, message) => {
            client.end();
            client = mqtt.connect(process.env.VUE_APP_MQTT_IP_ADDRESS);
        });
        client.on("close", (topic, message) => {
            client.end();
            client = mqtt.connect(process.env.VUE_APP_MQTT_IP_ADDRESS);
        });
        client.on("error", (topic, message) => {
            client.end();
            client = mqtt.connect(process.env.VUE_APP_MQTT_IP_ADDRESS);
        });
        client.on("disconnect", (topic, message) => {
            client.end();
            client = mqtt.connect(process.env.VUE_APP_MQTT_IP_ADDRESS);
        });
        client.on("offline", (topic, message) => {
            client.end();
            client = mqtt.connect(process.env.VUE_APP_MQTT_IP_ADDRESS);
        });
        client.on("outgoingEmpty", (topic, message) => {
            //alert('1');
            //client.end();
        });
    }
};

export const chat = (msg) => {
    client.subscribe('chat', mqttOpt, function (err) {
        if (!err) {
            client.publish('chat', msg);
        }
    });
}

// 유저별 정보 표시하기 위해
export const setUserBetInfo = (msg) => {
    client.subscribe('userDist', mqttOpt, function (err) {
        if (!err) {
            client.publish('userDist', msg);
        }
    });
};

// 딜러툴과 토픽 통신
export const sendTopic = (topic, obj) => {
    client.publish(topic, encryptData(JSON.stringify(obj)));
}