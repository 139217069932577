<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('history') }}</h1>
            <span
                class="exitImg"
                v-on:click.stop="showModal('sabongHistory', false)">
                <img
                    alt="close"
                    src="@/assets/mobile/btn-close.png">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="historyWrap">
                <div
                    class="historyList noData"
                    v-if="historyData.historyList.length == 0">
                    {{ $t('noData')}}
                </div>
                <div
                    class="historyList"
                    v-else
                    v-for="(history, idx) in historyData.historyList"
                    v-on:click.stop="historyCmd.fn.setHistoryDetl(history)"
                    v-bind:key="idx">
                    <ul class="historyListTopBox">
                        <li class="accentColor">
                            #{{ history['event_id'] }}
                        </li>
                        <hr class="hr">
                        <li>{{ $t('round') }} {{ history['fight_no'] }}</li>
                    </ul>
                    <table
                        cellpadding="0"
                        cellspacing="0">
                        <tr>
                            <td class="accentColor">
                                {{ $t('date') }}
                            </td>
                            <td class="accentColor">
                                {{ $t('bet') }}
                            </td>
                            <td>
                                <span class="winColor">
                                    {{ $t('win2') }}
                                </span>
                                <span class="accentColor">
                                    &#47;
                                </span>
                                <span class="loseColor">
                                    {{ $t('loss') }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ history['reg_dt'] }}</td>
                            <td v-number-format="history['bet_amount']"></td>
                            <td
                                v-number-format="history['winnings']"
                                v-bind:class="{
                                    'profit': history['winnings'] > 0,
                                    'loss': history['winnings'] < 0
                                }"></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="historyFooter">
            <span v-on:touchstart.stop="historyCmd.fn.goPrevPage()">
                Prev
            </span>
            <ul class="historyPageNum">
                <li>{{ historyData.curPage }}</li>
                <li>&#47;</li>
                <li>{{ historyData.totalPage }}</li>
            </ul>
            <span v-on:touchstart.stop="historyCmd.fn.goNextPage()">
                Next
            </span>
        </div>
    </div>
    <SabongHistoryDetlM v-if="modals['sabongHistoryDetl']"/>
</template>
<script setup>
    import { onBeforeMount, onUnmounted, ref, defineProps } from "vue";
    import { historyData, getHistoryCmd, getWinColor, initSabongHistoryData } from "@/js/history/history";
    import SabongHistoryDetlM from "@/components/sabong/popup/SabongHistoryDetlM";
    import { showModal, modals } from "@/js/modal/modals";
    import { SABONG_GAME_TYPE_IDX } from '@/js/const';
    import { useRoute } from "vue-router";
    const route = useRoute();

    let historyCmd = ref(undefined);
    onBeforeMount(async () => {
        historyCmd.value = getHistoryCmd(route.meta.gameType);
        await historyCmd.value.fn.setHistory(SABONG_GAME_TYPE_IDX[route.params.gameType]);
    });
    onUnmounted(() => {
        modals['sabongHistoryDetl'] = false;
        initSabongHistoryData();
    });
</script>
<style scoped>
.historyFooter{
	height: 60px;
	margin: 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 30px;
}
.historyFooter > span{
	border-radius: 10px;
	border: 1px solid #fff;
	font-size: 20px;
	padding: 5px 10px;
	cursor: pointer;
}
.historyPageNum{
	display: flex;
	column-gap:5px;
}
.sideMenuBox{
	width: 100%;
	height: 100vh;
	padding-bottom: 60%;
	color: #fff;
	font-size: 14px;
	background-color: #2e3033;
	position: fixed;
	top: 0;
	z-index: 30;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 18px;
}
.sideMenuInner{
	padding: 0 3%;
	background-color: #2e3033;
	height: calc(100vh - 106px);
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 4%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/* 내용 */
.historyWrap{
	width: 100%;
	padding: 12px 4px;
	background-color: #1b1b1b;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
}
.historyList{
	width: 100%;
	border: 1px solid #6c6c6c;
	margin-bottom: 18px;
}
.historyList.noData {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.historyListTopBox{
	width: 100%;
	font-size: 13px;
	height: 35px;
	padding: 0 10px;
	line-height: 35px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: left;
}
.hr{
	width: 0;
	height: 16px;
	border-right: 1px solid #6c6c6c;
	margin: 0 10px;
}
.historyList ::v-deep(table) {
	width: 98%;
	margin: 0 1%;
	text-align: center;
	font-size: 13px;
	border-top: 1px solid #6c6c6c;
}
.historyList ::v-deep(table) > tr:nth-child(1){
	height: 30px;
}
.historyList ::v-deep(table) > tr:nth-child(2) td{
	padding: 0 4px 3px 4px;
}
.winColor{
	color: #2be000;
}
.accentColor{
	color: #5ccfff;
}
.accentColor:lang(en){
	white-space: nowrap;
	font-size: 12px;
}
.loseColor{
	color: #ff121a;
}
</style>