<template>
    <div class="modalBg detl">
        <div class="modalLog">
            <div class="dealerWrap">
                <img v-bind:src="selectedDealer['img']"/>
            </div>
            <div class="rightWrap">
                <div
                    class="closeBtn"
                    v-on:click.stop="$emit('hidePreviewModal')">
                    <img src="../../../assets/mobile/btn-close.png">
                </div>
                <div class="dealerSelect">
                    <div
                        v-for="(thum, idx) in selectedThums"
                        v-bind:key="idx"
                        v-on:click.stop="$emit('changeThum', thum)"
                        v-bind:class="{ 'selected': thum['group'] == selectedDealer['comment'] && selectThumType == thum['type'] }">
                        <img v-bind:src="thum['src']"/>
                    </div>
                </div>
                <div
                    class="buyBtn"
                    v-bind:class="[{ 'disabled': checkDealer(selectedDealer, true)['isBuy'] },checkDealer(selectedDealer)['key']]">
                    <button
                        v-bind:class="checkDealer(selectedDealer, true)['key']"
                        v-on:click.stop="$emit('showConfirmModal', selectedDealer, true)">{{ $t(checkDealer(selectedDealer, true)['key']) }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    export default defineComponent({
        props: ['selectedDealer', 'selectedThums', 'selectThumType', 'myDealers', 'equipDealer'],
        methods: {
            // 구매한 딜러인지체크
            checkDealer(dealer, isPreview){
                let obj = {
                    'isBuy': false,
                    'period': undefined,
                    'key': 'buy'
                }
                if(this.myDealers?.[dealer['index']]){
                    obj['isBuy'] = true;
                    obj['period'] = this.myDealers[dealer['index']]['expiryTime'] ? `${this.$t('period')}: ${this.myDealers[dealer['index']]['expiryTime']} ${this.$t('days')}` : `${this.$t('period')}: 30 ${this.$t('days')}`;
                }
                if(dealer['comment'] == 'lisa'){
                    obj['isBuy'] = true;
                    obj['period'] = 'unlimit';
                }
                if(obj['isBuy']) {
                    if (isPreview) {
                        obj['key'] = isPreview && this.equipDealer['color_type'] == dealer['type'] && this.equipDealer['buy_index'] == dealer['index'] ? 'unequip' : 'equip';
                    } else {
                        obj['key'] = this.equipDealer['buy_index'] == dealer['index'] ? 'unequip' : 'equip';
                    }
                }
                return obj;
            }
        }
    });
</script>
<style scoped>
	img {
		max-width: unset;
		height: auto;
	}
	.modalBg.detl{
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		z-index: 30;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.3);
	}

	.modalLog{
		position: relative;
		width: 95vw;
		height: 390px;
		background-color: #3b3b3b;
		border-radius: 10px;
		border: 1px solid #bbb;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 12px 12px 0;
	}

	.modalLog .dealerWrap{
		display: flex;
		width: 100%;
        justify-content: center;
        align-items: center;
        padding-left: 5%;
	}
    .dealerWrap > img{
        height: 370px;
    }
	.rightWrap{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: space-between;
	}
	.rightWrap .dealerSelect{
		display: flex;
		flex-direction: column;
		gap: 18px;
	}
	.rightWrap .dealerSelect > div{
		width: 58px;
		height: 53px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.rightWrap .dealerSelect .selected{
		background-image: url("../../../assets/mobile/select.png");
		background-size: cover;
		background-repeat: no-repeat;
	}
	.modalBg.detl .rightWrap .buyBtn{
		border: 1px solid #e1e1e1;
		background-image: linear-gradient(to bottom, #6696f6, #2352af);
		width: 75px;
		height: 35px;
		margin-bottom: 12px;
		text-align: center;
	}

    /* button style (purple) */
    .rightWrap .buyBtn.unequip {
        border: 1px solid #e1e1e1;
        background-image: linear-gradient(to bottom, #b742e8, #6223af) !important;
        width: 75px;
        height: 35px;
        margin-bottom: 12px;
        text-align: center;
    }

    /* button style (blue) */
    .rightWrap .buyBtn.equip {
        border: 1px solid #e1e1e1;
		background-image: linear-gradient(to bottom, #5bd43b, #0a770c) !important;
        width: 75px;
        height: 35px;
        margin-bottom: 12px;
        text-align: center;
    }

	.rightWrap .buyBtn button{
		font-size: 17px;
		line-height: 35px;
		color: #f1f1f1;
		font-weight: 300;
	}
	.closeBtn > img{
		width: 28px;
	}
	.dealerSelect img{
		width: 53px;
	}
</style>