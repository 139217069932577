export default {
    getStream: {
        version: "1.52",
        listenMessage: !1,
        listenFullscreen: !1,
        currentScript: document.currentScript,
        attachPlayer: async function (a, b, c, d, e) {
            let craffStream = this;
            if (this.websiteHost = "https://" + b + ".craffstream.com/player/index.html", this.listenMessage || (this.listenMessage = !0, window.addEventListener("message", function (a) {
                if ("getOptions" === a.data) {
                    craffStream.wn.postMessage({
                        action: "getOptions",
                        account: b,
                        contentId: c,
                        env: null,
                        alarm: "https://d3mgbr7exrskcr.cloudfront.net/v1/alarm",
                        options: d,
                        cognito: "https://d3mgbr7exrskcr.cloudfront.net/v1/cognito",
                        firehostDataStream: "craffstream"
                    }, "*")
                }
            })), this.playerid = a, this.playerListener = e, this.listenFullscreen || (this.listenFullscreen = !0, document.addEventListener("fullscreenchange", () => {
                document.fullscreenElement ? document.fullscreenElement.id === this.playerid && craffStream.wn.postMessage({embed: {fullscreen: "entered"}}, "*") : craffStream.wn.postMessage({embed: {fullscreen: "exit"}}, "*")
            })), "" === c) ; else {
                let b = `${this.websiteHost}`;
                document.getElementById(a).innerHTML = "<style>#" + a + " > *::-webkit-scrollbar {display: none;}</style><iframe id=\"" + a + "_iframe\" src=\"" + b + "\" title=\"Craffstream\" style=\"border:none;width: 100%;height: 100%; visibility: hidden;\" onload=\"this.style.visibility='visible';\" allow=\"autoplay; fullscreen; encrypted-media *\" allowtransparency=\"true\"></iframe>", craffStream.wn = document.getElementById(a + "_iframe").contentWindow
            }
        },
        playerChecking: function () {
            return new Promise(a => {
                setTimeout(() => {
                    a(!0)
                }, 500)
            })
        },
    }
}