<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>Member List</h1>
            <span
                class="exitImg"
                v-on:click.stop="$emit('showModal', '')">
                <img
                    src="@/assets/mobile/btn-close.png"
                    alt="close">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="memberListWrap">
                <ul>
                    <li>{{ $t('country') }}</li>
                    <li>{{ $t('id') }}</li>
                    <li>{{ $t('grade') }}</li>
                    <li>{{ $t('level') }}</li>
                </ul>
                <div class="memberListInner">
                    <div
                        class="columnListBox"
                        v-for="(user, idx) in enterRoomUsers['list']"
                        v-bind:key="idx">
                        <span class="country">
                            <img v-bind:src="user['country']"/>
                        </span>
                        <span>{{ user['userId'] }}</span>
                        <span>
                        </span>
                        <span>
                            <img v-bind:src="user['grade']"/>
                        </span>
                        <span>{{ user['level'] }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted } from "vue";
    import { enterRoomUsers, setEnterRoomUsers } from "@/js/user/enterUsers";
    import { getGradeImage, getFlagImage } from "@/js/common";
    import { useRoute } from "vue-router";
    const route = useRoute();

    onMounted(() => {
        setEnterRoomUsers(route.params.roomNo);
    });
</script>
<style scoped>
	.sideMenuBox{
		width: 100%;
		height: auto;
		padding-bottom: 30%;
		color: #fff;
		font-size: 14px;
		background-color: #2e3033;
	}
	.sideMenuTopBox{
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: #2e3033;
		padding: 3%;
	}
	.sideMenuTopBox > h1 {
		font-size: 20px;
	}
	.sideMenuInner{
		padding: 0 3%;
		background-color: #2e3033;
		height: 100%;
	}
	.exitImg{
		position: absolute;
		right: 0;
		margin-right: 4%;
		height: 28px;
	}
	.exitImg img{
		width: 28px;
		height: auto;
	}

	/*내용*/
	.memberListWrap{
		width: 100%;
		height: 100vh;
		padding: 0 4px;
		background-color: #1b1b1b;
		overflow-y: scroll;
	}
	.memberListWrap ul{
		width: 96%;
		border-bottom: 1px solid #484848;
		color: #5ccfff;
		font-size: 15px;
		height: 39px;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: center;
		margin: 0 auto;
	}
	.memberListWrap ul li{
		flex: 1;
	}
	.memberListWrap ul li:nth-child(2){
		flex: 1.5;
	}
	.memberListWrap ul li:nth-child(4){
		flex: .5;
	}
	.columnListBox{
		width: 100%;
		border: 1px solid #484848;
		display: flex;
		align-items: center;
		text-align: center;
		margin-top: 17px;
		justify-content: space-around;
		padding-right: 10px;
		height: 55px;
	}

	.country{
		flex: 1.1;
	}
	.columnListBox > span:nth-child(2){
		flex: 1.4;
	}
	.columnListBox > span:nth-child(3){
		flex: .3;
	}
	.columnListBox > span:nth-child(4){
		flex: 1.2;
	}
	.columnListBox > span:nth-child(5){
		flex: .3;
	}
	.columnListBox img{
		width: 28px;
		vertical-align: middle;
	}

	.country > img{
		width: 23px;
	}
</style>