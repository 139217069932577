<template>
    <div class="memberListWrap">
        <ul>
            <li>{{ $t('country') }}</li>
            <li>{{ $t('id') }}</li>
            <li>{{ $t('grade') }}</li>
            <li>{{ $t('level') }}</li>
        </ul>
        <div class="memberListInner">
            <div
                class="columnListBox"
                v-for="(user, idx) in enterRoomUsers['list']"
                v-bind:key="idx">
                <span class="country">
                    <img v-bind:src="user['country']"/>
                </span>
                <span>{{ user['userId'] }}</span>
                <span>
                    <!--                    <img src="@/assets/icons/glasses.png">-->
                </span>
                <span>
                    <img v-bind:src="user['grade']"/>
                </span>
                <span>{{ user['level'] }}</span>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted } from "vue";
    import { enterRoomUsers, setEnterRoomUsers } from "@/js/user/enterUsers";
    import { getGradeImage, getFlagImage } from "@/js/common";
    import { useRoute } from "vue-router";
    const route = useRoute();

    onMounted(() => {
        setEnterRoomUsers(route.params.roomNo);
    });
</script>
<style scoped>
    .memberListWrap{
        width: 100%;
        padding: 12px 4px;
        background-color: #1b1b1b;
        height: 99%;
        overflow-y: auto;
    }
    .memberListWrap ul{
        width: 100%;
        border-bottom: 1px solid #484848;
        color: #5ccfff;
        font-size: 22px;
        height: 39px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        margin: 0 auto;
    }
    .memberListWrap ul li{
        flex: 1;
    }
    .memberListWrap ul li:nth-child(2){
        flex: 1.5;
    }
    .memberListWrap ul li:nth-child(4){
        flex: .5;
    }
    .memberListInner{
        color: #fff;
    }
    .columnListBox{
        width: 100%;
        border: 1px solid #484848;
        display: flex;
        align-items: center;
        text-align: center;
        margin-top: 17px;
        justify-content: space-around;
        padding-right: 10px;
        height: 55px;
        font-size: 20px;
    }

    .country{
        flex: 1.1;
    }
    .columnListBox > span:nth-child(2){
        flex: 1.4;
    }
    .columnListBox > span:nth-child(3){
        flex: .3;
    }
    .columnListBox > span:nth-child(4){
        flex: 1.2;
    }
    .columnListBox > span:nth-child(5){
        flex: .3;
    }
    .columnListBox img{
        width: 28px;
        vertical-align: middle;
        cursor: pointer;
    }

    .country > img{
        width: 23px;
    }
</style>