<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('history') }}</h1>
            <span class="exitImg">
                <img
                    src="../../assets/mobile/btn-close.png"
                    alt="close"
                    v-on:click.stop="$emit('closeModal')">
            </span>
        </div>
        <div
            class="sideMenuInner"
            v-if="!isShowHistoryDetail">
            <div class="historyWrap">
                <div
                    class="historyList noData"
                    v-if="!loading['modal'] && historyList.length == 0">
                    {{ $t('noData')}}
                </div>
                <div
                    class="historyList"
                    v-for="(history, idx) in historyList"
                    v-bind:key="idx"
                    v-on:click.stop="showHistoryDetail(history)">
                    <ul class="historyListTopBox">
                        <li class="accentColor">
                            {{ $t('gameId') }}
                        </li>
                        <hr class="hr">
                        <li>{{ history['gameId'] }}</li>
                    </ul>
                    <table
                        cellspacing="0"
                        cellpadding="0">
                        <tr>
                            <td class="accentColor">
                                {{ $t('date') }}
                            </td>
                            <td class="accentColor">
                                {{ $t('bet') }}
                            </td>
                            <td>
                                <span class="winColor">
                                    {{ $t('win2') }}
                                </span>
                                <span class="accentColor">
                                    &#47;
                                </span>
                                <span class="loseColor">
                                    {{ $t('loss') }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ history['date'] }}</td>
                            <td v-numberFormat="history['bet']"></td>
                            <td
                                v-bind:class="{
                                    'loseColor': String(history['winLoss']).indexOf('-') > -1,
                                    'winColor': String(history['winLoss']).indexOf('-') === -1
                                }"
                                v-numberFormat="history['winLoss']"></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <ModalHistoryDetl
        v-on:goPage="goPage"
        v-if="isShowHistoryDetail"
        v-bind:selectedHistory="selectedHistory"
        v-bind:results="results"/>
</template>
<script>
    import { defineComponent } from "vue";
    import ModalHistoryDetl from "@/components/popup/ModalHistoryDetl";
    import { loading, sabong } from "@/js/reactive";
    import { CARD_INFO_ARRAY, CARD_INFO_ARRAY_VALUE  } from "@/js/const";
    import { pccApis } from "@/repository/repository";

    export default defineComponent({
        components: {
            ModalHistoryDetl
        },
        data(){
            return {
                sabong: sabong,
                loading: loading,
                // 현재 히스토리 페이지
                curPage: 1,
                // 히스토리 선택박스 pageCount
                totalCnt: 10,
                CARD_INFO_ARRAY: CARD_INFO_ARRAY,
                historyList: [],
                selectedHistory: {},
                isShowHistoryDetail: false,
                results: {
                    'cardResult': {
                        'player': {
                            key: 'player',
                            cards: [],
                            sum: 0
                        },
                        'banker': {
                            key: 'banker',
                            cards: [],
                            sum: 0
                        }
                    },
                    'result': {
                        'p': {
                            key: 'p',
                            bet: 0,
                            rst: 0
                        },
                        'pp': {
                            key: 'pp',
                            bet: 0,
                            rst: 0
                        },
                        'tie': {
                            key: 'tie',
                            bet: 0,
                            rst: 0
                        },
                        'b': {
                            key: 'b',
                            bet: 0,
                            rst: 0
                        },
                        'bp': {
                            key: 'bp',
                            bet: 0,
                            rst: 0
                        }
                    }
                }
            }
        },
        beforeMount() {
            if(process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong'){
            } else {
                this.getBaccaratHistory();
            }
        },
        methods: {
            initHistoryDetl() {
                this.selectedHistory['bet'] = 0;
                this.selectedHistory['winLoss'] = 0;
                this.results['result']['p']['bet'] = 0;
                this.results['result']['p']['rst'] = 0;
                this.results['result']['pp']['bet'] = 0;
                this.results['result']['pp']['rst'] = 0;
                this.results['result']['tie']['bet'] = 0;
                this.results['result']['tie']['rst'] =  0;
                this.results['result']['b']['bet'] = 0;
                this.results['result']['b']['rst'] = 0;
                this.results['result']['bp']['bet'] = 0;
                this.results['result']['bp']['rst'] = 0;
            },
            getCardColor(rst, idx) {
                let last = idx == 2 ? 'last' : '';
                return rst - 1 <= 25 ? `black ${last}` : `red ${last}`
            },
            async goPage(flag){
                if(flag == 'list'){
                    this.isShowHistoryDetail  = false;
                    return;
                }
                if(flag === 'prev'){
                    this.selectedHistory = this.historyList[this.curHistoryIdx - 1 < 0 ? 0 : this.curHistoryIdx - 1];
                } else {
                    this.selectedHistory = this.historyList[this.curHistoryIdx + 1 === this.historyList.length ? this.curHistoryIdx : this.curHistoryIdx + 1];
                }
                await this.showHistoryDetail(this.selectedHistory);
            },
            async getBaccaratHistory() {
                this.historyList = [];
                const res = await this.apis.getHistory({'pages': this.curPage });
                if(res?.decrypt_data?.value.length > 0){
                    res['decrypt_data']['value'].forEach(data => {
                        this.historyList.push({
                            'gameId': data['identifying_number'],
                            'date': data['register_time'],
                            'bet': data['bet_amount_banker']
                                + data['bet_amount_banker_pair']
                                + data['bet_amount_player']
                                + data['bet_amount_player_pair']
                                + data['bet_amount_tie'],
                            'winLoss': data['increase_amount']
                        })
                    });
                }
            },
            async showHistoryDetail(selected){
                this.initHistoryDetl();
                this.curHistoryIdx = this.historyList.findIndex(history => history['gameId'] === selected['gameId']);
                const res = await this.apis.getHistoryDetl({ 'identifying_number': selected['gameId']});
                if(res?.decrypt_data?.value){
                    const data = res['decrypt_data']['value'];
                    this.selectedHistory = {
                        'bet': data['bet_amount_banker']
                            + data['bet_amount_banker_pair']
                            + data['bet_amount_player']
                            + data['bet_amount_player_pair']
                            + data['bet_amount_tie'],
                        'winLoss': data['increase_amount'],
						...data
                    };

                    this.results['cardResult']['player']['sum'] = 0;
                    this.results['cardResult']['player']['cards'] = [];
                    this.results['cardResult']['banker']['cards'] = [];
                    this.results['cardResult']['banker']['sum'] = 0;

                    data['player_card_list'].split(',').forEach((card, idx) => {
                        if(idx === data['player_card_list'].length - 1){
                            this.results['cardResult']['player']['cards'].unshift(card);
                        } else {
                            this.results['cardResult']['player']['cards'].push(card);
                        }
                        this.results['cardResult']['player']['sum'] += CARD_INFO_ARRAY_VALUE[card - 1];
                    });

                    this.results['cardResult']['banker']['cards'] = data['banker_card_list'].split(',');
                    data['banker_card_list'].split(',').forEach(card => {
                        this.results['cardResult']['banker']['sum'] += CARD_INFO_ARRAY_VALUE[card - 1];
                    });

                    // 10 자리 넘어가면 1의자리만 표시
                    this.results['cardResult']['player']['sum'] = this.results['cardResult']['player']['sum'] >= 10 ? String(this.results['cardResult']['player']['sum']).substr(1,1) : this.results['cardResult']['player']['sum'];
                    this.results['cardResult']['banker']['sum'] = this.results['cardResult']['banker']['sum'] >= 10 ? String(this.results['cardResult']['banker']['sum']).substr(1,1) : this.results['cardResult']['banker']['sum'];

                    this.results['result']['p']['bet'] = data['bet_amount_player'];
                    this.results['result']['p']['rst'] = data['win_amount_player'];
                    this.results['result']['pp']['bet'] = data['bet_amount_player_pair'];
                    this.results['result']['pp']['rst'] = data['win_amount_player_pair'];
                    this.results['result']['tie']['bet'] = data['bet_amount_tie'];
                    this.results['result']['tie']['rst'] =  data['win_amount_tie'];
                    this.results['result']['b']['bet'] = data['bet_amount_banker'];
                    this.results['result']['b']['rst'] = data['win_amount_banker'];
                    this.results['result']['bp']['bet'] = data['bet_amount_banker_pair'];
                    this.results['result']['bp']['rst'] = data['win_amount_banker_pair'];

                    this.isShowHistoryDetail = true;
                }
            },
        }
    });
</script>
<style scoped>
.sideMenuBox{
	width: 100%;
	height: auto;
	padding-bottom: 60%;
	color: #fff;
	font-size: 14px;
	background-color: #2e3033;
	position: fixed;
	top: 0;
	z-index: 30;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 18px;
}
.sideMenuInner{
	padding: 0 3%;
	background-color: #2e3033;
	height: 100%;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 4%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/* 내용 */
.historyWrap{
	width: 100%;
	padding: 12px 4px 20%;
	background-color: #1b1b1b;
	height: 750px;
	overflow-y: scroll;
    overflow-x: hidden;
}
.historyList{
	width: 100%;
	border: 1px solid #6c6c6c;
	margin-bottom: 18px;
}
.historyList.noData {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.historyListTopBox{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	height: 35px;
}
.hr{
	width: 0;
	height: 16px;
	border-right: 1px solid #6c6c6c;
	margin: 0 10px;
}
.historyList > table{
	width: 98%;
	margin: 0 1%;
	text-align: center;
	font-size: 13px;
	border-top: 1px solid #6c6c6c;
}
.historyList > table > tr:nth-child(1){
	height: 30px;
}
.historyList > table > tr:nth-child(2) td{
	padding: 0 4px 3px 4px;
}
.winColor{
	color: #2be000;
}
.accentColor{
	color: #5ccfff;
}
.accentColor:lang(en){
    white-space: nowrap;
    font-size: 12px;
}
.loseColor{
	color: #ff121a;
}
</style>