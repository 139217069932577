import { reactive } from 'vue';
import { refferralApis } from "@/repository/repository";
import { getCookie, encryptData, decryptData } from "../utils";
import { err } from '../reactive';


const userRefferalInfo = reactive({
    agent_code: undefined,
    currency_code: undefined,
    referral_code: undefined,
    referral_commission: undefined,
    referral_retained: undefined,
    user_id: undefined,
    uuid: undefined
})

const refferalMemberList = reactive({
    lists: []
})


// 레퍼럴 멤버 리스트 전체 혹은 개인의 레퍼럴 정보 조회
// params 는 true , default는 false
const getReferralMemberList = async (uuid = false) => {
    let params = {};
    if(uuid) params['uuid'] = getCookie('uuid');

    const res = await refferralApis.$fetch('/transaction/get/referral/member', params);
    if(!res.data) return err['msg'] = '“Failed to complete request';

    refferalMemberList['lists'] = res.data;

    return res.data
}


// 유저의 reffer 정보를 reactive에 암호화해서 저장해주는 함수
const getMyRefferal = async () => {
    let params = {};
    params['uuid'] = getCookie('uuid');

    const res = await refferralApis.$fetch('/transaction/get/referral/member', params);

    if(!res.data) return;
    if(typeof res.data !== 'object') return;

    
    Object.entries(res.data[0]).forEach(el => {
        userRefferalInfo[el[0]] = encryptData(el[1]);
    });
}

const requestSettlement = async () => {
    let params = {};
    params['uuid'] = userRefferalInfo['uuid'];
    params['amount'] = userRefferalInfo['referral_retained'];

    if(!params['amount']) return err['msg'] = 'Have nothing to settlement';

    const res = await refferralApis.$fetch('/transaction/set/referral/member/request', params);

    return res;
}

const searchMyRefferalMemeberList = () => {
    const userRefferalCode = getCookie('uuid');
    const array = [];
    console.log(refferalMemberList['lists'])
    refferalMemberList['lists'].filter(el => {
        console.log(el.refferal_code);
        if(userRefferalCode !== el.referral_code) return;
        // if(userRefferalCode == el.uuid) return;

        array.push(el);
    })

    return array;

}


export {
    userRefferalInfo,
    refferalMemberList,

    getReferralMemberList,
    getMyRefferal,
    requestSettlement,
    searchMyRefferalMemeberList
}