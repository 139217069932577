<template>
    <div class="rootWrap">
        <!--	theme-->
        <div
            v-if="modalKey === 'theme'"
            class="modalWrap3 theme">
            <div class="modalBody3 theme">
                <keep-alive>
                    <SkinTheme/>
                </keep-alive>
            </div>
        </div>

        <div
            v-if="modalKey !== 'cam' && modalKey !== 'sound' && modalKey !== 'theme' && modalKey !== 'exit'"
            v-bind:class="{ 'historyAnother' : modalKey === 'history' }"
            class="inGameModalWrap">
            <div class="modalHeader">
                <div class="title">
                    {{ $t(modalKey) }}
                </div>
                <div class="closeBtn menu">
                    <img
                        src="../../../assets/mobile/btn-close.png"
                        v-on:click="hideModal"/>
                </div>
            </div>
            <div class="modalBody">
                <Loading v-if="loading['modal']"/>
                <HistoryModal v-if="modalKey === 'history' && deployMode == 'live'"/>
                <SabongHistoryModal v-if="modalKey === 'history' && deployMode.indexOf('sabong') > -1"/>
                <Chat v-if="modalKey === 'chat'"/>
                <ChangeTable v-if="modalKey === 'change'"/>
                <Tutorial v-if="modalKey === 'tutorial'"/>
                <MemberList v-if="modalKey === 'memberList'"/>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { setCookie, getCookie } from "@/js/utils";
    import '@vueform/slider/themes/default.css';
    import HistoryModal from "@/components/popup/HistoryModal"
    import SabongHistoryModal from "@/components/popup/SabongHistoryModal";
    import Chat from "@/components/popup/Chat"
    import { loading } from "@/js/reactive"
    import ChangeTable from "@/components/popup/ChangeTable"
    import Loading from "@/components/common/Loading"
    import Tutorial from "@/components/popup/Tutorial"
    import MemberList from "@/components/popup/MemberList"
    import SkinTheme from "@/components/popup/SkinTheme";
    export default defineComponent({
        components: {
            SabongHistoryModal,
            HistoryModal,
            Chat,
            ChangeTable,
            Loading,
            Tutorial,
            MemberList,
            SkinTheme
        },
        data(){
            return {
                deployMode: process.env.VUE_APP_DEPLOY_GAME_TYPE,
                loading: loading,
                icons: {
                    'change': require('@/assets/icons/ingame_top_menu/btn-tchange.png'),
                    'history': require('@/assets/icons/ingame_top_menu/btn-history.png'),
                    'chat': require('@/assets/icons/ingame_top_menu/btn-chat.png'),
                    'cam': require('@/assets/icons/ingame_top_menu/btn-cam.png'),
                    'tutorial': require('@/assets/icons/ingame_top_menu/btn-tutorial.png'),
                    'sound': require('@/assets/icons/ingame_top_menu/btn-sound.png')
                }
            }
        },
        props: {
            modalKey: {
                type: String,
                default: () => {
                    return undefined;
                }
            }
        },
        watch: {
            modalKey: {
                deep: true,
                immediate: true,
                handler(val){
                    if(val == 'cam'){
                        if(getCookie('video') != '') {
                            this.selectedVideo = getCookie('video');
                        }
                    }
                }
            }
        },
        methods: {
            hideModal() {
                this.$emit('hideModal');
            }
        }
    })
</script>
<style scoped>
.rootWrap{
    position: inherit;
    right: 0;
	top: 65px;
	z-index: 20;
    width: 508px;
}
.inGameModalWrap {
	position: absolute;
	right: 0;
	z-index: 20;
	/* width: 508px; */
	height: auto;
	background-color: var(--modal-header-background-color);
	border-radius: 6px;
	border: 1px solid #bbb;
}
.inGameModalWrap .modalHeader {
	display: flex;
	flex-direction: row;
	font-size: 21px;
	align-items: center;
	justify-content: center;
	color: var(--modal-header-font-color);
	width: 100%;
	gap: 15px;
	height: 65px;
	line-height: 20px;
}
.inGameModalWrap .modalHeader .closeBtn {
	position: absolute;
	margin-right: 20px;
	right: 0;
}
.closeBtn > img{
	width: 32px;
}
.inGameModalWrap .modalHeader .icon {
	margin-left: 15px;
}
.inGameModalWrap .modalBody {
	/*background-color: #1b1b1b;*/
	width: 100%;
	height: 483px;
	background-size: 100% 100%;
	padding: 0 5px;
}

.modalWrap2.cam,
.modalWrap2.sound {
	position: absolute;
	z-index: 10;
	outline: 1px solid gray;
}
.modalWrap2.cam {
	right: 168px;
}
.modalWrap2.sound {
	right: 30px;
}
.modalBody2.cam {
	background-color: #1b1b1b;
	width: 196px;
	height: 130px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.modalBody2.sound {
	background-color: #1b1b1b;
	width: 360px;
	height: 254px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 5px;
	/*
	width: 434px;
	height: 425px;
	*/
}
.modalWrap3.theme {
    width: 123px;
    height: 80px;
    position: absolute;
    right: 0; 
}
.modalBody3.theme {
    width: 100%;
    height: 100%;
}
.arrow.cam {
	position: absolute;
	top: -15px;
	left: 69px;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 20px solid var(--modal-body-background-color);;
}
.arrow.sound {
	position: absolute;
	top: -15px;
	left: 251px;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 20px solid var(--modal-body-background-color);;
}

/* 웹용 아이콘 크기 */
.exitBtns > .btn img,
.modalWrap .icon img,
.btn img,
.sendBtn img,
.prevBtn img,
.nextBtn img{
	width: 41px;
	height: 41px;
}

/*211123 한유정 추가*/
.historyAnother{
    width: 556px !important;
    height: 556px !important;
}

.themeWrap {
    position: absolute;
    top: 0px;
}
</style>