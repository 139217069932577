<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('video') }}</h1>
            <span
                class="exitImg"
                v-on:click.stop="$emit('showModal', '')">
                <img
                    src="../../../assets/mobile/btn-close.png"
                    alt="close">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="qualityWrap">
                <div
                    v-for="(inter, key) in interfaces['cam']"
                    v-bind:key="key"
                    v-on:click.stop="inter['click'](inter['key'])"
                    v-bind:class="{ 'selectQuality': selectedVideo == inter['key'] }"
                    class="qualityChoiceBoxs">
                    <span>&#8226; {{ $t(inter['key']) }}</span>
                    <span>{{ inter['value'] }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { mapGetters } from "vuex";

    export default defineComponent({
        data() {
            return {
                selectedVideo: 'on',
                interfaces: {
                    cam: [
                        {
                            'key': 'on',
                            'value': '',
                            'click': (key) => {
                                this.selectedVideo = key;
                                this.offCam(false);
                            }
                        },
                        {
                            'key': 'off',
                            'value': '',
                            'click': () => {
                                this.selectedVideo = 'off';
                                this.offCam(true);
                            }
                        }
                    ]
                },
            }
        },
        computed: {
			...mapGetters(['getIsWebRTCOn'])
        },
        watch: {
            getIsWebRTCOn: {
                immediate: true,
                handler(isOn){
                    if(isOn){
                        this.interfaces['cam'][0]['click']();
                    } else {
                        this.interfaces['cam'][1]['click']();
                    }
                }
            }
        },
        methods: {
            changeVolume(inter){
                inter['onoff'] = inter['value'] > 0 ? 1 : 0;
            },
            offCam(isOnOff){
                this.$emit('offCam', isOnOff);
            }
        }
    })
</script>
<style scoped>
.sideMenuBox{
	position: absolute;
	right: 0;
	width: 75vw;
	color: #fff;
	font-size: 14px;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 20px;
}
.sideMenuInner{
	padding: 1.5%;
	background-color: #2e3033;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 3%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/* 내용 */
.qualityWrap{
	padding:0 9px;
}
.qualityChoiceBoxs{
	width: 100%;
	height: 68px;
	margin-bottom: 30px;
	padding: 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #6c6c6c;
}
.selectQuality{
	background-color: #1b1b1b;
	color: #5ccfff;
}
</style>