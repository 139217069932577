<template>
    <div
        class="introWrap"
        v-bind:class="{ 'mobile': isMobile() }">
        <div class="introLogo">
            <img
                v-bind:src="setup['logoSrc']"
                width="500">
            <div class="loadingBar">
                <div class="bar"></div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { reactive } from 'vue';
    import { useStore } from 'vuex';
    import { getCookie, isMobile } from "@/js/utils";
    import {LOGO} from "@/js/const";

    const store = useStore();

    const setup = reactive({
        text: LOGO?.[location.hostname]?.interRoomTitle || LOGO['vinusinter.com'].interRoomTitle,
        logoSrc: LOGO?.[location.hostname]?.introLogo || LOGO['vinusinter.com'].introLogo,
    })

</script>
<style scoped>
    .introWrap{
        width: 100vw;
        height: 100vh;
        background-color: #0E1124;
        z-index: 999999;
        display: flex;
		position: absolute;
		top: 0;
		left: 0;
        justify-content: center;
        align-items: center;
        animation: intro 1.2s both;
        animation-delay: 4s;
    }
    .introLogo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .introLogo img{
        animation-delay: 0.5s;
    }
    .introLogo p{
        font-family: "Times New Roman" !important;
        font-size: 40px;
        font-weight: 900;
        margin-top: 20px;
        background: linear-gradient(to bottom, #c1c1c1, #ebebeb, #c1c1c1);
        color: transparent;
        -webkit-background-clip: text;
        animation: introText 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation-delay: 0.5s;
    }

    .loadingBar{
        margin-top: 20px;
        width: 385px;
        height: 5px;
        background-color: #161A35;
        animation: introText 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation-delay: 0.5s;
    }

    .loadingBar div{
        width: 0%;
        height: inherit;
        background-color: #4AB5B4;
        animation: Bar 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation-delay: 2.5s;
    }

    @keyframes intro {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            z-index: 0;
        }
    }

    @keyframes introBar{
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes Bar{
        0% {
            width: 0%;
        }
        80% {
            width: 100%;
        }
        100% {
            width: 100%;
        }
    }

    .introWrap.mobile .introLogo img{
        width: 250px;
    }
    .introWrap.mobile p{
        font-size: 24px;
        margin-top: 10px;
    }
    .introWrap.mobile .loadingBar{
        width: 250px;
        margin-top: 10px;
    }

</style>