<template>
    <div class="betListWrap">
        <h2>Bet List</h2>
        <div
            class="betListInnerBox"
            v-for="(infos, idx) in props.roomBetList"
            v-bind:key="idx">
            <ul
                class="betListInner"
                v-for="(bet, key) in infos"
                v-bind:key="key">
                <div class="betListUserInfo">
                    <li
                        class="id"
                        v-if="key != 'user'">
                        {{ infos['user'] }}
                    </li>
                    <li v-if="key != 'user'">
                        <img v-bind:src="betMarks[key]">
                    </li>
                    <li
                        class="amount"
                        v-if="key != 'user'"
                        v-numberFormat="infos[key]">
                    </li>
                </div>
            </ul>
        </div>
        <div class="betListBtmTxt">
            <h3>Closes after 08 seconds</h3>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, onMounted, onUnmounted, reactive } from "vue";

    const props = defineProps({
        roomBetList: Object
    });

    const betMarks = reactive({
        'p': require('../../assets/icons/betMark/icon-p.png'),
        'pp': require('../../assets/icons/betMark/icon-p-p.png'),
        'tie': require('../../assets/icons/betMark/icon-t.png'),
        'b': require('../../assets/icons/betMark/icon-b.png'),
        'bp': require('../../assets/icons/betMark/icon-p-b.png')
    });

    onMounted(() => {
        console.log(props.roomBetList);
    });

</script>

<style scoped>
.betListWrap{
	width: 400px;
	color: #fff;
	border: 1px solid #bbb;
	background-color: rgba(0,0,0,.8);
	border-radius: 6px;
	height: 345px;
	position: relative;
	overflow-y: hidden;
}
.betListWrap h2{
	text-align: center;
	margin: 10px 0;
}
.betListInnerBox{
	overflow-y: auto;
}
.betListBtmTxt{
	position: absolute;
	width: 100%;
	height: 35px;
	line-height: 30px;
	bottom: 0;
	margin: 0 auto;
}
.betListBtmTxt h3{
	font-size: 15px;
	text-align: center;
}
.betListInner{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 60px;
	margin: 10px 0;
}
.betListUserInfo{
	display: flex;
	flex-direction: row;
	gap: 5px;
	width: 100%;
}
.id {
	margin-left: 10px;
}
.amount {
	margin-left: auto;
	margin-right: 10px;
}
</style>