<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('tutorial') }}</h1>
            <span
                class="exitImg"
                v-on:click.stop="$emit('showModal', '')">
                <img
                    src="../../../assets/mobile/btn-close.png"
                    alt="close">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="tutorialWrap">
                <h2>{{ $t('tutorialTitle1') }}</h2>
                <ul>
                    <li>{{ $t('tutorial1') }}</li>
                    <li>{{ $t('tutorial2') }}</li>
                    <li>{{ $t('tutorial3') }}</li>
                </ul>
                <h2>{{ $t('tutorialTitle2') }}</h2>
                <ul>
                    <li>{{ $t('tutorial4') }}</li>
                    <li>{{ $t('tutorial5') }}</li>
                    <li>{{ $t('tutorial6') }}</li>
                </ul>
                <h2>{{ $t('tutorialTitle3') }}</h2>
                <ul>
                    <li>{{ $t('tutorial7') }}</li>
                    <li>{{ $t('tutorial8') }}</li>
                    <li>{{ $t('tutorial9') }}</li>
                    <li>{{ $t('tutorial10') }}</li>
                    <li>{{ $t('tutorial11') }}</li>
                    <li>{{ $t('tutorial12') }}</li>
                    <li>{{ $t('tutorial13') }}</li>
                    <li>{{ $t('tutorial14') }}</li>
                    <li>{{ $t('tutorial15') }}</li>
                    <li>{{ $t('tutorial16') }}</li>
                </ul>
                <h2></h2>
                <ul>
                    <li>{{ $t('tutorial17') }}</li>
                    <li>{{ $t('tutorial18') }}</li>
                    <li>{{ $t('tutorial19') }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>

</script>
<style scoped>
.sideMenuBox{
	width: 100%;
	height: auto;
	/*padding-bottom: 30%;*/
	color: #fff;
	font-size: 14px;
	background-color: #2e3033;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 18px;
}
.sideMenuInner{
	padding: 0 3%;
	background-color: #2e3033;
	height: 100%;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 4%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/* 내용 */
.tutorialWrap{
	width: 100%;
	height: 83.3vh;
	padding: 0 6px 60% 6px;
	background-color: #1b1b1b;
    margin-bottom: 10px;
}
</style>