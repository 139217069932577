export default {
  "baccarat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바카라"])},
  "teenpatti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["틴패티"])},
  "dragonTiger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["드래곤 타이거"])},
  "holdem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홀덤"])},
  "roulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["룰렛"])},
  "sicbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["식보"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출석"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상점"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임내역"])},
  "sabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사봉"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테이블 변경"])},
  "betLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배팅 제한정보"])},
  "lobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로비"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["채팅"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고화질"])},
  "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중화질"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저화질"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비디오"])},
  "fx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["효과음"])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배경음악"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭크"])},
  "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소리 켜기/끄기"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어팩"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나가기"])},
  "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도움말"])},
  "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P PAIR"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER"])},
  "tie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIE"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BANKER"])},
  "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B PAIR"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER"])},
  "banker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BANKER"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잔고"])},
  "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배팅"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["준비"])},
  "betReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배팅 시작"])},
  "betEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배팅 종료"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음라운드가 시작되기 전까지 기다려주세요."])},
  "noGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Game List."])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영어"])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한국어"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일본어"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["힌디어"])},
  "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중국어(간체)"])},
  "vt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["베트남어"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태국어"])},
  "sp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페인어"])},
  "would": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 하시겠습니까?"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기간"])},
  "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무제한"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순위"])},
  "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에이전트"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금"])},
  "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배팅"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지불"])},
  "win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승리"])},
  "win2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["승리"])},
  "lose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["패배"])},
  "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["손실"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무승부"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유저 정보"])},
  "gameId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임ID"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜(UTC)"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결과"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음"])},
  "historyWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 최근데이터 100건만 조회 가능합니다."])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회 가능한 데이터가 없습니다."])},
  "noRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 활성화된 방이 없습니다."])},
  "chatInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지를 입력해주세요."])},
  "toList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트로 이동"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소"])},
  "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대"])},
  "checkMinMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배팅한도를 확인해주세요."])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["끄기"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["켜기"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로딩중"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테이블"])},
  "roundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라운드 ID"])},
  "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["라운드"])},
  "betCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배팅 취소"])},
  "betUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한단계 이전"])},
  "betRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이전회차 배팅설정"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["되돌리기"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잔고"])},
  "rebet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전 회차 배팅"])},
  "tutorialTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[게임방법]"])},
  "tutorialTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[플레이어 차례]"])},
  "tutorialTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[뱅커 차례]"])},
  "tutorial1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 카드는 숫자 1로 계산하고, 10, J, Q, K 은 0으로 계산합니다.\n나머지 카드는 각 카드의 소유 숫자로 계산합니다."])},
  "tutorial2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["두개 숫자의 합은 10의 단위는 제외하고 생각합니다.\n예를들어 5 + 8 = 13이 아니라 3이 됩니다."])},
  "tutorial3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본적으로 Player와 Banker는 카드를 2장씩 받습니다.\n이때 두 숫자의 합이 어느 한쪽이라도 8 또는 9가 된다면 Natural 조건으로 3번째 카드를 받지 않고 게임이 종료됩니다."])},
  "tutorial4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["양쪽 모두 Natural이 아닐 경우, 플레이어는 2장 카드의 합에따라 3번째 카드를 받을지 말지 결정합니다."])},
  "tutorial5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어 숫자의 합이 6 또는 7일 경우 : 카드를 받지 않음 (Stand)"])},
  "tutorial6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어 숫자의 합이 0~5일 경우 : 3번째 카드를 받음"])},
  "tutorial7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Player가 Stand일 경우"])},
  "tutorial8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어가 6또는 7이며, Banker도 6또는 7일 경우 : 카드를 받지 않음"])},
  "tutorial9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어가 6또는 7이며, Banker가 0~5일 경우 : 3번째 카드를 받음"])},
  "tutorial10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player가 3번째 카드를 받을 경우"])},
  "tutorial11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어가 0~5이며, Banker가 0~2인 경우 : 3번째 카드를 받음"])},
  "tutorial12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어가 0~5이며, Banker가 3인 경우 : 3번째 카드를 받음 (플레이어 3번째 카드가 8일 경우 제외)"])},
  "tutorial13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어가 0~5이며, Banker가 4인 경우 : 3번째 카드를 받음 (플레이어 3번째 카드가 0,1,8,9일 경우 제외)"])},
  "tutorial14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어가 0~5이며, Banker가 5인 경우 : 3번째 카드를 받음 (플레이어 3번째 카드가 0,1,2,3,8,9일 경우 제외)"])},
  "tutorial15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어가 0~5이며, Banker가 6인 경우 : 3번째 카드를 받음 (플레이어 3번째 카드가 0,1,2,3,4,5,8,9일 경우 제외)"])},
  "tutorial16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ 플레이어가 0~5이며, Banker가 7인 경우 : 카드를 받지 않음 (Stand)"])},
  "tutorial17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 규칙에 따라 2장 혹은 3장의 카드 합 중 9에 가까운 쪽이 승리하게 됩니다."])},
  "tutorial18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테이블에 따라 조금 다르지만, 보통 승리한 쪽에 배팅금의 2배만큼 되돌려주고,\nTie로 승리할 경우 8배의 배팅금을 돌려줍니다."])},
  "tutorial19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banker가 승률이 높기 때문에 Banker로 승리했을 경우 하우스에 5%의 커미션을 지불합니다."])},
  "msgErr1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메시지가 너무 깁니다. (최대 길이 100자)"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["풀스크린"])},
  "cam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영상"])},
  "lobbyExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로비나가기"])},
  "equip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장착"])},
  "unequip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장착해제"])},
  "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보유 잔고가 부족합니다."])},
  "dealerSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["딜러 스킨"])},
  "cardSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 스킨"])},
  "dealerProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["딜러 프로필"])},
  "beInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용중"])},
  "dealerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["딜러정보"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메뉴"])},
  "myInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유저 정보"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 디바이스"])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 플랫폼"])},
  "lastIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 IP"])},
  "lastDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속 도메인"])},
  "loginTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 시간"])},
  "registerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입 날짜"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통화"])},
  "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유저 아이디"])},
  "agentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에이전트"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필"])},
  "dealerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["딜러 이름"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["좋아요"])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["팁"])},
  "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["플레이시간"])},
  "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원"])},
  "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["딜러"])},
  "meron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "wala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAW"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHITE"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홍"])},
  "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입장"])},
  "memberList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유저 목록"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가"])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등급"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨"])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변환"])},
  "deposit_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금"])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금"])},
  "depositBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전송"])},
  "transactionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입출금 내역"])},
  "depositWarningTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환전에 필요한 수수료는 변환되어질 포인트에서 차감되어집니다."])},
  "depositInfoTxt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사봉포인트(USD)를 이더리움 또는 BNB 로 변환 할 수 있습니다."])},
  "depositInfoTxt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["환산하고자 하는 값을 입력하고 전송 버튼을 누르면 교환요청 시점에 적합한 시세를 적용하여 사봉포인트로 전환됩니다."])},
  "walletCreateTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사봉 지갑의 코인은 지갑 전송을 통해 보유됩니다. 수량을 추가할 수 있습니다."])},
  "walletCreateTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비너스 사봉 지갑 만들기"])},
  "depositSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금 화폐"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["화폐"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["값"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GAS 비용"])},
  "copyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소 복사하기"])},
  "myWalletAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VINUS USD 지갑 주소"])},
  "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인덱스"])},
  "registerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 날짜"])},
  "requestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요청 상태"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 ID"])},
  "resultAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변환량"])},
  "withdrawalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금 수량"])},
  "fromTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변환 된 화폐"])},
  "transferAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변환시킬 수량"])},
  "toTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변환 할 화폐"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테마"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총합"])},
  "gameHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 진행내역"])},
  "fightNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임회차 "])},
  "betList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배팅 리스트"])},
  "gmt-mnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UTC/GMT+9"])},
  "gmt-ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UTC/GMT+5:30"])},
  "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반복"])},
  "betRooster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배팅항목"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑 연결"])},
  "walletHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑 내역"])},
  "cancelInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배당률 불균형으로 이번 경기가 캔슬처리 되었습니다."])},
  "cancelInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["게임 결과는"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입/출금"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어제"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일주일"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한달"])},
  "noBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잔액이 없습니다."])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타입"])},
  "bannerContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 게임은 Gaming의 가장 강력하고 가장 큰 콘텐츠 제품이며 현재 가장 인기있는 것으로 예상됩니다."])},
  "bannerContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필리핀 최고의 권위 있는 기업들과 협력하는 SABONG 콘텐츠는 매일 중단 없이 생중계됩니다."])},
  "bannerContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필리핀에서 가장 강렬하고 흥미진진한 게임인 SABONG을 경험할 수 있습니다."])},
  "sabongGuideHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 사봉 게임"])},
  "sabongGuide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필리핀 투계 경기를 타갈로그어로’ 사봉(Sabong)’이라고 합니다."])},
  "sabongGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필리핀 사람들이 가장 즐기는 전통 스포츠 중 하나입니다."])},
  "sabongGuide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["베팅은 ‘RED’와 ‘BLUE’중 선택 또는 두 곳 모두 베팅할 수 있습니다."])},
  "sabongGuide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["베팅 금액에 따라 배당률이 유동적으로 변동하며, 한쪽으로 베팅이 과도하게 모이면 경기가 취소될 수 있습니다."])},
  "sabongGuide5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sabongGuideHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. 베팅과 게임결과"])},
  "sabongGuide6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["베팅은 “Bet Open”이 되면 시작되고 LAST Call이 출력된 후 30초 동안만 가능합니다. "])},
  "sabongGuide7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["베팅 시간이 종료되면 베팅할 수 없습니다."])},
  "sabongGuide8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["베팅이 종료되면 경기가 진행되며 결과에 따라 배당률에 맞는 금액을 우승 상금으로 지급받거나 잃게 됩니다."])},
  "sabongGuide9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sabongGuideHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. 무효게임"])},
  "sabongGuide10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무승부 게임"])},
  "sabongGuide11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경기 시작 후 10분까지 승패가 결정되지 않을 때"])},
  "sabongGuide12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["두 마리의 닭이 모두 죽어 심판이 게임 진행 불가능을 결정할 때"])},
  "sabongGuide13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 게임"])},
  "sabongGuide14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경기를 진행하는 닭의 상태가 좋지 않아 게임을 포기한 경우"])},
  "sabongGuide15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["베팅의 불균형으로 배당률이 x1.20을 넘기지 못할 경우"])},
  "sabongGuide16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위와 같은 상황에서는 배팅금액이 반환되고 무효게임 처리됩니다."])}
}