import { createStore, createLogger } from "vuex";
import createPersistedState from "vuex-persistedstate";
import modal from "@/store/modules/modal";
import login from "@/store/modules/login";
import betOdd from "@/store/modules/betOdd";

const debug = process.env.NODE_ENV !== 'production';

import theme from './theme'

export default createStore({
    state: {
        'bgmOn': 0,
        'effectOn': 1,
        'videoOn': 1,
        'showMenu': false,
        'selectChip': {},
        'totalBet': 0,
        'betDetail': {},
        'curSelectBoard': [],
        'prevBet': {},
        'betErrMsg': '',
        // webRTCOn connect on/off
        'isWebRTCOn': true,
        'realWins': 0,
        // 배팅중 상태
        'isBetting': false,
    },
    getters: {
        getIsBetting: (state) => {
            return state.isBetting;
        },
        getRealWins: (state) => {
            return state.realWins;
        },
        getIsWebRTCOn: (state) => {
            return state.isWebRTCOn;
        },
        getVideoOn: (state) => {
            return state.videoOn;
        },
        getBetErrMsg: (state) => {
            return state.betErrMsg;
        },
        getBgmOn: (state) => {
            return state.bgmOn;
        },
        getEffectOn: (state) => {
            return state.effectOn;
        },
        getShowMenu: (state) => {
            return state.showMenu;
        },
        getSelectChip: (state) => {
            return state.selectChip;
        },
        getTotalBet: (state) => {
            return state.totalBet
        },
        getBetDetail: (state) => {
            return state.betDetail
        },
        getCurSelectBoard: (state) => {
            return state.curSelectBoard
        },
    },
    mutations: {
        setIsBetting: (state, data) => {
            state.isBetting = data;
        },
        setRealWins: (state, data) => {
            state.realWins = data;
        },
        setIsWebRTCOn: (state, data) => {
            state.isWebRTCOn = data;
        },
        setVideoOn: (state, data) => {
            state.videoOn = data;
        },
        setBetErrMsg: (state, data) => {
            state.betErrMsg = data;
        },
        setBgmOn: (state, data) => {
            state.bgmOn = data;
        },
        setEffectOn: (state, data) => {
            state.effectOn = data;
        },
        setSelectChip: (state, data) => {
            state.selectChip = data;
        },
        setShowMenu: (state, data) => {
            state.showMenu = data;
        },
        setTotalBet: (state, data) => {
            state.totalBet = data;
        },
        setBetDetail: (state, data) => {
            state.betDetail = data;
        },
        setCurSelectBoard: (state, data) => {
            state.curSelectBoard = data;
        },
    },
    actions: {},
    modules: {
        modal,
        login,
        betOdd,
        theme
    },
    strict: debug,
    plugins: debug ? [createPersistedState()] : [createPersistedState()]
});
