<template>
    <div class="userInfo">
        <div class="balance">
            <div class="infoTitle">
                {{ $t('balance') }}
            </div>
            <div
                class="count"
                v-number-format="(loginUser['balance'] - getTotalBet)"></div>
        </div>
        <div class="tableBet">
            <div class="currency">
                <img v-bind:src="getSymbolImage(CURRENCY_SYMBOLS[loginUser['currency']])">
            </div>
            <div class="betAmount">
                <span v-number-format="betMinMax['p']['min']"></span>
                -
                <span v-number-format="betMinMax['p']['max']"></span>
            </div>
        </div>
        <div class="bet">
            <div class="infoTitle">
                {{ $t('bet') }}
            </div>
            <div
                class="count"
                v-number-format="getTotalBet"></div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { mapGetters } from "vuex";
    import { loginUser } from "@/js/reactive";
    import InGame from "@/components/InGame";

    import { CURRENCY_SYMBOLS } from "@/js/const";
    import { getSymbolImageFileName } from "@/js/common";

    export default defineComponent({
        mixins: [InGame],
        props: ['betMinMax'],
        data() {
            return {
                loginUser: loginUser,

                CURRENCY_SYMBOLS: CURRENCY_SYMBOLS,
            }
        },
        computed: {
			...mapGetters(['getTotalBet']),
        },
        watch: {
        },
        methods:{
            getSymbolImage(symbol){
                let fileName = getSymbolImageFileName(symbol);
                return require(`@/assets/symbol/${fileName}`)
            }
        }
    });
</script>
<style scoped>
    .userInfo {
        display: flex;
        align-items: center;
        width: 100%;
        height: 22px !important;
        min-height: 22px;
		background-color: black;
        font-size: 10px;
    }
    .userInfo .balance {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 37%;
        height: 100%;
    }
    .userInfo .tableBet {
        display: flex;
        width: 28%;
        height: 100%;
        gap: 2%;
        align-items: center;
        justify-content: center;
        background-color: #282e5a;
        color: #ffd52d;
		font-size: 8px;
		white-space: nowrap;
    }
    .userInfo .bet {
        display: flex;
        width: 37%;
        height: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .infoTitle {
        font-weight: normal;
        color: white;
        margin-left: 4%;
    }
    .count {
        font-weight: normal;
        color: #01c787;
        margin-right: 4%;
    }

    .currency > img{
        height: 20px;
        max-width: unset;
    }
</style>
