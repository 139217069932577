import { computed, reactive, ref } from "vue";
import { sabongInter, sabongPcc, sabongOne, sabongWsa, sabongNca, loginUser, err, sabongChips, chips } from "@/js/reactive";
import { sabongApis, interApis } from "@/repository/repository";
import router from "@/router";
import { setCookie, getCookie, decryptData, deleteCookie, getMoneySymbol, checkNaN_Infinity, addKey } from "@/js/utils";
import { SABONG_GAME_TYPE_IDX, SABONG_GAME_TYPE } from '@/js/const';
import store from "@/store";

export const isShowBtnInterface = ref(false);
export const isApiCalling = ref(false);
export const sabongInfo = reactive({
    'rooms': {},
    'history': [],
    'prevEventId': undefined
});

/**
 * @name: Data 초기화
 */
export const initData = () => {
    userBets['meron'] = 0;
    userBets['wala'] = 0;
    userBets['draw'] = 0;
    userBets['total'] = 0;
    userBets['isBetConfirm'] = false;
    isShowBtnInterface.value = false;
    betUsers['list'] = {};
}

/**
 * @name: Data 초기화 (ProTheme auto 기능 추가)
 */
export const initData2 = (autoMeron, autoWala) => {
    userBets['meron'] = autoMeron === true ? userBets['meron'] : 0; 
    userBets['wala'] = autoWala === true ? userBets['wala'] : 0; 
    userBets['draw'] = 0;
    userBets['total'] = userBets['meron'] + userBets['wala'];
    userBets['isBetConfirm'] = false;
    isShowBtnInterface.value = userBets['total'] ? true : false;
    betUsers['list'] = {};
}

/**
 * @name: 인게임에서 배팅항목 배팅한 돈
 */
export const userBets = reactive({
    'meron': 0,
    'wala': 0,
    'draw': 0,
    'total': 0,
    'balance': loginUser['balance'],
    'isBetConfirm': false
});

/**
 * @name: 현재 배팅한 유저정보들
 * @param: 
 */
export const betUsers = reactive({
    'list': {},
    'totalBetList' : []
});

/**
 * @name: 사봉 로비에서 방 상태값 표시
 * @param: room Object 방 정보
 */

export const getVinusSabongRoomStatus = (room) => {
    let status =  room?.['fight_status'] || 'close'; // open /close
    let eventType = room?.['event_type'] || 'close'; // Active / close
    if(status == 'close' && eventType == 'Active' && getCookie(`isBetOk${room['game']}`)){
        deleteCookie(`isBetOk${room['game']}`);
    }
    return {
        'eventType': eventType,
        'tx': status,
        'color': status == 'open' ? 'green' : status == 'lastCall' ? 'orange' : 'gray'
    };
}

/**
 * @name: 사봉 현재 라운드 숫자 표시
 * @param: room Object 방 정보
 */

export const getVinusSabongFightNo = (room) => {
    return room?.['fight_no'] || '-';
}

/**
 * @name: 사봉 방/통화코드별 칩 정보 세팅
 * @param: gameType String 게임타입 pcc/international/wpa
 */
export const setCurrencyChip = (gameType, skinType) => {
    if(sabongChips[gameType]){
        let data = sabongChips[gameType].filter(data => data['code'] == getCookie('currency'));
        let cmsChipInfo = data.filter(data => data['name'].indexOf('Chip') > -1);
        cmsChipInfo.forEach(cmsChip => {
            let findChip = chips[skinType].find(chip => chip['cmsKey'] == cmsChip['name']);
            if(findChip){
                findChip['value'] = Number(cmsChip['value']);
                findChip['tx'] = getMoneySymbol(cmsChip['value']);
            }
        });
    }
}

/**
 * @name: 현재 활성화된 경기정보(방정보) 가져오기
 */

export const getSabongEvents = async () => {
    //console.log('call getSabongEvents');

    let res = await interApis.getEvents();
    sabongInter['rooms'] = res?.['active']?.international ? res?.['active']?.international : {};
    sabongPcc['rooms'] = res?.['active']?.pcc ? res?.['active']?.pcc : {};
    sabongOne['rooms'] = res?.['active']?.oneBet ? res?.['active']?.oneBet : {};
    sabongWsa['rooms'] = res?.['active']?.wsa ? res?.['active']?.wsa : {};
    sabongNca['rooms'] = res?.['active']?.nca ? res?.['active']?.nca : {};

    return {
        'pcc': sabongPcc['rooms'],
        'inter': sabongInter['rooms'],
        'one': sabongOne['rooms'],
        'wsa': sabongWsa['rooms'],
        'nca': sabongNca['rooms'],
    };
}

/**
 * @name: 배팅한 데이터 삭제
 */
export const clearBetData = (autoMeron, autoWala) => {
    userBets['meron'] = autoMeron === true ? userBets['meron'] : 0; 
    userBets['wala'] = autoWala === true ? userBets['wala'] : 0; 
    userBets['draw'] = 0;
    userBets['total'] = userBets['meron'] + userBets['wala'];
    isShowBtnInterface.value = userBets['total'] ? true : false;
}
/**
 * @name: 비너스사봉 배팅
 * @param: 칩 정보 Object
 * @param: 배팅항목 메론,왈라,무승부 String
 */

export const bet = async (chip, betKey, isBetConfirm, autoMeron, autoWala) => {

    if(getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] == 'close'
        || !isBetConfirm && Object.keys(chip['chip']).length == 0
        // || !isBetConfirm && userBets['total'] + chip['chip']['value'] > loginUser['balance']
        || getCookie('isTh') && getCookie(`isBetOk${sabongInfo['rooms']['game']}`) == sabongInfo['rooms']['game']){
        return;
    }

    let minChip = sabongChips?.[SABONG_GAME_TYPE?.[sabongInfo['rooms']['game']]]?.filter(chip => chip['code'] == store.getters['login/getLoginUser'].currency && chip['name'] == 'Chip1');
    if(isBetConfirm){
        if(minChip && userBets['meron'] + userBets['wala'] + userBets['draw'] < minChip['value']){
            return;
        }
        if(userBets['meron'] + userBets['wala'] + userBets['draw'] <= 0){
            return;
        }
        isApiCalling.value = true;
        const res = await interApis.bet({
            event_id: sabongInfo['rooms']['event_id'],
            fight_no: sabongInfo['rooms']['fight_no'],
            bet_meron: userBets['meron'],
            bet_wala: userBets['wala'],
            bet_draw: userBets['draw'],
        });
        isApiCalling.value = false;
        if(res){
            setCookie(`isBetOk${sabongInfo['rooms']['game']}`, sabongInfo['rooms']['game']);
            clearBetData(autoMeron, autoWala);
            userBets['isBetConfirm'] = true;
        }
        if(Object.keys(sabongInfo['rooms']).length > 0 && getVinusSabongRoomStatus(sabongInfo['rooms'])['tx'] != 'close'){
            await getBetUsers();
        }
        return;
    }
    if(chip['chip'] && !isBetConfirm){
        //( meron || wala ) + chip[value] > 0  ( ProTheme 버튼에 따라 -,+ 값 전달받음 )
        if(userBets[betKey] >= 0 && userBets[betKey] + chip['chip']?.['value'] > 0) {
            // meron  + wala 배팅금액 > loginUser[balance]
            if(userBets['total'] + chip['chip']?.['value'] > loginUser['balance']) {
                userBets[betKey] = loginUser['balance'] - userBets[betKey == 'meron' ? 'wala' : 'meron'];
                userBets['total'] = loginUser['balance'];
            } else {
                // meron  + wala 배팅금액 <= loginUser[balance]
                userBets[betKey] += chip['chip']?.['value'] || 0;
                userBets['total'] += chip['chip']?.['value'] || 0;
            }
            isShowBtnInterface.value = true;
        } else {
            // ( meron || wala ) + chip[value] < 0
            userBets['total'] -= userBets[betKey];
            isShowBtnInterface.value = userBets['total'] == 0 ? false : true;
            userBets[betKey] = 0;
        }
    }
}

/**
 * @name: 경기 배당률 가져오기 (인게임) 
 */

export const getBetUsers = async () => {
    if(!sabongInfo['rooms']['event_id']){
        return;
    }
    const res = await interApis.getBetUsers({
        event_id: sabongInfo['rooms']['event_id'],
        fight_no: sabongInfo['rooms']['fight_no']
    });
    if(res){
        // 배당률 소수점 변경 표시
        res['bet_info']['meron_odd'] = checkNaN_Infinity(res['bet_info']['meron_odd'] / 100).toFixed(2);
        res['bet_info']['wala_odd'] = checkNaN_Infinity(res['bet_info']['wala_odd'] / 100).toFixed(2);
        betUsers['list'] = res;
        betUsers['totalBetList'] = [
            ...addKey(res['meron'] , {"rooster" : "meron"}),
            ...addKey(res['wala']  , {"rooster" : "wala"}),
        ].sort((a,b) => b.originAmount-a.originAmount)
    }
}

/**
 * @name: 경기 배팅률 가져오기 (로비)
 */

export const getBetUsersLobby = async (params) => {
    const oddList = {
        'meron_odd' : '-',
        'wala_odd' : '-',
        'roomName' : params.roomName
    }
    if(params['event_id'] && params['fight_no']){
        const res = await interApis.getBetUsers({
            event_id: params['event_id'],
            fight_no: params['fight_no']
        });
        if(res){
            // 배당률 소수점 변경 표시
            res['bet_info']['meron_odd'] = (checkNaN_Infinity(res['bet_info']['meron_odd']) / 100)/*.toFixed(2)*/;
            res['bet_info']['wala_odd'] = (checkNaN_Infinity(res['bet_info']['wala_odd']) / 100)/*.toFixed(2)*/;
            oddList['meron_odd'] = res['bet_info']['meron_odd'].toFixed(2);
            oddList['wala_odd'] = res['bet_info']['wala_odd'].toFixed(2);
        }
    }
    return oddList;
}

/**
 * @name: 로비 게임 히스토리 가져오기(중국점)
 */

export const getHistory = async () => {
    //await getSabongEvents();
    sabongPcc['history'] = sabongPcc['rooms']?.event_id ? await interApis.getGameResult(
        {'game' : sabongPcc['rooms']?.game,'event_id' : sabongPcc['rooms']?.event_id} ) : {'history': []} ;
    sabongInter['history'] = sabongInter['rooms']?.event_id ? await interApis.getGameResult(
        {'game' : sabongInter['rooms']?.game,'event_id' : sabongInter['rooms']?.event_id} ) : {'history': []};
    sabongOne['history'] = sabongOne['rooms']?.event_id ? await interApis.getGameResult(
        {'game' : sabongOne['rooms']?.game,'event_id' : sabongOne['rooms']?.event_id} ) : {'history': []};
    sabongWsa['history'] = sabongWsa['rooms']?.event_id ? await interApis.getGameResult(
        {'game' : sabongWsa['rooms']?.game,'event_id' : sabongWsa['rooms']?.event_id} ) : {'history': []};
    sabongNca['history'] = sabongNca['rooms']?.event_id ? await interApis.getGameResult(
        {'game' : sabongNca['rooms']?.game,'event_id' : sabongNca['rooms']?.event_id} ) : {'history': []};
}

/**
 * @name: 경기 게임결과 가져오기(중국점)
 * @param: gameType String 게임타입
 */

export const getGameResult = async (gameType, eventId) => {
    if(!eventId){
        return;
    }
    const res = await interApis.getGameResult({
        'game': SABONG_GAME_TYPE_IDX[gameType],
        'event_id': eventId
    });
    if(res?.history){
        sabongInfo['history'] = res.history
    }
}

/**
 * @name: 인게임내 경기 방 세팅
 */
export const generateSabongInfo = (gameType) => {
    const gameRooms = {
        'vinusPcc': sabongPcc['rooms'],
        'one': sabongOne['rooms'],
        'inter': sabongInter['rooms'],
        'wsa': sabongWsa['rooms'],
        'nca': sabongNca['rooms']
    }
    sabongInfo['rooms'] = gameRooms[gameType];
    return sabongInfo['rooms'];
}