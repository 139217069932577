<template>
    <div
        class="loginWrap"
        v-bind:class="{ 'mobile': isMobile() }"
        v-on:touchstart.stop="data.isShowHelpModal = false">
        <div class="loginBox">
            <div class="mobileLogoWrap">
                <img
                    v-bind:src="setup['logoSrc']"
                    alt="">
                <img
                    v-bind:src="setup['logoTextSrc']"
                    alt="">
            </div>
            <div class="inputWrap">
                <div
                    class="inputName">
                    <div class="inputBox">
                        <div class="input">
                            <input
                                maxlength="10"
                                autocapitalize="off"
                                autocomplete="no"
                                placeholder="First Name"
                                type="text"
                            />
                        </div>
                    </div>
                    <div class="inputBox">
                        <div class="input">
                            <input
                                maxlength="20"
                                autocapitalize="off"
                                autocomplete="no"
                                placeholder="Last Name"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
                <div class="inputBox">
                    <div class="inputIcon">
                        <img src="@/assets/sabong/icon/icon_user.png"/>
                    </div>
                    <div class="input">
                        <input
                            v-model="data.userId"
                            autocapitalize="off"
                            autocomplete="no"
                            autofocus
                            class="inputID"
                            placeholder="UserName"
                            type="text"
                        />
                    </div>
                </div>
                <div class="inputBox">
                    <div
                        class="eye inputIcon"
                        v-on:click.stop="data.togglePwd = !data.togglePwd">
                        <img src="@/assets/icons/icon_eye1.png"/>
                    </div>
                    <div class="input">
                        <input
                            v-model="data.pwd"
                            autocapitalize="off"
                            autocomplete="no"
                            autofocus
                            class="inputID"
                            placeholder="Password"
                            v-bind:type="data.togglePwd ? 'password' : 'text'"
                            v-on:keyup.enter="login(data.userId, data.pwd, undefined, ()=>{})"
                        />
                    </div>
                </div>
            </div>
            <div class="loginBtn">
                <button
                    v-on:click="login(data.userId, data.pwd, 'normalSignup', () => {})"
                    class="signupBtn"
                >Sign up
                </button>
                <!-- <button
                    class="connectWallet"
                    v-on:click.stop="$store.commit('modal/showModal', { modalKey: 'connectWallet', open: true, styles: { width: '350px'}})">
                    <img
                        src="@/assets/sabong/icon/icon_wallet.png"
                        alt="">
                    <p>{{ $t("connectWallet") }}</p>
                </button> -->
            </div>
        </div>
    </div>
</template>
<script setup>
    import { reactive } from "vue";
    import { login }           from "@/js/common";
    import { getCookie, isMobile } from "@/js/utils";
    import { useStore } from 'vuex';

    const store = useStore();

    const setup = reactive({
        logoSrc: store.getters['theme/getTheme'] ? require("@/assets/sabong/logo/logo_KGI.png") : require("@/assets/sabong/logo/sabongVS.png"),
        logoTextSrc: store.getters['theme/getTheme'] ? require("@/assets/sabong/logo/logo_text_KGI.png") : require("@/assets/sabong/logo/vinussabong.png"),
    })

    const data = reactive({
        userId: '',
        pwd: '',
        togglePwd: true
    });

    const openLoginModal = () => {
        store.commit('modal/showModal', { modalKey: store.getters['modal/getModalKey'], open: false});
        store.commit('modal/showModal', { modalKey: 'signin', open: true, styles: { width: '400px'}});
    }
</script>
<style scoped>
.horiInputWrap {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
	width: 100%;
}

.horiInputWrap .inputBox {
	max-width: 180px;
}

.disabled {
	cursor: not-allowed !important;
	opacity: 0.5;
}
*{
	font-family: 'Folks', sans-serif;
	letter-spacing: .3px;
}
.loginWrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: inherit;
	height: inherit;
	/*background-color: #1f2839;*/
	/*padding: 63px 36px 40px;*/
	padding: 52px 12px 50px;
}
.loginWrap .mobileLogoWrap{
	display: none;
}
.loginWrap h2{
	padding: 10px;
	border-left: 6px solid #5ccfff;
	font-size: 30px;
	font-weight: bold;
	color: #5ccfff;
	margin-bottom: 37px;
}

.loginWrap .inputName {
	display: flex;
	justify-content: space-between;
}
.loginWrap .inputName > div{
	max-width: 48%;
}

.loginBox {
	width: 100%;
	position: relative;
}
.closeBtn{
	position: absolute;
	right: -4px;
	top: -40px;
	cursor: pointer;
}

.loginBox .inputWrap .inputBox{
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	gap: 11px;
	height: 48px;
	background-color: #1b1b1b;
	padding: 0 16px;
	border-radius: 6px;
}
.loginBox .inputWrap > div {
	margin-bottom: 17px;
}

.loginBox input {
	color: #ffffff;
	width: 100%;
	font-size: 18px;
	box-sizing: border-box;
	background-color: transparent;
	border: none;
}

.loginBox input::placeholder {
	color: #b2b2b2;
}



.loginBox .interfaces {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	margin-top: 12px;
}

.loginBox .interfaces input[type="checkbox"] {
	-webkit-appearance: none;
	position: relative;
	width: 24px !important;
	height: 24px !important;
	cursor: pointer;
	outline: none !important;
	border-radius: 6px;
	padding: 10px;
	background: #000000;
}

.loginBox .interfaces input[type="checkbox"]::before {
	content: "\2713";
	position: absolute;
	top: 50%;
	left: 50%;
	font-weight: bold;
	overflow: hidden;
	transform: scale(0) translate(-50%, -50%);
	line-height: 1;
}

.loginBox .interfaces input[type="checkbox"]:checked {
	background: #000000;
	border-color: rgba(255, 255, 255, 0.3);
	color: #b2b2b2;
}

.loginBox .interfaces input[type="checkbox"]:checked::before {
	border-radius: 6px;
	transform: scale(1) translate(-50%, -50%)
}

.loginBox .interfaces label{
	cursor: pointer;
	display: flex;
	align-items: center;
}

.loginBox .interfaces .leftContents {
	color: #b2b2b2;
	display: flex;
	gap: 12px;
}
.loginBox .interfaces .rightContents {
	color: #b2b2b2;
	text-align: right;
	cursor: pointer;
}
.password {
	-webkit-text-security: disc;
}
.helpcs {
	position: relative;
}


.loginBtn{
	display: flex;
	flex-direction: column;
	gap: 22px;
	margin-top: 25px;
}
.loginBtn .loginbtn{
	width: 100%;
	height: 40px;
	border-radius: 10px;
	background-image: linear-gradient(75deg, #238b4d 27%, #7ccb37 83%);
	color: #fff;
	font-size: 24px;
}
.loginBtn .loginWrong{
	width: 100%;
	text-align: center;
	color: #b2b2b2;
	font-size: 20px;
}

.loginBtn .loginWrong span {
	color: #ffffff;
	cursor: pointer;
}
.loginBtn .loginWrong span:hover, .inputIcon:hover {
	opacity: 0.5;
}

.connectWallet {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	height: 40px;
	font-size: 20px;
	border-radius: 10px;
	background-image: linear-gradient(73deg, #bc2424 27%, #e467b6 83%);
	color: #fff;
	gap: 6px;
	cursor: pointer;
}

.signupBtn {
	display: flex;
	cursor: pointer;
	color: #fff;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	height: 40px;
	font-size: 20px;
	border-radius: 10px;
	background-image: linear-gradient(75deg, #d2a023 27%, #bd3996 83%) !important;
}


/*************** mobile ****************/
.loginWrap.mobile{
	width: 100vw;
	height: 100vh;
	justify-content: flex-start;
	padding: 50px 50px 0;
}
.loginWrap.mobile .mobileLogoWrap{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 14px;
	margin-bottom: 57px;
}
.loginWrap.mobile .mobileLogoWrap img:first-child{
	width: 110px;
}

.loginWrap.mobile h2{
	padding: 0 0 0 7px;
	border-left: 2px solid #5ccfff;
	font-size: 22px;
	font-weight: normal;
	margin-bottom: 20px;
}

.loginWrap.mobile .closeBtn{
	display: none;
}

.loginWrap .inputWrap .inputBox .input{
	display: flex;
}
.loginWrap .inputWrap .inputBox .input input{
	font-size: 18px;
}

.loginWrap.mobile .loginBox input {
	font-size: 12px;
}
.inputIcon {
	position: absolute;
	right: 0;
	margin-right: 15px;
	cursor: pointer;
}

.loginWrap.mobile .inputIcon img{
	width: 24px;
	vertical-align: middle;
}

.loginWrap.mobile .loginBox .interfaces {
	font-size: 12px;
	margin-top: 7px;
}

.loginWrap.mobile .loginBox .interfaces input[type="checkbox"] {
	width: 14px !important;
	height: 14px !important;
	border-radius: 2px;
	padding: 4px;
}
.loginBox.mobile .interfaces .leftContents {
	gap: 4px;
}
.loginBox.mobile .interfaces .rightContents {
	padding-right: 0;
}

.loginWrap.mobile .loginBtn{
	gap: 14px;
	margin-top: 32px;
}
.loginBtn button{
	height: 40px;
	font-size: 20px;
}
.loginBtn .loginWrong{
	font-size: 16px;
}
</style>
