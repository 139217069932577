<template>
    <div
        v-bind:class="{ 'mobile': isMobile() }"
        class="sabongRoomWrap">

        <SabongInGameInter />

        <!--<div class="bannerSlideWrap">
            <MarqueeText
                v-bind:duration="10"
                v-bind:repeat="4"
                v-bind:paused="isStop"
                v-on:mouseover="isStop = true"
                v-on:mouseleave="isStop = false"
                v-bind:key="sabongRooms">
                <ul class="bannerSlide">
                    <li
                        v-for="(room, idx) in sabongRooms"
                        v-bind:key="idx">
                        <p
                            class="agentName"
                            v-bind:class="{ 'hide': room['hide'] }">
                            {{ room['roomName'] }}
                        </p>
                        <p
                            class="bannerFightNo"
                            v-bind:class="{ 'hide': room['hide'] }">
                            Fight No.<span>{{ room['getFightNo']() }}</span>
                        </p>
                        <p
                            class="slideWala"
                            v-bind:class="{ 'hide': room['hide'] }">
                            {{ sabongBanner['meron'] }} <span>{{ room['getOdd']?.['wala_odd'] }}</span>
                        </p>
                        <p
                            class="slideMeron"
                            v-bind:class="{ 'hide': room['hide'] }">
                            {{ sabongBanner['wala'] }} <span>{{ room['getOdd']?.['meron_odd'] }}</span>
                        </p>
                    </li>
                </ul>
            </MarqueeText>
        </div>
        <div class="sabongBanner">
            <p>
                <span class="sabongTitle">
                    {{ sabongTitle }}
                </span>
                {{ $t("bannerContent1") }}
                {{ $t("bannerContent2") }}<br>
                {{ $t("bannerContent3") }}<br>
            </p>
        </div>-->

        <!--                v-bind:class="{ 'disabled': room['roomObj'].value === 'close' || room['lock'], 'hide': room['hide'] }"-->
        <!--<div class="sabongRoomSlide">
            <div
                v-for="(room, idx) in sabongRooms"
                v-bind:key="idx"
                class="roomTable">
                <div class="tableMainTxt">
                    <div class="topLeft">
                        <img
                            alt=""
                            v-bind:src="room['logo']">
                        <span style="font-weight: bold">
                            {{ room['roomName'] }}
                        </span>
                    </div>
                </div>
                <div class="tableContBox">
                    <div
                        class="hoverContent"
                        v-on:click.stop="room['enter']()">
                        <p>Join The Game</p>
                    </div>
                    <img
                        v-bind:alt="room['key']"
                        v-bind:src="room['banner']">
                    <div class="fightNo">
                        <p>Fight No.<span>{{ room['getFightNo']() }}</span></p>
                    </div>
                    <div class="roomStateWrap">
                        <span v-bind:class="room['getStatus']()['color']">
                            {{ room['getStatus']()['tx'] }}
                        </span>
                    </div>
                    <div class="roomStateList">
                        <div class="roomStateTop">
                            <p>Last 10 Game History</p>
                            <p class="mobileTotal">
                                <span>{{ room['getFightNo']() }}</span> / {{ room['totalRound'] }}
                            </p>
                            <div>
                                <p>Total</p>
                                <p class="total">
                                    <span>{{ room['getFightNo']() }}</span> / {{ room['totalRound'] }}
                                </p>
                            </div>
                        </div>
                        <ul class="stateListBox">
                            <li
                                v-for="(data,idx) in room['getHistory'].value.history"
                                v-bind:key="data">
                                <p>Fight No.<span>{{ room['getHistory'].value.history.length - idx }}</span></p>
                                <p
                                    class="state"
                                    v-bind:class="data['sub_rooster']">
                                    {{ sabongHistory[data['sub_rooster']] }}
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>-->
        <!--roomTable-->
    </div>
</template>
<script setup>
    import { onBeforeMount, onMounted, watch,  reactive, computed, ref } from "vue";
    import { sabong, sabongInter, sabongPcc, sabongOne, sabongWsa, sabongNca, loginUser, topics } from "@/js/reactive";
    import { enterSabongRoom } from "@/js/inGame/sabong";
    import { getVinusSabongRoomStatus, getVinusSabongFightNo, getHistory } from "@/js/inGame/sabongInter";
    import { isMobile, getCookie, setCookie } from "@/js/utils";
    import { sendTopic } from "@/plugins/mqtt";
    import { showModal } from "@/js/modal/modals";
    import MarqueeText from 'vue-marquee-text-component';
    //사봉 로고, 배너 이미지들
    import pccLogo from '@/assets/sabong/logo/PCC2021.Live.png';
    import pccBanner from '@/assets/sabong/banner/pcc.png';
    import KGILogo from '@/assets/sabong/logo/logo_KGI.png';
    import KGIBanner from '@/assets/sabong/banner/banner_KGI.png';
    import vinusLogo from '@/assets/sabong/logo/VINUS_SABONG.png';
    import vinusBanner from '@/assets/sabong/banner/vinus.png';
    import ucfcLogo from '@/assets/sabong/logo/UCFC.png';
    import ucfcBanner from '@/assets/sabong/banner/sabong_banner_UCFC.png';
    import wsaLogo from '@/assets/sabong/logo/WSA.png';
    import wsaBanner from '@/assets/sabong/banner/WSA2.png';
    import oneBetLogo from '@/assets/sabong/logo/logo_ONEBET.png';
    import oneBetBanner from '@/assets/sabong/banner/onebet.png';
    import ncaLogo from '@/assets/sabong/logo/logo_NCA.png';
    import ncaBanner from '@/assets/sabong/banner/nca.png';
    import axios from "axios";
    import moment from "moment-timezone";
    import { useStore } from "vuex";
    import { LOGO } from "@/js/const";

    
    import SabongInGameInter from "@/components/sabongInter/InGame";



    const store = useStore();
    const duration = ref(30);

    watch(topics, () => {
        sabongRooms.forEach(room => {
            if(topics[`MainSabong/${room['gameIdx']}`]){
                room['totalRound'] = topics[`MainSabong/${room['gameIdx']}`]['totalFightCount'];
                delete topics[`MainSabong/${room['gameIdx']}`];
            }
            if(topics[`sabong_result_${room['gameIdx']}`]){
                switch(room['gameIdx']){
                    case 0:
                        sabongPcc['history'].history?.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                    case 1:
                        sabongInter['history'].history?.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                    case 2:
                        sabongWsa['history'].history?.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                    case 3:
                        sabongOne['history'].history?.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                    case 4:
                        sabongNca['history'].history?.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                }
                delete topics[`sabong_result_${room['gameIdx']}`];
            }
        });
    }, {
        deep: true
    })
    // 가운데 사봉 타이틀
    const sabongTitle = LOGO[location.hostname]?.sabongTitle || LOGO['vinusinter.com'].sabongTitle;

    // 로비 배너 MarqueeText
    const sabongBanner = LOGO[location.hostname].betLocale.roadLogo || LOGO['vinusinter.com'].betLocale.roadLogo;

    // 히스토리 결과 출력
    const sabongHistory = LOGO[location.hostname].betLocale.text || LOGO['vinusinter.com'].betLocale.text
    // 사봉 방
    const sabongRooms = [
        {
            'key': 'vinusPcc',
            'gameIdx': 0,
            'totalRound': topics['MainSabong/0'] ? topics['MainSabong/0']['totalFightCount'] : '-',
            'roomName': 'PCC2021.Live',
            'roomObj': computed(() => {
                return sabongPcc['rooms']?.event_id ? sabongPcc['rooms'] : 'close';
            }),
            'logo': pccLogo,
            'banner': pccBanner,
            'enter': async () => {
                const theme = store.getters['theme/getTheme'];
                isGetodd.value = false;
                await enterSabongRoom('vinusPcc', theme);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongPcc['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongPcc['rooms']);
            },
            'getHistory': computed(() => {
                return sabongPcc['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['vinusPcc'],
            'lock': false,
            'hide': true
        },
        /* {
            'key': 'inter',
            'roomName':  LOGO[location.hostname]?.interRoomTitle || LOGO['vinusinter.com'].interRoomTitle,
            'gameIdx': 1,
            'totalRound': topics['MainSabong/1'] ? topics['MainSabong/1']['totalFightCount'] : '-',
            'roomObj': computed(() => {
                return sabongInter['rooms']?.event_id ? sabongInter['rooms'] : 'close';
            }),
            'logo': LOGO[location.hostname]?.interRoomLogo ?? LOGO['vinusinter.com'].interRoomLogo,
            'banner': LOGO[location.hostname]?.interRoomBanner ?? LOGO['vinusinter.com'].interRoomBanner,
            'enter': async () => {
                isGetodd.value = false;
                await enterSabongRoom('inter', 1);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongInter['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongInter['rooms']);
            },
            'getHistory': computed(() => {
                return sabongInter['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['inter'],
            'lock': false,
            'hide': false
        },*/
        {
            'key': 'wsa',
            'gameIdx': 2,
            'totalRound': topics['MainSabong/2'] ? topics['MainSabong/2']['totalFightCount'] : '-',
            'roomName': 'World Slasher Alliance',
            'roomObj': computed(() => {
                return sabongWsa['rooms']?.event_id ? sabongWsa['rooms'] : 'close';
            }),
            'logo': wsaLogo,
            'banner': wsaBanner,
            'enter': async () => {
                const theme = store.getters['theme/getTheme'];
                isGetodd.value = false;
                await enterSabongRoom('wsa', theme);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongWsa['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongWsa['rooms']);
            },
            'getHistory': computed(() => {
                return sabongWsa['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['wsa'],
            'lock': false,
            'hide': true
        },
        /*{
            'key': 'one',
            'roomName': 'One Bet',
            'gameIdx': 3,
            'totalRound': topics['MainSabong/3'] ? topics['MainSabong/3']['totalFightCount'] : '-',
            'roomObj': computed(() => {
                return sabongOne['rooms']?.event_id ? sabongOne['rooms'] : 'close';
            }),
            'logo': oneBetLogo,
            'banner': oneBetBanner,
            'enter': async () => {
                const theme = store.getters['theme/getTheme'];
                isGetodd.value = false;
                await enterSabongRoom('one', theme);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongOne['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongOne['rooms']);
            },
            'getHistory': computed(() => {
                return sabongOne['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['one'],
            'lock': false,
            'hide': true
        },*/
        {
            'key': 'nca',
            'roomName': 'NCA',
            'gameIdx': 4,
            'totalRound': topics['MainSabong/4'] ? topics['MainSabong/4']['totalFightCount'] : '-',
            'roomObj': computed(() => {
                return sabongNca['rooms']?.event_id ? sabongNca['rooms'] : 'close';
            }),
            'logo': ncaLogo,
            'banner': ncaBanner,
            'enter': async () => {
                const theme = store.getters['theme/getTheme'];
                isGetodd.value = false;
                await enterSabongRoom('nca', theme);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongNca['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongNca['rooms']);
            },
            'getHistory': computed(() => {
                return sabongNca['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['nca'],
            'lock': false,
            'hide': true
        }
    ];

    // 배너 마우스 호버에 따른 멈춤효과
    const isStop = ref(false);

    onBeforeMount(async () => {
        let roomKey = 'inter';
        let showRoom = {
            'sabong.jgpot.com': [roomKey],
            'vgsabong.com': [roomKey],
            'vsabongservice.com': [roomKey],
            '127.0.0.1': [roomKey]
        }
        if(showRoom[location.hostname] && localStorage.token != 'token'){
            sabongRooms.forEach(room => {
                let find = showRoom[location.hostname].find(r => r == room['key']);
                if(!find){
                    room['hide'] = false;
                }
            });
        }
        sendTopic('SabongUserLogin', {});
    });

    onMounted(async () =>{
        await getHistory();
        getOdd();
    });

    const isGetodd = ref(true);
    const getOdd = () => {
        if(isGetodd.value) {
            sabongRooms.forEach((room) => {
                if(room['roomObj']?.value !== 'close'){
                    store.dispatch('betOdd/setRoomOdd', {
                        'event_id': room['roomObj'].value['event_id'],
                        'fight_no': room['roomObj'].value['fight_no'],
                        'roomName': room['key']
                    });
                }
            });
        }
    }
</script>
<style scoped>
    *{
        font-family: 'Folks', sans-serif;
        letter-spacing: .3px;
    }
    .sabongRoomWrap{
        width: 100%;
        height: calc(100vh - 130px);
        /*height: calc(100vh - 110px);*/
    }
    .bannerSlideWrap{
        width: 100%;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.75);
        height: 30px;
        line-height: 30px;
    }
    .bannerSlide{
        height: inherit;
        gap: 74px;
        color: #fff;
        font-size: 16px;
        display: flex;
        flex-wrap: nowrap;
    }

    .bannerSlide li{
        float: left;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        height: inherit;
        gap: 20px;
    }
    .bannerSlide li:last-child{
        margin-right: 74px;
    }
    .bannerSlide li .agentName{
        color: #5ccfff;
    }
    .bannerSlide li .slideWala{
        color: #ff2626;
    }
    .bannerSlide li .slideMeron{
        color: #2675ff;
    }
    .bannerSlide li .slideWala span,
    .bannerSlide li .slideMeron span{
        color: #fff;
    }

    .sabongBanner{
        width: 100%;
        height: 250px;
        background-image: url("../../assets/sabong/sabong_banner.png");
        background-size: 100% 100%;
        background-position: right;
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px;
    }
    .sabongBanner p{
        width: 420px;
        font-size: 18px;
        color: #fff;
    }
    .sabongBanner p span.sabongTitle{
        display: block;
        font-family: 'LoveraPersonal','sans-serif';
        font-size: 40px;
        margin-bottom: 10px;
        padding-left: 10px;
        border-left: 4px solid #fff;
    }
    .sabongRoomSlide{
        height: calc(100vh - 390px);
        min-height: 560px;
        padding: 15px 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #2e3033;
    }
    .roomTable{
        height: 100%;
        float: left;
        transition: all .3s ease;
        margin-right: 40px;
    }

    .roomTable .tableMainTxt {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fafafa;
        margin: 0 auto 10px;
    }

    .tableMainTxt .topLeft {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .tableMainTxt .topLeft > img {
        width: 53px;
    }

    .tableMainTxt .topRight {
        font-size: max(1.2vw, 15px);
        font-weight: 500;
        margin-right: 10px;
        text-align: right;
    }

    .roomTable .tableContBox {
        position: relative;
        height: 90%;
        margin: 0 auto;
    }
    .roomTable .tableContBox .hoverContent{
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        height: 100%;
        width: 100%;
        border-radius: 10px;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 0;
        opacity: 0;
        transition: all .3s ease;
        cursor: default;
    }
    .roomTable .tableContBox .hoverContent p{
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 44px;
    }
    .roomTable .tableContBox .hoverContent button{
        width: 60%;
        height: 40px;
        border-radius: 10px;
        font-size: 20px;
        color: #fff;
        margin-bottom: 24px;
    }
    .roomTable .tableContBox .hoverContent button:last-child{
        margin-bottom: 0;
    }
    .roomTable .tableContBox .hoverContent button.basic{
        background-image: linear-gradient(70deg, #238b4d 26%, #7ccb37 84%);
    }
    .roomTable .tableContBox .hoverContent button.pro{
        background-image: linear-gradient(70deg, #bc2424 26%, #e467b6 84%);
    }
    .sabongRoomSlide > div:not(.disabled):hover .hoverContent{
        z-index: 15;
        opacity: 1;
    }

    .roomTable .roomStateWrap {
        position: absolute;
        top: 10px;
        right: 10px;
        min-width: 147px;
        width: 46%;
        height: 24px;
        line-height: 24px;
        background-color: rgba(11, 11, 12, 0.9);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #ffffff;
        padding: 0 8px;
    }

	.roomTable .roomStateWrap .green {
		color: #2be000;
        animation: blink 2s infinite both;
	}
    @keyframes blink {
        0%{
            opacity: 1
        }
        40%{
            opacity: 0.2
        }
        80%{
            opacity: 1
        }
        100%{
            opacity: 1
        }
    }

    .roomTable .InfoBox .orange {
        color: orange;
    }

    .roomTable .InfoBox .blue {
        color: #079cff;
    }

    .roomTable .InfoBox .gold {
        color: #e09d00;
    }

    .roomTable .InfoBox .gray {
        color: #cfcfcf;
    }

    .roomTable .fightNo{
        position: absolute;
        top: 10px;
        left: 10px;
        min-width: 147px;
        width: 46%;
        height: 24px;
        line-height: 24px;
        background-color: rgba(11, 11, 12, 0.9);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #ffffff;
        padding: 0 8px;
    }

    .tableContBox > img {
        height: 100%;
        max-width: unset;
        border-radius: 10px;
        box-shadow: 10px 10px rgba(0, 0, 0, 0.4);
    }

    .tableContBox > .line{
        width: 140px;
        height: 23px;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: -10px;
        margin-right: -20px;
    }

    .roomStateList{
        position: absolute;
        top: 41px;
        right: 10px;
        width: 46%;
        min-width: 147px;
        height: 73%;
        background-color: rgba(11, 11, 12, 0.9);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        color: #ffffff;
        padding: 7px;
        gap: 8px;
    }
    .roomStateList .roomStateTop{
        display: unset;
        width: 100%;
    }
    .roomStateList .roomStateTop .last10{
        display: block;
        font-size: 10px;
        letter-spacing: 0;
        width: 100%;
        text-align: right;
    }
    .roomStateList .roomStateTop .mobileTotal{
        display: none;
    }
    .roomStateList .roomStateTop div{
        width: 100%;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
    }
    .roomStateList .roomStateTop div p.total{
        width: auto;
        height: 17px;
        line-height: 17px;
        border-radius: 2rem;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0 8px;
    }
    .stateListBox{
        width: 100%;
        height: calc(100% - 40px);
        overflow-y: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 6px;
        font-size: 12px;
    }
    .stateListBox li{
        width: 100%;
        min-height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #646464;
        padding: 0 3px;
    }
    .stateListBox li .state{
        height: 17px;
        line-height: 17px;
        width: 35%;
        text-align: center;
        border-radius: 6px;
    }
    .stateListBox li .state.meron{
        background-color: #e14242;

    }
    .stateListBox li .state.wala{
        background-color: #0274d6;
    }
    .stateListBox li .state.cancel{
        background-color: gray;
    }
    .stateListBox li .state.draw{
        background-color: green;
    }

	/* 모바일 */
    .sabongRoomWrap.mobile{
		display: flex;
		flex-wrap: wrap;
        margin-top:5px;
        gap: 20px;
        padding: 0 25px 0 14px;
    }
    .sabongRoomWrap.mobile .sabongBanner{
        display: none;
    }
	.sabongRoomWrap.mobile .roomTable{
        width: 90%;
		margin: 0 auto;
        height: auto;
        transition: all .3s ease;
	}

    .sabongRoomWrap.mobile .roomTable .tableMainTxt {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fafafa;
        padding: 0 5px;
    }

    .sabongRoomWrap.mobile .tableMainTxt .topLeft {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 9px;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 2px;
    }

    .sabongRoomWrap.mobile .tableMainTxt .topRight {
        font-size: 10px;
        font-weight: 500;
    }

    .sabongRoomWrap.mobile .tableMainTxt .topLeft > img {
        height: 38px;
    }

    .sabongRoomWrap.mobile .roomTable .tableContBox {
        position: relative;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }

    .sabongRoomWrap.mobile .roomTable .roomStateWrap {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 34%;
        min-width: 80px;
        height: 20px;
        line-height: 20px;
        background-color: rgba(46, 48, 51, 0.9);
        border-radius: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8vw;
        color: #ffffff;
        padding: 0 8px;
    }
    .sabongRoomWrap.mobile .roomTable .roomStateWrap .green {
		color: #2be000;
	}

    .sabongRoomWrap.mobile .roomTable .InfoBox .blue {
        color: #079cff;
    }

    .sabongRoomWrap.mobile .roomTable .roomStateWrap .gold {
		color: #e09d00;
	}
    .sabongRoomWrap.mobile .tableContBox > img {
		width: 100%;
		height: auto;
		box-shadow: 10px 10px rgba(0, 0, 0, .4);
	}
    .sabongRoomWrap.mobile .roomStateList .roomStateTop{
        display: flex;
        justify-content: space-between;
    }
    .sabongRoomWrap.mobile .roomStateList .roomStateTop .mobileTotal{
        display: block;
        width: auto;
        height: 13px;
        line-height: 13px;
        border-radius: 2rem;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0 8px;
    }
    .sabongRoomWrap.mobile .roomStateTop > div{
        display: none;
    }

    /* 20220209 박재형 추가 */
    .sabongRoomWrap.mobile .roomStateList{
        top: 27px;
        left: 4px;
        height: 80%;
        max-height: 250px;
        border-radius: 8px;
        padding: 4px 6px;
        gap: 4px;
    }
    .sabongRoomWrap.mobile .roomStateList ul{
        height: calc(100% - 20px);
        gap: 4px;
        overflow: hidden;
    }
    .sabongRoomWrap.mobile .stateListBox li{
        min-height: 18.5px;
    }
    .sabongRoomWrap.mobile .stateListBox li .state{
        height: 13px;
        line-height: 12px;
        width: 38%;
    }

    /*211119 한유정 추가*/
    .openFade{
        opacity: 1;
        transition: all .5s ease;
    }
    .closeFade{
        opacity: 0;
        transition: all .3s ease;
    }
	.disabled {
		opacity: 0.5;
	}
	.hide {
		display: none !important;
	}
</style>