<template>
    <div
        v-bind:class="{ 'mobile': isMobile() }"
        class="sabongRoomWrap">
        <div class="bannerSlideWrap">
            <MarqueeText
                v-bind:duration="20"
                v-bind:repeat="4">
                <ul class="bannerSlide">
                    <li
                        v-for="(room, idx) in sabongRooms"
                        v-bind:key="idx">
                        <p
                            class="agentName"
                            v-bind:class="{ 'hide': room['hide'] }">
                            {{ room['roomName'] }}
                        </p>
                        <p
                            class="bannerFightNo"
                            v-bind:class="{ 'hide': room['hide'] }">
                            Fight No.<span>{{ room['getFightNo']() }}</span>
                        </p>
                        <p
                            class="slideWala"
                            v-bind:class="{ 'hide': room['hide'] }">
                            {{ sabongBanner['meron'] }} <span>{{ room['getOdd']?.['wala_odd'] }}</span>
                        </p>
                        <p
                            class="slideMeron"
                            v-bind:class="{ 'hide': room['hide'] }">
                            {{ sabongBanner['wala'] }} <span>{{ room['getOdd']?.['meron_odd'] }}</span>
                        </p>
                    </li>
                </ul>
            </MarqueeText>
        </div>
        <div
            v-for="(room, idx) in sabongRooms"
            v-bind:key="idx"
            v-bind:class="{ 'disabled': room['roomObj'].value === 'close' || room['lock'], 'hide': room['hide'] }"
            v-on:mouseup.stop="room['enter']()"
            class="roomTable">
            <div class="tableMainTxt">
                <div class="topLeft">
                    <img
                        alt=""
                        v-bind:src="room['logo']">
                    <span>{{ room['roomName'] }}</span>
                </div>
            </div>
            <div class="tableContBox">
                <!-- <div
                    class="hoverContent"
                >
                    <p>Join The Game</p>
                    <button
                        class="basic"
                        v-on:mouseup.stop="room['enter'](0)">
                        Basic mode
                    </button>
                    <button
                        class="pro"
                        v-on:mouseup.stop="room['enter'](1)">
                        Pro mode
                    </button>
                </div> -->
                <img
                    v-bind:alt="room['key']"
                    v-bind:src="room['banner']">
                <div class="fightNoWrap">
                    <p>Fight No.
                        <span>
                            {{ room['getFightNo']() }}
                        </span>
                    </p>
                </div>
                <div class="roomStateWrap">
                    <span v-bind:class="room['getStatus']()['color']">
                        {{ room['getStatus']()['tx'] }}
                    </span>
                </div>
                <ul class="rightListWrap">
                    <li></li>
                    <li
                        v-for="i in 10"
                        v-bind:key="i">
                        <div v-bind:class="room['getHistory'].value.history?.[i]?.['sub_rooster']">
                            {{ sabongHistory[room['getHistory'].value.history?.[i]?.['sub_rooster']]?.substring(0, 1) }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!--roomTable-->
    </div>
</template>
<script setup>
    import {onBeforeMount, onMounted, watch, reactive, computed, ref} from "vue";
    import { sabong, sabongInter, sabongPcc, sabongOne, sabongWsa, sabongNca, loginUser, topics } from "@/js/reactive";
    import { enterSabongRoom } from "@/js/inGame/sabong";
    import { getVinusSabongRoomStatus, getVinusSabongFightNo, getHistory } from "@/js/inGame/sabongInter";
    import { isMobile, getCookie, setCookie } from "@/js/utils";
    import { sendTopic } from "@/plugins/mqtt";
    import SideLogin from "@/components/sabong/SideLogin";
    import MarqueeText from 'vue-marquee-text-component';

    //사봉 로고, 배너 이미지들
    import pccLogo from '@/assets/sabong/logo/PCC2021.Live.png';
    import pccBanner from '@/assets/sabong/banner/pcc2.png';
    import vinusLogo from '@/assets/sabong/logo/VINUS_SABONG.png';
    import vinusBanner from '@/assets/sabong/banner/vinus2.svg';
    import KGILogo from '@/assets/sabong/logo/logo_KGI.png';
    import KGIBanner from '@/assets/sabong/banner/mobile_banner_KGI.png';
    import interLogo from '@/assets/sabong/logo/sabong_logo_INT.png';
    import interBanner from '@/assets/sabong/banner/int2.png';
    import ucfcLogo from '@/assets/sabong/logo/UCFC.png';
    import ucfcBanner from '@/assets/sabong/banner/sabong_banner_UCFC.png';
    import wsaLogo from '@/assets/sabong/logo/WSA.png';
    import wsaBanner from '@/assets/sabong/banner/WSA_2.png';
    import oneBetLogo from '@/assets/sabong/logo/logo_ONEBET.png';
    import oneBetBanner from '@/assets/sabong/banner/onebet2.png';
    import ncaLogo from '@/assets/sabong/logo/logo_NCA.png';
    import ncaBanner from '@/assets/sabong/banner/NCA2.png';
    import axios from "axios";
    import moment from "moment-timezone";
    import { useStore } from "vuex";
    import {LOGO} from "@/js/const";
    const store = useStore();

    const setup = reactive({
        logoSrc: store.getters['theme/getTheme'] ? KGILogo : vinusLogo,
        bannerSrc: store.getters['theme/getTheme'] ? KGIBanner : vinusBanner,
    })

    watch(topics, () => {
        sabongRooms.forEach(room => {
            if(topics[`MainSabong/${room['gameIdx']}`]){
                room['totalRound'] = topics[`MainSabong/${room['gameIdx']}`]['totalFightCount'];
                delete topics[`MainSabong/${room['gameIdx']}`];
            }
            if(topics[`sabong_result_${room['gameIdx']}`]){
                switch(room['gameIdx']){
                    case 0:
                        sabongPcc['history'].history.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                    case 1:
                        sabongInter['history'].history.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                    case 2:
                        sabongWsa['history'].history.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                    case 3:
                        sabongOne['history'].history.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                    case 4:
                        sabongNca['history'].history.unshift({'sub_rooster' : topics[`sabong_result_${room['gameIdx']}`]?.['subRooster'] || topics[`sabong_result_${room['gameIdx']}`]?.['rooster'] });
                        break;
                }
                delete topics[`sabong_result_${room['gameIdx']}`];
            }
        });
    }, {
        deep: true
    })

    // 로비 배너 MarqueeText
    const sabongBanner = LOGO[location.hostname].betLocale.roadLogo || LOGO['vinusinter.com'].betLocale.roadLogo;

    // 히스토리 결과 출력
    const sabongHistory = LOGO[location.hostname].betLocale.text || LOGO['vinusinter.com'].betLocale.text

    // 사봉 방
    const sabongRooms = [
        {
            'key': 'vinusPcc',
            'gameIdx': 0,
            'totalRound': topics['MainSabong/0'] ? topics['MainSabong/0']['totalFightCount'] : '-',
            'roomName': 'PCC2021.Live',
            'roomObj': computed(() => {
                return sabongPcc['rooms']?.event_id ? sabongPcc['rooms'] : 'close';
            }),
            'logo': pccLogo,
            'banner': pccBanner,
            'enter': async () => {
                // vgsabong.com = basic / 0   , vsabongservice = pro / 1
                const theme = location.hostname.indexOf('vsabongservice') > -1 ? 1 : 0;
                store.commit('modal/showSideMenu', false);
                isGetodd.value = false;
                await enterSabongRoom('vinusPcc', theme);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongPcc['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongPcc['rooms']);
            },
            'getHistory': computed(() => {
                return sabongPcc['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['vinusPcc'],
            'lock': false,
            'hide': true
        },
        {
            'key': 'inter',
            'roomName': LOGO[location.hostname]?.interRoomTitle || LOGO['vinusinter.com'].interRoomTitle,
            'gameIdx': 1,
            'totalRound': topics['MainSabong/1'] ? topics['MainSabong/1']['totalFightCount'] : '-',
            'roomObj': computed(() => {
                return sabongInter['rooms']?.event_id ? sabongInter['rooms'] : 'close';
            }),
            'logo': LOGO[location.hostname]?.interRoomLogo || LOGO['vinusinter.com'].interRoomLogo,
            'banner': LOGO[location.hostname]?.interRoomBannerM || LOGO['vinusinter.com'].interRoomBannerM,
            'enter': async () => {
                const theme = store.getters['theme/getTheme'];
                isGetodd.value = false;
                await enterSabongRoom('inter', 1);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongInter['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongInter['rooms']);
            },
            'getHistory': computed(() => {
                return sabongInter['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['inter'],
            'lock': false,
            'hide': false
        },
        {
            'key': 'wsa',
            'gameIdx': 2,
            'totalRound': topics['MainSabong/2'] ? topics['MainSabong/2']['totalFightCount'] : '-',
            'roomName': 'WSA',
            'roomObj': computed(() => {
                return sabongWsa['rooms']?.event_id ? sabongWsa['rooms'] : 'close';
            }),
            'logo': wsaLogo,
            'banner': wsaBanner,
            'enter': async (theme) => {
                store.commit('modal/showSideMenu', false);
                isGetodd.value = false;
                await enterSabongRoom('wsa', theme);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongWsa['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongWsa['rooms']);
            },
            'getHistory': computed(() => {
                return sabongWsa['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['wsa'],
            'lock': false,
            'hide': true
        },
        {
            'key': 'one',
            'roomName': 'One Bet',
            'gameIdx': 3,
            'totalRound': topics['MainSabong/3'] ? topics['MainSabong/3']['totalFightCount'] : '-',
            'roomObj': computed(() => {
                return sabongOne['rooms']?.event_id ? sabongOne['rooms'] : 'close';
            }),
            'logo': oneBetLogo,
            'banner': oneBetBanner,
            'enter': async () => {
                // vgsabong.com = basic / 0   , vsabongservice = pro / 1
                const theme = location.hostname.indexOf('vsabongservice') > -1 ? 1 : 0;
                store.commit('modal/showSideMenu', false);
                isGetodd.value = false;
                await enterSabongRoom('one', theme);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongOne['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongOne['rooms']);
            },
            'getHistory': computed(() => {
                return sabongOne['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['one'],
            'lock': false,
            'hide': true
        },
        {
            'key': 'nca',
            'roomName': 'NCA',
            'gameIdx': 4,
            'totalRound': topics['MainSabong/4'] ? topics['MainSabong/4']['totalFightCount'] : '-',
            'roomObj': computed(() => {
                return sabongNca['rooms']?.event_id ? sabongNca['rooms'] : 'close';
            }),
            'logo': ncaLogo,
            'banner': ncaBanner,
            'enter': async () => {
                // vgsabong.com = basic / 0   , vsabongservice = pro / 1
                const theme = location.hostname.indexOf('vsabongservice') > -1 ? 1 : 0;
                store.commit('modal/showSideMenu', false);
                isGetodd.value = false;
                await enterSabongRoom('nca', theme);
            },
            'getFightNo': () => {
                return getVinusSabongFightNo(sabongNca['rooms']);
            },
            'getStatus': () => {
                return getVinusSabongRoomStatus(sabongNca['rooms']);
            },
            'getHistory': computed(() => {
                return sabongNca['history'];
            }),
            'getOdd': store.getters['betOdd/getRoomOdd']['nca'],
            'lock': false,
            'hide': true
        }
    ];

    onBeforeMount(async () => {
        let roomKey = 'inter';
        let showRoom = {
            'vgsabong.com': [roomKey],
            'vsabongservice.com': [roomKey],
            '127.0.0.1': [roomKey]
        }
        if(showRoom[location.hostname] && localStorage.token != 'token'){
            sabongRooms.forEach(room => {
                let find = showRoom[location.hostname].find(r => r == room['key']);
                if(!find){
                    room['hide'] = true;
                }
            });
        }
        sendTopic('SabongUserLogin', {});
    });

    onMounted(async () =>{
        await getHistory();
        getOdd();
    });

    const isGetodd = ref(true);

    const getOdd = () => {
        if (isGetodd.value) {
            sabongRooms.forEach((room) => {
                if (room['roomObj'] !== 'close') {
                    store.dispatch('betOdd/setRoomOdd', {
                        'event_id': room['roomObj'].value['event_id'],
                        'fight_no': room['roomObj'].value['fight_no'],
                        'roomName': room['key']
                    });
                }
            });
        }
    }
</script>
<style scoped>
*{
    font-family: 'Folks', sans-serif;
    letter-spacing: .3px;
}
.sabongRoomWrap{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 45px 4%;
	overflow-y: unset;
}

.bannerSlideWrap{
    width: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.75);
    height: 30px;
    line-height: 30px;
}
.bannerSlide{
    height: inherit;
    gap: 74px;
    color: #fff;
    font-size: 16px;
    display: flex;
    flex-wrap: nowrap;
}

.bannerSlide li{
    float: left;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: inherit;
    gap: 20px;
}
.bannerSlide li:last-child{
    margin-right: 74px;
}
.bannerSlide li .agentName{
    color: #5ccfff;
}
.bannerSlide li .slideWala{
    color: #ff2626;
}
.bannerSlide li .slideMeron{
    color: #2675ff;
}
.bannerSlide li .slideWala span,
.bannerSlide li .slideMeron span{
    color: #fff;
}

.roomWrap .roomTable {
	width: 48%;
	height: auto;
	transition: all .3s ease;
}

.roomTable .tableMainTxt {
	width: 90%;
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fafafa;
	margin: 0 auto;
}

.tableMainTxt .topLeft {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
	font-size: max(1.5vw, 18px);
	font-weight: 500;
	margin-bottom: 4px;
}

.tableMainTxt .topLeft > img {
	width: 74px;
}

.tableMainTxt .topRight {
	font-size: max(1.2vw, 15px);
	font-weight: 500;
	margin-right: 10px;
	text-align: right;
}

.roomTable .tableContBox {
	position: relative;
	width: 90%;
	margin: 0 auto;
	height: auto;
}

.roomTable .roomStateWrap {
	position: absolute;
	top: 10px;
	left: 10px;
	min-width: 147px;
	width: 90px;
	height: 26px;
	line-height: 26px;
	background-color: rgba(46, 48, 51, 0.9);
	border-radius: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.8vw;
	color: #ffffff;
	padding: 0 8px;
}

.roomTable .roomStateWrap .green {
	color: #2be000;
	animation: blink 2s infinite both;
}
@keyframes blink {
	0%{
		opacity: 1
	}
	40%{
		opacity: 0.2
	}
	80%{
		opacity: 1
	}
	100%{
		opacity: 1
	}
}


.roomTable .InfoBox .orange {
	color: orange;
}

.roomTable .InfoBox .blue {
	color: #079cff;
}

.roomTable .InfoBox .gold {
	color: #e09d00;
}

.roomTable .InfoBox .gray {
	color: #cfcfcf;
}

.tableContBox > img {
	box-shadow: 18px 18px rgba(0, 0, 0, 0.4);
}

.tableContBox > .line{
	width: 140px;
	height: 23px;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: -10px;
	margin-right: -20px;
}
.rightListWrap{
    width: 26px;
    max-height: 198px;
    height: 93%;
    border-radius: 13px;
    background-color: rgba(11, 11, 12, 0.9);
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 4px 0;
    overflow: hidden;
}
.rightListWrap li{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #272727;
    position: relative;
}
.rightListWrap li:first-child{
    border: dashed 2px #fff;
    background: transparent;
}
.rightListWrap li div{
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 14px;
    font-size: 10px;
    color: #fff;
}
.rightListWrap li div.meron{
    background-color: #f00;
}
.rightListWrap li div.wala{
    background-color: #2675ff;
}
.rightListWrap li div.cancel{
    background-color: #979797;
}
.rightListWrap li div.draw{
	background-color: #2be000;
}

/* 모바일 */
.sabongRoomWrap.mobile{
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
}
.sabongRoomWrap.mobile .roomTable{
	width: 100%;
	margin: 0 15px 0 8px;
	height: auto;
	transition: all .3s ease;
}

.sabongRoomWrap.mobile .roomTable .tableMainTxt {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fafafa;
	padding: 0 5px;
}

.sabongRoomWrap.mobile .tableMainTxt .topLeft {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 5px;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 2px;
}

.sabongRoomWrap.mobile .tableMainTxt .topRight {
	font-size: 10px;
	font-weight: 500;
}

.sabongRoomWrap.mobile .tableMainTxt .topLeft > img {
	height: 38px;
}

.sabongRoomWrap.mobile .roomTable .tableContBox {
	position: relative;
	width: 100%;
	height: auto;
	margin: 0 auto;
}

.sabongRoomWrap.mobile .roomTable .roomStateWrap {
	position: absolute;
	top: 4px;
    left: 106px;
	width: 90px;
	min-width: 80px;
	height: 17px;
	line-height: 17px;
    border-radius: 2rem;
    background-color: rgba(11, 11, 12, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: #ffffff;
}
.sabongRoomWrap.mobile .roomTable .fightNoWrap {
     position: absolute;
     top: 4px;
     left: 4px;
     width: 90px;
     min-width: 80px;
     height: 17px;
     line-height: 17px;
     border-radius: 2rem;
     background-color: rgba(11, 11, 12, 0.9);
     display: flex;
     justify-content: center;
     align-items: center;
     font-size: 12px;
     color: #ffffff;
 }
.sabongRoomWrap.mobile .roomTable .roomStateWrap .green {
	color: #2be000;
}

.sabongRoomWrap.mobile .roomTable .InfoBox .blue {
	color: #079cff;
}

.sabongRoomWrap.mobile .roomTable .roomStateWrap .gold {
	color: #e09d00;
}
.sabongRoomWrap.mobile .tableContBox > img {
	width: 100%;
	height: auto;
	box-shadow:8px 8px rgba(0, 0, 0, .5);
    border-radius: 23px;
}
.sabongRoomWrap.mobile .roomStateList .roomStateTop{
	display: flex;
	justify-content: space-between;
}
.sabongRoomWrap.mobile .roomStateList .roomStateTop .mobileTotal{
	display: block;
	width: auto;
	height: 13px;
	line-height: 13px;
	border-radius: 2rem;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 0 8px;
}
.sabongRoomWrap.mobile .roomStateTop > div{
	display: none;
}


.roomTable .tableContBox .hoverContent{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
    opacity: 1;
}
.roomTable .tableContBox .hoverContent p{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}
.roomTable .tableContBox .hoverContent button{
    width: 50%;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    margin-bottom: 15px;
}
.roomTable .tableContBox .hoverContent button:last-child{
    margin-bottom: 0;
}
.roomTable .tableContBox .hoverContent button.basic{
    background-image: linear-gradient(70deg, #238b4d 26%, #7ccb37 84%);
}
.roomTable .tableContBox .hoverContent button.pro{
    background-image: linear-gradient(70deg, #bc2424 26%, #e467b6 84%);
}



/*211119 한유정 추가*/

.openFade{
	opacity: 1;
	transition: all .5s ease;
}
.closeFade{
	opacity: 0;
	transition: all .3s ease;
}
.disabled {
	opacity: 0.5;
}
.hide {
	display: none;
}
</style>
