import { reactive, computed } from 'vue';

const state = () => reactive({
    loginUser: {
        balance: 0,
        currency: undefined,
        id: undefined,
        uuid: undefined,
        time: undefined,
        isWallet: false
    },
    refferCode: undefined,
    privateKey: undefined
});

const mutations = {
    setLoginUser: (state, payload) => {
        state.loginUser = JSON.parse(JSON.stringify(payload));
    },
    setLoginUserBalance: (state, payload) => {
        state.loginUser.balance = payload;
    },
    setRefferCode: (state, payload) => {
        state.refferCode = payload;
    },
    setPrivateKey: (state, payload) => {
        state.privateKey = payload;
    },
    initCodeAndKey: (state) => {
        state.refferCode = undefined;
        state.privateKey = undefined;
    },
}

const getters = {
    getLoginUser: (state) => {
        return state.loginUser;
    },
    getRefferCode: (state) => {
        return state.refferCode;
    },
    getPrivateKey: (state) => {
        return state.privateKey;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}