<template>
    <audio
        ref="bgm"
        id="bgm"
        v-if="isBgmOn"
        loop
        autoplay>
        <source
            src="../../assets/music/bgm.wav"
            type="audio/wav">
    </audio>
    <audio
        ref="effect"
        id="effect"
        v-if="getEffectOn > 0"
        loop>
        <source
            src="../../assets/music/timerv21.ogg"
            type="audio/ogg">
    </audio>
</template>
<script>
    import { defineComponent } from "vue";
    import { mapGetters } from 'vuex';

    export default defineComponent({
        computed: {
			...mapGetters(['getBgmOn', 'getIsWebRTCOn', 'getEffectOn'])
        },
        data(){
            return {
                isBgmOn: false
            }
        },
        mounted() {
        },
        watch: {
            getBgmOn: {
                immediate: true,
                handler(val){
                    this.isBgmOn = !this.getIsWebRTCOn && val > 0 ? true : false;
                }
            },
            getIsWebRTCOn: {
                immediate: true,
                handler(isOn){
                    this.isBgmOn = isOn ? false : true;
                }
            },
            getEffectOn: {
                immediate: true,
                handler(val){
                }
            }
        }
    });
</script>