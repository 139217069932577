<template>
    <div
        class="modalInner"
        v-bind:class="{ 'mobile': isMobile() }">
        <ul
            class="btnBox"
            v-bind:class="{ 'mobile': isMobile() }">
            <li
                v-on:click.stop="clickTabKey('deposit')"
                v-on:touchend="clickTabKey('deposit')"
                v-bind:class="{
                    'deposit': tabKey == 'deposit'
                }">
                {{ $t('deposit') }}
            </li>
            <li
                v-on:click.stop="clickTabKey('withdrawal')"
                v-on:touchend="clickTabKey('withdrawal')"
                v-bind:class="{
                    'withdrawal': tabKey == 'withdrawal'
                }">
                {{ $t('withdrawal') }}
            </li>
        </ul>
        <h3 class="wide">
            {{ $t('value')}}
        </h3>
        <div class="inputWrap">
            <img
                src="@/assets/icons/btn_change.png"
                alt="change_btn"
                v-on:click.stop="amount = 0">
            <input
                v-model.number="amount"
                placeholder="0"/>
        </div>
        <span
            class="maxbtn">
            <button
                v-bind:class="{
                    'withdrawal': tabKey == 'withdrawal'
                }"
                v-on:click.stop.prevent="setAmonutValue"
                v-on:touchend.stop.prevent="setAmonutValue"
                v-numberFormat="1000"
                value="1000">
                1000
            </button>
            <button
                v-bind:class="{
                    'withdrawal': tabKey == 'withdrawal'
                }"
                v-on:click.stop.prevent="setAmonutValue"
                v-on:touchend.stop.prevent="setAmonutValue"
                v-numberFormat="5000"
                value="5000">
                5000
            </button>
            <button
                v-bind:class="{
                    'withdrawal': tabKey == 'withdrawal'
                }"
                v-on:click.stop.prevent="setAmonutValue"
                v-on:touchend.stop.prevent="setAmonutValue"
                v-numberFormat="10000"
                value="10000">
            </button>
            <button
                v-bind:class="{
                    'withdrawal': tabKey == 'withdrawal'
                }"
                v-on:click.stop.prevent="setAmonutValue"
                v-on:touchend.stop.prevent="setAmonutValue"
                v-numberFormat="50000"
                value="50000">
                50000
            </button>
            <button
                v-bind:class="{
                    'withdrawal': tabKey == 'withdrawal'
                }"
                v-on:click.stop.prevent="setAmonutValue"
                v-on:touchend.stop.prevent="setAmonutValue"
                v-numberFormat="100000"
                value="100000">
                100000
            </button>
        </span>
        <div class="convertWrap">
            <span>{{ $t('total') }}:</span>
            <span v-numberFormat="amount"></span>
            <span>{{ loginUser.currency }}</span>

        </div>
        <div class="btnWrap">
            <button
                v-bind:class="{
                    'withdrawal': tabKey == 'withdrawal'
                }"
                v-on:click.stop="requestTransaction()"
                class="copyBtn">
                {{ $t(tabKey) }}
            </button>
        </div>
    </div>
</template>
<script setup>

    import { ref, reactive, onMounted, computed } from "vue";
    import {err, loginUser} from "@/js/reactive";
    import { isMobile } from "@/js/utils";
    import { fn } from '@/components/sabong/popup/transaction/transaction'
    import { useStore } from 'vuex';

    const store = useStore();

    const amount = ref(0);
    const tabKey = ref('deposit');
    const submitState = ref(true);

    const setAmonutValue = (e) => {
        const targetValue = Number(e.target.value);
        amount.value += targetValue;
    }

    const requestTransaction = async () => {
        if(amount.value <= 0) return err['msg'] = 'Set the amount.';

        if(tabKey.value == 'withdrawal' && amount.value > loginUser['balance']) return err['msg'] = 'Not enough values.';

        if (tabKey.value == 'deposit' && submitState.value) {
            submitState.value = false;
            const res = await fn.deposit(amount.value);
            if(!res?.logic) err['msg'] = 'Deposit Success. Check your History.';
        }
        if (tabKey.value == 'withdrawal' && submitState.value) {
            submitState.value = false;
            const res = await fn.withdrawal(amount.value);
            if(!res?.logic) err['msg'] = 'Withdrawal Success. Check your History.';
        }
        store.commit('modal/initModal');
    }

    const clickTabKey = (key) => {
        amount.value = 0;
        tabKey.value = key;
    }

</script>
<style scoped>

.maxbtn {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	padding-bottom: 10px;
}
.maxbtn > button {
	min-width: 90px;
	min-height: 34px;
	font-size: 16px;
	border-radius: 5px;
	box-shadow: 3px 5px 5px #000;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image: linear-gradient(73deg, #244dbc 27%, #3ca7e5 83%);
}
.maxbtn > button.withdrawal {
    background-image: linear-gradient(73deg, #bc2424 27%, #e467b6 83%);
}

.maxbtn > button:active {
	animation: clickBtn .2s ease-out;
}

@keyframes clickBtn {
	0%,
	100% {
		transform: translateY(0px);
		box-shadow: 3px 5px 5px #000;
	}
	50% {
		transform: translateY(2px);
		box-shadow: 3px 3px 5px #000;
	}
}

.symbolWrap {
	display: flex;
	align-items: center;
	color: white;
	justify-content: center;
	gap: 8px;
}
.symbolWrap:hover {
	cursor: pointer;
	opacity: 0.5;
}
*{
	font-family: 'Folks', sans-serif;
	letter-spacing: .3px;
}
.btnBox{
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
	padding: 10px 5px 0 0;
}

.btnBox li{
	width: 180px;
	height: 48px;
	line-height: 48px;
	background-image: linear-gradient(64deg, #4f5a6d 25%, #89959b 85%);
	font-size: 22px;
	color: #cbcbcb;
	text-align: center;
	border-radius: 10px 10px 0 0;
	cursor: pointer;
}

.btnBox li.deposit{
	border-bottom: 0;
	background-image: linear-gradient(64deg, #244dbc 25%, #3ca7e5 85%);
	color: #fff;
}
.btnBox li.withdrawal{
	border-bottom: 0;
    background-image: linear-gradient(73deg, #bc2424 27%, #e467b6 83%);
	color: #fff;
}

.convertWrap {
	padding-right: 10px;
}

.convertWrap {
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	background: none !important;
	font-size: 12px;
}

.inputWrap {
	background: #1b1b1b;
	height: 45px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	margin-bottom: 10px;
	padding: 0px 10px;
}

.inputWrap img {
	width: 29px;
	cursor: pointer;
	transition: all .3s linear;
}

.inputWrap img:hover {
	transform: rotate(180deg) scale(1.05);
}

.inputWrap input {
	width: 100%;
	height: 25px;
	background: none;
	text-align: right;
	color: #ffce70 !important;
	font-size: 20px;
}
.rArrow {
	transform: rotate(180deg);
}
*{
	font-family: 'Folks', sans-serif;
	letter-spacing: .3px;
}
.modalInner{
	padding: 0 5px;
	font-size:20px;
	height: 100%;
}
.modalInner h3{
	margin: 14px 0 8px;
	font-size:22px;
}
.modalInner h3.wide{
	margin-top: 22px;
}
.modalInner>div{
	box-sizing: border-box;
	background: #1b1b1b;
	border-radius: 6px;
}

.btnWrap button{
	width: 294px;
	height:48px;
	line-height: 48px;
	background-image: linear-gradient(73deg, #244dbc 27%, #3ca7e5 83%);
	font-size:20px;
	border-radius: 10px;
	box-shadow: 3px 5px 5px #000;
}

.btnWrap button.withdrawal{
	background-image: linear-gradient(73deg, #bc2424 27%, #e467b6 83%);
}
.symbolListBox{
	position: relative;
	display: flex;
	width: 100%;
	height:44px;
	padding: 0 12px;
}
.symbolListBox > div{
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background: #1b1b1b;
}
.symbolListBox > div div{
	display: flex;
	align-items: center;
	font-size: 22px;
}
.symbolListBox > div .iconImg{
	margin-right: 10px;
}
.symbolListBox > div .rArrow{
	line-height: 20px;
	height:20px;
}
.symbolListBox .depth_2{
	display:flex ;
	flex-direction: column;
	gap:5px;
	width: 100%;
	height: auto;
	min-height: 100px;
	max-height: 350px;
	overflow-y: auto;
	border: 1px solid #bbbbbb;
	box-sizing: border-box;
	padding: 5px;
	position: absolute;
	top:45px;
	left: 0;
	z-index:10;
	background: #1b1b1b;
}
.symbolListBox .depth_2 li{
	line-height: 39px;
	text-align: left;
	cursor: pointer;
	border: 2px solid #484848;
	box-sizing: border-box;
}
.symbolListBox .depth_2 li img{
	vertical-align: middle;
	margin: 5px 10px;
}
.mvaWrap{
	display: flex;
	gap:20px;
	align-items: center;
	flex-direction: column;
	padding: 28px 0 19px;
}
.qrWrap span{
	display: block;
	background: #fff;
	width: 160px;
	height: 160px;
}
.qrWrap img{
	width: 160px;
	height: 190px;
	padding-bottom: 28px;
}
.mvaWrap textarea{
	width: 100%;
	padding: 0 10%;
	overflow: hidden;
	font-size: 18px;
	line-height: 1.5;
	background: none;
	resize: none;
	border:none;
}
.mvaWrap textarea:focus{
	outline: none;
}
.btnWrap{
	background: none !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 34px 0;
}
*{
	color:#fff;
}



/* mobile */

.modalInner.mobile{
	padding: 12px 10px;
	font-size: 16px;
}
.modalInner.mobile h3{
	margin: 0 0 8px 0;
	font-size: 20px;
}
.modalInner.mobile .symbolListBox{
	margin-bottom: 27px;
	height: 40px;
	padding: 0 10px;
	font-size: 18px;
}
.modalInner.mobile .iconImg{
	width: 28px;
	margin-right: 13px;
}
.modalInner.mobile .rArrow{
	width: 22px;
}
.modalInner.mobile .mvaWrap{
	padding: 46px 0 50px;
	gap: 24px;
}

.modalInner.mobile .inputWrap {
	margin-bottom: 15px;
}

.modalInner.mobile .maxbtn {
	flex-direction: column;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
}

.modalInner.mobile .mvaWrap textarea{
	width: 90%;
}
.modalInner.mobile .mvaWrap p{
	font-size: 14px;
}
.modalInner.mobile .btnWrap{
	margin: 30px 0;
}
.modalInner.mobile button{
	width: 294px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
}
.modalInner.mobile .depth_2{
	height: 454px;
	top: 40px;
	border: 2px solid #adadad;
	padding-top: 12px;
	gap: 8px;
}
.modalInner.mobile .depth_2 img{
	width: 32px;
}

.btnBox.mobile{
	padding: 0 4px;
}
.btnBox.mobile li{
	height: 42px;
	line-height: 42px;
	font-size: 18px;
}
.btnBox.mobile li.active{
	border-bottom: 0;
	background-image: linear-gradient(to bottom, #6696f6, #2352af);
}

</style>
