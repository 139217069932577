<template>
    <div class="loadingWrapBox">
        <div class="loadingWrap">
            <div class="loading">
            </div>
            <span class="text">
                {{ $t('loading') }}
            </span>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    export default defineComponent({
        data() {
            return {
            }
        }
    })
</script>
<style scoped>
	.loadingWrapBox {
		position: fixed;
        left: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        height: 50%;
        z-index: 10;
        transform: translateY(-50%);
        justify-content: center;
	}
	.loadingWrapBox .loadingWrap {
		display: flex;
		height: 400px;
		align-items: center;
		justify-content: center;
	}
	.loadingWrapBox .loadingWrap .loading {
		top: 0;
		width: 180px;
		height: 180px;
		animation-name: ani_loading;
		animation-iteration-count: infinite;
		animation-duration: 1s;
		margin: auto;
		background-image: url('../../assets/icon_loading.png');
		background-size: 100% 100%;
	}
	.loadingWrapBox .loadingWrap .text {
		color: white;
		font-size: 20px;
		position: absolute;
	}
	@keyframes ani_loading {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
</style>