<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>Chatting</h1>
            <span class="exitImg">
                <img
                    src="../../../assets/mobile/btn-close.png"
                    alt="close"
                    v-on:click.stop="$emit('showModal', '')">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="chattingWrap">
                <div class="chattingList">
                    <div
                        v-for="(data, idx) in chat"
                        v-bind:key="idx"
                        v-bind:class="{ 'chatMyMsg': loginId == data['id'] }"
                        class="chatBox">
                        <span>{{ data['id'] }} {{ data['room'] }}</span>
                        <ul>
                            <li class="chatMsg">
                                {{ data['msg'] }}
                            </li>
                            <li class="chatTime">
                                {{ data['time'] }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="chattingSendBox">
                <div class="sendMsg">
                    <input
                        v-model="msg"
                        v-bind:placeholder="$t('chatInfo')"
                        v-on:keyup.enter="send()">
                    <img
                        src="../../../assets/btn/btn-send.png"
                        alt="send"
                        v-on:click.stop="send()">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { chat } from "@/plugins/mqtt";
    import { getCookie } from "@/js/utils";
    import { chatArr } from "@/js/reactive";
    import moment from 'moment';
    import { sendMessage } from "@/js/common";

    export default defineComponent({
        data(){
            return {
                msg: '',
                isErrShow: false,
                chatArr: chatArr,
                chat: [],
                loginId: getCookie('loginId')
            }
        },
        watch: {
            chatArr: {
                immediate: true,
                deep: true,
                handler(arr){
                    this.chat = [];
                    arr.forEach(data => {
                        let info = data.split('/');
                        this.chat.push({
                            'msg': info[0],
                            'time': info[1],
                            'id': info[2],
                            'room': `(Room ${info[3]})`
                        });
                    });
                    this.chat = this.chat.reverse();
                }
            }
        },
        methods: {
            send(){
                if(this.msg.length >= 100){
                    this.isErrShow = true;
                    setTimeout(() => { this.isErrShow = false; }, 1000);
                    return;
                }
                sendMessage(this);
            }
        }
    })

</script>
<style scoped>
    .sideMenuBox{
        width: 100%;
        height: auto;
        padding-bottom: 15%;
        color: #fff;
        font-size: 14px;
        background-color: #2e3033;
    }
    .sideMenuTopBox{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #2e3033;
        padding: 3%;
    }
    .sideMenuTopBox > h1 {
        font-size: 18px;
    }
    .sideMenuInner{
        padding: 0 3%;
        background-color: #2e3033;
        height: 77.6vh;
    }
    .exitImg{
        position: absolute;
        right: 0;
        margin-right: 4%;
        height: 28px;
    }
    .exitImg img{
        width: 28px;
        height: auto;
    }

    /* 내용 */
    .chattingWrap{
        width: 100%;
        height: auto;
        padding: 2px 4px;
        background-color: #1b1b1b;
    }
    .chattingList{
        height: 76vh;
        display: flex;
        flex-direction: column-reverse;
        padding: 12px 5px;
        overflow-y: auto;
    }
    .chatBox{
        width: 100%;
        margin-bottom: 10px;
    }
    .chatBox ul{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-end;
        gap: 5px;
    }
    .chatBox > span{
        display: block;
        color: #b1b1b1;
        font-size: 15px;
        padding-left: 5px;
        margin-bottom: 5px;
    }
    .chatMsg{
        width: 200px;
        border: 1px solid #bbbbbb;
        border-radius: 20px;
        height: 31px;
        line-height: 31px;
        padding: 0 15px;
        text-align: left;
    }
    .chatTime{
        font-size: 12px;
        color: #b1b1b1;
    }

    .chatMyMsg .chatMsg{
        background-color: #3b3b3b !important;
        border-color: #bbbbbb !important;
        text-align: right !important;
    }
    .chatMyMsg{
        text-align: right;
    }
    .chatMyMsg ul{
        flex-direction: row-reverse;
        justify-content: right;
    }
    .sendMsg{
        position: relative;
        width: 100%;
        padding: 8px 0;
    }
    .sendMsg input{
        width: 100%;
        background-color: #1b1b1b;
        border-radius: 40px;
        border: 0;
        height: 55px;
        padding: 5px 20px;
        font-size: 15px;
        color: #fff;
    }
    .sendMsg input::placeholder{
        font-size: 15px;
    }
    .sendMsg input:lang(en)::placeholder{
        font-size: 13px;
    }
    .sendMsg > img{
        position: absolute;
        width: 39px;
        top: 0;
        right: 0;
        margin-right: 10px;
        margin-top: 15px;
    }
</style>