<template>
    <div class="modalWrap">
        <div class="header">
            <div class="title">
                {{ $t(modalKey) }}
            </div>
            <div class="exitBtns">
                <!-- <div class="btn" @touchstart.stop="$emit('exitModal')">
                    <img src="../../../assets/mobile/icon-back.png" width="50" height="50"/> 
                </div> -->
                <div
                    class="btn"
                    v-on:touchstart.stop="$emit('hideSideMenu')">
                    <img
                        src="../../../assets/btn/btn-close.png"
                        width="32"
                        height="32"/>
                </div>
            </div>

        </div>
        <div
            class="body"
            v-bind:class="{ 'chatBody': modalKey === 'chat', 'historyBody': modalKey === 'history', 'soundBody': modalKey === 'sound' }">
            <div
                class="betLimitWrap"
                v-if="modalKey === 'betLimit'">
                <div class="minMax">
                    <div class="title">
                        {{ $t('bet') }}
                    </div>
                    <div class="title">
                        {{ $t('min') }}
                    </div>
                    <div class="title">
                        {{ $t('max') }}
                    </div>
                </div>
                <div
                    class="minMax"
                    v-for="(key, idx) in limitKeys"
                    v-bind:key="idx">
                    <div>
                        {{ $t(key) }}
                    </div>
                    <div
                        class="min"
                        v-number-format="betMinMax[key]['min']">
                    </div>
                    <div
                        class="max"
                        v-number-format="betMinMax[key]['max']">
                    </div>
                </div>
            </div>
            <Loading v-if="loading['modal']"/>
            <Chat v-if="modalKey === 'chat'"/>
            <keep-alive>
                <Sound v-if="modalKey === 'sound'"/>
            </keep-alive>
            <keep-alive>
                <Video
                    v-if="modalKey === 'video'"
                    v-on:onCam="onCam"
                    v-on:offCam="offCam"/>
            </keep-alive>
            <HistoryModal
                v-if="modalKey === 'history'"
                v-on:showModalFooter="showModalFooter"/>
            <ChangeTable v-if="modalKey === 'change'"/>
            <Help v-if="modalKey === 'help'"/>
            <Shop v-if="modalKey === 'shop'"/>
        </div>
        <div
            class="footer"
            v-if="isShowFooter">
        </div>
    </div>
</template>
<script>
    import { loading } from "@/js/reactive";
    import Loading from "@/components/common/Loading";
    import { defineComponent } from "vue";
    import HistoryModal from "@/components/popup/HistoryModal";
    import Chat from "@/components/popup/Chat";
    import Sound from "@/components/popup/Sound";
    import Video from "@/components/popup/Video";
    import ChangeTable from "@/components/popup/ChangeTable";
    import Help from "@/components/popup/Help";
    import Shop from "@/components/popup/Shop";
    import {setNumberFormat} from "@/js/utils";

    export default defineComponent({
        components: {
            HistoryModal,
            Chat,
            Loading,
            Sound,
            Video,
            ChangeTable,
            Help,
            Shop
        },
        data() {
            return {
                limitKeys: [
                    'p', 'b', 'pp', 'bp', 'tie'
                ],
                loading: loading,
                isShowFooter: true
            }
        },
        props: {
            betMinMax: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            modalKey: {
                type: String,
                default: ''
            },
        },
        methods: {
            showModalFooter(isShow) {
                this.isShowFooter = isShow;
            },
            offCam() {
                this.$emit('setCamOff', true);
            },
            onCam() {
                this.$emit('setCamOff', false);
            }
        }
    })
</script>
<style scoped>
	.modalWrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1100;
        background-color: #2e3033;
	}

	.modalWrap .header {
		display: flex;
		align-items: center;
		background-color: #2e3033;
		width: 100%;
		justify-content: center;
		min-height: 48px;
		gap: 15px;
		color: white;
        line-height: 17px;
        font-size: 17px;
	}

	.modalWrap .header .icon {
		margin-left: 15px;
	}
	.modalWrap .body {
		width: 100%;
		height: 356px;
		padding: 0 10px;
		background-color: #2e3033;
	}

	.modalWrap .body.soundBody {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.modalWrap .body .betLimitWrap {
		/*display: flex;*/
        /*flex-direction: column;*/
        /*gap: 30px;*/
		width: 100%;
		font-weight: 500;
		font-size: 13px;
		padding-top: 15px;
	}

	.modalWrap .body .betLimitWrap .minMax {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		color: white;
		text-align: center;
        line-height: 20px;
        font-weight: 300;
	}

	.modalWrap .body .betLimitWrap .minMax div {
		flex: 1;
	}

	.modalWrap .body .betLimitWrap .minMax .title {
		color: #5ccfff;
        font-size: 15px;
        margin-bottom: 14px;
        font-weight: 500;
	}

	.modalWrap .footer {
		display: none;
		align-items: center;
		width: 100%;
		min-height: 80px;
		background-image: linear-gradient(to right, var(--mobile-modal-body-gradient-color1), var(--mobile-modal-body-gradient-color2));
	}
	.modalWrap .footer .btn {
		width: 50px;
		height: 50px;
	}
	.modalWrap .footer .btn:first-child {
		margin-left: 15px;
		margin-right: auto;
	}
	.modalWrap .footer .btn:last-child {
		margin-right: 15px;
		margin-left: auto;
	}
	.exitBtns {
		display: flex;
		margin-right: 15px;
		margin-left: auto;
		position: absolute;
		right: 0;
	}
    .exitBtns > .btn{
        line-height: 10px;
    }
    .chatBody{
        height: 80vh !important;
    }
	.historyBody{
		height: 80vh !important;
	}
</style>