// 도메인 추가 시 해야할 일
/**
 * 1. DOMAIN_AGENT_KEY 에 국가코드 생성 ( default currency, CMS agent, 없을시 생성 )
 * 2. LOGO 에 hostname에 맞춰 추가. ( default logo, text )
 * 3. AXIOS_URL 에 hostname 및 env 변수 세팅 ( axios , cms )
 * 4. UTIL_URL에 hostname 및 env 변수 세팅 ( wallet, util )
 * 5. DOMAIN_MQTT_CONNECT_IP 에 hostname 및 env 변수 세팅 ( mqtt, roominfo )
 * 6. index.html에 파비콘 추가 및 title 변경 ( window header, favicon )
 * 7. 모든 변경이 끝났다 생각들면 1~6 IP 127.0.0.1에 세팅 / vue.config.js에서 proxy 변경 후 돌려보기. ***** 별 5개
 */




export const DOMAIN_AGENT_KEY = {
    "vgsabong.com": "EUR_NORMAL",
    "vsabongservice.com": "USD_NORMAL",
    "kinggalaxyinteractive.com": "USD_NORMAL",
    "kg-365.com": "USD_NORMAL",
    "vinusinter.com": "INR_NORMAL",
    "vinus-inter.com": "INR_NORMAL",
    "vinuscockxing.com": "USD_NORMAL",
    "127.0.0.1": "USD_NORMAL",
    "sabong.jgpot.com": "USD_NORMAL",
}

export const LOGO = {
    "sabong.jgpot.com": {
        betLocale: {
            text: {
                meron: "red",
                wala: "blue",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#2056af"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "B",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/sabong/logo/VS.png'),
        pcHeaderTitle: require("@/assets/sabong/logo/vinussabong.png"),
        headerM: require('@/assets/sabong/logo/VS.png'),
        sabongTitle: "SABONG",
        interRoomLogo: require('@/assets/sabong/logo/VINUS_SABONG.png'),
        interRoomTitle: 'VINUS SABONG',
        interRoomBanner: require('@/assets/sabong/banner/vinus.png'),
        interRoomBannerM: require('@/assets/sabong/logo/mobileinter.jpg'),
        introLogo: require('@/assets/sabong/logo/VS_logo.png'),
        isPagcor: true
    },
    "vsabongservice.com": {
        betLocale: {
            text: {
                meron: "red",
                wala: "blue",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#2056af"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "B",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/logo/vg_top.png'),
        pcHeaderTitle: require("@/assets/logo/VG.png"),
        headerM: require('@/assets/logo/vg_top.png'),
        sabongTitle: "SABONG",
        interRoomLogo: require('@/assets/sabong/logo/VINUS_SABONG.png'),
        interRoomTitle: 'VINUS GAMING',
        interRoomBanner: require('@/assets/logo/VGbanner_nn.png'),
        interRoomBannerM: require('@/assets/logo/VGbannerm_lc.png'),
        introLogo: require('@/assets/logo/vg_login.png'),
        isPagcor: false
    },
    "vinus-inter.com": {
        betLocale: {
            text: {
                meron: "red",
                wala: "blue",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#2056af"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "B",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/logo/vg_top.png'),
        pcHeaderTitle: require("@/assets/logo/VG.png"),
        headerM: require('@/assets/logo/vg_top.png'),
        sabongTitle: "SABONG",
        interRoomLogo: require('@/assets/logo/vg_login.png'),
        interRoomTitle: 'VINUS GAMING',
        interRoomBanner: require('@/assets/logo/VGbanner_nn.png'),
        interRoomBannerM: require('@/assets/logo/VGbannerm_lc.png'),
        introLogo: require('@/assets/logo/vg_login.png'),
        isPagcor: false
    },
    "vinusinter.com": {
        betLocale: {
            text: {
                meron: "red",
                wala: "blue",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#2056af"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "B",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/logo/vg_top.png'),
        pcHeaderTitle: require("@/assets/logo/VG.png"),
        headerM: require('@/assets/logo/vg_top.png'),
        sabongTitle: "SABONG",
        interRoomLogo: require('@/assets/logo/vg_login.png'),
        interRoomTitle: 'VINUS GAMING',
        interRoomBanner: require('@/assets/logo/VGbanner_nn.png'),
        interRoomBannerM: require('@/assets/logo/VGbannerm_lc.png'),
        introLogo: require('@/assets/logo/vg_login.png'),
        isPagcor: false
    },
    "kinggalaxyinteractive.com": {
        betLocale: {
            text: {
                meron: "red",
                wala: "blue",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#2056af"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "B",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/sabong/logo/logo_KGI.png'),
        pcHeaderTitle: require('@/assets/sabong/logo/logo_text_KGI.png'),
        headerM: require('@/assets/sabong/logo/logo_KGI.png'),
        sabongTitle: "SABONG",
        interRoomLogo: require('@/assets/sabong/logo/logo_KGI.png'),
        interRoomTitle: 'KING GALAXY INTERACTIVE',
        interRoomBanner: require('@/assets/sabong/banner/banner_KGI.png'),
        interRoomBannerM: require('@/assets/sabong/banner/mobile_banner_KGI.png'),
        introLogo: require('@/assets/sabong/logo/logo_KGI.png'),
        isPagcor: false
    },
    "kg-365.com": {
        betLocale: {
            text: {
                meron: "red",
                wala: "blue",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#2056af"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "B",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/sabong/logo/logo_KGI.png'),
        pcHeaderTitle: require('@/assets/sabong/logo/logo_text_KGI.png'),
        headerM: require('@/assets/sabong/logo/logo_KGI.png'),
        sabongTitle: "SABONG",
        interRoomLogo: require('@/assets/sabong/logo/logo_KGI.png'),
        interRoomTitle: 'KING GALAXY INTERACTIVE',
        interRoomBanner: require('@/assets/sabong/banner/banner_KGI.png'),
        interRoomBannerM: require('@/assets/sabong/banner/mobile_banner_KGI.png'),
        introLogo: require('@/assets/sabong/logo/logo_KGI.png'),
        isPagcor: false
    },    
    // cockxing 임시 도메인
    "vinuscockxing.com": {
        betLocale: {
            text: {
                meron: "red",
                wala: "white",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#fff"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "W",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/sabong/logo/logo_cockxing_small.png'),
        pcHeaderTitle: require('@/assets/sabong/logo/logo_text_cockxing.png'),
        headerM: require('@/assets/sabong/logo/mobile_logo_cockxing.png'),
        sabongTitle: "COCKXING",
        interRoomLogo: require('@/assets/sabong/logo/logo_cockxing_small.png'),
        interRoomTitle: 'Cockxing',
        interRoomBanner: require('@/assets/sabong/banner/banner_cockxing.png'),
        interRoomBannerM: require('@/assets/sabong/banner/mobile_banner_cockxing.png'),
        introLogo: require('@/assets/sabong/logo/logo_cockxing.png'),
        isPagcor: false
    },
    // 로컬서버 확인 할때 사용
    "127.0.0.1": {
        betLocale: {
            text: {
                meron: "red",
                wala: "white",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#fff"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "W",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/sabong/logo/logo_cockxing_small.png'),
        pcHeaderTitle: require('@/assets/sabong/logo/logo_text_cockxing.png'),
        headerM: require('@/assets/sabong/logo/mobile_logo_cockxing.png'),
        sabongTitle: "COCKXING",
        interRoomLogo: require('@/assets/sabong/logo/logo_cockxing_small.png'),
        interRoomTitle: 'Cockxing',
        interRoomBanner: require('@/assets/sabong/banner/banner_cockxing.png'),
        interRoomBannerM: require('@/assets/sabong/banner/mobile_banner_cockxing.png'),
        introLogo: require('@/assets/sabong/logo/logo_cockxing.png'),
        isPagcor: false
    },
    // 로컬서버 확인 할때 사용
    "localhost": {
        betLocale: {
            text: {
                meron: "red",
                wala: "blue",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#2056af"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "B",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/sabong/logo/VS.png'),
        pcHeaderTitle: require("@/assets/sabong/logo/vinussabong.png"),
        headerM: require('@/assets/sabong/logo/VS.png'),
        sabongTitle: "SABONG",
        interRoomLogo: require('@/assets/sabong/logo/VINUS_SABONG.png'),
        interRoomTitle: 'VINUS SABONG',
        interRoomBanner: require('@/assets/sabong/banner/vinus.png'),
        interRoomBannerM: require('@/assets/sabong/logo/mobileinter.jpg'),
        introLogo: require('@/assets/sabong/logo/VS_logo.png'),
        isPagcor: true
    },
    // 개발서버 확인 할때 사용
    "111.92.245.98": {
        betLocale: {
            text: {
                meron: "red",
                wala: "white",
                draw: "draw",
                cancel: "cancel"
            },
            color: {
                meron: {color : "#e82121"},
                wala: {color : "#fff"},
            },
            bgcolor: {
                meron: {backgroundColor : "#e82121"},
                wala: {backgroundColor : "#2056af"},
            },
            roadLogo: {
                meron: "R",
                wala: "W",
                draw: "D",
                cancel: "C"
            }
        },
        pcHeader: require('@/assets/sabong/logo/logo_cockxing_small.png'),
        pcHeaderTitle: require('@/assets/sabong/logo/logo_text_cockxing.png'),
        headerM: require('@/assets/sabong/logo/mobile_logo_cockxing.png'),
        sabongTitle: "COCKXING",
        interRoomLogo: require('@/assets/sabong/logo/logo_cockxing_small.png'),
        interRoomTitle: 'Cockxing',
        interRoomBanner: require('@/assets/sabong/banner/banner_cockxing.png'),
        interRoomBannerM: require('@/assets/sabong/banner/mobile_banner_cockxing.png'),
        introLogo: require('@/assets/sabong/logo/logo_cockxing.png'),
        isPagcor: false
    },
}


export const SABONG_GAME_TYPE_IDX = {
    'pcc': 0,
    'vinusPcc': 0,
    'inter': 1,
    'wsa': 2,
    'one': 3,
    'nca': 4
}

export const SABONG_GAME_TYPE = ['pcc', 'inter', 'wsa', 'one', 'nca'];

export const GAME_TOPIC_ENV ={
    'baccarat': 'live_bacc',
    'dragonTiger': '',
    'teenpatti': '',
    'holdem': '',
    'roulette': '',
    'sicbo': ''
}

// 바카라 배당
export const BACCARAT_PAYOUT = {
    'p': 0.95,
    'pp': 11,
    'tie': 8,
    'b': 1,
    'bp': 11
}

// 바카라 게임 결과
export const BACCARAT_RESULT_IDX = {
    0: "Player Wins!",
    1: "Banker Wins!",
    2: "Player Pair",
    3: "Banker Pair",
    4: "Tie"
};

//국기
export const FLAG_IMG = {
    'UNK': 'flag-english.png',
    'USD': 'flag-english.png',
    'CNY': 'flag-chinese.png',
    'JPY': 'flag-japanese.png',
    'KOR': 'flag-korean.png',
    'VSP': 'flag-korean.png',
    'KRW': 'flag-korean.png',
    'EUR': 'flag-spanish.png',
    'THB': 'flag-thai.png',
    'VND': 'flag-vietnamese.png',
    'INR': 'flag-india.png',
    'PHP': 'php.jpg'
}

//플레이어 등급
export const PLAYER_GRADE_IMG = {
    '0': 'rank-normal.png',
    '1': 'rank-vip.png',
    '2': 'rank-rvip.png',
    '3': 'rank-vvip.png',
    '4': 'rankg-svip.png'
};

//플레이어 등급
export const PLAYER_GRADE_IMG_TX = {
    'NORMAL': 'rank-normal.png',
    'VIP': 'rank-vip.png',
    'RVIP': 'rank-rvip.png',
    'VVIP': 'rank-vvip.png',
    'SVIP': 'rankg-svip.png'
};

//모바일화면 언어팩 국기 이미지
export const MOBILE_LANG_FLAG_IMG = {
    'en': 'flag-english.png',
    'ko': 'flag-korean.png',
    'ch': 'flag-chinese.png',
    'ja': 'flag-japanese.png',
    'hi': 'flag-hindi.png',
    'sp': 'flag-spanish.png',
    'th': 'flag-thai.png',
    'vt': 'flag-vietnamese.png'
};


export const BACCARAT_SCORE_IDX = {
    0: 'p',
    1: 'b',
    2: 'pp',
    3: 'bp',
    4: 'tie'
}
// 방 상태
export const ROOM_STATUS = {
    0: "ready",
    1: "gameStart",
    2: "BetEnd",
    3: "CardStart",
    4: "CardEnd",
    5: "ResultStart",
    6: "ResultEnd",
};
export const CARD_INFO_ARRAY = [
    "A","2","3","4","5","6","7","8","9","10","J","Q","K",
    "A","2","3","4","5","6","7","8","9","10","J","Q","K",
    "A","2","3","4","5","6","7","8","9","10","J","Q","K",
    "A","2","3","4","5","6","7","8","9","10","J","Q","K","K"
];
export const CARD_MARK_ARRAY = [
    "♠","♠","♠","♠","♠","♠","♠","♠","♠","♠","♠","♠",
    "♠","♣","♣","♣","♣","♣","♣","♣","♣","♣","♣","♣",
    "♣","♣", "◆","◆","◆","◆","◆","◆","◆","◆","◆","◆","◆",
    "◆","◆","♥","♥","♥","♥","♥","♥","♥","♥","♥","♥","♥","♥","♥","♥"
];
export const CARD_INFO_ARRAY_VALUE = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 0, 0,
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 0, 0,
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 0, 0,
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 0, 0, 0,0
]

// vinus axios 요청 에러코드
export const ERR_CODE = {
    //common
    '-10000001': 'PUBLISHER-PRIVATE-KEY Error',
    '-10000002':  'CLIENT-VERSION-TYPE Error',
    '-10000003':  'CLIENT-GAME-ID Error',
    '-10000004': 'json data does not exist.',
    '10000000': 'Normal',
    '10000001': 'Incorrect parameter information',
    '10000002': 'No session was created. Please try logging in again.',
    '10000003': 'Database execution error',
    '10000004': 'Bad request. Please contact system admin.',
    '10000005': 'PHP INPUT value does not exist.',
    '10000006': 'A duplicate session has been created. Please try logging in again.',
    //auth
    '200000001': 'Block and unsubscribe users',
    '200000002': 'ID or password is not correct.',
    '200000003': 'UUID does not exist.',
    '200000004': 'User DB creation error',
    '200000005': 'User product information generation error',
    '200000006': 'User card information generation error',
    '200000007': 'User deck information creation error',
    '200000008': 'Dealer ranking creation failure',
    '200000009': 'Users exist when creating an account.',
    '200000010': 'Agent information does not exist.',
    //shop
    '300000001': 'Invalid shop index',
    '300000002': 'The product classification is wrong.',
    '300000003': 'It is not for sale.',
    '300000004': 'The purchase amount is insufficient.',
    '300000006': 'The sales type does not exist.',
    //goods
    '400000001': 'Failed to read user goods information from database.',
    '400000002': 'The amount information does not exist.',
    '400000003': 'Failed to update amount information',
    '400000004': 'No transaction data found.',
    '400000005': 'Transaction record value does not exist.',
    '400000006': 'In-game deposit refund of 0 or less',
    '400000007': 'The record value exists when refunding the in-game deposit, but it is not a deposit (bating amount).',
    '400000008': 'Betting and Deposit record values do not exist.',
    '110000001': 'The betting draw is in progress'
}

export const DEALER_INFO = {
    'colorType': {
        'a': '0',
        'b': '1',
        'c': '2',
        'd': '3'
    },
    'colorTypeEng': {
        '0': 'a',
        '1': 'b',
        '2': 'c',
        '3': 'd'
    },
    'info': [
        {
            group: 'alice',
            type: 'a',
            src:  require("../assets/character/list-alice-1.png"),
            mobile: require("../assets/character/mobile/list-alice-1-m.png"),
            previewW: require("../assets/character/preview/alice-1.png"),
            previewM: require("../assets/character/mobile/preview/alice-1-m.png"),
            order: 0,
        },
        {
            group: 'alice',
            type: 'b',
            src:  require("../assets/character/list-alice-2.png"),
            mobile: require("../assets/character/mobile/list-alice-2-m.png"),
            previewW: require("../assets/character/preview/alice-2.png"),
            previewM: require("../assets/character/mobile/preview/alice-2-m.png"),
            order: 1,
        },
        {
            group: 'alice',
            type: 'c',
            src:  require("../assets/character/list-alice-3.png"),
            mobile: require("../assets/character/mobile/list-alice-3-m.png"),
            previewW: require("../assets/character/preview/alice-3.png"),
            previewM: require("../assets/character/mobile/preview/alice-3-m.png"),
            order: 2,
        },
        {
            group: 'alice',
            type: 'd',
            src:  require("../assets/character/list-alice-4.png"),
            mobile: require("../assets/character/mobile/list-alice-4-m.png"),
            previewW: require("../assets/character/preview/alice-4.png"),
            previewM: require("../assets/character/mobile/preview/alice-4-m.png"),
            order: 3,
        },
        {
            group: 'angela',
            type: 'a',
            src: require("../assets/character/list-angela-1.png"),
            mobile: require("../assets/character/mobile/list-angela-1-m.png"),
            previewW: require("../assets/character/preview/angela-1.png"),
            previewM: require("../assets/character/mobile/preview/angela-1-m.png"),
            order: 0,
        },
        {
            group: 'angela',
            type: 'b',
            src: require("../assets/character/list-angela-2.png"),
            mobile: require("../assets/character/mobile/list-angela-2-m.png"),
            previewW: require("../assets/character/preview/angela-2.png"),
            previewM: require("../assets/character/mobile/preview/angela-2-m.png"),
            order: 1
        },
        {
            group: 'angela',
            type: 'c',
            src: require("../assets/character/list-angela-3.png"),
            mobile: require("../assets/character/mobile/list-angela-3-m.png"),
            previewW: require("../assets/character/preview/angela-3.png"),
            previewM: require("../assets/character/mobile/preview/angela-3-m.png"),
            order: 2
        },
        {
            group: 'angela',
            type: 'd',
            src: require("../assets/character/list-angela-4.png"),
            mobile: require("../assets/character/mobile/list-angela-4-m.png"),
            previewW: require("../assets/character/preview/angela-4.png"),
            previewM: require("../assets/character/mobile/preview/angela-4-m.png"),
            order: 3
        },
        {
            group: 'jule',
            type: 'a',
            src:  require("../assets/character/list-jule-1.png"),
            mobile:  require("../assets/character/mobile/list-jule-1-m.png"),
            previewW:  require("../assets/character/preview/jule-1.png"),
            previewM:  require("../assets/character/mobile/preview/jule-1-m.png"),
            order: 0
        },
        {
            group: 'jule',
            type: 'b',
            src:  require("../assets/character/list-jule-2.png"),
            mobile:  require("../assets/character/mobile/list-jule-2-m.png"),
            previewW:  require("../assets/character/preview/jule-2.png"),
            previewM:  require("../assets/character/mobile/preview/jule-2-m.png"),
            order: 1
        },
        {
            group: 'jule',
            type: 'c',
            src:  require("../assets/character/list-jule-3.png"),
            mobile:  require("../assets/character/mobile/list-jule-3-m.png"),
            previewW:  require("../assets/character/preview/jule-3.png"),
            previewM:  require("../assets/character/mobile/preview/jule-3-m.png"),
            order: 2
        },
        {
            group: 'jule',
            type: 'd',
            src:  require("../assets/character/list-jule-4.png"),
            mobile:  require("../assets/character/mobile/list-jule-4-m.png"),
            previewW:  require("../assets/character/preview/jule-4.png"),
            previewM:  require("../assets/character/mobile/preview/jule-4-m.png"),
            order: 3
        },
        {
            group: 'lisa',
            type: 'a',
            src:  require("../assets/character/list-lisa-1.png"),
            mobile:  require("../assets/character/mobile/list-lisa-1-m.png"),
            previewW:  require("../assets/character/preview/lisa-1.png"),
            previewM:  require("../assets/character/mobile/preview/lisa-1-m.png"),
            order: 0
        },
        {
            group: 'lisa',
            type: 'b',
            src:  require("../assets/character/list-lisa-2.png"),
            mobile:  require("../assets/character/mobile/list-lisa-2-m.png"),
            previewW:  require("../assets/character/preview/lisa-2.png"),
            previewM:  require("../assets/character/mobile/preview/lisa-2-m.png"),
            order: 1
        },
        {
            group: 'lisa',
            type: 'c',
            src:  require("../assets/character/list-lisa-3.png"),
            mobile:  require("../assets/character/mobile/list-lisa-3-m.png"),
            previewW:  require("../assets/character/preview/lisa-3.png"),
            previewM:  require("../assets/character/mobile/preview/lisa-3-m.png"),
            order: 2
        },
        {
            group: 'lisa',
            type: 'd',
            src:  require("../assets/character/list-lisa-4.png"),
            mobile:  require("../assets/character/mobile/list-lisa-4-m.png"),
            previewW:  require("../assets/character/preview/lisa-4.png"),
            previewM:  require("../assets/character/mobile/preview/lisa-4-m.png"),
            order: 3
        },
        {
            group: 'lyn',
            type: 'a',
            src:  require("../assets/character/list-lyn-1.png"),
            mobile:  require("../assets/character/mobile/list-lyn-1-m.png"),
            previewW:  require("../assets/character/preview/lyn-1.png"),
            previewM:  require("../assets/character/mobile/preview/lyn-1-m.png"),
            order: 0
        },
        {
            group: 'lyn',
            type: 'b',
            src:  require("../assets/character/list-lyn-2.png"),
            mobile:  require("../assets/character/mobile/list-lyn-2-m.png"),
            previewW:  require("../assets/character/preview/lyn-2.png"),
            previewM:  require("../assets/character/mobile/preview/lyn-2-m.png"),
            order: 1
        },
        {
            group: 'lyn',
            type: 'c',
            src:  require("../assets/character/list-lyn-3.png"),
            mobile:  require("../assets/character/mobile/list-lyn-3-m.png"),
            previewW:  require("../assets/character/preview/lyn-3.png"),
            previewM:  require("../assets/character/mobile/preview/lyn-3-m.png"),
            order: 2
        },
        {
            group: 'lyn',
            type: 'd',
            src:  require("../assets/character/list-lyn-4.png"),
            mobile:  require("../assets/character/mobile/list-lyn-4-m.png"),
            previewW:  require("../assets/character/preview/lyn-4.png"),
            previewM:  require("../assets/character/mobile/preview/lyn-4-m.png"),
            order: 3
        },
        {
            group: 'olivia',
            type: 'a',
            src:  require("../assets/character/list-olivia-1.png"),
            mobile:  require("../assets/character/mobile/list-olivia-1-m.png"),
            previewW:  require("../assets/character/preview/olivia-1.png"),
            previewM:  require("../assets/character/mobile/preview/olivia-1-m.png"),
            order: 0
        },
        {
            group: 'olivia',
            type: 'b',
            src:  require("../assets/character/list-olivia-2.png"),
            mobile:  require("../assets/character/mobile/list-olivia-2-m.png"),
            previewW:  require("../assets/character/preview/olivia-2.png"),
            previewM:  require("../assets/character/mobile/preview/olivia-2-m.png"),
            order: 1
        },
        {
            group: 'olivia',
            type: 'c',
            src:  require("../assets/character/list-olivia-3.png"),
            mobile:  require("../assets/character/mobile/list-olivia-3-m.png"),
            previewW:  require("../assets/character/preview/olivia-3.png"),
            previewM:  require("../assets/character/mobile/preview/olivia-3-m.png"),
            order: 2
        },
        {
            group: 'olivia',
            type: 'd',
            src:  require("../assets/character/list-olivia-4.png"),
            mobile:  require("../assets/character/mobile/list-olivia-4-m.png"),
            previewW:  require("../assets/character/preview/olivia-4.png"),
            previewM:  require("../assets/character/mobile/preview/olivia-4-m.png"),
            order: 3
        },
    ],
    'thumInfo': {
        'alice': [
            {
                group: 'alice',
                type: 'a',
                src:  require("../assets/character/thum/alice-thum-1.png")
            },
            {
                group: 'alice',
                type: 'b',
                src:  require("../assets/character/thum/alice-thum-2.png")
            },
            {
                group: 'alice',
                type: 'c',
                src:  require("../assets/character/thum/alice-thum-3.png")
            },
            {
                group: 'alice',
                type: 'd',
                src:  require("../assets/character/thum/alice-thum-4.png")
            },
        ],
        'angela': [
            {
                group: 'angela',
                type: 'a',
                src:  require("../assets/character/thum/angela-thum-1.png")
            },
            {
                group: 'angela',
                type: 'b',
                src:  require("../assets/character/thum/angela-thum-2.png")
            },
            {
                group: 'angela',
                type: 'c',
                src:  require("../assets/character/thum/angela-thum-3.png")
            },
            {
                group: 'angela',
                type: 'd',
                src:  require("../assets/character/thum/angela-thum-4.png")
            },
        ],
        'jule': [
            {
                group: 'jule',
                type: 'a',
                src:  require("../assets/character/thum/jule_a.png")
            },
            {
                group: 'jule',
                type: 'b',
                src:  require("../assets/character/thum/jule_b.png")
            },
            {
                group: 'jule',
                type: 'c',
                src:  require("../assets/character/thum/jule_c.png")
            },
            {
                group: 'jule',
                type: 'd',
                src:  require("../assets/character/thum/jule_d.png")
            },
        ],
        'lisa': [
            {
                group: 'lisa',
                type: 'a',
                src:  require("../assets/character/thum/lisa-thum-1.png")
            },
            {
                group: 'lisa',
                type: 'b',
                src:  require("../assets/character/thum/lisa-thum-2.png")
            },
            {
                group: 'lisa',
                type: 'c',
                src:  require("../assets/character/thum/lisa-thum-3.png")
            },
            {
                group: 'lisa',
                type: 'd',
                src:  require("../assets/character/thum/lisa-thum-4.png")
            },
        ],
        'lyn': [
            {
                group: 'lyn',
                type: 'a',
                src:  require("../assets/character/thum/lyn-thum-1.png")
            },
            {
                group: 'lyn',
                type: 'b',
                src:  require("../assets/character/thum/lyn-thum-2.png")
            },
            {
                group: 'lyn',
                type: 'c',
                src:  require("../assets/character/thum/lyn-thum-3.png")
            },
            {
                group: 'lyn',
                type: 'd',
                src:  require("../assets/character/thum/lyn-thum-4.png")
            },
        ],
        'olivia': [
            {
                group: 'olivia',
                type: 'a',
                src:  require("../assets/character/thum/olivia-thum-1.png")
            },
            {
                group: 'olivia',
                type: 'b',
                src:  require("../assets/character/thum/olivia-thum-2.png")
            },
            {
                group: 'olivia',
                type: 'c',
                src:  require("../assets/character/thum/olivia-thum-3.png")
            },
            {
                group: 'olivia',
                type: 'd',
                src:  require("../assets/character/thum/olivia-thum-4.png")
            },
        ],
    }
}

export const CURRENCY_SYMBOLS = {
    'AED': 'د.إ',	
    'AFN': '؋',
    'ALL': 'L',
    'AMD': '֏',
    'ANG': 'ƒ',	
    'AOA': 'Kz',	
    'ARS': '$',
    'AUD': 'A$',	
    'AWG': 'ƒ',
    'AZN': '₼',	
    'BAM': 'KM',	
    'BBD': 'Bds$',
    'BDT': '৳',
    'BGN': 'Лв.',	
    'BHD': 'BD',
    'BIF': 'FBu',	
    'BMD': '$',
    'BND': 'B$',	
    'BOB': 'Bs',
    'BRL': 'R$',
    'BSD': 'B$',	
    'BTN': 'Nu.',
    'BWP': 'P',	
    'BYN': 'Br',	
    'BYR': 'p',
    'BZD': '$',	
    'CAD': '$',	
    'CDF': 'FC',	
    'CHF': 'Fr.',	
    'CLF': 'UF',
    'CLP': '$',	
    'CNH': '¥',
    'CNY': '¥',	
    'COP': '$',	
    'CRC': '₡',
    'CUC': '$',
    'CUP': '₱',	
    'CVE': '$',
    'CZK': '$',	
    'DJF': 'Fdj',	
    'DKK': 'Kr.',	
    'DOP': 'RD$',	
    'DZD': 'دج',	
    'EGP': '£',	
    'ERN': 'Nkf',	
    'ETB': 'Br',	
    'EUR': '€',	
    'FJD': '$',	
    'FKP': '£',
    'GBP': '£',	
    'GEL': '₾',	
    'GGP': '£',	
    'GHS': '¢',	
    'GIP': '£',	
    'GMD': 'D',	
    'GNF': 'FG',	
    'GTQ': 'Q',
    'GYD': '$',	
    'HKD': '$',	
    'HNL': 'L',	
    'HRK': 'kn',	
    'HTG': 'G',	
    'HUF': 'Ft',	
    'IDR': 'Rp',
    'ILS': '₪',
    'IMP': '£',
    'INR': '₹',	
    'IQD': 'د.ع',	
    'IRR': '﷼',	
    'ISK': 'kr',	
    'JEP': '£',	
    'JMD': 'J$',	
    'JOD': 'د.أ',	
    'JPY': '¥',	
    'KES': 'KSh',	
    'KGS': 'лв',	
    'KHR': '៛',	
    'KMF': 'CF',	
    'KPW': '₩',	
    'KRW': '₩',	
    'KWD': 'ك',	
    'KYD': '$',
    'KZT': 'лв',
    'LAK': '₭',	
    'LBP': '£',	
    'LKR': '₨',	
    'LRD': '$',	
    'LSL': 'L',
    'LYD': 'LD',	
    'MAD': 'DH',	
    'MDL': 'L',	
    'MGA': 'Ar',	
    'MKD': 'ден',	
    'MMK': 'K',	
    'MNT': '₮',	
    'MOP': 'MOP$',
    'MRU': 'UM',
    'MUR': '₨',
    'MVR': 'Rf',
    'MWK': 'MK',
    'MXN': '$',
    'MYR': 'RM',
    'MZN': 'MT',
    'NAD': '$',
    'NGN': '₦',
    'NIO': 'C$',
    'NOK': 'kr',
    'NPR': 'रू',
    'NZD': '$',
    'OMR': '﷼',
    'PAB': 'B/.',
    'PEN': 'S/.',
    'PGK': 'K',
    'PHP': '₱',
    'PKR': '₨',
    'PLN': 'zł',
    'PYG': 'Gs',
    'QAR': '﷼',
    'RON': 'lei',
    'RSD': 'Дин.',
    'RUB': '₽',
    'RWF': 'RF',
    'SAR': '﷼',
    'SBD': '$',
    'SCR': 'SR',
    'SDG': 'ج.س',
    'SEK': 'kr',
    'SGD': '$',
    'SHP': '£',
    'SLL': 'Le',
    'SOS': 'S',
    'SRD': '$',
    'SSP': '£',
    'STN': 'Db',
    'SVC': '$',
    'SYP': '£',
    'SZL': 'L',
    'THB': '฿',
    'TJS': 'SM',
    'TMT': 'm',
    'TND': 'د.ت',
    'TOP': 'T$',
    'TRY': '₺',
    'TTD': 'TT$',
    'TWD': 'NT$',
    'TZS': 'TSh',
    'UAH': '₴',
    'UGX': 'USh',
    'USD': '$',
    'UYU': '$U',
    'UZS': 'лв',
    'VEF': 'Bs',
    'VES': 'Bs.',
    'VND': '₫',
    'VUV': 'VT',
    'WST': 'WS$',
    'XAF': 'FCFA',
    'XCD': '$',
    'XDR': 'SDR',
    'XOF': 'CFA',
    'XPF': '₣',
    'YER': '﷼',
    'ZAR': 'R',
    'ZMW': 'K',
    'ZWL': '$'
};
