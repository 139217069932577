<template>
    <div
        v-if="!isShowConfirm && !isShowPreview"
        class="shopWrap">
        <div class="header">
            <div class="shop_tab">
                <div
                    v-bind:class="{ 'selected': tabKey == '1' }"
                    class="tab"
                    v-on:click.stop="clickTab('1')"> <!-- 탭 활성 -->
                    <div class="tab_text">
                        {{ $t('dealerSkin') }}
                    </div>
                </div>
                <div class="tab lock">
                    <div class="tab_text">
                        {{ $t('cardSkin') }}
                    </div>
                </div>
                <div class="tab lock">
                    <div class="tab_text">
                        {{ $t('dealerProfile') }}
                    </div>
                </div>
            </div>
            <div class="balanceWrap box">
                <div
                    v-number-format="loginUser['balance']"
                    class="balance">
                </div>
                <div class="currency">
                    {{ loginUser['currency'] }}
                </div>
            </div>
        </div>
        <div class="body box">
            <div
                v-for="(dealer, key) in dealers"
                v-bind:key="key"
                class="dealerWrap">
                <div class="back">
                    <img v-bind:src="getDealerImage(dealer)"/>
                    <span class="restDate">
                        {{ checkDealer(dealer)['period'] }}
                    </span>
                    <div
                        class="preview"
                        v-on:click.stop="showPreviewModal(dealer)">
                        <img src="../../assets/shop/btn-minmax.png"/>
                    </div>
                </div>
                <div
                    v-bind:class="{ 'disabled': checkDealer(dealer)['isBuy'] }"
                    class="buyBtn">
                    <button
                        v-bind:class="checkDealer(dealer)['key']"
                        v-on:click.stop="showConfirmModal(dealer)">{{ $t(checkDealer(dealer)['key']) }}</button>
                </div>
            </div>
        </div>
    </div>

    <div
        v-if="isShowConfirm"
        class="modalBody">
        <div class="dealerWrap">
            <div class="back">
                <img v-bind:src="selectedDealer['img']"/>
            </div>
            <div>
                {{ selectedDealer['name'] }}
            </div>
            <div>
                (x{{ selectedDealer['expiry'] }} Days)
            </div>
            <div class="priceTag">
                <div class="text">
                    {{ $t('price') }} :
                </div>
                <div
                    v-number-format="selectedDealer['sell_price']"
                    class="price">
                </div>
            </div>
            <div class="info">
                {{ $t('would') }}
            </div>
        </div>
        <div class="buttonInterface">
            <div
                class="cancelBtn"
                v-on:click.stop="buyCancel()">
                <button>{{ $t('cancel') }}</button>
            </div>
            <div
                class="buyBtn"
                v-on:click.stop="buyDealer()">
                <button>{{ $t('confirm') }}</button>
            </div>
        </div>
    </div>

    <div
        v-if="isShowPreview"
        class="modalBg">
        <div
            class="closeBtn menu"
            v-on:click.stop="hidePreviewModal()">
            <img
                height="36"
                src="../../assets/mobile/btn-close.png"
                width="36"/>
        </div>
        <div class="modalLog">
            <div class="modalInner">
                <div
                    v-bind:class="{ 'big': selectedDealer['comment'] != 'jule'}"
                    class="dealerImage">
                    <img v-bind:src="selectedDealer['img']"/>
                </div>
            </div>
        </div>
        <div class="thums">
            <div
                v-for="(thum, idx) in selectedThums"
                v-bind:key="idx"
                v-bind:class="{ 'selected': thum['group'] == selectedDealer['comment'] && selectThumType == thum['type'] }"
                class="thum"
                v-on:click.stop="changeThum(thum)">
                <img v-bind:src="thum['src']"/>
            </div>
            <div
                v-bind:class="{ 'disabled': checkDealer(selectedDealer, true)['isBuy'] }"
                class="buyBtn">
                <button
                    v-bind:class="checkDealer(selectedDealer, true)['key']"
                    v-on:click.stop="showConfirmModal(selectedDealer, true)">{{ $t(checkDealer(selectedDealer, true)['key']) }}</button>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import moment from "moment";
    import { loginUser } from "@/js/reactive";
    import { setNumberFormat, isMobile } from "@/js/utils";
    import { DEALER_INFO, colorTypeEng } from "@/js/const";

    export default defineComponent({
        data(){
            return {
                dealers: [],
                myDealers: {},
                selectedDealer: {},
                selectedThums: [],
                selectThumType: 'a',
                DEALER_INFO: DEALER_INFO,
                loginUser: loginUser,
                isShowConfirm: false,
                isShowPreview: false,
                equipDealer: {},
                tabKey: 1,
            }
        },
        beforeMount() {
            this.getMyDealerList();
            this.getEquipDealer();
            this.getDealerInfo();
        },
        methods: {
            isMobile,
            hidePreviewModal(){
                this.isShowPreview = false;
                this.$emit('showPreview', false);
                this.selectThumType = 'a';
                this.$emit('showCloseBtn', true);
            },
            clickTab(key){
                this.tabKey = key;
            },
            async getDealerInfo(){
                const res = await this.apis.getShopInfo();
                this.dealers = res['decrypt_data']['dealers'];
                this.dealers.splice(this.dealers.findIndex(dealer => dealer['comment'] == 'victoria'), 1);
            },
            async updateEquipStatus(dealer, equipStatus){
                console.log(this.checkDealer(dealer)['key'] == 'equip' ? 'Y' : 'N');
                const res = await this.apis.updateEquipStatus({
                    buyIndex: dealer['index'],
                    colorType: DEALER_INFO['colorType'][this.selectThumType],
                    useYn: this.checkDealer(dealer)['key'] == 'equip' ? 'Y' : 'N'
                });
                await this.getMyDealerList();
                await this.getEquipDealer();
            },
            async getEquipDealer(){
                const res = await this.apis.getEquipDealer();
                if(res?.['decrypt_data']?.['mounting'].length > 0){
                    this.equipDealer = res?.['decrypt_data']?.['mounting'][0];
                } else {
                    this.equipDealer = {
                        buy_index: 1001,
						...this.dealers.find(shop => shop['comment'] === 'lisa')
                    };
                }
            },
            changeThum(thum){
                this.selectThumType = thum['type'];
                this.selectedDealer = this.dealers.find(dealer => dealer['comment'] == thum['group']);
                this.selectedDealer['img'] = DEALER_INFO['info'].find(info => info['group'] === thum['group'] && info['type'] === thum['type'])[isMobile() ? 'previewM' : 'previewW'];
                this.selectedDealer['type'] = DEALER_INFO['colorType'][this.selectThumType];
            },
            getDealerImage(dealer){
                if(dealer['comment'] == 'victoria'){
                    return;
                }
                return DEALER_INFO['info'].find(info => info['group'] === dealer['comment'] && info['type'] === 'a')[isMobile() ? 'mobile' : 'src'];
            },
            async showConfirmModal(dealer){
                if(this.checkDealer(dealer)['isBuy']){
                    await this.updateEquipStatus(dealer);
                    return;
                }
                this.hidePreviewModal();
                this.selectedDealer = dealer;
                this.$emit('showCloseBtn', false);
                this.isShowConfirm = true;
                this.selectedDealer['img'] = DEALER_INFO['info'].find(info => info['group'] === dealer['comment'] && info['type'] === 'a')[isMobile() ? 'mobile' : 'src'];
            },
            showPreviewModal(dealer){
                this.selectedDealer = dealer;
                this.selectedDealer['img'] = DEALER_INFO['info'].find(info => info['group'] === dealer['comment'] && info['type'] === 'a')[isMobile() ? 'previewM' : 'previewW'];
                this.selectedThums = DEALER_INFO['thumInfo'][dealer['comment']];
                this.isShowPreview = true;
                this.$emit('showPreview', true);
                this.$emit('showCloseBtn', false);
            },
            async buyDealer(){
                const res = await this.apis.buyDealer({
                    shop_index: this.selectedDealer['shopIndex']
                });
                await this.getMyDealerList();
                this.isShowConfirm = false;
                this.$emit('showCloseBtn', true);
            },
            buyCancel(){
                this.isShowConfirm = false;
                this.$emit('showCloseBtn', true);
            },
            async getMyDealerList() {
                const res = await this.apis.getMyDealerList();
                res['decrypt_data']['dealer'].forEach(dealer => {
                    this.myDealers[dealer['buy_index']] = {
                        'expiryTime': undefined
                    }
                    if(dealer['expiry_time']){
                        let expireTime = moment(dealer['expiry_time'], "YYYY-MM-DD HH:mm:ss");
                        let nowTime = moment();
                        this.myDealers[dealer['buy_index']]['expiryTime'] = Math.ceil(moment.duration(expireTime.diff(nowTime)).asDays()) || '30';
                    }
                });
            },
            // 구매한 딜러인지체크
            checkDealer(dealer, isPreview){
                let obj = {
                    'isBuy': false,
                    'period': undefined,
                    'key': 'buy'
                }
                if(this.myDealers?.[dealer['index']]){
                    obj['isBuy'] = true;
                    obj['period'] = this.myDealers[dealer['index']]['expiryTime'] ? `${this.$t('period')}: ${this.myDealers[dealer['index']]['expiryTime']} ${this.$t('days')}` : `${this.$t('period')}: 30 ${this.$t('days')}`;
                }

                if(dealer['comment'] == 'lisa'){
                    obj['isBuy'] = true;
                    obj['period'] = this.$t('unlimit');
                }
                if(obj['isBuy']) {
                    if (isPreview) {
                        obj['key'] = this.equipDealer['color_type'] == dealer['type'] && this.equipDealer['buy_index'] == dealer['index'] ? 'unequip' : 'equip';
                    } else {
                        obj['key'] = this.equipDealer['buy_index'] == dealer['index'] ? 'unequip' : 'equip';
                    }
                }
                this.$emit('getEquipDealer');
                return obj;
            }
        }
    });
</script>
<style scoped>
	.shopWrap_M{
		width: 100%;
		height: auto;
		color: #fff;
		font-size: 14px;
		background-color: #2e3033;
		padding: 0 8px;
	}
	.shopTopBox{
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: #2e3033;
		padding: 3%;
	}
	.shopTopBox > h1 {
		font-size: 22px;
	}
	.exitImg{
		position: absolute;
		right: 0;
		margin-right: 4%;
	}
	.exitImg img{
		width: 28px;
		height: auto;
	}
	.shopWrap_M .currencyBox{
		width: 202px;
		height: 44px;
		background-color: #1b1b1b;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		gap: 10px;
		padding: 0 11px;
		color: #fff;
		font-size: 18px;
		float: right;
		margin: 5px 0 15px 0;
	}
	.shopWrap_M .shopTab{
		width: 100%;
	}
	.shopWrap_M .shopTab ul{
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 9px;
		color: #ffffff;
		font-size: 14px;
	}
	.shopWrap_M .shopTab ul li{
		text-align: center;
		width: 111px;
		height: 40px;
		line-height: 40px;
		background-image: url("../../assets/shop/btn-gray-2.png");
		background-repeat: no-repeat;
		background-size: 100%;
	}
	.shopWrap_M .shopTab ul li.select{
		background-image: url("../../assets/shop/btn-blue-2.png");
		background-repeat: no-repeat;
		background-size: 100%;
	}

	.shopWrap_M .shopBody{
		width: 100%;
		height: 560px;
		overflow-y: auto;
		padding: 12px;
		background-color: #1b1b1b;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 11px 3%;
	}
	.shopWrap_M .shopBody .dealerWrap{
		width: 48.5%;
		height: 260px;
		border: 1px solid #484848;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 8px 12px 11px;
		gap: 2%;
	}
	.shopWrap_M .shopBody .dealerWrap .dealerImg{
		position: relative;
		width: 148px;
		height: 204px;
		background-image: url("../../assets/mobileBg/item-bg.png");
		background-repeat: no-repeat;
		background-size: 100%;
	}
	.shopWrap_M .shopBody .dealerWrap .dealerImg > img{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 95%;
	}
	.shopWrap_M .shopBody .dealerWrap .dealerImg .info{
		position: absolute;
		top: 5px;
		right: 5px;
	}
	.shopWrap_M .shopBody .dealerWrap .dealerImg .use{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 23px;
		background-color: rgba(20, 30, 109, 0.9);
		text-align: center;
		line-height: 23px;
		color: #fff;
		font-size: 18px;
		font-weight: 300;
	}

	/* buy Btn*/
	.shopWrap_M .shopBody .dealerWrap .buyBtn{
		width: 135px;
		height: 35px;
		background-image: linear-gradient(to bottom, #6696f6, #2352af);
		border-radius: 3px;
		border: 1px solid #e1e1e1;
		font-size: 17px;
		color: #fff;
		text-align: center;
		line-height: 35px;
	}
	.shopWrap_M .shopBody .dealerWrap .buyBtn.on{
		background-image: linear-gradient(to bottom, #b742e8, #6223af);
	}
	.shopWrap_M .shopBody .dealerWrap .buyBtn button{
		font-size: 17px;
		color: #fff;
	}
	.info > img{
		width: 20px;
	}

	.thums {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		gap: 5px;
		bottom: 0;
		right: 0;
		margin-right: 3%;
	}
	.thums .thum {
		display: flex;
		cursor: pointer;
	}
	.thum.selected {
		outline: 4px solid #5ccfff;
		border-radius: 10px;
	}
	.modalBg{
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: #3b3b3b;
		outline: 1px solid #bbbbbb;
	}
	.modalLog{
		position: relative;
		width: 35vw;
        height: 764px
	}
	.modalLogTopBox h1{
		font-size: 40px;
	}
	.closeBtn{
		position: absolute;
		right: 0;
		top: 0;
		margin-top: 3%;
		margin-right: 3%;
		font-size: 40px;
		z-index: 2;
	}
	.modalInner{
		display: flex;
		justify-content: center;
		width: inherit;
		height: auto;
	}
	.modalInner .dealerImage {
		/*width: 335px;
		top: 30px;*/
		position: relative;

		height: auto;
	}
	.modalInner .dealerImage.big {
		position: relative;
		top: 0;
	}
	.modalInner .dealerImage img {
		width: inherit;
		height: 764px;
	}
	.preview {
		width: 30px;
		height: 30px;
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 5px;
		margin-right: 5px;
		cursor: pointer;
	}
	.shopWrap {
		width: 100%;
	}
	.shopWrap .header {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 50px;
		color: white;
		font-weight: bold;
        padding: 0px 24px;
	}
    .shopWrap .header .shop_tab {
        display: flex;
		width: 100%;
        flex-direction: row;
        padding-left: 6px;
        gap: 10px;
    }
    .shopWrap .header .shop_tab .tab {
		cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 50px;
		background-image: url("../../assets/shop/btn-gray-2.png");
        position: relative;
    }
	.tab.selected {
		background-image: url("../../assets/shop/btn-blue-2.png") !important;
	}
	.tab.lock {
		cursor: not-allowed !important;
	}
    .shopWrap .header .shop_tab .tab .tab_text {
        font-size: 16px;
        color: #f1f1f1;
        z-index: 1;
        font-weight: 300;
		white-space: nowrap;
    }
	.shopWrap .header .balanceWrap {
		width: 244px;
		height: 44px;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: right;
		margin: 0 0 0 auto;
		font-size: 20px;
        color: #ffce70;
	}

	.balanceWrap .balance {
		margin-left: auto;
	}
    .balanceWrap .balance:lang(en){
        font-size: 18px;
    }

	.balanceWrap .currency {
		margin: 10px 10px;
	}

	.shopWrap .body {
		display: grid;
		grid-template-columns: repeat(3, 30%);
		justify-content: space-around;
		margin: 0 auto;
		width: 95%;
		height: 560px;
		overflow-y: scroll;
	}

	.shopWrap .body .dealerWrap {
		margin-top: 10px;
		margin-bottom: 10px;
		width: 100%;
		height: 415px;
		background-color: var(--modal-shop-dealer-background-color);
        border: solid 2px #484848;
	}

	.shopWrap .body .dealerWrap .back {
		display: flex;
		justify-content: center;
		width: 90%;
		height: 77%;
		background-image: linear-gradient(to bottom, #616668, #0f0f10);
		border: 2px solid var(--modal-shop-dealer-border-color);
		margin: 5px auto 0 auto;
		color: white;
		font-weight: bold;
        position: relative;
	}

	.modalBody .priceTag {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin: 2px auto 2px auto;
		max-width: 374px;
		font-weight: bold;
		font-size: 32px;
		height: 50px;
		background-image: radial-gradient(circle, var(--modal-shop-confirm-priceTag-color) 10%, rgba(0, 0, 0, 0) );
		color: var(--modal-shop-confirm-priceTag-font-color);
	}

	.priceTag .text {
		margin-right: 5px;
	}

	.modalBody {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: transparent;
		width: 100%;
		height: inherit;
	}
	.modalBody .info {
		color: white;
		margin: 0 auto;
		font-size: 26px;
		height: 29px;
		white-space: nowrap;
	}

	.modalBody .dealerWrap {
		width: 467px;
		height: 531px;
		background-color: var(--modal-shop-confirm-dealer-background-color);
		margin: 10px auto;
		text-align: center;
	}

	.modalBody .dealerWrap .back {
		width: 58%;
		background-image: linear-gradient(to bottom, #616668, #0f0f10);
		border: 2px solid var(--modal-shop-dealer-border-color);
		margin: 5px auto 2px auto;
	}

	.modalBody .dealerWrap div:nth-child(2),
	.modalBody .dealerWrap div:nth-child(3) {
		color: white;
		font-weight: bold;
		font-size: 16px;
	}

	.cancelBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 223px;
		height: 56px;
		margin: 10px auto 10px auto;
		background-color: var(--modal-shop-cancel-button-background-color);
		border: 1px solid var(--modal-shop-buy-button-outline-color);
	}

	.buyBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 223px;
		height: 56px;
		margin: 10px auto 10px auto;
		background-image: linear-gradient(to top, #2352af, #6696f6);
		border: 1px solid var(--modal-shop-buy-button-outline-color);
	}
	.buyBtn.disabled {
		cursor: not-allowed !important;
		background-color: var(--modal-shop-cancel-button-background-color);
		background-image: none;
	}

	.buyBtn button.equip {
		background-image: url('../../assets/shop/btn-green.png');
		background-size: 100% 100%;
		width: 222px;
		height: 56px;
	}
	.buyBtn button.unequip {
		background-image: url('../../assets/shop/btn-purple.png');
		background-size: 100% 100%;
		width: 222px;
		height: 56px;
	}
	button {
		width: inherit;
		height: inherit;
		color: white;
		font-size: 26px;
		text-shadow: 0 1px 0 #000;
        font-weight: 300;
	}
	.buttonInterface {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 15px;
		margin-top: 10px;
	}
    .back > span{
        position: absolute;
        font-weight: 300;
        font-size: 12px;
        left: 5px;
    }
    .back > span:lang(ko){
        top: 3px;
    }
</style>