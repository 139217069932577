<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>Daily Rewards</h1>
            <span
                class="exitImg"
                v-on:click.stop="$emit('showModal', '')">
                <img
                    alt="close"
                    src="@/assets/mobile/btn-close.png">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="dailyWrap">
                <img
                    alt="daily"
                    src="@/assets/mobile/baccarat-m-daily.png">
            </div>
        </div>
    </div>
</template>
<script>

</script>
<style scoped>
    .sideMenuBox{
        width: 100%;
        height: auto;
        padding-bottom: 30%;
        color: #fff;
        font-size: 14px;
        background-color: #2e3033;
        position: fixed;
        top: 0;
        z-index: 30;
    }
    .sideMenuTopBox{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #2e3033;
        padding: 3%;
    }
    .sideMenuTopBox > h1 {
        font-size: 20px;
    }
    .sideMenuInner{
        padding: 0 3%;
        background-color: #2e3033;
        height: 100%;
    }
    .exitImg{
        position: absolute;
        right: 0;
        margin-right: 4%;
        height: 28px;
    }
    .exitImg img{
        width: 28px;
        height: auto;
    }

    /*내용*/
    .dailyWrap{
        width: 100%;
        padding: 12px 4px;
        background-color: #1b1b1b;
        height: 750px;
        overflow-y: hidden;
    }
    .dailyWrap > img{
        width: 365px;
    }
</style>