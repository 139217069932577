export default {
  "baccarat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["百家乐"])},
  "teenpatti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丁帕蒂"])},
  "dragonTiger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["龙虎"])},
  "holdem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["霍尔德姆"])},
  "roulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮盘赌"])},
  "sicbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SICBO"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每一天"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铺子"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史记录"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["换桌"])},
  "betLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赌注限制"])},
  "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打开/关闭声音"])},
  "helpInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助/资讯"])},
  "lobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["门厅"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开销"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["闲聊"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["录像带"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
  "fx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FX(FX)"])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音乐"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高。"])},
  "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中间"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低洼"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助C/S"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择权"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注销"])},
  "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自习书"])},
  "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P.Pair公司"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["播放器"])},
  "tie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扳平比分"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行家"])},
  "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B.Pair公司"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["播放器"])},
  "banker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行家"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结余款"])},
  "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打赌"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额度"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["准备就绪"])},
  "betReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下注"])},
  "betEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赌局结束"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请等到下一轮开始。"])},
  "noGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有游戏列表。"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韩语"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日语"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印地语"])},
  "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汉文"])},
  "vt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["越南人"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰语"])},
  "sp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["西班牙语"])},
  "would": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要购买吗?"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间"])},
  "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无限制"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["几日"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有。"])},
  "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理ID"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账号"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤走"])},
  "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贝茨"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
  "win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIN(红酒)"])},
  "win2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["胜诉"])},
  "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["损耗损"])},
  "lose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失去"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拉平"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户信息"])},
  "gameId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏ID"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期(UTC)"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预防"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个"])},
  "historyWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*只能查看最后100行明细。"])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可查看的数据。"])},
  "noRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前没有活动桌。"])},
  "chatInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送聊天信息"])},
  "toList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要罗列的列表"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["闽"])},
  "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马克斯"])},
  "checkMinMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认投注限额。"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关机"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["装货"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["饭桌"])},
  "roundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圆ID"])},
  "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圆形"])},
  "betCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消赌注"])},
  "sabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙峰"])},
  "betUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下注撤销"])},
  "betRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上轮赌局设置"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回溯源"])},
  "rebet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忆起"])},
  "tutorialTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏方法"])},
  "tutorialTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["玩家转身"])},
  "tutorialTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行转身"])},
  "tutorial1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A卡用数字1计算，10、J、Q、K用0计算。剩下的卡是根据每张卡的所有权数来计算的。"])},
  "tutorial2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除10的单位外，考虑两个数的和。例如，不是5+8=13，而是3。"])},
  "tutorial3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本上Player和Banker各收到两张卡片。这时，如果两个数字的总和达到8或9，在自然条件下，将无法获得第三张牌，游戏将结束。"])},
  "tutorial4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果两者都不是自然牌，玩家将根据两张牌的总和来决定是否获得第三张牌。"])},
  "tutorial5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□选手编号合计为6或7时:不收牌（台灯）"])},
  "tutorial6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□选手编号之和为0-5时:获得第三张牌。"])},
  "tutorial7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果Player是Stand的话"])},
  "tutorial8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□玩家为6或7，银行为6或7时:不收卡"])},
  "tutorial9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□玩家为6或7，银行为0~5时:领取第3张卡片"])},
  "tutorial10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果玩家拿到第三张牌，"])},
  "tutorial11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□玩家为0-5，银行为0-2时:领取第3张卡片。"])},
  "tutorial12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□0~5，银行职员3时:领取第三张卡片（除第三张卡片8外）"])},
  "tutorial13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□0比5，银行为4时:领取第3张卡片（第3张卡片为0、1、8、9时除外）。"])},
  "tutorial14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□0~5，银行职员为5时:领取第3张卡片（第3张卡片为0、1、2、3、8、9时除外）。"])},
  "tutorial15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□0比5，银行为6时:领取第3张卡片（第3张卡片为0、1、2、3、4、5、8、9时除外）。"])},
  "tutorial16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□玩家为0~5，银行为7时:不收卡（台灯）"])},
  "tutorial17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据游戏规则，在2张或3张卡片中，接近9张的人获胜。"])},
  "tutorial18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虽然根据桌子的不同，击球量略有不同，但一般情况下，击球量会回到胜利的一方，如果以平局获胜，击球量会回到8倍。"])},
  "tutorial19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因为Banker的中奖率很高，所以如果Banker获胜，就要给房子支付5%的佣金。"])},
  "msgErr1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信件太长(最大长度为100字)"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全屏"])},
  "cam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摄像机"])},
  "lobbyExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出口"])},
  "equip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备齐备"])},
  "unequip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不均匀"])},
  "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额不足。"])},
  "dealerSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经销商皮肤"])},
  "cardSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纸牌护肤质"])},
  "dealerProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经销商简介"])},
  "beInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在使用"])},
  "dealerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经销商信息"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["菜单"])},
  "myInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的资讯"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设备"])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["讲台"])},
  "lastIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后一个IP"])},
  "lastDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后一个域"])},
  "loginTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上回"])},
  "registerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规定日期"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规定地址"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币"])},
  "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户ID"])},
  "agentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理ID"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纵断面"])},
  "dealerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经销商名称"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["喜欢"])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小费"])},
  "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏时间"])},
  "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成员"])},
  "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经销商"])},
  "meron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "wala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAW"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHITE"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "lastGameResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后一场比赛结果"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["米"])},
  "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W语言"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入"])},
  "memberList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成员名单"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等级"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-Lv-Lv。-Lv.-Lv.-Lv。"])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["换算盘"])},
  "deposit_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款"])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤资"])},
  "depositBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款"])},
  "transactionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易明细"])},
  "depositWarningTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费是换算时测定的，但换钱时不测定的。"])},
  "depositInfoTxt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可将SabongPoint(USD)转换为以太坊或BNB。"])},
  "depositInfoTxt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入要转换的值后，按Transfer按钮，适用符合换货要求时间的行情，转换为四峰积分。"])},
  "walletCreateTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙峰钱包的硬币通过钱包传送方式保管。可以追加数量。"])},
  "walletCreateTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["制作VINUSSABONG钱包"])},
  "depositSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款符号"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["符号"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价值"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["煤气"])},
  "copyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制地址"])},
  "myWalletAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司USD地址"])},
  "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索引"])},
  "registerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记日期"])},
  "requestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求状态"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易ID"])},
  "resultAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果金额"])},
  "withdrawalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取款金额"])},
  "fromTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账货币"])},
  "transferAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账金额"])},
  "toTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币转移"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
  "gameHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏历史"])},
  "fightNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第1次战斗。"])},
  "betList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赌单"])},
  "gmt-mnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-MNL"])},
  "gmt-ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-IST"])},
  "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复"])},
  "betRooster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贝特公鸡"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调职"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接到钱包"])},
  "walletHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包明细"])},
  "cancelInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于非正常的分红率，赌注被取消了。"])},
  "cancelInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["真正的比赛结果"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簽到"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册会员"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "noBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有余额"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "bannerContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這款遊戲是Gaming最強大、內容最大的產品，預計目前最受歡迎."])},
  "bannerContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["與菲律賓頂級權威企業合作的SABONG內容每天都會不間斷地直播."])},
  "bannerContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可以體驗到菲律賓最強烈、最有趣的遊戲SABONG."])},
  "sabongGuideHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 荧光棒游戏"])},
  "sabongGuide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["菲律宾斗鸡比赛用他加禄语称为\"萨蓬(Sabong)\"。"])},
  "sabongGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是菲律宾人最喜爱的传统运动之一。"])},
  "sabongGuide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下注可在\"RED\"和\"BLUE\"中选择，或两地均可下注。"])},
  "sabongGuide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据赌注金额,股息率会灵活变动,如果赌注过度聚集在一边,比赛可能会被取消。"])},
  "sabongGuide5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sabongGuideHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. 赌注和游戏结果"])},
  "sabongGuide6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当\"Bet Open\"时开始下注，仅在LAST Call输出后30秒内完成。"])},
  "sabongGuide7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下注时间结束时不能下注。"])},
  "sabongGuide8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果赌注结束,比赛将进行,根据结果,将获得或失去符合股息率的金额作为冠军奖金。"])},
  "sabongGuide9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sabongGuideHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. 无效游戏"])},
  "sabongGuide10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平局游戏"])},
  "sabongGuide11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比赛开始后10分钟，胜负未定时"])},
  "sabongGuide12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["两只鸡都死了，当裁判决定游戏无法进行时。"])},
  "sabongGuide13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消游戏"])},
  "sabongGuide14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行比赛的鸡因状态不好而放弃游戏者"])},
  "sabongGuide15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因下注不均衡导致赔率无法超过x1.20时"])},
  "sabongGuide16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上述情况下，下注金额将被退回，并作无效游戏处理。"])}
}