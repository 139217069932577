// export const metaFields = {
//     "gameType" : process.env.VUE_APP_DEPLOY_GAME_TYPE,
//     'checkTokenUser' : true,
// }
import { isMobile } from "@/js/utils";

export const routeName = {
    // 인게임 테마
    'InGameBasic' : {
        path: "/inGame/:gameType",
        name: `inGame`,
        component: () => isMobile() ? import('@/components/sabongInter/InGameM') : import('@/components/sabongInter/InGame'),
        meta: { gameType: process.env.VUE_APP_DEPLOY_GAME_TYPE },
    },
    'InGamePro' : {
        path: "/inGame/:gameType",
        name: `inGame`,
        component: () => isMobile() ? import('@/components/sabongInter/InGameSkinM') : import('@/components/sabongInter/InGameSkin'),
        meta: { gameType: process.env.VUE_APP_DEPLOY_GAME_TYPE },
    }
}
