import router from "@/router";
import { vinusAxios } from "@/plugins/axios";
import { loading, sabong, loginUser, err } from "@/js/reactive";
import { encryptMD5, getTomhornDateFormat } from "@/js/utils";
import { showModal } from "@/js/modal/modals";
import { ref } from "vue";
import axios from "@/plugins/axios";

const UTIL_URL = {
    "sabong.jgpot.com": process.env.VUE_APP_SABONG_UTIL_URL,
    "kinggalaxyinteractive.com": process.env.VUE_APP_KGI_UTIL_URL,
    "kg-365.com":  process.env.VUE_APP_KGI_UTIL_URL_1,
    "vsabongservice.com": process.env.VUE_APP_VSABONG_UTIL_URL,
    "vinus-inter.com": process.env.VUE_APP_AXIOS_INTER_UTIL_URL1,
    "vinusinter.com": process.env.VUE_APP_AXIOS_INTER_UTIL_URL2,
    "127.0.0.1": process.env.VUE_APP_AXIOS_INTER_UTIL_URL3,
    "localhost": process.env.VUE_APP_SABONG_UTIL_URL,
}

const tomhornEndpoint = {
    'getPlayerInfo': {
        'endPoint': '/tomhorn/dynamic',
        'url': `${process.env.VUE_APP_TOMHORN_API_URL}/api/Tomhorn/GetPlayerInfo`,
        'params': [
            {
                'key': 'token',
                'type': 'string',
                'req': true
            },
        ]
    }
}

/**
 * @name: 톰혼 연동 fetch
 */
export const $fetch = async (flag, payload) => {
    let isCheck = true;
    tomhornEndpoint[flag]['params'].some(param => {
        if (!payload[param['key']]) {
            err.msg = `${param['key']} invalid.`;
            isCheck = false;
            return true;
        }
        return false;
    });
    if (!isCheck) {
        return;
    }
    let params = {};
    let reqDate = getTomhornDateFormat();
    params['url'] = tomhornEndpoint[flag]['url'];
    params['time'] = reqDate;
    params['data'] = payload;
    params['hash'] = encryptMD5(`${process.env.VUE_APP_TOMHORN_SECRETKEY}${reqDate}${JSON.stringify(payload)}`);
    let opt = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    }
    let utilUrl = UTIL_URL[location.hostname];
    const res = await fetch(`${utilUrl}${tomhornEndpoint[flag]['endPoint']}`, opt)
        .then(res => {
            return res.json();
        }).then(res => {
            if (res.Data.err_desc) {
                err['msg'] = res.Data.err_desc;
                router.push('/error');
                throw err['msg'];
            }
            return res;
        }).catch(err => {
            router.push('/error');
            throw 'fetch error';
        });
    return res;
}

export const refferralApis = {
    isApiCalling: ref(false),
    $fetch: async (url, params) => {
        let data = params;
        let setting = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        let proxyApi = process.env.NODE_ENV == 'production' ? '' : "/refferal";
        refferralApis.isApiCalling.value = true;
        const res = await fetch(`${proxyApi}${url}`, setting)
            .then(res => {
                return res.json();
            }).then(res => {
                if (res.Status == 'ERROR') {
                    err['msg'] = typeof res.Message != 'string' ? JSON.stringify(res.Message) : res.Message;
                    throw err['msg'];
                }
                return res;
            }).catch(err => {
                refferralApis.isApiCalling.value = false;
                throw `fetch error ${err}`;
            });
        refferralApis.isApiCalling.value = false;
        return res;
    }
}

/**
 * @name: 코인 트랜잭션관련 fetch client
 */
export const ethApis = {
    isApiCalling: ref(false),
    endPoint: {
        withdrawal: {
            url: '/user/transaction/send'
        },
        history: {
            url: '/user/transaction/history'
        },
        gasInfo: {
            url: '/user/symbol/gas'
        },
        balance: {
            url: '/user/address/balance'
        },
        price: {
            url: '/user/symbol/price'
        }
    },
    $fetch: async (flag, params) => {
        let data = params;
        let setting = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };
        let proxyApi = process.env.NODE_ENV == 'production' ? '' : "/skintheme";
        ethApis.isApiCalling.value = true;
        let utilUrl = UTIL_URL[location.hostname];
        const res = await fetch(`${utilUrl}${proxyApi}${ethApis.endPoint[flag].url}`, setting)
            .then(res => {
                return res.json();
            }).then(res => {
                if (res.Status == 'ERROR') {
                    err['msg'] = typeof res.Message != 'string' ? JSON.stringify(res.Message) : res.Message;
                    throw err['msg'];
                }
                return res;
            }).catch(err => {
                ethApis.isApiCalling.value = false;
                throw `fetch error ${err}`;
            });
        ethApis.isApiCalling.value = false;
        return res;
    }
}

/**
 * @name: 스킨 테마 API
 */

export const themeApis = {
    themeUrl: undefined,
    settings: {
        method : "POST",
        headers : {
            'Content-Type': 'application/json'
        },
    },
    postData: (url, settings) => {
        themeApis.themeUrl = process.env.NODE_ENV == 'production' ? '' : "/skintheme";
        themeApis.themeUrl += url;

        let utilUrl = UTIL_URL[location.hostname];
        return fetch(`${utilUrl}${themeApis.themeUrl}`, settings)
            .then(response => {
                try{
                    return response.json();
                }catch(e){
                    throw `error : ${ e }`;
                }
            })
            .then(data => {
                try{
                    return data;
                }catch(e){
                    throw `error : ${ e }`;
                }
            })
    },
    // 현재 테마 값 
    getTheme: async (uuid) => {
        const res = await themeApis.postData(`/user/theme/${uuid}`, themeApis.settings);
        return res;
    },
    // 테마 변경, type == 바꾸고싶은 테마
    setTheme: async (uuid, type) => {
        const res = await themeApis.postData(`/user/theme/${uuid}/${type}`, themeApis.settings);
        return res;
    }
}

/**
 * @name: 사봉 지갑 API
 */

export const walletApis = {
    walletUrl: undefined,
    walletFormData : undefined,
    walletSetting: {},
    // fetch에 데이터 세팅.
    setSettings: (url, object) => {
        walletApis.walletUrl = process.env.VUE_APP_AXIOS_WALLET_BASE_URL + url;
        walletApis.walletFormData = new FormData();
        Object.entries(object).forEach(data => {
            walletApis.walletFormData.append(data[0], data[1]);
        })
        walletApis.walletSetting = {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            // credentials: "include",
            headers: {
                'Accept' : 'application/json',
                'Content-Type': ' application/x-www-form-urlencoded',
            },
            redirect: 'follow',
            referrer: '',
            body: new URLSearchParams(object),
        }
    },
    // get 요청
    getData: (url) => {
        walletApis.walletUrl = process.env.VUE_APP_AXIOS_WALLET_BASE_URL + url;
        return fetch(walletApis.walletUrl)
            .then(response => response.text())
            .then(data => data);
    },
    // post 요청
    postData: (url, object) => {
        // 오브젝트 형태인지 확인 및 데이터 검사 추가 필요
        walletApis.setSettings(url, object);
        return fetch(walletApis.walletUrl, walletApis.walletSetting)
            .then(response => {
                try{
                    // 유효성검사필요.
                    return response.text();
                }catch (e){
                    throw `error : ${ e }`;
                }
            })
            .then(data => {
                try{
                    return data;
                }catch (e) {
                    throw `error : ${ e }`;
                }
            });
    },
    // 지갑생성하기
    makeWallet: async (params) => {
        const res = await walletApis.postData("/blockchain_sdk_wallet_api.php", {
            ...params
        })
        return res;
    },
    //유저가 보유한 이더 수량
    ethAmount: async (params) => {
        const res = await walletApis.postData("/blockchain_sdk_balance_api.php", {
            ...params
        });
        return res;
    },
    //유저 사봉 지갑 주소
    walletAddress: async (params) => {
        const res = await walletApis.postData("/eth_wallet.php",{
            ...params
        });
        return res;
    },
    //유저 사봉 지갑 여부
    walletOwn: async (params) => {
        const res = await walletApis.postData("/eth_wallet.php", {
            ...params
        });
        return res;
    },
    //이더리움->포인트 변환 전송 api
    walletConvertSend: async (params) => {
        const res = await walletApis.postData("/blockchain_sdk_ethsend_api.php",{
            ...params
        });
        return res;
    },
    // 유저의 거래내역
    walletTransHistory: async (params) => {
        const res = await walletApis.postData("/transaction_api.php", {
            ...params
        });
        return res;
    },
    //이더리움 전송시 수수료
    walletEthGas: async () => {
        const res = await walletApis.getData("/eth_gas.php");
        return res;
    },
    //현재 이더스캔 기준 usd 가격
    walletUsdPrice: async () => {
        const res = await walletApis.getData("/etherscan_ethprice_api.php");
        return res;
    },
}

/**
 * @name: 인터네셔널 사봉 API
 */
export const interApis = {
    // 경기 정보 가져오기
    getEvents: async (params) => {
        const res = await vinusAxios.post("/api/sabong/play/game/info", {
            'pass': true,
            ...params
        });
        return res;
    },
    // 경기 배팅
    bet: async (params) => {
        const res = await vinusAxios.post("/api/sabong/bet", {
            ...params
        });
        return res;
    },
    // 경기 배팅유저정보가져오기
    getBetUsers: async (params) => {
        const res = await vinusAxios.post("/api/sabong/game/bet/history", {
            ...params
        });
        return res;
    },
    // 경기 배팅히스토리 가져오기
    getBetHistory: async (params) => {
        const res = await vinusAxios.post("/api/sabong/bet/history", {
            ...params
        });
        return res;
    },
    // 경기 결과 가져오기
    getGameResult: async (params) => {
        const res = await vinusAxios.post("/api/sabong/play/game/result/history", {
            ...params
        });
        return res;
    },
    // 현재 액티브방 상태값 가져오기
    getEventStatus: async (params) => {
        const res = await vinusAxios.post("/api/sabong/update/round/last/call", {
            ...params
        });
        return res;
    }
}



export const apis = {
    // 로그인
    login: async (params, headers = {}) => {

        let url = '/api/certification/signin';
        console.log(url+"_12");

        
        const res = await vinusAxios.post(`${url}`, {
            'pass': true,
            ...params
        },{
            headers: { ...headers }
        });
        return res;


        //let url = '/api/certification/signin';
        /*     let setting = {
            method: "POST",
            headers: {
                "publisher-private-key": "C105E791-F4F8-48C8-AC83-B550B3871C8A",
                "client-game-id": "C80A3C3E-99EF-4C37-819D-6E92A96AA1FA",
                "client-version-type": 1000000001,
                "Content-Type": "application/json, application/x-www-form-urlencoded",
            },
            body: JSON.stringify(params)  //post - body
        };

        let utilUrl = process.env.VUE_APP_AXIOS_BASE_URL1;
        const res = await fetch(`${utilUrl}${url}`, setting)
            .then(res => {
                return res;
            }).then(res => {
                if (res.Status == 'ERROR') {
                    throw 'msg';
                }
                return res;
            }).catch(err => {
                throw `fetch error ${err}`;
            });

        return res;*/
    },
    // 로그아웃
    logout: async () => {
        await vinusAxios.post("/api/auth/logout",{});
    },
    //딜러이미지가져오기
    getDealerImage: (params) => {
        return vinusAxios.get("/api/profile/list/room");
    },
    // 룸 정보 가져오기 ( 전체 )
    getRoomInfo: (params) => {
        return vinusAxios.post("/maintenance/command/data", {
            ...params
        });
    },
    //로그인한 유저의 돈 가져오기
    getLoginUserAmount: (params) => {
        return vinusAxios.post("/api/auth/goods", {
            ...params
        });
    },
    // 배팅
    bet: (params) => {
        return vinusAxios.post("/api/play/baccarat/bet", {
            ...params
        });
    },
    // 배팅취소(전체)
    cancel: (params) => {
        return vinusAxios.post("/api/play/baccarat/refund", {
            ...params
        });
    },
    ping: () => {
        return vinusAxios.post("/maintenance/get/ping", {});
    },
    // 상점 정보 들 가져옴
    getShopInfo(){
        loading['modal'] = true;
        return vinusAxios.get("/api/powerball/dealers");
    },
    // 딜러구매
    buyDealer(params){
        loading['modal'] = true;
        return vinusAxios.post("/api/shop/buy/dealer", {
            ...params
        });
    },
    // 유저가 보유중인 딜러들가져옴
    getMyDealerList(){
        loading['modal'] = true;
        return vinusAxios.get('/api/powerball/user/dealer');
    },
    // 장착중인 딜러
    getEquipDealer(){
        loading['modal'] = true;
        return vinusAxios.get('/api/powerball/user/dealer/use/mounting');
    },
    // 장착 딜러정보 변경
    updateEquipStatus(params){
        loading['modal'] = true;
        return vinusAxios.post('/api/powerball/user/dealer/mounting', {
            ...params
        });
    },
    // 바카라 히스토리(추후 밑에 함수로 통합해야함)
    getBaccaratHistory(params){
        showModal('loading', true);
        return vinusAxios.post('/api/history/member', {
            ...params
        });
    },
    // 바카라 히스토리
    getHistory(params){
        showModal('loading', true);
        return vinusAxios.post('/api/history/member', {
            ...params
        });
    },
    // 유저 상세정보 조회
    getUserDetail(params){
        return vinusAxios.post('/api/powerball/bet/users/detail', {
            ...params
        });
    },
    // 바카라 상세 히스토리(추후 밑에 함수로 통합해야함)
    getBaccaratHistoryDetail: async (params) => {
        showModal('loading', true);
        const res = await vinusAxios.post('/api/history/member/detail', {
            ...params
        });
        return res;
    },
    // 바카라 상세 히스토리
    getHistoryDetl(params){
        loading['modal'] = true;
        return vinusAxios.post('/api/history/member/detail', {
            ...params
        });
    },
    // 랭킹정보
    getRank(params){
        loading['modal'] = true;
        return vinusAxios.post('/api/ranking/member', {
            ...params
        });
    },
    // 딜러랭킹정보
    getDealerRank(){
        loading['modal'] = true;
        return vinusAxios.post('/api/ranking/dealer', {});
    },
    // 딜러프로필정보가져오기
    getDealerProfile(params){
        loading['modal'] = true;
        return vinusAxios.post('/api/profile/list', {
            ...params
        });
    },
    /**
     * @name: 환율 정보 가져오기
     * @param: code String 커런시코드 ("USD", "KRW")
     * @param: game String 게임타입 (바카라: 1, 사봉: 2)
     * @param: table String 테이블번호 (테이블번호 )
     */
    getCurrencyInfo: async (params) => {
        const res = await vinusAxios.post('/api/cms/country/lists', {
            ...params
        });
        return res;
    },
    // 입금
    deposit: async (params) => {
        const res = await vinusAxios.post('/transaction/set_deposit_request', {
            ...params
        });
        return res;
    },
    // 출금
    withdrawal: async (params) => {
        const res = await vinusAxios.post('/transaction/set_withdraw_request', {
            ...params
        });
        return res;
    },
    // 입금
    depositHistory: async (params) => {
        const res = await vinusAxios.post('/transaction/get_history_deposit', {
            ...params
        });
        return res;
    },
    // 출금
    withdrawalHistory: async (params) => {
        const res = await vinusAxios.post('/transaction/get_history_withdraw', {
            ...params
        });
        return res;
    },
}