import { createApp }  from "vue";
import App            from "./App.vue";
import router         from "./router";
import store          from "./store";
import { i18n }           from "./i18n";
import { apis } from "@/repository/repository";
import Toaster from '@meforma/vue-toaster';
import {setNumberFormat, getMoneySymbol, setNumberRound} from "./js/utils";
import Vue3Autocounter from 'vue3-autocounter';
import { err, loginUser } from "@/js/reactive";
import clickOutside from "click-outside-vue3"

const app                              = createApp(App);
app.config.devtools                    = false;
app.config.globalProperties.apis = apis;
app.config.globalProperties.store = store;
app.config.errorHandler = (error, instance, info) => {
    if(error?.message){
        err.msg = error.code == '-32002' ? 'Open the browser extension program to complete the meta mask setup.' : error.message;
    }
    console.log(error, instance, info);
    //router.push('/lobby');
}


app.directive('numberFormat', {
    updated: (el, binding) => {
        el.innerText = setNumberFormat(binding.value)
    },
    mounted: (el, binding) => {
        el.innerText = setNumberFormat(binding.value)
    }
});

app.directive('moneySymbol', {
    updated: (el, binding) => {
        el.innerText = getMoneySymbol(binding.value)
    },
    mounted: (el, binding) => {
        el.innerText = getMoneySymbol(binding.value)
    }
})

app.directive('numberRound', {
    updated: (el, binding) => {
        el.innerText = setNumberRound(binding.value, el.attributes.digit?.value)
    },
    mounted: (el, binding, vnode) => {
        el.innerText = setNumberRound(binding.value , el.attributes.digit?.value)
    }
})

// 로컬스토리지에 token이란 키가있으면 해당태그를 안보여줌.
app.directive('tokenCheck', {
    created: (el, binding) => {
        if(localStorage.token == 'token'){
            el.style.display = 'none';
        }
    },
    updated: (el, binding) => {
        if(localStorage.token == 'token'){
            el.style.display = 'none';
        }
    },
    mounted: (el, binding) => {
        if(localStorage.token == 'token'){
            el.style.display = 'none';
        }
    },
});

/*https://vuejsexamples.com/vue-js-toast-notification-plugin-for-vue-3/*/
app.use(Toaster)
app.use(i18n);
app.use(store);
app.use(router);
app.use(Vue3Autocounter);
app.use(clickOutside);

app.mount("#app");
