export default {
  "baccarat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACCARAT"])},
  "teenpatti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEENPATTI"])},
  "dragonTiger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIGERDEDRAGON"])},
  "holdem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOLDEM"])},
  "roulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROULETE"])},
  "sicbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SICBO"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiodetabla"])},
  "betLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitesdeapuestas"])},
  "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enciende/desaparezcaelsonido"])},
  "helpInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda/Info"])},
  "lobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esunvestíbulo"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salga"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chateando"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Video!"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra."])},
  "fx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FX: enelmundodelcambio."])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Música"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto."])},
  "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio."])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bajo."])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rancho"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AyudaC/S"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opción."])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-Sí:claro."])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selogró."])},
  "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutorial"])},
  "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P.PAIR"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUERRA"])},
  "tie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIE"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BANCARIA"])},
  "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B.PAIR"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUERRA"])},
  "banker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BANCARIA"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apuesta."])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMORA"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listo."])},
  "betReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLACERVOSBETES"])},
  "betEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BETfinda"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porfavor:esperenhastaqueempiecelapróximaronda."])},
  "noGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nohaylistadejuegos."])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-Sí: señor."])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coréano"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Japones!"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chino"])},
  "vt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamita"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tailandia"])},
  "sp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
  "would": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tegustaríacomprar?"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio."])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar."])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntodetiempo"])},
  "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinlímites"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No: no."])},
  "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificacióndeagente."])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEPOSITO"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELDESCUENTO"])},
  "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puesto"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAGAR"])},
  "win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GANAR"])},
  "win2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganar"])},
  "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perda"])},
  "lose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdida"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibujar"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacióndelusuario"])},
  "gameId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificacióndejuego"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Día(UTC)"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previo"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente."])},
  "historyWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Lahistoriasólopuedeverseenlasúltimas100filas."])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nohaydatosdisponiblesparaver."])},
  "noRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente:nohaytablasactivas."])},
  "chatInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envíeunmensajedecharla"])},
  "toList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alalista"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-No."])},
  "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max: ¿qué?"])},
  "checkMinMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porfavor:revisenellímitedeapuestas"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡OFF!"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encendido"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Llamando"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latabla"])},
  "roundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iddelaronda"])},
  "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RUNDO"])},
  "betCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apuestacancelada"])},
  "sabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SABONG"])},
  "betUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apuestaanular"])},
  "betRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previamente: lasapuestasrondas"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUIDO"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresa"])},
  "rebet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordar"])},
  "tutorialTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómojugar"])},
  "tutorialTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Mira!"])},
  "tutorialTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Mira!"])},
  "tutorial1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LatarjetaAsecalculaporelnúmero1: y10: J: QyKsecalculanpor0.Losrestantestarjetassecalculansegúnelnúmerodepropiedaddecadatarjeta."])},
  "tutorial2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconsideralasumadelosdosnúmeros: exceptolaunidadde10.Porejemplo: noson5+8=13: sino3."])},
  "tutorial3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enesencia: eljugadoryelbanquerorecibendoscartascadauna.Enestemomento: silasumadelosdosnúmerosseconvierteen8o9: eljuegoterminarásinrecibirlaterceratarjetabajocondicionesnaturales."])},
  "tutorial4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siambosnosonnaturales: eljugadordecidesirecibironolaterceratarjetabasadaenlasumadelasdoscartas."])},
  "tutorial5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Silasumadelosnúmerosdelosjugadoreses6o7: Noaceptenlascartas(Stand)"])},
  "tutorial6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Silasumadelosnúmerosdejugadoreses0-5: Recibíla3ªtarjeta."])},
  "tutorial7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieljugadorestáenpie: "])},
  "tutorial8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Sieljugadortiene6o7añosyelbanquerotiene6o7: Noaceptenlatarjeta"])},
  "tutorial9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Sieljugadortiene6o7añosyelbanqueroesde0a5,Recibíla3ªtarjeta"])},
  "tutorial10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieljugadorrecibelaterceratarjeta: "])},
  "tutorial11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Sieljugadortiene0-5yelbanqueroes0-2: Recibíla3ªtarjeta."])},
  "tutorial12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Sieljugadortiene0a5añosyelbanquerotiene3,Recibela3ªtarjeta(exceptosila3ªtarjetadeljugadores8)"])},
  "tutorial13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Sieljugadortiene0a5añosyelbanqueroes4: recibala3ªtarjeta(exceptosila3ªes0: 1: 8: 9)."])},
  "tutorial14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Sieljugadortiene0a5añosyelbanquerotiene5: Recibala3ªtarjeta(exceptosila3ªes0: 1: 2: 3: 8: 9)."])},
  "tutorial15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Sieljugadortiene0a5añosyelbanquero6: Recibala3ªtarjeta(exceptosila3ªes0: 1: 2: 3: 4: 5: 8: 9)."])},
  "tutorial16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□Sieljugadortiene0a5añosyelbanquerotiene7: Noaceptenlatarjeta(Stand)"])},
  "tutorial17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segúnlasreglasdeljuego: ganalaqueseacercaa9de2o3."])},
  "tutorial18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varialigeramentesegúnlamesa: peronormalmentedevuelveeldobledelacantidaddebatealladoganador: ysiganasconunacorbata: regresasochoveceslacantidaddebate."])},
  "tutorial19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoelbanquerotieneunatasadegananciaalta: sigananporelbanquero: pagaránunacomisióndel5%alacasa."])},
  "msgErr1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmensajeesdemasiadolargo.(lanuramáximade100caracteres)"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enpantallacompleta"])},
  "cam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAM"])},
  "lobbyExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir."])},
  "equip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipo"])},
  "unequip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desequiparar"])},
  "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unequilibrioinsuficiente."])},
  "dealerSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lapieldelcorredor"])},
  "cardSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CartasPeleada"])},
  "dealerProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiledecomerciantes."])},
  "beInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estarenuso"])},
  "dealerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacióndeloscomerciantes"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENU"])},
  "myInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miinformación"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivo."])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plataforma"])},
  "lastIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimoip"])},
  "lastDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimodominio"])},
  "loginTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laúltimavez."])},
  "registerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date."])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EnderezaRegs."])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinero"])},
  "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificacióndeusuario"])},
  "agentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificacióndeagente"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile."])},
  "dealerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombredelcomerciante"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como..."])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips"])},
  "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempodejuego"])},
  "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elmiembro"])},
  "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
  "meron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "wala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAW"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHITE"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "lastGameResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elresultadodelúltimojuego"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No: no: no: no: no: no: no: no: no: no: "])},
  "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W."])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adelante."])},
  "memberList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listademiembros"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-Sí: sí."])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gradodelaserie"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-Sí: LV."])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convertir"])},
  "deposit_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito"])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada"])},
  "depositBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito"])},
  "transactionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiadetransacciones"])},
  "depositWarningTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latarifasemideparalaconversión: peronosemidecuandoseintercambiandinero."])},
  "depositInfoTxt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PuedenconvertirsupuntoSabong(USD)enEthereumoBNB"])},
  "depositInfoTxt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EntreelvalorquequieresconvertirypresioneelbotónTransferparaconvertirloenSabongPointaplicandoelpreciodemercadoapropiadoparaelmomentodelpedidodecambio."])},
  "walletCreateTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LasmonedasdeSABONGWALLETsemantienenatravésdelatransmisióndebilletes.Puedesagregarlacantidad."])},
  "walletCreateTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crearunacarteradevinosabong"])},
  "depositSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Símbolosdedepósito"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Símbolos"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valordelaobra"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "copyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiarladirección."])},
  "myWalletAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EnderezadelaempresaUSD"])},
  "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indexa"])},
  "registerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dataderegistro"])},
  "requestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estacióndesolicitud"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificacióndetransacción"])},
  "resultAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ResultadoMontante"])},
  "withdrawalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montantederetirada"])},
  "fromTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delamonedadetransferencia"])},
  "transferAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montantedetransferencia"])},
  "toTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paratransferirlamoneda"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasajedebilletes"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora."])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalmente."])},
  "gameHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiadelosjuegos"])},
  "fightNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No."])},
  "betList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listadeapuestas"])},
  "gmt-mnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-MNL"])},
  "gmt-ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-IST"])},
  "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repito."])},
  "betRooster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BetRooster: "])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envíense"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfiere"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conecteaunacartera"])},
  "walletHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiadelasbilletes"])},
  "cancelInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laapuestafuecanceladadebidoaunatasaanormaldedividendos."])},
  "cancelInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elverdaderoresultadodeljuego"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se inscribe"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscribirse"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busca"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis"])},
  "noBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sin saldo"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo"])},
  "bannerContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este juego es el producto más potente y de mayor contenido de Gaming y se espera que sea el más popular en la actualidad."])},
  "bannerContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los contenidos SAVONG, que colaboran con las mejores compañías filipinas de prestigio, se transmiten en vivo todos los días sin interrupción."])},
  "bannerContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrás vivir el juego más intenso y emocionante de Filipinas, SABONG."])},
  "sabongGuideHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Juego de sabong"])},
  "sabongGuide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El juego de peleas de gallos de Filipinas se llama 'Sabong' en tagalo."])},
  "sabongGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es uno de los deportes tradicionales que más disfrutan los filipinos."])},
  "sabongGuide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las apuestas pueden ser 'ROJAS' o 'AZULES' o ambas."])},
  "sabongGuide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tasa de dividendos fluctúa de manera fluida según la cantidad de apuestas, y las apuestas por un lado son Las reuniones excesivas pueden provocar la cancelación del juego."])},
  "sabongGuide5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sabongGuideHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apuestas y resultados de juegos"])},
  "sabongGuide6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las apuestas comienzan cuando \"Bet Open\" está activado y solo durante 30 segundos después de que se emita la ÚLTIMA llamada"])},
  "sabongGuide7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, no puedes apostar cuando se acaba el tiempo de apuestas."])},
  "sabongGuide8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando terminen las apuestas, el juego continuará y la cantidad que coincida con la tasa de dividendo se determinará de acuerdo con el resultado."])},
  "sabongGuide9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibirá o perderá el dinero del premio."])},
  "sabongGuideHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Juego inválido"])},
  "sabongGuide10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un juego de sorteo"])},
  "sabongGuide11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando el juego no se decida hasta 10 minutos después del inicio del juego,"])},
  "sabongGuide12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando ambos pollos mueren y el árbitro decide que no se puede jugar el juego"])},
  "sabongGuide13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juego de cancelación"])},
  "sabongGuide14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el caso de abandono del juego por mal estado del pollo que realiza el juego;"])},
  "sabongGuide15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la tasa de dividendo no supera x1,20 debido a un desequilibrio en las apuestas"])},
  "sabongGuide16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la situación anterior, se devolverá el monto de la apuesta y se invalidará el juego."])}
}