<template>
    <header>
        <div class="headerWrap">
            <div class="headerLeft">
                <img
                    v-if="logoSrc"
                    class="sabongLogo"
                    v-bind:src="logoSrc"
                    alt="vg">
                <ul class="userInfoBox">
                    <li v-if="!loginUser['uuid']">
                        <img
                            class="sabongLogo2"
                            v-bind:src="logoTextSrc">
                    </li>
                    <li
                        v-if="loginUser['uuid']"
                        class="sabongHeader"
                        v-on:click.stop="store.commit('modal/showModal', { modalKey: 'userInfo', open: true, styles: { width: '508px',left: '1px', top: '99px', transform: 'translate(0)'}})"
                    >
                        <p class="welcome">
                            Welcome!
                        </p>
                        <p>
                            {{ loginUser['id'] }} <img src="../../assets/sabong/icon/icon_arrow.png">
                        </p>
                    </li>
                    <li v-if="loginUser['uuid']">
                        <ul class="moneyBox">
                            <li>
                                <p
                                    class="coinWrap"
                                    v-if="loginUser['isWallet']">
                                    <span
                                        class="inrBox"
                                        v-numberRound="ethBalance"></span>
                                    <span class="inrBox symbolBox">
                                        <img v-bind:src="isEth ? require('@/assets/icons/eth.png') : require('@/assets/icons/bnb.png')">
                                    </span>
                                    <button
                                        class="refreshBtn"
                                        v-bind:class="{ 'lock': ethApis.isApiCalling }"
                                        v-bind:disabled="ethApis.isApiCalling"
                                        v-on:click.stop="getEthAmount()">
                                        <img
                                            src="@/assets/icons/btn_change.png"
                                            width="20"
                                            height="20">
                                    </button>
                                </p>
                                <p>
                                    <span
                                        class="inrBox"
                                        v-numberRound="loginUser['balance']"
                                        digit="2"></span>
                                    <span class="inrBox">
                                        {{ loginUser['currency'] }}
                                    </span>
                                </p>
                            </li>
                            <li v-if="loginUser['isWallet']">
                                <img
                                    src="../../assets/btn/btn_wallet.png"
                                    alt="wallet"
                                    v-on:click.stop="store.commit('modal/showModal', { modalKey: 'MyWallet', open: true, styles: { width: '508px'}})">
                                <span class="walletAlert"></span>
                            </li>
                        </ul>
                    </li>
                </ul>
                <span class="triangle"></span>
            </div>
            <!--			일반유저 입출금-->
            <div
                class="btnBox"
                v-if="!loginUser['isWallet'] && loginUser['uuid']"
                v-tokenCheck>
                <div
                    v-for="(menu, key) in transMenu"
                    v-bind:class="{ 'hide': menu['hide'], 'lock': menu['lock'] }"
                    v-on:click="menu['click'](menu)"
                    v-bind:key="key">
                    <img
                        v-bind:src="menu['walletIcon']"
                        style="height: 26px;"/>
                    {{ $t(menu['key']) }}
                </div>
            </div>
            <!--			일반유저 입출금-->
            <!--			월렛유저 입출금-->
            <div
                class="btnBox"
                v-if="loginUser['isWallet']">
                <div
                    v-for="(menu, key) in walletMenu"
                    v-bind:class="{ 'hide': menu['hide'], 'lock': menu['lock'] }"
                    v-on:click="menu['click'](menu)"
                    v-bind:key="key">
                    <img
                        v-bind:src="menu['walletIcon']"
                        style="height: 26px;"/>
                    {{ $t(menu['key']) }}
                </div>
            </div>
            <!--			월렛유저 입출금-->
        </div>
        <div class="contentWrap">
            <div class="contentBox">
                <ul class="headerRightBox">
                    <li
                        class="tutorial">
                        <p 
                            v-on:click="toggleGuide">
                            {{ $t("tutorial") }}
                        </p>
                        <div
                            class="guideModal"
                            v-if="isShowGuide"
                            v-click-outside="hideGuide">
                            <div class="modalHeader">
                                <div class="title">
                                    {{ $t("tutorial") }}
                                </div>
                                <div class="closeBtn menu">
                                    <img
                                        src="@/assets/mobile/btn-close.png"
                                        v-on:click="hideGuide"/>
                                </div>
                            </div>
                            <div class="modalBody">
                                <Tutorial/>
                            </div>
                        </div>
                    </li>
                    <!-- <li
                        class="connectWallet"
                        v-if="!loginUser['uuid']"
                        v-on:click.stop="$store.commit('modal/showModal', { modalKey: 'connectWallet', open: true, styles: { width: '350px'}})">
                        <img
                            src="@/assets/sabong/icon/icon_wallet.png"
                            alt="">
                        <p>{{ $t("connectWallet") }}</p>
                    </li> -->
                    <li
                        v-for="(menu, key) in centerMenu"
                        v-bind:class="{ 'hide': menu['hide'] }"
                        v-on:click="menu['click']"
                        v-bind:key="key">
                        <p>{{ $t(menu['key']) }}</p>
                    </li>
                    <li
                        class="lngBox">
                        <p
                            v-on:click="toggleLang">
                            {{ $t("language") }}
                            <img src="../../assets/icons/arrow2.png"/>
                        </p>
                        <transition name="slide">
                            <div
                                class="langWrap"
                                v-if="isShowLang"
                                v-click-outside="hideLang">
                                <div
                                    class="lang"
                                    v-for="(lang, idx) in langArr"
                                    v-bind:key="idx"
                                    v-on:click.stop="lang['click']"
                                    v-bind:class="{ 'selected': $i18n.locale == lang['key'] }">
                                    <div class="flag">
                                        <img
                                            v-bind:src="lang['flag']"
                                            width="18"
                                            height="18"/>
                                    </div>
                                    <div>
                                        {{ lang['title'] }}
                                    </div>
                                </div>
                                <div
                                    class="cancel"
                                    v-on:click="hideLang">
                                    {{ $t("cancel") }}
                                </div>
                            </div>
                        </transition>
                    </li>
                    <li
                        class="LoginBox"
                        v-tokenCheck>
                        <span
                            v-on:click.stop="logout()"
                            class="logoutTx"
                            v-if="loginUser['uuid']">
                            {{ $t("logout") }}
                        </span>
                        <span
                            v-on:click.stop="$store.commit('modal/showModal', { modalKey: 'signin', open: true, styles: { width: '400px'}})"
                            v-if="!loginUser['uuid']">
                            {{ $t("signin") }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>

        <Modal
            v-if="isShowModal"
            v-bind:title="modalTitle"
            v-bind:modalKey="modalKey"
            v-on:setModalKey="setModalKey"
            v-on:hideModal="hideModal"/>
        <WalletModal
            v-if="isShowWalletModal"
            v-bind:title="modalTitle"
            v-bind:modalKey="walletModalKey"
            v-on:setModalKey="setModalKey"
            v-on:hideModal="hideModal"/>
        <transition name="slideUserInfo">
            <ModalUserInfo
                v-if="isShowUserInfo"
                v-on:showUserInfo="showUserInfo"/>
        </transition>
        <WalletUserInfo
            v-if="isShowMyWallet && isWalletOwn == true"
            v-on:closeModal="showMyWallet(isShowMyWallet)"
            v-on:openConvertModal="openConvertModal"/>
        <WalletCreateModal
            v-if="isShowMyWallet && isWalletOwn == false"
            v-on:closeModal="showMyWallet(isShowMyWallet)"/>
        <Daily
            v-if="modalKey === 'daily'"
            v-on:setModalKey="setModalKey"/>
    </header>
</template>
<script>
    import { computed, defineComponent } from "vue";
    import { ethApis } from "@/repository/repository";
    import { loginUser, timer, sabong, err } from "@/js/reactive";
    import { decryptData, setNumberFormat, getCookie } from "@/js/utils";
    import { logout } from "@/js/common";
    import { ethAmount, walletOwn, walletUsdPrice, walletTransHistory, walletAddress, sabongWalletInfo } from "@/js/inGame/sabongWallet"
    import { showModal, modals } from "@/js/modal/modals";
    import { getBalance } from "@/js/request";
    import WalletModal from "@/components/popup/WalletModal";
    import ModalUserInfo from "@/components/popup/ModalUserInfo";
    import WalletUserInfo from "@/components/sabong/popup/WalletUserInfo";
    import WalletCreateModal from "@/components/sabong/popup/WalletCreateModal";
    import Modal from "@/components/popup/Modal";
    import Daily from "@/components/popup/Daily";
    import { LOGO } from "@/js/const";
    import Tutorial from "@/components/popup/Tutorial"

    export default defineComponent({
        name: "WalletHeader",
        components: {
            Modal,
            WalletModal,
            ModalUserInfo,
            WalletUserInfo,
            WalletCreateModal,
            Daily,
            Tutorial
        },
        data() {
            return {
                ethApis: ethApis,
                modals: modals,
                ethBalance: computed(() => {return sabongWalletInfo['ethAmount']}),
                deployType: process.env.VUE_APP_DEPLOY_GAME_TYPE,
                logoSrc: LOGO?.[location.hostname]?.pcHeader ?? LOGO?.['vinusinter.com']?.pcHeader,
                logoTextSrc: LOGO?.[location.hostname]?.pcHeaderTitle ?? LOGO?.['vinusinter.com']?.pcHeaderTitle,
                isShowUserInfo: false,
                isApiCalling: false,
                modalTitle: '',
                modalKey: '',
                walletModalKey: '',
                isShowModal: false,
                isShowWalletModal: false,
                isShowChangeMoney: false,
                isShowMyWallet: false,
                isShowLang: false,
                isShowGuide: false,
                isWalletOwn: computed(() => {return sabongWalletInfo['walletOwn']}),
                loginUser: computed(() => {return this.$store.getters['login/getLoginUser']; }),
                langArr: [
                    {
                        'key': 'en',
                        'title': this.$t("en"),
                        'click': () => {
                            this.$i18n.locale = 'en'
                            this.isShowLang = false;
                        },
                        'flag': require(`../../assets/flag/flag-english.png`),
                        'lock': false
                    },
                    {
                        'key': 'ko',
                        'title': this.$t("ko"),
                        'click': () => {
                            this.$i18n.locale = 'ko'
                            this.isShowLang = false;
                        },
                        'flag': require(`../../assets/flag/flag-korean.png`),
                        'lock': true
                    },
                    {
                        'key': 'ja',
                        'title': this.$t("ja"),
                        'flag': require(`../../assets/flag/flag-japanese.png`),
                        'click': () => {
                            this.$i18n.locale = 'ja'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'hi',
                        'flag': require(`../../assets/flag/flag-india.png`),
                        'title': this.$t("hi"),
                        'click': () => {
                            this.$i18n.locale = 'hi'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'ch',
                        'title': this.$t("ch"),
                        'flag': require(`../../assets/flag/flag-chinese.png`),
                        'click': () => {
                            this.$i18n.locale = 'ch'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'vt',
                        'title': this.$t("vt"),
                        'flag': require(`../../assets/flag/flag-vietnamese.png`),
                        'click': () => {
                            this.$i18n.locale = 'vt'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'th',
                        'flag': require(`../../assets/flag/flag-thai.png`),
                        'title': this.$t("th"),
                        'click': () => {
                            this.$i18n.locale = 'th'
                            this.isShowLang = false;
                        },
                        'lock': true
                    },
                    {
                        'key': 'sp',
                        'flag': require(`../../assets/flag/flag-spanish.png`),
                        'title': this.$t("sp"),
                        'click': () => {
                            this.$i18n.locale = 'sp'
                            this.isShowLang = false;
                        },
                        'lock': true
                    }
                ],
                centerMenu: [
                    {
                        'key': 'daily',
                        'title': this.$t("daily"),
                        'click': () => {
                            this.modalKey = 'daily';
                            this.isShowModal = false;
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false
                    },
                    {
                        'key': 'shop',
                        'title': this.$t("shop"),
                        'click': () => {
                            this.modalTitle = this.$t("shop");
                            this.modalKey = 'shop';
                            this.isShowModal = true;
                            //showModal('shop', true);
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false
                    },
                    {
                        'key': 'history',
                        'title': this.$t("history"),
                        'click': () => {
                            this.modalTitle = this.$t("history");
                            this.modalKey = 'history';
                            this.isShowModal = true;
                            if(process.env.VUE_APP_DEPLOY_GAME_TYPE == 'live'){
                                showModal('history', true);
                            }
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false
                    },
                    {
                        'key': 'rank',
                        'title': this.$t("rank"),
                        'click': () => {
                            this.modalTitle = this.$t("rank");
                            this.modalKey = 'rank';
                            this.isShowModal = true;
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false
                    },
                    {
                        'key': 'help',
                        'title': this.$t("help"),
                        'click': () => {
                            this.$store.commit('modal/showModal', { modalKey: 'help', open: true, styles: { width: '500px', height: '428px'}})
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false
                    },
                ],
                transMenu: [
                    {
                        'key': 'transaction',
                        'walletIcon': require(`../../assets/sabong/icon/Deposit.png`),
                        'click': (menu) => {
                            if(menu['lock']){
                                return;
                            }
                            this.$store.commit('modal/showModal', { modalKey: 'transaction', open: true, styles: { width: '500px', height: '410px'}});
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : true
                    },
                    {
                        'key': 'transactionHistory',
                        'walletIcon': require(`../../assets/sabong/icon/Wallet_History.png`),
                        'click': (menu) => {
                            if(menu['lock']){
                                return;
                            }
                            this.$store.commit('modal/showModal', { modalKey: 'transactionHistory', open: true, styles: { width: '70vw'}})
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : true
                    },
                    // {
                    //     'key': 'reffer',
                    //     'walletIcon': require(`../../assets/sabong/icon/Wallet_History.png`),
                    //     'click': (menu) => {
                    //         if(menu['lock']){
                    //             return;
                    //         }
                    //         this.$store.commit('modal/showModal', { modalKey: 'reffer', open: true, styles: { width: '500px' }})
                    //     },
                    //     'lock': false,
                    //     'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : true
                    // },
                ],
                walletMenu : [
                    {
                        'key': 'deposit_menu',
                        'title': this.$t("deposit_menu"),
                        'walletIcon': require(`../../assets/sabong/icon/Deposit.png`),
                        'click': (menu) => {
                            if(menu['lock']){
                                return;
                            }
                            this.$store.commit('modal/showModal', { modalKey: 'deposit_menu', open: true, styles: { width: '422px'}})
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : true
                    },
                    {
                        'key': 'withdrawal',
                        'title': this.$t("withdrawal"),
                        'walletIcon': require(`../../assets/sabong/icon/Withdrawal.png`),
                        'click': (menu) => {
                            if(menu['lock']){
                                return;
                            }
                            this.$store.commit('modal/showModal', { modalKey: 'withdrawal', open: true, styles: { width: '422px'}})
                        },
                        'lock': false,
                        'hide': true
                    },
                    {
                        'key': 'transactionHistory',
                        'title': this.$t("transactionHistory"),
                        'walletIcon': require(`../../assets/sabong/icon/Wallet_History.png`),
                        'click': (menu) => {
                            if(menu['lock']){
                                return;
                            }
                            this.$store.commit('modal/showModal', { modalKey: 'transactionHistory', open: true, styles: { width: '85vw'}})
                        },
                        'lock': false,
                        'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : true
                    },
                ],
                isLoginTextShow: false,
                isShowPcc: false,
                moneyTitle: 'VINUS LIVE',
                isEth: true
            }
        },
        watch: {
            loginUser: {
                deep: true,
                immediate: true,
                async handler(val){
                    if(val.time){
                        await getBalance();
                        if(loginUser['isWallet']){
                            await this.getUserDetail();
                            await walletOwn();
                            await ethAmount('eth');
                            await walletUsdPrice('eth');
                            // 지갑 보유 시 히스토리와 지갑 주소 찾기
                            await this.getOwnWallet();
                        }
                    }
                }
            }
        },
        unmounted() {
            clearTimeout(timer['balance']);
        },
        methods: {
            async getEthAmount() {
                this.isEth = !this.isEth;
                await ethAmount(this.isEth ? 'eth' : 'bsc');
            },
            showModal,
            logout,
            clickUserId(){
                this.isShowUserInfo = !this.isShowUserInfo;
            },
            changeShowMoneyView(isShowPcc){
                this.isShowPcc = isShowPcc;
                this.moneyTitle = this.isShowPcc ? 'PCC' : 'VINUS LIVE';
                this.isShowChangeMoney = false;
            },
            showMyWallet(isShow){
                this.isShowMyWallet = !isShow;
            },
            showUserInfo(val) {
                this.isShowUserInfo = val
            },
            hideModal(hide) {
                this.isShowModal = hide;
                this.isShowWalletModal = hide;
            },
            setModalKey(key){
                this.modalKey = key;
            },
            async getUserDetail(){
                const res = await this.apis.getUserDetail({
                    'userId': getCookie('loginId')
                });
                loginUser['id'] = res['decrypt_data']['search']?.['id'];
            },
            async getOwnWallet(){
                if(sabongWalletInfo['walletOwn']) {
                    await walletAddress();
                }
            },
            ChangeMoneyBox(){
                this.isShowChangeMoney = !this.isShowChangeMoney;
            },
            openConvertModal() {
                this.modalTitle = this.$t("convert");
                this.walletModalKey = 'convert';
                this.isShowWalletModal = sabongWalletInfo['walletOwn'];
            },
            toggleLang() {
                this.isShowLang = !this.isShowLang
            },
            showLang() {
                this.isShowLang = true;
            },
            hideLang() {
                this.isShowLang = false;
            },
            toggleGuide() {
                this.isShowGuide = !this.isShowGuide;
            },
            showGuide() {
                this.isShowGuide = true;
            },
            hideGuide() {
                this.isShowGuide = false;
            }
        }
    });
</script>
<style scoped>
	.coinWrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.refreshBtn {
		cursor: pointer;
		margin-left: 3px;
        margin-top: 1px;
	}
	.connectWallet {
        display: flex;
        justify-content: center;
        align-items: center;
		font-weight: bold;
        width: 190px;
        height: 30px;
        border-radius: 10px;
        background-image: linear-gradient(73deg, #bc2424 27%, #e467b6 83%);
        gap: 6px;
	}
	.lock {
		cursor: not-allowed;
		opacity: 0.7;
	}
	.changeMoneyView {
		cursor: pointer;
	}
	.headerRightBox li.hide {
		display: none;
	}

    .slide-leave-active{
        height: 322px;
        transition: height .2s ease-in-out;
    }
    .slide-leave-to{
        height: 0;
        transition: height .2s ease-in-out;
    }

	.slideUserInfo-enter {

	}

	.sabongLogo2 {
		width: 260px;
	}

	.slideUserInfo-enter-active {
		animation: slide-in 1s ease-out forwards;
		/* forwards = element가 finishing position에 붙어놓음  */
		transition: opacity .5s;
	}

	.slideUserInfo-leave {

	}
	.slideUserInfo-leave-active {
		animation: slide-out 1s ease-out forwards;
		transition: opacity .5s;
		opacity: 0;
	}

	@keyframes slide-in {
		from {
			transform: translateX(-500px);
		}
		to {
			transform: translateX(0);
		}
	}

	@keyframes slide-out {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-500px);
		}
	}


    @keyframes slideDown {
        from {
            height: 0;
        }
        to {
            height: 322px;
        }
    }

	.lock{
		color: gray;
		cursor: not-allowed !important;
	}
    *{
        font-family: 'Folks', sans-serif;
        letter-spacing: .3px;
    }
	header{
		width: 100%;
		height: 110px;
		color: #fff;
		background-color: #2e3033;
	}
	.headerWrap{
        position: relative;
        display: flex;
        justify-content: flex-end;
		width: 100%;
		height: 70px;
        background-color: #141414;
	}
    .headerLeft{
        width: auto;
        height: 98px;
        background-color: #141414;
        padding: 0 9px;
        display: flex;
        align-items: center;
        top: 0;
        left: 0;
        position: absolute;
    }
    .headerLeft .sabongLogo{
        width: 80px;
        margin-right: 16px;
    }
    .headerLeft span.triangle{
        position: absolute;
        bottom: 0;
        right: -30px;
        display: block;
        width: 0;
        height: 0;
        border-top: 30px solid #141414;
        border-right: 30px solid transparent;
    }

    ul.userInfoBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        gap: 7px;
    }
    ul.userInfoBox > li{
        font-size: 16px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 100%;
    }
    ul.userInfoBox > li .welcome{
        color: #ff9100;
    }

    ul.moneyBox{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 18px;
    }
    ul.moneyBox li{
        display: flex;
        flex-direction: column;
        gap: 7px;
        text-align: right;
    }
    ul.moneyBox li span{
        margin-left: 8px;
    }
    ul.moneyBox li span:nth-child(2){
        color: #ffffff;
    }

    .walletAlert{
        /*display: none;*/
        position: absolute;
        top: 49px;
        right: 6px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #ff2121;
    }

    .headerWrap .btnBox{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding-right: 34px;
    }
    .headerWrap .btnBox div{
        width: auto;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
    }
    /* 기능구현 미완성 */
    .btnBox button.hide{
        cursor: not-allowed;
        opacity: 0.5;
    }



    .contentWrap{
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 40px;
    }

    .contentWrap .contentBox{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .headerRightBox{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 40px;
        margin-right: 34px;
    }
    .headerRightBox > li{
        font-size: 16px;
        padding: 0 10px;
        cursor: pointer;
        position: relative;
		white-space: nowrap;
        transition: all .3s ease;
    }
    .headerRightBox > li p,
    .headerRightBox > li img{
        transition: all .3s ease;
    }
    .headerRightBox .connectWallet:hover {
        gap: 15px;
    }
    .headerRightBox > li:hover p,
    .headerRightBox > li:hover > img{
        transform: scale(1.15);
        transform-origin: center;
    }

	.lngBox p > img{
		transform: rotate(180deg);
        vertical-align: middle;
        margin-left: 5px;
	}
	.lngBox .langWrap {
		position: absolute;
        top: 30px;
        right: -27px;
		background-color: #1b1b1b;
		outline: 1px solid #bbbbbb;
		width: 160px;
		z-index: 2;
        animation: slideDown .2s ease-in-out;
        overflow: hidden;
        transform: unset;
	}
    .lngBox:hover .langWrap{
        transform: unset;
    }
    .lngBox .langWrap .lang {
		display: flex;
		width: 100%;
		height: 43px;
		align-items: center;
		justify-content: flex-start;
        font-size: 16px;
	}
	.lngBox .langWrap .lang .flag {
		display: flex;
		margin: 5px;
		align-items: center;
	}
	.lngBox .langWrap .lang.selected {
		background-color: #3c4149;
	}
    .lang:hover{
        background-color: #3c4149;
    }
    .cancel {
        display: flex;
        width: 100%;
        height: 43px;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        justify-content: center;
    }
    .cancel:hover{
        background-color: #3c4149;
    }
	.inrBox{
		color: #ffce70;
	}
    /* symbol img */
    .inrBox.symbolBox {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    .openSlide{
        margin-left: 0;
        transition: all 1s ease;
    }
    .closeSlide{
        margin-left: -518px;
        transition: all 1s ease;
    }

    .LoginBox{
        width: 23px;
        height: 21px;
		margin-right: 10px;
        background-size: 15px;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .5s ease, scale .3s ease;
    }
    .LoginBox:hover{
        transform: scale(1.05);
        transform-origin: center;
    }
    .logoutTx{
        position: absolute;
        line-height: 30px;
        text-align: center;
        transition: all .3s ease;
		white-space: nowrap;
    }
    .logoutTx:hover{
        transform: scale(1.15);
        transform-origin: center;
    }
    .sabongHeader{
        padding-right: 20px !important;
		border: 0 !important;
    }


    .dropdownChangeMoney{
        color: #fff;
        vertical-align: middle;
        cursor: pointer;
        position: relative;
    }
    .dropdownChangeMoney > ol{
        width: 275px;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 30px;
        background-color: #1b1b1b;
        border: 1px solid #fff;
        list-style-type: none;
        z-index: 1;
    }
    .dropdownChangeMoney > ol > li{
        font-size: 14px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }
    .dropdownChangeMoney > ol > li > span{
        color: #ffce70;
    }
    .dropdownChangeMoney > ol > li:hover{
        background-color: #3c4149;
    }


    .demo-logo {
        font-family: 'Folks', sans-serif;
        font-size: 28px; 
        text-align: center; 
        color: #F1C164; 
        text-shadow: 2px 2px #16345A, 4px 4px #284D8E;
    }

    .guideModal {
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 20;
        width: 508px;
        height: auto;
        background-color: var(--modal-header-background-color);
        border-radius: 6px;
        border: 1px solid #bbb;
        cursor: default;
    }
    .guideModal .modalHeader {
        display: flex;
        flex-direction: row;
        font-size: 21px;
        align-items: center;
        justify-content: center;
        color: var(--modal-header-font-color);
        width: 100%;
        gap: 15px;
        height: 65px;
        line-height: 20px;
    }
    .guideModal .modalHeader .closeBtn {
        position: absolute;
        margin-right: 20px;
        right: 0;
    }
    .closeBtn > img{
        width: 32px;
    }
    .guideModal .modalHeader .icon {
        margin-left: 15px;
    }
    .guideModal .modalBody {
        /*background-color: #1b1b1b;*/
        width: 100%;
        height: 483px;
        background-size: 100% 100%;
        padding: 0 5px;
        white-space: normal;
    }
</style>
