<template>
    <!--히스토리 상세팝업-->
    <div
        class="historyWrap historyDetail"
        v-if="isShowHistoryDetail">
        <div class="modalFooterM">
            <div
                class="left"
                v-on:click.stop="goPage('prev')">
                <div class="prev">
                    {{ $t('prev') }}
                </div>
                <div class="prevBtn">
                    <img src="../../assets/btn/btn-arrow.png"/>
                </div>
            </div>
            <div
                class="center"
                v-on:click.stop="isShowHistoryDetail = false; $emit('showCloseBtn', true); $emit('showModalFooter', true);">
                {{ $t('toList') }}
            </div>
            <div
                class="right"
                v-on:click.stop="goPage('next')">
                <div class="next">
                    {{ $t('next') }}
                </div>
                <div class="nextBtn">
                    <img src="../../assets/btn/btn-arrow.png"/>
                </div>
            </div>
        </div>

        <!-- 안쪽 상세 히스토리 -->
        <div class="body box">
            <div class="row historyDetail">
                <div class="topContents">
                    <span
                        class="prev"
                        v-on:click.stop="goPage('prev')">
                        <img
                            alt="prev"
                            src="../../assets/icons/icon_history_arrow_L.png">
                    </span>
                    <div class="cntTxtBox">
                        <div class="text">
                            {{ $t('gameId') }}
                        </div>
                        <div class="gameId">
                            {{ selectedHistory['identifying_number'] }}
                        </div>
                    </div>
                    <span
                        class="next"
                        v-on:click.stop="goPage('next')">
                        <img
                            alt="next"
                            src="../../assets/icons/icon_history_arrow_R.png">
                    </span>
                </div>
                <div class="botContents">
                    <div class="infos">
                        <div class="text">
                            {{ $t('date') }}
                        </div>
                        <div class="value">
                            {{ selectedHistory['register_time'] }}
                        </div>
                    </div>
                    <div class="infos">
                        <div class="text">
                            {{ $t('bet') }}
                        </div>
                        <div
                            class="value"
                            v-number-format="selectedHistory['bet']">
                        </div>
                    </div>
                    <div class="infos">
                        <div>
                            <span class="profit">
                                {{ $t('win2') }}
                            </span>
                            <span class="andLine">
                                &#47;
                            </span>
                            <span class="loss">
                                {{ $t('loss') }}
                            </span>
                        </div>
                        <div
                            v-bind:class="{
                                'profit': String(selectedHistory['winLoss']).indexOf('-') === -1,
                                'loss': String(selectedHistory['winLoss']).indexOf('-') > -1,
                            }"
                            v-number-format="selectedHistory['winLoss']">
                        </div>
                    </div>
                </div>
            </div>
            <div class="centerContents">
                <div
                    class="result"
                    v-for="(rst, key) in results['cardResult']"
                    v-bind:key="key">
                    <div
                        class="header"
                        v-bind:class="rst['key']">
                        <div
                            class="cardSum"
                            v-bind:class="rst['key']">
                            {{ rst['sum'] }}
                        </div>
                        <div
                            class="title"
                            v-bind:class="rst['key']">
                            {{ $t(rst['key']) }}
                        </div>
                    </div>
                    <div
                        class="cards"
                        v-bind:class="rst['key']">
                        <div
                            class="card"
                            v-bind:class="getCardResult({'result': rst['cards']}, cardIdx)['color']"
                            v-for="(card, cardIdx) in rst['cards']"
                            v-bind:key="cardIdx">
                            <span class="mark">
                                <img v-bind:src="getCardImage(getCardResult({'result': rst['cards']}, cardIdx)['mark'])"/>
                            </span>
                            <span class="tx">
                                {{ getCardResult({'result': rst['cards']}, cardIdx)['tx'] }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="botContents">
                <div
                    class="result"
                    v-for="(rst, key) in results['result']"
                    v-bind:key="key">
                    <div v-bind:class="rst['key']">
                        {{ $t(rst['key']) }}
                    </div>
                    <div class="text">
                        {{ $t('bet') }}
                    </div>
                    <div v-number-format="rst['bet']">
                    </div>
                    <div class="text">
                        {{ $t('result') }}
                    </div>
                    <div v-number-format="rst['rst']">
                    </div>
                </div>
            </div>
        </div>
        <!-- 안쪽 상세 히스토리 end -->
        <div class="modalFooter">
            <div
                class="center"
                v-on:click.stop="isShowHistoryDetail = false; $emit('showCloseBtn', true); $emit('showModalFooter', true);">
                {{ $t('toList') }}
            </div>
        </div>
    </div>
    <div
        v-else
        class="historyWrap">
        <div class="body">
            <div
                class="header"
                style="display: none">
                <div class="select">
                    {{ $t('historyWarn') }}
                    <select
                        v-model="curPage"
                        v-on:change.stop="getBaccaratHistory()">
                        <option
                            v-bind:value="pageCount"
                            v-for="pageCount in totalCnt"
                            v-bind:key="pageCount">
                            {{ pageCount }}
                        </option>
                    </select>
                </div>
            </div>
            <div
                class="row noData"
                v-if="!loading['modal'] && historyList.length == 0">
                {{ $t('noData')}}
            </div>

            <!-- 바깥쪽 히스토리 -->
            <div
                class="row"
                v-for="(history, idx) in historyList"
                v-bind:key="idx"
                v-on:click.stop="showHistoryDetail(history)">
                <div class="topContents">
                    <span>&#8226;</span>
                    <div class="text">
                        {{ $t('gameId') }}
                    </div>
                    <div class="gameId">
                        {{ history['gameId'] }}
                    </div>
                </div>
                <div class="botContents">
                    <div class="infos">
                        <div class="text">
                            {{ $t('date') }}
                        </div>
                        <div class="value">
                            {{ history['date'] }}
                        </div>
                    </div>
                    <div class="infos">
                        <div class="text">
                            {{ $t('bet') }}
                        </div>
                        <div
                            class="value"
                            v-number-format="history['bet']">
                        </div>
                    </div>
                    <div class="infos">
                        <div>
                            <span class="profit">
                                {{ $t('win2') }}
                            </span>
                            <span class="andLine">
                                &#47;
                            </span>
                            <span class="loss">
                                {{ $t('loss') }}
                            </span>
                        </div>
                        <div
                            v-bind:class="{
                                'loss': String(history['winLoss']).indexOf('-') > -1,
                                'profit': String(history['winLoss']).indexOf('-') === -1
                            }"
                            v-number-format="history['winLoss']">
                        </div>
                    </div>
                </div>
            </div>
            <!-- 바깥쪽 히스토리 end -->
        </div>
        <!--        <div class="historyFooter">
            <span>Prev</span>
            <ul class="historyPageNum">
                <li>1</li>
                <li>&#47;</li>
                <li>13</li>
            </ul>
            <span>Next</span>
        </div>-->
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { setNumberFormat } from "@/js/utils";
    import { loading } from "@/js/reactive";
    import { getCardResult } from "@/js/common";
    import { DEALER_INFO, CARD_INFO_ARRAY, CARD_INFO_ARRAY_VALUE, FLAG_IMG, PLAYER_GRADE_IMG } from "@/js/const";
    export default defineComponent({
        data(){
            return {
                loading: loading,
                // 현재 히스토리 페이지
                curPage: 1,
                // 히스토리 선택박스 pageCount
                totalCnt: 10,
                CARD_INFO_ARRAY: CARD_INFO_ARRAY,
                historyList: [],
                selectedHistory: {},
                isShowHistoryDetail: false,
                results: {
                    'cardResult': {
                        'player': {
                            key: 'player',
                            cards: [],
                            sum: 0
                        },
                        'banker': {
                            key: 'banker',
                            cards: [],
                            sum: 0
                        }
                    },
                    'result': {
                        'p': {
                            key: 'p',
                            bet: 0,
                            rst: 0
                        },
                        'pp': {
                            key: 'pp',
                            bet: 0,
                            rst: 0
                        },
                        'tie': {
                            key: 'tie',
                            bet: 0,
                            rst: 0
                        },
                        'b': {
                            key: 'b',
                            bet: 0,
                            rst: 0
                        },
                        'bp': {
                            key: 'bp',
                            bet: 0,
                            rst: 0
                        }
                    }
                }
            }
        },
        directives: {
            numberFormat: {
                updated: (el, binding) => {
                    el.innerText = setNumberFormat(binding.value)
                },
                mounted: (el, binding) => {
                    el.innerText = setNumberFormat(binding.value)
                }
            }
        },
        beforeMount() {
            this.getBaccaratHistory();
        },
        methods: {
            getCardResult,
            initHistoryDetl() {
                this.selectedHistory['bet'] = 0;
                this.selectedHistory['winLoss'] = 0;
                this.results['result']['p']['bet'] = 0;
                this.results['result']['p']['rst'] = 0;
                this.results['result']['pp']['bet'] = 0;
                this.results['result']['pp']['rst'] = 0;
                this.results['result']['tie']['bet'] = 0;
                this.results['result']['tie']['rst'] =  0;
                this.results['result']['b']['bet'] = 0;
                this.results['result']['b']['rst'] = 0;
                this.results['result']['bp']['bet'] = 0;
                this.results['result']['bp']['rst'] = 0;
            },
            getCardColor(rst, idx) {
                let last = idx == 2 ? 'last' : '';
                return rst - 1 <= 25 ? `black ${last}` : `red ${last}`
            },
            async goPage(flag){
                if(flag === 'prev'){
                    this.selectedHistory = this.historyList[this.curHistoryIdx - 1 < 0 ? 0 : this.curHistoryIdx - 1];
                } else {
                    this.selectedHistory = this.historyList[this.curHistoryIdx + 1 === this.historyList.length ? this.curHistoryIdx : this.curHistoryIdx + 1];
                }
                await this.showHistoryDetail(this.selectedHistory);
            },
            async getBaccaratHistory() {
                this.historyList = [];
                const res = await this.apis.getBaccaratHistory({'pages': this.curPage });
                console.log(res);
                if(res?.decrypt_data?.value.length > 0){
                    res['decrypt_data']['value'].forEach(data => {
                        this.historyList.push({
                            'gameId': data['identifying_number'],
                            'date': data['register_time'],
                            'bet': data['bet_amount_banker']
                                + data['bet_amount_banker_pair']
                                + data['bet_amount_player']
                                + data['bet_amount_player_pair']
                                + data['bet_amount_tie'],
                            'winLoss': data['increase_amount']
                        })
                    });
                }
            },
            getCardImage(mark){
                return require(`@/assets/mark/${mark}`);
            },
            async showHistoryDetail(selected){
                this.initHistoryDetl();
                // 모바일에서 Modal.vue에서 사용
                this.$emit('showModalFooter', false);
                this.$emit('showCloseBtn', false);
                this.curHistoryIdx = this.historyList.findIndex(history => history['gameId'] === selected['gameId']);
                const res = await this.apis.getBaccaratHistoryDetail({ 'identifying_number': selected['gameId']});
                if(res?.decrypt_data?.value){
                    const data = res['decrypt_data']['value'];
                    this.selectedHistory = {
                        'bet': data['bet_amount_banker']
                            + data['bet_amount_banker_pair']
                            + data['bet_amount_player']
                            + data['bet_amount_player_pair']
                            + data['bet_amount_tie'],
                        'winLoss': data['increase_amount'],
						...data
                    };

                    this.results['cardResult']['player']['sum'] = 0;
                    this.results['cardResult']['player']['cards'] = [];
                    this.results['cardResult']['banker']['cards'] = [];
                    this.results['cardResult']['banker']['sum'] = 0;

                    data['player_card_list'].split(',').forEach((card, idx) => {
                        if(idx === data['player_card_list'].length - 1){
                            this.results['cardResult']['player']['cards'].unshift(card);
                        } else {
                            this.results['cardResult']['player']['cards'].push(card);
                        }
                        this.results['cardResult']['player']['sum'] += CARD_INFO_ARRAY_VALUE[card - 1];
                    });

                    this.results['cardResult']['banker']['cards'] = data['banker_card_list'].split(',');
                    data['banker_card_list'].split(',').forEach(card => {
                        this.results['cardResult']['banker']['sum'] += CARD_INFO_ARRAY_VALUE[card - 1];
                    });

                    // 10 자리 넘어가면 1의자리만 표시
                    this.results['cardResult']['player']['sum'] = this.results['cardResult']['player']['sum'] >= 10 ? String(this.results['cardResult']['player']['sum']).substr(1,1) : this.results['cardResult']['player']['sum'];
                    this.results['cardResult']['banker']['sum'] = this.results['cardResult']['banker']['sum'] >= 10 ? String(this.results['cardResult']['banker']['sum']).substr(1,1) : this.results['cardResult']['banker']['sum'];

                    this.results['result']['p']['bet'] = data['bet_amount_player'];
                    this.results['result']['p']['rst'] = data['win_amount_player'];
                    this.results['result']['pp']['bet'] = data['bet_amount_player_pair'];
                    this.results['result']['pp']['rst'] = data['win_amount_player_pair'];
                    this.results['result']['tie']['bet'] = data['bet_amount_tie'];
                    this.results['result']['tie']['rst'] =  data['win_amount_tie'];
                    this.results['result']['b']['bet'] = data['bet_amount_banker'];
                    this.results['result']['b']['rst'] = data['win_amount_banker'];
                    this.results['result']['bp']['bet'] = data['bet_amount_banker_pair'];
                    this.results['result']['bp']['rst'] = data['win_amount_banker_pair'];

                    this.isShowHistoryDetail = true;
                }
            },
        }
    })
</script>
<style scoped>
	.historyWrap {
		width: 100%;
		height: 100%;
		color: white;
		font-weight: bold;
	}
    .historyWrap:not(.historyDetail,.inGameModalWrap){
        padding-bottom: 60px;
    }
	.historyWrap .header {
		display: flex;
		align-items: center;
		width: 100%;
		height: 80px;
		margin: 0 auto;
	}
	.historyWrap .header select {
		width: 80px;
		height: 30px;
	}
	.historyWrap .body {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		overflow-y: scroll;
		font-size: 16px;
        font-weight: 500;
        gap: 5px;
        padding: 5px;
        background-color: #1b1b1b;
        border-radius: unset !important;
	}
	.historyWrap.historyDetail .body {
		display: flex;
		flex-direction: column;
		width: 98%;
		height: 83%;
		margin: 0 auto;
        overflow: auto;
		padding: 10px 0;
		font-size: 20px;
        border: none;
	}

	.historyWrap .body .row {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 140px;
		padding: 10px;
		gap: 20px;
		font-size: 20px;
		cursor: pointer;
        border: solid 2px #484848;
	}

	.row.noData {
		height: 100% !important;
		align-items: center;
		justify-content: center;
	}

	.historyWrap .body .row.historyDetail {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 140px;
		padding-top: 10px;
		padding-bottom: 10px;
		gap: 20px;
		background-color: transparent;
		border: none;
	}

	.historyWrap .body .row .topContents,
	.historyWrap .body .row .botContents {
		flex: 1;
		display: flex;
		width: 100%;
		height: 30px;
		gap: 10px;
		text-align: center;
		flex-direction: row;
		align-items: center;
		justify-content: left;
	}
	.historyWrap .body .row .topContents {
		border-bottom: 1px solid #6c6c6c;
	}
    .topContents .text{
		width: 110px;
        border-right: 2px solid #6c6c6c;
        white-space: nowrap;
        padding-right: 10px;
    }
	.topContents .gameId {
		text-align: left;
        margin-left: 10px;
	}
    .prev {
        cursor: pointer;
        position: sticky;
        left: 0;
        top: 0;
    }
    .next {
        cursor: pointer;
        position: sticky;
        right: 0;
        top: 0;
    }
    .topContents > .prev > img,
    .topContents > .next > img{
        height: 30px;
        vertical-align: middle;
    }
    .cntTxtBox{
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    .cntTxtBox:lang(ko){
        font-size: 18px;
    }

	.historyWrap .body .botContents {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: inherit;
	}

	.historyWrap .body .botContents .result {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
        margin: 10px 0;
	}

	.historyWrap .body .botContents .result div:first-child {
		border-right: 2px solid #484848;
	}

	.result div.p,
	.result div.pp {
		color: var(--modal-history-player-color);
	}

	.result div.b,
	.result div.bp {
		color: var(--modal-history-banker-color);
	}

	.result div.tie {
		color: var(--modal-history-tie-color);
	}


    .historyWrap .body .centerContents {
		width: 100%;
		height: 128px;
		min-height: 150px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
        padding-bottom: 15px;
        margin: 10px 0;
	}

	.historyWrap .centerContents .result {
		width: 300px;
		text-align: center;
	}
	.historyWrap .centerContents .result .header {
		width: 100%;
		height: 45px;
		justify-content: center;
        margin-bottom: 10px;
	}
	.historyWrap .centerContents .result .header.banker {
		flex-direction: row-reverse;
	}
	.historyWrap .centerContents .result .header .title.player {
		color: var(--modal-history-player-color);
	}
	.historyWrap .centerContents .result .header .title.banker {
		color: var(--modal-history-banker-color);
	}
	.historyWrap .centerContents .result .cards {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 10px;
	}
	.cards .card {
		width: 46px;
		height: 63px;
		background-color: white;
		border-radius: 8px;
		position: relative;
		color: black;
		white-space: pre-line;
		font-size: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

    .cards.player .card.last {
        transform: rotate(90deg);
    }

	.cardSum {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;
		background-color: #222222;
        border-radius: 6px;
	}

	.cardSum.player {
		margin-right: 50px;
		border: 1px solid var(--modal-history-player-color);
	}

	.cardSum.banker {
		margin-left: 50px;
		border: 1px solid var(--modal-history-banker-color);
	}

	.historyWrap .body .row .botContents div {
		flex: 1;
	}

	.historyWrap ::v-deep(.text) {
		color: var(--modal-history-font-color);
	}

	.historyWrap ::v-deep(.infos) {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		white-space: nowrap;
	}
    .andLine{
        color: #5ccfff;
        margin: 0 2px;
    }

	.historyWrap ::v-deep(.modalFooter) {
		display: flex;
		width: 95%;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 80px;
		margin: 0 auto;
	}

	.modalFooter .center {
		flex: 1;
		text-align: center;
		margin: 0 auto;
		cursor: pointer;
		font-size: 20px;
	}

	.modalFooter .left, .modalFooter .right {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 15px;
		cursor: pointer;
		font-size: 20px;
	}
	.nextBtn {
		transform: scaleX(-1);
		-moz-transform: scaleX(-1);
		-o-transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
	}

    .modalFooterM{
        display: none;
    }

	.profit {
		color: #2be000 !important;
	}
	.loss {
		color: #ff121a !important;
	}
	.black {
		color: black !important;
	}
	.red {
		color: #dd3d3d !important;
	}
	.card .mark {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 20px;
        margin-left: 2px;
	}
    .mark > img{
        width: 16px;
    }
	.card .tx {
		font-size: 40px;
		position: relative;
		top: 10px;
	}

    /* 211029 한유정 추가 */
    .topContents:lang(ko){
        padding-left: 0 !important;
    }
    .topContents > .text:lang(ko){
        white-space: nowrap;
    }
    .result > .text{
        font-size: 16px;
    }
    .historyWrap .body .botContents .result div {
        text-align: center;
    }
    .result > div:nth-child(1){
        width: 25%;
    }
    .historyWrap .result > div:nth-child(2),
	.historyWrap .result > div:nth-child(4){
        width: 10%;
    }
    .result > div:nth-child(3){
        width: 25%;
    }
    .result > div:nth-child(5){
        width: 35%;
    }
    .historyWrap .body .row:lang(en) {
        font-size: 16px !important;
    }
    .historyWrap .body > .historyDetail > .topContents{
        padding-left: 0 !important;
        gap: 0 !important;
    }

    /* 21115 한유정 추가 */
    .historyFooter{
        height: 60px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 30px;
    }
    .historyFooter > span{
        border-radius: 10px;
        border: 1px solid #fff;
        font-size: 20px;
        padding: 5px 10px;
        cursor: pointer;
    }
    .historyPageNum{
        display: flex;
        column-gap:5px;
    }
    .inGameModalWrap .historyWrap{
        padding-bottom: 0 !important;
    }
    .inGameModalWrap .historyWrap.historyDetail .body{
        height: 91%;
    }
</style>