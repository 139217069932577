<template>
    <div
        class="baseRoadWrap"
        v-bind:class="{ 'mobile': isMobile() }"
        ref="RoadMap">
        <!--
        <div
            class="row"
            v-for="(row, rowCount) in sabongRoadMap[props.gameType]['beadRoad']?.['array']"
            v-bind:key="rowCount">
            <div
                class="cell"
                v-for="(score, cellCount) in row"
                v-bind:ref="(el) => setItemRef(el , rowCount , row.length , cellCount)"
                v-bind:class="[getScoreClass(score)]"
                v-bind:key="cellCount">
            </div>
        </div>-->
    </div>
</template>
<script setup>
    import { getScoreClass, sabongRoadMap, searchLastCell } from "@/js/scoreBoard/generator";
    import {ref, defineProps, nextTick, onMounted} from "vue";
    import { isMobile } from "@/js/utils";

    const RoadMap = ref(undefined);
    const props = defineProps({
        gameType: {
            type: String,
            default: 'pcc'
        }
    });

    onMounted(()=>{
        scrollMove();
    });

    let refs = [];
    const setItemRef = (el , count , length = 0 , nowLength) => {
        if(count === 0 && el !== null){
            refs.push(el);
            if(nowLength == (length-1)){
                scrollMove();		
            }
        }
    };
    const scrollMove = () => {
        nextTick(()=>{
            const setScrollRate = isMobile() ? 200 : 300;
            if(refs.map(el => el.classList.length).indexOf(1) > -1) {
                RoadMap.value.scrollLeft = refs[refs.map(el => el.classList.length).indexOf(1)].offsetLeft - setScrollRate;
            }
            refs = [];
        });
    };
</script>
<style scoped>
	::-webkit-scrollbar {
		width: 10px;  /* 세로축 스크롤바 길이 */
		height: 8px;  /* 가로축 스크롤바 길이 */
	}

	.baseRoadWrap {
        width: 100%;
		height: inherit;
		overflow-x: scroll;
		overflow-y: hidden;
		background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAABxJREFUKFNjZCAO/GckTh3DqEK8AQUOnv/EhCUAZm8KAZQzbbIAAAAASUVORK5CYII=") repeat #fff;
		background-size: 23.2px 23.2px;
		background-attachment: local;
	}
	.baseRoadWrap .row {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: row;
		scroll-snap-align: end;
	}
	.baseRoadWrap ::v-deep(.cell) {
		min-width: 23.2px;
		height: 23.2px;
		position: relative;
		border-radius: 50%;
		box-sizing: border-box;
	}
	.cell.p {
		border: 3px solid blue !important;
		background-color: blue;
	}
	.cell.b {
		border: 3px solid red !important;
		background-color: red;
	}
	.cell.cancel {
		border: 3px solid gray !important;
		background-color: gray;
	}
	.cell.draw {
		border: 3px solid green !important;
		background-color: green;
        
	}

/* 모바일 중국점 스크롤갱신 스타일 추가 22.01.25 박재형*/
    .baseRoadWrap.mobile .rowWrap{
        width: 576%;
    }
</style>