<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('change') }}</h1>
            <span
                class="exitImg"
                v-on:click.stop="$emit('exitModal')">
                <img
                    src="../../../assets/mobile/btn-close.png"
                    alt="close">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="changeTableWrap">
                <h2>Baccarat</h2>
                <div
                    class="changeTableList"
                    v-bind:class="{ 'selected': $route['params']['tableNo'] == room['table_id'].replace('Table', '')}"
                    v-bind:key="idx"
                    v-on:click.stop="enterRoom(room)"
                    v-for="(room, idx) in totalRoom">
                    <ul class="changeTableBox">
                        <li>{{ room['table_id'] }}</li>
                        <li class="money">{{ room['currency_code'] }}
                            <span v-number-format="room['player_min_bet']"></span>
                            -
                            <span v-number-format="room['player_max_bet']"></span>
                        </li>
                    </ul>
                    <div
                        class="soccerBoard"
                        v-bind:key="idx">
                        <keep-alive>
                            <ScoreBoard
                                v-bind:scoreBoard="getBigRoad(room['room_no'])"
                                v-bind:options="{ 'size': 's' }"/>
                        </keep-alive>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { totalRoom,loginUser } from "@/js/reactive";
    import { setNumberFormat, isMobile } from "@/js/utils";
    import { getBigRoad } from "@/js/scoreBoard/generator";
    import ScoreBoard from "@/components/common/ScoreBoard";

    export default defineComponent({
        props: ['roomNo'],
        components: {
            ScoreBoard
        },
        data() {
            return {
                curRoom: undefined,
                totalRoom: totalRoom['rooms'],
                loginUser: loginUser
            }
        },
        mounted() {
        },
        watch: {
            totalRoom: {
                deep:true,
                immediate: true,
                handler(rooms){
                }
            }
        },
        methods: {
            getBigRoad,
            isMobile,
            // 방 입장
            enterRoom(room) {
                this.$router.push({ name: 'inGame', params: JSON.parse(JSON.stringify({
                    roomNo: room['room_no'],
                    room: JSON.stringify(room),
                    tableNo: room['table_id'].replace('Table', ''),
                    index: room['index'] }))});
            },
        }
    });

</script>
<style scoped>
.money {
	color: #ffce70;
}
.sideMenuBox{
	position: absolute;
	right: 0;
	width: 75vw;
	color: #fff;
	font-size: 14px;
	z-index: 10;
    border: 1px solid #bbb;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 20px;
}
.sideMenuInner{
	padding: 0 1.5% 1.5% 1.5%;
	background-color: #2e3033;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 3%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}
/* 내용 */
.changeTableWrap{
	width: 100%;
	height: 475px;
	overflow-y: auto;
	padding: 2px 4px;
	background-color: #1b1b1b;
}
.changeTableWrap > h2{
	height: 41px;
	line-height: 41px;
	font-size: 16px;
	margin-left: 4px;
}
.changeTableList{
	border: 1px solid #6c6c6c;
	padding: 5px 7px;
	margin-bottom: 18px;
}
.changeTableList.selected {
	color: #ffce70;
}
.changeTableList ul{
	display: flex;
	justify-content: space-between;
}
.soccerBoard{
	width: 100%;
	height: 51px;
	background-color: #fff;
}
</style>