<template>
    <ul
        class="btnBox"
        v-bind:class="{ 'mobile': isMobile() }">
        <li
            v-bind:class="{
                'active': selectedTab == 'deposit'
            }"
            v-on:click.stop="$emit('changeTab', { tabKey: 'deposit', symbol: selectedSymbol })">
            {{ $t('deposit') }}
        </li>
        <li
            v-bind:class="{
                'active': selectedTab == 'withdrawal'
            }"
            v-on:click.stop="$emit('changeTab', { tabKey: 'withdrawal', symbol: selectedSymbol })">
            {{ $t('withdrawal') }}
        </li>
        <div
            class="symbolWrap"
            v-on:click.stop="clickSymbol">
            <img
                width="32"
                height="32"
                v-bind:src="selectedSymbol['icon']"/>{{ selectedSymbol['symbol'] }}
        </div>
    </ul>
</template>
<script setup>
    import {defineProps, reactive, ref, defineEmits} from "vue";
    import { isMobile } from "@/js/utils";
    import {walletEthGas, walletUsdPrice} from "@/js/inGame/sabongWallet";
    import { walletTransHistory } from "@/js/inGame/sabongWallet"
    const props = defineProps({
        selectedTab: {
            type: String,
            default: "deposit"
        }
    });

    const emit = defineEmits(['symbol']);
    const symbols = reactive({
        ETH: {
            icon: require('@/assets/icons/eth.png'),
            symbol: 'ETH',
            key: 'eth'
        },
        BNB: {
            icon: require('@/assets/icons/bnb.png'),
            symbol: 'BNB',
            key: 'bsc'
        }
    });
    const selectedSymbol = ref({
        icon: require('@/assets/icons/eth.png'),
        symbol: 'ETH',
        key: 'eth'
    });
    const isEth = ref(true);
    const clickSymbol = async (e) => {
        isEth.value = !isEth.value;
        emit('symbol', isEth.value);
        selectedSymbol.value = symbols[isEth.value ? 'ETH' : 'BNB'];
        await walletTransHistory(props.selectedTab, selectedSymbol.value['key']);
        
    }
</script>
<style scoped>
.symbolWrap {
	display: flex;
	align-items: center;
	color: white;
	justify-content: center;
	gap: 8px;
}
.symbolWrap:hover {
	cursor: pointer;
	opacity: 0.5;
}
*{
    font-family: 'Folks', sans-serif;
    letter-spacing: .3px;
}
.btnBox{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 20px 10px 0;
}

.btnBox li{
    width: 180px;
    height: 48px;
    line-height: 48px;
    background-image: linear-gradient(64deg, #4f5a6d 25%, #89959b 85%);
    font-size: 22px;
    color: #cbcbcb;
    text-align: center;
    border-radius: 10px 10px 0 0;
}
.btnBox li.active{
    border-bottom: 0;
    background-image: linear-gradient(64deg, #244dbc 25%, #3ca7e5 85%);
    color: #fff;
}
.btnBox.mobile{
    padding: 0 12px;
}
.btnBox.mobile li{
    width: 33.333%;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
}
/*mobile*/
.btnBox.mobile{
    padding: 0 4px;
}
.btnBox.mobile li{
    height: 42px;
    line-height: 42px;
    font-size: 18px;
}
.btnBox.mobile li.active{
    border-bottom: 0;
    background-image: linear-gradient(to bottom, #6696f6, #2352af);
}
</style>