<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>Help</h1>
            <span class="exitImg">
                <img
                    src="../../assets/mobile/btn-close.png"
                    alt="close"
                    v-on:click.stop="$emit('closeModal')">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="helpInnerWrap">
                <dl>
                    <div class="helpImgBox">
                        <img
                            src="../../assets/telegram.png"
                            alt="telegram">
                    </div>
                    <dt>Telegram</dt>
                    <dd>&#64;exceltechsupport</dd>
                </dl>
                <dl>
                    <div class="helpImgBox">
                        <img
                            src="../../assets/vg.png"
                            alt="Vinus Gaming">
                    </div>
                    <dt>Vinus Gaming</dt>
                    <dd>+255 32 020 0865</dd>
                </dl>
            </div>
        </div>
    </div>
</template>
<script>

</script>
<style scoped>
.sideMenuBox{
	width: 100%;
	height: 100vh;
	padding-bottom: 30%;
	color: #fff;
	font-size: 14px;
	background-color: #2e3033;
	position: fixed;
	top: 0;
	z-index: 30;
    overflow-y: hidden;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 22px;
}
.sideMenuInner{
	padding: 3%;
	background-color: #2e3033;
	height: 100%;
}
.helpInnerWrap{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 30px;
	height: auto;
	width: 100%;
    padding-top: 50%;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 4%;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/* 내용 */
.sideMenuInner dl{
	width: 40%;
	text-align: center;
	font-size: 15px;
}
.sideMenuInner dt{
	color: #f4d7a1;
	margin-bottom: 10px;
}
.sideMenuInner dd{
	color: #c8c5c1;
}
.helpImgBox img {
	width: 64px;
	height: auto;
	object-fit: contain;
}
.helpImgBox{
	width: 64px;
	height: 64px;
	display: flex;
	align-items: center;
	margin: 0 auto 21px auto;
}
</style>