<template>
    <div
        v-bind:lang="$i18n.locale"
        class="lang"
        v-bind:class="{ 'langMobile':isMobile() }">
        <router-view v-bind:key="$route.fullPath"/>
    </div>
    <transition name="fade-modal">
        <Modal v-if="store.getters['modal/isModalOpen']"/>
    </transition>
    <transition name="sidemenu-hide">
        <SideMenu v-if="store.getters['modal/isSideMenuOpen']"/>
    </transition>
</template>
<script setup>
    import "@/css/common.css";
    import "@/css/main.css";
    import "@/css/media.css";
    import Modal from "@/components/modals/renew/Modal";
    import { isMobile } from "@/js/utils";
    import { initData } from "@/js/common";
    import {getCurrentInstance, onMounted, watch, computed, onUnmounted} from "vue";
    import { err, setting }      from "@/js/reactive";
    import { useI18n } from "vue-i18n";
    import {  setCookie } from "@/js/utils";
    import SideMenu from "@/components/popup/SideMenu";
    import NoSleep from "nosleep.js";
    let noSleep = new NoSleep();
    document.addEventListener("click", function enableNoSleep () {
        document.removeEventListener("click", enableNoSleep, false);
        noSleep.enable();
    }, false);

    import { useStore } from "vuex";
    const store = useStore();

    const { proxy } = getCurrentInstance();
    const i18n = useI18n();

    onMounted(() => {
        console.log('app version:'+process.env.VUE_APP_VERSION);
        initData();
        window.addEventListener('beforeunload', initData);
        setCookie('version' , process.env.VUE_APP_VERSION);
    });
    onUnmounted(() => {
        window.removeEventListener('beforeunload', initData);
    });
    // 토스트 메시지 (AXIOS 요청 응답값 보여줌)
    watch(() => err.msg, (msg) => {
        if (msg) {
            proxy.$toast.show(msg, {
                type     : "info",
                position : "top-right",
                duration : 5000,
            });
            err["msg"]        = undefined;
            err["errPageMsg"] = msg;
        }
    }, {
        deep : true,
    });

    // 톰혼 게임런처Url language설정
    watch(() => setting.locale, (val) => {
        i18n.locale.value = val;
    }, {
        deep : true,
    });

</script>
<style scoped>
.lang {
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.langMobile {
	display: block;
}


.fade-modal-enter-active,
.fade-modal-leave-active {
    transition: opacity 0.5s ease;
}

.fade-modal-enter-from,
.fade-modal-leave-to {
    opacity: 0;
}

.sidemenu-hide-enter-active,
.sidemenu-hide-leave-active {
    transition: opacity .1s ease;
}

.sidemenu-hide-enter-from,
.sidemenu-hide-leave-to {
    opacity: 0;
}

.modalContainer {
    z-index: 100;
}

.sidemenu {
    z-index: 3;
}

</style>
