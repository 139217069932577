import { reactive } from "vue";
import { apis, interApis } from "@/repository/repository";
import { CARD_INFO_ARRAY_VALUE, CARD_INFO_ARRAY } from "@/js/const";
import { showModal, modals } from "@/js/modal/modals";
import { getCookie } from "@/js/utils";
import moment from "moment";

/**
 * @name:  PCC 쪽에서 red 일시, wala 승 blue 일시 meron 으로 넘어오는이슈로 가공해주는 함수를 만듬
 * @param:  data Object 선택한 히스토리 데이터
 */
export const getWinColor = (data) => {
    let winColor = !data['rooster'] ? 'process' : data['rooster'];
    return winColor;
}

/**
 * @name: 사봉 히스토리 정보들
 */
export let historyData = reactive({
    curPage: 1,
    totalPage: 0,
    totalCnt: 0,
    curNavIdx: 0,
    historyList: [],
    selectedHistory: {},
    gameIdx: undefined
});

/**
 * @name: 사봉히스토리데이터 초기화
 */
export const initSabongHistoryData = () => {
    historyData = reactive({
        curPage: 1,
        totalPage: 0,
        totalCnt: 0,
        curNavIdx: 0,
        historyList: [],
        selectedHistory: {},
        gameIdx: undefined
    });
}

/**
 * @name: 커맨드들 가져오기
 * @params: type String 사봉 게임 타입들(pcc, 인터내셔널)
 */
export const getHistoryCmd = (type) =>{
    return type == 'sabong' ? sabongHistoryCmd : historyStore;
}

/**
 * @name: 사봉 배팅히스토리 커맨드
 */
const sabongHistoryCmd = {
    fn: {
        /**
         * @name: 히스토리정보 불러오기
         * @param: gameIdx int pcc 0, international 1, wsa 2, onebet 3
         */
        setHistory: async (gameIdx) => {
            if(typeof gameIdx != 'undefined'){
                historyData.gameIdx = gameIdx;
            }
            historyData.historyList = [];
            const res = await interApis.getBetHistory({
                page: historyData.curPage,
                limit: 10,
                game: historyData.gameIdx
            });
            if(res){
                historyData.totalCnt = res.total;
                historyData.totalPage = Math.ceil(res.total / 10);
                historyData.historyList = res.history.map(data => {
                    data['bet_amount'] = data['bet_draw'] + data['bet_meron'] + data['bet_wala'];
                    data['winnings'] = data['win_draw'] + data['win_meron'] + data['win_wala'];
                    data['winnings'] = data['adjustment_state'] == 0 ? 0 : data['winnings'] == 0 ? -(data['bet_amount']) : data['winnings'] -  data['bet_amount']
                    return data;
                });
            }
        },
        /**
         * @name: 히스토리 상세 가져오기
         */
        setHistoryDetl: (selected) => {
            historyData.curNavIdx = historyData.historyList.findIndex(history => history['index'] === selected['index']);
            historyData.selectedHistory = selected;
            if(!modals['sabongHistoryDetl']){
                showModal('sabongHistoryDetl', true);
            }
        },
        /**
         * @name: 히스토리 상세 다음페이지 이전페이지 이동
         * @name: flag String 페이지 다음/이전 여부 flag
         */
        goPage: async (flag) => {
            if(flag == 'prev' && historyData.curNavIdx == 0){
                await sabongHistoryCmd.fn.goPrevPage();
                historyData.curNavIdx = historyData.curPage == 1 ? -1 : 10;
            } else if(flag == 'next' && historyData.curNavIdx == 9){
                await sabongHistoryCmd.fn.goNextPage();
                historyData.curNavIdx = -1;
            }
            historyData.selectedHistory = flag === 'prev' ?
                historyData.historyList[historyData.curNavIdx - 1 < 0 ? 0 : historyData.curNavIdx - 1] :
                historyData.historyList[historyData.curNavIdx + 1 === historyData.historyList.length ? historyData.curNavIdx : historyData.curNavIdx + 1];
            sabongHistoryCmd.fn.setHistoryDetl(historyData.selectedHistory);
        },
        /**
         * @name: 히스토리 리스트 다음페이지 이동
         */
        goNextPage: async () => {
            if(historyData.curPage + 1 > historyData.totalPage){
                return;
            }
            historyData.curPage = historyData.curPage + 1
            await sabongHistoryCmd.fn.setHistory();
        },
        /**
         * @name: 히스토리 리스트 이전페이지 이동
         */
        goPrevPage: async () => {
            if(historyData.curPage == 1){
                return;
            }
            historyData.curPage = historyData.curPage - 1
            await sabongHistoryCmd.fn.setHistory();
        }
    },
}


export const historyStore = {
    data: reactive({
        curPage: 1,
        totalCnt: 10,
        curNavIdx: 0,
        historyList: [],
        selectedHistory: {}
    }),
    fn: {
        /**
         * @name: 히스토리정보 불러오기
         */
        setHistory: async () => {
            historyStore.data.historyList = [];
            const res = await apis.getHistory({ 'pages': historyStore.data.curPage});
            if(res?.decrypt_data?.value.length > 0){
                res['decrypt_data']['value'].forEach(data => {
                    historyStore.data.historyList.push({
                        'gameId': data['identifying_number'],
                        'date': data['register_time'],
                        'bet': data['bet_amount_banker']
                            + data['bet_amount_banker_pair']
                            + data['bet_amount_player']
                            + data['bet_amount_player_pair']
                            + data['bet_amount_tie'],
                        'winLoss': data['increase_amount']
                    })
                });
            }
        },
        /**
         * @name: 히스토리 상세 가져오기
         */
        setHistoryDetl: async (selected) => {
            historyStore.data.curNavIdx = historyStore.data.historyList.findIndex(history => history['gameId'] === selected['gameId']);
            const res = await apis.getHistoryDetl({ 'identifying_number': selected['gameId']});
            if(res?.decrypt_data?.value){
                const data = res['decrypt_data']['value'];
                let results = {
                    'cardResult': {
                        'player': {
                            key: 'player',
                            cards: [],
                            sum: 0
                        },
                        'banker': {
                            key: 'banker',
                            cards: [],
                            sum: 0
                        }
                    },
                    'result': {
                        'p': {
                            key: 'p',
                            bet: 0,
                            rst: 0
                        },
                        'pp': {
                            key: 'pp',
                            bet: 0,
                            rst: 0
                        },
                        'tie': {
                            key: 'tie',
                            bet: 0,
                            rst: 0
                        },
                        'b': {
                            key: 'b',
                            bet: 0,
                            rst: 0
                        },
                        'bp': {
                            key: 'bp',
                            bet: 0,
                            rst: 0
                        }
                    }
                };
                data['player_card_list'].split(',').forEach((card, idx) => {
                    if(idx === data['player_card_list'].length - 1){
                        results['cardResult']['player']['cards'].unshift(card);
                    } else {
                        results['cardResult']['player']['cards'].push(card);
                    }
                    results['cardResult']['player']['sum'] += CARD_INFO_ARRAY_VALUE[card - 1];
                });

                results['cardResult']['banker']['cards'] = data['banker_card_list'].split(',');
                data['banker_card_list'].split(',').forEach(card => {
                    results['cardResult']['banker']['sum'] += CARD_INFO_ARRAY_VALUE[card - 1];
                });
                // 10 자리 넘어가면 1의자리만 표시
                results['cardResult']['player']['sum'] = results['cardResult']['player']['sum'] >= 10 ? String(results['cardResult']['player']['sum']).substr(1,1) : results['cardResult']['player']['sum'];
                results['cardResult']['banker']['sum'] = results['cardResult']['banker']['sum'] >= 10 ? String(results['cardResult']['banker']['sum']).substr(1,1) : results['cardResult']['banker']['sum'];
                results['result']['p']['bet'] = data['bet_amount_player'];
                results['result']['p']['rst'] = data['win_amount_player'];
                results['result']['pp']['bet'] = data['bet_amount_player_pair'];
                results['result']['pp']['rst'] = data['win_amount_player_pair'];
                results['result']['tie']['bet'] = data['bet_amount_tie'];
                results['result']['tie']['rst'] =  data['win_amount_tie'];
                results['result']['b']['bet'] = data['bet_amount_banker'];
                results['result']['b']['rst'] = data['win_amount_banker'];
                results['result']['bp']['bet'] = data['bet_amount_banker_pair'];
                results['result']['bp']['rst'] = data['win_amount_banker_pair'];

                historyStore.data.selectedHistory = {
                    ...results,
                    'bet': data['bet_amount_banker']
                        + data['bet_amount_banker_pair']
                        + data['bet_amount_player']
                        + data['bet_amount_player_pair']
                        + data['bet_amount_tie'],
                    'winLoss': data['increase_amount'],
                    ...data
                }
            }
            showModal('historyDetl', true);
        },
        /**
         * @name: 히스토리 상세 다음페이지 이전페이지 이동
         * @name: flag String 페이지 다음/이전 여부 flag
         */
        goPage: async (flag) => {
            historyStore.data.selectedHistory = flag === 'prev' ?
                historyStore.data.historyList[historyStore.data.curNavIdx - 1 < 0 ? 0 : historyStore.data.curNavIdx - 1] :
                historyStore.data.historyList[historyStore.data.curNavIdx + 1 === historyStore.data.historyList.length ? historyStore.data.curNavIdx : historyStore.data.curNavIdx + 1];
            await historyStore.fn.setHistoryDetl(historyStore.data.selectedHistory);
        }
    },
}