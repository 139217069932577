import { reactive, computed, ref } from "vue";
import { getCookie } from "@/js/utils";
export const iscamOff = ref(false);

/**
 * @name: 전체 커런시정보
 */
export const currencyInfo = reactive({
    'data': []
})

/**
 * @name: WSA 사봉 방 리스트 / 로그인유저 정보
 */
export const sabongWsa = reactive({
    'rooms': [],
    'history': [],
    'loginUser': undefined,
    'enterRoom': {}
});

/**
 * @name: 원뱃 사봉 방 리스트 / 로그인유저 정보
 */
export const sabongOne = reactive({
    'rooms': [],
    'history': [],
    'loginUser': undefined,
    'enterRoom': {}
});

/**
 * @name: NCA 사봉 방 리스트 / 로그인유저 정보
 */
export const sabongNca = reactive({
    'rooms': [],
    'history': [],
    'loginUser': undefined,
    'enterRoom': {}
});

/**
 * @name: 인터내셔널 사봉 방 리스트 / 로그인유저 정보
 */
export const sabongInter = reactive({
    'rooms': [],
    'history': [],
    'loginUser': undefined,
    'enterRoom': {}
});

/**
 * @name: PCC(VINUS) 사봉 방 리스트 / 로그인유저 정보
 */
export const sabongPcc = reactive({
    'rooms': [],
    'history': [],
    'loginUser': undefined,
    'enterRoom': {}
});

/**
 * @name: 사봉 방 리스트 / 로그인유저 정보
 */
export const sabong = reactive({
    'rooms': [],
    'loginUser': undefined,
    'enterRoom': {}
});

export const dist = reactive({
    'walaDist': computed(() => {
        return ((sabong['enterRoom']['event_bet']['total_wala_bet'] / sabong['enterRoom']['event_bet']['total_bet']) * 100)?.toFixed(2) || '-';
    }),
    'meronDist': computed(() => {
        return ((sabong['enterRoom']['event_bet']['total_meron_bet'] / sabong['enterRoom']['event_bet']['total_bet']) * 100)?.toFixed(2) || '-';
    }),
});

/**
 * @name: 사봉 방별 칩정보들 (pcc, international, wpa)
 */
export const sabongChips = {
    'pcc': [],
    'inter': [],
    'wpa': [],
    'one': []
};

/**
 * @name: 사봉 테마별 칩정보들 
 */
export const chips = reactive({
    'Basic': [{
        'key': 'chipBlack',
        'tx': '1',
        'value': 1,
        'src': require('@/assets/chips/1_chip.png'),
        'cmsKey': 'Chip1',
    },
    {
        'key': 'chipBlue',
        'tx': '2',
        'value': 2,
        'src': require('@/assets/chips/2_chip.png'),
        'cmsKey': 'Chip2',
    },
    {
        'key': 'chipGreen',
        'tx': '5',
        'value': 5,
        'src': require('@/assets/chips/3_chip.png'),
        'cmsKey': 'Chip3',
    },
    {
        'key': 'chipPink',
        'tx': '25',
        'value': 25,
        'src': require('@/assets/chips/4_chip.png'),
        'cmsKey': 'Chip4',
    },
    {
        'key': 'chipPurple',
        'tx': '100',
        'value': 100,
        'src': require('@/assets/chips/5_chip.png'),
        'cmsKey': 'Chip5',
    },
    {
        'key': 'chipYellow',
        'tx': '500',
        'value': 500,
        'src': require('@/assets/chips/6_chip.png'),
        'cmsKey': 'Chip6',
    },
    {
        'key': 'chipRed',
        'tx': '1K',
        'value': 1000,
        'src': require('@/assets/chips/7_chip.png'),
        'cmsKey': 'Chip7',
    },],
    'Pro': [
        {
            'key': 'Btn1',
            'tx': '50',
            'value': 50,
            'cmsKey': 'Chip8',
        },
        {
            'key': 'Btn2',
            'tx': '100',
            'value': 100,
            'cmsKey': 'Chip9',
        },
        {
            'key': 'Btn3',
            'tx': '500',
            'value': 500,
            'cmsKey': 'Chip10',
        },
        {
            'key': 'Btn4',
            'tx': '1000',
            'value': 1000,
            'cmsKey': 'Chip11',
        },
        // {
        //     'key': 'Btn4',
        //     'tx': 'All in',
        //     'value': computed(()=>loginUser['balance']),
        //     'cmsKey': 'Chip11',
        // },
    ],
});

export const agentInfo = reactive({
    key: undefined,
});

// 로딩여부 판단
export const loading = reactive({
    'isLoading': true
});

// 딜러툴에서 주는 토픽정보들
export const topics = reactive({});

// 전체 방정보(게임별 모두 포함)
export const totalRoom = reactive({
    'rooms': []
});

// 로그인한 유저 정보들
export const loginUser = reactive({
    balance: 0,
    pccBalance: 0,
    currency: getCookie('currency'),
    id: undefined,
    pw: undefined,
    uuid: undefined,
});

// 요청 interval 타이머
export const timer = reactive({
    balance: undefined,
    reCamConnect: undefined
});

// 채팅메시지들
export let chatArr = reactive([]);

// 배팅비율들
export let distArr = reactive([]);

// axios 요청시, 응답 에러 메시지
export const err = reactive({
    msg: undefined,
    errPageMsg: undefined
});

// 전체적인 어플리케이션 설정할때 쓰려는 변수 언어팩이나 커런시 등등
export const setting = reactive({
    'locale': 'en'
});