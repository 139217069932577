<template>
    <div class="modalLog">
        <TabMenu
            v-on:symbol="symbol"
            v-on:changeTab="changeTab"
            v-bind:selectedTab="selectedTab"/>
        <div
            class="listBox"
            v-on:mouseup.stop="showDropDown = !showDropDown">
            <p>
                {{ $t(selectedCol['tx']) }}
            </p>
            <span><img src="@/assets/icons/triangle-979.png"></span>
            <ul
                class="transfer TwoDepth"
                v-if="showDropDown">
                <span
                    v-on:mouseup.stop="changeOpt(col)"
                    v-for="(col, index) in cols"
                    v-bind:key="index">
                    <li>{{ $t(col['tx']) }}</li>
                    <hr>
                </span>
            </ul>
        </div>
        <div class="modalInner">
            <ul class="topBox">
                <li class="cellOne">
                    {{ $t('index') }}
                </li>
                <li class="cellTwo">
                    {{ $t('userId') }}
                </li>
                <li class="cellThr">
                    {{ $t(selectedCol['tx']) }}
                </li>
                <li class="cellFour">
                    {{ $t('registerDate') }}(GMT+9)
                </li>
            </ul>
            <div class="listWrap">
                <p v-if="history?.length == 0">
                    There is no information to receive
                </p>
                <ul
                    class="list"
                    v-for="(data, idx) in history"
                    v-bind:key="idx">
                    <li class="cellOne">
                        {{ idx + 1 }}
                    </li>
                    <li class="cellTwo">
                        <a 
                            v-bind:class="selectedSymbol"
                            target="_blank"
                            v-bind:href="blankHiperLink(selectedSymbol,'address',loginUser['id'])"
                        >
                            {{ setCollapsed(loginUser['id']) }}</a>
                    </li>
                    <li
                        class="cellThr"
                        v-on:mouseup.stop="selectedCol['tx'] === 'transactionId' ? showTransaction(data): false">
                        {{ selectedCol[selectedTab](data) }}
                    </li>
                    <li class="cellFour">
                        {{ moment(data['timeStamp'] * 1000).format('YYYY-MM-DD HH:mm:ss') }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script setup>
    import {computed, defineComponent, onMounted, ref} from "vue";
    import { isMobile, setCollapsed } from "@/js/utils";
    import { sabongWalletInfo, walletTransHistory } from "@/js/inGame/sabongWallet.js"
    import TabMenu from "@/components/layout/sabongTab";
    import moment from "moment";
    import store from "@/store";

    const cols = [
        {
            'tx': 'toTransferCurrency',
            'deposit': (data) => {
                return data['toCurrency']
            },
            'withdrawal': (data) => {
                return data['toCurrency']
            }
        },
        {
            'tx': 'fromTransferCurrency',
            'deposit': (data) => {
                return data['fromCurrency']
            },
            'withdrawal': (data) => {
                return data['fromCurrency']
            },
        },
        {
            'tx': 'resultAmount',
            'deposit': (data) => {
                return data['afterAmount']
            },
            'withdrawal': (data) => {
                return data['afterAmount']
            },
        },
        {
            'tx': 'transactionId',
            'deposit': (data) => {
                return setCollapsed(data['hash'],6,-6)
            },
            'withdrawal': (data) => {
                return setCollapsed(data['hash'],6,-6)
            },
        },
        {
            'tx': 'requestStatus',
            'deposit': (data) => {
                return data['change']
            },
            'withdrawal': (data) => {
                return data['change']
            },
        }
    ]
    const showDropDown = ref(false);
    const selectedTab = ref('deposit');
    const selectedSymbol = ref('ETH');
    const selectedCol = ref(cols[0]);
    
    const symbol = (value) => {
        selectedSymbol.value = value ? 'ETH' : 'BNB';
    }
    const showTransaction = (data) => {
        const href = blankHiperLink(selectedSymbol.value, 'tx', data['hash']);
        // let openNewWindow = window.open("about:blank");
        window.location = href;
        // openNewWindow.location.href = href;
    }
    const blankHiperLink = (symbol, search ,hash) => {
        const _symbol = symbol === 'BNB' ? 'https://www.bscscan.com/' : 'https://etherscan.io/';
        const _search = search;
        const _hash = hash; 
        return `${_symbol}${_search}/${_hash}`;
    }
    const history = computed(() => {
        return sabongWalletInfo['transHistory'];
    });
    const loginUser = computed(() => {
        return store.getters['login/getLoginUser'];
    });
    const changeTab = async (selected) => {
        selectedTab.value = selected.tabKey;
        await walletTransHistory(selectedTab.value, selected.symbol['key']);
    }
    const changeOpt = (col) => {
        selectedCol.value = col;
        showDropDown.value = false;
    }
    onMounted(async () => {
        await walletTransHistory(selectedTab.value, 'eth');
    });
</script>

<style scoped>
*{
    box-sizing: border-box !important;
    font-family: 'Folks', sans-serif;
    letter-spacing: .3px;
}
.modalLog{
    position: relative;
    width: 100vw;
    background-color: #3b3b3b;
    height: auto;
    padding: 16px 8px 0;
}

.modalInner{
    width: 100%;
    padding: 4px;
    text-align: center;
}
.modalInner li.cellOne{
    width: 10%;
}
.modalInner li.cellTwo{
    width: 20%;
}
.modalInner li.cellThr{
    width: 40%;
}
.modalInner li.cellFour {
	width: 20%;
}
.listBox{
    width: 100%;
    height: 40px;
    margin-bottom: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #1b1b1b;
    border-radius: 6px;
}
.listBox > span{
    height: 18px;
    position: absolute;
    right: 9px;
}
.listBox > span img{
    width: 22px;
    height: 18px;
}
.listBox > span.select img {
    transform: rotate(0);
}
.listBox > p{
    font-size: 18px;
    color: #5ccfff;
}
.TwoDepth{
    position: absolute;
    top: 40px;
    width: 100%;
    background: #1b1b1b;
    border: 2px solid #bbb;
    color: #5ccfff;
    border-radius: 6px;
    font-size: 18px;
}
.TwoDepth hr{
    margin: 0 6px;
    border: 1px solid #646464;
}
.TwoDepth li{
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
}
.TwoDepth li:last-child{
    width: 100%;
    border-bottom: 0;
}
.TwoDepth li.select{
    background: #646464;
}

ul.topBox{
    width: 100%;
    height: 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
ul.topBox li{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
    font-size: 18px;
    color: #5ccfff;
    text-align: center;
}

.listWrap{
    width: 100%;
    height: calc(100vh - 220px);
    overflow-y: auto;
    margin-top: 22px;
    background-color: #1b1b1b;
    display: flex;
    flex-direction: column;
    border-radius: 6px 6px 0 0;
}
.listWrap ul{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: auto;
    color: #fafafa;
}
.listWrap ul li{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
    font-size: 18px;
    text-align: center;
    padding: 16px 0;
}

.deleteBtn{
    width: 90%;
    height: 19px;
    border-radius: 4px;
    border: 1px solid #c8c8c8;
    background-color: #bf2727;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yellow{
    color: #ffaa2a;
}
.green{
    color: #1dc422;
}
.red{
    color: #ff3d3d;
}
    /* userID 클릭 시 bscscan.com으로이동 */
    a{
        text-decoration: none !important;
        color: #fff;
    }
</style>