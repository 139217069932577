<template>
    <div class="sabongMenuBox">
        <div class="select">
            <div class="tabMenuIcon">
            </div>
            <p>{{ $t('sabong') }}1</p>
        </div>
        <div>
            <div class="tabMenuIcon">
            </div>
            <p>{{ $t('sabong') }}2</p>
        </div>
        <div>
            <div class="tabMenuIcon">
            </div>
            <p>{{ $t('sabong') }}3</p>
        </div>
        <div>
            <div class="tabMenuIcon">
            </div>
            <p>{{ $t('sabong') }}4</p>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped>
.sabongMenuBox {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 18px;
    gap: 10px;
    background-color: #2e3033;
}

.sabongMenuBox > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 60px;
    background-color: #6e747a;
    border: 1px solid #acacac;
    color: #fff;
    font-size: 20px;
    gap: 7px;
    cursor: pointer;
}
.sabongMenuBox .tabMenuIcon{
    width: 26px;
    height: 32px;
    background-image: url("../../assets/sabong/icon-btn-sabong-2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.sabongMenuBox > .select {
    background-color: #2056af;
    border: 1px solid #4387f0;
    color: #fff;
}
.sabongMenuBox > .select .tabMenuIcon{
    background-image: url("../../assets/sabong/icon-btn-sabong-1.png");
}
.sabongMenuBox > div > img {
    margin-right: 1px;
}
.sabongMenuBox > .select > img {
    margin-right: 3px;
}

</style>