export default {
  "baccarat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バカラ"])},
  "teenpatti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ティーンパティ"])},
  "dragonTiger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドラゴンタイガー"])},
  "holdem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホールドエム"])},
  "roulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ルーレット"])},
  "sicbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SICBO"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デイリー"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショップ"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歴史"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更テーブル"])},
  "betLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賭けの制限"])},
  "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サウンドのオン/オフ"])},
  "helpInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプ/情報"])},
  "lobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロビー"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出口"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雑談"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビデオ"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入"])},
  "fx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FX"])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["音楽"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高い"])},
  "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低い"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位階"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプC/S"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
  "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チュートリアル"])},
  "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P。ペア"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレーヤー"])},
  "tie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイ"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行員"])},
  "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B.ペア"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレーヤー"])},
  "banker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行員"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天秤"])},
  "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賭ける"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金額"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["準備ができている"])},
  "betReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賭ける"])},
  "betEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベント"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のラウンドが始まるまでお待ちください。"])},
  "noGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームリストなし。"])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓国語"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヒンディー語"])},
  "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国語"])},
  "vt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベトナム語"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイ語"])},
  "sp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペイン語"])},
  "would": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご購入なさいますか？"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期間"])},
  "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無制限"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日数"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いいえ。"])},
  "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントID"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供託"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退会する"])},
  "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賭ける"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払う"])},
  "win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["勝つ"])},
  "win2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["勝つ"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き寄せる"])},
  "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["損失"])},
  "lose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失う"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー情報"])},
  "gameId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームID"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付(UTC)"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前のバージョン"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
  "historyWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* 履歴を表示できるのは、最後の100行だけです。"])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示できるデータがありません。"])},
  "noRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、アクティブなテーブルはありません。"])},
  "chatInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャットメッセージの送信"])},
  "toList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一覧表示"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分"])},
  "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大値"])},
  "checkMinMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賭け金の限度額を確認してください"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オフ"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オン"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込み中"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表"])},
  "roundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ラウンドID"])},
  "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丸い"])},
  "betCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベットキャンセル"])},
  "sabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サボン"])},
  "betUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["元に戻す"])},
  "betRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前のラウンドベット設定"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻す"])},
  "rebet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リコール"])},
  "tutorialTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームのやり方"])},
  "tutorialTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレイヤターン"])},
  "tutorialTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バンカーターン"])},
  "tutorial1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カードAは数字1で計算され、10、J、Q、Kは0で計算されます。 残りのカードは、各カードの所有数で計算されます。"])},
  "tutorial2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10の単位を除いて、2つの数字の合計が考慮されます。 たとえば、5+8=13ではなく、3です。"])},
  "tutorial3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本的に、プレイヤーとバンカーはそれぞれ2枚のカードを受け取ります。 この時、2つの数字の合計が8または9になると、自然条件下で3番目のカードを受け取らずにゲームが終了する。"])},
  "tutorial4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["両方がNaturalでない場合、プレーヤは2枚のカードの合計に基づいて第3のカードを受け取るか否かを決定する。"])},
  "tutorial5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □選手番号の合計が6または7の場合:カードを受け付けない(スタンド)"])},
  "tutorial6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □選手番号の合計が0~5の場合:3枚目のカードを受領。"])},
  "tutorial7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" プレイヤーがスタンドの場合、"])},
  "tutorial8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □プレイヤーが6または7で、銀行員が6または7の場合:カードを受け取らない"])},
  "tutorial9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □プレイヤーが6、7で銀行員が0~5の場合:3枚目のカードを受領"])},
  "tutorial10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3枚目のカードが出たら"])},
  "tutorial11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □プレイヤーが0-5で銀行員が0-2の場合:3枚目のカードを受け取りました。"])},
  "tutorial12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □プレイヤーが0~5の場合、銀行員が3枚目の場合:3枚目のカードを受け取る(プレイヤーの3枚目が8枚目の場合を除く)"])},
  "tutorial13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □プレイヤーが0~5で銀行員が4の場合:3枚目のカードを受け取ります(3枚目のカードが0、1、8、9の場合を除く)。"])},
  "tutorial14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □プレイヤーが0~5で銀行員が5の場合:3枚目のカードを受け取ります(3枚目のカードが0、1、2、3、8、9の場合を除く)。"])},
  "tutorial15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □プレイヤーが0~5で銀行員が6の場合:3枚目のカードを受け取ります(3枚目のカードが0、1、2、3、4、5、8、9の場合を除く)。"])},
  "tutorial16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □プレイヤーが0~5で銀行員が7の場合:カードを受け取らない(スタンド)"])},
  "tutorial17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームのルールによると、2、3枚のうち9枚に近い方が勝ちです。"])},
  "tutorial18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テーブルによって多少異なるが、通常は2倍の打撃額を勝利側に返し、同点で勝利すれば8倍の打撃額を返す。"])},
  "tutorial19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バンカーは勝率が高いので、バンカーに勝てば5%の手数料を家に払う。"])},
  "msgErr1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メッセージが長すぎます。（最大100文字）"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フルスクリーン"])},
  "cam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カム"])},
  "lobbyExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了"])},
  "equip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["装備"])},
  "unequip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不用意"])},
  "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残高不足。"])},
  "dealerSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディーラースキン"])},
  "cardSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カードスキン"])},
  "dealerProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディーラープロファイル"])},
  "beInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中"])},
  "dealerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディーラー情報"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メニュー"])},
  "myInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイ情報"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デバイス"])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プラットフォーム"])},
  "lastIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後のIP"])},
  "lastDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最後のドメイン"])},
  "loginTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前回"])},
  "registerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録日"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regs.住所"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通貨"])},
  "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザID"])},
  "agentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エージェントID"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロファイル"])},
  "dealerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売店名"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好きな"])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チップ"])},
  "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレイ時間"])},
  "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバー"])},
  "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ディーラー"])},
  "meron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "wala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAW"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHITE"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "lastGameResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["前回のゲーム結果"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])},
  "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加"])},
  "memberList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバーリスト"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国名"])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グレード"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lv."])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変換"])},
  "deposit_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供託金"])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤退"])},
  "depositBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供託金"])},
  "transactionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トランザクション履歴"])},
  "depositWarningTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料は換算のために測定されますが、両替の際には測定されません。"])},
  "depositInfoTxt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SabongPoint（USD）をイーサリアムまたはBNBに変換できます。"])},
  "depositInfoTxt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["換算したい値を入力し、振替ボタンを押すと、両替要求時点に適した相場を適用して、沙峰ポイントに換算する。"])},
  "walletCreateTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サボンウォレットのコインは、ウォレット送信によって保持されます。 量を足すことができます。"])},
  "walletCreateTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vinussabongウォレットを作成します。"])},
  "depositSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預金記号"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記号"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価値"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガス"])},
  "copyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピーアドレス"])},
  "myWalletAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社のUSDアドレス"])},
  "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索引"])},
  "registerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録日"])},
  "requestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要求ステータス"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トランザクションID"])},
  "resultAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果金額"])},
  "withdrawalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金額"])},
  "fromTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["転送通貨から"])},
  "transferAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["振込金額"])},
  "toTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通貨を転送するには"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財布"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テーマ"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["州"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
  "gameHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲームの履歴"])},
  "fightNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイトNo."])},
  "betList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベットリスト"])},
  "gmt-mnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-MNL"])},
  "gmt-ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-IST"])},
  "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繰り返す"])},
  "betRooster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賭けのおんどり"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["転送"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレットへの接続"])},
  "walletHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレット履歴"])},
  "cancelInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配当率の異常で賭けはキャンセルされた"])},
  "cancelInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["試合の真の結果"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインインする"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員登録"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "noBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残高がありません"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "bannerContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このゲームはGamingの最も強力で最大のコンテンツ製品であり、現在最も人気があると予想されます."])},
  "bannerContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィリピン最高の権威ある企業と協力するSABONGコンテンツは、毎日中断なく生中継されます."])},
  "bannerContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィリピンで最も強烈でエキサイティングなゲームであるSABONGを体験することができます."])},
  "sabongGuideHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1.サボンゲーム"])},
  "sabongGuide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィリピンの闘鶏ゲームはタガログ語で「サボン」と呼ばれています。"])},
  "sabongGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィリピン人が最も楽しむ伝統的なスポーツの一つです。"])},
  "sabongGuide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッティングは「RED」か「BLUE」のどちらか、または両方です。"])},
  "sabongGuide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配当率は賭け金の金額によって変動が激しく、片方の賭け金が過度な集まりでゲームがキャンセルされることもある。"])},
  "sabongGuide5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sabongGuideHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.賭け事及び試合結果"])},
  "sabongGuide6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベッティングは、「BetOpen」がオンのときに開始され、LASTCallが出力されてから30秒間だけ開始されます。"])},
  "sabongGuide7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["はい、賭け事の時間になると賭けられません。"])},
  "sabongGuide8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賭けが終わればゲームが進行し、結果によって配当率に合う金額が決定される。"])},
  "sabongGuide9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賞金を受け取るか失うかです。"])},
  "sabongGuideHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3.無効なゲーム"])},
  "sabongGuide10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き分け試合"])},
  "sabongGuide11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["試合開始から10分後まで試合が決まらない場合は、"])},
  "sabongGuide12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["両方のニワトリが死んで、審判がゲームをプレイできないと判断した場合"])},
  "sabongGuide13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルゲーム"])},
  "sabongGuide14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鶏がゲームを進行する状態が良くなくてゲームを放棄した場合"])},
  "sabongGuide15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベットの不均衡により配当率がx1.20を超えない場合"])},
  "sabongGuide16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上記の場合、ベット金額が返還され、ゲームが無効になります"])}
}