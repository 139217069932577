<template>
    <div class="helpWrap">
        <div class="body">
            <div class="info">
                <div class="logo">
                    <img src="../../assets/telegram.png"/>
                </div>
                <div>
                    Excel Tech Support<br>
                    <span class="">
                        +63 919 076 2428
                    </span>
                </div>
            </div>
            <div class="info">
                <div class="logo telLogo">
                    <img src="../../assets/vg.png"/>
                </div>
                <div>
                    Vinus Gaming<br>
                    <span>+255 32 020 0865</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";

    export default defineComponent({
        data() {
            return {}
        }
    })
</script>
<style scoped>
	.helpWrap {
		display: flex;
		height: inherit;
		width: 100%;
		justify-content: center;
		position: relative;
		z-index: 30;
	}
	.helpWrap .body {
		display: flex;
		width: 100%;
		height: inherit;
		flex-direction: row;
		align-items: center;
	}

	.helpWrap .body .info {
		height: inherit;
		text-align: center;
        width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
/*

	.helpWrap .body .info div {
		flex: 1;
	}
	.helpWrap .body .info .logo {
		display: flex;
	}
	.helpWrap .body .info div:nth-child(2) {
		font-size: 14px;
		color: #f4d7a1;
		max-height: 50px;
        line-height: 70px;
	}
	.helpWrap .body .info div:nth-child(3) {
		max-height: 100px;
		font-size: 20px;
		color: #c8c5c1;
		position: relative;
		bottom: 16px;
		white-space: nowrap;
	}

*/
    .helpWrap .body .info div{
        font-size: 14px;
        color: #f4d7a1;
        line-height: 25px;
        text-align: center;
    }
    .helpWrap .body .info div > span{
        font-size: 20px;
        color: #c8c5c1;

    }
	.helpWrap .body .info:nth-child(2){
		position: relative;
		right: 15px;
	}
    .logo img{
        width: 100px;
        height: 100px;
        margin-top: 80px;
    }

    /*211124한유정 추가*/
    .logo{
        width: 100px;
        height: 100px;
    }
</style>