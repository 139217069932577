<template>
    <div class="modalBg">
        <div class="modalLog">
            <div class="modalLogTopBox">
                <h1>{{ $t('dealerInfo') }}</h1>
                <span class="exitImg">
                    <img
                        src="../../../assets/mobile/btn-close.png"
                        alt="close"
                        v-on:click.stop="$emit('hideDealerInfo')">
                </span>
            </div>
            <div class="modalInner">
                <div class="dealerInfoWrap">
                    <div class="dealerInfoTopBox">
                        <img
                            v-if="selectedDealer['img']"
                            v-bind:src="selectedDealer['img']">
                        <img
                            v-else
                            src="../../../assets/icons/lobby_table/dealer-none.png">
                    </div>
                    <div class="dealerInfoBtmBox">
                        <table>
                            <tr>
                                <td>{{ $t('dealer') }}</td>
                                <td>{{ selectedDealer['user_id'] }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('tip') }}</td>
                                <td v-number-format="selectedDealer['tip']"></td>
                            </tr>
                            <tr>
                                <td>{{ $t('playTime') }}</td>
                                <td v-number-format="selectedDealer['playTime']"></td>
                            </tr>
                            <tr>
                                <td>{{ $t('like') }}</td>
                                <td v-number-format="selectedDealer['like']"></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <ul class="dealerInfoIconBox">
                    <li>
                        <img
                            src="../../../assets/btn/btn-like.png"
                            alt="like">
                    </li>
                    <li>
                        <img
                            src="../../../assets/btn/btn-tip.png"
                            alt="tip">
                    </li>
                    <li>
                        <img
                            src="../../../assets/btn/btn-rank.png"
                            alt="rank"
                            v-on:click.stop="$emit('hideDealerInfo')">
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import {defineComponent} from "vue";
    export default defineComponent({
        props: ['selectedDealer'],
        data(){
            return {

            }
        }
    });

</script>
<style scoped>
.modalBg{
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 30;
}

.modalLog{
	position: relative;
	width: 80vw;
	background-color: #fff;
	border: 1px solid #fff;
	height: auto;
}
.modalLogTopBox > h1{
	font-size: 20px;
}

.modalLogTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #2e3033;
	padding: 1%;
	color: #fff;
	height: 48px;
}

.exitImg{
	position: absolute;
	right: 0;
	margin-right: 3%;
	height: 28px;
}
.exitImg img{
	width: 28px;
	height: auto;
}

.modalInner{
	padding: 0 8px;
	background-color: #2e3033;
}

/* 내용 */
.dealerInfoWrap{
	width: 100%;
	height: auto;
	padding: 2px 4px;
	background-color: #1b1b1b;
}
.dealerInfoTopBox{
	width: 100%;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}
.dealerInfoBtmBox{
	margin-bottom: 5px;
}
.dealerInfoTopBox > img{
	width: 146px;
}
.dealerInfoBtmBox table{
	width: 100%;
	color: #fff;
	font-size: 14px;
	text-align: center;
	border-spacing: 9px 5px;
	border: 1px solid #484848;
}
.dealerInfoBtmBox table tr{
	height: 20px;
}
.dealerInfoBtmBox table tr td:nth-child(1){
	border-right: 1px solid #6c6c6c;
	text-align: left;
}
.dealerInfoIconBox{
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}
.dealerInfoIconBox li img{
	width: 38px;
}

</style>