<template>
    <div class="videoBox">
        <!-- <div
            class="iframeWrap"
            v-if="!isVgsabong"> -->
        <div class="iframeWrap">

            <!--<iframe
                id="videoIframe"
                style="width: 100%; height: 100%; position: inherit;"
                v-bind:src="videoSrc[vendor]"
                frameborder="0"
                framespacing="0"></iframe>-->

            <video
                v-if="videoType[vendor] == 'hls'"
                id="videojs-player"
                style="width: 100%; height: 100%; position: absolute;"
                class="video-js vjs-default-skin vjs-live"
                playsinline
                webkit-playsinline
                x-webkit-airplay="deny"></video>

            <!--<video src="../../assets/testvideo.mp4" autoplay></video>-->
            <!--<iframe
                v-bind:src="videoIframeUrl"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameborder="0"
                framespacing="0"></iframe>
            </div>-->
            <!--<div
                class="iframeWrap"
                v-else>
                <div
                    v-if="videoType[vendor] == 'craff'"
                    id="videoPcc"
                    style="width: 100%; height: 100%; position: absolute;"></div>
                <div
                    v-if="videoType[vendor] == 'oven'"
                    id="videoNca"
                    style="width: 100%; height: 100%; position: absolute;"></div>

                <video
                    v-if="videoType[vendor] == 'hls'"
                    id="videojs-player"
                    style="width: 100%; height: 100%; position: absolute;"
                    class="video-js vjs-default-skin vjs-live"
                    playsinline
                    webkit-playsinline
                    x-webkit-airplay="deny"></video>
                <iframe
                    v-if="videoType[vendor] == 'iframe'"
                    id="videoIframe"
                    style="width: 100%; height: 100%;"
                    v-bind:src="videoSrc[vendor]"
                    frameborder="0"
                    framespacing="0"></iframe>
                <iframe
                    v-if="videoType[hostname] == 'cockxing'"
                    v-bind:src="videoSrc[hostname]"
                    allowfullscreen
                    frameborder="0"
                    width="560"
                    height="315"></iframe>
            </div>-->
        </div>
    </div>
</template>
<script>
    import { defineProps, ref, onMounted } from "vue";
    import craffstream from "@/plugins/craffstream";
    import OvenPlayer from "ovenplayer";
    import videojs from "video.js";
    import "video.js/dist/video-js.css";

    import ncaPreview from '@/assets/ncaPreview.mp4';
    import wsaPreview from '@/assets/wsa02.mp4';
    
    import mpPlay1 from '@/assets/mp4/1.mp4';
    import mpPlay2 from '@/assets/mp4/2.mp4';
    import mpPlay3 from '@/assets/mp4/LCA_58.mp4';

    const video = ref(null);

    export default {
        data() {
            return {
                isVgsabong: true,
                videoIframeUrl: '',
                player: null,
                hostname: location.hostname,
                videoType: {
                    'one': 'hls',
                    'inter': 'hls',
                    'wsa': 'hls',
                    //'pcc': 'craff',
                    'pcc': 'hls',
                    'nca': 'hls',
                    'vinuscockxing.com': 'cockxing',
                    '127.0.0.1': 'cockxing',
                },
                videoSrc: {
                    '127.0.0.1': 'https://cockxing.live:5443/WebRTCApp/play.html?name=468230175227795665466157',
                    /*'vinuscockxing.com': 'https://cockxing.live:5443/WebRTCApp/play.html?name=468230175227795665466157',
                    'one': "https://o2b001.secureswiftcontent.com/abet/abet_manifest.m3u8",
                    'inter': "https://d1892klpony6i.cloudfront.net/livecf/marvelous/playlist.m3u8",*/
                    /*				https://d1892klpony6i.cloudfront.net/livecf/marvelous/playlist.m3u8*/
                    /*		https://d1892klpony6i.cloudfront.net/livecf/maravelloso/playlist.m3u8*/
                    /*'wsa': wsaPreview, mpPlay1
                    'nca': ncaPreview,*/
                    'vinuscockxing.com': ncaPreview,
                    'pcc': mpPlay1,
                    'inter': ncaPreview,
                    'one': ncaPreview,
                    'wsa': mpPlay2, 
                    'nca': mpPlay3,
                    /*'nca': 'wss://wssnca.casinotechnologiesgroup.com:8443/app/stream?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=zHwIwoUPqzvO-_jNeMmYs4htxQM',*/
                }
            }
        },
        props: {
            'vendor': {
                type: String,
                default: 'pcc'
            }
        },
        mounted() {
            //this.videoIframeUrl = "https://iframe.dacast.com/live/649b838f-ded4-a7ad-6179-c58a51ebf35e/dfa36d4b-b27f-7ad3-f7cd-960f2c18ba79";
            
            this.player = videojs("videojs-player", {
                html5: {
                    hls: {
                        overrideNative: true
                    },
                    nativeVideoTracks: true,
                    nativeAudioTracks: true,
                    nativeTextTracks: true
                },
                controls: true,
                liveui: false,
                autoplay: true,
                loop: true,
                suppressNotSupportedError: true,
                notSupportedMessage: 'No live stream available',
                sources: [
                    {
                        //type: this.vendor == 'wsa' || this.vendor == 'nca' ? "video/mp4" : "application/x-mpegURL",
                        type: "video/mp4",
                        src: this.videoSrc[this.vendor]
                    }
                ]
            }, () => {
                console.log('onplayerReady', this);
            });

            
            
            //this.videoIframeUrl = "https://iframe.dacast.com/live/649b838f-ded4-a7ad-6179-c58a51ebf35e/dfa36d4b-b27f-7ad3-f7cd-960f2c18ba79";
                  
            // if(location.hostname.indexOf('vgsabong.com') == -1 && process.env.NODE_ENV == 'production' && location.hostname.indexOf('vinuscockxing.com') == -1){
            //     this.isVgsabong = false;
            //     this.videoIframeUrl = `http://vgsabong.com/streaming/${this.vendor}`;
            //     return;
            // }
            // if (this.videoType[this.vendor] == 'craff') {
            //     craffstream.getStream.attachPlayer('videoPcc', 'pcc', 'ramoj');
            // }
            // if (this.videoType[this.vendor] == 'hls') {
            //     this.player = videojs("videojs-player", {
            //         html5: {
            //             hls: {
            //                 overrideNative: true
            //             },
            //             nativeVideoTracks: true,
            //             nativeAudioTracks: true,
            //             nativeTextTracks: true
            //         },
            //         controls: true,
            //         liveui: false,
            //         autoplay: true,
            //         loop: true,
            //         suppressNotSupportedError: true,
            //         notSupportedMessage: 'No live stream available',
            //         sources: [
            //             {
            //                 type: this.vendor == 'wsa' || this.vendor == 'nca' ? "video/mp4" : "application/x-mpegURL",
            //                 src: this.videoSrc[this.vendor]
            //             }
            //         ]
            //     }, () => {
            //         console.log('onplayerReady', this);
            //     });
            // }
            // if (this.videoType[this.vendor] == 'oven') {
            //     const player = OvenPlayer.create("videoNca", {
            //         "autoStart": true,
            //         "autoFallback": true,
            //         "mute": false,
            //         "sources": [
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream2?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=F2sV9m-HzbE6WxWGDBSSdANCu6Q"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=zHwIwoUPqzvO-_jNeMmYs4htxQM"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream1?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=FxBpnDAbkOvpBpyB9yV1Ds8pWHQ"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream3?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=9guhFOHx_Ka8Ks95L5ux2CSOVS8"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream4?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=zzSznDB5xDgYXHTk2RvNHy5UqnM"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream5?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=_qacMyL00XHU41GedpiWSZF7-uk"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream6?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=GFSXZvQ-Ae_rqOVKS9bPnfmHvXA"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream7?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=GMj4qThRAu7bpZ0aK2Pk6Cou4nY"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream8?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=0MtMQ84tSeA7U3qzzHnsuTCrHJI"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream9?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=OqLYbMmZgFKJP44No_gjNFzFxao"
            //             },
            //             {
            //                 "type": "webrtc",
            //                 "file": "wss://wssnca.casinotechnologiesgroup.com:8443/app/stream10?policy=eyJ1cmxfZXhwaXJlIjoyNjQ2MDY0MDAwMDAwfQ&signature=BqBsLXiE1CZ7Pc1sbl5oS2h76z0"
            //             }]
            //     });
            // }
        },
        beforeUnmount() {
            if (this.player) {
                this.player.dispose();
            }
        }
    }
</script>
<style>
    .videoBox {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
    }
	.iframeWrap {
		z-index: 10;
		position:relative;max-width:100%;height:100%;overflow: hidden;
	}
	.iframeWrap iframe {
		position:absolute;width:100%;height:100%;top: 0;left: 0;
	}

    .op-wrapper.ovenplayer.op-fullscreen {
        position: unset !important;
    }
</style>
