<template>
    <div class="chatWrap">
        <div class="chatList">
            <div
                class="chat"
                v-for="(data, idx) in chat"
                v-bind:key="idx">
                <div
                    class="id"
                    v-if="loginId != data['id']">
                    {{ data['id'] }} {{ data['room'] }}
                </div>
                <div
                    class="msgBox"
                    v-bind:class="{ 'myMsg': loginId == data['id'] }">
                    <div class="msg">
                        {{ data['msg'] }}
                    </div>
                    <div class="time">
                        {{ data['time'] }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="isErrShow"
            class="sendMsgErr">
            {{ $t('msgErr1') }}
        </div>
        <div class="inputBox">
            <div class="input">
                <input
                    v-model="msg"
                    v-bind:placeholder="$t('chatInfo')"
                    v-on:keyup.enter="send()"/>
            </div>
            <div
                class="sendBtn"
                v-on:click.stop="send()">
                <img
                    src="../../assets/btn/send.png"
                    width="41"
                    height="41"/>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { chat } from "@/plugins/mqtt";
    import { getCookie } from "@/js/utils";
    import { chatArr } from "@/js/reactive";
    import moment from 'moment';
    import { sendMessage } from "@/js/common";

    export default defineComponent({
        data(){
            return {
                msg: '',
                isErrShow: false,
                chatArr: chatArr,
                chat: [],
                loginId: getCookie('loginId')
            }
        },
        watch: {
            chatArr: {
                immediate: true,
                deep: true,
                handler(arr){
                    this.chat = [];
                    arr.forEach(data => {
                        let info = data.split('/');
                        this.chat.push({
                            'msg': info[0],
                            'time': info[1],
                            'id': info[2],
                            'room': `(Room ${info[3]})`
                        });
                    });
                    this.chat = this.chat.reverse();
                }
            }
        },
        methods: {
            send(){
                if(this.msg.length >= 100){
                    this.isErrShow = true;
                    setTimeout(() => { this.isErrShow = false; }, 1000);
                    return;
                }
                sendMessage(this);
            }
        }
    })
</script>
<style scoped>
	.chatWrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		min-height: 100%;
		height: 100vh;
		max-height: 480px;
		font-weight: bold;
		gap: 5px;
        position: relative;
        /*background-color: #1b1b1b;*/
	}

	.chatWrap .chatList {
		display: flex;
		gap: 10px;
		flex-direction: column-reverse;
		width: inherit;
        min-height: auto;
		height: inherit;
		overflow-y: scroll;
        background-color: #1b1b1b;
	}

	.chatWrap .chatList .chat {
		gap: 5px;
		width: 95%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
	}

	.chatWrap .chatList .chat .id {
		font-size: 16px;
		color: var(--modal-chat-placeholder-color);
	}

	.chatWrap .chatList .msgBox {
        display: flex;
        flex-direction: row;
        position: relative;
        min-height: 40px;
        align-items: center;
        color: #fff;
    }

	.chatWrap .chatList .msgBox.myMsg {
		flex-direction: row-reverse;
	}

	.chatWrap .chatList .msgBox .time {
        bottom: -12px;
		left: 16px;
		position: relative;
        transform: translateX(-50%);
        color: var(--modal-chat-placeholder-color);
        font-size: 10px;
	}
    .chatWrap .chatList .msgBox .msg {
        width: 50%;
        text-overflow: ellipsis;
        white-space: normal;
        text-align: left;
        word-wrap: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        border-radius: 18px;
        padding: 10px 20px;
        margin: 5px 0px 5px 5px;
        background-color: transparent;
		outline: 1px solid #bbbbbb;
    }

	.msgBox.myMsg .msg {
		background-color: #3b3b3b !important;
	}

	.chat.myMsg {
		float: right;
	}

	.chatWrap .inputBox {
        position: sticky;
        bottom: 0;
		width: 100%;
        min-height: 70px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		/*background-color: #3b3b3b;*/
	}
	.chatWrap .inputBox .input {
        width: 459px;
        height: 40px;
        background-color: black;
        border-radius: 30px;
        bottom: 0;
	}
	.sendBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 0;
		margin-right: 10px;
        cursor: pointer;
	}
	.sendBtn img {
		display: flex;
	}

	input {
		width: 100%;
		height: inherit;
		font-size: 20px;
		box-sizing: border-box;
		background-color: transparent;
		border:none;
		color: white;
        padding-left: 20px;
        padding-right: 60px;
	}
	input::placeholder {
		color: var(--modal-chat-placeholder-color);
	}
    .sendMsgErr{
        position: absolute;
        bottom: 0;
        color: red;
        margin-left: 30px;
    }
</style>