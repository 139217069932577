import store from "@/store";
import { isMobile, decryptUrlData } from "@/js/utils";
import { logoutForQueryLogin  } from "@/js/common";

const checkRefferCode = async (to, from, next) => {
    store.commit('modal/initModal');
    store.commit('login/initCodeAndKey');

    let PKToParams = to.params['pk'];
    let UuidToParams = to.params['code'];

    if(!PKToParams) return next({path: '/error'});
    if(!UuidToParams) return next({path: '/error'});

    // 기존 로그인되어 있었던 기록을 지워준다.
    await logoutForQueryLogin();

    // 암호화된 url 컴포넌트 복호화.
    PKToParams = decodeURIComponent(PKToParams);
    UuidToParams = decodeURIComponent(UuidToParams);

    // 암호화된 data 복호화.
    PKToParams = decryptUrlData(PKToParams);
    UuidToParams = decryptUrlData(UuidToParams);

    if(!PKToParams || !UuidToParams) return next({path: '/error'});
    
    store.commit('login/setPrivateKey', PKToParams);
    store.commit('login/setRefferCode', UuidToParams);

    store.commit('modal/showModal', { modalKey: 'signup', open: true, styles: { width: isMobile() ? true : '400px' }});
    next({path : '/lobby'});
}

export {
    checkRefferCode
}