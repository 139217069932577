<template>
    <div
        class="modalInner"
        v-bind:class="{ 'mobile': isMobile() }">
        <div class="coinInfoWrap">
            <div class="vgtInfo">
                <div class="coinInfo">
                    <img
                        src="../../../assets/icons/vgt.png"
                        alt="vgt">
                    <span>{{ loginUser['currency'] }}</span>
                </div>
                <p v-numberRound="loginUser['balance']"></p>
            </div>
        </div>
        <ul class="coinAddWrap">
            <li
                v-for="(wallet, idx) in wallets.list"
                v-bind:key="idx">
                <div>
                    <img
                        width="32"
                        height="32"
                        v-bind:src="wallet['icon']"
                        alt="ethIcon">
                    <span>{{ wallet['tx'] }}</span>
                </div>
                <div>
                    <p v-numberRound="wallet['balance']"></p>
                </div>
            </li>
        </ul>
    </div>
</template>
<script setup>
    import { isMobile, decompression } from "@/js/utils";
    import { loginUser } from "@/js/reactive";
    import {ethAmount, sabongWalletInfo, walletUsdPrice} from "@/js/inGame/sabongWallet.js"
    import {onMounted, reactive, computed, onBeforeMount} from "vue";

    const wallets = reactive({
        list: [
            {
                key: 'eth',
                tx: 'ETH',
                icon: require('@/assets/icons/eth.png'),
                balance: 0,
                price: 0
            },
            {
                key: 'bsc',
                tx: 'BNB',
                icon: require('@/assets/icons/bnb.png'),
                balance: 0,
                price: 0
            }
        ]
    })
    onBeforeMount(async () => {
        for(const [index, wallet] of wallets.list.entries()){
            wallets.list[index].balance = await ethAmount(wallet['key'], true);
            wallets.list[index].price = await walletUsdPrice(wallet['key'], true);
        }
    });

</script>
<style scoped>
    *{
        font-family: 'Folks', sans-serif;
        letter-spacing: .3px;
    }
    .modalInner{
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        gap:15px;
    }
    .coinInfoWrap{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap:15px;
        margin-top: 16px;
    }
    .coinInfoWrap>div{
        display: flex;
        justify-content: flex-end;
        font-size: 20px;
        gap:46px;
    }
    .coinInfoWrap .krwInfo{
        gap:29px;
    }
    .coinInfoWrap>div p{
        width: 270px;
        height: 36px;
        font-size: 20px;
        color:#fff;
        line-height: 36px;
        text-align: right;
        padding: 0px 12px;
        background-color:#1b1b1b ;
        border-radius: 6px;
    }
    .coinInfoWrap>div span{
        color:#fff;
        font-size: 22px;
    }
    .coinInfoWrap .krwInfo span,.coinInfoWrap .krwInfo p{
        color:#ffce70;
    }
    .coinInfoWrap .coinInfo{
        display: flex;
        align-items: center;
        gap:12px;
    }
    .coinAddWrap{
        display: flex;
        flex-direction: column;
        padding: 14px 5px;
        gap: 18px;
        height: 468px;
        background-color: #1b1b1b;
        overflow-y: auto;
        border-radius: 6px;
        margin-bottom: 5px;
    }
    .coinAddWrap li{
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        color:#fff;
        padding-right: 6px;
    }
    .coinAddWrap li p{
        font-size: 20px;
    }
    .coinAddWrap li div{
        display: flex;
        align-items: center;
        gap: 13px;
        font-size: 22px;
    }

    /* mobile */
    .modalInner.mobile{
        padding: 0 10px;
        font-size: 16px;
    }
    .modalInner.mobile .modalInner h3{
        margin: 0 0 8px 0;
        font-size: 18px;
    }
    .modalInner.mobile .coinInfoWrap{
        margin-top: 14px;
        gap: 12px;
    }
    .modalInner.mobile .coinInfoWrap > div{
        gap: 30px;
        width: unset !important;
    }
    .modalInner.mobile .coinInfoWrap > div p{
        width: 60%;
        height: 29px;
        line-height: 29px;
        padding: 0 10px;
    }
    .modalInner.mobile .coinInfoWrap > div img{
        width: 28px;
    }
    .coinInfoWrap .coinInfo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        text-align: left;
    }
    .modalInner.mobile .coinAddWrap{
        width: 100%;
        height: calc(100vh - 86px);
        overflow-y: auto;
        padding: 8px 8px;
        gap: 15px;
    }
    .modalInner.mobile .coinAddWrap img{
        width: 28px;
        cursor: pointer;
    }
</style>