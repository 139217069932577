export default {
  "baccarat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACCARAT"])},
  "teenpatti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEENPATTI"])},
  "dragonTiger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAGON\nTIGER"])},
  "holdem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOLDEM"])},
  "roulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROULETTE"])},
  "sicbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SICBO"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Table"])},
  "betLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet Limits"])},
  "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound On/Off"])},
  "helpInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help/Info"])},
  "lobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chatting"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "fx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FX"])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
  "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help C/S"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player guide"])},
  "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P.PAIR"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER"])},
  "tie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIE"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BANKER"])},
  "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B.PAIR"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLAYER"])},
  "banker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BANKER"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["READY"])},
  "betReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLACE YOUR BETS"])},
  "betEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BET END"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait until the next round begins."])},
  "noGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Game List."])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hindi"])},
  "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
  "vt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnamese"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thai"])},
  "sp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "would": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to buy?"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO."])},
  "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGENT ID"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEPOSIT"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WITHDRAW"])},
  "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BETS"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAY"])},
  "win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIN"])},
  "win2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Win"])},
  "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loss"])},
  "lose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOSE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAW"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Info"])},
  "gameId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game ID"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date(UTC)"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prev"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "historyWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* History can only be viewed for the last 100 rows."])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available to view."])},
  "noRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently, no tables are active."])},
  "chatInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Chatting Message"])},
  "toList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To List"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
  "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
  "checkMinMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the betting limit"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFF"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table"])},
  "roundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROUND ID"])},
  "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROUND"])},
  "betCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet cancel"])},
  "sabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SABONG"])},
  "betUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet undo"])},
  "betRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous round bet settings"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLOSE"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revert"])},
  "rebet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recall"])},
  "tutorialTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[How to play the game]"])},
  "tutorialTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Player Turn]"])},
  "tutorialTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Banker Turn]"])},
  "tutorial1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card A is calculated by the number 1, and 10, J, Q, and K are calculated by 0.\nThe remaining cards are calculated by the number of ownership of each card."])},
  "tutorial2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sum of the two numbers is considered except for the unit of 10.\nFor example, it is not 5 + 8 = 13, but 3."])},
  "tutorial3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basically, Player and Banker receive two cards each.\nAt this time, if the sum of the two numbers becomes 8 or 9,\nthe game will end without receiving the third card under Natural conditions."])},
  "tutorial4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If both are not Natural, the player decides whether to receive the third card or not based on the sum of the two cards."])},
  "tutorial5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the sum of the players' numbers is 6 or 7 : Do not accept cards (Stand)"])},
  "tutorial6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the sum of player numbers is 0-5: Received the 3rd card."])},
  "tutorial7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" If Player is Stand,"])},
  "tutorial8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the player is 6 or 7 and the banker is 6 or 7: Do not accept the card"])},
  "tutorial9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the player is 6 or 7 and the banker is 0 to 5 : Received the 3rd card"])},
  "tutorial10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the player gets the 3rd card,"])},
  "tutorial11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the player is 0-5 and the banker is 0-2: Received the 3rd card."])},
  "tutorial12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the player is 0 to 5 and the banker is 3 : Receive the 3rd card (except if the player's 3rd card is 8)"])},
  "tutorial13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the player is 0 to 5 and the banker is 4: Receive the 3rd card (except if the 3rd card is 0, 1, 8, 9)."])},
  "tutorial14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the player is 0 to 5 and the banker is 5 : Receive the 3rd card (except if the 3rd card is 0, 1, 2, 3, 8, 9)."])},
  "tutorial15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the player is 0 to 5 and the banker is 6 : Receive the 3rd card (except if the 3rd card is 0, 1, 2, 3, 4, 5, 8, 9)."])},
  "tutorial16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" □ If the player is 0 to 5 and the banker is 7: Do not accept the card (Stand)"])},
  "tutorial17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to the game rules, the one close to 9 out of 2 or 3 cards wins."])},
  "tutorial18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It varies slightly depending on the table, but usually returns twice the batting amount to the winning side,\nand if you win with a tie, you return eight times the batting amount."])},
  "tutorial19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because the Banker has a high winning rate, if you win by Banker, you will pay a 5% commission to the house."])},
  "msgErr1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The message is too long. (maximum length of 100 characters)"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Screen"])},
  "cam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAM"])},
  "lobbyExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXIT"])},
  "equip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equip"])},
  "unequip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unequip"])},
  "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient Balance."])},
  "dealerSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer Skin"])},
  "cardSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cards Skin"])},
  "dealerProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer Profile"])},
  "beInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be in use"])},
  "dealerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer Info"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENU"])},
  "myInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Info"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform"])},
  "lastIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last ip"])},
  "lastDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Domain"])},
  "loginTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last time"])},
  "registerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regs. Date"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regs. Address"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
  "agentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent ID"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "dealerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer Name"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like"])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
  "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Time"])},
  "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
  "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
  "meron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "wala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAW"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHITE"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "lastGameResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Game Result"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
  "memberList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member List"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lv."])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert"])},
  "deposit_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal"])},
  "depositBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
  "transactionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction History"])},
  "depositWarningTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The fee is measured for conversion, but it is not measured when exchanging money."])},
  "depositInfoTxt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can convert your Sabong Point (USD) into Ethereum or BNB"])},
  "depositInfoTxt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the value you want to convert and press the Transfer button to convert it to Sabong Point by applying the market price suitable for the time of exchange request."])},
  "walletCreateTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SABONG WALLET's coins are held through wallet transmission. You can add the quantity."])},
  "walletCreateTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a vinus sabong wallet"])},
  "depositSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Symbol"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "copyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Address"])},
  "myWalletAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company USD Address"])},
  "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
  "registerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Date"])},
  "requestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Status"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction ID"])},
  "resultAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result Amount"])},
  "withdrawalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal Amount"])},
  "fromTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Transfer Currency"])},
  "transferAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Amount"])},
  "reffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reffer"])},
  "toTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Transfer Currency"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "gameHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game History"])},
  "fightNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fight No."])},
  "betList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet List"])},
  "gmt-mnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-MNL"])},
  "gmt-ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-IST"])},
  "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat"])},
  "betRooster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bet Rooster"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to a Wallet"])},
  "walletHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet History"])},
  "cancelInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bet was canceled due to an abnormal dividend rate"])},
  "cancelInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["True result of the game"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit/Withdrawal"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "noBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Balance."])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "refferralCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refferal Commission"])},
  "bannerContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This game is the strongest and biggest content product of Gaming and is expected to be the popular at this time."])},
  "bannerContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborating with the best and prestigious companies in the Phillippines and its SABONG contents are broadcasted live uninterrupted everyday."])},
  "bannerContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Players can experience SABONG, the most intense and exciting game in the Phillippines, with the major leagues."])},
  "sabongGuideHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Sabong game"])},
  "sabongGuide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Philippine cock fighting game is called 'Sabong' in Tagalog."])},
  "sabongGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is one of the traditional sports that Filipinos enjoy the most."])},
  "sabongGuide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betting can be either 'RED' or 'BLUE' or both."])},
  "sabongGuide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The dividend rate fluctuates fluidly depending on the amount of betting, and the betting on one side is Excessive gatherings can cause the game to be canceled."])},
  "sabongGuide5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sabongGuideHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Betting and game results"])},
  "sabongGuide6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betting starts when \"Bet Open\" is on and only for 30 seconds after the LAST Call is output"])},
  "sabongGuide7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, you cannot bet when the betting time is up."])},
  "sabongGuide8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the betting is over, the game will proceed and the amount that matches the dividend rate will be determined according to the result."])},
  "sabongGuide9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive or lose the prize money."])},
  "sabongGuideHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Invalid game"])},
  "sabongGuide10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A draw game"])},
  "sabongGuide11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the game is not decided until 10 minutes after the start of the game."])},
  "sabongGuide12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When both chickens die and the referee decides that the game cannot be played."])},
  "sabongGuide13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation game"])},
  "sabongGuide14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the case of giving up the game due to the poor condition of the chicken conducting the game."])},
  "sabongGuide15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the dividend rate does not exceed x1.20 due to an imbalance in bets."])},
  "sabongGuide16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the above situation, the betting amount will be returned and the game will be invalidated."])}
}