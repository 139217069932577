export default {
  "baccarat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बैककारट"])},
  "teenpatti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टीएनपेटि"])},
  "dragonTiger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्रैगनटिगर"])},
  "holdem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हॉलडेम"])},
  "roulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रोलेट"])},
  "sicbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सिकबो"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतिदिन"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दुकान(L)"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इतिहास(H)"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तालिकाबदलें"])},
  "betLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शर्तसीमाएँ"])},
  "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ध्वनिचालू/बंद"])},
  "helpInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मदद/जानकारी"])},
  "lobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉबी"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहर"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गपशप"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वीडियो(V)"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ख़रीदें"])},
  "fx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एफएक्स"])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगीत(M)"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उच्च"])},
  "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मध्य"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मददसी/एस"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विकल्प(O)"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषाName"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉगआउट(L)"])},
  "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शिक्षणपाठ"])},
  "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पी.पी.एयर"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खिलाड़ी"])},
  "tie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टीआईई"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बैंकर"])},
  "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बी-पाहर"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खिलाड़ी"])},
  "banker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बैंकर"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संतुलन"])},
  "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शर्त"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रकम"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तैयार"])},
  "betReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनेदांवरखना"])},
  "betEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शर्तसमाप्त"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपयाअगलेदौरशुरूहोनेतकप्रतीक्षाकरें।"])},
  "noGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोईखेलसूचीनहीं."])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंग्रेजीभाषा"])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोरियाईName"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जापानीName"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हिन्दी"])},
  "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चीनीName"])},
  "vt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विएतनामी"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["थाईName"])},
  "sp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्पेनीName"])},
  "would": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्याआपखरीदनाचाहेंगे?"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य(P)"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्दकरें(C)"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पुष्टिकरें(o)"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय"])},
  "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असीमित"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिन(D)"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं."])},
  "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंटआईडी"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आईडी"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विचाराधीन"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विचारा"])},
  "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जुर्माना"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भुगतान(P)"])},
  "win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जीता"])},
  "win2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विन"])},
  "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नुकसान"])},
  "lose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोना"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आरेखित करें"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोक्ताजानकारी"])},
  "gameId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खेलआईडी"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तिथि(UTC)"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाम"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला(v)"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला(N)"])},
  "historyWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*इतिहासकेवलअंतिम100पंक्तियोंकेलिएदेखाजासकताहै."])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देखनेकेलिएकोईडाटाउपलब्धनहींहै."])},
  "noRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्तमानमें:कोईतालिकासक्रियनहींहै."])},
  "chatInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चैटिंगसंदेशभेजें"])},
  "toList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचीमेंजाएँ"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम"])},
  "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम"])},
  "checkMinMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपयाशर्तसीमाकीजाँचकरें"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चालू"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोडहोरहाहै"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सारणी"])},
  "roundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राउंडआईडी"])},
  "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राउंड"])},
  "betCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शर्तरद्द"])},
  "sabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सान्बोंग"])},
  "betUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शर्तपहलेजैसाकरना"])},
  "betRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछलाराउंडवाइससेटिंग्स"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बंद"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पीछेकरें(R)"])},
  "rebet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिकॉलकरें(R)"])},
  "tutorialTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खेलकैसेखेलें"])},
  "tutorialTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खिलाड़ीबारी"])},
  "tutorialTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बैंकरबारी"])},
  "tutorial1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्डएकीगणना1:और10:J:Q:औरKकीगणना0सेकीजातीहै.शेषकार्ड्सप्रत्येककार्डकेस्वामित्वकीसंख्यासेगणनाकीजातीहै।"])},
  "tutorial2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10कीइकाईकोछोड़करदोसंख्याओंकायोगमानाजाताहै।उदाहरणकेलिए:यह5+8=13:बल्कि3."])},
  "tutorial3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्यरूपसे:खिलाड़ीऔरबैंकरप्रत्येककोदोकार्डप्राप्तकरतेहैं।इससमय:यदिदोसंख्याओंकायोग8या9होजाताहै:तोखेलप्राकृतिकपरिस्थितियोंमेंतीसरेकार्डकोप्राप्तकिएबिनासमाप्तहोजाएगा."])},
  "tutorial4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदिदोनोंप्राकृतिकनहींहैं:तोखिलाड़ीयहतयकरताहैकिवहतीसरेकार्डकोप्राप्तकरेगायानहीं:दोनोंकार्डोंकीराशिकेआधारपर।"])},
  "tutorial5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ियोंकीसंख्याकायोग6या7है,कार्डस्वीकारनहींकरें(मानक)"])},
  "tutorial6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ीसंख्याओंकीराशि0-5है:3राकार्डपाया."])},
  "tutorial7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगरखिलाड़ीखड़ाहै:"])},
  "tutorial8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ी6या7सालकाहैऔरबैंकर6या7:कार्डकोस्वीकारनहींकरें"])},
  "tutorial9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ीकीआयु6या7हैऔरबैंकरकीआयु0से5है,3राकार्डपाया"])},
  "tutorial10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदिखिलाड़ीकोतीसराकार्डमिलताहै:"])},
  "tutorial11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ी0-5हैऔरबैंकर0-2है:3राकार्डपाया."])},
  "tutorial12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ी0से5सालकाहैऔरबैंकर3सालकाहै,3तारीखप्राप्तकरें(सिवायदाअगरखिलाड़ीका3वांकार्ड8है)"])},
  "tutorial13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ी0से5होऔरबैंकर4:3राकार्डप्राप्तकरें(सिवायउसपरअगर3राकार्ड0:1:8:9है)."])},
  "tutorial14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ी0से5हैऔरबैंकर5है,3राकार्डप्राप्तकरें(सिवायउसपरअगर3राकार्ड0:1:2:3:8:9है)."])},
  "tutorial15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ी0से5हैऔरबैंकर6है,3राकार्डप्राप्तकरें(सिवायउसपरअगर3राकार्ड0:1:2:3:4:5:8:9है)."])},
  "tutorial16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["□अगरखिलाड़ी0से5सालकेहैंऔरबैंकर7:कार्डकोस्वीकारनहींकरें(मानक)"])},
  "tutorial17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खेलकेनियमोंकेअनुसार:2मेंसे9कार्ड्समेंसेएककेकरीबजीतेगा।"])},
  "tutorial18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहतालिकाकेआधारपरथोड़ाबदलताहै:लेकिनआमतौरपरबल्लेबाजीकीराशिजीतनेवालेपक्षकोदुगनीलौटाताहै:औरयदिआपएकबराबरीसेजीततेहैं:तोआपबल्लेबाजीकीराशिआठगुनालौटातेहैं।"])},
  "tutorial19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चूंकिबैंकरकेपासएकउच्चजीतदरहै:इसलिएअगरआपबैंकरसेजीततेहैं:तोआपघरको5%कमीशनकाभुगतानकरेंगे।"])},
  "msgErr1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संदेशबहुतलंबाहै.(अधिकतम१००वर्णकीलंबाई)"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पूर्णस्क्रीन(F)"])},
  "cam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कैम"])},
  "lobbyExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाहरजाएँ"])},
  "equip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईकॉपकरें"])},
  "unequip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनियूप"])},
  "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किइनलोगोंकाताअज्जुबकमहै"])},
  "dealerSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डीलरस्किन"])},
  "cardSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्डस्किन"])},
  "dealerProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डीलरप्रोफ़ाइल"])},
  "beInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगमेंहोना"])},
  "dealerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डीलरसूचना"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेनू"])},
  "myInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेरीजानकारी"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["युक्ति"])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्लेटफॉर्म"])},
  "lastIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछलाआईपी"])},
  "lastDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिमडोमेन"])},
  "loginTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिमबार"])},
  "registerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रेग्स.तारीख़"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regs.पता"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा"])},
  "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोक्ताआईडी"])},
  "agentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एजेंटID"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोफ़ाइलः(P)"])},
  "dealerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डीलरनाम"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पसंदकरें(L)"])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["युक्ति"])},
  "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बजानेकासमय"])},
  "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्य"])},
  "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डीलर"])},
  "meron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "wala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAW"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHITE"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "lastGameResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिमखेलपरिणाम"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मा"])},
  "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डब्ल्यु"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शामिलहों"])},
  "memberList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यसूची"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश(n)"])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी:(G)"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एलवी."])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बदलें(C)"])},
  "deposit_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा"])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिटर्न"])},
  "depositBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा"])},
  "transactionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेनदेनइतिहास"])},
  "depositWarningTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शुल्कपरिवर्तनकेलिएमापाजाताहै:लेकिनइसेपैसेकेविनिमयकेदौरानमापानहींजाताहै।"])},
  "depositInfoTxt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपअपनेSabongPoint(USD)कोEthereumयाBNBमेंपरिवर्तितकरसकतेहैं"])},
  "depositInfoTxt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यभरेंजिसेआपपरिवर्तितकरनाचाहतेहैंऔरविनिमयनिवेदनकेसमयउपयुक्तबाजारमूल्यलागूकरकेउसेसबोंगपाइंटमेंबदलनेकेलिएहस्तांतरणबटनदबाएँ."])},
  "walletCreateTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सबोंगवॉलेटकेसिक्केबटुएकेप्रेषणकेमाध्यमसेरखेजातेहैं।आपमात्राजोड़सकतेहैं।"])},
  "walletCreateTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एकविनससबोंगबटुआबनाएँ"])},
  "depositSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमाचिह्न"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संकेत"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मान"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गैस"])},
  "copyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पतानक़लकरें(C)"])},
  "myWalletAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कंपनीUSDपता"])},
  "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूची"])},
  "registerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रजिस्टरतारीख़"])},
  "requestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुरोधस्थिति"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांजेक्शनआईडी"])},
  "resultAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिणाममात्रा"])},
  "withdrawalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मात्रा"])},
  "fromTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफरकरेंकरेंकरेंकरें"])},
  "transferAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरणराशि"])},
  "toTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्रांसफरकरेंकरेंकरेंकरेंकरेंकरें"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बटुआ"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रसंग(T)"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अब(N)"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुलयोग"])},
  "gameHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खेलइतिहास"])},
  "fightNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लडाईनंबर."])},
  "betList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शर्तसूची"])},
  "gmt-mnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-MNL"])},
  "gmt-ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-IST"])},
  "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दोहराएँ(R)"])},
  "betRooster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बेटरोस्टर"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लॉगइन(L)"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हस्तांतरण"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बटुआसेकनेक्टकरें"])},
  "walletHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बटुआइतिहास"])},
  "cancelInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असामान्यलाभांशदरकेकारणशर्तकोरद्दकरदियागयाथा।"])},
  "cancelInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खेलकावास्तविकपरिणाम"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करें"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन अप करें"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आज"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कल"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["माह"])},
  "noBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कुछ शेष नहीं"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
  "bannerContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गेम गेमिंग की सबसे मजबूत और सबसे बड़ी सामग्री पेशकश है, और अभी सबसे लोकप्रिय होने की उम्मीद है।"])},
  "bannerContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिलीपींस की कुछ सबसे प्रतिष्ठित कंपनियों के साथ सहयोग करते हुए, सबोंग सामग्री को हर दिन बिना किसी रुकावट के लाइव प्रसारित किया जाता है।"])},
  "bannerContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिलीपींस में सबोंग, सबसे तीव्र और रोमांचक खेल का अनुभव करें।"])},
  "sabongGuideHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. सबोंग खेल"])},
  "sabongGuide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिलीपीन कॉक फाइटिंग गेम को तागालोग में 'सबोंग' कहा जाता है।"])},
  "sabongGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह पारंपरिक खेलों में से एक है जिसका फिलिपिनो सबसे अधिक आनंद लेते हैं।"])},
  "sabongGuide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सट्टेबाजी या तो लाल या नीली या दोनों हो सकती है।"])},
  "sabongGuide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सट्टेबाजी की मात्रा के आधार पर लाभांश दर में उतार-चढ़ाव होता है, और एक तरफ सट्टेबाजी अत्यधिक सभाएं खेल को रद्द करने का कारण बन सकती हैं।"])},
  "sabongGuide5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sabongGuideHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. सट्टेबाजी और खेल के परिणाम"])},
  "sabongGuide6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सट्टेबाजी तब शुरू होती है जब \"बेट ओपन\" चालू होता है और केवल 30 सेकंड के बाद अंतिम कॉल आउटपुट होता है"])},
  "sabongGuide7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हां, जब सट्टेबाजी का समय खत्म हो जाए तो आप शर्त नहीं लगा सकते।"])},
  "sabongGuide8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब सट्टेबाजी समाप्त हो जाती है, तो खेल आगे बढ़ेगा और लाभांश दर से मेल खाने वाली राशि परिणाम के अनुसार निर्धारित की जाएगी।"])},
  "sabongGuide9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप पुरस्कार राशि प्राप्त करेंगे या खो देंगे।"])},
  "sabongGuideHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. अमान्य खेल"])},
  "sabongGuide10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ड्रा खेल"])},
  "sabongGuide11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब खेल शुरू होने के 10 मिनट बाद तक खेल तय नहीं होता है,"])},
  "sabongGuide12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जब दोनों मुर्गियां मर जाती हैं और रेफरी फैसला करता है कि खेल नहीं खेला जा सकता"])},
  "sabongGuide13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्दीकरण खेल"])},
  "sabongGuide14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खेल आयोजित करने वाले चिकन की खराब स्थिति के कारण खेल छोड़ने के मामले में;"])},
  "sabongGuide15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यदि दांव में असंतुलन के कारण लाभांश दर x1.20 से अधिक नहीं है"])},
  "sabongGuide16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपरोक्त स्थिति में, सट्टेबाजी की राशि वापस कर दी जाएगी और खेल को अमान्य कर दिया जाएगा।"])}
}