import { reactive } from "vue";
import { topics } from "@/js/reactive";
import { ScoreBoard } from "@/plugins/baccarat-score-board";
import { BACCARAT_SCORE_IDX } from "@/js/const";

export const searchLastCell = (roadMap) => {
    const column = roadMap.map(array => {
        return array.map(obj => typeof obj == 'object').filter(type => type === true).length;
    })
    const row = Math.max(...column);
    return row;
}

export const roadMap = {
    scoreBoards: {}
};
export const sabongRoadMap = {
    'inter': reactive({
        baseRoad: [],
        bigRoad: [],
        count:{
            'meron': 0,
            'wala': 0,
            'draw': 0,
            'cancel': 0
        }
    }),
    'vinusPcc': reactive({
        baseRoad: [],
        bigRoad: [],
        count:{
            'meron': 0,
            'wala': 0,
            'draw': 0,
            'cancel': 0
        }
    }),
    'one': reactive({
        baseRoad: [],
        bigRoad: [],
        count:{
            'meron': 0,
            'wala': 0,
            'draw': 0,
            'cancel': 0
        }
    }),
    'wsa': reactive({
        baseRoad: [],
        bigRoad: [],
        count:{
            'meron': 0,
            'wala': 0,
            'draw': 0,
            'cancel': 0
        }
    }),
    'nca': reactive({
        baseRoad: [],
        bigRoad: [],
        count:{
            'meron': 0,
            'wala': 0,
            'draw': 0,
            'cancel': 0
        }
    }),
}


/*'R8r', 'R8b', 'R8n', 'R8a', 'B8r', 'B8b', 'B8n', 'B8a', 'G8r', 'G8b', 'G8n', 'G8a'*/
/**
 * @name: 게임 결과
 */
const GAME_RESULT = {
    p: 'B',
    blue: 'R',
    wala: 'B',
    meron: 'R',
    close: 'C',
    b: 'R',
    red: 'B',
    tie: 'G',
    cancelled: 'C',
    cancel: 'C',
    draw: 'D',
}
/**
 * @name: 바카라 방 별 스코어보드 생성
 */
export const generateScoreBoard = () => {

    console.log('generateScoreBoard');
    let scoreBoards = {};
    let regex = /.*\/live_bacc\/RoomInfo/gi;
    let arr = ['']
    Object.entries(topics).forEach((topic) => {
        let arr = [];
        if (topic[0].match(regex)) {
            if(!topic[1]['score_board_record']){
                return;
            }
            topic[1]['score_board_record'].forEach(result => {
                // 결과
                let obj = {
                    'p': false,
                    'b': false,
                    'pp': false,
                    'bp': false,
                    'tie': false
                };
                result.split(',').forEach((score, idx) => {
                    // 당첨될경우 1로 리턴함
                    if(score == 1){
                        obj[BACCARAT_SCORE_IDX[idx]] = true;
                    }
                });
                arr.push(obj);
            });
            scoreBoards[topic[1]["room_no"]] = {
                'base': arr.reverse(),
                'beadRoad': [],
                'bigRoad': [],
                'bigEyeRoad': [],
                'smallRoad': [],
                'cockRoad': [],
            }
        }
    });
    roadMap['scoreBoards'] = scoreBoards;
    return scoreBoards;
}
/**
 * @name: 육매
 * @param: roomNo
 */
export const getBeadRoad = (roomNo) => {
    console.log('getBeadRoad');
    if(roomNo){
        generateScoreBoard();
        if(roadMap['scoreBoards'][roomNo]){
            let strArr = getStringArray(roadMap['scoreBoards'][roomNo]['base']);
            const board = ScoreBoard.fromRawData(strArr);
            const beadRoad = board.getBeadRoad(6, 42);
            roadMap['scoreBoards'][roomNo]['beadRoad'] = beadRoad['array'];
        }
    }
    return roadMap['scoreBoards'][roomNo]['beadRoad'];
}

/**
 * @name: 원매
 * @param: roomNo
 */
export const getBigRoad = (roomNo) => {
    console.log('getBigRoad');
    if(roomNo){
        generateScoreBoard();
        if(roadMap['scoreBoards'][roomNo]){
            let strArr = getStringArray(roadMap['scoreBoards'][roomNo]['base']);
            const board = ScoreBoard.fromRawData(strArr);
            const bigRoad = board.getBigRoad(6, 42);
            roadMap['scoreBoards'][roomNo]['bigRoad'] = bigRoad['array'];
        }
    }
    return roadMap['scoreBoards'][roomNo]['bigRoad'];
}

/**
 * @name: 1군
 * @param: roomNo
 */
export const getBigEyeRoad = (roomNo) => {
    if(roomNo){
        generateScoreBoard();
        if(roadMap['scoreBoards'][roomNo]){
            let strArr = getStringArray(roadMap['scoreBoards'][roomNo]['base']);
            const board = ScoreBoard.fromRawData(strArr);
            if(board?.roundResults.length > 0){
                const beadRoad = board.getBigEyeRoad(6, 42);
                roadMap['scoreBoards'][roomNo]['bigEyeRoad'] = beadRoad['array'];
            }
        }
    }
    return roadMap['scoreBoards'][roomNo]['bigEyeRoad'];
}

/**
 * @name: 2군
 * @param: roomNo
 */
export const getSmallRoad = (roomNo) => {
    if(roomNo){
        generateScoreBoard();
        if(roadMap['scoreBoards'][roomNo]){
            let strArr = getStringArray(roadMap['scoreBoards'][roomNo]['base']);
            const board = ScoreBoard.fromRawData(strArr);
            if(board?.roundResults.length > 0){
                const beadRoad = board.getSmallRoad(6, 42);
                roadMap['scoreBoards'][roomNo]['smallRoad'] = beadRoad['array'];
            }
        }
    }
    return roadMap['scoreBoards'][roomNo]['smallRoad'];
}

/**
 * @name: 3군
 * @param: roomNo
 */
export const getCockRoad = (roomNo) => {
    if(roomNo){
        generateScoreBoard();
        if(roadMap['scoreBoards'][roomNo]){
            let strArr = getStringArray(roadMap['scoreBoards'][roomNo]['base']);
            const board = ScoreBoard.fromRawData(strArr);
            if(board?.roundResults.length > 0){
                const beadRoad = board.getCockroachRoad(6, 42);
                roadMap['scoreBoards'][roomNo]['cockRoad'] = beadRoad['array'];
            }
        }
    }
    return roadMap['scoreBoards'][roomNo]['cockRoad'];
}

/**
 * @name: 사봉 스코어보드 생성
 * @param: scores Array 사봉 중국점 결과
 * @param: gameType String 사봉 게임 협력사 (pcc, inter)
 */
export const generateSabongScoreBoard = (scores, gameType) => {

    console.log('generateSabongScoreBoard');

    let arr = [];
    scores.forEach(score => {
        let str = "";
        str = `${GAME_RESULT[score?.['record_declare'] || score?.['sub_rooster'] || score?.['rooster']]}8n`
        arr.push(str);
    });

    const board = ScoreBoard.fromRawData(arr);
    sabongRoadMap[gameType]['beadRoad'] = board.getBeadRoad(6, arr.length + 10);
    sabongRoadMap[gameType]['bigRoad'] = board.getBigRoad(6, arr.length + 10);
    sabongRoadMap[gameType]['count']['meron'] = board.bankerCount;
    sabongRoadMap[gameType]['count']['wala'] = board.playerCount;
    sabongRoadMap[gameType]['count']['draw'] = board.drawCount;
    sabongRoadMap[gameType]['count']['cancel'] = board.cancelCount;
    return sabongRoadMap;
}

/**
 * @name: 스코어보드를 만들기위한 스트링 배열형태를 만들어줌
 * @param: scores 게임결과 Array
 */
const getStringArray = (scores) => {
    let strArr = [];
    scores.forEach(score => {
        let str = "";
        Object.keys(score).forEach(key => {
            // 게임결과 b, p, tie
            if(GAME_RESULT[key] && score[key] === true){
                str = `${GAME_RESULT[key]}8`
            }
        });
        // 페어 결과 pp bp
        str += score['pp'] && score['bp'] ? 'a' : score['pp'] ? 'b' : score['bp'] ? 'r' : 'n';
        strArr.push(str);
    });
    return strArr;
}

/**
 * @name: 타이 카운트 가져오기
 * @param: 중국점 정보 Object
 */
export const getTieCount = (cell) => {
    if(!cell){
        return;
    }
    return cell['tieCount'] > 0 ? cell['tieCount'] : '';
}

/**
 * @name: 중국점 결과 CSS 클래스 가져오기 (1, 2, 3 군)
 * @param: 중국점 정보 Object
 */

export const getScoreClassGun = (cell) => {
    if(!cell){
        return '';
    }
    return cell['repetition'] ? 'blue' : 'red';
}

/**
 * @name: 중국점 결과 CSS 클래스 가져오기 (원매, 육매)
 * @param: 중국점 정보 Object
 */
export const getScoreClass = (cell) => {
    if(!cell){
        return '';
    }
    let cls = '';
    if(cell['gameResult'] == 0){
        cls += ' tie';
    }
    if(cell['gameResult'] == 1){
        cls += ' b';
    }
    if(cell['gameResult'] == 2){
        cls += ' p';
    }
    if(cell['gameResult'] == 3){
        cls += ' draw';
    }
    if(cell['gameResult'] == 4){
        cls += ' cancel';
    }
    if(cell['pairResult'] == 1){
        cls += ' bp';
    }
    if(cell['pairResult'] == 2){
        cls += ' pp';
    }
    if(cell['pairResult'] == 3){
        cls += ' bp pp';
    }
    return cls;
}