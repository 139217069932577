<template>
    <div class="infoModalPosition">
        <div
            class="sideMenuBox"
            v-bind:style="{ 'width': isMobile() ? '100vw' : '27vw' }">
            <div class="sideMenuTopBox">
                <h1>{{ $t('myInfo') }}</h1>
                <span class="exitImg">
                    <img
                        src="../../assets/mobile/btn-close.png"
                        alt="close"
                        v-on:click.stop="$emit('hideModal'); $emit('showUserInfo', false)">
                </span>
            </div>
            <div class="sideMenuInner">
                <div class="topContents">
                    <div class="row">
                        <div class="tx1">
                            {{ $t('userId') }}
                        </div>
                        <div class="tx2">
                            {{ userInfo['id'] }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="tx1">
                            {{ $t('agentId') }}
                        </div>
                        <div class="tx2">
                            {{ userInfo['agentId'] }}
                        </div>
                    </div>
                </div>
                <div class="centerContents">
                    <div class="userInfo">
                        <div class="tx2">
                            LV. {{ userInfo['player_level'] }}
                        </div>
                        <div class="tx2">
                            <img v-bind:src="getGradeImage()"/>
                        </div>
                        <div class="tx2">
                            <img v-bind:src="getFlagImage()"/>
                            <span>
                                {{ userInfo['currency_code'] }}
                            </span>
                        </div>
                    </div>
                    <div
                        class="row"
                        v-for="(col, key) in cols"
                        v-bind:key="key">
                        <div class="tx1">
                            {{ $t(col['title']) }}
                        </div>
                        <div class="tx2">
                            {{ userInfo[col['key']] || '-' }}
                        </div>
                    </div>
                </div>
                <div class="botContents">
                    <div class="row deposit">
                        <div class="tx1 deposit">
                            {{ $t('deposit') }}
                        </div>
                        <div
                            class="tx2 deposit"
                            v-number-format="userInfo['amount_for_deposit']">
                        </div>
                    </div>
                    <div class="row withdraw">
                        <div class="tx1 withdraw">
                            {{ $t('withdraw') }}
                        </div>
                        <div
                            class="tx2 withdraw"
                            v-number-format="userInfo['amount_for_withdraw']">
                        </div>
                    </div>
                    <div class="row amount">
                        <div class="tx1 amount">
                            {{ $t('amount') }}
                        </div>
                        <div
                            class="tx2 amount"
                            v-number-format="userInfo['amount_retained']">
                        </div>
                    </div>
                </div>
                <div
                    class="walletBox"
                    v-if="loginUser['isWallet']">
                    <p v-if="isWalletOwn">
                        This is the user who created the vinus wallet.
                    </p>
                    <button
                        v-if="!isWalletOwn"
                        v-on:click="getCreatWallet(); $emit('hideModal'); $emit('showUserInfo', false);">
                        Create Sabong Wallet
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { PLAYER_GRADE_IMG_TX, FLAG_IMG } from "@/js/const";
    import { defineComponent } from "vue";
    import { loginUser, err } from '@/js/reactive';
    import { makeWallet, walletOwn, walletAddress, sabongWalletInfo} from "@/js/inGame/sabongWallet.js"
    import { isMobile } from "@/js/utils";

    export default defineComponent({
        data(){
            return {
                PLAYER_GRADE_IMG_TX: PLAYER_GRADE_IMG_TX,
                FLAG_IMG: FLAG_IMG,
                loginUser: loginUser,
                isWalletOwn: sabongWalletInfo['walletOwn'],
                userInfo: {},
                cols: [
                    {
                        'key': 'device',
                        'title': 'device'
                    },
                    {
                        'key': 'platform',
                        'title': 'platform'
                    },
                    {
                        'key': 'last_ip',
                        'title': 'lastIp'
                    },
                    {
                        'key': 'last_domain',
                        'title': 'lastDomain'
                    },
                    {
                        'key': 'login_time',
                        'title': 'loginTime'
                    },
                    {
                        'key': 'register_time',
                        'title': 'registerTime'
                    },
                    {
                        'key': 'address',
                        'title': 'address'
                    },
                    {
                        'key': 'full_name',
                        'title': 'currency'
                    },
                ],
            }
        },
        async created() {
            await this.getRank();
            await this.getUserDetail();
        },
        methods: {
            isMobile,
            async getRank(){
                const res = await this.apis.getRank({'type': 0});
                this.userInfo = res['decrypt_data']['value']['myRanking'];
            },
            async getUserDetail(){
                const res = await this.apis.getUserDetail({
                    'userId': loginUser['id']
                });
                this.userInfo = Object.assign(this.userInfo, res['decrypt_data']['search']);
            },
            async getCreatWallet(){
                const res = await makeWallet();
                if(res[0] === true) {
                    await walletOwn();
                    await walletAddress();
                    err['msg'] = res[1];
                }else {
                    err['msg'] = res[1];
                }
            },
            getGradeImage(){
                if(!this.userInfo['class']){
                    return;
                }
                return require(`@/assets/grade/${this.PLAYER_GRADE_IMG_TX[this.userInfo['class']]}`);
            },
            getFlagImage(){
                if(!this.userInfo['currency_code']){
                    return;
                }
                return require(`@/assets/flag/${this.FLAG_IMG[this.userInfo['currency_code']]}`);
            }
        }
    });
</script>
<style scoped>
    .withdraw {
        color: #f10000 !important;
    }
    .deposit {
        color: #3ba4e1 !important;
    }
    .amount {
        color: #ffce70 !important;
    }
    .userInfo {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .userInfo .tx2 {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }
    .tx1 {
        color: #5ccfff;
        border-right: 2px solid #484848;
    }
    .tx2 {
        margin-left: 10px !important;
        color: white;
    }
    .sideMenuBox{
        position: absolute;
        left: 0;
        top: 0;
        height: auto;
        padding-bottom: 15px;
        background-color: #2e3033;
        border: 1px solid #bbb;
        color: #fff;
        z-index: 20;
        border-radius: 6px;
    }
    .sideMenuTopBox{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 2%;
    }
    .sideMenuTopBox > h1 {
        font-size: 21px;
    }
    .sideMenuInner{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
        justify-content: center;
    }

    .sideMenuInner .topContents,
    .sideMenuInner .centerContents,
    .sideMenuInner .botContents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #1b1b1b;
        width: 95%;
        border: 2px solid #484848;
    }

    .row {
        display: flex;
        width: 100%;
        height: 30px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0;
    }

    .topContents .row div:nth-child(1) {
        min-width: 115px;
        text-align: center;
    }

    .topContents .row div:nth-child(2) {
        text-align: center;
    }

    .centerContents .row div:nth-child(1),
    .botContents .row div:nth-child(1) {
        flex: 1;
        margin-right: auto;
        margin-left: 10px;
    }
    .row div:nth-child(2){
        flex: 1;
        width: 100%;
        margin-left: auto;
    }

    .sideMenuInner .topContents {
        height: 100px;
    }

    .sideMenuInner .centerContents {
        /*height: 230px;*/
    }

    .sideMenuInner .botContents {
        width: 95%;
        height: 150px;
    }

    .exitImg{
        cursor: pointer;
        position: absolute;
        right: 0;
        margin-right: 3%;
        height: 28px;
    }
    .exitImg img{
        width: 28px;
        height: auto;
    }

    /*211124 한유정 추가*/
    .myInfoAnother .sideMenuTopBox{
        height: 65px;
    }
    .myInfoAnother .exitImg{
        cursor: pointer;
        position: absolute;
        right: 0;
        margin-right: 3%;
        height: 32px;
    }
    .myInfoAnother .exitImg img{
        width: 32px;
        height: auto;
    }

    .walletBox{
        width: 100%;
        margin-top: 12px;
        text-align: center;
        padding: 0 2%;
    }
    .walletBox p{
        text-align: center;
        font-size: 18px;
        color: #39D530;
    }
    .walletBox button{
        font-size: 18px;
        border: 1px solid #3f82e9;
        background-color: #2056af;
        color: #ffffff;
        width: 250px;
        height: 30px;
        line-height: 30px;
    }
    .infoModalPosition{
        position: relative;
        z-index: 9999;
    }
</style>