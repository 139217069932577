import CryptoJS from "crypto-js";
import pako     from "pako";

/**
 * @name: 톰혼 요청 시간포맷 가져오기
 */
export const getTomhornDateFormat = () => {
    let current = new Date().toISOString().replace("T", " ").replace(/\..*/, '');
    let split = current.split(' ');
    let date = split[0].split('-');
    return `${date[2]}-${date[1]}-${date[0]} ${split[1]}`;
}

export const setCollapsed = (text,start,end) => {
    start = start || 4;
    end = end || -2;
    if(!text){
        return;
    }
    if(text.length < 20){
        return text;
    }
    return `${text.substring(0, start)}...${text.slice(end, text.length)}`;
}

export const encryptDataV2 = (plaintext) => {
    let _plaintext = plaintext.toString();
    const iv       = CryptoJS.enc.Utf8.parse("0123456789012345");
    const key      = CryptoJS.enc.Utf8.parse("01234567890123456789012345678901");
    const cipher   = CryptoJS.AES.encrypt(_plaintext, key, {
        iv      : iv,
        mode    : CryptoJS.mode.CBC,
        keySize : 256 / 32,
        padding : CryptoJS.pad.Pkcs7,
    });
    return cipher.toString();
}

export const encryptHmac256 = (plaintext) => {
    let hash = CryptoJS.HmacSHA256(plaintext, process.env.VUE_APP_TOMHORN_SECRETKEY);
    return hash.toString();
}

export const encryptMD5 = (plaintext) => {
    let hash = CryptoJS.MD5(plaintext).toString();
    return hash;
}

export const encryptUrlData = (plaintext) => {
    const rtrim = (str, charlist) => {
        charlist = !charlist
            ? ' \\s\u00A0'
            : (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '\\$1')
        const re = new RegExp('[' + charlist + ']+$', 'g');
        return (str + '').replace(re, '');
    }
    const iv       = CryptoJS.enc.Hex.parse("0000000000000000");
    const key      =  CryptoJS.enc.Utf8.parse("C105E791-F4F8-48C8-AC83-B550B3871C8A".slice(0,32));
    const cipher   = CryptoJS.AES.encrypt(plaintext, key, {
        iv      : iv,
        mode    : CryptoJS.mode.CBC,
        keySize : 256 / 32,
        padding : CryptoJS.pad.Pkcs7,
    });
    return rtrim(cipher.toString().replaceAll('+','-').replaceAll('/','_'),'=');
}
export const decryptUrlData = (_plaintext) => {
    if (!_plaintext) {
        return "null";
    }
    _plaintext = setStrPad(_plaintext.replaceAll('-', '+').replaceAll('_','/'),_plaintext.length % 4, '=', 'STR_PAD_RIGHT');
    const iv     = CryptoJS.enc.Hex.parse("0000000000000000");
    const key    = CryptoJS.enc.Utf8.parse("C105E791-F4F8-48C8-AC83-B550B3871C8A".slice(0, 32));
    const cipher = CryptoJS.AES.decrypt(_plaintext, key, {
        iv      : iv,
        mode    : CryptoJS.mode.CBC,
        keySize : 256 / 32,
        padding : CryptoJS.pad.Pkcs7,
    });
    let decryptedUtf8 = null;
    try {
        decryptedUtf8 = cipher.toString(CryptoJS.enc.Utf8);
    } catch (e) {
        decryptedUtf8 = JSON.parse(decryptedUtf8);
    }
    return decryptedUtf8;
}

/**
 * @name: php 함수 str_pad 자바스크립트
 */
export const setStrPad = (input, padLength, padString, padType) => {
    let half = ''
    let padToGo
    const _strPadRepeater = function (s, len) {
        let collect = ''
        while (collect.length < len) {
            collect += s
        }
        collect = collect.substr(0, len)
        return collect
    }
    input += ''
    padString = padString !== undefined ? padString : ' ';
    if (padType !== 'STR_PAD_LEFT' && padType !== 'STR_PAD_RIGHT' && padType !== 'STR_PAD_BOTH') {
        padType = 'STR_PAD_RIGHT'
    }
    if ((padToGo = padLength - input.length) > 0) {
        if (padType === 'STR_PAD_LEFT') {
            input = _strPadRepeater(padString, padToGo) + input
        } else if (padType === 'STR_PAD_RIGHT') {
            input = input + _strPadRepeater(padString, padToGo)
        } else if (padType === 'STR_PAD_BOTH') {
            half = _strPadRepeater(padString, Math.ceil(padToGo / 2))
            input = half + input + half
            input = input.substr(0, padLength)
        }
    }
    return input
};
/**
 * @name: 쿼리 스트링 만들기
 * @param: payload Object 쿼리스트링으로 만들 Object 값
 */
export const generateQueryString = (payload) => {
    return Object.entries(payload).map(e => e.join('=')).join('&');
}

export const encryptData   = (plaintext) => {
    if(!plaintext) return "null";
    let _plaintext = plaintext.toString();
    const iv       = CryptoJS.enc.Hex.parse("0000000000000000");
    const key      = CryptoJS.enc.Utf8.parse("C105E791F4F848C8AC83B550B3871C8A");
    const cipher   = CryptoJS.AES.encrypt(_plaintext, key, {
        iv      : iv,
        mode    : CryptoJS.mode.CBC,
        keySize : 256 / 32,
        padding : CryptoJS.pad.Pkcs7,
    });
    // console.log(cipher.toString());
    return cipher.toString();
};

/*export const encryptData128 = (plaintext) => {
    let _plaintext = plaintext.toString();
    const iv     = CryptoJS.enc.Utf8.parse("0000000000000000");
    const key    = CryptoJS.enc.Utf8.parse("VINUSSABONG000000");
    const cipher = CryptoJS.AES.encrypt(_plaintext, key ,{
        iv : iv,
        mode: CryptoJS.mode.CBC,
        padding:CryptoJS.pad.ZeroPadding
    });
    return cipher.toString();
};*/

export const decryptData   = (_plaintext, topic = '') => {
    const iv  = CryptoJS.enc.Hex.parse("0000000000000000");
    const key = CryptoJS.enc.Utf8.parse("C105E791F4F848C8AC83B550B3871C8A");

    const cipher        = CryptoJS.AES.decrypt(_plaintext, key, {
        iv      : iv,
        mode    : CryptoJS.mode.CBC,
        keySize : 256 / 32,
        padding : CryptoJS.pad.Pkcs7,
    });
    let decryptedUtf8 = null;
    try{
        decryptedUtf8 = cipher.toString(CryptoJS.enc.Utf8);
        // eslint-disable-next-line no-empty
    }catch (e){

    }
    try {
        return JSON.parse(decryptedUtf8);
    } catch (e) {
        return decryptedUtf8;
    }
};
export const decompression = (uncompressed) => {
    const _atob          = atob(uncompressed);
    const array          = _atob.split("").map(x => x.charCodeAt(0));
    const byteArray      = new Uint8Array(array);
    const inflate        = pako.inflate(byteArray);
    const byteArrayAscii = String.fromCharCode.apply(null, new Uint16Array(inflate));
    return btoa(byteArrayAscii);
};

export const setNumberFormat = (value) => {
    if (!/^[-0-9]/g.test(value)) {
        return value;
    }
    let str = '';
    let amount = '';
    let decimal = '';

    if(String(value).indexOf('.') > -1){
        amount = String(value).split('.')[0].replace(/[^\d]+/g, "").replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
        decimal = String(value).split('.')[1];
        str = `${amount}.${decimal}`
    } else if(String(value).indexOf('-') > -1){
        str = `-${String(value).replace(/[^\d]+/g, "").replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")}`;
    } else {
        str = String(value).replace(/[^\d]+/g, "").replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    }

    return str;

}

export const setNumberRound = (value , digit = 4) => {
    let _value = value || 0;
    _value = checkNaN_Infinity(_value);
    _value = _value.toFixed(digit);
    _value = setNumberFormat(_value);
    return _value;
}

export const setCookie = (name, value, exp) => {
    deleteCookie(name);
    let date = new Date();
    date.setTime(date.getTime() + exp*24*60*60*1000);
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
};

export const getMoneySymbol = (num) => {
    let digits = 2;
    let si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}


export const getCookie = (name) => {
    let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value? value[2] : null;
};

export const deleteCookie = (name) => {
    document.cookie = `${name}=; Max-Age=-99999999; path=/`;
}

// 특정 값 제거
export const arraySplice = (arr, value) => {
    arr.forEach(data => {
        if(data == value){
            arr.splice(arr.indexOf(data), 1)
        }
    })
    return arr;
}

export const searchChar = (text, searchChar) => {
    let count = 0;
    let pos = text.indexOf(searchChar);
    while (pos !== -1) {
        count++;
        pos = text.indexOf(searchChar, pos + 1); // 첫 번째 a 이후의 인덱스부터 a를 찾습니다.
    }

    return count;
}

export const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const checkNaN_Infinity = (data) => Number.isNaN(Number(data)) === true || Number.isFinite(Number(data)) === false ? 0.00 : Number(data);


// 배열 내 객체에 entries 추가하기.
export const addKey = (array, params) => array.map(obj => Object.assign(obj, params));
