<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>Sound</h1>
            <span class="exitImg">
                <img
                    src="../../../assets/mobile/btn-close.png"
                    alt="close"
                    v-on:click.stop="$emit('showModal', '')">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="SoundWrap">
                <div
                    class="SoundBoxs"
                    v-for="(inter, key) in interfaces['sound']"
                    v-bind:key="key">
                    <h3>{{ inter['title'] }}</h3>
                    <ul>
                        <li>
                            <img
                                width="21"
                                height="20"
                                v-bind:src="inter['onoff'] === 0 || inter['value'] === 0 ? inter['offLeftIcon'] : inter['leftIcon']"/>
                        </li>
                        <li>
                            <img
                                width="21"
                                height="20"
                                v-bind:src="inter['onoff'] === 0 || inter['value'] === 0 ? inter['offRightIcon'] : inter['rightIcon']"/>
                        </li>
                    </ul>
                    <Slider
                        v-model="inter['value']"
                        v-bind:tooltips="false"
                        v-on:click.stop="changeVolume(inter)"
                        v-bind:min="0"
                        v-bind:max="1"
                        v-bind:step="0.1"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { mapGetters } from 'vuex';
    import Slider from "@vueform/slider";
    import { getCookie, setCookie } from "@//js/utils";

    export default defineComponent({
        components: {
            Slider
        },
        data() {
            return {
                bgm: undefined,
                interfaces: {
                    sound: [
                        {
                            'key': 'video',
                            'title': this.$t('video'),
                            'onoff': 1,
                            'leftIcon': require('@//assets/icons/ingame_top_menu/icon-sound-off.png'),
                            'rightIcon': require('@//assets/icons/ingame_top_menu/icon-sound-on.png'),
                            'offLeftIcon': require('@//assets/icons/ingame_top_menu/sound-off-dim.png'),
                            'offRightIcon': require('@//assets/icons/ingame_top_menu/sound-on-dim.png'),
                            'value': 1
                        },
                        {
                            'key': 'fx',
                            'title': this.$t('fx'),
                            'onoff': 1,
                            'leftIcon': require('@/assets/icons/ingame_top_menu/icon-sound-off.png'),
                            'rightIcon': require('@/assets/icons/ingame_top_menu/icon-sound-on.png'),
                            'offLeftIcon': require('@/assets/icons/ingame_top_menu/sound-off-dim.png'),
                            'offRightIcon': require('@/assets/icons/ingame_top_menu/sound-on-dim.png'),
                            'value': 1
                        },
                        {
                            'key': 'music',
                            'title': this.$t('music'),
                            'onoff': 0,
                            'leftIcon': require('@/assets/icons/ingame_top_menu/icon-sound-off.png'),
                            'rightIcon': require('@/assets/icons/ingame_top_menu/icon-sound-on.png'),
                            'offLeftIcon': require('@/assets/icons/ingame_top_menu/sound-off-dim.png'),
                            'offRightIcon': require('@/assets/icons/ingame_top_menu/sound-on-dim.png'),
                            'value': 0
                        }
                    ]
                },
            }
        },
        computed: {
		...mapGetters(['getBgmOn', 'getEffectOn', 'getVideoOn', 'getIsWebRTCOn'])
        },
        unmounted() {
        },
        mounted() {
            this.$store.commit('setBgmOn', this.getBgmOn);
            this.$store.commit('setEffectOn', this.getEffectOn);
            this.$store.commit('setVideoOn', this.getVideoOn);
        },
        watch: {
            getIsWebRTCOn: {
                immediate: true,
                handler(isOn){
                    if(isOn){
                        this.interfaces['sound'][0]['value'] = 1;
                        this.changeVolume(this.interfaces['sound'][0]);
                        this.interfaces['sound'][2]['value'] = 0;
                        this.changeVolume(this.interfaces['sound'][2]);
                    } else {
                        this.interfaces['sound'][0]['value'] = 0;
                        this.changeVolume(this.interfaces['sound'][0]);
                        this.interfaces['sound'][2]['value'] = 1;
                        this.changeVolume(this.interfaces['sound'][2]);
                    }
                }
            }
        },
        methods: {
            changeVolume(inter){
                if(inter['key'] == 'music'){
                    this.$store.commit('setBgmOn', inter['value']);
                }
                if(inter['key'] == 'fx'){
                    this.$store.commit('setEffectOn', inter['value']);
                }
                if(inter['key'] == 'video'){
                    this.$store.commit('setVideoOn', inter['value']);
                }
                inter['onoff'] = inter['value'] > 0 ? 1 : 0;
            },
        }
    })
</script>
<style scoped>
	.sideMenuBox{
		position: absolute;
		right: 0;
		width: 75vw;
		color: #fff;
		font-size: 14px;
	}
	.sideMenuTopBox{
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: #2e3033;
		padding: 3%;
	}
	.sideMenuTopBox > h1 {
		font-size: 20px;
	}
	.sideMenuInner{
		padding: 1.5%;
		background-color: #2e3033;
	}
	.exitImg{
		position: absolute;
		right: 0;
		margin-right: 3%;
		height: 28px;
	}
	.exitImg img{
		width: 28px;
		height: auto;
	}

	/* 내용 */
	.SoundWrap{
		padding: 0 12px;
	}
	.SoundBoxs{
		margin-top: 20px;
		height: 100px;
	}
	.SoundBoxs .icons {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.SoundBoxs ul{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
        margin-bottom: 5px;
	}
	.SoundBoxs ul li{
		flex: 1;
	}
	.SoundBoxs ul li:nth-child(2){
		text-align: right;
	}
</style>