import { createI18n } from "vue-i18n";
import { getCookie } from "@/js/utils";
/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            try{
                messages[locale] = locales(key).default;
            }catch(e){
                console.error(e);
            }
        }
    });
    return messages;
}

export const i18n =  createI18n({
    legacy: false,
    globalInjection: true,
    locale: getCookie("locale") ?? (process.env.VUE_APP_I18N_LOCALE || "en"),
    fallbackLocale: getCookie('locale') ?? (process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en"),
    messages: loadLocaleMessages(),
});
