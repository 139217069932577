<template>
    <div class="modalBg">
        <div class="modalLog">
            <div class="modalLogTopBox">
                <h1>Dealer Info</h1>
                <span class="exitImg">
                    <img
                        src="../../assets/mobile/btn-close.png"
                        alt="close"
                        v-on:click.stop="$emit('hideDealerInfo')">
                </span>
            </div>
            <div class="modalInner">
                <div class="dealerInfoWrap">
                    <div class="dealerInfoTopBox">
                        <img
                            v-if="selectedDealer['img']"
                            v-bind:src="selectedDealer['img']">
                        <img
                            v-else
                            src="../../assets/icons/lobby_table/dealer-none.png">
                    </div>
                    <div class="dealerInfoDetl">
                        <table>
                            <tr>
                                <td>{{ $t('dealerName') }}</td>
                                <td>{{ selectedDealer['user_id'] }}</td>
                            </tr>
                            <tr>
                                <td>Tip</td>
                                <td
                                    class="tipColor"
                                    v-number-format="selectedDealer['tip']"></td>
                            </tr>
                            <tr>
                                <td>Play time</td>
                                <td
                                    class="PtColor"
                                    v-number-format="selectedDealer['playTime']"></td>
                            </tr>
                            <tr>
                                <td>Like</td>
                                <td
                                    class="LikeColor"
                                    v-number-format="selectedDealer['like']"></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="dealerInfoBtmBox">
                    <ul>
                        <li>
                            <img
                                src="../../assets/btn/btn-like.png"
                                alt="like">
                        </li>
                        <li>
                            <img
                                src="../../assets/btn/btn-tip.png"
                                alt="tip">
                        </li>
                        <li v-on:click.stop="$emit('hideDealerInfo')">
                            <img
                                src="../../assets/btn/btn-rank.png"
                                alt="rank">
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import {defineComponent} from "vue";
    export default defineComponent({
        props: ['selectedDealer']
    })

</script>
<style scoped>
.dealerInfoTopBox {
	width: 200px;
	height: 200px;
	overflow: hidden;
}
.modalBg{
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 20;
}
.modalLog{
	position: relative;
	width: 30vw;
	background-color: #fff;
	height: auto;
	border: 1px solid #bbb;
	border-radius: 6px;
}
.modalLogTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #3b3b3b;
	padding: 1%;
	color: #fff;
	height: 80px;
	border-radius: 6px 6px 0 0;
}
.modalLogTopBox h1{
	font-size: 34px;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 3%;
	height: 36px;
}
.exitImg img{
	width: 36px;
	height: auto;
	cursor: pointer;
}
.modalInner{
	padding: 0 15px;
	background-color: #3b3b3b;
	border-radius: 0 0 6px 6px;
}

/*내용*/
.dealerInfoWrap{
	width: 100%;
	height: 222px;
	padding: 2px 4px;
	background-color: #1b1b1b;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	justify-content: center;
}
.dealerInfoBtmBox ul{
	width: 280px;
	height: 104px;
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	align-items: center;
	justify-content: space-around;
}
.dealerInfoBtmBox ul > li > img{
	width: 60px;
	cursor: pointer;
}
.dealerInfoImg {
	width: 216px;
	height: 202px;
	overflow: hidden;
}
.dealerInfoImg > img{
	width: 216px;
	height: auto;
}
.dealerInfoDetl{
	width: 297px;
	height: 202px;
}
.dealerInfoDetl table{
	width: 100%;
	height: 100%;
	color: #fafafa;
	border: 1px solid #484848;
	padding: 0 20px 0 30px;
	border-spacing: 0 22px;
}
.dealerInfoDetl table > tr > td:nth-child(1){
	border-right: 1px solid #6c6c6c;
}
.dealerInfoDetl table > tr > td:nth-child(2){
	text-align: center;
}

.tipColor{
	color: #ffce70;
}
.PtColor{
	color: #5ccfff;
}
.LikeColor{
	color: #ff5454;
}
</style>