<template>
    <div class="modalWrap">
        <div class="header">
            <div class="title">
                {{ $t(menu['key']) }}
            </div>
            <div
                v-if="isShowCloseBtn"
                class="closeBtn"
                v-on:click.stop="$router.go(-1)">
                <img
                    src="../../../assets/mobile/btn-close.png"
                    width="40"
                    height="40"/>
            </div>
        </div>

        <div class="body">
            <Loading v-if="loading['isLoading']"/>
            <!--			언어팩 211013 한유정추가 / langScrollBox 클래스 추가함 -->
            <div
                v-if="menu['key'] === 'language'"
                class="langScrollBox">
                <div
                    v-if="menu['key'] === 'language'"
                    class="langWrap">
                    <div
                        class="langs"
                        v-on:click.stop="changeLang(lang)"
                        v-for="(lang, idx) in langs"
                        v-bind:key="idx"
                        v-bind:class="{ 'selected': lang['key'] == $i18n.locale }">
                        <div class="img">
                            <img
                                v-bind:src="lang['img']"
                                width="80"
                                height="80"/>
                        </div>
                        <div class="title">
                            {{ $t(lang['key']) }}
                        </div>
                    </div>
                </div>
            </div>

            <!--랭크-->
            <div
                v-if="menu['key'] === 'rank'"
                class="rankWrap">
                <div
                    class="selectValue"
                    v-bind:class="selectedValue"
                    v-on:click.stop="isShowSelect = !isShowSelect">
                    <div>
                        {{ $t(selectedValue) }}
                    </div>
                    <div
                        class="arrow"
                        v-bind:class="{ 'reverse': isShowSelect }">
                        <img
                            src="../../../assets/btn/arrow.png"
                            width="30"
                            height="15"/>
                    </div>
                </div>

                <div
                    v-if="isShowSelect"
                    class="options">
                    <div
                        class="option"
                        v-bind:class="col['key']"
                        v-for="(col, key) in myRanks"
                        v-on:click.stop="selectedValue = col['key']; isShowSelect = false"
                        v-bind:key="key">
                        {{ $t(col['key']) }}
                    </div>
                </div>
                <div class="myRank">
                    <div class="no">
                        {{ getMyRankData('no') }}
                    </div>
                    <div class="agentWrap">
                        <div class="agent">
                            {{ getMyRankData('agent') }}
                        </div>
                        <div class="id">
                            {{ getMyRankData('id') }}
                        </div>
                    </div>
                    <div
                        class="detail"
                        v-on:click.stop="showUserInfo(getMyRankData('info'))">
                    </div>
                    <div
                        class="amount"
                        v-bind:class="getMyRankKey(selectedValue)"
                        v-number-format="getMyRankData(selectedValue)">
                    </div>
                </div>

                <div class="body">
                    <div class="columns">
                        <div class="column no">
                            {{ $t('no') }}
                        </div>
                        <div class="column">
                            <div>
                                {{ $t('agent') }}
                            </div>
                            <div>
                                {{ $t('id') }}
                            </div>
                        </div>
                        <div class="column colHide">
                        </div>
                        <div
                            class="column amount"
                            v-bind:class="selectedValue">
                            {{ $t(selectedValue) }}
                        </div>
                    </div>
                    <div class="ranksList">
                        <div
                            class="ranks"
                            v-for="(rank, key) in rankList"
                            v-bind:key="key">
                            <div class="no">
                                <img v-bind:src="rankIcons[key]"/><span v-if="key > 2">
                                    {{ key + 1}}
                                </span>
                            </div>
                            <div class="agentWrap">
                                <div class="agent">
                                    {{ rank['agent'] }}
                                </div>
                                <div class="id">
                                    {{ rank['id'] }}
                                </div>
                            </div>
                            <div
                                class="detail"
                                v-on:click.stop="showUserInfo(rank['info'])">
                            </div>
                            <div
                                class="amount"
                                v-bind:class="selectedValue"
                                v-number-format="rank[selectedValue]">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--            랭크 end-->
            <Shop
                v-if="menu['key'] === 'shop'"
                v-on:showCloseBtn="showCloseBtn"/>
            <Help
                v-if="menu['key'] === 'help'"
                v-on:showCloseBtn="showCloseBtn"/>
            <History
                v-if="menu['key'] === 'history'"
                v-on:showCloseBtn="showCloseBtn"/>
        </div>
        <div class="footer">
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { loading } from "@/js/reactive";
    import Shop from "@/components/popup/Shop";
    import Help from "@/components/popup/Help";
    import History from "@/components/popup/HistoryModal";
    import Modal from "@/components/popup/Modal";
    import Loading from "@/components/common/Loading"

    export default defineComponent({
        mixins: [Modal],
        components: {
            Shop,
            Help,
            History,
            Loading
        },
        data() {
            return {
                loading: loading,
                locale: 'en',
                isShowSelect: false,
                selectedValue: 'deposit',
                isShowCloseBtn: true,
                menu: {},
                langs: [
                    {
                        'key': 'en',
                        'img': require(`../../../assets/locale/flag-english.png`),
                        'click': () => {

                        }
                    },
                    {
                        'key': 'th',
                        'img': require(`../../../assets/locale/flag-thai.png`),
                        'click': () => {

                        }
                    },
                    {
                        'key': 'sp',
                        'img': require(`../../../assets/locale/flag-spanish.png`),
                        'click': () => {

                        }
                    },
                    {
                        'key': 'vt',
                        'img': require(`../../../assets/locale/flag-vietnamese.png`),
                        'click': () => {

                        }
                    },
                    {
                        'key': 'hi',
                        'img': require(`../../../assets/locale/flag-hindi.png`),
                        'click': () => {

                        }
                    },
                    {
                        'key': 'ko',
                        'img': require(`../../../assets/locale/flag-korean.png`),
                        'click': () => {

                        }
                    },
                    {
                        'key': 'ja',
                        'img': require(`../../../assets/locale/flag-japanese.png`),
                        'click': () => {

                        }
                    },
                    {
                        'key': 'ch',
                        'img': require(`../../../assets/locale/flag-chinese.png`),
                        'click': () => {

                        }
                    }
                ]
            }
        },
        beforeMount() {
            this.locale = this.$i18n.locale;
            this.menu = JSON.parse(this.$route['params']['menu']);
            if(this.menu['key'] === 'rank'){
                this.getRank();
            }
        },
        methods: {
            changeLang(locale){
                this.$root.$i18n.locale = locale['key'];
            },
            showCloseBtn(isShow){
                this.isShowCloseBtn = isShow;
            },
            getMyRankData(key){
                return this.myRanks.find(rank => rank['key'] == key)?.['value'] || '-';
            },
            getMyRankKey(key){
                return this.myRanks.find(rank => rank['key'] == key)?.['key'] || '-';
            }
        }
    });
</script>
<style scoped>
	.modalWrap {
		width: 100%;
		height: 100%;
	}
	.modalWrap .header {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #2e3033;
		width: 100%;
		height: 44px;
		gap: 20px;
	}
	.modalWrap .header .title {
		color: white;
		font-size: 20px;
		font-weight: bold;
	}
    .modalWrap .header .title:lang(en) {
        font-size: 22px;
    }

	.modalWrap .body {
		display: flex;
		justify-content: center;
		width: 100%;
        height: 100vh;
		background-color: #2e3033;
	}

	.modalWrap .footer {
		width: 100%;
		min-height: 80px;
		display: flex;
		flex-direction: row;
		background-color: #2e3033;
	}

	.modalWrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
	}

	.modalWrap .langWrap {
		display: grid;
		gap: 30px;
		grid-template-columns: repeat(2, 50%);
		align-items: center;
		justify-content: space-between;
		margin: 30px auto 30px auto;
	}

	.modalWrap .langWrap .langs {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: white;
		font-size: 20px;
		font-weight: bold;
		width: 150px;
		gap: 10px;
		height: 150px;
		background-color: var(--mobile-modal-lang-background-color);
	}

	.langs.selected {
		background-color: var(--mobile-modal-lang-selected-background-color) !important;
	}

	.rankWrap {
		display: flex;
		width: 100%;
		height: inherit;
		flex-direction: column;
	}

	.rankWrap .selectValue {
		margin: 10px auto 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 70px;
		font-weight: bold;
		font-size: 20px;
		background-color: #1b1b1b;
	}

	.arrow {
		position: absolute;
		right: 10px;
	}

	.arrow.reverse {
		transform: rotate(180deg);
	}

	.rankWrap .options {
		position: absolute;
		top: 180px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		font-size: 20px;
		width: 100%;
		height: 340px;
		text-align: center;
		background-color: #1b1b1b;
		outline: 1px solid #484848;
	}

	.rankWrap .options .option {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 15px;
		border-bottom: 1px solid #484848;
	}

	.rankWrap .body {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.rankWrap .myRank {
		width: 100%;
		display: flex;
		min-height: 80px;
		margin-top: 20px;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: #1b1b1b;
	}

	.rankWrap .myRank,
	.rankWrap .body .columns {
		display: flex;
		flex-direction: row;
		width: 100%;
		min-height: 60px;
		align-items: center;
		font-weight: bold;
		font-size: 16px;
		color: var(--mobile-modal-rank-col-font-color);
	}
	.rankWrap .body .ranksList {
		width: 100%;
		display: flex;
		height: inherit;
		overflow-y: scroll;
		flex-direction: column;
		align-items: center;
	}
	.rankWrap .body .ranksList .ranks {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		min-height: 80px;
		color: var(--mobile-modal-rank-col-font-color);
		background-color: #1b1b1b;
		border-bottom: 1px solid #484848;
		font-weight: bold;
	}

	.detail {
		min-width: 34px;
	}

	.rankWrap .body .ranksList .ranks .no,
	.rankWrap .myRank .no {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		font-size: 20px;
		color: var(--mobile-modal-rank-font-color) !important;
	}

	.rankWrap .body .ranksList .ranks .no,
	.rankWrap .myRank .no,
	.rankWrap .body .columns .no {
		flex: 0.5;
	}

	.rankWrap .body .ranksList .ranks .amount,
	.rankWrap .myRank .amount,
	.rankWrap .body .columns .amount {
		flex: 1;
	}

	.rankWrap .body .ranksList .ranks div,
	.rankWrap .myRank div,
	.rankWrap .body .columns div {
		text-align: center;
		white-space: nowrap;
	}
	.detail {
		background-image: url("../../../assets/icons/glasses.png");
		background-size: 100% 100%;
		max-width: 34px !important;
		height: 34px !important;
		text-indent: -10000px !important;
		cursor: pointer;
	}

	.column.detail {
		background-image: none !important;
	}
	
	.option.no, .option.agent, .option.id, .option.detail {
		display: none !important;
	}
    .langScrollBox{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 0 25px;
    }
    .closeBtn{
        position: absolute;
        right: 0;
        margin-right: 4%;
        height: 28px;
    }
    .closeBtn img{
        width: 28px;
        height: auto;
    }

	.deposit {
		color: #3ba4e1 !important;
	}

	.withdraw {
		color: #f10000 !important;
	}

	.bets {
		color: #fbe291 !important;
	}

	.pay {
		color: #e4b204 !important;
	}

	.win {
		color: #00d6d9 !important;
	}

	.lose {
		color: #ff5454 !important;
	}

	.profit {
		color: #2be000 !important;
	}
	.loss {
		color: #ff121a !important;
	}
</style>