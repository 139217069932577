import { apis } from "../repository/repository";
import { loginUser, timer, sabong } from "./reactive";
import { getCookie } from "@/js/utils";
import store from "@/store";

/**
 * @name: 로그인사용자 보유하고있는 잔액 가져오기 (사봉유저있는경우에는 요청 X)
 * @param:
 */
export const getBalance = async (isNotRepeat) => {
    const res = await apis.getLoginUserAmount({});
    loginUser['balance'] = res?.['decrypt_data']['value']['amount_retained'] || 0;
    store.commit('login/setLoginUserBalance', loginUser['balance']);
    if(!isNotRepeat){
        timer['balance'] = setTimeout(() => { getBalance(); clearTimeout(timer['balance']); }, 3000);
    }
}

/**
 * @name: vins live와 하트비트체크 (사봉유저있는경우에는 요청 X)
 * @param:
 */
export const getPing = async () => {
    const res = await apis.ping();
    loginUser['uuid'] = res['decrypt_data']['uuid'];
    timer['ping'] = setTimeout(() => { getPing(); clearTimeout(timer['ping']); }, 5000);
}



