<template>
    <div class="modalContainer">
        <div
            class="backdrop"
            v-on:click.stop="isSignUp()">
        </div>
        <div
            class="modalWrap"
            v-bind:style="store.getters['modal/getModalOpt'].styles"
            v-bind:class="[store.getters['modal/getModalKey'], { 'mobile': isMobile() }]"
        >
            <div
                class="modalHeaderWrap">
                <div
                    class="modalHeader"
                    v-if="store.getters['modal/getModalOpt'].showHeader">
                    <p v-if="store.getters['modal/getModalKey']">
                        {{ $t(store.getters['modal/getModalKey']) }}
                        <span></span></p>
                    <div
                        v-if="!store.getters['login/getRefferCode']"
                        class="closeBtn menu"
                        v-on:click.stop="store.commit('modal/initModal')">
                        <img src="@/assets/mobile/btn-close.png"/>
                    </div>
                    <div
                        v-else-if="store.getters['login/getRefferCode'] && store.getters['modal/getModalKey'] == 'connectWallet'"
                        class="backBtn menu"
                        v-on:click.stop="store.commit('modal/showModal', { modalKey: 'signup', open: true, styles: { width: isMobile() ? '100vw' : '400px' }})">
                        <img src="@/assets/icons/arrow_top.png"/>
                    </div>
                </div>
            </div>
            <div class="modalBody">
                <WalletDepositModal v-if="store.getters['modal/getModalKey'] == 'deposit_menu'"/>
                <SignUp v-if="store.getters['modal/getModalKey'] == 'signup'"/>
                <AmountTransfer v-if="store.getters['modal/getModalKey'] == 'transaction'"/>
                <WalletConvertModal v-if="store.getters['modal/getModalKey'] == 'withdrawal'"/>
                <WalletHistoryModal v-if="store.getters['modal/getModalKey'] == 'transactionHistory' && loginUser.isWallet"/>
                <AmountHistory v-if="store.getters['modal/getModalKey'] == 'transactionHistory' && !loginUser.isWallet"/>
                <!-- <RefferInfo v-if="store.getters['modal/getModalKey'] == 'reffer'" /> -->
                <!-- <RefferralCommission v-if="store.getters['modal/getModalKey'] == 'refferralCommission'" /> -->
                <MyWallet v-if="store.getters['modal/getModalKey'] == 'MyWallet'"/>
                <SabongHelp v-if="store.getters['modal/getModalKey'] == 'help'"/>
                <UserInfoModal v-if="store.getters['modal/getModalKey'] == 'userInfo'"/>
                <!-- <ConnectWallet v-if="store.getters['modal/getModalKey'] == 'connectWallet'"/> -->
                <loginmodal v-if="store.getters['modal/getModalKey'] == 'signin'"/>
                <MobileWalletHistoryModal v-if="store.getters['modal/getModalKey'] == 'walletHistory'"/>
                <MobileLanguageModal v-if="store.getters['modal/getModalKey'] == 'language'"/>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { loginUser } from '@/js/reactive';
    import { showModal } from '@/js/modal/modals';
    import { defineProps } from "vue";
    import WalletDepositModal from "@/components/sabong/popup/WalletTransferModal";

    import WalletHistoryModal from "@/components/sabong/popup/WalletTransactionHistoryModal";
    import MobileWalletHistoryModal from "@/components/sabong/popup/WalletTransactionHistoryModalM";
	
    import MyWallet from "@/components/sabong/popup/WalletUserInfo";
    import SabongHelp from "@/components/modals/renew/help/SabongHelp";
    import UserInfoModal from "@/components/modals/renew/userInfo/SabongUserInfo";
    import { isMobile, getCookie, setCookie } from "@/js/utils";
    import { useStore } from "vuex";
    import ConnectWallet from "@/components/modals/renew/connectWallet/ConnectWallet";
    import loginmodal from "@/components/modals/renew/SabongLogin/SabongLogin";
    import WalletConvertModal from "@/components/sabong/popup/WalletConvertModal";
    import AmountTransfer from "@/components/sabong/popup/transaction/AmountTransfer";
    import RefferInfo from "@/components/sabong/popup/reffer/RefferInfo";
    import RefferralCommission from "@/components/sabong/popup/reffer/RefferralCommission";
    import SignUp from "@/components/sabong/popup/SignUp";
    import AmountHistory from "@/components/sabong/popup/transaction/AmountHistory";

    import MobileLanguageModal from "@/components/modals/renew/languageM/LanguageM";

    const store = useStore();

    const isSignUp = () => {
        if(store.getters['login/getRefferCode']) return;
        store.commit('modal/initModal')
    }

</script>
<style scoped>
*{
    font-family: 'Folks', sans-serif;
    letter-spacing: .3px;
}
.modalContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.backdrop {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.5;
	overflow-y: hidden;
	z-index: 10;
}
.modalWrap {
	position: absolute;
	z-index: 20;
	top: 50%;
	left: 50%;
	transform:translate(-50%, -50%);
	height: auto;
	outline: 1px solid #bbbbbb;
	border-radius: 10px;
    background-color: #3b3b3b;
    overflow: hidden;
}
.modalHeaderWrap{
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.modalWrap .modalHeader {
    position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
    background-color: #141414;
	color: #fff;
	width: 100%;
	height: 48px;
}
.modalWrap .modalHeader > p{
    position: absolute;
    width: auto;
    top: 0;
    left: 0;
    padding: 0 40px;
    font-size: 30px;
    height: 70px;
    line-height: 70px;
    background-color: #141414;
}
.modalWrap .modalHeader > p span{
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    right: -30px;
    border-top: 30px solid #141414;
    border-right: 30px solid transparent;
}
.modalWrap .modalHeader .closeBtn {
	z-index: 25;
	margin-right: 22px;
    position: absolute;
    top: 6px;
    right: 0;
}

.closeBtn > img{
	height: 36px;
	width: 36px;
}

.modalWrap .modalHeader .backBtn {
    z-index: 25;
	margin-right: 22px;
    position: absolute;
    top: 15px;
    right: 0;
}
.backBtn > img{
	width: 32px;
    height: 32px;
    transform: rotate(90deg);
}
.modalWrap .modalBody {
	width: 100%;
	height: auto;
}

/* mobile */
.modalWrap.mobile:not(.help, .connectWallet) {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    transform: translate(0,0) !important;
    outline: 0;
    border-radius: 0;
}
.modalWrap.mobile .modalHeaderWrap{
    height: 60px;
}
.modalWrap.mobile .modalHeader {
    height: 40px;
}
.modalWrap.mobile .modalHeader > p{
    padding: 0 42px;
    font-size: 22px;
    height: 60px;
    line-height: 60px;
}
.modalWrap.mobile .modalHeader .closeBtn {
    margin-right: 15px;
    top: 5px;
}
.modalWrap.mobile .closeBtn > img{
    height: 28px;
    width: 28px;
}

@media (min-width: 320px) and (max-width: 480px){
    .modalBody {
        position: unset !important;
        width: 100% !important;
        height: auto !important;
        padding-top: 0;
        transform: unset;
    }
}
</style>