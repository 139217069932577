<template>
    <div
        class="soundInterface"
        v-for="(inter, key) in interfaces['sound']"
        v-bind:key="key">
        <div class="title">
            {{ $t(inter['key']) }}
        </div>
        <div class="icons">
            <div class="left">
                <img
                    width="21"
                    height="20"
                    v-bind:src="inter['onoff'] === 0 || inter['value'] === 0 ? inter['offLeftIcon'] : inter['leftIcon']"/>
            </div>
            <div class="right">
                <img
                    width="21"
                    height="20"
                    v-bind:src="inter['onoff'] === 0 || inter['value'] === 0 ? inter['offRightIcon'] : inter['rightIcon']"/>
            </div>
        </div>
        <Slider
            v-model="inter['value']"
            v-bind:tooltips="false"
            v-on:change="changeVolume(inter)"
            v-bind:min="0"
            v-bind:max="1"
            v-bind:step="0.1"/>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { mapGetters } from 'vuex';
    import Slider from "@vueform/slider";
    import { getCookie, setCookie } from "@/js/utils";

    export default defineComponent({
        components: {
            Slider
        },
        data() {
            return {
                bgm: undefined,
                interfaces: {
                    sound: [
                        {
                            'key': 'video',
                            'title': this.$t('video'),
                            'onoff': 1,
                            'leftIcon': require('../../assets/icons/ingame_top_menu/icon-sound-off.png'),
                            'rightIcon': require('../../assets/icons/ingame_top_menu/icon-sound-on.png'),
                            'offLeftIcon': require('../../assets/icons/ingame_top_menu/sound-off-dim.png'),
                            'offRightIcon': require('../../assets/icons/ingame_top_menu/sound-on-dim.png'),
                            'value': 1
                        },
                        {
                            'key': 'fx',
                            'title': this.$t('fx'),
                            'onoff': 1,
                            'leftIcon': require('../../assets/icons/ingame_top_menu/icon-sound-off.png'),
                            'rightIcon': require('../../assets/icons/ingame_top_menu/icon-sound-on.png'),
                            'offLeftIcon': require('../../assets/icons/ingame_top_menu/sound-off-dim.png'),
                            'offRightIcon': require('../../assets/icons/ingame_top_menu/sound-on-dim.png'),
                            'value': 1
                        },
                        {
                            'key': 'music',
                            'title': this.$t('music'),
                            'onoff': 0,
                            'leftIcon': require('../../assets/icons/ingame_top_menu/icon-sound-off.png'),
                            'rightIcon': require('../../assets/icons/ingame_top_menu/icon-sound-on.png'),
                            'offLeftIcon': require('../../assets/icons/ingame_top_menu/sound-off-dim.png'),
                            'offRightIcon': require('../../assets/icons/ingame_top_menu/sound-on-dim.png'),
                            'value': 0
                        }
                    ]
                },
            }
        },
        computed: {
			...mapGetters(['getBgmOn', 'getEffectOn', 'getVideoOn', 'getIsWebRTCOn'])
        },
        unmounted() {
        },
        mounted() {
            this.$store.commit('setBgmOn', this.getBgmOn);
            this.$store.commit('setEffectOn', this.getEffectOn);
            this.$store.commit('setVideoOn', this.getVideoOn);
        },
        watch: {
            getIsWebRTCOn: {
                immediate: true,
                handler(isOn){
                    if(isOn){
                        this.interfaces['sound'][0]['value'] = 1;
                        this.changeVolume(this.interfaces['sound'][0]);
                        this.interfaces['sound'][2]['value'] = 0;
                        this.changeVolume(this.interfaces['sound'][2]);
                    } else {
                        this.interfaces['sound'][0]['value'] = 0;
                        this.changeVolume(this.interfaces['sound'][0]);
                        this.interfaces['sound'][2]['value'] = 1;
                        this.changeVolume(this.interfaces['sound'][2]);
                    }
                }
            }
        },
        methods: {
            changeVolume(inter){
                if(inter['key'] == 'music'){
                    this.$store.commit('setBgmOn', inter['value']);
                }
                if(inter['key'] == 'fx'){
                    this.$store.commit('setEffectOn', inter['value']);
                }
                if(inter['key'] == 'video'){
                    this.$store.commit('setVideoOn', inter['value']);
                }
                inter['onoff'] = inter['value'] > 0 ? 1 : 0;
            },
        }
    })
</script>
<style scoped>
	.soundInterface {
		display: flex;
		flex-direction: column;
		gap: 5px;
		width: 90%;
        padding: 2px 13px;
        font-size: 16px;
        line-height: initial;
        text-align: left;
    }
	.soundInterface .title {
		margin-top: 10px;
		color: white;
        font-weight: 300;
	}
	.soundInterface .icons {
		display: flex;
		flex-direction: row;
        margin-bottom: 6px;
	}
	.soundInterface .icons .left {
		margin-right: auto;

	}
	.soundInterface .icons .right {
		margin-left: auto;
	}
	.interface.selected {
		color: var(--inGame-interface-selected-font-color);
	}
</style>