export default {
  "baccarat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACCARAT"])},
  "teenpatti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEENPATTI"])},
  "dragonTiger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ThànhTIGER"])},
  "holdem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOLDEM"])},
  "roulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROULETTE"])},
  "sicbo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SICBO"])},
  "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muasắm"])},
  "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lịchsử"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đổibảng"])},
  "betLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cákiêm"])},
  "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ThổiVào/đi"])},
  "helpInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cứu/Thôngtin"])},
  "lobby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vậnđộng"])},
  "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thoát ra"])},
  "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nóichuyện"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mua"])},
  "fx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FX"])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âmnhạc"])},
  "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cao"])},
  "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ởgiữa"])},
  "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thấp"])},
  "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xếphạng"])},
  "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TrợgiúpC/S"])},
  "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lựachọn"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngônngữ"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "tutorial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bàitậptục"])},
  "pp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P.PAIR"])},
  "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngườichơi"])},
  "tie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIE"])},
  "b": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngânhàng"])},
  "bp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B.PAIR"])},
  "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngườichơi"])},
  "banker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngânhàng"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sốdư"])},
  "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổngsố"])},
  "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sẵnsàng"])},
  "betReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặtgiườngcủabạnởđâu?"])},
  "betEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kếtthúccủabệnhviện"])},
  "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cácbạnhãychờchođếnkhivòngtiếptheobắtđầunhé."])},
  "noGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khôngcódanhsáchtròchơi."])},
  "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TiếngAnh"])},
  "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TiếngHàn"])},
  "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TiếngNhật"])},
  "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TiếngHindi"])},
  "ch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TiếngTrungQuốc"])},
  "vt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngườiviệtnam"])},
  "th": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TháiLan"])},
  "sp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TiếngTâyBanNha"])},
  "would": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạncómuốnmua?"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giácả"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủybỏ"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xácnhận"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chukỳ"])},
  "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khônggiớihạn"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không."])},
  "agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ĐặcvụID"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chứngminhthư"])},
  "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sựnhượngbộ"])},
  "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIẾNGNHẬNCỨU"])},
  "bets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cácmặtnạ"])},
  "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trảtiền"])},
  "win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thắng"])},
  "win2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thắng"])},
  "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mấtmát"])},
  "lose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOSE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vẽ"])},
  "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thôngtinngườidùng"])},
  "gameId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDtròchơi"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày(UTC)"])},
  "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kếtquả"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trướcđây"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếptheo"])},
  "historyWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lịchsửchỉcóthểxemđượctrong100dòngcuốicùng."])},
  "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khôngcódữliệunàođểxem."])},
  "noRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiệntại:khôngcóbảngnàođanghoạtđộng."])},
  "chatInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửitinnhắntángẫu"])},
  "toList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danhsách"])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
  "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
  "checkMinMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuilòngkiểmtragiớihạncácược"])},
  "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tắt"])},
  "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bắtđầu"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đangnạpđạn"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bàn"])},
  "roundId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDvòngtròn"])},
  "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vòngtròn"])},
  "betCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỏcuộc"])},
  "sabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SABONG"])},
  "betUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỏcuộc"])},
  "betRedo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xáclậpcácượcvòngtrước"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đónglại"])},
  "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quaytrởlại"])},
  "rebet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gọilại"])},
  "tutorialTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phươngphápchơigame"])},
  "tutorialTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kẻchơi"])},
  "tutorialTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BankerTurn"])},
  "tutorial1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CardAđượctínhbằngsố1vàsố10:J:QvàKđượctínhbằng0.Nhữngthẻcònlạiđượctínhtheosốchủsởhữucủamỗithẻ."])},
  "tutorial2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổngcủahaiconsốđượcxemxéttrừđơnvị10.Vídụ:khôngphải5+8<EMJ_PHL>13:màlà3."])},
  "tutorial3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vềcơbản:ngườichơivàngânhàngnhậnđượcmỗingườihailábài.Vàothờiđiểmnày:nếutổngcủahaiconsốtrởthành8hoặc9thìtròchơisẽkếtthúcmàkhôngnhậnđượcthẻthứbatrongđiềukiệntựnhiên."])},
  "tutorial4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NếucảhaiđềukhôngphảilàNatural:ngườichơiquyếtđịnhcónhậnđượcthẻthứbahaykhôngdựatrêntổngcủahaithẻ."])},
  "tutorial5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếutổngsốngườichơilà6hoặc7,Khôngchấpnhậnthẻ(Stand)"])},
  "tutorial6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếutổngsốngườichơilà0-5:Nhậnđượcthẻthứ3."])},
  "tutorial7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếungườichơiđứng:"])},
  "tutorial8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Nếungườichơilà6hoặc7tuổivàchủngânhànglà6hoặc7tuổi:Khôngchấpnhậnthẻ"])},
  "tutorial9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Nếungườichơilà6hoặc7tuổivàchủngânhànglà0đến5,nhậnđượcthẻthứba"])},
  "tutorial10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếungườichơinhậnđượclábàithứba:"])},
  "tutorial11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếungườichơilà0-5vàngườichủngânhànglà0-2:Nhậnđượcthẻthứ3."])},
  "tutorial12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Nếungườichơitừ0đến5vàchủngânhànglà3,Nhậnthẻthứ3(trừthẻthứ3củangườichơilà8)."])},
  "tutorial13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Nếungườichơitừ0đến5vàchủngânhànglà4:nhậnthẻthứba(trừthẻthứbalà0:1:8:9)."])},
  "tutorial14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếungườichơitừ0đến5vàchủngânhànglà5,Nhậnthẻthứ3(trừthẻthứ3là0:1:2:3:8:9)."])},
  "tutorial15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếungườichơitừ0đến5vàchủngânhànglà6,Nhậnthẻthứ3(trừthẻthứ3là0:1:2:3:4:5:8:9)."])},
  "tutorial16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Nếungườichơitừ0đến5vàngườichủngânhànglà7:Khôngchấpnhậnthẻ(đứnglập)"])},
  "tutorial17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theoluậtchơi:ngườinàogần9trên2hoặc3thẻsẽthắng."])},
  "tutorial18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tùythuộcvàobảng:nhưngthườngsẽtrảlạigấpđôisốtiềnđánhbóngchođộichiếnthắng:vànếubạnthắngbằngmộttrậnhòa:bạnsẽtrảlạigấptámlầnsốtiềnđánhbóng."])},
  "tutorial19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vìngânhàngcótỷlệthắngcao:nếubạnthắngngânhàng:bạnsẽphảitrả5%tiềnhoahồngchongôinhà."])},
  "msgErr1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thôngđiệpquádài.(tứcdàitốiđa100kýtự)"])},
  "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mànhìnhđầyđủ"])},
  "cam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAM"])},
  "lobbyExit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thoátkhỏi"])},
  "equip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trangbị"])},
  "unequip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khôngtrangbịđược"])},
  "insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiếucáncân."])},
  "dealerSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộphậnlọccủangườibánhàng"])},
  "cardSkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộbàida"])},
  "dealerProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hồsơđạilý"])},
  "beInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đangđượcsửdụng"])},
  "dealerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thôngtincủangườibánhàng"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENU"])},
  "myInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thôngtincủatôi"])},
  "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiếtbị"])},
  "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nềntảng"])},
  "lastIp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPcuốicùng"])},
  "lastDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lãnhđịacuốicùng"])},
  "loginTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lầntrước"])},
  "registerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngàytháng"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địachỉ."])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiềntệ"])},
  "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDngườidùng"])},
  "agentId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ĐặcvụID"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hồsơ"])},
  "dealerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tênđạilý"])},
  "like": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giốngnhư"])},
  "tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lờikhuyên"])},
  "playTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thờigianbiểudiễn"])},
  "member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thànhviên"])},
  "dealer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngườibánhàng"])},
  "meron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "wala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DRAW"])},
  "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RED"])},
  "white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHITE"])},
  "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLUE"])},
  "lastGameResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kếtquảtrậnđấucuốicùng"])},
  "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M"])},
  "w": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gianhập"])},
  "memberList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danhsáchthànhviên"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quốcgia"])},
  "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cấpbậc"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lv."])},
  "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biếnđổi"])},
  "deposit_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiềngửi"])},
  "withdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rútlui"])},
  "depositBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiềngửi"])},
  "transactionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lịchsửgiaodịch"])},
  "depositWarningTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phíđượctínhđểchuyểnđổi:nhưngnókhôngđượctínhkhiđổitiền."])},
  "depositInfoTxt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BạncóthểchuyểnđổiSabongPoint(USD)thànhEthereumhoặcBNB"])},
  "depositInfoTxt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NhậpgiátrịbạnmuốnchuyểnđổivàấnnútTransferđểchuyểnđổinóthànhSabongPointbằngcáchápdụnggiáthịtrườngphùhợpvớithờigianyêucầutraođổi."])},
  "walletCreateTxt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ĐồngxucủaSABONGWALLETđượcgiữthôngquaviệctruyềnví.Bạncóthểthêmsốlượngvào."])},
  "walletCreateTit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạoramộtcáivívinussawong"])},
  "depositSymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kýhiệutiềngửi"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biểutượng"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giátrị"])},
  "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khíga"])},
  "copyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyđịachỉ"])},
  "myWalletAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ĐịachỉcôngtyUSD"])},
  "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉsố"])},
  "registerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngàyđăngký"])},
  "requestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìnhtrạngyêucầu"])},
  "transactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDgiaodịch"])},
  "resultAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giátrịkếtquả"])},
  "withdrawalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sốtiềnrútra"])},
  "fromTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từtiềntệchuyểnnhượng"])},
  "transferAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sốtiềnchuyểnnhượng"])},
  "toTransferCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyểntiềntệ"])},
  "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túixách"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủđề"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiểubang"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bâygiờ"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổngcộng"])},
  "gameHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lịchsửtròchơi"])},
  "fightNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánhnhaukhông."])},
  "betList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danhsáchcácược"])},
  "gmt-mnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-MNL"])},
  "gmt-ist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GMT-IST"])},
  "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lặplại"])},
  "betRooster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cácược"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăngnhập"])},
  "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyểnnhượng"])},
  "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kếtnốivớimộtcáiví"])},
  "walletHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lịchsửvítiền"])},
  "cancelInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Việccácượcđãbịhủydotỷlệcổtứcbấtthường"])},
  "cancelInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kếtquảthựcsựcủatrậnđấu"])},
  "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ký tên"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tìm kiếm"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôm nay"])},
  "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôm qua"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tháng"])},
  "noBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không cân bằng"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "bannerContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trò chơi này là sản phẩm nội dung mạnh mẽ và lớn nhất của Gaming và được dự đoán là phổ biến nhất hiện nay."])},
  "bannerContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nội dung SABONG hợp tác với các công ty uy tín hàng đầu của Philippines được phát sóng trực tiếp không gián đoạn hàng ngày."])},
  "bannerContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể trải nghiệm SABONG, trò chơi mạnh mẽ và thú vị nhất Philippines."])},
  "sabongGuideHeader1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Trò chơi sabong"])},
  "sabongGuide1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trò chơi chọi gà Philippines được gọi là 'Sabong' trong tiếng Tagalog."])},
  "sabongGuide2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là một trong những môn thể thao truyền thống mà người Philippines thích nhất."])},
  "sabongGuide3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cá độ có thể là 'đỏ' hoặc 'xanh' hoặc cả hai."])},
  "sabongGuide4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỷ lệ cổ tức dao động linh động tùy theo số tiền cá cược, và cá cược một bên là thu thập quá mức có thể khiến trận đấu bị hủy bỏ."])},
  "sabongGuide5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sabongGuideHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Kết quả cá cược và trận đấu"])},
  "sabongGuide6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đánh cược bắt đầu khi \"Bet Open\" được bật và chỉ trong 30 giây sau khi cuộc gọi cuối cùng được phát hành"])},
  "sabongGuide7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vâng, bạn không thể cá cược khi hết thời gian đặt cược."])},
  "sabongGuide8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi cuộc cá cược kết thúc, trò chơi sẽ được tiến hành và số tiền phù hợp với tỷ lệ cổ tức sẽ được xác định theo kết quả."])},
  "sabongGuide9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn sẽ nhận hoặc mất tiền thưởng."])},
  "sabongGuideHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Trò chơi không hợp lệ"])},
  "sabongGuide10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["một trận hoà"])},
  "sabongGuide11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi trận đấu không được quyết định cho đến 10 phút sau khi trận đấu bắt đầu,"])},
  "sabongGuide12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi cả hai con gà đều chết và trọng tài quyết định trận đấu không thể chơi được"])},
  "sabongGuide13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trò chơi hủy bỏ"])},
  "sabongGuide14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong trường hợp từ bỏ trận đấu do tình trạng sức khỏe kém của con gà tiến hành trận đấu;"])},
  "sabongGuide15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu tỷ lệ cổ tức không vượt quá x1.20 do mất cân bằng trong cá cược"])},
  "sabongGuide16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong tình huống trên, số tiền đặt cược sẽ được hoàn trả và trận đấu sẽ bị vô hiệu hóa."])}
}