import { reactive } from "vue";
import { walletApis, ethApis } from "@/repository/repository";
import { getCookie } from "@/js/utils";
import {loginUser} from "@/js/reactive";
import store from "@/store";

export const sabongWalletInfo = reactive({
    'transHistory': [],
    'walletUsdPrice': 0,
    'user_address' : '',
    'ethAmount' : 0,
    'walletEthGas' : 0,
    'walletOwn' : false,
});

/**
 * @name:  서버에서 text 타입의 json 데이터를 객체형태로 하나씩 정리
 * @param: 데이터 한개 씩 분리하기위한 빈 배열 
 * @param: JSON 데이터 
 */
const setJsonData = (array, data) => {
    if(!data) {
        return;
    }
    let transData = undefined;
    let remaindData = undefined;

    const start_data = data.indexOf("{");
    const end_data = data.indexOf("}");

    transData = data.substring(start_data, end_data+1);
    remaindData = data.substring(end_data+1); 

    if(start_data !== -1){
        array.unshift(JSON.parse(transData));
        setJsonData(array, remaindData);
    }
}

/**
 * @name: 유저의 지갑생성 
 */
export const makeWallet = async () => {
    const res = await walletApis.makeWallet({
        'user_uuid' : getCookie('uuid')
    });
    let arr = [];
    setJsonData(arr,res);
    if(arr.length){
        const err = {
            'wallet ok' : [true, 'Successfully created'],
            'sql error' : [false ,"It exceeded today's limit"],
            'has already been created' : [false ,'has already been created']
        }
        // 지갑 생성시 true, 실패시 false 반환
        sabongWalletInfo['walletOwn'] = err[arr[0]?.['data']][0]; 
        return err[arr[0]?.['data']];
    }
}

/**
 * @name: 유저가 보유한 이더 / BNB 수량
 */
export const ethAmount = async (symbol, isBulk) => {
    const res = await ethApis.$fetch('balance',{
        address: loginUser['id'] || store.getters['login/getLoginUser'].id,
        value: 0,
        symbol: symbol
    });
    if(!isBulk){
        sabongWalletInfo['ethAmount'] = res?.Data?.payload?.balance || 0;
    }
    return res?.Data?.payload?.balance || 0;
}

/**
 * @name: 유저 사봉 지갑 주소 
 */
export const walletAddress = async () => {
    const res = await walletApis.walletAddress({
        'user_uuid' : getCookie('uuid')
    });
    let arr = [];
    setJsonData(arr,res);
    if(arr.length){
        sabongWalletInfo['user_address'] = arr[0]?.['user_address'];
    }
}

/**
 * @name: 유저 사봉 지갑 여부
 */
export const walletOwn = async () => {
    const res = await walletApis.walletOwn({
        'user_uuid' : getCookie('uuid')
    });
    let arr = [];
    setJsonData(arr,res);
    if(arr.length){
        // message
        // 200 : 이미 보유중
        // 301 : 데이터 없음
        // 302 : 신규 유저
        sabongWalletInfo['walletOwn'] = arr[0]?.['message'] === '200' ? true : false;
    }
}

/**
 * @name: 이더리움->포인트 변환 전송 api
 * @param: 변환 시 필요한 gas비용
 * @param: 유저가 입력한 deposit value 
 */
export const walletConvertSend = async (ethDepositGas, ethInputVal) => {    
    const res = await walletApis.walletConvertSend({
        'user_uuid' : getCookie('uuid'),
        'symbolType': 'eth',
        'balance': ethInputVal, //수량
        'medium': ethDepositGas  //가스비
    });
    let arr = [];
    setJsonData(arr,res);
    if(arr.length){
        // message
        // send ok : 요청 성공
        // send error : 요청 실패 및 data출력
        await ethAmount();
        return arr[0];
    }    
}

/**
 * @name: 이더리움 / BNB 전송시 수수료
 */
export const walletEthGas = async (symbol) => {
    const res = await ethApis.$fetch('gasInfo', {
        "address": "",
        "value": 0,
        "symbol": symbol
    });
    sabongWalletInfo['walletEthGas'] = res?.Data?.payload?.medium_gwei || res?.Data?.payload?.gwei;
    sabongWalletInfo['walletEthGas'] = '0.00000000' + sabongWalletInfo['walletEthGas'];
}

/**
 * @name: 현재 이더스캔 기준 이더 또는 BNB의 usd 가격
 */
export const walletUsdPrice = async (symbol, isBulk) => {
    const res = await ethApis.$fetch('price', {
        address: "",
        value: 0,
        symbol: symbol
    });
    if(!isBulk){
        sabongWalletInfo['walletUsdPrice'] = Number(res?.Data?.result?.ethusd);
    }
    return Number(res?.Data?.result?.ethusd);
}

/**
 * @name: 유저의 ETH or BNB / VSP 교환내역
 */
export const walletTransHistory = async (type, symbol) => {
    let params = {
        page: 0,
        size: 10,
        address: loginUser['id'] || store.getters['login/getLoginUser'].id,
        symbol: symbol
    };

    let showCurrency = symbol == 'bsc' ? 'BNB' : symbol.toUpperCase();
    params['type'] = type == 'deposit' ? 1 : 2;
    const res = await ethApis.$fetch('history',params);
    sabongWalletInfo['transHistory'] = res.Data.content.map((data) => {
        data['toCurrency'] = type == 'deposit' ?  showCurrency : 'USD';
        data['fromCurrency'] = type == 'deposit' ? 'USD' : showCurrency;
        return data;
    });
}
