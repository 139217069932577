import { computed, reactive } from "vue";
import { sabong, sabongInter, sabongPcc, sabongOne, sabongWsa, sabongNca, loginUser, err } from "@/js/reactive";
import { sabongApis } from "@/repository/repository";
import router from "@/router";
import theme from "@/store/theme";
import { getCookie, decryptData } from "@/js/utils";
import { routeTheme } from "../../router/theme";
import store from "@/store";
import { isMobile } from "@/js/utils";
import { showModal } from "@/js/modal/modals";

/**
 * @name: 방입장
 * @param: sabongType 사봉 타입(PCC, 인터네셔널, PCC VINUS)
 */
export const enterSabongRoom = async (gameType, theme) => {
    if(!store.getters['login/getLoginUser'].time){
        store.commit('modal/showModal', { modalKey: 'signin', open: true, styles: { width: '400px'}})
        return;
    }
    // if(theme != undefined) {
    //     await store.dispatch('theme/changeTheme' ,theme);
    //     store.commit('theme/changeTheme', theme);
    // }
    let routeName = 'inGame';
    if(gameType === 'inter' && Object.keys(sabongInter['rooms']).length > 0){
        console.log("enterSabongRoom inter");
        router.push({
            'name': routeName,
            'params': {
                'gameType': 'inter'
            }
        });
    }
    //if(gameType == 'vinusPcc' && Object.keys(sabongPcc['rooms']).length > 0){
    if(gameType == 'vinusPcc'){
        console.log("enterSabongRoom vinusPcc");
        router.push({
            'name': routeName,
            'params': {
                'gameType': 'pcc'
            },
        });
    }
    if(gameType == 'one' && Object.keys(sabongOne['rooms']).length > 0){
        console.log("enterSabongRoom one");
        router.push({
            'name': routeName,
            'params': {
                'gameType': 'one'
            },
        });
    }
    if(gameType == 'wsa'){
        console.log("enterSabongRoom wsa");
        router.push({
            'name': routeName,
            'params': {
                'gameType': 'wsa'
            },
        });
    }
    if(gameType == 'nca'){
        console.log("enterSabongRoom nca");
        router.push({
            'name': routeName,
            'params': {
                'gameType': 'nca'
            },
        });
    }
}

/**
 * @name: 인게임에서 배팅항목 배팅한 돈
 */
export const userBets = reactive({
    'meron': 0,
    'wala': 0,
    'draw': 0,
    'total': 0,
    'balance': loginUser['pccBalance']
});

/**
 * @name: Data 초기화
 */
export const initData = () => {
    userBets['meron'] = 0;
    userBets['wala'] = 0;
    userBets['draw'] = 0;
    userBets['total'] = 0;
}

/**
 * @name: 인게임에서 보여줄 배팅 정보들( 각 항목 배팅유저들, 배팅총합 )
 */
export const betUsers = reactive({
    meron: {},
    wala: {},
});

/**
 * @name: 사봉 로비에서 방 상태값 표시
 * @param: room Object 방 정보
 */
export const getSabongRoomStatus = (room) => {
    let status = room?.['event_details']?.['current_fight_status'] == 'open' ? 'open' : 'closed';
    return {
        'tx': status,
        'color': status == 'open' ? 'green' : 'gray'
    };
}

/**
 * @name: 사봉 현재 라운드 숫자 표시
 * @param: room Object 방 정보
 */

export const getCurrentFightNo = (room) => {
    return room?.['event_details']?.['current_fight_no'] || '-';
}

/**
 * @name: 인게임에서 유저 배팅비율
 */
export const dist = reactive({
    'walaDist': computed(() => {
        return ((sabong['rooms']?.['current_betting']['total_wala_bet'] / sabong['rooms']?.['current_betting']['total_bet']) * 100)?.toFixed(2) || 0;
    }),
    'meronDist': computed(() => {
        return ((sabong['rooms']?.['current_betting']['total_meron_bet'] / sabong['rooms']?.['current_betting']['total_bet']) * 100)?.toFixed(2) || 0;
    }),
})

/**
 * @name: 배팅한 유저정보들 가져오기
 * @params: 이벤트 정보 int
 * @params: 라운드 번호 int
 */
export const getBetUsers = async () => {
    betUsers['meron'] = {};
    betUsers['wala'] = {};
    //let find = sabong['rooms'].find(room => room['room']['event_id'] == sabong['enterRoom']['room']['event_id']);
    let find = sabong['rooms']
    if(find){
        find['current_bettor'].forEach(user => {
            if(!betUsers[user['bet_side'].toLowerCase()][user['user_name']]){
                betUsers[user['bet_side'].toLowerCase()][user['user_name']] = 0;
            }
            betUsers[user['bet_side'].toLowerCase()][user['user_name']] += Number(user['bet_amount']);
        });
    }
    sabong['enterRoom'] = find;
}
