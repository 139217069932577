<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>History</h1>
            <span class="exitImg">
                <img
                    src="../../assets/mobile/btn-close.png"
                    alt="close"
                    v-on:click.stop="$emit('goPage', 'list')">
            </span>
        </div>
        <div class="sideMenuInner">
            <div class="historyWrap">
                <div class="historyList">
                    <ul class="historyListTopBox">
                        <li v-on:click.stop="$emit('goPage', 'prev')">
                            <img
                                alt="prev"
                                src="../../assets/icons/icon_history_arrow_L.png">
                        </li>
                        <li class="accentColor">
                            {{ $t('gameId') }}
                        </li>
                        <hr class="hr">
                        <li>{{ selectedHistory['identifying_number'] }}</li>
                        <li v-on:click.stop="$emit('goPage', 'next')">
                            <img
                                alt="next"
                                src="../../assets/icons/icon_history_arrow_R.png">
                        </li>
                    </ul>
                    <table
                        cellspacing="0"
                        cellpadding="0">
                        <tr>
                            <td class="accentColor">
                                {{ $t('date') }}
                            </td>
                            <td class="accentColor">
                                {{ $t('bet') }}
                            </td>
                            <td>
                                <span class="winColor">
                                    {{ $t('win2') }}
                                </span>
                                <span class="accentColor">
                                    &#47;
                                </span>
                                <span class="loseColor">
                                    {{ $t('lose') }}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ selectedHistory['create_time'] }}</td>
                            <td v-number-format="selectedHistory['bet']"></td>
                            <td
                                v-bind:class="{
                                    'loseColor': String(selectedHistory['winLoss']).indexOf('-') > -1,
                                    'winColor': String(selectedHistory['winLoss']).indexOf('-') === -1
                                }"
                                v-number-format="selectedHistory['winLoss']"></td>
                        </tr>
                    </table>
                </div>
                <div class="cardDetail">
                    <div
                        class="result"
                        v-for="(rst, key) in results['cardResult']"
                        v-bind:key="key">
                        <div
                            class="header"
                            v-bind:class="rst['key']">
                            <div
                                class="cardSum"
                                v-bind:class="rst['key']">
                                {{ rst['sum'] }}
                            </div>
                            <div
                                class="title"
                                v-bind:class="rst['key']">
                                {{ $t(rst['key']) }}
                            </div>
                        </div>
                        <div
                            class="cards"
                            v-bind:class="rst['key']">
                            <div
                                class="card"
                                v-bind:class="getCardResult({'result': rst['cards']}, cardIdx)['color']"
                                v-for="(card, cardIdx) in rst['cards']"
                                v-bind:key="cardIdx">
                                <span class="mark">
                                    <img v-bind:src="getCardImage(getCardResult({'result': rst['cards']}, cardIdx)['mark'])"/>
                                </span>
                                <span class="tx">
                                    {{ getCardResult({'result': rst['cards']}, cardIdx)['tx'] }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <table>
                    <tr
                        v-for="(rst, key) in results['result']"
                        v-bind:key="key">
                        <td v-bind:class="rst['key']">
                            {{ $t(rst['key']) }}
                        </td>
                        <td class="accentColor">
                            {{ $t('bet') }}
                        </td>
                        <td v-number-format="rst['bet']"></td>
                        <td class="accentColor">
                            {{ $t('result') }}
                        </td>
                        <td v-number-format="rst['rst']"></td>
                    </tr>
                </table>
            </div>
            <div class="historyFooter">
                <ul>

                    <li v-on:click.stop="$emit('goPage', 'list')">
                        {{ $t('toList') }}
                    </li>

                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import {defineComponent} from "vue";
    import { getCardResult } from "@/js/common";
    export default defineComponent({
        data(){
        },
        props: ['selectedHistory', 'results'],
        methods: {
            getCardResult,
            getCardImage(mark){
                return require(`@/assets/mark/${mark}`);
            },
        }

    });

</script>
<style scoped>
	.sideMenuBox{
		width: 100%;
		height: auto;
		padding-bottom: 75%;
		color: #fff;
		font-size: 14px;
		background-color: #2e3033;
	}
	.sideMenuTopBox{
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		background-color: #2e3033;
		padding: 3%;
	}
	.sideMenuTopBox > h1 {
		font-size: 18px;
	}
	.sideMenuInner{
		padding: 0 3%;
		background-color: #2e3033;
		height: 100%;
	}
	.exitImg{
		position: absolute;
		right: 0;
		margin-right: 4%;
		height: 28px;
	}
	.exitImg img{
		width: 28px;
		height: auto;
	}

	/* 내용 */
	.historyWrap{
		width: 100%;
		height: auto;
		padding: 2px 4px;
		background-color: #1b1b1b;
	}
	.historyList{
		width: 100%;
		margin-bottom: 18px;
	}
	.historyListTopBox{
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		font-size: 13px;
		height: 35px;
	}
    .historyListTopBox li img{
        max-width: unset;
        height: 22px;
        vertical-align: middle;
    }
    .historyListTopBox:lang(en){
        font-size: 12px !important;
    }
    .historyList > ul.historyListTopBox:lang(en){
        justify-content: center !important;
        gap: 7px !important;
    }
    .hr{
		width: 0;
		height: 16px;
		border-right: 1px solid #6c6c6c;
	}
	.historyList > table{
		width: 98%;
		margin: 0 1%;
		text-align: center;
		font-size: 13px;
		border-top: 1px solid #6c6c6c;
	}
	.historyList > table > tr:nth-child(1){
		height: 30px;
	}
	.historyList > table > tr:nth-child(2) td{
        width: 38%;
        min-width: 38%;
		padding-bottom: 3px;
	}
    .historyWrap > table > tr > td:nth-child(2){
        width: 15%;
        min-width: 15%;
    }

	.winColor{
		color: #2be000;
	}
	.accentColor{
		color: #5ccfff;
	}
    .accentColor:lang(en){
        white-space: nowrap;
        font-size: 12px;
    }
    li.accentColor:lang(en){
        white-space: nowrap;
        font-size: 12px;
        min-width: 55px;
    }
	.loseColor{
		color: #ff121a;
	}

	.cardDetail{
		width: 100%;
		height: 90px;
	}

	.historyWrap > table{
		width: 100%;
		text-align: center;
		margin: 30px 0 5px 0;
		font-size: 13px;
		border-spacing: 0 22px;
	}
	.historyWrap > table > tr{
		height: 20px;
	}
	.historyWrap > table > tr > td:nth-child(1){
		width: 82px;
		border-right: 1px solid #6c6c6c;
	}
	.historyFooter ul{
		width: 100%;
		height: 35px;
		margin: 5px 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-size: 15px;
	}

	.cardDetail{
		width: 100%;
		height: 90px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.cardDetail .result {
		flex: 1;
		display: flex;
		gap: 15px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.cardDetail .result .header {
		display: flex;
		gap: 25px;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}

	.cardDetail .result .header.player {
		flex-direction: row-reverse;
	}

	.cardDetail .result .cards {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
		gap: 4px;
	}

	.cardDetail .result .cards .card {
		width: 46px;
		height: 63px;
		border-radius: 10px;
		background-color: #fafafa;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		position: relative;
	}
    .card .mark {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
        margin-left: 2px;
        margin-top: 2px;
    }
    .mark > img{
        width: 16px;
    }

	.cardDetail .result .cards .card .mark {
		position: absolute;
		font-size: 20px;
		top: 0;
		left: 0;
	}
	.cardDetail .result .cards .card .tx {
		position: relative;
		font-size: 36px;
		font-weight: bold;
		top: 10px;
	}

	.cardDetail .result .header .title.player {
		color: var(--modal-history-player-color);
	}
	.cardDetail .result .header .title.banker {
		color: var(--modal-history-banker-color);
	}

	.cardSum {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;
		background-color: #222222;
		border-radius: 6px;
	}

	.cardSum.player {
		margin-right: 50px;
		border: 1px solid var(--modal-history-player-color);
	}

	.cardSum.banker {
		margin-left: 50px;
		border: 1px solid var(--modal-history-banker-color);
	}

	.black {
		color: black !important;
	}
	.red {
		color: #dd3d3d !important;
	}
	td.p, td.pp {
		color: #3c86ff;
	}
	td.tie {
		color: #26ff01;
	}
	td.b, td.bp {
		color: #ff1818;
	}
</style>