<template>

    <div class="wrap">
        <!--        chip 선택시 나타나는 배경-->
        <div
            class="selectChipBg"
            v-if="isShowChipModal"
            v-on:touchstart.stop="closeModal(false)"></div>

        <Musics/>
        <InGameUserInfo v-bind:betMinMax="betMinMax"/>
        <span class="errMsg">
            {{ getBetErrMsg }}
        </span>
        <keep-alive>
            <InGameSideMenu
                v-if="isShowSideMenu"
                v-bind:betMinMax="betMinMax"
                v-on:offCam="offCam"
                v-on:getEquipDealer="getEquipDealer"
                v-on:hideSideMenu="hideSideMenu"/>
        </keep-alive>
        <div class="video">
            <div class="progressBox">
                <div
                    class="progress"
                    v-bind:style="{ 'width': `${((maxSec - curSec)/maxSec) * 100}%` }"
                    v-bind:class="progressStatus">
                    <div class="progressText">
                        {{ progressText }}
                    </div>
                </div>
            </div>
            <div
                class="timeSetBox"
                v-bind:style="{ 'visibility': isBetProgress ? 'visible' : 'hidden'}">
                <div class="spin">
                </div>
                <span class="tx">
                    {{ maxSec - curSec }}
                </span>
            </div>
            <div class="menus">
                <div
                    class="menu"
                    v-on:click.stop="isShowSideMenu = true">
                    <img
                        src="../../assets/20211025/icons/icon-menu.png"
                        width="39"
                        height="39"/>
                </div>
                <div
                    class="menu"
                    v-on:click.stop="modalKey = 'change'">
                    <img
                        src="../../assets/20211025/icons/icon-change-table.png"
                        width="39"
                        height="39"/>
                </div>
                <div
                    class="menu"
                    v-on:click.stop="$router.push('/lobby')">
                    <img
                        src="../../assets/20211025/icons/icon-lobby.png"
                        width="39"
                        height="39"/>
                </div>
            </div>
            <!--			cam on-->
            <CameraWebRTC
                v-if="!isCamOff"
                v-bind:streamName="tableNo"/>
            <!--			cam off-->
            <div
                class="videoWrap"
                v-if="isCamOff">
                <div class="bgImgBox">
                    <img src="../../assets/mobileBg/bg-baccarat.png"/>
                </div>
                <div class="chrctImgBox">
                    <img v-bind:src="equipDealer"/>
                </div>
                <div class="tableImgBox">
                    <img src="../../assets/mobileBg/table-baccarat.png"/>
                </div>
            </div>
            <div
                class="resultText"
                v-bind:class="resultClass"
                v-if="isShowResult">
                {{ result }}
            </div>
            <div
                class="resultText2"
                v-if="isShowResult">
                <span class="tx">
                    {{ $t('result') }} :
                </span>{{ getRealWins }}
            </div>
        </div>
        <InGameBetBoard/>
        <div class="interfaces">
            <div
                class="leftBtn"
                v-bind:class="buttons[0]['key']"
                v-on:touchstart.stop="buttons[0]['click']">
                <img
                    v-bind:src="buttons[0]['src']"
                    width="58"
                    height="58"/>
                <span>{{ $t(buttons[0]['key']) }}</span>
            </div>
            <div class="chips">
                <div
                    class="chip selected"
                    v-on:touchstart.stop="showChipModal(true, selectChip, $event)">
                    <div class="chip-image" >
                        <img v-bind:src="selectChip['src']">
                        <div class="chip-value">
                            {{ selectChip['tx'] }}
                            <!--                            chip modal 이동  이은정-->
                            <div
                                class="chipsWideOpen"
                                v-if="isShowChipModal"
                                v-on:touchstart.stop="closeModal(false)">
                                <div
                                    class="chipsNumBox"
                                    v-bind:class="[getChipClass(idx)]"
                                    v-for="(chip, idx) in chips"
                                    v-bind:key="idx"
                                    v-on:touchstart.stop="clickChip(chip, $event)">
                                    <span>{{ chip['tx'] }}</span>
                                    <img v-bind:src="chip['src']">
                                </div>
                                <div class="centerChipWrap">
                                    <div class="centerChip">
                                        <span>{{ selectChip['tx'] }}</span>
                                        <img v-bind:src="selectChip['src']">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightBtn">
                <div
                    class="button"
                    v-bind:class="buttons[1]['key']"
                    v-on:touchstart.stop="buttons[1]['click']">
                    <img
                        v-bind:src="buttons[1]['src']"
                        width="58"
                        height="58"/>
                    <span>{{ $t(buttons[1]['key']) }}</span>
                </div>
                <div
                    class="button"
                    v-bind:class="buttons[2]['key']"
                    v-on:touchstart.stop="buttons[2]['click']">
                    <img
                        v-bind:src="buttons[2]['src']"
                        width="58"
                        height="58"/>
                    <span>{{ $t(buttons[2]['key']) }}</span>
                </div>
            </div>
        </div>
        <div class="score-interface">
            <div
                v-for="(icon, idx) in scoreIcons"
                v-bind:key="idx"
                v-on:touchstart.stop="selectScoreIcon = icon['key'];">
                <img
                    v-bind:src="getScoreIconImg(icon)"
                    class="scoreIcon"/>
            </div>
        </div>
        <div class="scoreBoardWrap">
            <keep-alive>
                <ScoreBoard1
                    v-bind:scoreBoard="getBeadRoad(roomNo)"
                    v-bind:options="{size: 'sm'}"
                    v-if="selectScoreIcon === '1'"/>
            </keep-alive>
            <keep-alive>
                <ScoreBoard
                    v-bind:scoreBoard="getBigRoad(roomNo)"
                    v-bind:options="{size: 'sm'}"
                    v-if="selectScoreIcon === '2'"/>
            </keep-alive>
            <keep-alive>
                <ScoreBoard1Gun
                    v-bind:scoreBoard="getBigEyeRoad(roomNo)"
                    v-bind:options="{size: 'sm'}"
                    v-if="selectScoreIcon === '3'"/>
            </keep-alive>
            <keep-alive>
                <ScoreBoard2Gun
                    v-bind:scoreBoard="getSmallRoad(roomNo)"
                    v-bind:options="{size: 'sm'}"
                    v-if="selectScoreIcon === '4'"/>
            </keep-alive>
            <keep-alive>
                <ScoreBoard3Gun
                    v-bind:scoreBoard="getCockRoad(roomNo)"
                    v-bind:options="{size: 'sm'}"
                    v-if="selectScoreIcon === '5'"/>
            </keep-alive>
        </div>
        <div class="footer">
            <InGameRoomInfo
                v-bind:tableNo="tableNo"
                v-bind:gameId="gameId"
                v-bind:gameNo="gameNo"
                v-bind:betMinMax="betMinMax"/>
        </div>
    </div>
    <transition name="bounce">
        <div class="betListWrap">
            <UserBetListM
                v-if="isShowBetUser"
                v-bind:roomBetList="roomBetList"
            />
        </div>
    </transition>
    <ChangeTable
        v-if="modalKey == 'change'"
        v-bind:roomNo="roomNo"
        v-on:exitModal="exitModal"/>
</template>
<script>
    import { defineComponent } from "vue";
    import { mapGetters } from "vuex";
    import InGameUserInfo from "@/components/mobile/layout/InGameUserInfo"
    import InGameBetBoard from "@/components/mobile/layout/InGameBetBoard"
    import ScoreBoard from "@/components/common/ScoreBoard";
    import ScoreBoard1 from "@/components/common/ScoreBoard1";
    import ScoreBoard1Gun from "@/components/common/ScoreBoard1Gun";
    import ScoreBoard2Gun from "@/components/common/ScoreBoard2Gun";
    import ScoreBoard3Gun from "@/components/common/ScoreBoard3Gun";
    import InGameRoomInfo from "@/components/common/InGameRoomInfo";
    import InGameSideMenu from "@/components/mobile/InGameSideMenu";
    import ChangeTable from "@/components/mobile/popup/ChangeTable";
    import Musics from "@/components/common/Musics";
    import InGame from "@/components/InGame";
    import UserBetListM from "@/components/popup/UserBetListM";
    /*import CameraWebRTCDirect from "@/components/streaming/CameraWebRTCDirect";*/
    import CameraWebRTC from "@/components/streaming/CameraWebRTC";
    import { setUserBetInfo } from "@/plugins/mqtt";
    import { chips } from '@/js/reactive';
    import {DEALER_INFO} from "@/js/const";
    import {
        getBeadRoad,
        getBigRoad,
        getBigEyeRoad,
        getSmallRoad,
        getCockRoad
    } from "@/js/scoreBoard/generator";

    export default defineComponent({
        mixins: [InGame],
        components: {
            InGameRoomInfo,
            InGameUserInfo,
            InGameBetBoard,
            ScoreBoard,
            ScoreBoard1,
            ScoreBoard1Gun,
            ScoreBoard2Gun,
            ScoreBoard3Gun,
            ChangeTable,
            Musics,
            UserBetListM,
            /* CameraWebRTCDirect,*/
            CameraWebRTC,
            InGameSideMenu
        },
        data() {
            return {
                chips: chips,
                parentChipPos: {},
                modalKey: '',
                isShowSideMenu: false,
                isShowSideModal: false,
                isShowChipModal: false,
                // 선택한 칩
                selectChip: {
                    'key': 'chipBlack',
                    'tx': '10',
                    'value': 1,
                    'src': require('@/assets/chips/1_chip.png')
                },
                dWidth : document.documentElement.clientWidth,
                dHeight : document.documentElement.clientHeight,
                selectScoreIcon: '1',
                scoreIcons: [
                    {
                        key: '1',
                        changeIcon: require('../../assets/icons/score/scoreboard-icon-1.png'),
                        changeOnIcon: require('../../assets/icons/score/scoreboard-icon-1-ex.png')
                    },
                    {
                        key: '2',
                        changeIcon: require('../../assets/icons/score/scoreboard-icon-2.png'),
                        changeOnIcon: require('../../assets/icons/score/scoreboard-icon-2-ex.png')
                    },
                    {
                        key: '3',
                        changeIcon: require('../../assets/icons/score/scoreboard-icon-3.png'),
                        changeOnIcon: require('../../assets/icons/score/scoreboard-icon-3-ex.png')
                    },
                    {
                        key: '4',
                        changeIcon: require('../../assets/icons/score/scoreboard-icon-5.png'),
                        changeOnIcon: require('../../assets/icons/score/scoreboard-icon-5-ex.png')
                    },
                    {
                        key: '5',
                        changeIcon: require('../../assets/icons/score/scoreboard-icon-4.png'),
                        changeOnIcon: require('../../assets/icons/score/scoreboard-icon-4-ex.png')
                    },
                ],
                buttons: [
                    {
                        key: 'cancel',
                        src: require('../../assets/icons/ingame_bet_btn/btn_cancel.png'),
                        disableSrc: require('../../assets/icons/ingame_bet_btn/btn_cancel_disable.png'),
                        click: () => {
                            this.betCancel();
                        }
                    },
                    {
                        key: 'undo',
                        src: require('../../assets/icons/ingame_bet_btn/btn_undo.png'),
                        disableSrc: require('../../assets/icons/ingame_bet_btn/btn_undo_disable.png'),
                        click: () => {
                            this.betUndo();
                        }
                    },
                    {
                        key: 'rebet',
                        src: require('../../assets/icons/ingame_bet_btn/btn_rebet.png'),
                        disableSrc: require('../../assets/icons/ingame_bet_btn/btn_rebet_disable.png'),
                        click: () => {
                            this.betRestore();
                        }
                    },
                ]
            }
        },
        computed: {
            ...mapGetters([
                'getSelectChip',
                'getCurSelectBoard',
                'getBetDetail',
                'getTotalBet',
                'getBetErrMsg',
                'getBgmOn',
                'getRealWins']),
        },
        watch: {
            getRealWins: {
                //deep: true,
                //handler(isShow){
                //   console.log(isShow);
                //}
            },
        },
        beforeMount(){
            this.showChips = this.chips.slice(0, 3);
            this.$emit('hideModalFooter', true);
        },
        methods: {
            getBeadRoad,
            getBigRoad,
            getBigEyeRoad,
            getSmallRoad,
            getCockRoad,
            // 모바일상점에서 장착시 장착한딜러갱신해주기 위해
            async getEquipDealer(){
                const res = await this.apis.getEquipDealer();
                let equipDealer = res?.decrypt_data?.mounting.length > 0 ?
                    {
                        'type': res?.decrypt_data?.mounting[0]['color_type'],
                        'name': res?.decrypt_data?.mounting[0]['name']
                    } :
                    {
                        'type': 0,
                        'name': 'lisa'
                    };
                this.equipDealer = DEALER_INFO['info'].find(dealer =>
                    dealer['order'] == equipDealer['type'] && dealer['group'] == equipDealer['name'].toLowerCase()
                )?.['previewM'];
            },
            offCam(isOnOff){
                this.isCamOff = isOnOff;
            },
            getScoreIconImg(icon){
                return this.selectScoreIcon == icon['key'] ? icon['changeOnIcon'] : icon['changeIcon'];
            },
            getChipClass(idx){
                return `chipSttn${idx + 1}`
            },
            betUndo(){
                if(this.getTotalBet == 0){
                    return;
                }
                if(this.getCurSelectBoard.length > 0){
                    let popCurSelectedBoard = this.getCurSelectBoard.pop();
                    let popSelectChip = popCurSelectedBoard['betPool'].pop();

                    this.betInfo['totalBet'] -= popSelectChip['value']
                    this.betInfo['totalWin'] -= popSelectChip['value'] * popCurSelectedBoard['win'];
                    this.getBetDetail[popCurSelectedBoard['key']]['bet'] -= popSelectChip['value'];
                    this.getBetDetail[popCurSelectedBoard['key']]['expectWin'] -= popSelectChip['value'] * popCurSelectedBoard['win'];


                    setUserBetInfo(`${this.loginUser['id']}/${this.gameNo}/${JSON.stringify(this.betInfo)}`);
                    this.$store.commit('setTotalBet', this.getTotalBet - popSelectChip['value']);
                    this.$store.commit('setBetDetail', this.getBetDetail);
                    this.$store.commit('setCurSelectBoard', this.getCurSelectBoard);
                }
            },
            clickChip(selected, event)	{
                this.showChipModal(false, selected, event);
                this.$store.commit('setSelectChip', selected);
            },
            hideSideMenu() {
                this.modalKey = '';
                this.isShowSideMenu = false;
                this.isShowSideModal = false;
            },
            exitModal() {
                this.modalKey = '';
                this.isShowSideModal = false;
            },
            setCamOff(isOff) {
                this.isCamOff = isOff;
            },
            showChipModal(isShow, selected, event){
                this.parentChipPos = event.target.getBoundingClientRect();
                this.selectChip = selected;
                this.isShowChipModal = isShow;
            },
            //211014한유정 추가
            closeModal(isShow){
                this.isShowChipModal = isShow;
            }
        }
    })
</script>
<style scoped>
.betListWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 73px;
}
.errMsg {
    color: red;
    width: 100%;
    position: absolute;
    top: 60px;
    z-index: 1;
    font-size: 12px;
    text-align: center;
}
.timeSetBox{
    bottom: 0;
    color: white;
    position: absolute;
    z-index: 4;
    width: 80px;
    margin-left: 45px;
    margin-bottom: 40px;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.timeSetBox .spin {
    background-image: url("../../assets/ingame_loading.png");
    background-size: 100% 100%;
    width: 80px;
    height: 80px;
    animation-name: ani_loading;
    animation-iteration-count: infinite;
    animation-duration: 1s;
}
@keyframes ani_loading{
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.timeSetBox .tx {
    position: absolute;
}
.videoWrap {
    width: 100%;
    height: 100%;
    position: relative;
}
.sideMenu,
.sideModal {
    position: absolute;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 40%;
    top: 5%;
    height: auto;
    background-color: #3b3b3b;
    border: 1px solid #bbbbbb;
}

.sideMenu .header {
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-size: 24px;
}
.sideMenu .header .closeBtn{
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 10px;
}

.sideModal .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #0e162f;
    height: 80px;
}
.sideModal .body {
    flex: 1;
    height: auto;
    background-color: #3b3b3b;
}

.sideMenu .icon {
    width: 32px;
    height: 32px;
    margin-left: 20px;
}

.sideMenu .menu {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    width: 100%;
    min-height: 59px;
    color: white;
}

.video .menus {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    top: 30px;
    right: 0;
    margin-right: 15px;
    z-index: 4;
}

.video .menus .menu {
    width: 39px;
    height: 39px;
}

video {
    width: 100%;
    height: inherit;
}
.wrap{
    width: 100%;
    display: flex;
    height: 100vh;
    background-color: black;
    position: fixed;
    flex-direction: column;
    font-weight: bold;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow-y: auto;
}
.wrap::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

.progressBox {
    position: absolute;
    display: flex;
    justify-content: center;
    top: -20px;
    margin-top: 20px;
    width: 100%;
    height: 20px;
    background-image: radial-gradient(circle, var(--inGame-progressBox-color) 10%, rgba(0, 0, 0, 0) );
    color: var(--inGame-progress-text-color);
    z-index: 4;
}
.progressBox .progressText {
    position: absolute;
    font-size: 14px;
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black;
}
.progressBox .progress {
    display: flex;
    transition: all 0.8s ease-out;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.progress.normal {
    background-image: radial-gradient(circle, var(--inGame-progress-color) 10%, rgba(0, 0, 0, 0) );
}
.progress.wait {
    background-image: radial-gradient(circle, var(--inGame-b-border-color) 10%, rgba(0, 0, 0, 0) );
}
.wrap .video:not(.menu.video) {
    display: flex;
    position: relative;
    width: 100%;
    height: 370px;
    overflow: hidden;
}
.exitIcon {
    right: 0;
    position: absolute;
    z-index: 1;
    width: 112px;
    height: 112px;
    margin-top: 7%;
    margin-right: 3%;
}

.betInfo {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.betDetail {
    font-size: 32px;
    width: 80%;
    height: 58px;
    background-color: #222222;
    color: #ffe431;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    position: relative;
    bottom: 0;
}

.betDetail.p, .betDetail.pp {
    border: 2px solid var(--inGame-pp-border-color);
}
.betDetail.tie {
    border: 2px solid var(--inGame-tie-border-color);
}
.betDetail.b, .betDetail.bp {
    border: 2px solid var(--inGame-b-border-color);
}
.board .resultBox {
    display: flex;
    align-items: center;
    gap: 3px;
    flex-direction: row;
}
.board .resultBox .result {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    width: 60px;
    height: 80px;
    background-color: white;
    border-radius: 7px;
    font-size: 32px;
}
.black {
    color: black;
}

.red {
    color: #dd3d3d;
}
.last {
    min-width: 80px !important;
    height: 60px !important;
}

.result div {
    white-space: pre-line;
}
.last div {
    white-space: normal !important;
}
.interfaces {
    position: relative;
    display: flex;
    align-items: center;
    height: 90px;
    width: 100%;
    bottom: -45px;
}
.interfaces div {
    flex: 1;
}
.interfaces .leftBtn {
    width: 56px;
    height: 64px;
    margin: 0 auto;
}
.interfaces .leftBtn,
.interfaces .rightBtn {
    text-align: center;
    color: #fff;
    font-weight: 300;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.interfaces .leftBtn img,
.interfaces .rightBtn img{
    margin-bottom: 3px;
}
.interfaces .leftBtn:lang(ko),
.interfaces .rightBtn:lang(ko){
    font-size: 12px;
    white-space: nowrap;
}

.interfaces .rightBtn {
    height: 68px;
    margin: 0 auto;
    flex-direction: row;
    align-items: flex-start;
}
.interfaces .rightBtn .button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.interfaces .chips {
    display: flex;
    color: red;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
.interfaces .chips .chip {
    display: flex;
    height: 86px;
    position: relative;
}

.centerChip {
    filter: drop-shadow(0px 0px 9px rgb(35, 128, 255));
}

.chip .chip-image {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.chip .chip-value {
    position: absolute;
    font-size: 25px;
    justify-content: center;
    align-items: center;
    width: 85px;
    text-align: center;
}
.l_buttons {
    position: absolute;
    display: flex;
    width: 80%;
}
.r_buttons {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 95%;
    gap: 3%;
}
.button {
    width: 56px;
    height: 66px;
}

.wrap .score-interface {
    width: 96%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    height: auto;
    /*margin: 5px auto 1% auto;*/
    margin: 0 auto;
    bottom: -50px;
    position: relative;
    padding: 5px 0;
    border-radius: 40px;
    background-color: #0e162f;
    border: 1px solid #3a5695;
}
.wrap .score-interface img {
    display: flex;
}
.scoreIcon {
    width: 18px;
    height: 18px;
}

.scoreBoardWrap {
    width: 96%;
    height: 108px;
    margin: 0 auto;
    position: relative;
    bottom: -50px;
}
.footer {
    position: relative;
    color: #8d8d8d;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 120px;
    min-height: 120px;
    max-height: 120px;
    font-size: 11px;
    bottom: -50px;
}
.resultText {
    display: flex;
    flex-wrap: wrap;
    white-space: pre-line;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 159px;
    width: 100%;
    height: 30px;
}

.resultText2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    white-space: pre-line;
    z-index: 5;
    left: 0;
    font-size: 22px;
    top: 188px;
    width: 100%;
    height: 36px;
    color: white;
    background-image: radial-gradient(circle, #3b3b3b 10%, rgba(0, 0, 0, 0) );
}

.resultText2 .tx {
    color: #ffce70;
}

.resultText.p {
    background-image: radial-gradient(circle, var(--inGame-pp-board-color1) 10%, rgba(0, 0, 0, 0) );
    color: var(--inGame-progress-text-color);
}
.resultText.b {
    background-image: radial-gradient(circle, var(--inGame-b-board-color1) 10%, rgba(0, 0, 0, 0) );
    color: var(--inGame-progress-text-color);
}
.resultText.tie {
    background-image: radial-gradient(circle, var(--inGame-tie-board-color1) 10%, rgba(0, 0, 0, 0) );
    color: var(--inGame-progress-text-color);
}
.betTotalBox {
    width: 40px;
    height: 40px;
    background-color: #222222;
    position: absolute;
    color: #e2e2e2;
    border-radius: 6px;
    font-size: 36px;
    text-align: center;
}
.betTotalBox.p {
    top: 0;
    left: 0;
    border: 1px solid var(--inGame-pp-border-color);
}
.betTotalBox.b {
    top: 0;
    right: 0;
    border: 1px solid var(--inGame-b-border-color);
}
.betTotalBox.tie {
    display: none;
}
.betPoolBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    position: absolute;
    bottom: 0px;
}

.betPoolBox .chip{
    transition: 1s ease-in;
    position: absolute;
    bottom: 10px;
    width: 50px;
    height: 50px;
    flex-basis: 25px;
    flex-shrink: 0;
}
.circleText {
    position: absolute;
    color: white;
    font-size: 40px;
    top: 37%;
    left: 36%;
}

.circleProgress {
    position: absolute;
    z-index: 5;
    line-height: 36px;
    font-size: 10px;
    left: 0;
    bottom: 0;
    opacity: 0.5;
}
.cell.p, .cell.pp {
    border: 4px solid blue !important;
}
.cell.b, .cell.bp {
    border: 4px solid red !important;
}
.cell.tie {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="100%" x2="100%" y2="0" stroke="green"/></svg>');
    border:1px solid green;
}

/* 210930한유정 추가 / 이미지 삽입 */
.bgImgBox{
    width: 100vw;
    height: inherit;
    z-index: 1;
    margin-top: 20px;
}
.bgImgBox img {
    height: inherit;
}
.chrctImgBox{
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    z-index: 2;
}
.chrctImgBox > img{
    width: 200px !important;
    height: 250px;
    object-fit: contain !important;
    min-width: unset !important;
}
.tableImgBox{
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 3;
    margin-bottom: 24px;
}
.videoWrap > div img{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
}
.notSelected{
    opacity: 0.5;
    transform: scale(0.9);
}


/* 211013 한유정 추가 / 칩 팝업창 */
.selectChipBg{
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 5;
}
.chipsWideOpen{
    position: absolute;
    z-index: 15;
    bottom: -80px;
    left: -151px;
}
.chipsWideOpen img{
    width: 56px;
    height: 56px;
}
.chipsWideOpen .chipsNumBox{
    position: absolute;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chipsWideOpen .chipsNumBox span{
    position: absolute;
    font-size: 19px;
    color: #f42121;
    font-weight: 500;
}
.chipSttn1{
    left: 100px;
    bottom: 0;
}
.chipSttn2{
    left: 70px;
    bottom: 65px;
}
.chipSttn3{
    left: 100px;
    bottom: 135px;
}
.chipSttn4{
    left: 165px;
    bottom: 170px;
    z-index: 99;
}
.chipSttn5{
    left: 230px;
    bottom: 135px;
}
.chipSttn6{
    left: 260px;
    bottom: 65px;
}
.chipSttn7{
    left: 230px;
    bottom: 0;
}
.centerChipWrap{
    position: absolute;
    top: -135px;
    left: 151px;
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerChip{
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerChip span{
position: absolute;
font-size: 25px;
color: #f42121;
font-weight: 500;
}
.centerChip img,
.chip-image img{
width: 82px !important;
height: 82px !important;
}
.bounce-enter-active {
animation: bounce-in .5s;
}
.bounce-leave-active {
animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
0% {
    transform: scale(0);
}
50% {
    transform: scale(1.5);
}
100% {
    transform: scale(1);
}
}
</style>