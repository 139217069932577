import detectEthereumProvider from '@metamask/detect-provider';
import MetaMaskOnboarding from '@metamask/onboarding';
import { reactive } from 'vue';
import { login } from "@/js/common";
import { err } from "@/js/reactive";

export const setting = {
    accounts: [],
    metaMask: {
        onboard: undefined,
        provider: undefined
    },
    isMetaMaskOk: true,
    fn: {
        onBoarding: async () => {
            setting.metaMask.onboard = new MetaMaskOnboarding();
            if(!MetaMaskOnboarding.isMetaMaskInstalled()){
                setting.isMetaMaskOk = false;
                setting.metaMask.onboard.startOnboarding();
            } else if(setting.accounts.length > 0 || MetaMaskOnboarding.isMetaMaskInstalled()){
                setting.isMetaMaskOk = true;
                const res = await setting.fn.call('wallet_requestPermissions', [{eth_accounts: {}}]);
                const addr = await setting.fn.call('eth_requestAccounts');
                if(addr.length > 0){
                    setting.accounts = addr;
                    await login(addr[0], '1111', false, () => {}, undefined, "", 'metamask');
                }
            }
        },
        initMetaMask: async () => {
            const provider = await detectEthereumProvider();
            if(provider){
                setting.metaMask.provider = provider;
            }
            return setting.metaMask.provider;
        },
        call: async (flag, params = {}) => {
            if(!setting.metaMask.provider){
                await setting.fn.initMetaMask();
            }
            const res = await setting.metaMask.provider.request({
                method: flag,
                params
            });
            return res;
        },
    }
}