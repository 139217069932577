<template>
    <div
        class="loginWrap"
        v-bind:class="{ 'mobile': isMobile() }"
        v-on:touchstart.stop="data.isShowHelpModal = false">
        <div class="loginBox">
            <div class="mobileLogoWrap">
                <img
                    src="@/assets/sabong/logo/VS2.png"
                    alt="">
                <img
                    src="@/assets/sabong/logo/vinussabong.png"
                    alt="">
                <img
                    class="closeBtn"
                    src="@/assets/icons/btn_close.png"
                    alt=""
                    v-on:click.stop="showModal('login', false)">
                <h2>Login</h2>
            </div>
            <div class="inputWrap">
                <div class="inputBox">
                    <div class="inputIcon">
                        <img src="@/assets/sabong/icon/icon_user.png"/>
                    </div>
                    <div class="input">
                        <input
                            v-model="data.name"
                            autocapitalize="off"
                            autocomplete="no"
                            autofocus
                            class="inputID"
                            placeholder="Username"
                            type="text"
                            v-on:keyup.enter="login(data.name, data.pwd, data.remember['id'], fadeAnim)"
                        />
                    </div>
                </div>
                <div class="inputBox">
                    <div class="inputIcon">
                        <img src="@/assets/sabong/icon/icon_lock.png"/>
                    </div>
                    <div class="input">
                        <input
                            v-model="data.pwd"
                            autocomplete="no"
                            class="password"
                            placeholder="Password"
                            type="password"
                            v-on:keyup.enter="login(data.name, data.pwd, data.remember['id'], fadeAnim)"
                        />
                    </div>
                </div>
            </div>
            <div class="interfaces">
                <div class="leftContents">
                    <input
                        id="rememberCheck"
                        v-model="data.remember['id']"
                        type="checkbox"/>
                    <label for="rememberCheck">
                        Remember Me
                    </label>
                </div>
                <div class="rightContents">
                    <!--                    <div
                        class="helpcs"
                        v-on:click.stop="store.commit('modal/showModal', { modalKey: 'helpcs', open: true, styles: isMobile() ? { width: '80vw' } : { width: '500px'}})">
                        Help C/S
                    </div>-->
                </div>
            </div>
            <div class="loginBtn">
                <button
                    v-on:click="login(data.name, data.pwd, data.remember['id'] , fadeAnim)"
                    class="loginbtn"
                >Account Login
                </button>
                <button
                    v-on:click="store.commit('modal/showModal', { modalKey: 'signup', open: true, styles: { width: isMobile() ? '100vw' : '400px' }})"
                    class="signupBtn"
                >Sign up
                </button>
                <p class="loginWrong">
                    {{ err.msg }}
                </p>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { watchEffect, reactive, onMounted, ref } from "vue";
    import { login }           from "@/js/common";
    import { getCookie, isMobile } from "@/js/utils";
    import router from "@/router";
    import { showModal } from "@/js/modal/modals";
    import { err } from "@/js/reactive";
    import Intro from "@/components/sabong/intro";
    const logoSrc = ref(undefined);

    const data = reactive({
        show : true,
        name: "",
        pwd: "",
        isShowHelpModal: false,
        remember: {
            "id": false
        }
    });

    const fadeAnim = () => {
        data.show = false;
    }

    const style = (boolean , style) => boolean ? style : undefined;

    onMounted(async () => {
        if(getCookie('rememberId')){
            data.name = getCookie('rememberId');
        }
        logoSrc.value = process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? require("@/assets/sabong/logo/VS_logo.png") : require("@/assets/weblogin/v-glogo.png");
    });
</script>
<style scoped>
	.disabled {
		cursor: not-allowed !important;
		opacity: 0.5;
	}
    *{
        font-family: 'Folks', sans-serif;
        letter-spacing: .3px;
    }
    .loginWrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: inherit;
        height: inherit;
        /*background-color: #1f2839;*/
        /*padding: 63px 36px 40px;*/
        padding: 52px 12px 50px;
    }
    .loginWrap .mobileLogoWrap{
        display: none;
    }
    .loginWrap h2{
        padding: 10px;
        border-left: 6px solid #5ccfff;
        font-size: 30px;
        font-weight: bold;
        color: #5ccfff;
        margin-bottom: 37px;
    }

    .loginBox {
        width: 100%;
        position: relative;
    }
    .closeBtn{
        position: absolute;
        right: -4px;
        top: -40px;
        cursor: pointer;
    }

    .loginBox .inputWrap .inputBox{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 11px;
        height: 48px;
        background-color: #1b1b1b;
        padding: 0 16px;
        border-radius: 6px;
    }
    .loginBox .inputWrap > div:first-child{
        margin-bottom: 38px;
    }

    .loginBox input {
        color: #ffffff;
        font-size: 18px;
        box-sizing: border-box;
        background-color: transparent;
        border: none;
    }

    .loginBox input::placeholder {
        color: #b2b2b2;
    }



    .loginBox .interfaces {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        margin-top: 12px;
    }

    .loginBox .interfaces input[type="checkbox"] {
        -webkit-appearance: none;
        position: relative;
        width: 24px !important;
        height: 24px !important;
        cursor: pointer;
        outline: none !important;
        border-radius: 6px;
        padding: 10px;
        background: #000000;
    }

    .loginBox .interfaces input[type="checkbox"]::before {
        content: "\2713";
        position: absolute;
        top: 50%;
        left: 50%;
        font-weight: bold;
        overflow: hidden;
        transform: scale(0) translate(-50%, -50%);
        line-height: 1;
    }

    .loginBox .interfaces input[type="checkbox"]:checked {
        background: #000000;
        border-color: rgba(255, 255, 255, 0.3);
        color: #b2b2b2;
    }

    .loginBox .interfaces input[type="checkbox"]:checked::before {
        border-radius: 6px;
        transform: scale(1) translate(-50%, -50%)
    }

    .loginBox .interfaces label{
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .loginBox .interfaces .leftContents {
        color: #b2b2b2;
        display: flex;
        gap: 12px;
    }
    .loginBox .interfaces .rightContents {
        color: #b2b2b2;
        text-align: right;
        cursor: pointer;
    }
    .helpcs {
        position: relative;
    }


    .loginBtn{
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-top: 64px;
    }
    .loginBtn button{
        width: 100%;
        height: 60px;
        border-radius: 10px;
        background-image: linear-gradient(75deg, #238b4d 27%, #7ccb37 83%);
        color: #fff;
        font-size: 24px;
    }
	.signupBtn {
		background-image: linear-gradient(75deg, #d2a023 27%, #bd3996 83%) !important;
	}
    .loginBtn .loginWrong{
        width: 100%;
        text-align: center;
        color: #ff2e2e;
        font-size: 20px;
    }


    .walletBtnWrap .walletBtn{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .walletBtnWrap .walletBtn div{
        width: 100%;
        height: 60px;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 22px;
        cursor: pointer;
    }
    .walletBtnWrap .walletBtn div p{
        font-size: 16px;
        font-weight: bold;
        color: #000;
        text-align: right;
    }
    .walletBtnWrap .walletBtn div img{
        height: 80%;
    }


    /*************** mobile ****************/
    .loginWrap.mobile{
        width: 100vw;
        height: 100vh;
        padding: 0 77px;
        justify-content: center;
    }
    .loginWrap.mobile .mobileLogoWrap{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 14px;
        margin-bottom: 57px;
    }
    .loginWrap.mobile .mobileLogoWrap img:first-child{
        width: 110px;
    }

    .loginWrap.mobile h2{
        padding: 0 0 0 7px;
        border-left: 2px solid #5ccfff;
        font-size: 22px;
        font-weight: normal;
        margin-bottom: 20px;
    }

    .loginWrap.mobile .closeBtn{
        display: none;
    }


    .loginWrap.mobile .walletBtnWrap{
        display: none;
    }


    .loginWrap.mobile .inputWrap .inputBox{
        border-bottom: 1px solid #b2b2b2;
        gap: 6px;
        padding: 0 6px 4px;
    }
    .loginWrap.mobile .inputWrap > div:first-child{
        margin-bottom: 28px;
    }

    .loginWrap.mobile .loginBox input {
        font-size: 12px;
    }
    .loginWrap.mobile .inputIcon img{
        width: 14px;
        vertical-align: middle;
    }

    .loginWrap.mobile .loginBox .interfaces {
        font-size: 12px;
        margin-top: 7px;
    }

    .loginWrap.mobile .loginBox .interfaces input[type="checkbox"] {
        width: 14px !important;
        height: 14px !important;
        border-radius: 2px;
        padding: 4px;
    }
    .loginBox.mobile .interfaces .leftContents {
        gap: 4px;
    }
    .loginBox.mobile .interfaces .rightContents {
        padding-right: 0;
    }

    .loginWrap.mobile .loginBtn{
        gap: 4px;
        margin-top: 32px;
    }
    .loginBtn button{
        height: 40px;
        font-size: 20px;
    }
    .loginBtn .loginWrong{
        font-size: 16px;
    }




</style>
