<template>
    <nav class="sidemenu">
        <ul>
            <li
                v-for="(menu, key) in menuList[deployType]"
                v-bind:key="key"
                v-bind:class="[{ 'hide': menu['hide']}, menu['key']]"
                v-on:click.stop="menu['click'](menu)">
                <img
                    v-if="menu['img']"
                    v-bind:src="menu['img']"
                    width="24">
                <p>{{ $t(menu['key']) }}</p>
            </li>
            <hr class="hr">
            <li
                v-on:click.stop="logout()"
                v-tokenCheck>
                <img
                    src="@/assets/icons/lobby_menu/menu_logout.svg"
                    width="24">
                <p>{{ $t('logout') }}</p>
            </li>
        </ul>
    </nav>
</template>
<script setup>
    import Modal from "@/components/mobile/popup/Modal";
    import { logout } from "@/js/common";
    import { useStore } from "vuex";
    const store = useStore();
    const deployType = process.env.VUE_APP_DEPLOY_GAME_TYPE;
    const menuList = {
        'live': [
            {
                'key': 'daily',
                'img': require('@/assets/icons/lobby_menu/menu_daily.svg'),
                'click': () => {},
                'lock': false,
                'hide': false
            },
            {
                'key': 'shop',
                'img': require('@/assets/icons/lobby_menu/menu_shop.svg'),
                'click': () => {},
                'lock': false,
                'hide': false
            },
            {
                'key': 'rank',
                'img': require('@/assets/icons/lobby_menu/menu_rank.svg'),
                'click': () => {},
                'lock': false,
                'hide': false
            },
            {
                'key': 'history',
                'img': require('@/assets/icons/lobby_menu/menu_history.svg'),
                'click': () => {},
                'lock': false,
                'hide': false
            },
            {
                'key': 'language',
                'img': require('@/assets/icons/lobby_menu/menu_language.svg'),
                'click': () => {},
                'lock': false,
                'hide': false
            },
            {
                'key': 'help',
                'img': require('@/assets/icons/lobby_menu/menu_help.svg'),
                'click': () => {},
                'lock': false,
                'hide': false
            }
        ],
        'sabong': [
            {
                'key': 'language',
                'img': require('@/assets/icons/lobby_menu/menu_language.svg'),
                'click': () => {
                    store.commit('modal/showSideMenu', !store.getters['modal/isSideMenuOpen'])
                    store.commit('modal/showModal', { modalKey: 'language', open: true, styles: { width: '100vw',height: '100vh'}})
                },
                'hide': false
            },
            // 일반유저 입출금
            {
                'key': 'transaction',
                'img': require('@/assets/sabong/icon/Deposit2.png'),
                'click': () => {
                    store.commit('modal/showSideMenu', !store.getters['modal/isSideMenuOpen'])
                    store.commit('modal/showModal', { modalKey: 'transaction', open: true, styles: { width: '100vw',height: '100vh'}})
                },
                'hide': store.getters['login/getLoginUser'].isWallet ? true : false
            },
            // 일반유저 히스토리
            {
                'key': 'transactionHistory',
                'img': require('@/assets/icons/lobby_menu/menu_history.svg'),
                'click': () => {
                    store.commit('modal/showSideMenu', !store.getters['modal/isSideMenuOpen'])
                    store.commit('modal/showModal', { modalKey: 'transactionHistory', open: true, styles: { width: '100vw',height: '100vh'}})
                },
                'hide': store.getters['login/getLoginUser'].isWallet ? true : false
            },
            // 월렛유저 deposit
            {
                'key': 'deposit_menu',
                'img': require('@/assets/sabong/icon/Deposit2.png'),
                'click': () => {
                    store.commit('modal/showModal', { modalKey: 'deposit_menu', open: true, styles: { width: '100vw',height: '100vh'}})
                },
                'hide': store.getters['login/getLoginUser'].isWallet ? false : true
            },
            // 월렛유저 withdrawal
            {
                'key': 'withdrawal',
                'img': require('@/assets/sabong/icon/Withdrawal2.png'),
                'click': () => {
                    store.commit('modal/showModal', { modalKey: 'withdrawal', open: true, styles: { width: '100vw',height: '100vh'}})
                },
                'hide': store.getters['login/getLoginUser'].isWallet ? false : true
            },
            // 월렛유저 walletHistory
            {
                'key': 'walletHistory',
                'img': require('@/assets/sabong/icon/Wallet_History2.png'),
                'click': () => {
                    store.commit('modal/showModal', { modalKey: 'walletHistory', open: true, styles: { width: '100vw',height: '100vh'}})
                },
                'hide': store.getters['login/getLoginUser'].isWallet ? false : true
            },
            {
                'key': 'help',
                'img': require('@/assets/icons/lobby_menu/menu_help.svg'),
                'click': () => {
                    store.commit('modal/showModal', { modalKey: 'help', open: true, styles: { width: '100vw',height: 'auto'}})
                },
                'lock': false,
                'hide': false
            },
        ]
    }
</script>
<style scoped>
	*{
		font-family: 'Folks', sans-serif;
		letter-spacing: .3px;
	}
	.sidemenu ul li.hide {
		display: none;
	}
	.lock {
		color: gray;
	}

	nav.sidemenu{
		position: absolute;
		top: 38px;
		right: 0px;
		width: 224px !important;
		height: auto;
		border: solid 1px #bbb;
		background-color: #1b1b1b;
		color: #ffffff;
		font-size: 18px;
		padding:  20px 9px !important;
		z-index: 5;
		border-radius: 12px;
	}
	nav.sidemenu ul{
		display: flex;
		flex-direction: column;
		height: 100%;
		gap: 34px;
	}
	nav.sidemenu ul li{
		display: flex;
		align-items: center;
		width: 100%;
		gap: 15px;
	}
	nav.sidemenu ul li.btnBox{
		gap: 20px;
	}
	nav.sidemenu ul li:last-child{
		padding-bottom: 5px;
	}
	nav.sidemenu ul li > img{
		height: 22px;
	}
	.hr{
		height: 2px;
		display: block;
		background-color: #6c6c6c;
		border: none;
	}
	li.btnBox{
		display: flex;
		flex-direction: column;
		height: auto !important;
		gap: 20px;
		padding-left: 0 !important;
	}
	li.btnBox button{
		width: 100%;
		height: 30px;
		text-align: left;
		font-size: 17px;
		color: #ffffff;
		padding: 0 5px;
		white-space: nowrap;
	}
</style>