import { apis } from '@/repository/repository';

const fn = {
    deposit: async (amount) => {
        const res = await apis.deposit({
            amount: amount
        });
        return res;
    },
    withdrawal: async (amount) => {
        const res = await apis.withdrawal({
            amount: amount
        });
        return res;
    },
    depositHistory: async (date) => {
        const res = await apis.depositHistory({
            start_date: `${date['startDate']} 00:00:00`,
            end_date: `${date['endDate']} 23:59:59`,
            search_limit: 10
        });
        return res;
    },
    withdrawalHistory: async (date) => {
        const res = await apis.withdrawalHistory({
            start_date: `${date['startDate']} 00:00:00`,
            end_date: `${date['endDate']} 23:59:59`,
            search_limit: 10
        });
        return res;
    }
}

export {
    fn
}