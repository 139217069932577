<template>
    <div
        class="modalBg"
        v-bind:class="{ 'mobile': isMobile() }">
        <div
            class="backdrop"
            v-on:click.stop="$emit('closeModal');">
        </div>
        <div class="modalLog">
            <div class="modalLogTopBox">
                <h1>{{ $t('walletCreateTit') }}</h1>
                <span
                    class="exitImg"
                    v-on:click="$emit('closeModal')">
                    <img
                        alt="close"
                        src="../../../assets/mobile/btn-close.png">
                </span>
            </div>
            <div class="modalInner">
                <div class="mvaWrap">
                    <p>
                        {{ $t('walletCreateTxt') }}
                    </p>
                </div>
                <div class="btnWrap">
                    <button
                        v-on:click="getCreatWallet(), $emit('closeModal')"
                        class="confirmBtn">
                        {{ $t('confirm') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { isMobile } from "@/js/utils";
    import { walletOwn, walletAddress, makeWallet } from "@/js/inGame/sabongWallet.js"
    import { err } from "@/js/reactive";

    const getCreatWallet = async () => {
        const res = await makeWallet();
        if(res[0] === true) {
            await walletOwn();
            await walletAddress();
            err['msg'] = res[1];
        }else {
            err['msg'] = res[1];
        }
    }

</script>
<style scoped>
    .modalBg{
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 10;
    }

    .modalLog{
        position: relative;
        width: 420px;
        height: auto;
        border-radius:5px;
        border: 2px solid #484848;
        background-color:#3b3b3b ;
        z-index: 12;
    }

    .modalLogTopBox{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        color: #fff;
    }

    .modalLogTopBox h1{
        font-size: 24px;
        text-align: center;
        word-break: break-all;
        width: 50%;
    }
    .exitImg{
        position: absolute;
        right: 0;
        margin-right: 4%;
        cursor: pointer;
    }
    .exitImg img{
        width: 36px;
    }
    .modalInner{
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        font-size:20px;
    }
    .modalInner>div{
        box-sizing: border-box;
        background: #1b1b1b;
    }
    .modalInner button{
        width: 294px;
        height:50px;
        line-height: 50px;
        background-image: linear-gradient(to bottom, #6696f6, #2352af);
        border: 2px solid #e1e1e1;
        font-size:20px;
    }
    .mvaWrap{
        height:auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 56px 0;
    }
    .mvaWrap p{
        width: 80%;
        font-size: 20px;
        line-height: 35px;

    }
    .btnWrap{
        background: none !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 42px 0 34px;
    }
    *{
        color:#fff;
    }

    /* mobile */
    .modalBg.mobile .modalLog{
        width: 95vw;
        height: auto;
    }
    .modalBg.mobile .modalLogTopBox{
        padding: 10px 0;
    }
    .modalBg.mobile .modalLogTopBox h1{
        margin: 0;
        font-size: 20px;
    }
    .modalBg.mobile .modalLogTopBox .exitImg{
        width: 28px;
        margin-right: 10px;
    }
    .modalBg.mobile .modalLogTopBox .exitImg img{
        width: 28px;
    }
    .modalBg.mobile .modalInner{
        font-size: 14px;
        line-height: 25px;
    }
    .modalBg.mobile .mvaWrap{
        padding:40px 18px;
    }
    .modalBg.mobile .mvaWrap p{
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.42px;
        text-align: center;
    }
    .modalBg.mobile .btnWrap{
        gap: 26px;
        padding: 32px 0;
    }
    .modalBg.mobile .modalInner button{
        width: 80%;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
    }
</style>
