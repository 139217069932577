export const DEFAULT_THEME = {
    variables: {
        "--header-color": "#0e162f",
        "--header-font-color": "white",
        "--gameList-header-color": "#0c1215",
        "--gameList-header-font-color": "white",
        "--body-color": "#0c1215",

        "--room-betInfo-color": "#1a274f",
        "--room-tableTitle-color": "#214793",

        "--lobby-lang-background-color": "#0e162f",
        "--lobby-lang-selected-background-color": "#25376d",

        "--inGame-player-color": "#007eff",
        "--inGame-banker-color": "#e70101",
        "--inGame-tie-color": "#08b300",
        "--inGame-userInfo-title-color": "white",
        "--inGame-userInfo-value-color": "#01c787",

        "--inGame-header-color": "#1b1b1b",
        "--inGame-header-border-color": "#bbbbbb",
        "--inGame-pp-board-color1": "#3f64a0",
        "--inGame-pp-board-color2": "#162346",
        "--inGame-pp-border-color": "#4486d7",
        "--inGame-pp-font-color": "#6aaeda",
        "--inGame-tie-board-color1": "#42923e",
        "--inGame-tie-board-color2": "#184a15",
        "--inGame-tie-border-color": "#4bd12b",
        "--inGame-tie-font-color": "#73ff51",
        "--inGame-b-board-color1": "#923e4a",
        "--inGame-b-board-color2": "#4d1413",
        "--inGame-b-border-color": "#dd3d3d",
        "--inGame-b-font-color": "#ff7575",

        "--inGame-progressBox-color": "rgba(7, 28, 108, 1)",
        "--inGame-progress-color": "rgba(248, 155, 0, 1)",
        "--inGame-progress-text-color": "#fff",

        "--inGame-interface-font-color": "#b1b1b1",
        "--inGame-interface-selected-font-color": "#ffa220",

        "--mobile-inGame-header": "#000000",
        "--mobile-inGame-label-color": "#fafafa",
        "--mobile-inGame-color": "#01c787",

        "--modal-header-background-color": "#3b3b3b",
        "--modal-header-font-color": "#f0f0f0",
        "--modal-body-background-color": "#3b3b3b",

        "--modal-shop-dealer-background-color": "#2b2b2b",
        "--modal-shop-confirm-dealer-background-color": "#1b1b1b",
        "--modal-shop-dealer-border-color": "#00080d",
        "--modal-shop-buy-button-gradient1": "#6696f6",
        "--modal-shop-buy-button-gradient2": "#2352af",
        "--modal-shop-buy-button-outline-color": "#e2e2e2",
        "--modal-shop-cancel-button-background-color": "#6e747a",

        "--modal-shop-confirm-priceTag-color": "#236318",
        "--modal-shop-confirm-priceTag-font-color": "#e7b106",

        "--modal-rank-col-font-color": "#e6e6e6",

        "--modal-userInfo-line-color": "#507f9c",

        "--modal-userInfo-id-font-color": "#5ccfff",
        "--modal-userInfo-col-font-color": "#e6e6e6",

        "--modal-history-row-background-color": "#2b2b2b",
        "--modal-history-font-color": "#5ccfff",

        "--modal-history-player-color": "#3c86ff",
        "--modal-history-banker-color": "#ff1818",
        "--modal-history-tie-color": "#26ff01",

        "--modal-chat-input-color": "#111427",
        "--modal-chat-placeholder-color": "#6f6f76",
        "--modal-chat-msg-background-color": "#151e39",
        "--modal-chat-myMsg-background-color": "#4258d3",

        "--modal-changeTable-sameRoom-font-color": "#ffa200",

        "--mobile-modal-header-background-color": "#0e162f",

        "--mobile-modal-body-gradient-color1": "#363c64",
        "--mobile-modal-body-gradient-color2": "#1a1f3f",
        "--mobile-modal-lang-background-color": "#666666",
        "--mobile-modal-lang-selected-background-color": "#eb9923",

        "--mobile-modal-rank-select-background-color": "#111427",
        "--mobile-modal-rank-select-line-color": "#507f9c",

        "--mobile-modal-rank-col-font-color": "#b8c0dc",
        "--mobile-modal-rank-font-color": "#f7e5b5",
        "--mobile-modal-rank-row-background-color": "#151e39",
    },
};
