import { themeApis } from '../repository/repository'
import { getCookie } from "@/js/utils"
import { err } from "@/js/reactive";
import { routeTheme } from "../router/theme";
import router from "@/router";
import store from "@/store";
// state
const state = { 
    activeTheme: undefined,
    userUuid: undefined
}

// mutations
const mutations = {
    changeTheme (state, type) {
        state.activeTheme = type;
    },
    setUuid(state, payload) {
        state.userUuid = payload;
    }
}

// actions
const actions = { 
    // 변경 api 호출
    changeTheme: async ({state, commit}, type ) => {
        const res = await themeApis.setTheme(store.getters['login/getLoginUser'].uuid, type);
        router.removeRoute("inGame");
        routeTheme(res['Data']['theme']);
        commit('changeTheme', res['Data']['theme']);
    }
}

// getters
const getters = {
    // 현재 적용중인 테마
    getTheme (state) {
        return state.activeTheme;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}