<template>
    <div class="changeWrap">
        <h1>Baccarat</h1>
        <div class="roomList">
            <div
                class="table"
                v-on:click.stop="enterRoom(room)"
                v-for="(room, idx) in totalRoom"
                v-bind:key="idx">
                <div
                    class="body"
                    v-bind:class="{ 'selected': $route['params']['tableNo'] == room['table_id'].replace('Table', '')}">
                    <div class="leftContents">
                        <div class="tableId">
                            {{ room['table_id'] }}
                        </div>
                        <div class="minMax">
                            {{ room['currency_code'] }} {{ setNumberFormat(room['player_min_bet']) }} - {{ setNumberFormat(room['player_max_bet']) }}
                        </div>
                    </div>
                    <div
                        class="scoreBoardWrap"
                        v-bind:class="{ 'mobileSize': isMobile() }">
                        <ScoreBoard v-bind:scoreBoard="getBigRoad(room['room_no'])"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { totalRoom,loginUser } from "@/js/reactive";
    import { setNumberFormat, isMobile } from "@/js/utils";
    import { getBigRoad } from "@/js/scoreBoard/generator";
    import ScoreBoard from "@/components/common/ScoreBoard";

    export default defineComponent({
        components: {
            ScoreBoard
        },
        data() {
            return {
                curRoom: undefined,
                totalRoom: totalRoom['rooms'],
                loginUser: loginUser
            }
        },
        watch: {
            totalRoom: {
                deep:true,
                immediate: true,
                handler(rooms){
                }
            }
        },
        methods: {
            getBigRoad,
            isMobile,
            setNumberFormat,
            // 방 입장
            enterRoom(room) {
                this.$router.push({ name: 'inGame', params: JSON.parse(JSON.stringify({
                    roomNo: room['room_no'],
                    room: JSON.stringify(room),
                    tableNo: room['table_id'].replace('Table', ''),
                    index: room['index'] }))});
            },
        }
    });
</script>
<style scoped>

    .scoreBoardWrap.mobileSize{
        width: 180px !important;
        height: 61px !important;
    }

	.scoreBoardWrap {
		width: 97%;
		height: 51px;
	}
	.changeWrap {
		width: inherit;
		height: 95%;
		display: flex;
		flex-direction: column;
		font-weight: bold;
		color: white;
		font-size: 16px;
        background-color: #1b1b1b;
	}
	.changeWrap h1 {
		margin-top: 10px;
	}
	.changeWrap .roomList {
		width: 95%;
		height: inherit;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		gap: 35px;
		overflow-y: scroll;
	}
	.changeWrap .roomList .table {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 30px;
		cursor: pointer;
	}
	.changeWrap .roomList .table .body {
		display: flex;
		flex-direction: column;
	}
    .changeWrap .roomList .table .leftContents{
        display: flex;
        width: 100%;
        padding-right: 3%;
		flex-direction: row;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 5px;
    }
	.changeWrap .roomList .table .rightContents{
		margin-right: 7px;
	}
	.selected {
		cursor: not-allowed;
		color: var(--modal-changeTable-sameRoom-font-color);
	}
    .changeWrap h1{
        font-size: 20px;
        font-weight: 300;
        padding-left: 6px;
        margin-bottom: 25px;
    }
</style>