import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { decryptUrlData, getCookie, setCookie } from "@/js/utils";
import { gameLauncherLogin, login } from "@/js/common";
import Login from "@/components/sabong/SideLogin.vue";
import SignUp from "@/components/sabong/popup/SignUp";

import SabongLobby from "@/components/sabong/SabongLobby.vue";
import SabongLobbyInGame from "@/components/sabong/SabongLobbyInGame.vue";
import Lobby from "@/components/Lobby.vue";
import InGame from "@/components/InGame.vue";

import LobbyM from "@/components/mobile/LobbyM.vue";
import InGameM from "@/components/mobile/InGameM.vue";
import Common from "@/components/mobile/layout/Common.vue";
import { $fetch } from "@/repository/repository";

import SabongInGameInter from "@/components/sabongInter/InGame";
import SabongInGameInterM from "@/components/sabongInter/InGameM";
import ErrorPage from "@/components/common/ErrorPage";

import InGameSkinDemo from "@/components/demo/InGameSkinDemo";

//211019 임시로 열어둠
import Publish from "@/components/sabong/SabongLobby";
import { clearBetData } from "@/js/inGame/sabongInter";

import { err, setting } from "@/js/reactive";
/* https://www.npmjs.com/package/vue-uuid */
import { v4 as uuidv4 } from "uuid";
import { activeTheme, setTheme } from "./theme";
import { checkRefferCode } from "./checkRefferCode";
import { enterSabongRoom } from "@/js/inGame/sabong";
import { setSignature } from "./signature";
const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
let routes = [
    {
        path: "/inGameDemo/:gameType",
        name: "inGameDemo",
        component: InGameSkinDemo
    },
    {
        path: "/streaming/:gameType",
        name: "Streaming",
        component: () => import(/* webpackChunkName: "Streaming" */'@/views/Streaming.vue'),
    },
    {
        path: "/streaming",
        name: "SabongCamTest",
        component: () => import(/* webpackChunkName: "SabongCamTest" */'@/components/common/SabongCamTest.vue'),
    },
    {
        path: "/error",
        name: "ErrorPage",
        component: ErrorPage,
    },
    {
        path      : "/lobby",
        name      : "Lobby",
        component : process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? isMobile() ? LobbyM : SabongLobby : Lobby,
        /*component : process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? isMobile() ? LobbyM : SabongLobby : Lobby,*/
        meta      : { gameType : process.env.VUE_APP_DEPLOY_GAME_TYPE }
    },
    {
        path      : "/lobbyGame",
        name      : "lobbyGame",
        component : process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? isMobile() ? LobbyM : SabongLobbyInGame : Lobby,
        
        meta      : { gameType : process.env.VUE_APP_DEPLOY_GAME_TYPE }
    },
];
if (isMobile()) {
    routes.push(
        {
            path      : "/",
            name      : "Login",
            component : Login,
            meta: {
                checkTokenUser : true
            },
            children: [
                {
                    // 추천인 회원가입의 경로로 올 시 로컬에 남은 로그인 기록 지우고
                    // 회원가입 모달 띄워주기
                    path: ':code/:pk',
                    beforeEnter: (checkRefferCode)
                }
            ]
        },
        {
            path      : "/inGame/:tableNo",
            name      : "inGame",
            component : InGameM,
            children: [{
                path: '',
                beforeEnter: async (to, from, next) => {
                    const theme = location.hostname.indexOf('vsabongservice') > -1 ? 1 : 0;
                    await enterSabongRoom(to.params['tableNo'], theme);
                    next({
                        name: 'inGame', 
                        params: { 
                            'gameType': to.params['tableNo']
                        }
                    });
                }
            }]
        },
        {
            path      : "/common",
            name      : "Common",
            component : Common,
        },
        {
            path      : "/pub",
            name      : "Publish",
            component : Publish,
        });
} else {
    routes.push(
        {
            path      : "/",
            redirect  : "/lobby",
            meta: {
                checkTokenUser : true
            },
            children: [
                {
                    path: ':auth',
                    beforeEnter: (to, from, next) => {
                        err['msg'] = "Wrong PATH! Please Try Again.";
                        next({path : '/error'});
                    }
                },
                {
                    // 추천인 회원가입의 경로로 올 시 로컬에 남은 로그인 기록 지우고
                    // 회원가입 모달 띄워주기
                    path: ':code/:pk',
                    beforeEnter: (checkRefferCode)
                },
                {
                    path: 'inGame/:gameType',
                    beforeEnter: async (to, from, next) => {
                        const theme = location.hostname.indexOf('vsabongservice') > -1 ? 1 : 0;
                        await enterSabongRoom(to.params['gameType'], theme);
                        next({
                            name: 'inGame', 
                            params: { 
                                'gameType': to.params['gameType'] 
                            }
                        });
                    }
                },
            ]
        },
        {
            path: "/inGameLive/:tableNo",
            name: "inGameLive",
            component: () => import(/* webpackChunkName: "inGame" */'@/components/InGame.vue'),
        },
        {
            path      : "/pub",
            name      : "Publish",
            component : Publish,
        },
        {
            path      : "/pub",
            name      : "Publish",
            component : Publish,
        });
}

routes.push({
    path : "/demo",
    name : "demo",
    component : () => import(/* webpackChunkName: "Demo" */"@/views/Demo.vue"),
})


routes.push({
    path : "/vg",
    name : "vg",
    component : () => import(/* webpackChunkName: "VG" */"@/views/VG.vue"),
})

/**
 * @name: 톰혼 쿼리스트링 체크
 * @param: query URL 쿼리스트링
 */
const checkTomhornUrl = (query) => {
    let valid = {
        'isOk': false,
        'msg': 'Please check url parameter'
    };
    let check = {
        'demo': ['clientid', 'gameID', 'language'],
        'real_play': ['clientid', 'gameID', 'language','token']
    }

    if(check[query['mode']]){
        check[query['mode']].some(key => {
            if(!query[key]){
                valid['isOk'] = false;
                valid['msg'] = `Please check url parameter: ${key}`;
                return true;
            }
            valid['isOk'] = true;
            valid['msg'] = '';
            return false;
        });
    }
    return valid;
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if(store.getters.getIsBetting){
        store.commit('setIsBetting', false);
    }
    clearBetData();

    if(to.query.clientid){ // 톰혼런처
        localStorage.token = 'token';
        try{
            let check = checkTomhornUrl(to.query);
            if(check['isOk']){
                let mode = to.query.mode || 'demo';
                setting['locale'] = to.query.language;
                if(mode == 'demo'){
                    let uuid = undefined;
                    if(getCookie('demoUuid') && getCookie('demoUuid') != ''){
                        uuid = getCookie('demoUuid');
                    } else {
                        uuid = uuidv4().split('-')[0] + uuidv4().split('-')[1];
                        setCookie('demoUuid', uuid);
                    }
                    await login(uuid, '1111', false, () => {}, true);
                } else if(mode == 'real_play'){
                    const res = await $fetch('getPlayerInfo', {
                        "token": to.query.token
                    });
                    if(res.Data.err_desc == null){
                        await login(res.Data.nickName, '1111', false, () => {}, false, to.query.token);
                    }
                }
            } else {
                err['msg'] = check['msg'];
                throw check['msg'];
            }
        } catch(e) {
            //에러 페이지 이동
            next('/error');
        }
    } else if(to.query.token){ // gateway 런처로 실해할때
        try{
            localStorage.token = 'token';
            let userInfo = JSON.parse(decryptUrlData(to.query.token));
            if(!userInfo){
                throw "Unknown User Infomation";
            } else {
                if(userInfo?.cms){
                    await gameLauncherLogin(userInfo);
                } else {
                    await login(userInfo.user_id, userInfo.password, false, () => {});
                }
            }
        }catch(e){
            // 에러 페이지 호출
            next('/error');
        }
    }

    if(to.meta.checkTokenUser && to.query?.mode != 'demo'){
        if(localStorage.token == 'token' && !to.query.token){
            next('/error');
        }
    }
    next();
});

router.beforeEach(setTheme);
router.beforeEach(setSignature);
export default router;
