import NodeWalletConnect from "@walletconnect/node";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";
import { login } from "@/js/common";

export const setting = {
    walletConnect: undefined,
    accounts: [],
    fn: {
        onBoarding: async () => {
            // Create a connector
            setting.walletConnect = new NodeWalletConnect(
                {
                    bridge: "https://bridge.walletconnect.org", // Required
                },
                {
                    clientMeta: {
                        description: "WalletConnect NodeJS Client",
                        url: "https://nodejs.org/en/",
                        icons: ["https://nodejs.org/static/images/logo.svg"],
                        name: "WalletConnect",
                    },
                }
            );
            if(setting.walletConnect.connected){
                await setting.walletConnect.killSession();
            }
            if (!setting.walletConnect.connected) {
                setting.walletConnect.createSession().then(() => {
                    const uri = setting.walletConnect.uri;
                    WalletConnectQRCodeModal.open(
                        uri,
                        () => {
                            console.log("QR Code Modal closed");
                        },
                        true // isNode = true
                    );

                });
                setting.walletConnect.on("connect", async (error, payload) => {
                    if (error) {
                        throw error;
                    }
                    // Close QR Code Modal
                    WalletConnectQRCodeModal.close(
                        true // isNode = true
                    );
                    const { accounts, chainId } = payload.params[0];
                    if(accounts && accounts.length > 0){
                        setting.accounts = accounts;
                        await login(accounts[0], '1111', false, () => {}, undefined, "", 'wc');
                    }
                });

                setting.walletConnect.on("session_update", (error, payload) => {
                    if (error) {
                        throw error;
                    }
                    const { accounts, chainId } = payload.params[0];
                });

                setting.walletConnect.on("disconnect", (error, payload) => {
                    if (error) {
                        throw error;
                    }

                });
            }
        }
    }
}