<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('language') }}</h1>
            <span class="exitImg">
                <img
                    src="../../assets/mobile/btn-close.png"
                    alt="close"
                    v-on:click.stop="$emit('closeModal')">
            </span>
        </div>
        <div class="sideMenuInner">
            <ul>
                <li
                    v-for="(lang, key) in langs"
                    v-bind:class="{ 'selected': lang['key'] == $i18n.locale }"
                    v-bind:key="key"
                    v-on:click.stop="changeLang(lang['key'])">
                    <img
                        v-bind:src="lang['img']"
                        alt="english">
                    <h3>{{ $t(lang['key']) }}</h3>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    export default defineComponent({
        data(){
            return {
                locale: 'en',
                langs: [
                    {
                        'key': 'en',
                        'img': require(`../../assets/locale/flag-english.png`),
                        'click': () => {
                        }
                    },
                    {
                        'key': 'th',
                        'img': require(`../../assets/locale/flag-thai.png`),
                        'click': () => {
                        }
                    },
                    {
                        'key': 'sp',
                        'img': require(`../../assets/locale/flag-spanish.png`),
                        'click': () => {
                        }
                    },
                    {
                        'key': 'vt',
                        'img': require(`../../assets/locale/flag-vietnamese.png`),
                        'click': () => {
                        }
                    },
                    {
                        'key': 'hi',
                        'img': require(`../../assets/locale/flag-hindi.png`),
                        'click': () => {
                        }
                    },
                    {
                        'key': 'ko',
                        'img': require(`../../assets/locale/flag-korean.png`),
                        'click': () => {
                        }
                    },
                    {
                        'key': 'ja',
                        'img': require(`../../assets/locale/flag-japanese.png`),
                        'click': () => {
                        }
                    },
                    {
                        'key': 'ch',
                        'img': require(`../../assets/locale/flag-chinese.png`),
                        'click': () => {
                        }
                    }
                ]
            }
        },
        beforeMount() {
            this.locale = this.$i18n.locale;
        },
        methods: {
            changeLang(locale){
                this.$i18n.locale = locale;
                this.$emit('closeModal');
            }
        }
    });

</script>
<style scoped>
.selected {
	background-color: #384f75 !important;
}
.sideMenuBox{
	position: fixed;
	top: 0;

	z-index: 30;
	width: 100%;
	height: 100vh;
	padding-bottom: 50%;
	color: #fff;
	font-size: 14px;
	background-color: #2e3033;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 3%;
	background-color: #2e3033;
}
.sideMenuTopBox > h1 {
	font-size: 22px;
}
.sideMenuInner{
	padding: 3%;
	background-color: #2e3033;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}
.sideMenuInner ul{
	width: 350px;
	height: 488px;
	display: flex;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px 18px;
	margin-top: 10vh;
}
.sideMenuInner ul li{
	background-color: #424242;
	width: 108px;
	height: 108px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    gap: 3px;
}
.sideMenuInner ul li img{
	width: 60px;
	height: auto;
	max-width: 100%;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 4%;
	line-height: 5px;
}
.exitImg img{
	width: 28px;
	height: auto;
}
</style>