<template>
    <div class="sideMenuBox">
        <div class="sideMenuTopBox">
            <h1>{{ $t('rank') }}</h1>
            <span
                class="exitImg"
                v-on:click.stop="$emit('closeModal')">
                <img
                    src="../../assets/mobile/btn-close.png"
                    alt="close">
            </span>
        </div>
        <div class="rank_tab">
            <div
                class="tab"
                v-on:click.stop="clickTab('rank')"
                v-bind:class="{ 'selected': tabKey == 'rank' }"> <!-- 탭 활성 -->
                <div class="tab_text">
                    {{ $t('member') }}
                </div>
            </div>
            <div
                class="tab"
                v-on:click.stop="clickTab('dealerRank')"
                v-bind:class="{ 'selected': tabKey == 'dealerRank' }">
                <div class="tab_text">
                    {{ $t('dealer') }}
                </div>
            </div>
        </div>
        <div
            class="myRankTopBox"
            v-if="tabKey == 'rank' ">
            <div class="tableRank no">
                {{ getMyRankData('no') }}
            </div>
            <div class="tableRankUserBox">
                <div class="tableRank">
                    <span>{{ getMyRankData('id') }}</span>
                </div>
                <div
                    class="tableRank"
                    v-on:click.stop="showUserInfo(getMyRankData('info'))">
                    <img src="../../assets/icons/glasses.png">
                </div>
            </div>
            <div
                class="tableRank amount"
                v-bind:class="getMyRankKey(selectedValue)"
                v-number-format="getMyRankData(selectedValue)">
            </div>
        </div>
        <div class="sideMenuInner">
            <div class="rankBodyHeader">
                <div class="tableRank no">
                    {{ $t('no') }}
                </div>
                <div class="tableRankUserBox">
                    <div class="tableRank">
                        <span v-if="tabKey == 'rank'">
                            {{ $t('id') }}
                        </span>
                        <span v-else>
                            {{ $t('dealerName') }}
                        </span>
                    </div>
                </div>
                <div
                    class="rankDropBoxWrap"
                    v-bind:class="selectedValue"
                    v-on:click.stop="isShowSelect = !isShowSelect">
                    <div class="DropBoxSelect">
                        {{ $t(selectedValue) }}
                    </div>
                    <span
                        class="tabArrow"
                        v-bind:class="{ 'reverse': isShowSelect }">
                        <img src="../../assets/btn/arrow-3.png">
                    </span>
                    <div
                        class="rankDropDownBox"
                        v-if="isShowSelect">
                        <ul>
                            <li
                                v-bind:class="col"
                                v-for="(col, key) in options"
                                v-bind:key="key"
                                v-on:click.stop="selectedValue = col;
                                                 isShowSelect = false">{{ $t(col) }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="ranksList">
            <div
                class="ranks"
                v-for="(rank, key) in rankList"
                v-bind:key="key">
                <div class="tableRank no">
                    <img v-bind:src="rankIcons[key]"/>
                    <span v-if="key > 2">
                        {{ key + 1}}
                    </span>
                </div>
                <div class="tableRankUserBox">
                    <div class="tableRank">
                        <span v-if="tabKey == 'rank'">
                            {{ rank['id'] }}
                        </span>
                        <span v-else>
                            {{ rank['user_id'] }}
                        </span>
                    </div>
                    <div
                        v-if="tabKey == 'rank'"
                        class="tableRank"
                        v-on:click.stop="showUserInfo(rank['info'])">
                        <img src="../../assets/icons/glasses.png">
                    </div>
                    <div
                        v-else
                        class="tableRank"
                        v-on:click.stop="showDealerInfo(rank)">
                        <img src="../../assets/icons/glasses.png">
                    </div>
                </div>
                <div
                    class="tableRank amount"
                    v-bind:class="selectedValue"
                    v-number-format="rank[selectedValue]">
                </div>
            </div>
        </div>
    </div>
    <ModalDealerInfo
        v-on:hideDealerInfo="hideDealerInfo"
        v-if="selectedDealer"
        v-bind:selectedDealer="selectedDealer"/>
</template>
<script>
    import { defineComponent } from "vue";
    import { setNumberFormat, decompression } from "@/js/utils";
    import { calcDealerRank } from "@/js/common";
    import ModalDealerInfo from "@/components/mobile/popup/DealerInfo";

    export default defineComponent({
        components: {
            ModalDealerInfo
        },
        data() {
            return {
                selectedUser: {},
                selectedDealer: undefined,
                selectedValue: '',
                isShowSelect: false,
                options: [],
                dealers: {},
                myRanks: [
                    {
                        'key': 'no',
                        'value': 0,
                    },
                    {
                        'key': 'agent',
                        'value': '-',
                    },
                    {
                        'key': 'id',
                        'value': '-',
                    },
                    {
                        'key': 'detail',
                        'value': '',
                    },
                    {
                        'key': 'deposit',
                        'value': 0,
                    },
                    {
                        'key': 'withdraw',
                        'value': 0,
                    },
                    {
                        'key': 'bets',
                        'value': 0,
                    },
                    {
                        'key': 'pay',
                        'value': 0,
                    },
                    {
                        'key': 'win',
                        'value': 0,
                    },
                    {
                        'key': 'lose',
                        'value': 0,
                    },
                ],
                rankList: [],
                rankIcons: {
                    '0': require("../../assets/icons/grade-1.png"),
                    '1': require("../../assets/icons/grade-2.png"),
                    '2': require("../../assets/icons/grade-3.png")
                },
                tabKey: 'rank',
            }
        },
        beforeMount() {
            this.getRank();
        },
        watch: {
            tabKey: {
                immediate: true,
                handler(val){
                    this.selectedValue = val == 'rank' ? 'deposit' : 'like';
                }
            }
        },
        methods: {
            async clickTab(key){
                this.tabKey = key;
                if(key == 'dealerRank'){
                    await this.getDealerRank();
                } else {
                    await this.getRank();
                }
            },
            hideDealerInfo(){
                this.selectedDealer = undefined;
            },
            getMyRankData(key) {
                return this.myRanks.find(rank => rank['key'] == key)?.['value'] || '-';
            },
            getMyRankKey(key) {
                return this.myRanks.find(rank => rank['key'] == key)?.['key'] || '-';
            },
            showUserInfo(user, key) {
                if (key && key !== 'detail') {
                    return;
                }
                this.selectedUser['id'] = user['user_id'];
                this.selectedUser['deposit'] = user['amount_for_deposit'];
                this.selectedUser['withdraw'] = user['amount_for_withdraw'];
                this.selectedUser['bets'] = user['amount_for_bet'];
                this.selectedUser['country'] = user['country'] ? user['country'] : "INR";
                this.selectedUser['level'] = user['player_level'];
                this.selectedUser['flagImg'] = require(`../../assets/flag/${this.FLAG_IMG[this.selectedUser['country']]}`);
                this.selectedUser['gradeImg'] = require(`../../assets/grade/${this.PLAYER_GRADE_IMG[user['rating']]}`);
                this.isShowUserInfo = true;
            },
            showDealerInfo(rank){
                this.selectedDealer = {
					...rank,
					'img': this.dealers[rank['user_id']]
                }
            },
            async getDealerRank(){
                this.rankList = [];
                this.options = JSON.parse(JSON.stringify([
                    'like', 'tip', 'playTime'
                ]));
                const res = await this.apis.getDealerRank();
                calcDealerRank(res['decrypt_data']['value']).forEach(dealer => {
                    this.rankList.push({
                        'rank': dealer['rank'] || '-',
                        'profile': dealer['user_id'],
                        'user_id': dealer['user_id'],
                        'detail': '',
                        'like': dealer['amount_for_like'],
                        'tip': dealer['amount_for_tip'],
                        'playTime': dealer['amount_for_play_time'],
                    });
                });

                let queue = [];
                this.rankList.forEach(dealer => {
                    queue.push(
                        this.apis.getDealerProfile({
                            start: 0,
                            end: 1,
                            id: dealer['user_id'],
                            req: true
                        })
                    );
                });
                const ress = await Promise.all(queue);
                ress.forEach(res => {
                    let meta = undefined;
                    if(res?.decrypt_data?.images.length > 0){
                        meta = res?.decrypt_data?.images[0]['meta_png'];
                        this.dealers[res['decrypt_data']['images'][0]['name']] = meta ? `data:image/png;base64,${decompression(meta)}` : require('../../assets/icons/lobby_table/dealer-none.png');
                    }
                });
            },
            async getRank() {
                this.rankList = [];
                this.options = JSON.parse(JSON.stringify([
                    'deposit', 'withdraw', 'bets', 'pay', 'win', 'lose'
                ]));
                const res = await this.apis.getRank({'type': 0});
                if (res?.decrypt_data?.value?.myRanking) {
                    let myRank = res?.decrypt_data?.value?.myRanking;
                    this.myRanks[0]['value'] = '50+';
                    this.myRanks[1]['value'] = myRank['agent_id'];
                    this.myRanks[2]['value'] = myRank['user_id'];
                    this.myRanks[3]['value'] = myRank;
                    this.myRanks[4]['value'] = setNumberFormat(myRank['amount_for_deposit']);
                    this.myRanks[5]['value'] = setNumberFormat(myRank['amount_for_withdraw']);
                    this.myRanks[6]['value'] = setNumberFormat(myRank['amount_for_bet']);
                    this.myRanks[7]['value'] = setNumberFormat(myRank['amount_for_pay']);
                    this.myRanks[8]['value'] = setNumberFormat(myRank['amount_for_win']);
                    this.myRanks[9]['value'] = setNumberFormat(myRank['amount_for_lose']);
                }

                if (res?.decrypt_data?.value?.rankingList.length > 0) {
                    res['decrypt_data']['value']['rankingList'].forEach(data => {
                        this.rankList.push({
                            'agent': data['agent_id'] || '-',
                            'id': data['user_id'],
                            'detail': '',
                            'deposit': data['amount_for_deposit'],
                            'withdraw': data['amount_for_withdraw'],
                            'bets': data['amount_for_bet'],
                            'pay': data['amount_for_pay'],
                            'win': data['amount_for_win'],
                            'lose': data['amount_for_lose'],
                            'info': data
                        });
                    });
                }
            }
        }
    });
</script>
<style scoped>
.sideMenuBox{
	width: 100%;
	height: auto;
	color: #fff;
	font-size: 14px;
	background-color: #2e3033;
	position: fixed;
	top: 0;
	z-index: 30;
}
.sideMenuTopBox{
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: #2e3033;
	padding: 3%;
}
.sideMenuTopBox > h1 {
	font-size: 18px;
}

.sideMenuBox .rank_tab {
    display: flex;
	width: 100%;
    flex-direction: row;
	justify-content: center;
    gap: 10px;
}
.sideMenuBox .rank_tab .tab {
	cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
	background-image: url("../../assets/shop/btn-gray-2.png");
    position: relative;
}
.tab.selected {
	background-image: url("../../assets/shop/btn-blue-2.png") !important;
}
.tab.lock {
	cursor: not-allowed !important;
}
.sideMenuBox .rank_tab .tab .tab_text {
    font-size: 16px;
    color: #f1f1f1;
    z-index: 1;
    font-weight: 300;
	white-space: nowrap;
}

.sideMenuInner{
	padding: 18px 14px 12px 14px;
	background-color: #2e3033;
	height: 100%;
}
.sideMenuTopBox > h1 {
	font-size: 22px;
}
.exitImg{
	position: absolute;
	right: 0;
	margin-right: 4%;
}
.exitImg img{
	width: 28px;
	height: auto;
}

/* 내용 */
.rankDropBoxWrap{
	position: relative;
	width: 180px;
	height: auto;
}
.DropBoxSelect{
	width: 100%;
	height: 43px;
	background-color: #1b1b1b;
	text-align: center;
	line-height: 43px;
	font-size: 14px;
	border: 1px solid #bbbbbb;
    padding: 0 20px;
}
.tabArrow img{
	position: absolute;
	width: 20px;
	height: auto;
	max-width: 100%;
	top: 0;
	right: 0;
	margin-top: 15px;
	margin-right: 8px;
	cursor: pointer;
}
.myRankTopBox,.ranks{
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	background-color: #1b1b1b;
	padding: 10px 5px;
}
.rankDropDownBox{
    width: 180px;
	position: absolute;
	top: 0;
	right: 0;
}
.rankDropDownBox ul{
    width: 100%;
	background-color: #1b1b1b;
	height: auto;
	border: 1px solid #bbbbbb;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	font-size: 18px;
}
.rankDropDownBox ul li{
	width: 100%;
	border-bottom: 1px solid #484848;
	padding: 10px 0;
	text-align: center;
}
.rankDropDownBox ul li:last-child{
	border-bottom: none;
}
.tableRank{
	line-height: 22px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tableRankUserBox{
	width: 48%;
	display: flex;
	flex-direction: row;
	gap: 5px;
	white-space: nowrap;
}
.rankBodyHeader{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	text-align: center;
}
.rankBodyHeader .tableRank{
	font-size: 18px;
    white-space: nowrap;
}
.tableRank > span{
    text-align: right;
}
.amount{
	flex: .6;
}
.no{
	flex: .3;
}
.ranks{
	margin: 7px 0;
	border: 1px solid #484848;
    min-height: 56px;
}
.ranksList{
	margin: 0 1%;
	padding: 1px 0 30% 0;
	width: 98%;
	background-color: #1b1b1b;
	height: 700px;
	overflow-y: scroll;
}

/* 각 컬러 */
.total{
	color: #50e145;
}
.deposit{
	color: #3ba4e1;
}
.withdraw{
	color: #f10000;
}
.bets{
	color: #fbe291;
}
.pay{
	color: #e4b204;
}
.win{
	color: #00d6d9;
}
.lose{
	color: #ff5454;
}
</style>