<template>
    <ul>
        <li
            v-bind:class="{ 'hide': icon['hide'], 'opacity': icon['opacity'] }"
            v-on:click="clickIcon(icon['key'])"
            v-on:mouseleave="hoverRightBtn(icon,idx,'hidden')"
            v-on:mouseover="hoverRightBtn(icon,idx,'visible')"
            v-for="(icon, idx) in icons"
            v-bind:key="idx">
            <img v-bind:src="icon['img']">
            <div
                v-bind:ref="el => { if (el) divs[idx] = el }"
                class="tooltip">
                {{ $t(icon['tooltip']) }}
            </div>
        </li>
    </ul>
    <InGameModal
        v-if="modalKey != undefined && modalKey != 'myInfo'"
        v-on:hideModal="clickIcon(modalKey)"
        v-bind:modalKey="modalKey"/>
    <div class="userInfoModalWrap">
        <ModalUserInfo
            v-on:hideModal="hideModal"
            v-if="modalKey == 'myInfo'"
            v-bind:class="{ 'myInfoAnother' : modalKey === 'myInfo' }"
        />
    </div>
</template>
<script setup>
    import { reactive, ref } from "vue";
    import InGameModal from "@/template/sabong/InGameModal/InGameModal";
    import ModalUserInfo from "@/components/popup/ModalUserInfo";
    import router from "@/router";
    const icons = [
        {
            'key': 'change',
            'tooltip': 'change',
            'img': require("@/assets/icons/ingame_top_menu/btn-tchange.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false,
            'opacity': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
        },
        {
            'key': 'history',
            'tooltip': 'history',
            'img': require("@/assets/icons/ingame_top_menu/btn-history.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
            'opacity': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
        },
        {
            'key': 'memberList',
            'tooltip': 'memberList',
            'img': require("@/assets/icons/ingame_top_menu/btn-user-list.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false,
            'opacity': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
        },
        {
            'key': 'chat',
            'tooltip': 'chat',
            'img': require("@/assets/icons/ingame_top_menu/btn-chat.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
            'opacity': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false,
        },
        {
            'key': 'myInfo',
            'tooltip': 'myInfo',
            'img': require("@/assets/icons/ingame_top_menu/btn-user-info.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false,
            'opacity': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
        },
        {
            'key': 'cam',
            'tooltip': 'cam',
            'img': require("@/assets/icons/ingame_top_menu/btn-cam.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false,
            'opacity': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
        },
        {
            'key': 'tutorial',
            'tooltip': 'tutorial',
            'img': require("@/assets/icons/ingame_top_menu/btn-tutorial.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
            'opacity': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
        },
        {
            'key': 'sound',
            'tooltip': 'sound',
            'img': require("@/assets/icons/ingame_top_menu/btn-sound.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
            'opacity': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? true : false,
        },
        {
            'key': 'full',
            'tooltip': 'full',
            'img': require("@/assets/icons/ingame_top_menu/btn-full.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
            'opacity': false
        },
        // {
        //     'key': 'theme',
        //     'tooltip': 'theme',
        //     'img': require("@/assets/icons/ingame_top_menu/btn_uiskin.png"),
        //     'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
        //     'opacity': false
        // },
        {
            'key': 'exit',
            'tooltip': 'lobbyExit',
            'img': require("@/assets/icons/ingame_top_menu/btn-exit.png"),
            'hide': process.env.VUE_APP_DEPLOY_GAME_TYPE == 'sabong' ? false : false,
            'opacity': false
        },
    ]

    const modalKey = ref(undefined);
    const clickIcon = (key) => {
        if(icons.find(icon => icon['key'] == key)['opacity']){
            return;
        }
        if(modalKey.value == key) {
            modalKey.value = undefined;
            return;
        }
        if(key === 'exit'){
            modalKey.value = undefined;
            router.push('/');
        }
        if(key === 'full'){
            if (document.fullscreenElement) {
                document.exitFullscreen()
            } else {
                document.documentElement.requestFullscreen();
            }
            return;
        }
        modalKey.value = key;
    }

    const divs = ref([]);
    const hoverRightBtn = (icon,idx,value) => {
        if(icons.find(icon2 => icon2['key'] == icon['key'])['opacity']){
            return;
        }
        divs.value[idx].style.visibility = value;
    }

</script>
<style scoped>
	.userInfoModalWrap {
		position: absolute;
		left: 130px;
		top: 20px;
	}
    ul{
        width: 430px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 14px;
        gap: 12px;
    }
    ul > li {
        cursor: pointer;
        position: relative;
    }
    ul > li > img{
        width: 50px;
        height: 50px;
    }
    ul > li > img:active{
        transform: scale(0.9);
        animation: click 0.2s;
    }
    @keyframes click {
        0%{transform: scale(0.9);}
        50%{transform: scale(1.02);}
        100%{transform: scale(1);}
    }
	ul > li.hide {
		display: none;
	}
	ul > li.opacity {
		opacity: 0.5;
		cursor: not-allowed;
	}

    .tooltip {
        visibility: hidden;
        display: flex;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        padding: 5px;
        position: absolute;
        background-color: var(--inGame-header-color);
        width: auto;
        min-width: 100px;
        height: 30px;
        font-size: 14px;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        bottom: -35px;
        left: 50%;
        z-index: 999;
        margin-top: -30px;
        transform: translateX(-50%);
    }

    .tooltip.bottom {
        top: -35px;
        left: -22px;
    }

    .myInfoAnother{
        left: -30px;
        top: 50px;
        background-color: var(--modal-header-background-color);
    }
</style>