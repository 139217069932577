<template>
    <div
        class="modalInner"
        v-bind:class="{ 'mobile': isMobile() }">
        <h3>{{ $t('symbol') }}</h3>
        <div class="symbolListBox">
            <div v-on:click.stop="showDropDown = !showDropDown">
                <div>
                    <img
                        v-bind:src="selectedSymbol['icon']"
                        class="iconImg"
                        width="32"
                        height="32"
                        alt="vgtIcon">
                    <span>{{ selectedSymbol['symbol'] }}</span>
                </div>
                <div class="rArrow">
                    <img src="@/assets/icons/arrow_top.png">
                </div>
            </div>
            <!-- class="on, off" 추가(임시주석처리) -->
            <ul
                class="depth_2"
                v-if="showDropDown">
                <li
                    v-for="(sy, idx) in symbols"
                    v-bind:key="idx"
                    v-on:click.stop="clickSymbol">
                    <img
                        v-bind:src="sy['icon']"
                        width="32"
                        height="32">{{ sy['symbol']}}
                </li>
            </ul>
        </div>
        <h3 class="wide">
            {{ $t('My VSW Adress')}}
        </h3>
        <div class="mvaWrap">
            <div class="qrWrap">
                <QRCodeVue3
                    value="0x1626a73c48d3404Cb827F2ff86b1d29559323f73"
                    v-bind:width="160"
                    v-bind:height="160"
                    v-bind:dotsOptions="{
                        type: 'square',
                        color: '#000',
                    }"
                    v-bind:cornersSquareOptions="{
                        type: 'square',
                        color: '#000'
                    }"
                />
            </div>
            <textarea
                ref="textarea"
                v-model="userWalletAddress"
                readonly></textarea>
        </div>
        <div class="btnWrap">
            <button
                class="copyBtn"
                v-on:click="CopyWalletAddress">
                {{ $t('copyAddress') }}
            </button>
        </div>
    </div>
</template>
<script setup>
    import { isMobile } from "@/js/utils";
    import { sabongWalletInfo } from "@/js/inGame/sabongWallet.js"
    import { ref, reactive, onMounted, computed } from "vue";
    import QRCodeVue3 from "qrcode-vue3";
    import { err } from "@/js/reactive";
    const showDropDown = ref(false);
    const symbols = reactive({
        ETH: {
            icon: require('@/assets/icons/eth.png'),
            symbol: 'ETH'
        },
        BNB: {
            icon: require('@/assets/icons/bnb.png'),
            symbol: 'BNB'
        }
    });
    const selectedSymbol = ref({
        icon: require('@/assets/icons/eth.png'),
        symbol: 'ETH'
    });
    const clickSymbol = (e) => {
        selectedSymbol.value = symbols[e.target.innerText.replaceAll(' ', '')];
        showDropDown.value = false;
    }
    let userWalletAddress = ref("0x6fffB577dE7E65EB7803b098CB14a32DeFA5d5E1");
    let textarea = ref(textarea);

    const isCopyWalletAddress = async () => {
        textarea.value.select();
        document.execCommand("copy");
    };
    const CopyWalletAddress = () => {
        if (!navigator.clipboard) {
            isCopyWalletAddress()
            return
        }
        navigator.clipboard.writeText(userWalletAddress.value)
            .then(() => err['msg'] = 'Copy success')
            .catch(() => isCopyWalletAddress())
    };

</script>
<style scoped>
	.rArrow {
		transform: rotate(180deg);
	}
    *{
        font-family: 'Folks', sans-serif;
        letter-spacing: .3px;
    }
    .modalInner{
        padding: 0 5px;
        font-size:20px;
        height: 100%;
    }
    .modalInner h3{
        margin: 14px 0 8px;
        font-size:22px;
    }
    .modalInner h3.wide{
        margin-top: 22px;
    }
    .modalInner>div{
        box-sizing: border-box;
        background: #1b1b1b;
        border-radius: 6px;
    }
    .modalInner button{
        width: 294px;
        height:48px;
        line-height: 48px;
        background-image: linear-gradient(73deg, #244dbc 27%, #3ca7e5 83%);
        font-size:20px;
        border-radius: 10px;
    }
    .symbolListBox{
        position: relative;
        display: flex;
        width: 100%;
        height:44px;
        padding: 0 12px;
    }
    .symbolListBox > div{
        display: flex;
		width: 100%;
        justify-content: space-between;
        align-items: center;
        background: #1b1b1b;
    }
    .symbolListBox > div div{
        display: flex;
        align-items: center;
        font-size: 22px;
    }
    .symbolListBox > div .iconImg{
        margin-right: 10px;
    }
    .symbolListBox > div .rArrow{
        line-height: 20px;
        height:20px;
    }
    .symbolListBox .depth_2{
        display:flex ;
        flex-direction: column;
        gap:5px;
        width: 100%;
        height: auto;
        min-height: 100px;
        max-height: 350px;
        overflow-y: auto;
        border: 1px solid #bbbbbb;
        box-sizing: border-box;
        padding: 5px;
        position: absolute;
        top:45px;
        left: 0;
        z-index:10;
        background: #1b1b1b;
    }
    .symbolListBox .depth_2 li{
        line-height: 39px;
        text-align: left;
        cursor: pointer;
        border: 2px solid #484848;
        box-sizing: border-box;
    }
    .symbolListBox .depth_2 li img{
        vertical-align: middle;
        margin: 5px 10px;
    }
    .mvaWrap{
        display: flex;
        gap:20px;
        align-items: center;
        flex-direction: column;
        padding: 28px 0 19px;
    }
    .qrWrap span{
        display: block;
        background: #fff;
        width: 160px;
        height: 160px;
    }
    .qrWrap img{
        width: 160px;
        height: 190px;
        padding-bottom: 28px;
    }
    .mvaWrap textarea{
        width: 100%;
        padding: 0 10%;
        overflow: hidden;
        font-size: 18px;
        line-height: 1.5;
        background: none;
        resize: none;
        border:none;
    }
    .mvaWrap textarea:focus{
        outline: none;
    }
    .btnWrap{
        background: none !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 34px 0;
    }
    *{
        color:#fff;
    }

    /* mobile */

    .modalInner.mobile{
        padding: 12px 10px;
        font-size: 16px;
    }
    .modalInner.mobile h3{
        margin: 0 0 8px 0;
        font-size: 20px;
    }
    .modalInner.mobile .symbolListBox{
        margin-bottom: 27px;
        height: 40px;
        padding: 0 10px;
        font-size: 18px;
    }
    .modalInner.mobile .iconImg{
        width: 28px;
        margin-right: 13px;
    }
    .modalInner.mobile .rArrow{
        width: 22px;
    }
    .modalInner.mobile .mvaWrap{
        padding: 46px 0 50px;
        gap: 24px;
    }
    .modalInner.mobile .mvaWrap textarea{
        width: 90%;
    }
    .modalInner.mobile .mvaWrap p{
        font-size: 14px;
    }
    .modalInner.mobile .btnWrap{
        margin: 30px 0;
    }
    .modalInner.mobile button{
        width: 294px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
    }
    .modalInner.mobile .depth_2{
        height: 454px;
        top: 40px;
        border: 2px solid #adadad;
        padding-top: 12px;
        gap: 8px;
    }
    .modalInner.mobile .depth_2 img{
        width: 32px;
    }
</style>
