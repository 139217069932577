<template>
    <div
        v-show="isLoading"
        class="wrap">
        <Loading/>
    </div>
    <div
        v-show="!isLoading"
        class="wrap">
        <div
            class="inGameWrap"
            ref="root">
            <Musics/>
            <div class="icons box">
                <InGameIcons v-on:offCam="offCam"/>
            </div>
            <div class="roomInfoBox">
                <InGameRoomInfo
                    v-bind:tableNo="tableNo"
                    v-bind:gameId="gameId"
                    v-bind:gameNo="gameNo"
                    v-bind:betMinMax="betMinMax"/>
            </div>
            <!--			cam on-->
            <div
                v-if="!iscamOff"
                style="width:100%; height:100%; position: absolute; top:0">
                <CameraWebRTC v-bind:streamName="tableNo"/>
            </div>
            <!--  cam off할떄-->
            <div
                class="videoOff"
                v-show="iscamOff">
                <div class="bgImgBox">
                    <img src="../assets/bg/test-bg.png"/>
                </div>
                <div class="chrctImgBox">
                    <img v-bind:src="equipDealer"/>
                </div>
                <div class="tableImgBox">
                    <img src="../assets/bg/test-table.png"/>
                </div>
            </div>
            <div
                v-bind:class="{ 'open': realTimeBet.length > 0}"
                class="betListBox">
                <div
                    v-for="(data,idx) in realTimeBet"
                    v-bind:key="idx"
                    class="realTimeBetList">
                    {{ data['id'] }} &#10072;
                    <span v-number-format="data['bet']">
                        {{ data['bet'] }}
                    </span>
                </div>
            </div>
            <div
                class="resultText"
                v-bind:class="resultClass"
                v-if="isShowResult">
                {{ result }}
            </div>
            <div
                class="resultText2"
                v-if="isShowResult">
                <span class="tx">
                    {{ $t('result') }} :
                </span>{{ realWins }}
            </div>
            <div class="batting">
                <div class="errMsg">
                    {{ errMsg }}
                </div>
                <div class="bettingBoardWrap">
                    <!--  -->
                    <div
                        class="progressBox"
                        v-bind:style="{ 'margin-bottom': isBetProgress ? '49px' : '-47px' }">
                        <div class="progressText">
                            {{ progressText }}
                        </div>
                        <div
                            class="progress"
                            v-bind:style="{ 'width': `${((maxSec - curSec)/maxSec) * 100}%` }"
                            v-bind:class="progressStatus">
                        </div>
                    </div>

                    <div class="bettingBoard">
                        <div class="leftBoard">
                            <div class="header">
                                <div
                                    class="playerHeader"
                                    v-on:click.stop="clickPredictBtn('p')"/>
                                <div
                                    class="bankerHeader"
                                    v-on:click.stop="clickPredictBtn('b')"/>
                            </div>
                            <div class="ScoreBoardBg"></div>
                            <ScoreBoard1
                                v-bind:scoreBoard="scoreBoard6"
                                v-bind:options="{size: 'm'}"/>
                            <div class="userInfo">
                                <div class="balance box">
                                    <div class="boxTopTxt">
                                        {{ $t('balance') }}
                                    </div>
                                    <div
                                        class="boxBtmTxt"
                                        v-numberFormat="totalBalance">
                                    </div>
                                </div>
                                <div class="currency box boxTopTxt">
                                    {{ loginUser['currency'] }}
                                </div>
                                <div class="bet box">
                                    <div class="boxTopTxt">
                                        {{ $t('bet') }}
                                    </div>
                                    <div
                                        class="boxBtmTxt"
                                        v-numberFormat="totalBetAmount">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  -->
                        <div
                            class="centerBoard"
                            v-bind:style="{'margin-top': isBetProgress ? '-42px' : '56px'}"
                            v-bind:class="{ 'betDisabled': !isBetProgress }">
                            <div class="boardBox box">
                                <div
                                    v-bind:class="board['key']"
                                    class="board box menu"
                                    v-for="(board, idx) in centerBoard"
                                    v-bind:ref="setBetBoard"
                                    v-on:click="clickBetBoard(board , $event, idx)"
                                    v-bind:key="idx">
                                    <div
                                        class="distBox"
                                        v-bind:class="{
                                            'boxLineP': board['key'] === 'p' || board['key'] === 'pp',
                                            'boxLineB': board['key'] === 'b' || board['key'] === 'bp',
                                            'boxLineT': board['key'] === 'tie'
                                        }"
                                        v-if="board['key'] === 'p'
                                            || board['key'] === 'b'
                                            || board['key'] === 'bp'
                                            || board['key'] === 'pp'
                                            || board['key'] === 'tie'">
                                        <div
                                            class="distPrcnt"
                                            v-bind:style="{ 'height': `${getDistData(board['key'])}` }"
                                            v-bind:class="{
                                                'boxClrP': board['key'] === 'p' || board['key'] === 'pp',
                                                'boxClrB': board['key'] === 'b' || board['key'] === 'bp',
                                                'boxClrT': board['key'] === 'tie'
                                            }"></div>
                                        <p
                                            v-bind:class="board['key']"
                                            class="prcntNum">
                                            {{ getDistData(board['key']) }}
                                        </p>
                                        <div
                                            v-numberFormat="getDistDataValue(board['key'])"
                                            v-bind:class="board['key']"
                                            class="amount">
                                        </div>
                                    </div>
                                    <div
                                        class="betTotalBox"
                                        v-bind:class="board['key']">
                                        {{ board['key'] === 'p' ? playerCardSum : bankerCardSum }}
                                    </div>
                                    <div
                                        class="resultBox"
                                        v-bind:class="board['key']"
                                        v-if="board['key'] == 'p' || board['key'] == 'b'">
                                        <div
                                            class="result"
                                            v-if="board['result'].length > 0"
                                            v-bind:class="getCardResult(board, 0)['color']">
                                            <span class="mark">
                                                <img v-bind:src="getCardImage(getCardResult(board, 0)['mark'])"/>
                                            </span>
                                            <div class="number">
                                                {{ getCardResult(board, 0)['tx'] }}
                                            </div>
                                        </div>
                                        <div
                                            class="result"
                                            v-if="board['result'].length > 1"
                                            v-bind:class="getCardResult(board, 1)['color']">
                                            <span class="mark">
                                                <img v-bind:src="getCardImage(getCardResult(board, 1)['mark'])"/>
                                            </span>
                                            <div class="number">
                                                {{ getCardResult(board, 1)['tx'] }}
                                            </div>
                                        </div>
                                        <div
                                            class="result"
                                            v-if="board['result'].length > 2"
                                            v-bind:class="getCardResult(board, 2)['color']">
                                            <span class="mark">
                                                <img v-bind:src="getCardImage(getCardResult(board, 2)['mark'])"/>
                                            </span>
                                            <div class="number">
                                                {{ getCardResult(board, 2)['tx'] }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="title mainTxt">
                                        {{ board['title'] }}
                                    </div>
                                    <div class="win winTxt">
                                        X {{ board['win'] }}
                                    </div>
                                    <div
                                        class="betDetail"
                                        v-bind:class="[board['key'], {
                                            'pReward': board['key'] == 'p' || board['key'] == 'pp',
                                            'bReward': board['key'] == 'b' || board['key'] == 'bp',
                                            'tieReward': board['key'] == 'tie'
                                        }]"
                                        v-numberFormat="betInfo['betDetail'][board['key']]['bet']">
                                    </div>
                                    <div class="betLimit">
                                        <span v-moneySymbol="betMinMax[board['key']]['min']"></span>-<span v-moneySymbol="betMinMax[board['key']]['max']"></span>
                                    </div>
                                    <!--
                                    <div class="betPoolBox">
                                        <div class="chip"
                                             v-for="(chip, idx) in board['betPool']"
                                             :key="idx"
                                             :style="{ 'background-image': `url(${chip['src']})`, 'left': `${idx*2 + 1}px` }">
                                        </div>
                                    </div>
                                    -->
                                </div>
                            </div>
                            <div
                                ref="chipContent"
                                v-bind:style="{ 'visibility': isBetProgress ? 'visible' : 'hidden'}"
                                class="chips frontChips">
                                <div
                                    v-for="(chip, idx) in chips['Basic']"
                                    v-bind:key="idx"
                                    v-bind:class="[
                                        chip['key'],
                                        { 'selected': selectChip['key'] === chip['key'] },{
                                            'bigFontSize': chip['value'] <= 5
                                        }]"
                                    v-bind:style="{ 'background-image': `url(${chip['src']})` }"
                                    class="chip menu"
                                    v-on:click="clickChip(chip, idx , $event)">
                                    {{ chip['tx'] }}
                                </div>
                            </div>
                            <div
                                ref="chipContent"
                                v-bind:style="{ 'visibility': isBetProgress ? 'visible' : 'hidden'}"
                                class="chips backChips">
                                <div
                                    class="chip menu"
                                    v-on:click="clickChip(chip, idx , $event)"
                                    v-bind:ref="setItemRef"
                                    v-bind:style="{ 'background-image': `url(${chip['src']})` }"
                                    v-bind:class="[
                                        chip['key'],
                                        { 'selected': selectChip['key'] === chip['key'] },{
                                            'bigFontSize': chip['value'] <= 5
                                        }]"
                                    v-for="(chip, idx) in chips"
                                    v-bind:key="idx">
                                    {{ chip['tx'] }}
                                </div>
                            </div>
                        </div>

                        <div class="rightBoard">
                            <div class="header box rightPointBox">
                                <div
                                    class="titles"
                                    v-for="(board, idx) in rightBoard['header']"
                                    v-bind:key="idx">
                                    <div
                                        v-bind:class="board['key']"
                                        class="title">
                                        <img v-bind:src="board['src']"/>
                                    </div>
                                    <div class="mark">
                                        {{ board['count'] }}
                                    </div>
                                </div>
                            </div>
                            <div class="ScoreBoardBg"></div>
                            <ScoreBoard
                                v-bind:scoreBoard="scoreBoard"
                                v-bind:scoreBoard1G="scoreBoard1G"
                                v-bind:scoreBoard2G="scoreBoard2G"
                                v-bind:scoreBoard3G="scoreBoard3G"
                                v-bind:options="{size: 'm'}"
                                v-bind:isInGame="true"/>
                            <div class="buttons">
                                <div
                                    class="btnWrap menu"
                                    v-on:mouseover="hoverRightBtn(btn, 'visible')"
                                    v-on:mouseleave="hoverRightBtn(btn, 'hidden')"
                                    v-bind:class="btn['key']"
                                    v-on:click.stop="btn['click']"
                                    v-for="(btn, idx) in rightBoard['buttons']"
                                    v-bind:key="idx">
                                    <img
                                        class="btn"
                                        v-bind:src="btn['src']"/>
                                    <div
                                        class="tooltip bottom"
                                        v-bind:ref="btn['key']">
                                        {{ $t(btn['tooltip']) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 211013 이은정 html 위치 변경 (152 > 201) -->
                <div
                    class="timeSetBox"
                    v-bind:style="{ 'visibility': isBetProgress ? 'visible' : 'hidden'}">
                    <div class="loading">
                        <img src="../assets/ingame_loading.png">
                    </div>
                    <div class="text">
                        {{ maxSec - curSec }}
                    </div>
                </div>
            </div>
        </div>
        <transition name="bounce">
            <UserBetList
                v-if="isShowBetUser"
                v-bind:roomBetList="roomBetList"/>
        </transition>
    </div>
</template>
<script>
    import { defineComponent } from "vue";
    import { loginUser, topics, chatArr, distArr, iscamOff } from "@/js/reactive";
    import {
        ROOM_STATUS,
        CARD_INFO_ARRAY,
        BACCARAT_SCORE_IDX,
        BACCARAT_RESULT_IDX,
        CARD_INFO_ARRAY_VALUE,
        DEALER_INFO
    } from "../js/const";
    /*    import CameraSetup from "@/components/streaming/CameraSetup"*/
    import { setNumberFormat, arraySplice, setCookie, getCookie, deleteCookie, isMobile, decryptData } from "@/js/utils";
    import { getBalance, getPing } from "@/js/request";
    import "vue3-circle-progress/dist/circle-progress.css";
    import Loading from "@/components/common/Loading";
    import ScoreBoard from "@/components/common/ScoreBoard";
    import ScoreBoard1 from "@/components/common/ScoreBoard1";
    import InGameRoomInfo from "@/components/common/InGameRoomInfo";
    import {
        getBigRoad,
        getBeadRoad,
        getBigEyeRoad,
        getSmallRoad,
        getCockRoad
    } from "@/js/scoreBoard/generator";
    import { getCardResult } from "@/js/common";
    import { mapGetters } from "vuex";
    import { setUserBetInfo, sendTopic } from "@/plugins/mqtt";
    import Musics from "@/components/common/Musics";
    import CameraWebRTC from "@/components/streaming/CameraWebRTC";
    import { chips } from "@/js/reactive";
    /*    import UserBetList from "@/components/popup/UserBetList";*/
    import InGameIcons from "@/components/common/InGameIcons";

    import CameraWebRTCDirect from "@/components/streaming/CameraWebRTCDirect";

    export default defineComponent({
        name: 'InGame',
        components: {
            /*   CameraSetup,*/
            CameraWebRTC,
            InGameRoomInfo,
            Loading,
            ScoreBoard,
            /*            UserBetList,*/
            ScoreBoard1,
            Musics,
            InGameIcons,
            /*Chat*/
        },
        data() {
            return {
                iscamOff: iscamOff,
                betTextRefs : undefined,
                selectChipPosition : {
                    x : 0,
                    y : 0
                },
                betAnim : false,
                chipContent : undefined,
                betBoardRefs : [],
                selectChipRef : undefined,
                itemRefs : [],
                room: {},
                scoreBoard: {},
                scoreBoard6: {},
                scoreBoard1G: {},
                scoreBoard2G: {},
                scoreBoard3G: {},
                chips: chips,
                msg: '',
                isCamOff: false,
                isShowModal: false,
                modalTitle: '',
                modalKey: '',
                isLoading: true,
                progressStatus: 'normal',
                CARD_INFO_ARRAY: CARD_INFO_ARRAY,
                topics: topics,
                starVal:0,
                endVal: 2017,
                // 결과창 보여주기 여부
                isShowResult: false,
                // 실제 승리금
                realWins: 0,
                // 나의 배팅정보
                betInfo: {
                    totalBet: 0,
                    totalWin: 0,
                    betDetail: {
                        'pp': {
                            bet: 0,
                            expectWin: 0
                        },
                        'p': {
                            bet: 0,
                            expectWin: 0
                        },
                        'tie': {
                            bet: 0,
                            expectWin: 0
                        },
                        'b': {
                            bet: 0,
                            expectWin: 0
                        },
                        'bp': {
                            bet: 0,
                            expectWin: 0
                        }
                    }
                },
                // 현재 선택한칩
                selectChip: {},

                // 아래 왼쪽보드
                leftBoard: {
                    header: [
                        {
                            key: 'player',
                            title: this.$t('player'),
                            mark: '',
                        },
                        {
                            key: 'banker',
                            title: this.$t('banker'),
                            mark: '',
                        },
                    ],
                    scoreBoard: {}
                },
                userInfo: {},

                // 아래 오른쪽보드
                rightBoard: {
                    header: [
                        {
                            key: 'pp',
                            title: 'PP',
                            count: 0,
                            src: require('../assets/icons/betMark/icon_pp.png'),
                        },
                        {
                            key: 'p',
                            title: 'P',
                            count: 0,
                            src: require('../assets/icons/betMark/icon_p.png'),
                        },
                        {
                            key: 'tie',
                            title: 'T',
                            count: 0,
                            src: require('../assets/icons/betMark/icon_t.png'),
                        },
                        {
                            key: 'b',
                            title: 'B',
                            count: 0,
                            src: require('../assets/icons/betMark/icon_b.png'),
                        },
                        {
                            key: 'bp',
                            title: 'BP',
                            count: 0,
                            src: require('../assets/icons/betMark/icon_pb.png'),
                        }
                    ],
                    scoreBoard: {},
                    buttons: [
                        {
                            key: 'cancel',
                            tooltip: 'betCancel',
                            src: require('../assets/icons/ingame_bet_btn/btn_cancel.png'),
                            disableSrc: require('../assets/icons/ingame_bet_btn/btn_cancel_disable.png'),
                            click: () => {
                                this.betCancel();
                            }
                        },
                        {
                            key: 'undo',
                            tooltip: 'betUndo',
                            src: require('../assets/icons/ingame_bet_btn/btn_undo.png'),
                            disableSrc: require('../assets/icons/ingame_bet_btn/btn_undo_disable.png'),
                            click: () => {
                                this.betUndo();
                            }
                        },
                        {
                            key: 'rebet',
                            tooltip: 'betRedo',
                            src: require('../assets/icons/ingame_bet_btn/btn_rebet.png'),
                            disableSrc: require('../assets/icons/ingame_bet_btn/btn_rebet_disable.png'),
                            click: () => {
                                this.betRestore();
                            }
                        },
                    ]
                },

                // 중간보드
                centerBoard: [
                    {
                        'key': 'pp',
                        'title': this.$t('pp'),
                        'win': 11,
                        'betPool': [],
                        'result': [],
                        'dist': 0
                    },
                    {
                        'key': 'p',
                        'title': this.$t('p'),
                        'win': 1,
                        'betPool': [],
                        'result': [],
                        'dist': 0
                    },
                    {
                        'key': 'tie',
                        'title': this.$t('tie'),
                        'win': 8,
                        'betPool': [],
                        'result': [],
                        'dist': 0
                    },
                    {
                        'key': 'b',
                        'title': this.$t('b'),
                        'win': 0.95,
                        'betPool': [],
                        'result': [],
                        'dist': 0
                    },
                    {
                        'key': 'bp',
                        'title': this.$t('bp'),
                        'win': 11,
                        'betPool': [],
                        'result': [],
                        'dist': 0
                    },
                ],

                ROOM_STATUS: ROOM_STATUS,
                loginUser: loginUser,

                tableNo: undefined,
                tableIdx: undefined,
                roomNo: undefined,

                gameId: 0,
                gameNo: undefined,
                roomStatus: undefined,
                result: '',
                resultClass: '',
                progressText: '',
                isBetProgress: false,
                curSec: 0,
                maxSec: 27,

                timer: null,

                curSelectedBoard: [],

                restoreCenterBoard: [],
                restoreBetInfo: {},
                restoreSelectedBoard: [],

                playerCardSum: 0,
                bankerCardSum: 0,

                playerRst: [],
                bankerRst: [],

                scoreCounts: {
                    'p': 0,
                    'pp': 0,
                    'b': 0,
                    'bp': 0,
                    'tie': 0
                },

                chatArr: chatArr,
                distArr: distArr,
                realTimeBet:[],
                chat: [],
                isShowChatBox: true,
                chatTimer: undefined,

                isShowUserInfo: false,
                isEffectOn: false,

                betMinMax: {
                    'p': {
                        min: 0,
                        max: 0
                    },
                    'pp': {
                        min: 0,
                        max: 0
                    },
                    'tie': {
                        min: 0,
                        max: 0
                    },
                    'b': {
                        min: 0,
                        max: 0
                    },
                    'bp': {
                        min: 0,
                        max: 0
                    }
                },
                betDist: {
                    'p': {
                        percent: 0,
                        amount: 0
                    },
                    'b': {
                        percent: 0,
                        amount: 0
                    },
                    'tie': {
                        percent: 0,
                        amount: 0
                    },
                    'pp': {
                        percent: 0,
                        amount: 0
                    },
                    'bp': {
                        percent: 0,
                        amount: 0
                    }
                },
                predicting: false,
                predictCnt: 0,
                mqttTimer: {
                    'enterRoom': undefined
                },
                errMsg: '',
                equipDealer: undefined,
                roomBetList: [],
                isShowBetUser: false
            }
        },
        watch: {
            // 모바일에서 배팅하는경우, betInfo 변수 동기화해줌
            getBetDetail: {
                deep: true,
                handler(val){
                    this.betInfo['betDetail'] = val;
                }
            },
            getTotalBet: {
                handler(val){
                    this.betInfo['totalBet'] = val
                }
            },
            isLoading: {
                handler(val){
                    if(!val){
                        window.dispatchEvent(new Event('resize'));
                    }
                }
            },
            errMsg: {
                handler(val){
                    if(val != ''){
                        setTimeout(() => {
                            this.errMsg = '';
                            this.$store.commit('setBetErrMsg', '');
                        }, 3000);
                    }
                }
            },
            distArr: {
                deep: true,
                handler(arr){
                    this.setDistData(arr);
                    this.realTimeBet = [];
                    arr.forEach(data => {
                        let info = data.split('/')
                        let bet = JSON.parse(info[2])
                        this.realTimeBet.push({
                            'id':info[0],
                            'bet':bet['totalBet']
                        })
                    });
                    this.realTimeBet = this.realTimeBet.reverse();
                }
            },
            chatArr: {
                immediate: true,
                deep: true,
                handler(arr){
                    if(!this.isShowChatBox){
                        this.isShowChatBox = true;
                    }
                    this.chat = [];
                    arr.forEach(data => {
                        let info = data.split('/');
                        this.chat.push({
                            'msg': info[0],
                            'time': info[1],
                            'id': info[2],
                            'room': `(Room ${info[3]})`,
                            'key': info
                        });
                    });
                    this.chat = this.chat.reverse();
                    this.chat = this.chat.splice(0, 10);
                    if(!this.chatTimer){
                        this.chatTimer = setTimeout(() => {
                            this.isShowChatBox = false;
                            this.chatTimer = undefined;
                            clearTimeout(this.chatTimer);
                        }, 10000);
                    }
                }
            },
            gameNo: {
                handler(gameNo) {
                    if(!gameNo){
                        this.$router.push('/');
                    }
                }
            },
            isBetProgress: {
                handler(progress){
                    if(progress && this.roomStatus === 1){
                        this.isShowResult = false;
                        this.betCancel();
                        setCookie('gameNo', this.gameNo);
                        this.timer = setInterval(() => {
                            if(this.curSec === this.maxSec) {
                                this.curSec = 0;
                                this.isBetProgress = false;
                                this.isEffectOn = false;
                                clearInterval(this.timer);
                                return;
                            }
                            this.roomBetList = [];
                            this.curSec++;
                        }, 1000);
                    }
                }
            },
            curSec: {
                immediate: true,
                handler(val){
                    if(!this.isEffectOn && val != 0 && this.maxSec - val <= 10){
                        let sound = this.$refs['effect'];
                        if(this.$refs['effect']){
                            sound.pause();
                            sound.volumne = this.getEffectOn;
                            sound.currentTime = 0;
                            sound.play();
                            this.isEffectOn = true;
                        }
                    }
                }
            },
            topics: {
                immediate: true,
                deep: true,
                handler(topics) {
                    this.getIngameTopics(topics);
                },
            },
            roomStatus: {
                immediate: true,
                handler(status, preStatus){
                    if(status === 0){
                        this.progressText = this.$t('ready');
                    }
                    if(status >= 3){
                        this.progressText = this.$t('betEnd');
                        this.isBetProgress = false;
                    }
                    if(status >= 1 && status < 3){
                        if(typeof preStatus !== 'undefined' && status !== 2){
                            this.isBetProgress = true;
                        }
                        // 배팅 중간에 입장한 사람에게는 게임시작못하도록.
                        this.progressText = typeof preStatus === 'undefined' ? this.$t('wait') : status == 2 ? this.$t('betEnd') : this.$t('betReady');
                        this.progressStatus = typeof preStatus === 'undefined' ? 'wait' : status == 2 ? 'wait' : 'normal';
                    }
                    if(status === 3){
                        this.progressStatus = 'normal';
                        if(typeof preStatus !== 'undefined'){
                            if(this.getIsBetting){
                                return;
                            }
                            this.$store.commit('setIsBetting', true);
                            this.bet();
                        }
                    }
                }
            },
        },
        created(){
            getBalance();
            getPing();
        },
        beforeUnmount() {
            clearInterval(this.mqttTimer['enterRoom']);
        },
        beforeMount() {
            window.addEventListener("resize", this.windowSetScale);
        },
        mounted() {
            this.settingRoomInfo();
            this.settingScoreBoard();
            if(!this.getIsWebRTCOn){
                this.$store.commit('setBgmOn', 1);
            }
            //방입장토픽 호출
            if(this.roomNo){
                this.mqttTimer['enterRoom'] = setInterval(() => {
                    getBalance(true);
                    //TODO 추후 드래곤타이거 키값으로 분리
                    sendTopic(`${this.roomNo}/live_bacc/EnterRoomUser`, {
                        "SerializationFailed": false,
                        "ArrivedEmpty": false,
                        "ArrivedTopic": "",
                        "OriginalMessage": "",
                        "timestamp": new Date().getTime(),
                        'UserType': 0,
                        'agent_type': 0,
                        'cash_decrease': 0,
                        'cash_increase': 0,
                        'country': loginUser['currency'],
                        'dealer_id': this.room['deaer_id'],
                        'equip_card_idx': 0,
                        'equip_dealer_color_type': 0,
                        'equip_dealer_idx': 0,
                        'game_id': this.gameId,
                        'grade': '1',
                        'player_level': '1',
                        'player_type': '0',
                        'teenPatti_dealer': false,
                        'teenPatti_slot': 0,
                        'total_amount': loginUser['balance'],
                        'total_like': 0,
                        'total_tip': 0,
                        'user_name': null,
                        'user_id': loginUser['id'],
                        'user_uuid': loginUser['uuid'],
                        'room_no': this.roomNo
                    });
                }, 3000);
            }
            // 캠OFF시, 장착딜러 보여줌
            this.getEquipDealer();
        },
        unmounted() {
            this.leaveRoomTopic();
            window.removeEventListener("resize", this.windowSetScale);
        },
        computed: {
			...mapGetters([
				'getBetDetail',
				'getSelectChip',
				'getTotalBet',
				'getBgmOn',
				'getEffectOn',
				'getIsWebRTCOn',
				'getIsBetting'
			]),
            totalBetAmount() {
                return this.betInfo['totalBet']
            },
            totalBalance() {
                return this.loginUser['balance'] - this.betInfo['totalBet']
            },
        },
        beforeUpdate() {
            this.itemRefs = []
        },
        methods: {
            getCardResult,
            getCookie,
            settingScoreBoard() {  
                
                console.log('methods settingScoreBoard');

                this.scoreBoard = getBigRoad(this.roomNo);
                this.scoreBoard6 = getBeadRoad(this.roomNo);
                this.scoreBoard1G = getBigEyeRoad(this.roomNo);
                this.scoreBoard2G = getSmallRoad(this.roomNo);
                this.scoreBoard3G = getCockRoad(this.roomNo);
            },
            setBetBoard(el) {
                if (el) {
                    this.betBoardRefs.push(el);
                }
            },
            setItemRef(el) {
                if (el) {
                    this.itemRefs.push(el);
                }
            },
            // 룸정보세팅
            settingRoomInfo(){
                this.room = JSON.parse(this.$route['params']['room']);
                this.maxSec = this.room['time_limit'];
                this.roomNo = this.$route['params']['roomNo'];
                this.tableIdx = this.$route['params']['index'];
                this.tableNo = this.$route['params']['tableNo'];
            },
            getCardImage(mark){
                return require(`@/assets/mark/${mark}`);
            },
            showUserInfo(val){
                this.isShowUserInfo = val;
            },
            async getEquipDealer(){
                const res = await this.apis.getEquipDealer();
                let equipDealer = res?.decrypt_data?.mounting.length > 0 ?
                    {
                        'type': res?.decrypt_data?.mounting[0]['color_type'],
                        'name': res?.decrypt_data?.mounting[0]['name']
                    } :
                    {
                        'type': 0,
                        'name': 'lisa'
                    };
                this.equipDealer = DEALER_INFO['info'].find(dealer =>
                    dealer['order'] == equipDealer['type'] && dealer['group'] == equipDealer['name'].toLowerCase()
                )?.[isMobile()? 'previewM' : 'previewW'];
            },
            clickPredictBtn(key){
                this.predictPoint(key);
            },
            predictPoint(key){
                if(this.predicting){
                    return;
                }
                this.predicting = true;
                // 원매, 1군, 2군, 3군 마지막점
                let isSame = false;
                let lastScore2 = Object.entries(this.scoreBoard6)[Object.entries(this.scoreBoard6).length - 1];
                let lastKey = Number(lastScore2[0]) + lastScore2[1].split('@').length;
                let score = lastScore2[1].split('@')[lastScore2[1].split('@').length - 1];
                if(key == 'p'){
                    let playerWin = {
                        p: true,
                        pp: false,
                        tie: false,
                        b: false,
                        bp: false
                    };
                    this.scoreBoard.push(playerWin);
                    if(JSON.parse(score)['p']){
                        isSame = true;
                        this.scoreBoard6[lastScore2[0]] = `${this.scoreBoard6[lastScore2[0]]}@${score}`
                        this.scoreBoard1G[lastScore2[0]] = `${this.scoreBoard1G[lastScore2[0]]}@${score}`
                        this.scoreBoard2G[lastScore2[0]] = `${this.scoreBoard2G[lastScore2[0]]}@${score}`
                        this.scoreBoard3G[lastScore2[0]] = `${this.scoreBoard3G[lastScore2[0]]}@${score}`
                    } else {
                        this.scoreBoard6[lastKey] = JSON.stringify(playerWin);
                        this.scoreBoard1G[lastKey] = JSON.stringify(playerWin);
                        this.scoreBoard2G[lastKey] = JSON.stringify(playerWin);
                        this.scoreBoard3G[lastKey] = JSON.stringify(playerWin);
                    }
                } else {
                    let bankerWin = {
                        p: false,
                        pp: false,
                        tie: false,
                        b: true,
                        bp: false
                    };
                    this.scoreBoard.push(bankerWin);
                    if(JSON.parse(score)['p']){
                        this.scoreBoard6[lastKey] = JSON.stringify(bankerWin);
                        this.scoreBoard1G[lastKey] = JSON.stringify(bankerWin);
                        this.scoreBoard2G[lastKey] = JSON.stringify(bankerWin);
                        this.scoreBoard3G[lastKey] = JSON.stringify(bankerWin);
                    } else {
                        isSame = true;
                        this.scoreBoard6[lastScore2[0]] = `${this.scoreBoard6[lastScore2[0]]}@${score}`
                        this.scoreBoard1G[lastScore2[0]] = `${this.scoreBoard1G[lastScore2[0]]}@${score}`
                        this.scoreBoard2G[lastScore2[0]] = `${this.scoreBoard2G[lastScore2[0]]}@${score}`
                        this.scoreBoard3G[lastScore2[0]] = `${this.scoreBoard3G[lastScore2[0]]}@${score}`
                    }
                }

                setTimeout(() => {
                    this.scoreBoard.pop();
                    if(isSame){
                        this.scoreBoard6[lastScore2[0]] = `${this.scoreBoard6[lastScore2[0]].split('@').pop()}`
                        this.scoreBoard1G[lastScore2[0]] = `${this.scoreBoard1G[lastScore2[0]].split('@').pop()}`
                        this.scoreBoard2G[lastScore2[0]] = `${this.scoreBoard2G[lastScore2[0]].split('@').pop()}`
                        this.scoreBoard3G[lastScore2[0]] = `${this.scoreBoard3G[lastScore2[0]].split('@').pop()}`
                    } else {
                        delete this.scoreBoard6[lastKey];
                        delete this.scoreBoard1G[lastKey];
                        delete this.scoreBoard2G[lastKey];
                        delete this.scoreBoard3G[lastKey];
                    }
                    this.predicting = false
                    /*if(this.predictCnt <= 2){
                        this.predictCnt++;
                        this.clickPredictBtn(key);
                    }*/
                }, 1000);
            },
            hoverRightBtn(btn, value){
                this.$refs[btn['key']].style.visibility = value;
            },
            getDistData(key){
                let data = 0;
                switch(key){
                    case 'p': data = `${this.betDist['p']['percent']}%`;
                              break;
                    case 'pp': data = `${this.betDist['pp']['percent']}%`;
                               break;
                    case 'tie':  data = `${this.betDist['tie']['percent']}%`;
                                 break;
                    case 'b':  data = `${this.betDist['b']['percent']}%`;
                               break;
                    case 'bp': data = `${this.betDist['bp']['percent']}%`;
                               break;
                }
                return data;
            },
            getDistDataValue(key){
                let data = 0;
                switch(key){
                    case 'p': data = this.betDist['p']['amount'];
                              break;
                    case 'pp': data = this.betDist['pp']['amount'];
                               break;
                    case 'tie':  data = this.betDist['tie']['amount'];
                                 break;
                    case 'b':  data = this.betDist['b']['amount'];
                               break;
                    case 'bp': data = this.betDist['bp']['amount'];
                               break;
                }
                return data;
            },
            // 방 나갈때 딜러툴로 나가는 토픽쏴줌.
            leaveRoomTopic() {
                sendTopic(`${this.roomNo}/live_bacc/LeaveRoomUser`,{
                    "SerializationFailed": false,
                    "ArrivedEmpty": false,
                    "ArrivedTopic": "",
                    "OriginalMessage": "",
                    "timestamp": new Date().getTime(),
                    "type": 0,
                    "room_no": this.roomNo,
                    "user_id": loginUser['id'],
                    "user_uuid": loginUser['uuid'],
                    "is_tp_playing_leave": false
                });
            },
            // 픽분포
            setDistData(arr){
                let total = 0;
                let pBet = 0;
                let bBet = 0;
                let bpBet = 0;
                let ppBet = 0;
                let tieBet = 0;

                arr.forEach(data => {
                    let gameNo = getCookie('gameNo');
                    let split = data.split('/');
                    if(gameNo == split[1]){
                        let dist = JSON.parse(split[2]);
                        total += dist['totalBet'];
                        pBet += dist['betDetail']['p']['bet'];
                        bBet += dist['betDetail']['b']['bet'];
                        bpBet += dist['betDetail']['bp']['bet'];
                        ppBet += dist['betDetail']['pp']['bet'];
                        tieBet += dist['betDetail']['tie']['bet'];
                    }
                });

                // 배팅 MIN에 걸려서 데이터가 초기화될경우, total이 0으로 들어오는데, 1로 초기화
                if(total == 0){
                    total = 1;
                }

                this.betDist['p']['amount'] = pBet
                this.betDist['b']['amount'] = bBet
                this.betDist['tie']['amount'] = tieBet
                this.betDist['bp']['amount'] = bpBet
                this.betDist['pp']['amount'] = ppBet

                this.betDist['p']['percent'] = Number((pBet / total) * 100).toFixed(2);
                this.betDist['b']['percent'] = Number((bBet / total) * 100).toFixed(2);
                this.betDist['tie']['percent'] = Number((tieBet / total) * 100).toFixed(2);
                this.betDist['bp']['percent'] = Number((bpBet / total) * 100).toFixed(2);
                this.betDist['pp']['percent'] = Number((ppBet / total) * 100).toFixed(2);
            },
            offCam(isOff){
                this.isCamOff = isOff;
            },
            isMobile() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            windowSetScale(event) {
                let scaleSize = 1;
                let clientAspectRatio = 1920 / 1080;
                let innerWidth = event.target.innerWidth;
                let innerHeight = event.target.innerHeight;

                if(innerHeight * clientAspectRatio <= innerWidth)
                {
                    scaleSize = innerHeight / 1080;
                }
                else
                {
                    scaleSize = innerWidth / 1920;
                }
                if(!this.isMobile()){
                    if(this.$refs.root){
                        this.$refs.root.style.transform = `scale(${scaleSize})`;
                    }
                }
            },
            async bet(){
                let inValidMinMax = false;
                // 배팅MIN 체크
                Object.entries(this.betInfo['betDetail']).forEach((info, idx) => {
                    if(info[1]['bet'] != 0 && info[1]['bet'] < this.betMinMax[info[0]]['min']){
                        this.betInfo['totalBet'] -= this.betInfo['betDetail'][info[0]]['bet'];
                        this.betInfo['betDetail'][info[0]]['bet'] = 0;
                        this.centerBoard[idx]['betPool'] = [];

                        let deletesIdx = [];
                        // 배팅판 클릭했던 히스토리에서 해당 배팅항목 제거(배팅캔슬에서 걸림)
                        this.curSelectedBoard.forEach((board, idx) => {
                            if(board['key'] == info[0]){
                                deletesIdx.push(idx);
                            }
                        });

                        deletesIdx.forEach(idx => {
                            this.curSelectedBoard.splice(idx, 1);
                        })

                        this.$store.commit('setTotalBet', this.betInfo['totalBet']);
                        this.$store.commit('setBetDetail', this.betInfo['betDetail'])
                        inValidMinMax = true;
                    }
                });

                if(inValidMinMax){
                    this.errMsg = `${this.$t('checkMinMax')}`;
                    this.$store.commit('setBetErrMsg', this.errMsg);
                    setUserBetInfo(`${loginUser['id']}/${this.gameNo}/${JSON.stringify(this.betInfo)}`);
                }


                /*if(this.betInfo['totalBet'] == 0 || this.totalBalance <= 0 ){
                    return;
                }
*/
                let params = {
                    before_amount: this.loginUser['balance'],
                    current_amount: this.totalBalance,
                    increase_amount: -this.betInfo['totalBet'],
                    bet_amount_banker: this.betInfo['betDetail']['b']['bet'],
                    bet_amount_banker_pair: this.betInfo['betDetail']['bp']['bet'],
                    bet_amount_player: this.betInfo['betDetail']['p']['bet'],
                    bet_amount_player_pair: this.betInfo['betDetail']['pp']['bet'],
                    bet_amount_tie: this.betInfo['betDetail']['tie']['bet'],
                    client_uuid: this.loginUser['uuid'],
                    country: 'KOR',
                    ct_index: this.tableIdx,
                    identifying_number: this.gameNo,
                }


                await this.apis.bet(params);
                sendTopic(`${this.roomNo}/live_bacc/UserBet`, {
                    'room_no': this.roomNo,
                    'game_id': this.gameNo,
                    'bet_region': 5,
                    'bet_amount': `${this.betInfo['betDetail']['p']['bet']}:
					${this.betInfo['betDetail']['pp']['bet']}:${this.betInfo['betDetail']['tie']['bet']}:${this.betInfo['betDetail']['b']['bet']}:${this.betInfo['betDetail']['bp']['bet']}`,
                    'current_amount': this.totalBalance,
                    'user_id': this.loginUser['id'],
                    'user_uuid': this.loginUser['uuid'],
                })
                getBalance(true);
                // 전 배팅 기록 저장
                this.restoreCenterBoard = JSON.parse(JSON.stringify(this.centerBoard));
                this.restoreBetInfo = JSON.parse(JSON.stringify(this.betInfo));
                this.restoreSelectedBoard = JSON.parse(JSON.stringify(this.curSelectedBoard));

                this.betInfo['totalBet'] = 0;

            },
            betCancel(){ // 등록된 배팅 취소
                if(!this.isBetProgress){
                    return;
                }
                this.betInfo['totalBet'] = 0;
                this.$store.commit('setTotalBet', 0);
                this.betInfo['totalWin'] = 0;
                this.centerBoard.forEach((board) => {
                    board.betPool = [];
                    board.result = [];
                });
                if(this.curSelectedBoard.length > 0){
                    this.curSelectedBoard = [];
                }
                this.betDist = {
                    'p': {
                        percent: 0,
                        amount: 0
                    },
                    'b': {
                        percent: 0,
                        amount: 0
                    },
                    'tie': {
                        percent: 0,
                        amount: 0
                    },
                    'pp': {
                        percent: 0,
                        amount: 0
                    },
                    'bp': {
                        percent: 0,
                        amount: 0
                    }
                }
                this.betInfo = {
                    totalBet: 0,
                    totalWin: 0,
                    betDetail: {
                        'pp': {
                            bet: 0,
                            expectWin: 0
                        },
                        'p': {
                            bet: 0,
                            expectWin: 0
                        },
                        'tie': {
                            bet: 0,
                            expectWin: 0
                        },
                        'b': {
                            bet: 0,
                            expectWin: 0
                        },
                        'bp': {
                            bet: 0,
                            expectWin: 0
                        }
                    }
                }
                this.$store.commit('setCurSelectBoard',[]);
                this.$store.commit('setBetDetail', this.betInfo['betDetail']);
                setUserBetInfo(`${loginUser['id']}/${this.gameNo}/${JSON.stringify(this.betInfo)}`);
            },
            // 배팅 한 단계 뒤로 되돌리기
            betUndo(){
                if(!this.isBetProgress){
                    return;
                }
                if(this.curSelectedBoard.length > 0){
                    let prev = this.curSelectedBoard.pop();
                    let popSelectChip = prev['betPool'].pop();
                    this.betInfo['totalBet'] -= popSelectChip['value'];
                    this.betInfo['totalWin'] -= popSelectChip['value'] * prev['win'];
                    this.betInfo['betDetail'][prev['key']]['bet'] -= popSelectChip['value'];
                    this.betInfo['betDetail'][prev['key']]['expectWin'] -= popSelectChip['value'] * prev['win'];
                }
                setUserBetInfo(`${loginUser['id']}/${this.gameNo}/${JSON.stringify(this.betInfo)}`);
                this.$store.commit('setBetDetail', this.betInfo['betDetail']);
            },
            betRestore(){ // 전 게임 배팅 기록 적용
                if(!this.isBetProgress){
                    return;
                }
                if(this.restoreCenterBoard.length > 0)
                {
                    this.centerBoard = JSON.parse(JSON.stringify(this.restoreCenterBoard));
                    this.betInfo = JSON.parse(JSON.stringify(this.restoreBetInfo));
                    this.curSelectedBoard = JSON.parse(JSON.stringify(this.restoreSelectedBoard));
                }
                setUserBetInfo(`${loginUser['id']}/${this.gameNo}/${JSON.stringify(this.betInfo)}`);
                this.$store.commit('setBetDetail', this.betInfo['betDetail']);
            },
            getIngameTopics(topics) {
                let roomInfoTopic = `${this.roomNo}/live_bacc/RoomInfo`;
                let gameStatusTopic = `${this.roomNo}/live_bacc/GameStatus`;
                let gameCardTopic = `${this.roomNo}/live_bacc/GameCard`;
                let gameResultTopic = `${this.roomNo}/live_bacc/GameResult`;
                let roomBetUsersTopic = `${this.roomNo}/live_bacc/RoomBetList`;

                let player = this.centerBoard.find(board => board['key'] === 'p');
                let banker = this.centerBoard.find(board => board['key'] === 'b');

                if(Object.entries(topics).length == 0) {
                    this.$router.push('/lobby');
                }

                Object.entries(topics).forEach((topic) => {
                    if (topic[0] == roomBetUsersTopic){
                        let orders = ['p', 'pp', 'tie', 'b', 'bp'];
                        topic[1]['user_id'].forEach((user, idx) => {
                            let obj = {};
                            obj['user'] = user;
                            if(topic[1]['user_betamount'][idx]){
                                let amounts = topic[1]['user_betamount'][idx].split(',');
                                amounts.forEach((amount, idx) => {
                                    if(amounts[idx] > 0){
                                        obj[orders[idx]] = amounts[idx];
                                    }
                                });
                            }
                            if(this.roomBetList.findIndex(obj => obj['user'] == user) == -1){
                                this.roomBetList.push(obj);
                            }
                        });
                    }
                    if (topic[0] == gameStatusTopic) {
                        this.gameId = topic[1]['game_id'];
                        this.gameNo = topic[1]['IdentifyingNumber'];
                        this.roomStatus = topic[1]['status'];
                    }
                    if(topic[0] == roomInfoTopic) {

                        this.betMinMax['p']['min'] = topic[1]['player_min_bet'];
                        this.betMinMax['p']['max'] = topic[1]['player_max_bet'];
                        this.betMinMax['pp']['min'] = topic[1]['ppair_min_bet'];
                        this.betMinMax['pp']['max'] = topic[1]['ppair_max_bet'];
                        this.betMinMax['tie']['min'] = topic[1]['tie_min_bet'];
                        this.betMinMax['tie']['max'] = topic[1]['tie_max_bet'];
                        this.betMinMax['b']['min'] = topic[1]['banker_min_bet'];
                        this.betMinMax['b']['max'] = topic[1]['banker_max_bet'];
                        this.betMinMax['bp']['min'] = topic[1]['bpair_min_bet'];
                        this.betMinMax['bp']['max'] = topic[1]['bpair_max_bet'];

                        this.roomStatus = topic[1]['status'];
                        this.scoreCounts = {
                            'p': 0,
                            'pp': 0,
                            'b': 0,
                            'bp': 0,
                            'tie': 0
                        }
                        topic[1]['score_board_record'].forEach(score => {
                            score.split(',').forEach((data, idx) => {
                                if(data == 1){
                                    this.scoreCounts[BACCARAT_SCORE_IDX[idx]] += 1
                                }
                            });
                        });
                        this.rightBoard['header'].map(data => {
                            data['count'] =  this.scoreCounts[data['key']];
                            return data;
                        });
                        this.isLoading = false;
                    }

                    if (topic[0] == gameResultTopic) {
                        if(topic[1]['game_id'] === this.gameId && this.roomStatus >= 5){
                            let resultMsg = '';
                            let resultAmounts = [];
                            this.realWins = 0;
                            let betDetails = this.betInfo['betDetail'];
                            topic[1]['all_status'].forEach((result, idx) => {
                                if(result === 1) {
                                    if(betDetails){
                                        resultAmounts.push({ key: BACCARAT_SCORE_IDX[idx], ...betDetails[BACCARAT_SCORE_IDX[idx]]});
                                    }
                                    resultMsg += ` ${BACCARAT_RESULT_IDX[idx]} `;
                                    if(idx === 0 || idx === 1 || idx === 4){
                                        this.resultClass = BACCARAT_SCORE_IDX[idx];
                                    }
                                }
                            });

                            this.result = `${resultMsg}`;
                            if(betDetails){
                                // 실제 화면에 보여줄 총 상금 계산
                                resultAmounts.forEach(result => {
                                    if(betDetails[result['key']] && betDetails[result['key']]['bet'] != 0){
                                        this.realWins += betDetails[result['key']]['expectWin'];
                                        this.realWins += betDetails[result['key']]['bet'];
                                    }
                                });
                                Object.values(betDetails).forEach(bet => {
                                    if(bet['bet'] != 0){
                                        this.realWins -= bet['bet'];
                                    }
                                });

                                //tie 결과인경우에는 뱅커, 플레이어 배팅한금액을 돌려줌
                                if(this.resultClass == 'tie'){
                                    this.realWins += betDetails['b']['bet'] > 0 ? betDetails['b']['bet'] : 0;
                                    this.realWins += betDetails['p']['bet'] > 0 ? betDetails['p']['bet'] : 0;
                                }
                            }

                            this.roomBetList = [];
                            this.realTimeBet = [];
                            this.realWins = setNumberFormat(this.realWins);
                            this.$store.commit('setRealWins', this.realWins);
                            this.$store.commit('setIsBetting', false);
                            this.isShowResult = true;
                            this.isShowBetUser = false;
                            this.settingScoreBoard();
                            delete topics[`${this.roomNo}/live_bacc/GameResult`];
                        }
                    }
                    if (topic[0] == gameCardTopic) {
                        if(this.roomStatus === 0){
                            return;
                        }
                        if(topic[1]['game_id'] === this.gameId){
                            banker['result'] = arraySplice(topic[1]['banker_total_card_idx'], -1);
                            player['result'] = arraySplice(topic[1]['player_total_card_idx'], -1);

                            // 뱅커카드 나오는순서 딜러캠 위치랑 동일하게 하기
                            if(banker['result'].length == 2){
                                banker['result'] = [banker['result'][1], banker['result'][0]];
                            }
                            if(banker['result'].length == 3){
                                banker['result'] = [banker['result'][1], banker['result'][0], banker['result'][2]];
                            }

                            topic[1]['banker_total_card_idx'].forEach(cardValue => {
                                let value = CARD_INFO_ARRAY_VALUE[cardValue];
                                if(value === 0 || cardValue === -1 ){
                                    return;
                                }
                                if(this.bankerRst.indexOf(cardValue) === -1){
                                    this.bankerRst.push(cardValue);
                                }
                            });
                            topic[1]['player_total_card_idx'].forEach(cardValue => {
                                let value = CARD_INFO_ARRAY_VALUE[cardValue];
                                if(value === 0 || cardValue === -1){
                                    return;
                                }
                                if(this.playerRst.indexOf(cardValue) === -1){
                                    this.playerRst.push(cardValue);
                                }
                            });
                            this.bankerCardSum = 0;
                            this.playerCardSum = 0;
                            this.bankerRst.forEach(cardValue => {
                                this.bankerCardSum += CARD_INFO_ARRAY_VALUE[cardValue];
                            });
                            this.playerRst.forEach(cardValue => {
                                this.playerCardSum += CARD_INFO_ARRAY_VALUE[cardValue];
                            });
                            if(this.playerCardSum >= 10){
                                this.playerCardSum = String(this.playerCardSum).substr(1,1);
                            }
                            if(this.bankerCardSum >= 10){
                                this.bankerCardSum = String(this.bankerCardSum).substr(1,1);
                            }
                        } else {
                            this.bankerCardSum = 0;
                            this.playerCardSum = 0;
                            this.playerRst = [];
                            this.bankerRst = [];
                            banker['result'] = [];
                            player['result'] = [];
                        }
                    }
                });
            },
            getCardColor(rst, order) {
                return rst <= 26 ? 'black' : 'red';
            },
            isLastCard(idx){
                return idx === 2 ? 'last' : 'notLast';
            },
            clickChip(chip,idx,event)	{
                this.selectChip = chip;
            },
            animationBoard(event,idx){
                const x = event.clientX - this.selectChipPosition.x;
                const y = event.clientY - this.selectChipPosition.y;
                const anim =  this.selectChipRef.animate([
                    {
                        visibility: "visible",
                        transform : "translate(0px, 0px) scale(1.5)",
                        opacity   : "1",
                    },
                    {
                        visibility: "hidden",
                        transform : `translate(${x}px, ${y}px) scale(0.5)`,
                        opacity   : "0",
                    },

                ], {
                    duration   : 300,
                    iterations : 1,
                });
                if (anim) {
                    anim.onfinish = () => {
                        this.betAnim = false;
                    };
                }
            },
            clickBetBoard(selected , idx) {
                if(!this.betAnim){
                    this.betAnim = true;
                }

                if(this.getSelectChip['key']) {
                    this.selectChip = this.getSelectChip
                }
                if(!this.selectChip['key']
                    || !this.isBetProgress
                    || this.totalBalance == 0){
                    return;
                }
                let sum = 0;
                Object.values(this.betInfo['betDetail']).forEach(obj => {
                    sum += obj['bet']
                });

                if(sum + this.selectChip['value'] > this.totalBalance + sum){
                    this.errMsg = this.$t('insufficient');
                    this.$store.commit('setBetErrMsg', this.errMsg);
                    return;
                }

                // minMax 체크
                let min = this.betMinMax[selected['key']]['min'];
                let max = this.betMinMax[selected['key']]['max'];
                if(this.betInfo['betDetail'][selected['key']]['bet'] + this.selectChip['value'] > max){
                    this.errMsg = `${this.$t('checkMinMax')} ${this.$t(selected['key'])} ${this.$t('min')}: ${min} ${this.$t('max')}: ${max}`;
                    this.$store.commit('setBetErrMsg', this.errMsg);
                    return;
                }

                let selectedBoard = this.centerBoard.find(board => board['key'] === selected['key']);

                if(selectedBoard['betPool'].length < 30){
                    selectedBoard['betPool'].push(this.selectChip);
                    this.betInfo['totalBet'] += this.selectChip['value'];
                    this.betInfo['totalWin'] += this.selectChip['value'] * selectedBoard['win'];
                    this.betInfo['betDetail'][selectedBoard['key']]['bet'] += this.selectChip['value'];
                    this.betInfo['betDetail'][selectedBoard['key']]['expectWin'] += this.selectChip['value'] * selectedBoard['win'];
                    this.curSelectedBoard.push(selectedBoard);
                    this.$store.commit('setTotalBet', this.betInfo['totalBet']);
                    this.$store.commit('setBetDetail', this.betInfo['betDetail']);
                    this.$store.commit('setCurSelectBoard', this.curSelectedBoard);
                    setUserBetInfo(`${loginUser['id']}/${this.gameNo}/${JSON.stringify(this.betInfo)}`);
                }


            }
        }
    })
</script>
<style scoped>
.betUser {
	font-size: 12px;
}
.userInfoWrap {
	position: absolute;
	z-index: 10;
	width: 38.76px;
	height: auto;
	display: flex;
	/*top: 51px;*/
	top: 42px;
	left: 30px;
	cursor: pointer;
}

.userInfoWrap .userInfoBox {
	background-color: rgba(0,0,0,0.8);
	margin-left: 6px;
	align-items: center;
	justify-content: center;
	min-width: 120px;
	height: auto;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 6px;
	color: white;
	border-radius: 6px;
	outline: 1px solid white;
}

input {
	width: 100%;
	height: inherit;
	font-size: 20px;
	box-sizing: border-box;
	background-color: transparent;
	border:none;
	border-right:0px;
	border-top:0px;
	boder-left:0px;
	boder-bottom:0px;
	color: white;
}
.chip {
    animation-name: betting;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
}
@keyframes betting {
  from {
    transform: scale(2);
  }

  to {
    transform: scale(1);
  }
}

.wrap {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 177.78vh;
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: center;
    overflow: hidden;
    margin: 0 auto;
}
.wrap .inGameWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1920px;
    height: 1080px;
	overflow: hidden;
    position: absolute;
    background-color: var(--body-color);
}

.inGameWrap .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: inherit;
}

.resultText {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    white-space: pre-line;
    z-index: 5;
    left: 0;
	font-size: 22px;
    top: 450px;
    width: 100%;
    height: 48px;
}

.resultText2 {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	position: absolute;
	white-space: pre-line;
	z-index: 5;
	left: 0;
	font-size: 22px;
	top: 500px;
	width: 100%;
	height: 48px;
	color: white;
	background-image: radial-gradient(circle, #3b3b3b 10%, rgba(0, 0, 0, 0) );
}

.resultText2 .tx {
	color: #ffce70;
}



.resultText.p {
    background-image: radial-gradient(circle, var(--inGame-pp-board-color1) 10%, rgba(0, 0, 0, 0) );
    color: var(--inGame-progress-text-color);
}
.resultText.b {
    background-image: radial-gradient(circle, var(--inGame-b-board-color1) 10%, rgba(0, 0, 0, 0) );
    color: var(--inGame-progress-text-color);
}
.resultText.tie {
    background-image: radial-gradient(circle, var(--inGame-tie-board-color1) 10%, rgba(0, 0, 0, 0) );
    color: var(--inGame-progress-text-color);
}

.inGameWrap .icons {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 3%;
	position: absolute;
	min-width: 378px;
	height: 66px;
	top: 30px;
	background-color: rgba(0, 0, 0, 0.7);
	right: 30px;
	border-radius: 30px;
	z-index: 10;
	padding: 0 15px;
    width: 620px;
}

.inGameWrap .batting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
}
.inGameWrap .batting .errMsg {
	color: red;
	position: relative;
	bottom: 15px;
}
.bettingBoardWrap {
	width: 100%;
	box-sizing: border-box;
	/*padding-left: 7px;*/
	padding-right: 3px;
}

.bettingBoardWrap .bettingBoard {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 316px;
    /*justify-content: space-between;*/
    justify-content: center;
    gap: 6px;
}

.bettingBoardWrap .bettingBoard .centerBoard {
	display: flex;
	width: 742px;
	/*height: 315px;*/
    /* 2101007한유정 추가 / chips 사라졌을때 height: 404px */
    height: 404px;
	flex-direction: column;
	transition: all 1s ease-out;
	position: relative;
	left: 1px;
}

.bettingBoardWrap .bettingBoard .centerBoard .boardBox {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    border-radius: 10px;
    /*height: 242px;*/
    height: 257px;
    padding: 6px 4px;
}

.boardBox .pp, .boardBox .bp {
	max-width: 110px;
}

.boardBox .tie {
	max-width: 124px;
}

.icon {
	display: flex;
	align-items: center;
	width: 60px;
	height: inherit;
	position: relative;
}

.progressBox .progressText {
    position: absolute;
    font-weight: normal;
    z-index: 1;
    font-size: 22px;
    /*margin-bottom: 5px;*/
}

.progressBox .progress {
    display: flex;
    width: 100%;
    transition: all 0.8s ease-out;
    /*height: 36px;*/
    height: 30px;
}
.progress.normal {
    background-image: radial-gradient(circle, var(--inGame-progress-color) 10%, rgba(0, 0, 0, 0) );
}
.progress.wait {
    background-image: radial-gradient(circle, var(--inGame-b-border-color) 10%, rgba(0, 0, 0, 0) );
}
.bettingBoardWrap .progressBox {
    display: flex;
    align-items: center;
    position: relative;
    transition: all 1s ease-out;
    justify-content: center;
    width: 715px;
    margin-left: auto;
    margin-right: auto;
    /*height: 36px;*/
    height: 30px;
    background-image: radial-gradient(circle, var(--inGame-progressBox-color) 10%, rgba(0, 0, 0, 0) );
    color: var(--inGame-progress-text-color);
}

.betPoolBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    position: absolute;
    bottom: 43px;
	left: 9px;
}

.betPoolBox .chip{
    position: absolute;
    bottom: 10px;
    width: 30px;
    height: 30px;
    flex-basis: 25px;
    flex-shrink: 0;
}

.bettingBoardWrap .bettingBoard .centerBoard .board {
    display: flex;
    position: relative;
    gap: 1%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-radius: 10px;
    margin: 0 2px;
}

.board .resultBox {
	position: relative;
	top: 3px;
	display: flex;
	align-items: flex-start;
	gap: 3px;
	flex-direction: row;
	height: 115px;
}

.resultBox.p {
	flex-direction: row-reverse;
}

.board .resultBox .result {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex: 1;
	width: 55px;
	line-height: 28px;
	height: 70px;
	font-weight: bold;
	background-color: white;
	border-radius: 7px;
	font-size: 46px;
	position: relative;
	top: 6px;
}

.result .number {
	position: relative;
	top: 6px;
}


.black {
    color: black;
}

.red {
    color: #dd3d3d;
}

.last {
    min-width: 60px !important;
    height: 46px !important;
}

.result div {
    white-space: pre-line;
}

.last div {
    white-space: normal !important;
}

.bettingBoardWrap .bettingBoard .chips {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 38px;
    flex-direction: row;
    margin: 19px auto;
    transition: all 0.5s ease-out;
}

.chip {
    position: relative;
	transform: scale(1.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: bold;
    background-size: 100% 100%;
    border-radius: 20px;
	color: red;
    width: 66px;
    height: 66px;
}

.chip.bigFontSize {
	font-size: 28px;
}

.betDisabled {
    cursor: not-allowed !important;
}

.chip.selected {
    filter: drop-shadow(0 0 6px rgb(35 128 255));
    transform: scale(1.5);
}
.board.pp, .board.p {
    background: linear-gradient(to bottom, var(--inGame-pp-board-color1), var(--inGame-pp-board-color2));
    border: 1px solid var(--inGame-pp-border-color);
    color: var(--inGame-pp-font-color);
}

.board.tie {
    background: linear-gradient(to bottom, var(--inGame-tie-board-color1), var(--inGame-tie-board-color2));
    border: 1px solid var(--inGame-tie-border-color);
    color: var(--inGame-tie-font-color);
}

.board.b, .board.bp {
    background: linear-gradient(to bottom, var(--inGame-b-board-color1), var(--inGame-b-board-color2));
    border: 1px solid var(--inGame-b-border-color);
    color: var(--inGame-b-font-color);
}

/*.bettingBoardWrap .bettingBoard .leftBoard .header{
    height: 44px !important;
}*/
.bettingBoardWrap .bettingBoard .rightBoard .header {
	top: -9px !important;
	position: relative;
    height: 30px !important;
    margin-bottom: 6px;
    margin-left: 2px;
    width: 577px;
}

.bettingBoardWrap .bettingBoard .rightBoard,
.bettingBoardWrap .bettingBoard .leftBoard {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 576px;
    position: relative;
    /*top: -32px;*/
}

.bettingBoardWrap .bettingBoard .leftBoard .header,
.bettingBoardWrap .bettingBoard .rightBoard .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*justify-content: space-between;*/
    justify-content: center;
    gap: 13px;
    border-radius: 10px;
    /*height: 32px;*/
    height: 37px;
    /*min-height: 32px;*/
    /*margin-bottom: 7px;*/
}

.bettingBoardWrap .bettingBoard .leftBoard .scoreBoard,
.bettingBoardWrap .bettingBoard .rightBoard .scoreBoard {
    width: 567px;
    height: 167px;
    max-height: 167px;
    min-height: 167px;
    margin-top: 8px;
}
.bettingBoardWrap .bettingBoard .leftBoard .scoreBoard,
.bettingBoardWrap .bettingBoard .rightBoard .scoreBoard {
    width: 560px !important;
    height: 172px !important;
    max-height: 172px !important;
    min-height: 172px !important;
    margin-left: 8px;
    margin-bottom: 13px;
}
.bettingBoardWrap .bettingBoard .leftBoard .scoreBoard{
    margin-bottom: 12px !important;
}

.bettingBoardWrap .bettingBoard .leftBoard .userInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 64px;
    gap: 6px;
    /*margin: 5px 0;*/
    margin: 3px 0;
}

.bettingBoardWrap .bettingBoard .rightBoard .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 52px;
    margin: 7px auto;
}

.buttons .btnWrap {
    width: 57px;
	position: relative;
    height: 57px;
}

.userInfo div {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*border-radius: 10px;*/
    /*width: 188px;*/
    /*height: 57px;*/
    border-radius: 10px;
    width: 188px;
    text-align: center;
}

.userInfo div:nth-child(1){
    color: var(--inGame-userInfo-title-color);
}
.userInfo div:nth-child(2){
    color: var(--inGame-userInfo-value-color);
}

.bettingBoardWrap .bettingBoard .leftBoard .header .playerHeader {
    width: 283px;
    height: 33px;
	cursor: pointer;
    background-image: url("../assets/icons/ingame_bet_btn/btn-player.png");
    background-repeat: no-repeat;
}
.bettingBoardWrap .bettingBoard .leftBoard .header .bankerHeader {
    width: 283px;
    height: 33px;
	cursor: pointer;
    background-image: url("../assets/icons/ingame_bet_btn/btn-banker.png");
    background-repeat: no-repeat;
    /*margin-right: 2px;*/
    margin-right: -3px;
}

.bettingBoardWrap .bettingBoard .rightBoard .header .titles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: bold;
    gap: 5px;
}

.bettingBoardWrap .bettingBoard .rightBoard .header .titles {
    color: white;
}

.bettingBoardWrap .bettingBoard .rightBoard .header .titles .title {
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    font-size: 12px;
}

.header .titles .title > img{
    height: 20px;
}
.header .titles .title.player {
    color: var(--inGame-player-color);
}

.header .titles .title.banker {
    color: var(--inGame-banker-color);
}
.circleText {
	position: absolute;
	color: white;
	font-size: 40px;
}

.circleProgress {
	position: absolute;
	z-index: 4;
	left: 0;
	bottom: 85%;
	margin-left: 10px;
	margin-top: 10px;
	opacity: 0.5;
	display: flex;
	align-items: center;
	justify-content: center;
}
.roomInfoBox {
	color: white;
	font-weight: bold;
	position: absolute;
	z-index: 4;
	left: 0;
	bottom: 27.5%;
	margin-left: 10px;
	margin-top: 10px;
	opacity: 0.5;
	display: flex;
	flex-direction: column;
}
.betTotalBox {
    width: 25px;
    height: 25px;
    background-color: #222222;
    position: absolute;
    color: #e2e2e2;
    border-radius: 6px;
    font-size: 16px;
    text-align: center;
    line-height: 25px;
    font-weight: 500;
}

.betTotalBox.p {
    top: 0;
    right: 0;
    border: 1px solid var(--inGame-pp-border-color);
    margin: 5px 5px 0;
}
.betTotalBox.b {
    top: 0;
    left: 0;
    border: 1px solid var(--inGame-b-border-color);
    margin: 5px 0 0 5px;
}
.betTotalBox.tie, .betTotalBox.pp, .betTotalBox.bp {
    display: none;
}
.gameNo {
    position: absolute;
    margin-left: 10px;
    font-weight: bold;
    transition: all 0.7s ease-out;
}

/* 210929한유정 추가 */
.balanceWrap{
  height: 60px;
}
.boxTopTxt{
    font-size: 18px;
    color: #fff !important; /* 글씨 색상일치를 위해 important */
    font-weight: 500;
    /*padding-top: 9px;*/
    padding-top: 12px;
}
.boxBtmTxt{
    font-size: 16px;
}
.distBox{
    position: absolute;
    width: 100px;
    height: 24px;
    border: 1px solid blue;
    top: 0;
    border-radius: 0.3rem;
    background-color: #222;
    margin: 5px 0;
}
.distBox p{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    line-height: 24px;
    z-index: 1;
    color: #ffe431;
    font-weight: 500;
}
.distBox .amount {
	position: absolute;
	top: 26px;
	width: 100%;
	text-align: center;
	color: white;
}
.distPrcnt{
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: #8b1d2a; /* 임시로 기본 컬러 잡아둔것 */
	border-radius: 0.3rem;
}
.mainTxt{
    font-size: 28px;
    margin-top: 10px;
    letter-spacing: 1.2px;
    font-weight: 500;
}

.mainTxt:lang(en){
	font-size: 25px !important;
}

.winTxt{
    margin-top: -8px;
    font-size: 22px;
    letter-spacing: -0.5px;
    font-weight: 500;
}
.distBox .boxClrP{
    background-color: #1d3c8b;
}
.distBox .boxClrB{
    background-color: #8b1d2a;
}
.distBox .boxClrT{
    background-color: #1d8b22;
}
.boxLineP{
    border-color: #4486d7;
}
.boxLineB{
    border-color: #dd3d3d;
}
.boxLineT{
    border-color: #4bd12b;
}

/* 210930한유정 추가 / 채팅창 css */
.chatting{
	position: absolute;
	left: 0;
	top: 0;
	margin: 5px;
	z-index: 10;
	opacity: 0.5;
}
.chatting .sendMsg{
    width: 350px;
    height: 31px;
    line-height: 31px;
    border-radius: 1.45rem;
    background-color: #0e162f;
    border: solid 2px #3a5695;
    padding: 2px 17px;
    font-size: 14px;
}
.chatting .sendMsg > p{
    color: #6f6f76;
}
.chatting .listMsg{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 710px;
	overflow: hidden;
	margin-left: 20px;
	color: #fff2d7;
	transition: all 1s ease-out;
}
.chatting .detailMsg span{
    display:inline-block;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}
.chatting .detailMsg span:last-of-type{
    color: #fff;
}

.videoOff{
    position: relative;
}
.videoOff .bgImgBox{
    width: 1920px;
    height: 1080px;
    z-index: 1;
}
.videoOff .chrctImgBox{
    position: absolute;
    width: 330px;
    height: 910px;
    left: 50%;
    top: 0;
    margin-top: 60px;
    transform: translateX(-50%);
    z-index: 2;
}
.videoOff .tableImgBox{
    position: absolute;
    width: 100%;
    bottom: -20px;
    left: 0;
    z-index: 3;
}
.videoOff img{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
}
.rightPointBox{
    padding: 0 26px;
}
.timeSetBox{
    position: absolute;
    top: 324px;
    left: 50%;
    width: 184px;
    height: 184px;
    transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
}
.timeSetBox .text:lang(en){
	font-size: 58px !important;
}
.timeSetBox .loading{
    width: 184px;
    height: 184px;
	animation-name: ani_loading;
	animation-iteration-count: infinite;
	animation-duration: 1s;
}
.timeSetBox .text{
    position: absolute;
    top: 2px;
    left: 0;
    width: 184px;
	display: flex;
	align-items: center;
	justify-content: center;
    height: 184px;
    color: #fafafa;
    font-size: 84px;
    text-align: center;
    font-weight: 500;
}

@keyframes ani_loading{
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.betDetail{
    background-color: #222222;
    color: #ffe431;
    border-radius: 6px;
    border: 1px solid;
    padding: 6px;
    font-size: 14px;
    margin-top: 22px;
    margin-bottom: 3px;
    width: 102px;
    text-align: center;
    /*width: 85px;*/
    /*margin-bottom: 10px;*/
    /*margin-top: 14px;*/
    /*font-size: 18px;*/
}

.board.p .title, .board.b .title{
	position: absolute;
	bottom: 78px;
}

.board.p .winTxt, .board.b .winTxt{
	position: relative;
	bottom: 5px;
}


.betDetail {
	position: absolute;
	bottom:22px;
}
.betLimit {
	position: absolute;
	bottom: 5px;
	font-size: 12px;
	color: white;
}
.betDetail.pReward{
    border-color: #4486d7;
}
.betDetail.tieReward{
    border-color: #4bd12b;
}
.betDetail.bReward{
    border-color: #dd3d3d;
}
.ScoreBoardBg{
    position: absolute;
    bottom: 73px;
    right: 0;
    width: 577px;
    height: 188px;
    background-color: #0e162f;
    border-radius: 10px;
    border: solid 2px var(--inGame-header-border-color);
    z-index: -1;
}
.currency{
    padding-top: 22px;
}
.rightBoard .ScoreBoardBg{
    margin-right: -3px;
}
.userInfoModal {
	z-index: 10;
	position: absolute;
	left: 0;
	top: 4px;
}


/*211029 한유정 추가*/
.result .mark {
    margin-left: 3px;
    position: absolute;
    font-size: 16px;
    top: 0;
    left: 0;
}
.mark > img{
    width: 16px;
}
.betUsersPosition {
	position: absolute;
	top: 100px;
	left: 97px;
}

/*211115 한유정 추가*/
.betListBox{
    position: absolute;
    left: 0;
    margin-left: -350px;
    transition: all 1s ease;
    top: 100px;
    width: 350px;
    height: 625px;
    background-color: rgba(0,0,0,.3);
    z-index: 5;
    padding: 1%;
    color: #fff;
}
.betListBox.open{
    margin-left: 0 !important;
}
.realTimeBetList{
    color: var(--inGame-progress-color);
}
.realTimeBetList > span{
    color: #fff;
}

/*211122 한유정 추가*/
.frontChips{
    position: relative;
}
.backChips{
    position: absolute;
    bottom: 0;
    margin-bottom: 62px !important;
}
</style>